import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import StepFive from '../../../components/SootchyProfile/Signup/Step5'
import StepFour from '../../../components/SootchyProfile/Signup/Step4'
import StepOne from '../../../components/SootchyProfile/Signup/Step1'
import StepThree from '../../../components/SootchyProfile/Signup/Step3'
import StepTwo from '../../../components/SootchyProfile/Signup/Step2'
import {
  changeUserRegistrationStepperData,
  decreamentUserRegistrationStep,
  increamentUserRegistrationStep,
  resetUserRegistrationStep,
  underageUserRegistrationStep,
} from '../../../redux/actions/userRegistrationStepperActions'
// @ts-ignore
import DefaultLayout from '../../Common/Layouts/NewDefault'
import { RootReduxStateType } from '../../../types'
import { getActivationUserInfo } from '../../../redux/actions/userActions'
import { createAlertAction } from '../../../redux/actions/alertActions'

type ActivationUserInfo = {
  activationEventID: number
  activationEventVenue: string
  activationEventName: string
  activationEventDate: string
  description: string
  mapLocation: string
  emailAddress: string
  expiryDate: string
  isUsed: false
}

// const testData: ActivationUserInfo = {
//   activationEventID: 2,
//   activationEventVenue: 'Exclusive Invite via email',
//   activationEventName: 'Sootchy',
//   activationEventDate: '2099-12-31T10:00:00',
//   description: 'Exclusive Invite via email to signup',
//   mapLocation: 'https://goo.gl/maps/wPHWyhcsYzSFfA4C8',
//   emailAddress: 'ankur.shah@sootchy.com',
//   expiryDate: '2023-04-20T00:00:00',
//   isUsed: false,
// }

const SignUp = () => {
  const dispatch = useDispatch()
  const stepState = useSelector(
    (state: RootReduxStateType) => state.userRegistrationStepper
  )
  const [_, setActivationUserInfo] = useState<null | ActivationUserInfo>(null)

  const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  useEffect(() => {
    if (!stepState.data.dateOfBirth) {
      dispatch(resetUserRegistrationStep())
    }

    handleGetActivationInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetActivationInfo = async () => {
    dispatch(changeUserRegistrationStepperData('referredByReferralCode', null))
    dispatch(changeUserRegistrationStepperData('isActivationCodeValid', false))
    dispatch(changeUserRegistrationStepperData('emailAddress', null))

    const invitationId = query.get('invitationId')
    if (invitationId) {
      getActivationUserInfo(invitationId)
        .then((res) => {
          if (res.data.isExpired)
            dispatch(
              createAlertAction({
                type: 'DANGER',
                title: 'Invitation is expired',
                message:
                  'Invitation link is expired, please contact support for futher info.',
                autoClose: true,
              })
            )

          setActivationUserInfo(res.data)

          dispatch(
            changeUserRegistrationStepperData(
              'referredByReferralCode',
              invitationId
            )
          )
          dispatch(
            changeUserRegistrationStepperData('isActivationCodeValid', true)
          )
          dispatch(
            changeUserRegistrationStepperData(
              'emailAddress',
              res.data.emailAddress
            )
          )
        })
        .catch(() =>
          dispatch(
            createAlertAction({
              type: 'DANGER',
              title: 'Invitation already used',
              message: 'Invitation link is already used.',
              autoClose: true,
            })
          )
        )
    }
  }

  const handleDataChange = (e: any) => {
    dispatch(changeUserRegistrationStepperData(e.target.name, e.target.value))
  }

  const increamentStep = () => {
    dispatch(increamentUserRegistrationStep())
  }

  const decreamentStep = () => {
    dispatch(decreamentUserRegistrationStep())
  }

  const underageStep = () => {
    dispatch(underageUserRegistrationStep())
  }

  const resetStep = () => {
    dispatch(resetUserRegistrationStep())
  }

  useEffect(() => {
    if (query.get('refferalCode'))
      dispatch(
        changeUserRegistrationStepperData(
          'referredByReferralCode',
          query.get('refferalCode')
        )
      )
    else if (query.get('RefferalCode'))
      dispatch(
        changeUserRegistrationStepperData(
          'referredByReferralCode',
          query.get('RefferalCode')
        )
      )
    else
      dispatch(changeUserRegistrationStepperData('referredByReferralCode', ''))

    if (query.get('email'))
      dispatch(
        changeUserRegistrationStepperData('emailAddress', query.get('email'))
      )
    else if (query.get('Email'))
      dispatch(
        changeUserRegistrationStepperData('emailAddress', query.get('Email'))
      )
    else dispatch(changeUserRegistrationStepperData('emailAddress', ''))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const displayComponent = () => {
    switch (stepState.step) {
      case 1:
        return (
          <StepOne
            styles={styles}
            handleDataChange={handleDataChange}
            increamentStep={increamentStep}
            underageStep={underageStep}
          />
        )
      case 2:
        return (
          <StepTwo
            styles={styles}
            handleDataChange={handleDataChange}
            stepData={stepState.data}
            increamentStep={increamentStep}
            decreamentStep={decreamentStep}
          />
        )
      case 3:
        return (
          <StepThree
            styles={styles}
            handleDataChange={handleDataChange}
            stepData={stepState.data}
            decreamentStep={decreamentStep}
            increamentStep={increamentStep}
          />
        )

      case 4:
        return <StepFour styles={styles} />

      case 5:
        return <StepFive resetStep={resetStep} />

      default:
        return (
          <StepOne
            styles={styles}
            handleDataChange={handleDataChange}
            increamentStep={increamentStep}
            underageStep={underageStep}
          />
        )
    }
  }

  //return <LayoutFullPage>{displayComponent()}</LayoutFullPage>
  return (
    <DefaultLayout>
      <div className="flex justify-between items-center py-[24px] border-b-2 border-primaryLight border-dashed">
        <p className=" font-semibold text-[22px] text-primaryDark">Sign Up</p>
        <NavLink
          className="text-primary text-[14px] underline cursor-pointer font-[400] leading-[17.78px]"
          to="/login"
        >
          Log in
        </NavLink>
      </div>
      <div className="grow min-h-[496px]">{displayComponent()}</div>
    </DefaultLayout>
  )
}

const styles = {
  form: 'w-8/12 flex flex-col justify-center py-10 p-12 mx-auto h-full tab:w-full',
  input: 'text-input mb-[16px]',
  button: 'btn btn--big btn--dark mb-[16px]',
  backButton: 'btn btn--big btn--gray mb-[16px]',
  match: 'text-warning text-sm mb-[16px]',
  small: 'text-sm mb-[32px]',
}

export default SignUp
