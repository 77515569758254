import React from 'react'
import {
  IoCalendarClearOutline,
  IoEyeOffOutline,
  IoEyeOutline,
} from 'react-icons/io5'

type PropsType = {
  type: string
  label: string
  value: string
  name: string
  classes?: string
  onChange: (x: any) => void
  passwordField?: boolean | undefined
  datepicker?: boolean | undefined
  showPasswordIconStatus?: boolean | undefined
  changeIcon?: () => void
  autoComplete?: string
  readOnly?: boolean
  onBlur?: (x?: any) => void
  disabled?: boolean
}

const TextField = ({
  type = 'text',
  label,
  value,
  name,
  classes,
  onChange,
  passwordField,
  datepicker,
  showPasswordIconStatus,
  changeIcon,
  disabled = false,
  ...props
}: PropsType) => {
  return (
    <div
      className={`form-group ${classes} ${value && 'form-group--filled'} ${
        datepicker && 'pointer-events-none'
      }`}
    >
      <input
        {...props}
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        aria-label={name}
        disabled={disabled}
        style={{
          color: disabled ? '#bbb' : '#000',
        }}
      />

      <label
        htmlFor={name}
        style={{ color: disabled ? '#bbb' : 'rgba(0,79,78,0.6)' }}
      >
        {label}
      </label>

      {passwordField ? (
        showPasswordIconStatus ? (
          <div className="password-visible">
            <IoEyeOutline onClick={changeIcon} className="text-primary" />
          </div>
        ) : (
          <div className="password-visible">
            <IoEyeOffOutline onClick={changeIcon} className="text-primary" />
          </div>
        )
      ) : null}

      {datepicker && (
        <div className="password-visible">
          <IoCalendarClearOutline className="text-gray-500" />
        </div>
      )}
    </div>
  )
}

export default TextField
