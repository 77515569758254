import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateTaxAndCitizenship,
  alpacaStepperUpdateVideo,
  decrementAlpacaStepperStep,
  getIncomeAndLiquidAssetsData,
  getUserFinances,
  increamentAlpacaStepperStep,
  saveUserFinances,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import { AlpacaIncomeType } from '../../../types/alpaca'
import SafeAreaView from '../../Common/SafeAreaView'
import VerificationModal from '../../Common/VerificationModal'
import DropdownSelector from '../../Common/Partials/DropdownSelector'
import SetupBottomAlpaca from '../SetupBottomAlpaca'
// @ts-ignore
import GreenInfo from '../../../assets/greeninfo.png'

const incomeTypes: AlpacaIncomeType[] = [
  '$0.00 - $19,999.99',
  '$20,000 - $49,999.99',
  '$50,000.00 - $99,999.99',
  '$100,000.00 - $499,999.99',
  '$500,000 - $999,999.99',
  '$1,000,000.00 - $9,999,999.99',
]

const AlpacaStepNine: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const handleNext = () => {
    // Save finance details to API
    if (alpaca.taxAndIncome.incomePerYear && alpaca.taxAndIncome.liquidAssets) {
      setDisabled(true)

      saveUserFinances(
        parseInt(user.id),
        alpaca.taxAndIncome.incomePerYear,
        alpaca.taxAndIncome.liquidAssets
      )
        .then((res) => {
          setDisabled(false)
          dispatch(increamentAlpacaStepperStep())
        })
        .catch((err) => {
          alert(err)
          setDisabled(false)
        })
    }
  }

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const handleCancel = () => {
    dispatch(decrementAlpacaStepperStep())
  }

  const handleChange = (key: string, value: string) => {
    dispatch(alpacaStepperUpdateTaxAndCitizenship(key, value))
  }

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (alpaca.taxAndIncome.incomePerYear && alpaca.taxAndIncome.liquidAssets)
      setDisabled(false)
    else setDisabled(true)
  }, [alpaca.taxAndIncome.incomePerYear, alpaca.taxAndIncome.liquidAssets])

  useEffect(() => {
    setLoading(true)
    getUserFinances()
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            alpacaStepperUpdateTaxAndCitizenship(
              'incomePerYear',
              getIncomeAndLiquidAssetsData(
                res.data.annualHouseholdIncomeFinancialRangeID
              )
            )
          )
          dispatch(
            alpacaStepperUpdateTaxAndCitizenship(
              'liquidAssets',
              getIncomeAndLiquidAssetsData(
                res.data.liquidAssetsFinancialRangeID
              )
            )
          )
          setLoading(false)
        }
      })
      .catch((err) => {
        //alert(err)
        setLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {showModal && (
        <VerificationModal
          title="What are liquid assets?"
          showButton={true}
          buttonText="Gotcha!"
          handleOnClick={handleShowModal}
          description="We need copy here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          onClose={handleShowModal}
        />
      )}

      <SafeAreaView>
        <p className="font-semibold text-[17px] py-2">Your income</p>
        <p>How much money do you earn per year?</p>

        <div className="py-4">
          {loading ? (
            <Skeleton height={90} />
          ) : (
            <DropdownSelector
              title={'Income per year'}
              list={incomeTypes}
              value={alpaca.taxAndIncome.incomePerYear ?? ''}
              handleOnchange={(value: string) =>
                handleChange('incomePerYear', value)
              }
            />
          )}
        </div>

        <p>Do you have any liquid assets?</p>

        <div className="py-4">
          {loading ? (
            <Skeleton height={90} />
          ) : (
            <DropdownSelector
              title={'Liquid assets in $'}
              list={incomeTypes}
              value={alpaca.taxAndIncome.liquidAssets ?? ''}
              handleOnchange={(value: string) =>
                handleChange('liquidAssets', value)
              }
            />
          )}
        </div>

        <div className="flex items-center" onClick={handleShowModal}>
          <img src={GreenInfo} alt="GreenInfo" />
          <p className="underline text-primary text-[14px] underline-offset-4 mx-1 cursor-pointer">
            What are liquid assets?
          </p>
        </div>

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 mt-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={disabled || loading}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </div>
  )
}

export default AlpacaStepNine
