import React from 'react'
import {
  Amp,
  AP,
  BusinessJournal,
  Businesswire,
  Cision,
  Forbes,
  Globis,
  TechCrunch,
  THV,
} from '../ImageImports'

const Brands = () => {
  return (
    <div className="mb-[48px] grid grid-cols-3 md:grid-cols-9 gap-4">
      <img src={Forbes} alt="Forbes" className="h-[36px] md:h-[38px]" />
      <img
        src={TechCrunch}
        alt="Tech Crunch"
        className="h-[36px] md:h-[38px]"
      />
      <img
        src={Businesswire}
        alt="Business Wire"
        className="h-[36px] md:h-[38px]"
      />
      <img src={THV} alt="THV" className="h-[36px] md:h-[38px]" />
      <img src={Cision} alt="Cision" className="h-[36px] md:h-[38px]" />
      <img src={AP} alt="AP" className="h-[36px] md:h-[38px]" />
      <img
        src={BusinessJournal}
        alt="Business Journal"
        className="h-[36px] md:h-[38px]"
      />
      <img src={Globis} alt="Globis" className="h-[36px] md:h-[38px]" />
      <img src={Amp} alt="Amp" className="h-[36px] md:h-[38px]" />
    </div>
  )
}

export default Brands
