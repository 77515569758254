import React from 'react'
// @ts-ignore
import CloseIcon from '../../../assets/closeIcon.png'

type PropsType = {
  onClose: () => void
  onCancel: () => void
  onSubmit: () => void
  loading: boolean
}

const BankModal: React.FC<PropsType> = ({
  onClose,
  onCancel,
  onSubmit,
  loading,
}) => {
  return (
    <div>
      <div className="fixed h-full w-screen z-50 top-0 left-0">
        <div
          className="absolute bg-black opacity-80 h-screen w-screen"
          onClick={onClose}
        ></div>
        <div
          className="absolute bg-white w-[88%] rounded-xl p-4 sm:w-[400px]"
          style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
        >
          <div className="flex justify-between items-center border-[#C4E8E8] border-b-2 border-dashed pb-4">
            <p className="font-semibold text-primaryDark">
              Disconnect CapitalOne XXX6575?
            </p>
            <img
              src={CloseIcon}
              alt="close icon"
              className="w-[13px] h-[13px]"
              onClick={onClose}
            />
          </div>
          <div className="py-5">
            <p>
              Once you disconnect this account you will no longer be able to
              transfer funds to and from this account.
            </p>
          </div>
          <div className="flex justify-end">
            <div>
              <button
                className="border-[#C4E8E8] py-3"
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="border-none bg-[#FF3D00] text-white py-3 ml-1"
                onClick={onSubmit}
                disabled={loading}
              >
                Disconnect
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankModal
