import api from '../../api'
import { GET_COINS } from './action.types'

export const getUserCoins = () => async (dispatch: Function) => {
  try {
    // make api call
    //const data = 637
    const res = await api.get(`UserAccount/UserAccountID/1`)
    if (res.status === 200) {
      dispatch({
        type: GET_COINS,
        payload: {
          value: +res.data.pointsEarned / 100,
          referralCode: res.data.referralCode,
        },
      })
    }
  } catch (err) {
    console.log(err)
    return { success: false, err }
  }
}
