import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setupSavingsGetChildrenAPI } from '../../../../redux/actions/setupSavingsStepperActions'
import { getSillaStatus, getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { SilaStatusResp } from '../../../../types/sila'
import { calculateUserAge } from '../../../../utilities/generalUtilities'
import { LoadingSpinner } from '../../../Common/Loading'
// @ts-ignore
import PlusIcon from '../../../../assets/plusnew.png'
// @ts-ignore
import PencilIcon from '../../../../assets/pencil.png'
import My529AddChildModal from '../../../My529/SetupMy529Account/Children/My529AddChildModal'
import { My529MinorAPIType } from '../../../../types/my529'

type PropsType = {
  disableForm?: React.Dispatch<React.SetStateAction<boolean>>
}

const ChildMain: React.FC<PropsType> = ({ disableForm }) => {
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [showAddChildModal, setShowAddChildModal] = useState(false)
  /* When user is editing the child info, 
  this ID will be sent to the modal */
  const [childID, setChildID] = useState<string | null>(null)

  //const [childInfo, setChildInfo] = useState<Object[] | null>(null)
  const [childInfo, setChildInfo] = useState<My529MinorAPIType[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [verificationStatus, setVerificationStatus] = useState<
    'Passed' | 'Documents_Received' | 'Pending' | 'Server_Error' | null
  >(null)
  const [isSilaAccountCreated, setIsSilaAccountCreated] =
    useState<boolean>(false)

  const handleUpdateChild = (id: string) => {
    setChildID(id)
    setShowAddChildModal(true)
  }

  const getChildrenInfo = () => {
    setupSavingsGetChildrenAPI()
      .then((res) => {
        console.log('Getting Child Info', res.data)
        setChildInfo(res.data)
        setLoading(false)
      })
      .catch(() => {
        alert('Unable to retrive children info')
      })
  }

  const handleDisableForm = () => {
    if (disableForm && childInfo) {
      disableForm(childInfo.length === 0 ?? false)
    }
  }

  const handleGetUser = () => {
    getUser(user.id)
      .then((res) => {
        if (res.status === 200) {
          if (!res.data.silaUserAccountID) setIsSilaAccountCreated(false)
          else setIsSilaAccountCreated(true)
          setLoading(false)
        }
      })
      .catch(() => {
        alert('Error retrieving user')
        setLoading(false)
      })
  }

  const handleGetSilaaStatus = async () => {
    try {
      const res = await getSillaStatus(user.id)
      const data: SilaStatusResp = res.data
      if (res.status === 200) {
        setVerificationStatus(data.silaAccountVerificationStatus ?? null)
      }
    } catch (err: any) {
      setVerificationStatus('Server_Error')
    }
  }

  useEffect(() => {
    handleGetUser()
    getChildrenInfo()
    handleGetSilaaStatus()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleDisableForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childInfo])

  return (
    <div className="w-full bg-[#E3F2F2] min-h-full rounded-lg my-7 p-4">
      {showAddChildModal && (
        <>
          {/* <AddChildModal
          onClose={() => {
            setShowAddChildModal(false)
            getChildrenInfo()
          }}
          id={childID}
          childInfo={childInfo}
        /> */}

          <My529AddChildModal
            onClose={() => {
              setShowAddChildModal(false)
              getChildrenInfo()
            }}
            id={childID}
            childInfo={childInfo ?? []}
            isNotStepper
          />
        </>
      )}

      {loading && (
        <div className="flex items-center justify-center py-5">
          <LoadingSpinner color="#004F4E" />
        </div>
      )}

      {childInfo &&
        childInfo.map((child: any) => (
          <div
            key={child.minorAccountID}
            className="bg-white shadow-md p-3 rounded-lg mt-1 mb-4"
          >
            <div className="flex items-center">
              <p>{child.firstName}</p>
              <img
                src={PencilIcon}
                alt="PencilIcon"
                className="px-3 h-[18px] cursor-pointer"
                onClick={() => handleUpdateChild(child.minorAccountID)}
              />
            </div>
            <p className="text-primary mt-1 text-[15px]">
              {child.dateOfBirth
                ? calculateUserAge(child.dateOfBirth) + ', '
                : ''}
              {child.emailAddress ? child.emailAddress + ', ' : ''}
              {child.identifier ? child.identifier : ''}
            </p>
          </div>
        ))}

      {isSilaAccountCreated ? (
        verificationStatus === 'Passed' && (
          <div
            className="w-full border-none bg-[#C4E8E8] py-4 cursor-pointer flex items-center justify-center rounded-lg"
            onClick={() => {
              setChildID(null)
              setShowAddChildModal(true)
            }}
          >
            <img src={PlusIcon} alt="Plus" className="h-[20px]" />
            <p className="text-primaryDark px-2">Add child</p>
          </div>
        )
      ) : (
        <div
          className="w-full border-none bg-[#C4E8E8] py-4 cursor-pointer flex items-center justify-center rounded-lg"
          onClick={() => {
            setChildID(null)
            setShowAddChildModal(true)
          }}
        >
          <img src={PlusIcon} alt="Plus" className="h-[20px]" />
          <p className="text-primaryDark px-2">Add child</p>
        </div>
      )}
    </div>
  )
}

export default ChildMain
