import React from 'react'
// @ts-ignore
import CloseIcon from '../../../assets/closeIcon.png'

type PropsType = {
  onClose?: () => void
  children: any
  isShare?: boolean
}

const ActivationModal = ({ children, onClose, isShare }: PropsType) => {
  return (
    <div>
      <div className="fixed h-full w-screen z-40 top-0 left-0">
        <div
          className="absolute bg-black opacity-80 h-screen w-screen"
          onClick={onClose}
        ></div>
        <div
          className={`absolute bg-white ${
            isShare ? 'w-[30%]' : 'w-[90%] md:w-[50%] 2xl:w-[25%]'
          } rounded-xl p-4`}
          style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
        >
          <div className="flex justify-end items-center pb-4">
            {onClose && (
              <img
                src={CloseIcon}
                alt="close icon"
                className="w-[13px] h-[13px] cursor-pointer"
                onClick={onClose}
              />
            )}
          </div>

          <div
            className={` w-full h-full ${
              isShare ? 'block' : 'flex items-center justify-center'
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivationModal
