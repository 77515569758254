import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getSeries,
  getUserDetails,
} from '../../../redux/actions/financialLiteracyActions'
import { RootReduxStateType } from '../../../types'
import { LoadingSpinner } from '../../Common/Loading'
import LayoutDefault from '../../Common/Layouts/Default'

const obj = [
  {
    url: '',
    title: '',
    time: 0,
    contentID: '',
    isPublic: '',
    image: '',
    userHasViewed: false,
    thumbnailUrl: '',
    isViewable: false,
  },
]

const Lessons = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [content, setContent] = useState(obj)
  const [series, setSeries] = useState<null | {
    title: string
    description: string
  }>(null)
  const [loading, setLoading] = useState(false)

  const types = [
    {
      title: t('Base.Audio'),
      color: '#00B2FF',
    },
    {
      title: t('Base.Video'),
      color: '#8D50DC',
    },
  ]

  useEffect(() => {
    getContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContent = async () => {
    setLoading(true)
    const res = await getSeries()

    res.forEach(async (data: any) => {
      setSeries(data)

      const res = await getUserDetails()
      setContent(res)
    })
    console.log('Content', series)
    setLoading(false)
  }

  const handleIsPublic = (data: any) => {
    data.isPublic && navigate(`/money-academy/post/${data.contentID}`)
  }

  const handleItemSelect = (data: any) => {
    //console.log('Logged in user', data)
    data.isViewable && navigate(`/money-academy/post/${data.contentID}`)
  }

  return (
    <>
      <LayoutDefault>
        <div className={styles.landing.wrapper}>
          <div className="container mx-auto px-[20px]">
            <div className="flex justify-between items-center">
              <div className="md:mt-6 mt-4">
                <button
                  onClick={() => navigate('/money-academy')}
                  className="mb-6 flex justify-center "
                >
                  <img
                    src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/back.svg"
                    className="mr-2"
                    alt="image"
                  />
                  back
                </button>
                <h1 className={styles.landing.title}>{series?.title}</h1>
                <p className="opacity-40 mb-6 text-[11px] font-bold leading-[13.2px]">
                  COLLECTION
                </p>
                <p>{series?.description}</p>
              </div>
              <img
                src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/grad_hat.svg"
                alt="Grad Hat"
                className="w-[100px] h-[100px]"
              />
            </div>
          </div>
          <div className={styles.wave.wrapper}>
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
              className={styles.wave.svg}
            >
              <path
                d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                className={styles.wave.path}
              ></path>
            </svg>
          </div>
        </div>

        <div className={styles.content.wrapper}>
          {loading ? (
            <div className="flex justify-center w-full h-[120px]">
              <LoadingSpinner color="#0E6D6C" size={8} />
            </div>
          ) : (
            <div className="mb-[100px] grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {content.map((lesson) => (
                <div key={lesson.contentID}>
                  <div
                    className={styles.content.slideWrapper}
                    onClick={() => {
                      user.token
                        ? handleItemSelect(lesson)
                        : lesson.isPublic && handleIsPublic(lesson)
                    }}
                  >
                    <div className={styles.content.topBanner}>
                      <div className="flex justify-between">
                        <div className={styles.content.time}>
                          Lesson {lesson.contentID}
                        </div>

                        {lesson.userHasViewed && (
                          <div
                            className={styles.content.completed}
                            style={{ background: '#22c55e' }}
                          >
                            Completed
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={styles.content.image}
                      style={{ backgroundImage: `url(${lesson.thumbnailUrl})` }}
                    >
                      {user.token
                        ? !lesson.isViewable && (
                            <div className={styles.content.isViewableFlag}>
                              Complete prior lessons to unlock this lesson
                            </div>
                          )
                        : !lesson.isPublic && (
                            <div className={styles.content.overlay}>
                              Login to view content
                            </div>
                          )}
                    </div>
                    <div className={styles.content.types}>
                      {types.map((type, i) => (
                        <div
                          key={i}
                          className={`${styles.content.type}`}
                          style={{ background: type.color }}
                        >
                          {type.title}
                        </div>
                      ))}
                    </div>
                  </div>
                  <p className={styles.content.text}>{lesson.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </LayoutDefault>
    </>
  )
}

const styles = {
  landing: {
    wrapper: 'relative bg-[#7FE5E4] pt-[87px] pb-[60px] mb-[32px]',
    container: 'container mx-auto px-[20px] flex pr-0 items-center mb-[25px]',
    title: 'text-[48px] mb-[24px] font-semibold text-[#004F4E] leading-[43px]',
    desc: 'text-[16px] text-[#004F4E] mb-[32px] leading-[150%]',
    dolphin: 'block ml-auto',
    small: 'block text-center uppercase text-[#004F4E] opacity-[60%] font-bold',
    action: 'container px-[20px] w-[310px] mb-[40px]',
    actionBtn: 'btn btn--big btn--white btn--shadow mb-[16px]',
  },
  wave: {
    wrapper:
      'absolute bottom-0 left-0 overflow-hidden line-height leading-[0px] w-[100%]',
    svg: 'relative block h-[30px] w-[calc(100%_+_1.3px)]',
    path: 'fill-white',
  },

  waveline: 'block w-[100%] mb-[32px]',
  content: {
    title:
      'text-[24px] mb-[24px] font-semibold text-primaryDark leading-[120%]',
    wrapper: 'container mx-auto px-[20px] overflow-hidden mb-[32px]',
    slick: 'md:w-[100%] w-[120%] md:w-[100%] flex',
    slide: '',
    slideWrapper: 'py-[11px]  mb-[10px] cursor-pointer ',
    image:
      'bg-cover bg-center bg-no-repeat w-[100%] h-[112px] md:w-[214px] md:h-[120px] xl:w-[370px] xl:h-[250px] rounded-[10px]', //192px
    time: 'font-bold uppercase text-[11px] text-primaryDark rounded-[10px] px-[8px] py-[4px] bg-accent leading-[13px]',
    completed:
      'font-bold uppercase text-[11px] text-white rounded-[10px] mx-2 px-[8px] py-[4px] leading-[13px]',
    topBanner: 'z-20 absolute translate-y-[-50%] r-16 px-[8px] py-[4px] ',
    types: 'flex',
    type: 'mr-[4px] font-bold uppercase text-[11px] tracking-[0.04em] translate-y-[-50%] text-white px-[8px] py-[4px] rounded-[10px] leading-[13px]',
    text: '',
    overlay:
      'flex z-10 justify-center items-center h-full text-white bg-black opacity-60 rounded-[10px]',
    isViewableFlag:
      'z-10 text-center flex justify-center items-center h-full text-white bg-black opacity-60 rounded-[10px]',
  },
}

export default Lessons
