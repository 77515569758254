import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import {
  getUser,
  updateEmail,
  verifyEmailAPI,
} from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { validateEmail } from '../../../utilities/generalUtilities'
import SafeAreaView from '../../Common/SafeAreaView'
import Toast from '../../Common/Toast'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepThree: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [userEmailFromAPI, setUserEmailFromAPI] = useState<string | null>('')

  useEffect(() => {
    if (alpaca.user.email && validateEmail(alpaca.user.email))
      setDisabled(false)
    else setDisabled(true)
  }, [alpaca.user.email])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(alpacaStepperUpdateUser(key, value === '' ? null : value))
  }

  const handleNext = () => {
    setDisabled(true)

    // If email is changed send verification email and update email
    if (userEmailFromAPI !== alpaca.user.email) {
      updateEmail(user.id, alpaca.user.email ?? '').then((res) => {
        setDisabled(false)
        verifyEmailAPI()
        dispatch(increamentAlpacaStepperStep())
      })
      return
    }

    setDisabled(false)
    dispatch(increamentAlpacaStepperStep())
  }

  useEffect(() => {
    setLoading(true)
    getUser(user.id).then((res) => {
      setUserEmailFromAPI(res.data.emailAddress)
      dispatch(alpacaStepperUpdateUser('email', res.data.emailAddress))
      setLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="font-semibold text-[17px]">Email Address</p>

        {loading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-4">
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="email"
                aria-label="email"
                value={alpaca.user.email ?? ''}
              />
              <label>Email Address</label>
            </div>
          </div>
        )}

        <Toast
          styles={'primary'}
          description={
            'If you change your email, you will need to verify the new email before we can activate your account.'
          }
        />

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={disabled || loading}
        handleNext={handleNext}
      />
    </div>
  )
}

export default AlpacaStepThree
