import React, { useEffect, useState } from 'react'
import { Range } from 'react-range'
import { useDispatch } from 'react-redux'
import { activationRegisterUpdateGoal } from '../../../../redux/actions/activationActions'

const MIN_YEAR: number = 2024
const MAX_YEAR: number = 2064
const DEFAULT_VALUE: number = 30
const CHANGE_PERCENTAGE: number = (MAX_YEAR - MIN_YEAR) / 100

const GoalTimeline: React.FC = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState<number[]>([DEFAULT_VALUE])
  const [year, setYear] = useState(DEFAULT_VALUE * CHANGE_PERCENTAGE)

  const handleSliderChange = (values: number[]) => {
    const value = (values[0] * CHANGE_PERCENTAGE).toFixed()
    if (parseInt(value) < 1) return

    setYear(parseInt(value))
    setValues(values)

    dispatch(
      activationRegisterUpdateGoal('timeline', (MIN_YEAR + year).toString())
    )
  }

  useEffect(() => {
    dispatch(
      activationRegisterUpdateGoal('timeline', (MIN_YEAR + year).toString())
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex items-center py-[60px]">
      <p className="pr-5 text-[15px] font-thin text-primary">{MIN_YEAR}</p>

      <Range
        step={0.1}
        min={0}
        max={100}
        values={values}
        onChange={handleSliderChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '12px',
              width: '100%',
              backgroundColor: '#87AEAE',
              borderRadius: 100,
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '28px',
              width: '28px',
              backgroundColor: '#0E6D6C',
              borderRadius: 100,
              position: 'relative',
              outline: 'none',
            }}
          >
            <p className="absolute -top-8 -translate-x-[7px]  font-semibold text-[18px]">
              {MIN_YEAR + year}
            </p>

            <p className="absolute -bottom-8 -translate-x-[10px] w-[100px]  font-thin text-[14px]">
              In {year} {year === 1 ? 'year' : 'years'}
            </p>
          </div>
        )}
      />
      <p className="pl-5 text-[15px] font-thin text-primary">{MAX_YEAR}</p>
    </div>
  )
}

export default GoalTimeline
