import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PinInput } from 'react-input-pin-code'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LOG_IN } from '../../../redux/actions/action.types'
import { validateMultiFactorAuth } from '../../../redux/actions/generalActions'
import {
  getUserAge,
  getUserFromToken,
} from '../../../utilities/generalUtilities'
import Modal from '../Partials/Modal'

type PropsType = {
  onSuccess: (e?: any) => void
  openModal: boolean
  setOpenModal: (x: boolean) => void
}

const TwoFactorAuthentication: React.FC<PropsType> = ({
  openModal,
  setOpenModal,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const [values, setValues] = useState(['', '', '', '', '', ''])
  const [loading, _] = useState(false)

  // const handleValidate = async (e: any) => {
  //   const code = values.join('')

  //   values.join('').length === 6 &&
  //     (await validateMultiFactorAuth(code, navigate)
  //       .then((res: any) => {
  //         if (res.status === 200) {
  //           const {
  //             UserName: userName,
  //             UserAccountID: id,
  //             is2FAEnabled: is2FA,
  //             DateOfBirth,
  //           } = getUserFromToken(res.data.accessToken)
  //           setLoading(false)

  //           dispatch({
  //             type: LOG_IN,
  //             payload: {
  //               id,
  //               userName,
  //               userAge: getUserAge(dayjs(DateOfBirth).format('YYYY-MM-DD')),
  //               emailAddress: '',
  //               token: res.data.accessToken,
  //               is2FAEnabled: is2FA,
  //             },
  //           })

  //           onSuccess()
  //           setOpenModal(false)
  //           setValues(['', '', '', '', '', ''])
  //         } else {
  //           setLoading(false)
  //           setErrorMessage('Your code is not valid, please try again')
  //         }
  //       })
  //       .catch((err) => console.log('Failed to validate')))
  // }

  const handleClear = () => {
    setErrorMessage('')
    setValues(['', '', '', '', '', ''])
  }

  // Commented out temporarily to disable 2FA
  // useEffect(() => {
  //   values.join('').length === 6 && handleValidate(values.join(''))
  // }, [values])

  return (
    <div className="absolute top-0 left-0">
      <Modal onLogin={false} openModal={openModal} setOpenModal={setOpenModal}>
        <div className="w-[80%] flex flex-col items-center">
          <p className="text-lg mb-4">{t('2FA.ModalTitle')}</p>
          <p className="text-sm text-red-500 mb-2">{errorMessage}</p>

          <PinInput
            values={values}
            size="md"
            placeholder=""
            onChange={(value, index, values) => setValues(values)}
            autoComplete="off"
            autoFocus
            focusBorderColor="#0E6D6C"
            validBorderColor={errorMessage ? 'rgb(220,53,69)' : '#0E6D6C'}
            disabled={loading}
          />

          <div className="md:flex justify-between my-6 md:w-[80%] w-full">
            <button
              className="btn btn--gray py-4 w-full cursor-pointer"
              onClick={handleClear}
            >
              {t('2FA.Clear')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TwoFactorAuthentication
