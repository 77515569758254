import React from 'react'

type PropsType = {
  title: string
  description?: string
  BtnText?: string
  onSubmit: () => void
  onCancel: () => void
  icon: string
  isListRequired?: boolean
  nextSteps?: Array<string>
}

// This is also used in the KYC failure
const InformationModal: React.FC<PropsType> = ({
  title,
  description,
  icon,
  BtnText,
  isListRequired,
  nextSteps,
  onCancel,
  onSubmit,
}) => {
  return (
    <>
      <div className="block fixed bg-[#021212BF] w-[100%] h-[100vh] top-0 left-0 opacity-95 z-[100]"></div>
      <>
        <div
          data-test="quiz-modal"
          className="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-[90%] md:w-[376px] lg:w-[480px] min-h-[200px] max-h-[90%]  opacity-100 rounded-[15px] flex flex-col z-[101]"
        >
          <div className="relative w-full h-full p-[16px]">
            {/* <div className="absolute top-4 right-4">
              <img
                src={Close}
                alt="Close"
                className="pb-4"
                onClick={onCancel}
              />
            </div> */}

            <div className="flex flex-col justify-center items-center pt-4">
              <img
                src={icon}
                alt="Close"
                className="w-[90px] h-[90px] object-contain"
                onClick={onCancel}
              />
              <h4 className="mt-[16px] text-center mb-[24px] text-primary font-semibold text-[24px] leading-[29px]">
                {title}
              </h4>

              {description && (
                <p className="font-normal text-center text-[16px] leading-[24px] mb-[40px]">
                  {description}
                </p>
              )}

              {isListRequired && (
                <div className="mb-[40px]">
                  <p className="font-normal text-center text-[16px] leading-[24px]">
                    Next steps:
                  </p>
                  <ul className="list-disc w-[80%] mx-auto">
                    {nextSteps &&
                      nextSteps.map((step, index) => (
                        <li key={index} className="py-1">
                          {step}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>

            <button
              className="btn btn--dark w-full p-[16px]"
              onClick={onSubmit}
            >
              {BtnText ? BtnText : 'Confirm'}
            </button>
          </div>
        </div>
      </>
    </>
  )
}

export default InformationModal
