import React from 'react'
import { useTranslation } from 'react-i18next'
import Navigation from '../../../Common/Navigation'

const ResetPasswordFailed = () => {
  const { t } = useTranslation()
  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <h1>{t('User.VerificationCodeFailed')}</h1>
          <p>{t('User.VerificationCodeTryAgain')}</p>
        </div>
      </Navigation>
    </>
  )
}

export default ResetPasswordFailed
