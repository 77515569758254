import ActivationsHome from '../components/Activations'
import ActivationRegister from '../components/Activations/Registration'
import MainAlpacaStepper from '../components/Alpaca'
import LegalPage from '../pages/Legal'
import TakeAPicture from '../components/Common/DocumentUpload/Camera/index'
import MoneyAcademy from '../components/FinancialLiteracy'
import Post from '../components/FinancialLiteracy/post'
import Settings from '../components/SootchyProfile/Settings'
import ConnectedBanks from '../components/SootchyProfile/Settings/ConnectedBanks'
import WalletSettings from '../components/SootchyProfile/Settings/WalletSettings'
import MainSetupSavingsAccount from '../components/Sila/SetupSavingsAccount'
import VerifySavingsAccount from '../components/Sila/SetupSavingsAccount/Verification'
import UpdateSavingsAccount from '../components/Sila/SetupSavingsAccount/Verification/KYCFailure/UserInformation'
import VerifyEmail from '../components/SootchyProfile/AccountManagement/EmailVerification'
import EmailVerificationFailed from '../components/SootchyProfile/AccountManagement/EmailVerification/EmailVerificationFailed'
import EmailVerificationSucess from '../components/SootchyProfile/AccountManagement/EmailVerification/EmailVerificationSucess'
import CheckVerificationCode from '../components/SootchyProfile/AccountManagement/ResetPassword/CheckVerificationCode'
import ResetPasswordFailed from '../components/SootchyProfile/AccountManagement/ResetPassword/ResetPasswordFailed'
import SendVerificationCodeSuccess from '../components/SootchyProfile/AccountManagement/ResetPassword/SendVerificationCodeSuccess'
import UpdatePassword from '../components/SootchyProfile/AccountManagement/ResetPassword/UpdatePassword'
import Profile from '../components/SootchyProfile/ProfileManagement'
import ChildrenDetails from '../components/SootchyProfile/ProfileManagement/ChildrenDetails'
import ContactDetails from '../components/SootchyProfile/ProfileManagement/ContactDetails'
import OccupationDetails from '../components/SootchyProfile/ProfileManagement/OccupationDetails'
import PasswordDetails from '../components/SootchyProfile/ProfileManagement/PasswordDetails'
import PersonalDetails from '../components/SootchyProfile/ProfileManagement/PersonalDetails'
import AllTransactions from '../components/Sila/Transactions'
import TransactionDetails from '../components/Sila/Transactions/TransactionDetails'
import Wallets from '../components/Sila/Wallet'
import HelpCenter from '../pages/HelpCenter'
import Login from '../pages/Login'
import SignOut from '../pages/LogOut'
import MultiFacorAuthentication from '../pages/MultiFactorAuthentication'
import ResetPassword from '../components/SootchyProfile/AccountManagement/ResetPassword'
import SignUp from '../components/SootchyProfile/Signup'
import SetupMy529Account from '../components/My529/SetupMy529Account'
// @ts-ignore
import Lessons from '../components/FinancialLiteracy/series/index'
import WalletDetails from '../components/Sila/Wallet/WalletDetails'
import My529 from '../components/My529'
import PrivacyPolicy from '../components/Legal/PrivacyPolicy'
import TermsOfUse from '../components/Legal/TermsOfUse'
import CustomerRelationshipSummary from '../components/Legal/CustomerRelationshipSummary'
import CookiePolicy from '../components/Legal/CookiePolicy'
import RequestForDeletion from '../components/RequestForDeletion'

type RouteType = {
  path: string
  element: any
  protected: boolean
}

export const routes: RouteType[] = [
  {
    path: '/',
    //element: Wallets,
    element: My529,
    protected: true,
  },
  {
    path: '/signup',
    element: SignUp,
    protected: false,
  },
  {
    path: '/signup/:invitationId',
    element: SignUp,
    protected: false,
  },
  {
    path: '/login',
    element: Login,
    protected: false,
  },
  {
    path: '/logout',
    element: SignOut,
    protected: false,
  },
  {
    path: '/reset-password',
    element: ResetPassword,
    protected: false,
  },
  {
    path: '/verification-code-success',
    element: SendVerificationCodeSuccess,
    protected: false,
  },
  {
    path: '/verify-code',
    element: CheckVerificationCode,
    protected: false,
  },
  {
    path: '/confirm-password-reset',
    element: UpdatePassword,
    protected: false,
  },
  {
    path: '/reset-password-failed',
    element: ResetPasswordFailed,
    protected: false,
  },
  {
    path: '/Verify',
    element: VerifyEmail,
    protected: false,
  },
  {
    path: '/email-verification-successful',
    element: EmailVerificationSucess,
    protected: false,
  },
  {
    path: '/email-verification-failed',
    element: EmailVerificationFailed,
    protected: false,
  },
  {
    path: '/profile/personal-details',
    element: PersonalDetails,
    protected: true,
  },
  {
    path: '/profile/children-details',
    element: ChildrenDetails,
    protected: true,
  },
  {
    path: '/profile/update-password',
    element: PasswordDetails,
    protected: true,
  },
  {
    path: '/profile/contacts',
    element: ContactDetails,
    protected: true,
  },
  {
    path: '/profile/school',
    element: OccupationDetails,
    protected: true,
  },
  {
    path: 'profile',
    element: Profile,
    protected: true,
  },
  {
    path: '/help/:id',
    element: HelpCenter,
    protected: false,
  },
  {
    path: '/money-academy',
    element: MoneyAcademy,
    protected: true,
  },
  {
    path: `/money-academy/series/content`,
    element: Lessons,
    protected: true,
  },
  {
    path: '/money-academy/post/:id',
    element: Post,
    protected: false,
  },
  {
    path: '/security/mfa',
    element: MultiFacorAuthentication,
    protected: true,
  },
  {
    path: '/setup-savings',
    element: MainSetupSavingsAccount,
    protected: true,
  },
  {
    path: '/wallet',
    element: Wallets,
    protected: true,
  },
  {
    path: '/upload-by-camera',
    element: TakeAPicture,
    protected: true,
  },
  {
    path: '/savings-account-verification',
    element: VerifySavingsAccount,
    protected: true,
  },
  {
    path: '/savings-account-verification/user-details',
    element: UpdateSavingsAccount,
    protected: true,
  },
  {
    path: '/wallet/:id',
    element: WalletDetails,
    protected: true,
  },
  {
    path: '/settings/:walletName',
    element: Settings,
    protected: true,
  },
  {
    path: '/settings/connected-banks/:walletName',
    element: ConnectedBanks,
    protected: true,
  },
  {
    path: '/settings/wallet-settings/:walletName',
    element: WalletSettings,
    protected: true,
  },
  {
    path: '/transaction/:silawalletname/:transactionid',
    element: TransactionDetails,
    protected: true,
  },
  {
    path: '/transactions/:silawalletname',
    element: AllTransactions,
    protected: true,
  },
  {
    path: '/legal',
    element: LegalPage,
    protected: false,
  },
  {
    path: '/alpaca',
    element: MainAlpacaStepper,
    protected: true,
  },
  {
    path: '/update-password',
    element: UpdatePassword,
    protected: false,
  },
  {
    path: '/activation',
    element: ActivationsHome,
    protected: false,
  },
  {
    path: '/activation/:activationEvent',
    element: ActivationsHome,
    protected: false,
  },
  {
    path: '/activation/setup',
    element: ActivationRegister,
    protected: false,
  },
  {
    path: '/setup/my529',
    element: SetupMy529Account,
    protected: true,
  },
  {
    path: '/my529',
    element: My529,
    protected: true,
  },
  {
    path: '/privacyPolicy',
    element: PrivacyPolicy,
    protected: false,
  },
  {
    path: '/termsAndUse',
    element: TermsOfUse,
    protected: false,
  },
  {
    path: '/customerRelationshipSummary',
    element: CustomerRelationshipSummary,
    protected: false,
  },
  {
    path: '/cookiePolicy',
    element: CookiePolicy,
    protected: false,
  },
  {
    path: '/request-for-deletion',
    element: RequestForDeletion,
    protected: false,
  },
]
