import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../../api'
import {
  getRequiredDocumentsFromSilla,
  storeUploadData,
  updateUploadStep,
} from '../../../redux/actions/generalActions'
import { getUser } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { UserAPIType } from '../../../types/user'
import SafeAreaView from '../SafeAreaView'
import Toast from '../Toast'
import UploadCard from '../Partials/UploadCard'
import SilaSetupBottom from '../../Sila/SetupSavingsAccount/SilaSetupBottom'
import DocumentUploadLoader from '../SkeletonLoaders/DocumentUploadLoader'
import UserDetailsLoader from '../SkeletonLoaders/UserDetailsLoader'
import LoadUser from './BrowseFiles/LoadUser'
import UploadModal from './BrowseFiles/UploadModal'
import CameraUploadComponent from './Camera/CameraUploadComponent'
import { createAlertAction } from '../../../redux/actions/alertActions'

const DocumentUpload = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const general = useSelector((state: RootReduxStateType) => state.general)

  const [loadedUser, setLoadedUser] = useState<UserAPIType>()
  const [selectedDoc, setSelectedDoc] = useState<any>()
  const [stepsToUpload, setStepsToUpload] = useState<number>(0)
  const [requiredSteps, setRequiredSteps] = useState<number>(0)
  const [activeStep, setActiveStep] = useState<number>(-1)
  const [requiredCategories, setRequiredCategories] = useState<any>([])
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showCameraModal, setShowCameraModal] = useState<boolean>(false)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)

  const [showToast, setShowToast] = useState<boolean>(false)

  const timeOutNotification = () => {
    const timer = setTimeout(() => {
      setShowToast(false)

      if (requiredSteps <= 1) {
        window.location.reload()
        setActiveStep(0)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }

  const getUserInfo = async () => {
    setUserLoading(true)
    const userResponse = await getUser(user.id)

    if (userResponse.status === 200 && userResponse) {
      setLoadedUser(userResponse.data)
      //console.log('User Information', userResponse.data)
    }
    setUserLoading(false)
  }

  const displayRequiredDocs = async () => {
    setDocumentsLoading(true)
    const response: any = await getRequiredDocumentsFromSilla()

    const result = response
      .reduce(function (res: any, currentValue: any) {
        if (res.indexOf(currentValue.documentList) === -1) {
          res.push(currentValue.documentList)
        }
        return res
      }, [])
      .map(function (documentList: any) {
        return {
          // documentList: documentList,
          obj: response
            .filter(function (_el: any) {
              return _el.documentList === documentList
            })
            .map(function (_el: any) {
              return _el
            }),
        }
      })

    //console.log('Required Documents', result)

    setRequiredSteps(result.length)
    setRequiredCategories(result)
    setDocumentsLoading(false)
  }

  const handleDocumentAdd = (data: any) => {
    setSelectedDoc(data)
    dispatch(storeUploadData(data))
    //console.log('File Selected', data)
  }

  const handleFileUpload = async (data: any) => {
    try {
      let formData = new FormData()
      let count = 0

      //console.log('Data to upload', data)

      if (data) {
        if (data.type !== 'text/plain') {
          if (loadedUser) {
            let silaUserID: any = loadedUser.silaUserAccountID

            formData.append('files', data)
            formData.append('SilaUserAccountID', silaUserID)
            formData.append('SilaDocumentType', selectedDoc.silaDocumentType)
            formData.append('CreatedUserAccountID', user.id)
            formData.append('SourceProcess', 'WebApplication')

            const response: any = await api.post(
              'SilaDocument/Upload',
              formData
            )

            if (response.status === 200) {
              console.log('Response from Upload', response)
              setShowModal(false)
              setShowToast(true)
              timeOutNotification()

              // to skip documents which satisfy other conditions

              if (activeStep < requiredSteps) {
                requiredCategories.map((category: any) =>
                  category.obj.map((item: any) => {
                    if (
                      selectedDoc.silaDocumentTypeID === item.silaDocumentTypeID
                    ) {
                      setActiveStep((currentValue) => currentValue + 1)
                      setStepsToUpload((currentStep) => currentStep + 1)
                    }
                  })
                )
              } else {
                handleAPICall()
              }
            }
          }
        } else {
          dispatch(
            createAlertAction({
              type: 'DANGER',
              title: 'Error Occured',
              message:
                'Input not in an expected format, please upload a file in JPG, PNG, or PDF format.',
              autoClose: true,
              autoCloseDuration: 3000,
            })
          )
        }
      }
    } catch (err) {
      //console.log('Failed to upload file', error.message)
      //setErrorMessage('Failed to upload file')
      //setShowErrorToast(true)
      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Error Occured',
          message: 'Failed to upload file, please try again',
          autoClose: true,
          autoCloseDuration: 3000,
        })
      )
    }
  }

  const handleAPICall = async () => {
    try {
      setBtnDisabled(false)
      const response: any = await api.post('/KYC/Request', {
        SourceProcess: 'WebApplication',
      })

      console.log('Uplaod and request KYC', response)
    } catch (error) {
      console.log('Error')
    }
  }

  const handleBrowseFiles = (e: any) => {
    if (selectedDoc.isFrontAndBackRequired) {
      if (e.target.files.length === 2) {
        console.log('Call Upload API', e.target.files.length)
        handleFileUpload(e.target.files[0])
      } else {
        console.log('Ask User to upload again', e.target.files.length)
        dispatch(
          createAlertAction({
            type: 'DANGER',
            title: 'Error Occured',
            message: 'Front and Back required, Please try again',
            autoClose: true,
            autoCloseDuration: 3000,
          })
        )
      }
      setShowModal(false)
    } else {
      console.log('Front and Back not required, Call Upload API')
      handleFileUpload(e.target.files[0])
    }

    //console.log('Selected Doc', selectedDoc)
  }

  const handleNext = () => {
    if (activeStep < 0) {
      if (requiredSteps === 0) {
        setActiveStep(0)
      } else {
        setActiveStep(1)
      }
    } else {
      if (activeStep === requiredSteps) {
        console.log('Male API Call')
        //handleFileUpload(e.target.files[0])
      } else {
        setActiveStep((currentValue) => currentValue + 1)
      }
    }
  }

  const handleCancel = () => {
    if (activeStep < 0) {
      navigate(-1)
    } else {
      setActiveStep((currentValue) => currentValue - 1)
    }
  }

  useEffect(() => {
    getUserInfo()
    displayRequiredDocs()

    if (general.uploadDocumentsByCamera) {
      if (requiredSteps > activeStep) {
        setActiveStep(1)
      } else {
        setActiveStep(0)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(updateUploadStep(activeStep))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return (
    <>
      <SafeAreaView>
        {activeStep < 0 ? (
          <LoadUser />
        ) : userLoading && documentsLoading ? (
          <DocumentUploadLoader />
        ) : requiredSteps === 0 ? (
          <>
            <div className="flex items-center space-x-2 py-[12px]">
              <img src="/assets/images/warning_icon.svg" alt="warning" />
              <p className="font-semibold text-[18px] leading-[22.31px]">
                We need to prove your identity
              </p>
            </div>

            <div className="mb-4 bg-[#90F2B8] text-[#1F623A] p-[20px] text-center rounded-[10px]">
              <p>
                You have already uploaded the required files, press 'Finish' to
                request a KYC check again.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center space-x-2 py-[12px]">
              <img src="/assets/images/warning_icon.svg" alt="warning" />
              <p className="font-semibold text-[18px] leading-[22.31px]">
                We need to prove your identity
              </p>
            </div>

            <div className="mb-[16px]">
              <p className="mb-[24px]">
                Nothing to worry about. Our validation system requires a few
                documents from you to prove you’re a real person. This is a
                standard procedure.
              </p>
              <p>We need these documents:</p>
              <p>
                Please upload any one of these documents from the following
                steps
              </p>

              <p className="py-2 font-semibold text-[14px]">
                STEP {activeStep} OF {requiredSteps}
              </p>

              <div className="space-y-2 mb-4">
                {requiredCategories ? (
                  requiredCategories[stepsToUpload].obj.map(
                    (item: any, index: any) => (
                      <div key={index} onClick={() => handleDocumentAdd(item)}>
                        <UploadCard
                          title={item.silaDocumentTypeLabel}
                          instructions={item.instructions}
                          handleAdd={() => setShowModal(!showModal)}
                          buttonLabel="Add"
                        />
                      </div>
                    )
                  )
                ) : (
                  <UserDetailsLoader />
                )}
              </div>
            </div>

            {showModal && (
              <UploadModal
                documentToUpload={selectedDoc}
                enableMultipleUploads={selectedDoc.isFrontAndBackRequired}
                handleFileChange={handleBrowseFiles}
                onCancel={() => setShowModal(false)}
                //onTakePicture={() => navigate('/upload-by-camera')}
                onTakePicture={() => {
                  setShowModal(false)
                  setShowCameraModal(true)
                }}
              />
            )}

            {showCameraModal && loadedUser && (
              <CameraUploadComponent
                loadedUser={loadedUser}
                requiredSteps={requiredSteps}
                onCancel={() => setShowCameraModal(false)}
              />
            )}

            <div
              className={`lg:w-[37%] lg:mx-auto fixed inset-x-0 z-40 top-0  ${
                showToast ? 'translate-x-0' : '-translate-x-full'
              } ease-in-out duration-300 transition-transform`}
            >
              {showToast && (
                <div className="absolute inset-x-0 top-0 z-40 mx-auto">
                  <Toast
                    styles="plain"
                    description="Awesome! Your documents have been uploaded successfully."
                    className="my-5 mx-2"
                  />
                </div>
              )}
            </div>
          </>
        )}
      </SafeAreaView>

      <SilaSetupBottom
        handleNext={
          activeStep === requiredSteps ? () => navigate('/wallet') : handleNext
        }
        handleCancel={handleCancel}
        disabled={
          activeStep > 0 && activeStep === requiredSteps
            ? btnDisabled
              ? true
              : false
            : false
        }
        handleNextBtn={activeStep === requiredSteps ? 'Finish' : 'Next'}
      />
    </>
  )
}

export default DocumentUpload
