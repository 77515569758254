import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navigation from '../components/Common/Navigation'
import GetSootchyAssistance from '../components/SootchyProfile/ProfileManagement/Assistance'
import SootchyFAQs from '../components/SootchyProfile/ProfileManagement/FAQs'

const HelpCenter: React.FC = () => {
  const params = useParams()
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

  useEffect(() => {
    if (params.id === 'assitance') {
      setActiveTabIndex(1)
    } else {
      setActiveTabIndex(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const TabData = [
    { label: 'FAQ', content: <SootchyFAQs /> },
    { label: 'Assistance', content: <GetSootchyAssistance /> },
  ]

  return (
    <>
      <Navigation>
        <div className="w-[90%] m-auto lg:w-[570px] py-[32px]">
          <div className="flex space-x-4">
            {TabData.map((tab, index) => (
              <div
                key={index}
                className={`cursor-pointer py-2 font-semibold text-[1.5rem] border-b-4  ${
                  index === activeTabIndex
                    ? 'text-primaryDark  border-primary'
                    : 'text-[#87AEAE] border-none'
                }`}
                onClick={() => setActiveTabIndex(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>

          <div className="mt-[32px]">{TabData[activeTabIndex].content}</div>
        </div>
      </Navigation>
    </>
  )
}

export default HelpCenter
