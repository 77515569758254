import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
} from '../../redux/actions/alpacaActions'
import { getUser } from '../../redux/actions/userActions'
import { RootReduxStateType } from '../../types'
import TelephoneNumber from '../Common/TelephoneNumber'
// @ts-ignore
import CloseIcon from '../../assets/closeIcon.png'
// @ts-ignore
import ContactSupport from '../../assets/ContactSupport.png'

const AlpacaAssistenceModal = () => {
  const dispatch = useDispatch()

  const [disabled, setDisabled] = useState<boolean>(true)
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    alpaca.user.phoneNumber ?? null
  )
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (phoneNumber) setDisabled(false)
    else setDisabled(true)
  }, [phoneNumber])

  const onClose = () => {
    dispatch(alpacaStepperUpdateAssistenceNeeded(false))
  }

  useEffect(() => {
    getUser(user.id).then((res) => {
      setLoading(true)
      if (res.status === 200) {
        dispatch(
          alpacaStepperUpdateUser('phoneNumber', res.data.telephoneNumber)
        )
      }

      setLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="fixed h-full w-screen z-50 top-0 left-0">
      <div
        className="absolute bg-black opacity-80 h-screen w-screen"
        onClick={onClose}
      ></div>

      <div
        className="absolute bg-white w-[88%] rounded-xl p-4 sm:w-[400px]"
        style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
      >
        <div className="flex justify-end items-center pb-1 cursor-pointer">
          <img
            src={CloseIcon}
            alt="close icon"
            className="w-[13px] h-[13px]"
            onClick={onClose}
          />
        </div>

        <img
          src={ContactSupport}
          alt="ContactSupport"
          className="w-[80px] m-auto"
        />
        <div className="text-center text-[17px] font-semibold py-3">
          Get Help
        </div>

        <p className="text-center w-[80%] m-auto">
          Leave us your phone number so our rep Jessica can call you and help
          set this up.
        </p>

        {loading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-6">
            <TelephoneNumber
              value={alpaca.user.phoneNumber ?? ''}
              onChange={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
            />
          </div>
        )}

        <button
          className="w-full border-none text-white bg-primary h-[50px]"
          disabled={disabled || loading}
        >
          Get help
        </button>
      </div>
    </div>
  )
}

export default AlpacaAssistenceModal
