import React from 'react'
import { useDispatch } from 'react-redux'
import { removeAlertAction } from '../../../redux/actions/alertActions'
import { AlertType } from '../../../types/alert'

const Alert = ({ id, type, title, message }: AlertType) => {
  const dispatch = useDispatch()

  const handleCloseAlert = (id: string) => {
    dispatch(removeAlertAction(id))
  }

  const findAlertColor = (type: 'DANGER' | 'SUCCESS' | 'WARNING' | 'INFO') => {
    switch (type) {
      case 'DANGER':
        return {
          backgroundColor: 'bg-red-600',
          borderColor: 'border-red-500',
        }

      case 'SUCCESS':
        return {
          backgroundColor: 'bg-green-500',
          borderColor: 'border-green-400',
        }

      case 'WARNING':
        return {
          backgroundColor: 'bg-yellow-500',
          borderColor: 'border-yellow-400',
        }

      case 'INFO':
        return {
          backgroundColor: 'bg-blue-600',
          borderColor: 'border-blue-500',
        }

      default:
        return {
          backgroundColor: 'bg-blue-600',
          borderColor: 'border-blue-500',
        }
    }
  }

  return (
    <div>
      <div
        data-test={`main-alert-${id}`}
        className={`${
          findAlertColor(type).backgroundColor
        } shadow-lg mx-auto w-[100%] md:w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block`}
        id="static-example"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-mdb-autohide="false"
      >
        <div
          className={`${
            findAlertColor(type).backgroundColor
          } flex justify-between items-center py-2 px-3 bg-clip-padding border-b ${
            findAlertColor(type).borderColor
          } rounded-t-lg`}
        >
          <p className="font-bold text-white flex items-center">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times-circle"
              className="w-4 h-4 mr-2 fill-current"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
              ></path>
            </svg>
            {title}
          </p>
          <div className="flex items-center">
            {/* <p className="text-white opacity-90 text-xs pr-2">11 mins ago</p> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="16"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: 'white' }}
              onClick={() => handleCloseAlert(id)}
              className="cursor-pointer"
              data-test={`main-alert-close-icon-${id}`}
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
            </svg>
          </div>
        </div>
        <div
          className={`p-3 ${
            findAlertColor(type).backgroundColor
          } rounded-b-lg break-words text-white`}
        >
          {message}
        </div>
      </div>
    </div>
  )
}

export default Alert
