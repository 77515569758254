import api from '../../api'

import {
  CONNECT_BANK,
  REPEAT_TRANSACTION,
  RESET_CONFIRMATION_DIALOG_OPTIONS,
  SHOW_CONFIRMATION_DIALOG,
  SHOW_CONFIRMATION_DIALOG_DATA,
  SHOW_CONFIRMATION_DIALOG_OPTIONS,
  SHOW_SLIDE_UP,
  TRANSACTIONS_WIDGET,
  UPDATE_TRANSACTION_PROMPT,
} from './action.types'

export const handleConfirmationDisplay =
  (value: string | boolean) => (dispatch: Function) => {
    dispatch({
      type: SHOW_CONFIRMATION_DIALOG,
      payload: value,
    })
  }

export const handleConfirmationOptions =
  (data: any) => (dispatch: Function) => {
    dispatch({
      type: SHOW_CONFIRMATION_DIALOG_OPTIONS,
      payload: data,
    })
  }

export const resetConfirmationDialogOptions = () => (dispatch: Function) => {
  dispatch({
    type: RESET_CONFIRMATION_DIALOG_OPTIONS,
  })
}

export const handleConfirmationDialogData =
  (data: any) => (dispatch: Function) => {
    dispatch({
      type: SHOW_CONFIRMATION_DIALOG_DATA,
      payload: data,
    })
  }

export const handleShowSlideup = (data: boolean) => (dispatch: Function) => {
  dispatch({
    type: SHOW_SLIDE_UP,
    payload: data,
  })
}

export const handleConnectBank = (data: boolean) => (dispatch: Function) => {
  dispatch({
    type: CONNECT_BANK,
    payload: data,
  })
}

export const handleTransactionPrompt =
  (data: string | null) => (dispatch: Function) => {
    dispatch({
      type: UPDATE_TRANSACTION_PROMPT,
      payload: data,
    })
  }

export const handleTransactionWidgetDisplay =
  (data: { [key: string]: boolean }) => (dispatch: Function) => {
    dispatch({
      type: TRANSACTIONS_WIDGET,
      payload: data,
    })
  }

export const handleRepeatTransactionAction =
  (data: boolean) => (dispatch: Function) => {
    dispatch({
      type: REPEAT_TRANSACTION,
      payload: data,
    })
  }

// Main Transactions

// Add Money from Bank to Wallet
export const handleAddMoneyFromBankToWallet = (
  LinkedAccountName: any,
  DestinationWalletName: any,
  AmountToIssue: string
) => {
  let formattedAmount: number = +parseFloat(AmountToIssue).toFixed(2)

  console.log('Money to add', Math.round(formattedAmount * 100.0))

  return api.post('SilaWallet/Add/Sila', {
    LinkedAccountName,
    DestinationWalletName,
    //AmountToIssue: parseInt(AmountToIssue) * 100,
    AmountToIssue: Math.round(formattedAmount * 100.0),
    SourceProcess: 'WebApplication',
    ChangeReason: 'Testing a five dollar transaction.',
  })
}

// Send Money from Wallet to Wallet
export const sendMoneyFromWalletToWallet = (
  sendFrom: any,
  sendTo: any,
  amountToSend: string
) => {
  let formattedAmount: any = parseFloat(amountToSend).toFixed(2)

  return api.post('/SilaWallet/Transfer/Sila', {
    SourceWalletName: sendFrom,
    DestinationWalletName: sendTo,
    //AmountToTransfer: parseInt(amountToSend) * 100,
    AmountToTransfer: Math.round(formattedAmount * 100),
    SourceProcess: 'WebApplication',
    ChangeReason: 'Testing a five dollar transaction.',
  })
}

// Send Money from Wallet to User
export const sendMoneyFromWalletToPerson = (
  sendFrom: any,
  sendTo: any,
  amountToSend: string
) => {
  let formattedAmount: any = parseFloat(amountToSend).toFixed(2)

  return api.post('/SilaWallet/Transfer/Sila/OtherUser', {
    SourceWalletName: sendFrom,
    DestinationUsername: sendTo,
    //AmountToTransfer: parseInt(amountToSend) * 100,
    AmountToTransfer: Math.round(formattedAmount * 100),
    SourceProcess: 'WebApplication',
    ChangeReason: 'Testing a five dollar transaction.',
  })
}

// Withdraw savings from Wallet to Bank
export const handleWithdrawMoneyFromWalletToBank = (
  walletName: any,
  LinkedAccountName: any,
  amountToAdd: string
) => {
  let formattedAmount: any = parseFloat(amountToAdd).toFixed(2)

  return api.post('/SilaWallet/Redeem/Sila', {
    SourceWalletName: walletName,
    LinkedAccountName: LinkedAccountName,
    //AmountToRedeem: parseInt(amountToAdd) * 100,
    AmountToRedeem: Math.round(formattedAmount * 100),
    SourceProcess: 'WebApplication',
    ChangeReason: 'Testing a five dollar transaction.',
  })
}

// Get latest local transactions

export const getLatestTransactions = async (silawalletid: number) => {
  try {
    const response: any = await api(
      `SilaWallet/${silawalletid}/TransactionsLocal`
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log('Unable to fetch transactions')
  }
}

export const getTransactionDetails = async (
  silawalletid: string,
  transactionid: string
) => {
  try {
    const response: any = await api(
      `/SilaWallet/${silawalletid}/Transaction/${transactionid}`
    )

    if (response.status === 200) {
      return response.data
    }

    //console.log('transaction response', response)
  } catch (error) {
    console.log('Unable to retrieve single transaction')
  }
}

export const handleWithdrawRequest = async (data: {
  Username: string
  UserAccountID: string
  FirstName: string
  LastName: string
  WithdrawalAmount: string
  WithdrawalReason: string
  WithdrawalDescription: string
  EmailAddress: string
  TelephoneNumber: string
}) => {
  try {
    const response: any = await api.post(`/SilaWallet/WithdrawRequest`, data)

    if (response.status === 202 || response.status === 200) {
      return true
    }
  } catch (err) {
    console.log('Unable to send withdraw request', err)
  }
}
