import React, { useEffect, useState } from 'react'
import { WalletType, BankType } from '../../../../../types/transactions'
// @ts-ignore
import BankStarIcon from '../../../../../assets/BankStarIcon.png'
// @ts-ignore
import Success from '../../../../../assets/Success.png'
// @ts-ignore
import BoldBottomIcon from '../../../../../assets/BoldBottomIcon.png'
import { PulseLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleConfirmationDialogData,
  handleConfirmationDisplay,
  handleConfirmationOptions,
  handleTransactionPrompt,
  handleTransactionWidgetDisplay,
  handleWithdrawRequest,
} from '../../../../../redux/actions/transactionsActions'
import { RootReduxStateType } from '../../../../../types'
import NewTwoFactorAuthentication from '../../../../Common/TwoFactorAuthentication/NewTwoFactorAuthentication'
import DropdownSelector from '../../../../Common/Partials/DropdownSelector'
import { UserAPIType } from '../../../../../types/user'
import TransactionNotAllowed from '../../../Transactions/TransactionNotAllowed'
import { PreventNegativeValues } from '../../../../Common/preventNegativeValues'

type Proptypes = {
  currentUser: UserAPIType | undefined
  currentWallet: WalletType
  defaultBank: BankType | undefined
  showConfirmation: (e: any) => any
  setDefaultBank: (e: any) => void
  banks: any
}

type WithdrawRequestTypes = {
  userName: string
  userId: string
  firstName: string
  lastName: string
  withdrawalAmount: string
  withdrawalReason: string
  withdrawalReasonDescription: string
  userEmail: string
  contactNumber: string
}

const RequestMoneyWithdrawToBank: React.FC<Proptypes> = ({
  currentUser,
  currentWallet,
  defaultBank,
  setDefaultBank,
  showConfirmation,
  banks,
}) => {
  const dispatch = useDispatch()
  const transactions = useSelector(
    (state: RootReduxStateType) => state.transactions
  )
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [amountToWithdraw, setAmountToWithdraw] = useState('')
  const [showMore, setShowMore] = useState<boolean>(false)
  const [transferLoading, setTransferLoading] = useState(false)
  const [isSuccess, setIsSucess] = useState(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [withdrawReason, setWithdrawReason] = useState<string>('')
  const [withdrawDescription, setWithdrawDescription] = useState<string>('')

  const withdrawOptions = [
    'I entered school',
    'I started a business ',
    'I am having financial difficulty',
  ]

  // const withdrawDescriptionOptions = [
  //   'Tell us about your school and decision',
  //   'Tell us about your business and decision',
  //   'Tell us about your problem and decision',
  // ]

  useEffect(() => {
    setIsSucess(false)
  }, [])

  useEffect(() => {
    if (transactions.confirmationDialogOptions.handleConfirm) {
      handleWithdrawTransaction()
    }
  }, [transactions.confirmationDialogOptions])

  const handleWithdrawMoney = () => {
    dispatch(handleConfirmationDisplay(true))
    dispatch(
      handleConfirmationDialogData({
        transactionType: 'redeem',
        sender: currentWallet.walletName,
        recipient: defaultBank?.accountName,
        amount: amountToWithdraw,
      })
    )

    const withdrawData = JSON.stringify({
      userName: currentUser?.userName,
      userId: currentUser?.userAccountID,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      withdrawalAmount: amountToWithdraw,
      withdrawalReason: withdrawReason,
      withdrawalReasonDescription: withdrawDescription,
      userEmail: currentUser?.emailAddress,
      contactNumber: currentUser?.telephoneNumber,
    })
    localStorage.setItem('withdrawObject', withdrawData)
  }

  const handleWithdrawTransaction = async () => {
    try {
      setTransferLoading(true)

      const withdrawJSON = localStorage.getItem('withdrawObject')

      if (withdrawJSON !== null) {
        const obj: WithdrawRequestTypes = JSON.parse(withdrawJSON)

        await handleWithdrawRequest({
          Username: obj.userName,
          UserAccountID: obj.userId,
          FirstName: obj.firstName,
          LastName: obj.lastName,
          WithdrawalAmount: obj.withdrawalAmount,
          WithdrawalReason: obj.withdrawalReason,
          WithdrawalDescription: obj.withdrawalReasonDescription,
          EmailAddress: obj.userEmail,
          TelephoneNumber: obj.contactNumber,
        })
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setTransferLoading(false)
      setIsSucess(true)
      localStorage.removeItem('withdrawObject')
    }
  }

  return (
    <>
      {openModal && (
        <NewTwoFactorAuthentication
          //onSuccess={handleRedeemMoney}
          onSuccess={handleWithdrawMoney}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}

      {isSuccess ? (
        <>
          <div className="flex flex-col items-center justify-center py-2">
            <img src={Success} className="w-[40px]" alt="check" />
            <p className="py-4 text-[24px] font-semibold text-textGreen">
              We will contact you very soon.
            </p>
            <p>
              Our team is here to help. To assist you with this withdraw and
              prevent fraud, we'll have a Sootchy financial success rep contact
              you shortly.
            </p>
          </div>
          <div className="py-2  self-center bg-white w-full sm:relative sm:w-full">
            <button
              className="w-full mx-1 py-4  border-2 border-[#E3F2F2]"
              onClick={() => {
                dispatch(handleTransactionPrompt(null))
                dispatch(
                  handleTransactionWidgetDisplay({
                    showTransactionModal: false,
                    showTransactionSlideUp: false,
                  })
                )

                setIsSucess(false)
                dispatch(
                  handleConfirmationOptions({
                    handleChange: false,
                    handleCancel: false,
                    handleConfirm: false,
                  })
                )
                setAmountToWithdraw('')
                window.location.reload()
              }}
            >
              Awesome
            </button>
          </div>
        </>
      ) : banks && banks.length > 0 ? (
        <>
          <div className="flex justify-between items-center mb-[27px]">
            <h3 className="py-4 text-[24px] font-semibold text-textGreen">
              Request withdrawal
            </h3>
          </div>
          <div className="space-y-2 mb-[16px]">
            <p>
              You can withdraw your wallet money into any of the bank accounts
              that you connected to Sootchy.
            </p>
            <p>
              Please note that these withdrawals might take a few days before
              the money reaches your bank account.
            </p>
          </div>

          {/** Withdraw form */}
          <div>
            <div className="block px-[1rem] py-[0.75rem] bg-[#E3F2F2] mb-[16px] rounded-[10px]">
              <div className="flex space-x-4 items-center">
                <p className="text-[#87AEAE] font-semibold leading-[120.93%] text-[24px]">
                  $
                </p>
                <input
                  type="number"
                  value={amountToWithdraw}
                  min="0"
                  onKeyPress={PreventNegativeValues}
                  onChange={(e) => setAmountToWithdraw(e.target.value)}
                  name="Amount"
                  aria-label="Amount"
                  className="w-full bg-[#E3F2F2] text-[36px] font-semibold focus: outline-0"
                />
              </div>
              <p className="text-[#87AEAE] text-[0.6875rem] leading-[1.03125rem]">
                Wallet balance: ${currentWallet.currentBalance}
              </p>
            </div>

            <div className="mb-[16px] bg-[#E3F2F2]  rounded-[10px] min-h-[40px] flex flex-col items-center">
              <div className="px-[16px] py-[8px] flex justify-between items-center w-full">
                <div className="flex  justify-between items-center ">
                  {defaultBank?.bankLogo ? (
                    <img
                      src={`data:image/png;base64,${defaultBank?.bankLogo}`}
                      alt="Bank Logo"
                      className="h-[32px]"
                    />
                  ) : (
                    <p className="">{defaultBank?.bankName}</p>
                  )}
                  <p className="text-[14px] px-3">
                    {`XXX$${defaultBank?.maskedAccountNumber}`}
                  </p>
                </div>

                <div className="flex items-center">
                  {banks &&
                    banks.map(
                      (bank: BankType, index: number) =>
                        bank.isDefault &&
                        bank === defaultBank && (
                          <div className="px-1 ">
                            <img src={BankStarIcon} alt={'BankStarIcon'} />
                          </div>
                        )
                    )}

                  <button
                    className="border-0 focus:outline-0"
                    onClick={() => setShowMore(!showMore)}
                  >
                    <img
                      src={BoldBottomIcon}
                      alt="BoldBottomIcon"
                      className="w-[10px]"
                    />
                  </button>
                </div>
              </div>

              <div
                className={` bg-white w-[99%] max-h-[240px] overflow-y-scroll  rounded-b-lg border-b-2 border-[#E3F2F2] px-[16px] mx-auto transform transition ease-in-out duration-1000 ${
                  showMore ? 'block' : 'hidden'
                }`}
              >
                {banks &&
                  banks.map((bank: BankType, index: number) => (
                    <div
                      key={index}
                      onClick={() => {
                        setDefaultBank(bank)
                        setShowMore(false)
                      }}
                      className="py-4 flex justify-between items-center border-b-2 border-[#C4E8E8] border-dashed"
                    >
                      <div className="flex justify-between items-center">
                        {bank.bankLogo ? (
                          <img
                            src={`data:image/png;base64,${bank.bankLogo}`}
                            alt="Bank Logo"
                            className="h-[32px]"
                          />
                        ) : (
                          <p className="">{bank.bankName}</p>
                        )}

                        <p className="text-[14px] px-3">
                          {`XXX${bank.maskedAccountNumber}`}
                        </p>

                        {bank.isDefault && (
                          <div className="px-1">
                            <img src={BankStarIcon} alt={'BankStarIcon'} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="mb-[16px]">
              <DropdownSelector
                title="Withdrawal reason"
                list={withdrawOptions}
                value={withdrawReason!}
                handleOnchange={(data: any) => setWithdrawReason(data)}
              />
            </div>

            {withdrawReason && (
              <div className="block mb-[34px]">
                <textarea
                  className="bg-[#E3F2F2] w-full px-3 py-3 rounded-[10px] transition ease-in-out focus: outline-0"
                  name="withdrawDescription"
                  placeholder="Tell us about your decision"
                  //placeholder={withdrawReason === withdrawOptions[0] ? 'Tell us about your school and decision' : withdrawReason === withdrawOptions[1] ?  'Tell us about your business and decision': withdrawReason === withdrawOptions[2] && 'Tell us about your problem and decision' }
                  value={withdrawDescription}
                  onChange={(e) => setWithdrawDescription(e.target.value)}
                ></textarea>
              </div>
            )}
          </div>

          <div className="flex justify-between py-4 border-t-2 border-dashed border-[#C4E8E8] self-center bg-white w-full sm:relative sm:w-full">
            <button
              className="w-[70%] mx-1 py-4  border-2 border-[#E3F2F2]"
              onClick={() => {
                dispatch(
                  handleConfirmationOptions({
                    handleChange: false,
                    handleCancel: true,
                    handleConfirm: false,
                  })
                )
                dispatch(handleConfirmationDisplay(false))
                dispatch(handleTransactionPrompt(null))
                dispatch(
                  handleTransactionWidgetDisplay({
                    showTransactionModal: false,
                    showTransactionSlideUp: false,
                  })
                )
              }}
            >
              Cancel
            </button>

            <button
              className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
              onClick={
                user.is2FAEnabled
                  ? () => setOpenModal(true)
                  : handleWithdrawMoney
              }
              // disabled={
              //   amountToWithdraw &&
              //   defaultBank &&
              //   withdrawReason &&
              //   withdrawDescription
              //     ? false
              //     : true
              // }
              disabled={
                amountToWithdraw &&
                defaultBank &&
                withdrawReason &&
                withdrawDescription
                  ? currentWallet &&
                    currentWallet.currentBalance !== undefined &&
                    parseFloat(amountToWithdraw) <= currentWallet.currentBalance
                    ? false
                    : true
                  : true
              }
            >
              {transferLoading ? (
                <PulseLoader size={8} color="#FFFFFF" />
              ) : (
                'Withdraw'
              )}
            </button>
          </div>
          {/** End of Withdraw form */}
        </>
      ) : (
        <TransactionNotAllowed
          insufficientFunds={false}
          noBankConnected={true}
        />
      )}
    </>
  )
}

export default RequestMoneyWithdrawToBank
