import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { checkVerificationCode } from '../../../../redux/actions/resetPasswordActions'

const CheckVerificationCode = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [code] = useState(searchParams.get('PasswordResetVerificationCode'))

  useEffect(() => {
    if (code) {
      dispatch(checkVerificationCode(code, navigate))
    }
  }, [code, dispatch, navigate])

  return <div></div>
}

export default CheckVerificationCode
