import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setupSavingsDeleteChildAPI,
  setupSavingsRegisterChildAPI,
  setupSavingsRemoveChild,
  setupSavingsUpdateChildAPI,
} from '../../../../redux/actions/setupSavingsStepperActions'
import { getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import {
  formatSSN,
  validateEmail,
  validatePhoneNumber,
  validdateSsn,
} from '../../../../utilities/generalUtilities'
import DateInput from '../../../Common/DateInput/DateInput'
import { LoadingSpinner } from '../../../Common/Loading'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import TextField from '../../../Common/TextField'
// @ts-ignore
import CloseIcon from '../../../../assets/closeIcon.png'
import { createAlertAction } from '../../../../redux/actions/alertActions'
import dayjs from 'dayjs'
import { trackAddChildEvent } from '../../../../amplitude/events'

type PropsType = {
  onClose: () => void
  id: string | null
  childInfo: any
}

const AddChildModal: React.FC<PropsType> = ({ onClose, id, childInfo }) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState<any>(null)
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [ssn, setSsn] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isSilaAccountCreated, setIsSilaAccountCreated] =
    useState<boolean>(true)
  const [intialLoading, setInitialLoading] = useState<boolean>(true)

  const handleGetUser = async () => {
    const response = await getUser(user.id)

    if (response.status === 200) {
      setIsSilaAccountCreated(response.data.silaUserAccountID ? true : false)
      setInitialLoading(false)
    } else setInitialLoading(false)
  }

  // const formatPhoneNumberToSave = (phone: string) => {
  //   let arr = ['+1', phone.substring(1)]
  //   return arr.join(' ')
  // }

  // const formatPhoneNumber = (phoneNumber: string) => {
  //   if (phoneNumber.startsWith('+1 ')) {
  //     return phoneNumber.substring(3)
  //   } else if (phoneNumber.startsWith('1')) {
  //     return `+1 ${phoneNumber.substring(1)}`
  //   } else {
  //     return phoneNumber
  //   }
  // }

  const handleAddChild = () => {
    console.log('Phone Number added', phoneNumber)
    trackAddChildEvent({ userId: user.id, firstName, lastName })
    setLoading(true)
    setupSavingsRegisterChildAPI({
      userAccountID: parseInt(user.id),
      firstName,
      lastName,
      dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
      phoneNumber,
      email,
      ssn,
    })
      .then(() => {
        setLoading(false)
        onClose()
      })
      .catch(() => {
        setLoading(false)
        alert('Error when creating child')
      })
  }

  const handleUpdateChild = () => {
    console.log('Update child Phone', phoneNumber)
    if (id) {
      setLoading(true)
      setupSavingsUpdateChildAPI({
        minorAccountID: parseInt(id),
        userAccountID: parseInt(user.id),
        firstName,
        lastName,
        dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
        phoneNumber,
        email,
        ssn,
      })
        .then(() => {
          setLoading(false)
          onClose()
        })
        .catch(() => {
          setLoading(false)
          alert('Unable to update account')
        })
    }
  }

  const handleRemoveChild = () => {
    if (id) {
      setLoading(true)
      dispatch(setupSavingsRemoveChild(id))
      setupSavingsDeleteChildAPI(id, user.id)
        .then(() => {
          setLoading(false)
          onClose()
        })
        .catch(() => {
          setLoading(false)
          dispatch(
            createAlertAction({
              type: 'DANGER',
              title: 'Cannot delete child',
              message:
                'The Child cannot be deleted because it is connected with other accounts',
              autoClose: true,
              autoCloseDuration: 3000,
            })
          )
        })
    }
  }

  const populateData = () => {
    const data = childInfo.find((child: any) => child.minorAccountID === id)

    console.log('Populating Phone', data?.telephoneNumber)
    // data?.telephoneNumber &&
    //   console.log('Populating Phone', formatPhoneNumber(data?.telephoneNumber))

    setFirstName(data?.firstName ?? '')
    setLastName(data?.lastName ?? '')
    setDateOfBirth(data?.dateOfBirth)
    setEmail(data?.emailAddress ?? '')
    setPhoneNumber(data?.telephoneNumber ?? '')
    setSsn(data?.identifier ?? '')
  }

  useEffect(() => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      !dateOfBirth ||
      (email.length > 0 && !validateEmail(email)) ||
      (ssn.length > 0 && ssn[0] !== 'X' && !validdateSsn(ssn)) ||
      (phoneNumber.length > 0 && !validatePhoneNumber(phoneNumber))
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [firstName, lastName, dateOfBirth, email, phoneNumber, ssn])

  useEffect(() => {
    handleGetUser()
    if (id) {
      populateData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="fixed h-full w-screen z-50 top-0 left-0">
        <div className="absolute bg-black opacity-80 h-screen w-screen"></div>

        <div
          className="absolute bg-white w-[88%] rounded-xl py-6 px-6 sm:w-[400px]"
          style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
        >
          {intialLoading ? (
            <div className="flex justify-center items-center">
              <LoadingSpinner color="#004F4E" />
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center border-[#C4E8E8] border-b-2 border-dashed pb-4">
                <p className="font-semibold text-primaryDark text-[18px]">
                  Add Child
                </p>

                <div className="flex items-center">
                  {id && !isSilaAccountCreated && (
                    <button
                      className="outline-none border-none text-red-600 underline font-normal"
                      onClick={handleRemoveChild}
                    >
                      Delete
                    </button>
                  )}

                  <img
                    src={CloseIcon}
                    alt="close icon"
                    className="w-[13px] h-[13px] cursor-pointer"
                    onClick={() => {
                      onClose()
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 py-4">
                <TextField
                  type="text"
                  name="firstName"
                  label={'First Name*'}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  passwordField={undefined}
                  showPasswordIconStatus={undefined}
                  changeIcon={() => undefined}
                />

                <TextField
                  type="text"
                  name="lastName"
                  label={'Last Name*'}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  passwordField={undefined}
                  showPasswordIconStatus={undefined}
                  changeIcon={() => undefined}
                />

                <div>
                  <DateInput
                    label={dateOfBirth ? '' : 'Date of birth*'}
                    dateValue={dateOfBirth}
                    onChange={(newValue) => {
                      setDateOfBirth(newValue)
                    }}
                  />
                </div>

                <TextField
                  type="text"
                  name="email"
                  label={'Email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  passwordField={undefined}
                  showPasswordIconStatus={undefined}
                  changeIcon={() => undefined}
                />

                <TelephoneNumber
                  value={phoneNumber}
                  onChange={(phoneNumber: string) =>
                    setPhoneNumber(phoneNumber)
                  }
                />

                <TextField
                  type="text"
                  name="ssn"
                  label={'Social Security number'}
                  value={ssn}
                  onChange={(e) => setSsn(formatSSN(e.target.value))}
                  passwordField={undefined}
                  showPasswordIconStatus={undefined}
                  changeIcon={() => undefined}
                />
              </div>

              <button
                className={`border-none text-white bg-primary h-[50px] flex items-center w-full justify-center`}
                onClick={id ? handleUpdateChild : handleAddChild}
                disabled={disabled}
              >
                {loading ? (
                  <div className="flex justify-center items-center h-full">
                    <LoadingSpinner color="#fff" />
                  </div>
                ) : id ? (
                  'Update'
                ) : (
                  'Add'
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddChildModal
