import React, { useState } from 'react'
import NewNavbar from '../../Common/Partials/NewNavbar'
// @ts-ignore
import BoldRightIcon from '../../../assets/BoldRightIcon.png'
import { useNavigate, useParams } from 'react-router-dom'

const Settings = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [walletName, _] = useState<string | undefined>(params.walletName)

  return (
    <div>
      <NewNavbar style="plain" />
      <div className="px-7 mt-4 w-full lg:w-[800px] lg:m-auto lg:mt-8">
        <p className="text-center text-[18px] font-semibold text-primaryDark">
          Settings
        </p>
        <p className="text-center text-[12px] font-semibold text-[#87AEAE] uppercase">
          Update your Wallet settings
        </p>
        <div className="flex mt-10 items-center justify-between">
          <div>
            <p className="text-primaryDark text-[15px] font-semibold">
              Connected Banks
            </p>
            <p className="text-[#87AEAE] text-[14px]">
              Add or remove connected bank accounts.
            </p>
          </div>
          <div
            className="border-2 border-[#C4E8E8] h-[20px] py-4 px-5 flex items-center justify-center rounded-lg"
            onClick={() => navigate(`/settings/connected-banks/${walletName}`)}
          >
            <img
              src={BoldRightIcon}
              alt={BoldRightIcon}
              className="w-[10px] cursor-pointer"
            />
          </div>
        </div>

        <div className="flex mt-10 items-center justify-between">
          <div>
            <p className="text-primaryDark text-[15px] font-semibold">
              Wallet Settings
            </p>
            <p className="text-[#87AEAE] text-[14px]">
              Customize wallet color and name.
            </p>
          </div>
          <div
            className="border-2 border-[#C4E8E8] h-[20px] py-4 px-5 flex items-center justify-center rounded-lg"
            onClick={() => navigate(`/settings/wallet-settings/${walletName}`)}
          >
            <img
              src={BoldRightIcon}
              alt={BoldRightIcon}
              className="w-[10px] cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
