import React from 'react'

const TaxResidencePageLoader = () => {
  return (
    <div className="py-8 animate-pulse">
      <div className="h-8 rounded bg-slate-200 w-full mb-2"></div>
      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-[70%]"></div>
      </div>

      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-[80%]"></div>
      </div>

      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </div>

      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-[60%]"></div>
      </div>
      <div className="h-8 rounded bg-slate-200 w-full mb-2"></div>
      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-[70%]"></div>
      </div>

      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-[80%]"></div>
      </div>

      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </div>

      <div className="flex space-x-4 mb-2 items-center">
        <div className="h-4 bg-slate-200 rounded w-[60%]"></div>
      </div>
    </div>
  )
}

export default TaxResidencePageLoader
