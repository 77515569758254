import api, { getRootState } from '../../api'
import { RootReduxStateType } from '../../types'
import { getUserCoins } from './coinActions'

export const getContent = async (id: string, isUserLoggedIn: boolean) => {
  try {
    const res = await api.get(`/FinancialLiteracy/Content/${id}`)
    if (!res.data.isPublic) {
      if (isUserLoggedIn) {
        return res
      } else {
        window.location.href = '/login'
      }
    }
    return res
  } catch (err) {
    console.log(err)
    window.location.href = '/login'
  }
}

export const getAuthor = async (authorID: string) => {
  try {
    const res = await api.get(`/FinancialLiteracy/Authors/${authorID}`)
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getFinancialLiteracyPost = async (id: string) => {
  try {
    const seriesID = 1
    const isUserLoggedIn = getRootState().token
    const contentRes: any = await getContent(id, isUserLoggedIn)
    const res = await api.get(
      `/FinancialLiteracy/Content/${contentRes.data.contentFolder}/en-US/${contentRes.data.contentGuid}`
    )
    const authorRes: any = await getAuthor(contentRes.data.authorID)
    const nextContentInfo = await api.get(
      `/FinancialLiteracy/Series/${seriesID}/Content/${id}/Next/3`
    )

    return {
      contentMeta: contentRes.status === 200 ? contentRes.data : {},
      content: res.status === 200 ? res.data : {},
      nextContentInfo:
        nextContentInfo.status === 200 ? nextContentInfo.data : [],
      authorData: authorRes.status === 200 ? authorRes.data : {},
    }
  } catch (err) {
    console.log(err)
    // window.location = '/'
  }
}

export const getUserContentDetails = async (id: string) => {
  try {
    const res = await api.get(`/FinancialLiteracy/Content/${id}/UserDetails`)
    return res
  } catch (err) {
    console.log(err)
    return { success: false, err }
  }
}

export const completeQuiz =
  (contentID: any = 0) =>
  async (_: any, getState: () => RootReduxStateType) => {
    try {
      const { token } = getState().user

      if (token) {
        const res = await api.post(
          '/FinancialLiteracy/Content/Complete',
          contentID
        )
        if (res.data === 'OK' || res.status === 200) {
          _(getUserCoins())
        }
      }

      console.log('Result on quiz finishsjsss')
    } catch (err) {
      console.log(err)
    }
  }

//.............Landing Page Actions...............

export const getSeries = async () => {
  try {
    const res = await api.get(`FinancialLiteracy/Series`)

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    console.log('Failed to get Series', err)
  }
}

export const getLessons = async (id: string) => {
  try {
    const res = await api.get(`FinancialLiteracy/Series/${id}/Content`)

    if (res.status === 200) {
      //console.log('Lessons', res.data)
      return res.data
    }
  } catch (err) {
    console.log('Failed to get Series', err)
  }
}

export const getNextInSeries = async () => {
  try {
    const res = await api.get(`/FinancialLiteracy/Series/1/NextInSeries`)

    return res.data.contentID
  } catch (err) {
    console.log('Failed to get Series details', err)
  }
}

export const getUserDetails = async () => {
  try {
    const res = await api.get(`/FinancialLiteracy/Series/1/UserDetails`)
    //console.log('User details', res)

    return res.data
  } catch (err) {
    console.log('Failed to get user details in money academy', err)
  }
}

export const getNextThree = async (currentLesson: string) => {
  try {
    const res = await api.get(
      `/FinancialLiteracy/Series/1/Content/${currentLesson}/Next/3`
    )
    console.log('User details', res)

    return res.data
  } catch (err) {
    console.log('Failed to get user details in money academy', err)
  }
}
