import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  increamentSavingsStepperStep,
  silaAgreementsAPI,
} from '../../../../redux/actions/setupSavingsStepperActions'
import {
  getUser,
  getUserAddressInfo,
} from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user'
import SafeAreaView from '../../../Common/SafeAreaView'
import TwoFactorAuthentication from '../../../Common/TwoFactorAuthentication'
import SilaSetupBottom from '../SilaSetupBottom'

type PropsType = {
  setShowModal: (x: boolean) => void
}

const SetupSavingsStepperOne: React.FC<PropsType> = ({ setShowModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loadedUser, setLoadedUser] = useState<UserAPIType>()
  const [_, setInfoNeeded] = useState<string[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [agreePrivacyAndTerms, setAgreePrivacyAndTerms] = useState(false)
  const [accountAgreementDisclosure, setAccountAgreementDisclosure] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const getUserInfo = async () => {
    setInfoNeeded([])

    const addressResponse = await getUserAddressInfo(user.id)
    const userResponse = await getUser(user.id)

    if (userResponse.status === 200) {
      setLoadedUser(userResponse.data)

      if (
        userResponse.data.firstName === null ||
        userResponse.data.firstName === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'firstName',
        ])
      }

      if (
        userResponse.data.lastName === null ||
        userResponse.data.lastName === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'lastName',
        ])
      }

      if (
        userResponse.data.telephoneNumber === null ||
        userResponse.data.telephoneNumber === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'telephoneNumber',
        ])
      }

      if (addressResponse.response?.data.length === 0) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'address',
        ])
      }
    }
  }

  const handleNext = async () => {
    if (!loadedUser?.emailAddressIsVerified) {
      setShowModal(true)
      return
    }

    setLoading(true)
    silaAgreementsAPI(user.id, '')
      .then(() => {
        setLoading(false)
        dispatch(increamentSavingsStepperStep())
      })
      .catch(() => {
        setLoading(false)
        alert('Error on sila agreements register')
      })
  }

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TwoFactorAuthentication
        onSuccess={handleNext}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <SafeAreaView>
        <p className="">
          For security purposes, we need a few more details to prove that you
          are a real person.
        </p>

        <p className="leading-relaxed pt-4 pb-2 font-semibold text-[18px] mt-4">
          Terms of Use
        </p>

        <div className="checkbox mb-[16px] text-[15px] mt-2">
          <input
            type="checkbox"
            checked={agreePrivacyAndTerms}
            onChange={() => {
              setAgreePrivacyAndTerms(!agreePrivacyAndTerms)
            }}
            id="agreePrivacy"
            data-test="signup-terms-checkbox"
          />
          <label htmlFor="agreePrivacy">
            {t('User.IAgree')}{' '}
            <a
              href="https://www.sootchy.com/legal-documents/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              {t('User.PrivacyPolicy')}
            </a>
            {' & '} {t('User.IAgree')}{' '}
            <a
              href="https://www.sootchy.com/legal-documents/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              {t('User.TermsConditions')}
            </a>{' '}
          </label>
        </div>

        <p className="leading-relaxed pt-4 pb-2 font-semibold text-[18px]">
          Account Agreement Disclosure
        </p>

        <div className="checkbox mb-[16px] text-[15px] mt-2">
          <input
            type="checkbox"
            checked={accountAgreementDisclosure}
            onChange={() => {
              setAccountAgreementDisclosure(!accountAgreementDisclosure)
            }}
            id="accountAgreementDisclosure"
            data-test="signup-terms-checkbox"
          />
          <label htmlFor="accountAgreementDisclosure">
            I have read the{' '}
            <a
              href="https://www.sootchy.com/legal-documents/customer-account-agreement-disclosures-and-consent"
              target="_blank"
              rel="noreferrer"
            >
              Disclosure and Consent
            </a>
            , and I agree to the{' '}
            <a
              href="https://www.sootchy.com/evolve-customer-account-agreement"
              target="_blank"
              rel="noreferrer"
            >
              Evolve Customer Account Agreement
            </a>{' '}
            and the{' '}
            <a
              href="https://www.silamoney.com/legal/evolve-electronic-communications-consent"
              target="_blank"
              rel="noreferrer"
            >
              Evolve e-communications consent.
            </a>
          </label>
        </div>

        <div className="checkbox mb-[16px] text-[15px] mt-2 text-center underline">
          <a
            href="https://www.sootchy.com/legal-documents/additional-required-disclosures"
            target="_blank"
            rel="noreferrer"
          >
            Additional Required Disclosure
          </a>
        </div>
      </SafeAreaView>
      <SilaSetupBottom
        handleNext={user.is2FAEnabled ? () => setOpenModal(true) : handleNext}
        handleCancel={() => {
          navigate('/wallet')
        }}
        loading={loading}
        disabled={
          !loadedUser
            ? true
            : !agreePrivacyAndTerms || !accountAgreementDisclosure
        }
      />
    </>
  )
}

export default SetupSavingsStepperOne
