import React from 'react'
import { useTranslation } from 'react-i18next'
import SrcsetImage from '../SrcsetImage'
import FooterLanguage from './FooterLanguage'

type PropsType = {
  showUser?: boolean
  showClose?: boolean
  isFixable?: boolean
  footerWhite?: boolean
}

const Footer: React.FC<PropsType> = ({
  showUser,
  showClose,
  isFixable,
  footerWhite,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.footerWrapper}>
      <div
        className={`${styles.wrapper} ${
          footerWhite ? 'bg-white' : 'bg-[#7FE5E4]'
        }`}
      >
        <div className={`${styles.wave.wrapper} ${styles.waveFooter}`}>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            className={styles.wave.svg}
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className={`${styles.wave.path} ${
                footerWhite ? 'fill-white' : 'fill-[#7FE5E4]'
              }`}
            ></path>
          </svg>
        </div>
        <div className={styles.inner}>
          <div className={styles.text}>
            <FooterLanguage />
            <div className={styles.copyright}>
              © Sootchy, Inc. {t('Footer.Copyright')}{' '}
              <a href="/legal" className="underline">
                Legal
              </a>
            </div>
          </div>
          <SrcsetImage
            small="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/dolphin.svg"
            medium="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/dolphin.svg"
            large="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/dolphin.svg"
            alt="dolphin"
            className={styles.dolphin}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  footerWrapper: '',
  wrapper: 'p-[20px] relative',
  inner: 'relative',
  waveFooter: 'top-[-30px] lg:top-[-80px] bottom-auto',
  text: 'container mx-auto lg:flex items-center',
  copyright: 'text-[15px] text-primaryDark lg:order-1',
  dolphin:
    'absolute right-[20px] bottom-[-20px] block w-[89px] h-[108px] object-cover object-top',
  wave: {
    wrapper:
      'absolute bottom-0 left-0 overflow-hidden line-height leading-[0px] w-[100%]',
    svg: 'relative block h-[30px] lg:h-[80px] w-[calc(100%_+_1.3px)]',
    path: 'fill-white',
  },
}

export default Footer
