import React, { useEffect, useState } from 'react'
// @ts-ignore
import SearchIcon from '../../../assets/search.png'
// @ts-ignore
import BoldBottomIcon from '../../../assets/BoldBottomIcon.png'

type Proptypes = {
  title: string
  value?: string
  list: any
  handleValueChange?: (e: any) => void
  handleOnchange: (code: any, name: any) => void
  disable?: boolean
}

const CountrySelector = ({
  title,
  list,
  value,
  disable,
  handleOnchange,
}: Proptypes) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false)
  const [countryLabel, setCountryLabel] = useState<string>('')
  const [countriesFound, setCountriesFound] = useState<any>(list)

  const filter = (e: any) => {
    setIsListOpen(true)
    const keyword = e.target.value

    if (keyword !== '') {
      const results = list.filter((country: any) => {
        return country.countryName
          .toLowerCase()
          .startsWith(keyword.toLowerCase())
      })
      setCountriesFound(results)
    } else {
      setCountriesFound(list)
    }

    setCountryLabel(keyword)
  }

  const handleOnClick = () => {
    !disable && setIsListOpen(!isListOpen)
  }

  useEffect(() => {
    setIsListOpen(false)
  }, [handleOnchange])

  useEffect(() => {
    value && setCountryLabel(value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="my-2">
      <div
        onClick={handleOnClick}
        className="py-1 px-3 rounded-lg w-full bg-[#E3F2F2] flex flex-col justify-between"
      >
        <div className="w-full flex justify-between font-normal">
          <div
            className="flex items-center"
            onClick={() => setIsListOpen(false)}
          >
            <img src={SearchIcon} className="mr-2" alt="search-icon" />
            <div>
              <p className="text-[11px] px-1 text-primary">{title}</p>
              <input
                type="search"
                readOnly={disable}
                value={countryLabel}
                onChange={filter}
                placeholder={`Search for country`}
                className="w-full outline-none bg-[#E3F2F2] pl-1"
              />
            </div>
          </div>
          <button
            onClick={() => !disable && setIsListOpen(!isListOpen)}
            className="border-0 focus:outline-0 "
          >
            <img
              src={BoldBottomIcon}
              alt="BoldBottomIcon"
              className={`w-[10px] ${isListOpen && 'rotate-180'} `}
            />
          </button>
        </div>
      </div>

      <div>
        {isListOpen ? (
          countriesFound && countriesFound.length > 0 ? (
            <div className="absolute z-40 shadow max-h-[240px] w-full overflow-y-scroll px-8 bg-slate-100">
              {countriesFound.map((country: any, index: any) => (
                <div
                  key={index}
                  onClick={() => {
                    handleOnchange(country.alpha3, country.countryName)
                    setCountryLabel(country.countryName)
                    setCountriesFound(list)
                  }}
                  className="py-1 px-2 hover:bg-slate-200 rounded-lg cursor-pointer"
                >
                  {country.countryName}
                </div>
              ))}
            </div>
          ) : (
            <div className="absolute z-40 shadow max-h-[240px] w-full overflow-y-scroll px-8 py-6 bg-slate-100">
              No results found
            </div>
          )
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CountrySelector
