import React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Image1, Image2, Image3, Image4 } from '../ImageImports'

const ImageSlider = () => {
  const images = [Image1, Image2, Image3, Image4]

  return (
    <>
      <Swiper
        navigation={true}
        watchSlidesProgress={true}
        slidesPerView={1}
        className="md:hidden w-[100%] z-10"
      >
        {images.map((imageSrc, index) => (
          <SwiperSlide key={index} className="h-[320px] w-[600%]">
            <img
              src={imageSrc}
              alt="Pictures from Previous Activation Event"
              className="z-10 w-full h-full object-cover"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        navigation={true}
        watchSlidesProgress={true}
        slidesPerView={3}
        className="hidden md:block w-[100%] z-10"
      >
        {images.map((imageSrc, index) => (
          <SwiperSlide key={index} className="h-[320px] w-[600%]">
            <img
              src={imageSrc}
              alt="Pictures from Previous Activation Events"
              className="z-10 w-full h-full object-cover"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default ImageSlider
