import React, { useState } from 'react'

type PropsType = {
  items: ItemType[]
}

type ItemType = {
  title: string
  content: string
  open: boolean
}

const Faq: React.FC<PropsType> = ({ items }) => {
  const [faq, setFaq] = useState<ItemType[]>(
    items.map((item, index: number) => ({
      ...item,
      index,
      open: false,
    }))
  )

  return (
    <div>
      {items?.map((item, i) => (
        <div
          className={`${styles.item}`}
          key={i}
          onClick={() =>
            setFaq((prevState) =>
              prevState.map((pVal, pIndex) =>
                pIndex === i ? { ...pVal, open: !pVal.open } : pVal
              )
            )
          }
        >
          <div className={styles.itemTitle}>
            <div className="w-[90%]">{item.title}</div>
            <img
              src="/assets/images/arrow-down.svg"
              alt="arrow"
              className={`${styles.arrow} ${
                faq[i].open ? styles.arrowOpen : ''
              }`}
            />
          </div>
          <div
            className={`${styles.itemContent} ${
              faq[i].open ? styles.itemContentOpen : ''
            }`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  )
}

const styles = {
  item: 'mb-[16px] last:mb-0  py-[16px] border-b-[2.5px] border-[#C4E8E8] border-dashed cursor-pointer',
  itemTitle: `text-[1rem] leading-[24px] font-normal flex items-center justify-between`,
  arrow: 'block',
  arrowOpen: 'rotate-180',
  itemContent:
    'text-[14px] text-[#727272] max-h-0 overflow-hidden block transition-all',
  itemContentOpen: 'max-h-[9999px] pt-[15px]',
}

export default Faq
