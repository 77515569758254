import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateVideo,
  alpacaUserAgreements,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepFourteen: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  //const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [agreements, setAgreements] = useState(new Array())

  const handleChange = (e: any) => {
    const { value, checked } = e.target
    checked
      ? setAgreements([...agreements, value])
      : setAgreements(agreements.filter((e) => e !== value))
  }

  const handleNext = () => {
    //agreements.length === 2 && dispatch(increamentAlpacaStepperStep())

    if (agreements.length > 0) {
      alpacaUserAgreements({
        userAccountID: user.id,
        alpacaAgreementType: agreements,
        signatureDate: dayjs(new Date()).format(),
        signerIPAddress: '',
      })
        .then(() => {
          // registerAlpaca()
          //   .then((res) => {
          //     console.log('Register Alpaca', res)
          //     dispatch(increamentAlpacaStepperStep())
          //   })
          //   .catch((err) => {
          //     alert('Alpaca Register Error')
          //   })

          dispatch(increamentAlpacaStepperStep())
        })
        .catch((err) => console.log('Unable to post agreements', err))
    }
  }

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="text-[18px] py-[16px] font-semibold">Almost done</p>

        <p className="text-[18px] py-[16px] font-normal">
          After clicking “Next”, we will now do a quick background check to
          confirm that you are a real person. Do you agree with the following
          documents?
        </p>

        <div className="bg-[#E3F2F2] rounded-md py-[9px] px-[15px]">
          <div className="flex space-x-2">
            <img
              src="/assets/images/attach.svg"
              alt="attach"
              className="w-[20px]"
            />
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              className="text-primaryDark underline py-1 inline-block"
            >
              Alpaca Customer Agreement
            </a>
          </div>

          <div className="flex space-x-2">
            <img
              src="/assets/images/attach.svg"
              alt="attach"
              className="w-[20px]"
            />
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              className="text-primaryDark underline py-1 inline-block"
            >
              Margin Disclosure Statement
            </a>
          </div>
        </div>

        <div id="agreements" className="py-[24px]">
          <div className="mb-[16px] flex items-center space-x-4 ">
            <input
              className="accent-primary focus:accent-primary w-6 h-6"
              id="MarginAgreement"
              type="checkbox"
              value="MarginAgreement"
              name="agreements"
              onChange={handleChange}
            />
            <label htmlFor="MarginAgreement" className="w-[90%]">
              I have read, understood, and agree to be bound by Alpaca
              Securities LLC and Sootchy Inc. account terms, and all other
              terms, disclosures and disclaimers applicable to me, as referenced
              in the Alpaca Customer Agreement. I also acknowledge that the
              Alpaca Customer Agreement contains a pre-dispute arbitration
              clause in Section 43.
            </label>
          </div>

          <div className="mb-[16px] flex items-center space-x-4 ">
            <input
              className="accent-primary focus:accent-primary w-6 h-6"
              id="2"
              type="checkbox"
              value="CustodialAgreement"
              name="agreements"
              onChange={handleChange}
            />
            <label htmlFor="2" className="w-[90%]">
              I understand I am signing this agreement electronically, and that
              my electronic signature will have the same effect as physically
              signing and returning the Application Agreement.
            </label>
          </div>
        </div>

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 mt-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={agreements.length <= 0 ? true : false}
        handleNext={handleNext}
      />
    </div>
  )
}

export default AlpacaStepFourteen
