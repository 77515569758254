import React from 'react'
import { logoutUser } from '../../../utilities/generalUtilities'

type PropsType = {
  openModal: boolean
  setOpenModal: (x: any) => void
  children: React.ReactNode
  onLogin: boolean
}

const Modal: React.FC<PropsType> = ({
  openModal,
  setOpenModal,
  children,
  onLogin,
}) => {
  const handleClose = () => {
    //setOpenModal(false)
    if (onLogin) {
      console.log('Close')
      setOpenModal(false)
      logoutUser()
    } else {
      setOpenModal(false)
    }
  }
  return openModal ? (
    <div>
      <div
        className="block fixed  bg-black opacity-80 w-[100%] h-[100vh] top-0 left-0  z-[100]"
        onClick={() => setOpenModal(false)}
      ></div>
      <div>
        <div
          data-test="quiz-modal"
          className="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-5 pt-12 pb-5 w-[90%] min-h-[300px] max-h-[90%]  opacity-100 rounded-xl flex z-[101] lg:w-[50%]"
        >
          <img
            src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/plus.png"
            alt="close_icon"
            className="absolute top-5 right-5 cursor-pointer"
            onClick={handleClose}
          />
          <div className="flex flex-col items-center justify-center w-[100%] overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Modal
