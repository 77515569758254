import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import SlideUp from '../../../../Common/SlideUp'
import { WalletType } from '../../../../../types/transactions'
// @ts-ignore
import Success from '../../../../../assets/Success.png'
// @ts-ignore
import BoldBottomIcon from '../../../../../assets/BoldBottomIcon.png'
import { PreventNegativeValues } from '../../../../Common/preventNegativeValues'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleConfirmationDialogData,
  handleConfirmationDisplay,
  sendMoneyFromWalletToWallet,
} from '../../../../../redux/actions/transactionsActions'
import { RootReduxStateType } from '../../../../../types'
import { createAlertAction } from '../../../../../redux/actions/alertActions'
import { PulseLoader } from 'react-spinners'

type Proptypes = {
  isActive: boolean
  handleIsActiveChange: (e: any) => void
  currentWallet: WalletType
  wallets: any
  amountToSend: string
  setAmountToSend: (e: any) => void
}

const SendingMoneyToWallet = ({
  isActive,
  handleIsActiveChange,
  currentWallet,
  wallets,
  amountToSend,
  setAmountToSend,
}: Proptypes) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 767px)')
  const [isSuccess, setIsSucess] = useState<boolean>(false)
  const [transferLoading, setTransferLoading] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [defaultWallet, setDefaultWallet] = useState<any>()
  const transactions = useSelector(
    (state: RootReduxStateType) => state.transactions
  )

  useEffect(() => {
    //transactions.confirmationDialogOptions.handleCancel && setAmountToAdd('')
    transactions.confirmationDialogOptions.handleConfirm &&
      //handleSendTransaction(currentWallet, defaultWallet)
      handleSendTransaction(
        transactions.confirmationDialogData.sender,
        transactions.confirmationDialogData.recipient,
        transactions.confirmationDialogData.amount
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions])

  const handleSendMoney = () => {
    dispatch(handleConfirmationDisplay(true))
    dispatch(
      handleConfirmationDialogData({
        transactionType: 'transfer',
        sender: currentWallet.walletName,
        recipient: defaultWallet.walletName,
        amount: amountToSend,
      })
    )
  }

  const handleSendTransaction = async (
    sendFrom: any,
    sendTo: any,
    amountToSend: any
  ) => {
    console.log('Walet Info', sendTo)
    try {
      setTransferLoading(true)

      const response: any = await sendMoneyFromWalletToWallet(
        sendFrom,
        sendTo,
        amountToSend
      )
      if (response.status === 200) {
        setIsSucess(true)
      }
    } catch (error: any) {
      console.error(error)

      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Error Occured',
          message: error.message,
          autoClose: true,
          autoCloseDuration: 10000,
        })
      )
    } finally {
      setTransferLoading(false)
    }
  }

  const TransactionNotAllowed = () => {
    return (
      <>
        <h3 className="py-4 text-[24px] font-semibold text-textGreen">
          Insufficient Funds
        </h3>
        <p>Please add funds to continue with the transaction</p>

        <div className="py-2  self-center bg-white w-full sm:relative">
          <button
            className="w-full py-4  border-2 border-[#E3F2F2]"
            onClick={() => {
              handleIsActiveChange(undefined)
            }}
          >
            Okay
          </button>
        </div>
      </>
    )
  }

  const SuccessMessage = () => {
    return (
      <div>
        <div className="flex flex-col items-center justify-center py-2">
          <img src={Success} className="w-[40px]" alt="check" />
          <p className="py-4 text-[24px] font-semibold text-textGreen">
            ${amountToSend} has been transferred!
          </p>
        </div>
        <div className="py-2  self-center bg-white w-full sm:relative sm:w-full">
          <button
            className="w-full mx-1 py-4  border-2 border-[#E3F2F2]"
            onClick={() => {
              setAmountToSend('')
              handleIsActiveChange(undefined)
              window.location.reload()
            }}
          >
            Awesome
          </button>
        </div>
      </div>
    )
  }

  const SendMoneyForm = () => {
    return (
      <>
        <div>
          <div className="flex justify-between items-center mb-[27px]">
            <h3 className="py-4 text-[24px] font-semibold text-textGreen">
              Send Money
            </h3>
          </div>
          <div className="block px-[1rem] py-[0.75rem] bg-[#E3F2F2] mb-[16px] rounded-[10px]">
            <div className="flex space-x-4 items-center">
              <p className="text-[#87AEAE] font-semibold leading-[120.93%] text-[24px]">
                $
              </p>
              <input
                type="number"
                value={amountToSend}
                min="0"
                onKeyPress={PreventNegativeValues}
                //onChange={(e) => setAmountToSend(e.target.value)}
                autoFocus
                onChange={(e: any) => setAmountToSend(e.target.value)}
                name="AmountToSend"
                aria-label="AmountToSend"
                className="w-full bg-[#E3F2F2] text-[36px] font-semibold focus: outline-0"
              />
            </div>

            <p className="text-[#87AEAE] text-[0.6875rem] leading-[1.03125rem]">
              Wallet balance: ${currentWallet.currentBalance}
            </p>
          </div>

          {wallets?.length > 1 && (
            <div className="mb-[34px] bg-[#E3F2F2] min-h-[40px] rounded-[10px]">
              <div className="flex justify-between items-center px-[16px] py-[8px]">
                <div className="flex items-center space-x-4 w-full">
                  {defaultWallet !== null || undefined ? (
                    <div
                      style={{
                        backgroundColor: `${
                          defaultWallet ? defaultWallet?.walletColor : '#004F4E'
                        }`,
                      }}
                      className={`w-[46px] h-[32px] rounded text-white`}
                    >
                      <p className="text-[11px] px-[4px]">
                        {currentWallet.walletName.charAt(0)}
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`w-[46px] h-[32px] bg-primary rounded text-white`}
                    >
                      <p className="text-[11px] px-[4px]">
                        {currentWallet.walletName.charAt(0)}
                      </p>
                    </div>
                  )}

                  <div className="w-[70%] h-[40px]" data-test="wallet-dropdown">
                    {defaultWallet ? (
                      <div className="h-[40px] leading-tight flex flex-col justify-center">
                        <p className="text-[14px] font-semibold">
                          to {defaultWallet.walletName}
                        </p>
                        <p className="text-[11px] text-[#87AEAE]">
                          Balance: {defaultWallet.walletBalance / 100}
                        </p>
                      </div>
                    ) : (
                      <div className="h-[40px] flex items-center">
                        <p>Choose Wallet to send to</p>
                      </div>
                    )}
                  </div>
                </div>

                <button
                  className="border-0 focus:outline-0"
                  onClick={() => setShowMore(!showMore)}
                  data-test="wallet-dropdown-button"
                >
                  <img
                    src={BoldBottomIcon}
                    alt="BoldBottomIcon"
                    className="w-[10px]"
                  />
                </button>
              </div>

              <div
                className={`bg-white w-[99%] max-h-[240px] overflow-y-scroll rounded-b-lg border-b-2 border-[#E3F2F2] px-[16px] mx-auto transform transition ease-in-out duration-1000 ${
                  showMore ? 'block' : 'hidden'
                }`}
              >
                {wallets &&
                  wallets.map(
                    (wallet: WalletType, index: number) =>
                      wallet.silaWalletID !== currentWallet.silaWalletID && (
                        <div
                          key={index}
                          onClick={() => {
                            //console.log('Choosen Wallet', wallet)
                            setDefaultWallet(wallet)
                            setShowMore(false)
                          }}
                          className="flex items-center space-x-4"
                          data-test={`wallet-dropdown-list-${wallet.silaWalletID}`}
                        >
                          <div
                            style={{
                              backgroundColor: wallet.walletColor,
                            }}
                            className="w-[46px] h-[32px] rounded text-white"
                            data-test={`wallet-dropdown-list-main-${wallet.silaWalletID}`}
                          >
                            <p
                              className="text-[11px] px-[4px]"
                              data-test={`wallet-dropdown-list-text-logo-${wallet.silaWalletID}`}
                            >
                              {wallet.walletName.charAt(0)}
                            </p>
                          </div>
                          <div>
                            <p
                              className="text-[14px] font-semibold mt-[4px]"
                              data-test={`wallet-dropdown-list-title-${wallet.silaWalletID}`}
                            >
                              to {wallet.walletName}
                            </p>
                            <p
                              className="text-[11px] text-[#87AEAE] mb-[4px]"
                              data-test={`wallet-dropdown-list-balance-${wallet.silaWalletID}`}
                            >
                              Balance: ${wallet.walletBalance / 100}
                            </p>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between py-4 border-t-2 border-dashed border-[#C4E8E8] self-center bg-white w-full sm:relative sm:w-full">
          <button
            className="w-[70%] mx-1 py-4  border-2 border-[#E3F2F2]"
            onClick={handleIsActiveChange}
          >
            Cancel
          </button>
          {wallets ? (
            <button
              className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
              onClick={handleSendMoney}
              //disabled={amountToSend ? (defaultWallet ? false : true) : true}
              disabled={
                amountToSend
                  ? currentWallet &&
                    currentWallet.currentBalance !== undefined &&
                    parseFloat(amountToSend) <= currentWallet.currentBalance
                    ? defaultWallet
                      ? false
                      : true
                    : true
                  : true
              }
            >
              {transferLoading ? (
                <PulseLoader size={8} color="#FFFFFF" />
              ) : (
                'Send Money'
              )}
            </button>
          ) : (
            <button
              className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
              //onClick={handleAddMoney}
              disabled={
                amountToSend
                  ? defaultWallet === undefined
                    ? false
                    : true
                  : true
              }
            >
              Create Wallet
            </button>
          )}
        </div>
      </>
    )
  }

  const displayComponent = () => {
    return (
      <>
        {currentWallet &&
        currentWallet.currentBalance !== undefined &&
        currentWallet.currentBalance <= 0 ? (
          <TransactionNotAllowed />
        ) : isSuccess ? (
          <SuccessMessage />
        ) : (
          <SendMoneyForm />
        )}
      </>
    )
  }

  if (isActive) {
    return (
      <div className="fixed h-full w-screen z-50 top-0 left-0">
        <div className="absolute bg-black opacity-80 h-screen w-screen"></div>

        {isMobile ? (
          <div className="absolute w-full md:w-[570px] bottom-0 inset-x-0 mx-auto transform transition ease-in-out duration-300">
            <div className="bg-white shadow shadow-gray-400/50 rounded-t-[10px] p-[20px] z-40">
              <SlideUp>{displayComponent()}</SlideUp>
            </div>
          </div>
        ) : (
          <div
            className="absolute bg-white w-full md:w-[570px] ease-in-out duration-300 transition-transform rounded-xl py-6 px-6"
            style={{
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {displayComponent()}
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default SendingMoneyToWallet
