import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../../../../components/Common/Loading'
import { completeEmailVerification } from '../../../../redux/actions/userActions'
import Navigation from '../../../Common/Navigation'

const VerifyEmail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const [emailVerificationCode] = useState(
    searchParams.get('EmailVerificationCode')
  )

  useEffect(() => {
    if (emailVerificationCode)
      dispatch(completeEmailVerification(emailVerificationCode, navigate))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="flex flex-col justify-center items-center py-4">
            <img src="/assets/images/dolphin_logo.svg" alt="dolphin" />

            <h1 className="text-center text-[32px] lg:text-[36px] text-primaryDark leading-[32.37px] font-semibold py-[32px]">
              Welcome to Sootchy!
            </h1>
            <p className="text-primary pb-[32px]">
              {t('User.VerifyingEmail')}...
            </p>
            <div className="flex justify-center w-full">
              <LoadingSpinner color="#0E6D6C" size={8} />
            </div>
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default VerifyEmail
