import React from 'react'

const TransactionsLoader = () => {
  return (
    <>
      <div className="flex border-b-2 py-2 border-slate-200 border-dashed animate-pulse">
        <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
        <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
          <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
          <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
        </div>
      </div>

      <div className="flex border-b-2 py-2 border-slate-200 border-dashed animate-pulse">
        <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
        <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
          <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
          <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
        </div>
      </div>

      <div className="flex border-b-2 py-2 border-slate-200 border-dashed animate-pulse">
        <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
        <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
          <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
          <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
        </div>
      </div>

      <div className="flex border-b-2 py-2 border-slate-200 border-dashed animate-pulse">
        <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
        <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
          <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
          <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
        </div>
      </div>

      <div className="flex py-2 animate-pulse">
        <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
        <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
          <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
          <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
        </div>
      </div>
    </>
  )
}

export default TransactionsLoader
