import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decrementAlpacaStepperStep } from '../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../types'
import { LoadingSpinner } from '../Common/Loading'

type PropsType = {
  handleNext: () => void
  handleCancel?: () => void
  loading?: boolean
  disabled?: boolean
  handleNextBtn?: string
}

const SetupBottomAlpaca: React.FC<PropsType> = ({
  handleNext,
  handleCancel,
  disabled = false,
  handleNextBtn,
  loading,
}) => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)

  const defaultHandleCancel = () => {
    dispatch(decrementAlpacaStepperStep())
  }

  return (
    <div className="fixed bottom-0 flex justify-between py-4 border-t-2 border-dashed border-[#C4E8E8] self-center bg-white w-[90%] sm:relative sm:w-full">
      <button
        className="w-[70%] mx-1 py-4"
        onClick={handleCancel ? handleCancel : defaultHandleCancel}
      >
        {alpaca.step === 1 ? 'cancel' : 'Previous'}
      </button>
      <button
        className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
        onClick={handleNext}
        disabled={disabled}
      >
        {loading ? (
          <LoadingSpinner />
        ) : handleNextBtn ? (
          handleNextBtn
        ) : alpaca.step === 17 ? (
          (localStorage.getItem('isKycFailed') !== 'true' ? true : false) ? (
            'Finish'
          ) : (
            'Correct, Next'
          )
        ) : alpaca.step === 1 ? (
          'Continue'
        ) : (
          'Correct, Next'
        )}
      </button>
    </div>
  )
}

export default SetupBottomAlpaca
