import React from 'react'
import NewNavbar from '../Partials/NewNavbar'

type PropsType = {
  children: React.ReactNode
  isAbsolute?: boolean
}

const LayoutFullPage: React.FC<PropsType> = ({ children, isAbsolute }) => {
  return (
    <>
      <div className="bg-white min-h-[100vh]">
        <div className="bg-white">
          <NewNavbar />
        </div>
        {/** <div className="w-[90%] m-auto lg:w-[85%] mx-auto md:w-[480px] pt-[60px] flex-1">
          <div className="container mx-auto">{children}</div>
        </div> */}

        <div className="w-[90%] md:w-[480px] mx-auto pt-[60px]">
          <div className="container mx-auto">{children}</div>
        </div>
      </div>
    </>
  )
}

export default LayoutFullPage
