import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetUserAction } from '../../../../redux/actions/userActions'
import TextField from '../../../Common/TextField'

type PropsType = {
  userName: string
  setUserName: (x: any) => void
  handleOnClick: (x: any) => void
  goLogin: () => void
  translation: (x: string) => string
}

const SendVerificationCode: React.FC<PropsType> = ({
  userName,
  setUserName,
  handleOnClick,
  goLogin,
  translation: t,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetUserAction())
  }, [dispatch])

  return (
    <div className="w-[90%] md:w-[570px] m-auto">
      <div className="py-4">
        <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
          <button
            type="button"
            className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
            onClick={goLogin}
          >
            <img
              src={`/assets/images/back.svg`}
              alt="back"
              className="block text-primaryDark"
            />
          </button>
          Reset Password
        </h1>
      </div>

      <div>
        <TextField
          type="text"
          name="username"
          label={t('User.Username')}
          classes={styles.input}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          passwordField={undefined}
          showPasswordIconStatus={undefined}
          changeIcon={() => undefined}
        />
        <button className={styles.button} onClick={handleOnClick}>
          {t('User.ResetPassword')}
        </button>
      </div>
    </div>
  )
}

const styles = {
  form: 'w-8/12 flex flex-col justify-center py-10 p-12 mx-auto h-full tab:w-full',
  input: 'text-input mb-[16px]',
  button: 'btn btn--big btn--dark mb-[16px]',
  small:
    'text-primary my-[16px] text-sm underline cursor-pointer font-semibold block text-center  text-primary ',
}

export default SendVerificationCode
