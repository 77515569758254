import React, { useState } from 'react'
import { sendEmailFromContactForm } from '../../redux/actions/generalActions'

const RequestForDeletion = () => {
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [submission, setSubmission] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')

  const handleSubmit = async () => {
    sendEmailFromContactForm({
      email: email,
      message: `Please delete the Sootchy account with this username - ${username}`,
      name: '',
      occupation: '',
      phoneNumber: '',
      state: '',
      subject: 'Request for Deletion',
      username: username,
    })
      .then(() => {
        setLoading(true)
        setSubmission(true)
      })
      .catch(() => {
        setLoading(false)
        setSubmission(false)
      })
  }

  return (
    <div className="w-[90%] md:w-[40%] lg:w-[25%] flex items-center justify-center m-auto h-[100vh]">
      {!submission ? (
        <div>
          <h1 className="text-[22px] font-semibold text-[#004F4E]">
            Delete your Sootchy Account
          </h1>
          <p className="pt-6 pb-3 text-[15px]">
            You’re trying to delete your Sootchy Account, which provides access
            to various Sootchy services. You’ll no longer be able to use any of
            those services, and your account and data will be lost.
          </p>
          <p className="py-6 text-[15px]">
            Alternatively you can also reach out to support@sootchy.com
          </p>
          <input
            type="text"
            placeholder="Username"
            className="w-full outline-none bg-slate-100 py-4 px-4 rounded-lg"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <br />

          <input
            type="text"
            placeholder="Email"
            className="w-full outline-none bg-slate-100 py-4 px-4 rounded-lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <button
            className="w-full mt-6 py-4 bg-primaryDark text-white"
            onClick={handleSubmit}
          >
            {loading ? 'Loading...' : 'Submit'}
          </button>
        </div>
      ) : (
        <div>
          <h1 className="text-[22px] font-semibold text-[#004F4E]">
            Your Request has been submitted
          </h1>
          <p className="pt-6 pb-3 text-[15px]">
            Your request is submitted, our team will reach out to you soon.
            Thanks!
          </p>
        </div>
      )}
    </div>
  )
}

export default RequestForDeletion
