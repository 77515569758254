import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootReduxStateType } from '../../../../types'

type PropTypes = {
  styles: {
    button: string
    input: string
    backButton: string
  }
}

const StepFour = ({ styles }: PropTypes) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const data = useSelector((state: RootReduxStateType) => state.general)

  return (
    <div className="mt-[32px]">
      {/** <FullPageTitle title="Sign Up" onBack={decreamentStep} hideButton /> */}
      <img
        src="assets/images/mail-check.svg"
        alt="dolphin"
        className="block mb-[16px] mx-auto"
      />
      <p className="signup-description text-center ">
        {t('User.RegisterConfirmation')}
      </p>
      {data.isTwoFactorAuthEnabled && (
        <button
          onClick={() => navigate('/security/mfa')}
          className="btn btn--big btn--dark text-white mb-4"
        >
          {t('2FA.SetUpBtn')}
        </button>
      )}
      <NavLink to="/my529" className="btn btn--big btn--gray">
        {data.isTwoFactorAuthEnabled
          ? `${t('User.Skip')} 👍`
          : `${t('User.Gotcha')} 👍`}
      </NavLink>
    </div>
  )
}

export default StepFour
