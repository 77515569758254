import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'
//@ts-ignore
import { AzureMP } from 'react-azure-mp'

const legalInformations = [
  {
    title: 'Before we begin',
    info: [
      'Congratulations for taking this step to set up a savings account with Sootchy! We created Sootchy savings accounts to help you fund a bright and prosperous future for your family.',
      "We'll be asking you some questions to collect information that helps us keep your account safe and secure, and also to comply with federal law. Completing this account setup process usually takes about {TIME RANGE}.",
      ' Your data is safe with us and our team is here help if you need assistance at any point.',
    ],
  },
  {
    title: 'Sootchy Customer Agreement',
    info: [
      'These Terms of Use were last modiﬁed in August 2022.',
      'Terms of Use and your acceptance of them. Read before continuing.‍',
      'Sootchy, Inc. and Sootchy Securities LLC (collectively “Sootchy,” “we,” “us,” or “our”) may provide you with the information, content, tools, products, and services on its mobile application (“App”). Your use of the App is subject to these Terms of Use (&quot;Terms&quot;).',
      'These Terms are a binding agreement between you and Sootchy. By using or accessing the App, you accept and agree to be bound by these Terms. Your use of the App is governed by the version of the Terms in effect on the date the App is accessed by you. Sootchy may modify these Terms at any time and without prior notice. You should review the most current version of these Terms by visiting the App and clicking on the Terms of Use hyperlink. These Terms are in addition to any other agreements between you and Sootchy, including any client agreement and any other agreements that govern your use of information, content, tools, products and services available on and through the App. If you do not agree to these Terms of use, do not click to accept or otherwise register on the App.',
      'SOOTCHY MAKES EVERY REASONABLE EFFORT TO PROVIDE ACCURATE AND TIMELY INFORMATION ABOUT OUR COMPANY ON THE APP, HOWEVER, YOU SHOULD NOT ASSUME THAT THE INFORMATION PROVIDED IS ALWAYS CURRENT OR THAT THIS APP CONTAINS ALL THE RELEVANT INFORMATION AVAILABLE.',
    ],
  },
  {
    title: 'Alpaca Customer Agreement',
    info: [
      'These Terms of Use were last modiﬁed in August 2022.',
      'Terms of Use and your acceptance of them. Read before continuing.‍',
      'Sootchy, Inc. and Sootchy Securities LLC (collectively “Sootchy,” “we,” “us,” or “our”) may provide you with the information, content, tools, products, and services on its mobile application (“App”). Your use of the App is subject to these Terms of Use (&quot;Terms&quot;).',
      'These Terms are a binding agreement between you and Sootchy. By using or accessing the App, you accept and agree to be bound by these Terms. Your use of the App is governed by the version of the Terms in effect on the date the App is accessed by you. Sootchy may modify these Terms at any time and without prior notice. You should review the most current version of these Terms by visiting the App and clicking on the Terms of Use hyperlink. These Terms are in addition to any other agreements between you and Sootchy, including any client agreement and any other agreements that govern your use of information, content, tools, products and services available on and through the App. If you do not agree to these Terms of use, do not click to accept or otherwise register on the App.',
      'SOOTCHY MAKES EVERY REASONABLE EFFORT TO PROVIDE ACCURATE AND TIMELY INFORMATION ABOUT OUR COMPANY ON THE APP, HOWEVER, YOU SHOULD NOT ASSUME THAT THE INFORMATION PROVIDED IS ALWAYS CURRENT OR THAT THIS APP CONTAINS ALL THE RELEVANT INFORMATION AVAILABLE.',
    ],
  },
  {
    title: 'Client Relationship Summary',
    info: [
      'These Terms of Use were last modiﬁed in August 2022.',
      'Terms of Use and your acceptance of them. Read before continuing.‍',
      'Sootchy, Inc. and Sootchy Securities LLC (collectively “Sootchy,” “we,” “us,” or “our”) may provide you with the information, content, tools, products, and services on its mobile application (“App”). Your use of the App is subject to these Terms of Use (&quot;Terms&quot;).',
      'These Terms are a binding agreement between you and Sootchy. By using or accessing the App, you accept and agree to be bound by these Terms. Your use of the App is governed by the version of the Terms in effect on the date the App is accessed by you. Sootchy may modify these Terms at any time and without prior notice. You should review the most current version of these Terms by visiting the App and clicking on the Terms of Use hyperlink. These Terms are in addition to any other agreements between you and Sootchy, including any client agreement and any other agreements that govern your use of information, content, tools, products and services available on and through the App. If you do not agree to these Terms of use, do not click to accept or otherwise register on the App.',
      'SOOTCHY MAKES EVERY REASONABLE EFFORT TO PROVIDE ACCURATE AND TIMELY INFORMATION ABOUT OUR COMPANY ON THE APP, HOWEVER, YOU SHOULD NOT ASSUME THAT THE INFORMATION PROVIDED IS ALWAYS CURRENT OR THAT THIS APP CONTAINS ALL THE RELEVANT INFORMATION AVAILABLE.',
    ],
  },
  {
    title: 'A few more checkboxes...',
  },
]

const AlpacaStepOne: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [legalStep, setLegalStep] = useState<number>(0)
  const [agreementsStatus, setAgreementStatus] = useState({
    alpacaCustomerAgreement: false,
    applicationAgreement: false,
  })

  const handleNext = () => {
    if (legalStep !== 4) {
      setLegalStep(legalStep + 1)
      return
    }

    dispatch(increamentAlpacaStepperStep())
  }

  const handleCancel = () => {
    if (legalStep !== 0) {
      setLegalStep(legalStep - 1)
      return
    }

    navigate('/')
  }

  useEffect(() => {
    if (legalStep === 0)
      dispatch(
        alpacaStepperUpdateVideo(
          'https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/4df328b8-c30e-4ac6-ad34-a4ba03cc6eef/01-2d.ism/manifest'
        )
      )
    else dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalStep])

  return (
    <div>
      <SafeAreaView>
        <div>
          <div>
            {alpaca.video && (
              <div className={`w-[400px] m-auto mt-5 hidden md:block`}>
                <AzureMP
                  skin="amp-flush"
                  src={[
                    {
                      src: alpaca.video,
                      type: 'video/mp4',
                    },
                  ]}
                  options={{ controls: true, autoplay: false }}
                />
              </div>
            )}
          </div>
          <div className="px-5">
            <p className="text-[18px] font-semibold">
              {legalInformations[legalStep].title}
            </p>

            {legalStep === 4 ? (
              <div>
                <div className="mb-[16px] flex items-center space-x-4 mt-4">
                  <input
                    className="accent-primary focus:accent-primary"
                    id="AlpacaCustomerAgreement"
                    type="checkbox"
                    value="AlpacaCustomerAgreement"
                    name="agreements"
                    checked={agreementsStatus.alpacaCustomerAgreement}
                    onChange={() =>
                      setAgreementStatus({
                        ...agreementsStatus,
                        alpacaCustomerAgreement:
                          !agreementsStatus.alpacaCustomerAgreement,
                      })
                    }
                  />
                  <label htmlFor="AlpacaCustomerAgreement">
                    I have read, understood, and agree to be bound by Alpaca
                    Securities LLC and Sootchy Inc. account terms, and all other
                    terms, disclosures and disclaimers applicable to me, as
                    referenced in the Alpaca Customer Agreement. I also
                    acknowledge that the Alpaca Customer Agreement contains a
                    pre-dispute arbitration clause in Section 43.
                  </label>
                </div>

                <div className="mb-[16px] flex items-center space-x-4 mt-4">
                  <input
                    className="accent-primary focus:accent-primary"
                    id="ApplicationAgreement"
                    type="checkbox"
                    value="ApplicationAgreement"
                    name="agreements"
                    checked={agreementsStatus.applicationAgreement}
                    onChange={() =>
                      setAgreementStatus({
                        ...agreementsStatus,
                        applicationAgreement:
                          !agreementsStatus.applicationAgreement,
                      })
                    }
                  />
                  <label htmlFor="ApplicationAgreement">
                    I understand I am signing this agreement electronically, and
                    that my electronic signature will have the same effect as
                    physically signing and returning the Application Agreement.
                  </label>
                </div>
              </div>
            ) : (
              <div>
                {legalInformations[legalStep].info?.map(
                  (info, index: number) => (
                    <p className="mt-3" key={index}>
                      {info}
                    </p>
                  )
                )}
              </div>
            )}

            <p
              onClick={() =>
                dispatch(alpacaStepperUpdateAssistenceNeeded(true))
              }
              className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
            >
              I need assistance
            </p>
          </div>
        </div>
      </SafeAreaView>
      <SetupBottomAlpaca
        handleNext={handleNext}
        handleCancel={handleCancel}
        disabled={
          legalStep === 4
            ? agreementsStatus.alpacaCustomerAgreement === true &&
              agreementsStatus.applicationAgreement === true
              ? false
              : true
            : false
        }
      />
    </div>
  )
}

export default AlpacaStepOne
