import React, { useState } from 'react'
//import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { verifyEmail } from '../../../../redux/actions/userActions'
import { useDispatch } from 'react-redux'
import Navigation from '../../../Common/Navigation'
import { RootReduxStateType } from '../../../../types'

const EmailVerificationFailed = () => {
  //const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [verificationRequested, setVerificationRequested] = useState(false)

  const handleVerfyEmail = async (e: any) => {
    e.preventDefault()
    dispatch(verifyEmail(user.id))
    setVerificationRequested(true)
  }

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4 flex flex-col justify-center items-center">
            {verificationRequested ? (
              <>
                <img src="/assets/images/mail.svg" alt="mail" />
                <h1 className="text-center text-[32px] lg:text-[36px] text-primaryDark leading-[32.37px] font-semibold py-[32px]">
                  Check your Email
                </h1>
                <p className="text-center pb-[32px]">
                  We just sent you an email to verify your account. Once you
                  confirm your email address you'll be all set!
                </p>
                <button
                  onClick={() => navigate('/my529')}
                  className="btn btn--big btn--gray mb-[16px]"
                >
                  Gotcha
                </button>
              </>
            ) : (
              <>
                <img src="/assets/images/dolphin_logo.svg" alt="dolphin" />

                <h1 className="text-center text-[32px] lg:text-[36px] text-primaryDark leading-[32.37px] font-semibold py-[32px]">
                  Welcome to Sootchy!
                </h1>
                <p className="text-primary pb-[32px]">
                  Email Verification failed.
                </p>
                <p className="text-center pb-[32px]">
                  To complete registration, click on the button below. If you
                  are having trouble, you can click on this link instead.
                </p>

                <button
                  onClick={handleVerfyEmail}
                  className="btn btn--big btn--dark mb-[16px]"
                >
                  Verify your Email
                </button>
              </>
            )}
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default EmailVerificationFailed
