import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  activationRegisterParentInfo,
  activationRSVPStatus,
  activationUserRegistration,
  updateActivationUserProfileID,
} from '../../../redux/actions/activationActions'
import { RootReduxStateType } from '../../../types'
import {
  calculateUserAge,
  validateEmail,
  validatePhoneNumber,
} from '../../../utilities/generalUtilities'
import { LoadingSpinner } from '../../Common/Loading'
import DateInput from '../../Common/DateInput/DateInput'
import TelephoneNumber from '../../Common/TelephoneNumber'
import { getActivationUserInfo } from '../../../redux/actions/userActions'
import TextField from '../../Common/TextField'
import { trackActivationRegisterEvent } from '../../../amplitude/events'
import ActivationModal from './ActivationModal'

type Proptypes = {
  eventID: string
}

const ActivationRegistrationForm = ({ eventID }: Proptypes) => {
  const activation = useSelector(
    (state: RootReduxStateType) => state.activation
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [dateOfBirth, setDateOfBirth] = useState<any>(null)
  const [disclosures, setDisclosures] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isActivationCodeValid, setIsActivationCodeValid] =
    useState<boolean>(false)

  const useQuery = () => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  useEffect(() => {
    const invitationId = query.get('invitationId')
    if (invitationId) {
      getActivationUserInfo(invitationId)
        .then((res) => {
          if (res.data.isExpired) return

          setEmailAddress(res.data.emailAddress)
          setIsActivationCodeValid(true)
        })
        .catch(() => null)
    }
  }, [])

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      phoneNumber &&
      disclosures &&
      validatePhoneNumber(phoneNumber) &&
      validateEmail(emailAddress) &&
      dateOfBirth
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [firstName, lastName, phoneNumber, emailAddress, dateOfBirth, disclosures])

  const formatPhoneNumberToSave = (phone: string) => {
    let arr = ['+1', phone.substring(1)]
    return arr.join(' ')
  }

  const handleRegistration = async () => {
    const formattedPhoneNumber = formatPhoneNumberToSave(phoneNumber)

    const formattedDateOfBirth = dayjs(dateOfBirth).format('YYYY-MM-DD')

    setLoading(true)

    const userAge = calculateUserAge(dateOfBirth)

    if (userAge < 18) {
      setShowModal(true)
    } else {
      activationUserRegistration(
        eventID,
        firstName,
        lastName,
        formattedDateOfBirth,
        emailAddress,
        formattedPhoneNumber
      )
        .then((res) => {
          // Amplitude - Activation Register
          trackActivationRegisterEvent({ firstName, lastName })

          dispatch(activationRSVPStatus())
          dispatch(
            activationRegisterParentInfo({
              firstName: firstName,
              lastName: lastName,
              phoneNumber: formattedPhoneNumber,
              email: emailAddress,
              dateOfBirth: formattedDateOfBirth,
              invitationVerificationCode: query.get('invitationId') ?? null,
            })
          )

          dispatch(updateActivationUserProfileID(res))
          navigate('/activation/setup')
        })
        .catch((err) => {
          setLoading(false)
          console.log('Failed to register User', err)
        })
    }

    // if (
    //   firstName === parent.firstName ||
    //   lastName === parent.lastName ||
    //   phoneNumber === parent.phoneNumber ||
    //   dateOfBirth === parent.dateOfBirth ||
    //   emailAddress === parent.email
    // ) {
    //   navigate('/activation/setup')
    // } else {
    //   //check for dob
    //   const userAge = calculateUserAge(dateOfBirth)
    //   console.log('User Age', userAge)

    //   if (userAge < 18) {
    //     setShowModal(true)
    //   } else {
    //     activationUserRegistration(
    //       eventID,
    //       firstName,
    //       lastName,
    //       formattedDateOfBirth,
    //       emailAddress,
    //       formattedPhoneNumber
    //     )
    //       .then((res) => {
    //         dispatch(activationRSVPStatus())
    //         dispatch(
    //           activationRegisterParentInfo({
    //             firstName: firstName,
    //             lastName: lastName,
    //             phoneNumber: formattedPhoneNumber,
    //             email: emailAddress,
    //             dateOfBirth: formattedDateOfBirth,
    //             invitationVerificationCode: query.get('invitationId') ?? null,
    //           })
    //         )

    //         dispatch(updateActivationUserProfileID(res))
    //         navigate('/activation/setup')
    //       })
    //       .catch((err) => {
    //         setLoading(false)
    //         console.log('Failed to register User', err)
    //       })
    //   }
    // }
  }

  return (
    <div className="relative">
      {/** Form */}
      <div className="bg-white shadow-lg md:rounded-lg w-full min-h-[597px] max-h-[630px] pt-[36px] pb-[40px] px-[20px] md:px-[40px]">
        <h4 className="font-semibold text-[20px] md:text-[24px] leading-[20px] md:leading-[28.8px] mb-[12px]">
          RSVP for your child
        </h4>
        <p className="font-thin text-[14px] leading-[24px]">
          Provide some info about you and your children to RSVP and get early
          access to extra perks on the Sootchy app.
        </p>

        <div className="my-[32px]">
          <div>
            <p className="font-thin leading-[18px] text-[14px]">Your name</p>
            <div className="mt-[8px] mb-[16px] w-full flex space-x-2">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="First"
                  className="w-full bg-[#E3F2F2] px-[15px] py-[15px] rounded-[10px] outline-none"
                  value={firstName}
                  name="firstName"
                  autoFocus={false}
                  onChange={(e: any) => setFirstName(e.target.value)}
                  //disabled={activation.hasRSVPed}
                />
              </div>

              <div className="w-full">
                <input
                  type="text"
                  placeholder="Last"
                  className="bg-[#E3F2F2] w-full  px-[15px] py-[15px] rounded-[10px] outline-none"
                  name="lastName"
                  value={lastName}
                  autoFocus={false}
                  onChange={(e: any) => setLastName(e.target.value)}
                  //disabled={activation.hasRSVPed}
                />
              </div>
            </div>
          </div>

          <div className=" mb-[16px] w-full flex space-x-2">
            <div className="flex flex-col justify-center">
              <p className="font-thin leading-[18px] text-[14px]">
                Your phone number
              </p>
              <div className="mt-2">
                <TelephoneNumber
                  value={phoneNumber}
                  //disabled={activation.hasRSVPed}
                  onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="font-thin leading-[18px] text-[14px]">
                Your birth date
              </p>

              {/* <div className="mt-2 w-full">
              {activation.hasRSVPed ? (
                <ReadOnlyDatePicker date={dateOfBirth} />
              ) : (
                <DateInput
                  label={dateOfBirth ? '' : 'Select'}
                  isActivation
                  dateValue={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                />
              )}
            </div> */}

              <div className="mt-2 w-full">
                <DateInput
                  label={dateOfBirth ? '' : 'Select'}
                  isActivation
                  dateValue={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                />
              </div>
            </div>
          </div>

          <div>
            <p className="font-thin leading-[18px] text-[14px]">
              Your email address
            </p>
            <div>
              <TextField
                type="email"
                label={`Email`}
                name="emailAddress"
                classes={'mt-2 mb-3'}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                data-test="signup-email-input"
                disabled={isActivationCodeValid}
              />
            </div>
          </div>

          <div className="checkbox mb-[16px] font-thin leading-[18px] text-[14px]">
            <input
              type="checkbox"
              checked={disclosures}
              onChange={() => setDisclosures(!disclosures)}
              id="agreeTerms"
              //disabled={activation.hasRSVPed}
              //data-test="signup-terms-checkbox"
            />
            <label htmlFor="agreeTerms">
              I agree to
              <a
                href="https://www.sootchy.com/legal-documents/sootchy-terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              ,{' '}
              <a
                href="https://www.sootchy.com/legal-documents/sootchy-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </div>

          {/* <button
          onClick={handleRegistration}
          //disabled={activation.hasRSVPed ? false : !isValid}
          className="mt-[32px] w-full py-[16px] btn--dark"
        >
          {loading ? (
            <LoadingSpinner color="#FFFFFF" />
          ) : activation.hasRSVPed ? (
            'Add Child'
          ) : (
            'To registration'
          )}
        </button> */}
          <button
            onClick={handleRegistration}
            //disabled={activation.hasRSVPed ? false : !isValid}
            disabled={!isValid}
            className="mt-[32px] w-full py-[16px] btn--dark"
          >
            {loading ? <LoadingSpinner color="#FFFFFF" /> : 'To registration'}
          </button>
        </div>
      </div>

      {showModal && (
        <div>
          <ActivationModal>
            <div className="my-4">
              <h3 className="fullpage-title">We need your mom or dad 👪</h3>

              <p className="signup-description text-center ">
                We know you're a smart kid, but because of your age we need a
                parent (or legal guardian) to sign you up for this event. Show
                this page to your parent and ask them to register you.
              </p>
              <button
                onClick={() => {
                  setShowModal(false)
                  setLoading(false)
                }}
                className="btn btn--big btn--gray"
              >
                My parents will help me 👍
              </button>
            </div>
          </ActivationModal>
        </div>
      )}
    </div>
  )
}

export default ActivationRegistrationForm
