import React from 'react'
import { SootchyWhiteLogo } from '../ImageImports'

type Proptype = {
  activationEvent: string
}
const ActivationNavbar = ({ activationEvent }: Proptype) => {
  return (
    <div className="container mx-auto px-[20px] py-[38px]">
      <div className="flex items-center">
        <img
          src={SootchyWhiteLogo}
          alt="SootchyGreenLogo"
          className="h-[36px]"
        />

        <div className="pl-6 md:pl-8 flex justify-center">
          <p className="font-bold text-white text-[15px]">{activationEvent}</p>
        </div>
      </div>
    </div>
  )
}

export default ActivationNavbar
