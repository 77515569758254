import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateFunding,
  alpacaStepperUpdateVideo,
  decrementAlpacaStepperStep,
  increamentAlpacaStepperStep,
  updateAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepTwelve: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [disabled, setDisabled] = useState<boolean>(true)
  // const [loading, setLoading] = useState<boolean>(false)

  const handleOnChange = (e: any) => {
    const key = 'source'
    const value = e.target.value

    dispatch(alpacaStepperUpdateFunding(key, value))
  }

  const handleNext = () => {
    // TODO: If email is changed send verification email
    dispatch(increamentAlpacaStepperStep())
  }

  const handleCancel = () => {
    if (alpaca.employment.status !== 'Employed')
      dispatch(updateAlpacaStepperStep(10))
    else dispatch(decrementAlpacaStepperStep())
  }

  useEffect(() => {
    if (alpaca.funding.source) setDisabled(false)
    else setDisabled(true)
  }, [alpaca.funding.source])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="font-semibold text-[17px] py-2">Account funding source</p>
        <p className="text-[15px]">
          What is the primary source of funding to be used...
        </p>

        <div className="py-4">
          <div className="my-2">
            <input
              type="radio"
              id="employment_income"
              name="fav_language"
              value="employment_income"
              onChange={handleOnChange}
              checked={
                alpaca.funding.source &&
                alpaca.funding.source === 'employment_income'
                  ? true
                  : false
              }
            />
            <label htmlFor="employment_income" className="px-1">
              Employment income
            </label>
          </div>

          <div className="my-2">
            <input
              type="radio"
              id="business_income"
              name="fav_language"
              value="business_income"
              onChange={handleOnChange}
              checked={
                alpaca.funding.source &&
                alpaca.funding.source === 'business_income'
                  ? true
                  : false
              }
            />
            <label htmlFor="business_income" className="px-1">
              Business Income
            </label>
          </div>

          <div className="my-2">
            <input
              type="radio"
              id="inheritance"
              name="fav_language"
              value="inheritance"
              onChange={handleOnChange}
              checked={
                alpaca.funding.source && alpaca.funding.source === 'inheritance'
                  ? true
                  : false
              }
            />
            <label htmlFor="inheritance" className="px-1">
              Inheritance
            </label>
          </div>

          <div className="my-2">
            <input
              type="radio"
              id="investments"
              name="fav_language"
              value="investments"
              onChange={handleOnChange}
              checked={
                alpaca.funding.source && alpaca.funding.source === 'investments'
                  ? true
                  : false
              }
            />
            <label htmlFor="investments" className="px-1">
              Investments
            </label>
          </div>
        </div>

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={disabled}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default AlpacaStepTwelve
