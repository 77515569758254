import dayjs from 'dayjs'
import api, { getLoginRes, setLoginRes } from '../../api'
import { UserAPIDataType } from '../../types/user'
import {
  formatPhoneNumberToSave,
  getHashedPassword,
  getUserAge,
  getUserFromToken,
} from '../../utilities/generalUtilities'
import {
  LOG_IN,
  RESET_USER,
  RESET_USER_REGISTRATION_STEPPER_DATA,
  SIGNUP,
  UPDATE_TOKEN,
  UPDATE_USER_EMAIL,
} from './action.types'
import { createAlertAction } from './alertActions'
import {
  trackIncorrectPasswordEvent,
  trackLoginEvent,
  trackSignupEvent,
} from '../../amplitude/events'

export const userNameCheck = async (username: string) => {
  try {
    const res = await api.get(`UserAccount/UserNameCheck/${username}`)
    if (res.data) {
      return { success: false }
    }
    return { success: true }
  } catch (err) {
    return { success: false, err }
  }
}

export const loginAction: (data: {
  userName: string
  password: string
}) => (dispatch: Function) => Promise<boolean | undefined> =
  (data: { userName: string; password: string }) =>
  async (dispatch: Function) => {
    try {
      const res = await api.post('/UserAccount/Login', {
        userName: data.userName,
        password: await getHashedPassword(data.password),
      })

      if (res.status === 200) {
        const {
          UserName: userName,
          UserAccountID: id,
          is2FAEnabled: is2FA,
          DateOfBirth,
        } = getUserFromToken(res.data.accessToken)

        setLoginRes(res.data)

        trackLoginEvent({ userId: id })

        const is2FAEnabled = is2FA === '1'
        if (!is2FAEnabled) {
          //console.log('before')

          await dispatch({
            type: LOG_IN,
            payload: {
              id,
              userName,
              userAge: getUserAge(dayjs(DateOfBirth).format('YYYY-MM-DD')),
              emailAddress: '',
              token: res.data.accessToken,
              is2FAEnabled: res.data.is2FAEnabled,
            },
          })
        }

        /*  if (res.data.is2FAEnabled) {
          //alert('Run validation API')
          //await getUserInformation(res.data)
          return res.data
          // login user on validation MFA API and run getuserCoins and dispatch login.
        } else {
          setLoginRes(res.data)

          await dispatch({
            type: LOG_IN,
            payload: {
              id,
              userName,
              emailAddress: '',
              token: res.data.accessToken,
              is2FAEnabled: res.data.is2FAEnabled,
            },
          })

          await dispatch(getUserCoins())
          return res.data
        }
        */

        //setLoginRes(res.data)

        //await dispatch(getUserCoins())
        //return true

        return res.data
      }
    } catch (err: any) {
      trackIncorrectPasswordEvent({ username: data.userName })
      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Credentials does not match',
          message: 'Your password associated to the account does not match',
          autoClose: true,
        })
      )
    }
  }

export const socialLoginAction =
  (data: any, social: string) => async (dispatch: Function) => {
    try {
      const res = await api.post(`/UserAccount/LoginExternal/${social}`, data)
      if (res.status === 200) {
        const {
          UserName: userName,
          UserAccountID: id,
          is2FAEnabled: is2FA,
        } = getUserFromToken(res.data)
        const is2FAEnabled = is2FA === '1'
        if (!is2FAEnabled) {
          setLoginRes(res.data)
          dispatch({
            type: LOG_IN,
            payload: {
              id,
              userName,
              emailAddress: '',
              token: res.data,
              is2FAEnabled: is2FA,
            },
          })
        }
        //dispatch(getUserCoins())

        window.location.href = '/'
      }
      if (res.status === 202) {
        //e for email, t for IdToken, i for identifier, u for UserName
        window.location.href = `/signup?e=${res.data.email}&i=${res.data.identifier}&u=${res.data.userName}&s=${social}`
      }
      //dispatch(getUserCoins())

      //window.location.href = '/'
    } catch (err: any) {
      console.log(err)
      if (err.response.status === 403) {
        dispatch(
          createAlertAction({
            type: 'DANGER',
            title: 'Credentials does not match',
            message: 'Your password associated to the account does not match',
            autoClose: true,
          })
        )
        return true
      }

      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Internal Server Error',
          message: 'We are facing a internet error, try agin later',
          autoClose: true,
        })
      )
    }
  }
export const getUserAccount = async (username: string) => {
  try {
    const res = await api.get(`UserAccount/UserName/${username}`)

    if (res.status === 200) {
      const { emailAddressIsVerified } = res.data

      return emailAddressIsVerified
    }
  } catch (err) {
    console.log(err)
  }
}

export const signupAction = () => async (dispatch: Function, getState: any) => {
  try {
    const {
      streetAddress,
      referredByReferralCode,
      isActivationCodeValid,
      ...data
    } = getState().userRegistrationStepper.data

    const hashedPassword = await getHashedPassword(data.password)
    const queryParams = new URLSearchParams(window.location.search)
    const email = queryParams.get('e') ? queryParams.get('e') : null
    const identifier = queryParams.get('i') ? queryParams.get('i') : null
    const username = queryParams.get('u') ? queryParams.get('u') : null
    const source = queryParams.get('s') ? queryParams.get('s') : null
    const othetdata =
      identifier != null
        ? JSON.stringify({ username: username, email: email })
        : null

    const res = await api.post(`UserAccount/Create/`, {
      ...data,
      password: hashedPassword,
      accountStatus: 'AccountCreated',
      isTestAccount: false,
      SocialLoginIdentifier: identifier,
      SocialLoginOtherData: othetdata,
      SocialLoginSource: source,
      TelephoneNumber: formatPhoneNumberToSave(data.phoneNumber),
      sourceProcess: 'WebApplication',
      changeReason: 'Add new user account',
      InvitationVerificationCode: isActivationCodeValid
        ? referredByReferralCode
        : null,
    })

    if (res.status === 200) {
      setLoginRes(res.data)
      const { UserName, UserAccountID } = getUserFromToken(res.data.accessToken)

      trackSignupEvent({
        username: data.userName,
        firstName: data.firstName,
        lastName: data.lastName,
      })

      await api.post('UserAccount/Subscription', {
        UserAccountID: UserAccountID,
        ContactByPhone: data.contactByPhone,
        ContactByEmail: data.contactByEmail,
        NotificationSubscribed: data.recieveFutureUpdates,
      })

      const encodedPayload = res.data.accessToken

      dispatch({
        type: SIGNUP,
        payload: {
          id: UserAccountID,
          userName: UserName,
          emailAddress: data.emailAddress,
          token: encodedPayload,
        },
      })

      dispatch({
        type: RESET_USER_REGISTRATION_STEPPER_DATA,
      })

      //navigate('/')
      // dispatch(increamentUserRegistrationStep())
    }
  } catch (err) {
    console.log(err)
    return { success: false, err }
  }
}

export const resetUserAction = () => async (dispatch: Function) => {
  dispatch({
    type: RESET_USER,
  })
}

export const verifyEmail = (id: string) => async () => {
  try {
    const res = await api.post('/UserAccount/Verify/Email', 'WebApplication')

    if (res.status === 200) {
      return {
        success: true,
      }
    }
  } catch (err) {
    console.log(err)
    alert('Unable to Send Email')
  }
}

export const completeEmailVerification =
  (code: string, navigate: any) => async () => {
    try {
      const res = await api.post('/UserAccount/CompleteVerification/Email', {
        emailVerificationCode: code,
        sourceProcess: 'WebApplication',
      })

      if (res.status === 200) {
        navigate('/email-verification-successful')
      }
    } catch (err) {
      //console.log(err)
      navigate('/email-verification-failed')
    }
  }

export const updateTokenAction =
  (newAuthToken: string) => async (dispatch: Function) => {
    dispatch({
      type: UPDATE_TOKEN,
      payload: newAuthToken,
    })
  }

export const logoutAction =
  (newAuthToken?: string) => async (dispatch: Function) => {
    try {
      const res = await api.post('/UserAccount/Logout', getLoginRes(), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.status === 200) {
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }

export const getUserAddressInfo = async (userID: string) => {
  try {
    const response = await api.get(`UserAccountAddress/UserAccountID/${userID}`)

    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    console.log(err)
    return { success: false, err }
  }
}

export const getUser = (userID: string) => {
  //try {
  return api.get(`UserAccount/UserAccountID/${userID}`)

  //   if (response.status !== 200) return { success: false, response }

  //   return { success: true, response }
  // } catch (err: any) {
  //   return { success: false, err }
  // }
}

export const updateEmail = async (userID: string, email: string) => {
  try {
    const response = await getUser(userID)

    if (response.status === 200) {
      await api.put('UserAccount/Update/Profile', {
        UserAccountID: response.data.userAccountID,
        FirstName: response.data.firstName ?? '',
        LastName: response.data.lastName ?? '',
        DateOfBirth: response.data.dateOfBirth,
        TelephoneNumber:
          response.data.telephoneNumber === ''
            ? null
            : response.data.telephoneNumber,
        EmailAddress: email,
        ModifyingUserAccountID: response.data.userAccountID,
        SourceProcess: 'WebApplication',
        ChangeReason: 'Update EmailAddress',
      })

      if (response.status !== 200) return { success: false, response }

      return { success: true, response }
    }

    return { success: false, err: 'Error on getUser' }
  } catch (err: any) {
    return { success: false, err }
  }
}

export const updateUser = async (data: UserAPIDataType) => {
  try {
    const response = await api.put('UserAccount/Update/Profile', data)

    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    return { success: false, err }
  }
}

export const verifyEmailAPI = async () => {
  try {
    const response = await api.post(
      '/UserAccount/Verify/Email',
      'WebApplication'
    )

    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    return { success: false, err }
  }
}

export const createAddress = async ({
  userAccountID,
  streetAddress1,
  streetAddress2,
  city,
  state,
  zipCode,
}: {
  userAccountID: number
  streetAddress1: string
  streetAddress2: string
  city: string
  state: string
  zipCode: string
}) => {
  try {
    const response = await api.post('UserAccountAddress/create', {
      UserAccountID: userAccountID,
      StreetAddress1: streetAddress1,
      StreetAddress2: streetAddress2,
      City: city,
      State: state,
      ZipCode: zipCode,
      ModifyingUserAccountID: userAccountID,
      SourceProcess: 'WebApplication',
      ChangeReason: 'Creating a Address',
      CountryCode: 'USA', // Hardcoded to USA
    })

    console.log(response)
  } catch (err: any) {
    console.log(err)
    return { success: false, err }
  }
}

export const createSillaUserAccount = ({
  userAccountID,
  identifier,
  dateOfBirth,
  firstName,
  middleName,
  lastName,
}: {
  userAccountID: number
  identifier: string
  dateOfBirth: string
  firstName: string
  middleName: string
  lastName: string
}) => {
  return api.post('/SilaUserAccount/Register', {
    UserAccountID: userAccountID,
    KnownPersonStatus: 'PendingKyc',
    IdentifierType: 'SocialSecurityNumber',
    Identifier: identifier,
    DateOfBirth: dateOfBirth,
    FirstName: firstName,
    MiddleName: middleName,
    LastName: lastName,
    ModifyingUserAccountID: userAccountID,
    SourceProcess: 'ManualTest',
    ChangeReason: 'Adding a sila test account.',
  })
}

export const updateSillaUserAccount = ({
  userAccountID,
  identifier,
  dateOfBirth,
  firstName,
  middleName,
  lastName,
}: {
  userAccountID: number
  identifier: string
  dateOfBirth: string
  firstName: string
  middleName: string
  lastName: string
}) => {
  return api.put('/SilaUserAccount/KnownPerson', {
    UserAccountID: userAccountID,
    KnownPersonStatus: 'PendingKyc',
    IdentifierType: 'SocialSecurityNumber',
    Identifier: identifier,
    DateOfBirth: dateOfBirth,
    FirstName: firstName,
    MiddleName: middleName,
    LastName: lastName,
    ModifyingUserAccountID: userAccountID,
    SourceProcess: 'ManualTest',
    ChangeReason: 'Updating a sila test account.',
  })
}

export const updateUserEmailRedux =
  (email: string) => async (dispatch: Function) => {
    dispatch({
      type: UPDATE_USER_EMAIL,
      payload: email,
    })
  }

export const getSillaStatus = (useraccountid: string | number) => {
  return api.get(`SilaUserAccount/UserAccountID/${useraccountid}`)
}

export const getAlpacaStatus = async () => {
  return await api.get('Alpaca/User/Status')
}

export const updateUserAccount = async (
  userID: string,
  firstName: string,
  middleName: string,
  lastName: string
) => {
  const response = await getUser(userID)

  if (response.status === 200) {
    return await api.put('UserAccount/Update/Profile', {
      UserAccountID: response.data.userAccountID,
      KnownPersonStatus: response.data.knownPersonStatus,
      IdentifierType: response.data.identifierType,
      Identifier: response.data.identifier,
      DateOfBirth: response.data.dateOfBirth,
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      SourceProcess: 'WebApplication',
      ChangeReason: 'Updating Known person',
      EmailAddress: response.data.emailAddress,
      TelephoneNumber: response.data.telephoneNumber,
    })
  }

  throw new Error('Error in retrieving user info.')
}

export const createAlpacaKnownPerson = async (
  userID: string,
  socialSecurityNumber: string
) => {
  const response = await getUser(userID)

  if (response.status === 200) {
    return await api.post('/Alpaca/User/KnownPerson', {
      UserAccountID: response.data.userAccountID,
      KnownPersonStatus: response.data.knownPersonStatus,
      IdentifierType: 'SocialSecurityNumber',
      Identifier: socialSecurityNumber,
      DateOfBirth: response.data.dateOfBirth,
      FirstName: response.data.firstName,
      MiddleName: response.data.middleName,
      LastName: response.data.lastName,
      SourceProcess: 'WebApplication',
      ChangeReason: 'Creating a known person entry.',
      ModifyingUserAccountID: response.data.userAccountID,
    })
  }

  throw new Error('Error in retrieving user info.')
}

export const newUserSignup = async (data: {
  username: string
  firstName: string
  lastName: string
  phoneNumber: string
  password: string
  dateOfBirth: string
  email: string
  activationUserProfileID: number
  invitationVerificationCode: string | null
}) => {
  return await api.post('/UserAccount/Create', {
    AccountStatus: 'AccountCreated',
    IsTestAccount: false,
    UserName: data.username,
    FirstName: data.firstName,
    MiddleName: '',
    LastName: data.lastName,
    TelephoneNumber: data.phoneNumber,
    Password: await getHashedPassword(data.password),
    DateOfBirth: data.dateOfBirth,
    EmailAddress: data.email,
    ModifyingUserAccountID: null,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Adding a new sootchy account.',
    ActivationUserProfileID: data.activationUserProfileID,
    InvitationVerificationCode: data.invitationVerificationCode,
  })
}

export const getActivationUserInfo = async (invitationID: string) => {
  return await api.get(`/InvitationUserProfile/${invitationID}`)
}
