import { trackApplicationSubmittedEvent } from '../../amplitude/events'
import api from '../../api'
import { SetupMy529AccountProcessType } from '../../types/my529'
import { formatPhoneNumberToSave } from '../../utilities/generalUtilities'
import {
  SETUP_MY529_ADD_BANK_INF0,
  SETUP_MY529_ADD_CHILDREN_INFO,
  SETUP_MY529_STEPPER_DECREAMENT,
  SETUP_MY529_STEPPER_INCREAMENT,
  SETUP_MY529_STEPPER_RESET,
  SETUP_MY529_STEPPER_UPDATE,
  SETUP_MY529_STEPPER_UPDATE_MULTIPLE,
} from './action.types'
import { getUser } from './userActions'

type AddChildRequestBodyType = {
  UserAccountID: number
  MinorAccountID: number | null
  FirstName: string
  MiddleName: string
  LastName: string
  EmailAddress: string | null
  DateOfBirth: string | null
  Relationship: string
  TelephoneNumber: string | null
  Identifier: string | null
  IsMy529AccountForMySelf: boolean | null
  IsMy529DelegatedToMySelf: boolean
  IsAddressSameAsParent: boolean
  Address: {
    address1: null | string
    address2: null | string
    cityOrTown: null | string
    zipCode: null | string
    state: null | string
  }
}

export const increamentMy529Step = () => async (dispatch: Function) => {
  dispatch({
    type: SETUP_MY529_STEPPER_INCREAMENT,
  })
}

export const decreamentMy529Step = () => async (dispatch: Function) => {
  dispatch({
    type: SETUP_MY529_STEPPER_DECREAMENT,
  })
}

export const setupMy529Update =
  (key: string, value: string | boolean | number | any[] | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: SETUP_MY529_STEPPER_UPDATE,
      payload: { key, value },
    })
  }

export const setupMy529UpdateMultiple =
  (fieldsObject: Partial<SetupMy529AccountProcessType>) =>
  async (dispatch: Function) => {
    dispatch({
      type: SETUP_MY529_STEPPER_UPDATE_MULTIPLE,
      payload: fieldsObject,
    })
  }

export const setupMy529Reset = () => async (dispatch: Function) => {
  dispatch({
    type: SETUP_MY529_STEPPER_RESET,
  })
}

export const setupMy529AddChildrenInfo =
  (data: any) => async (dispatch: Function) => {
    dispatch({
      type: SETUP_MY529_ADD_CHILDREN_INFO,
      payload: data,
    })
  }

export const setupMy529AddBankInfo =
  (data: any) => async (dispatch: Function) => {
    dispatch({
      type: SETUP_MY529_ADD_BANK_INF0,
      payload: data,
    })
  }

export const addChildMy529API = async (data: AddChildRequestBodyType) => {
  const addressBody = data.IsAddressSameAsParent
    ? {
        StreetAddress1: null,
        StreetAddress2: null,
        City: null,
        State: null,
        ZipCode: null,
      }
    : {
        StreetAddress1: data.Address.address1,
        StreetAddress2: data.Address.address2,
        City: data.Address.cityOrTown,
        State: data.Address.state,
        ZipCode: data.Address.zipCode,
      }

  return await api.post('/MinorAccount/Utah/Beneficiary', {
    UserAccountID: data.UserAccountID,
    ParentUserAccountID: data.UserAccountID,
    ModifyingUserAccountID: data.UserAccountID,
    MinorAccountID: data.MinorAccountID,
    FirstName: data.FirstName,
    MiddleName: data.MiddleName,
    LastName: data.LastName,
    EmailAddress: data.EmailAddress,
    DateOfBirth: data.DateOfBirth,
    Relationship: data.Relationship,
    TelephoneNumber: data.TelephoneNumber
      ? formatPhoneNumberToSave(data.TelephoneNumber)
      : null,
    IdentifierType: 'SocialSecurityNumber',
    Identifier: data.Identifier,
    IsMy529AccountForMySelf: data.IsMy529AccountForMySelf,
    IsMy529DelegatedToMySelf: data.IsMy529DelegatedToMySelf,
    IsAddressSameAsParent: data.IsAddressSameAsParent,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Adding child',
    ...addressBody,
  })
}

export const setupMy529OtherInvestmentOptions = async () => {
  return await api.get('/Utah/InvestmentOptions')
}

export const UtahApplication = async (data: any) => {
  const addressBody = data.IsAddressSameAsParent
    ? {
        StreetAddress1: null,
        StreetAddress2: null,
        City: null,
        State: null,
        ZipCode: null,
        CountryCode: null,
      }
    : {
        StreetAddress1: data.Address.address1,
        StreetAddress2: data.Address.address2,
        City: data.Address.cityOrTown,
        State: data.Address.state,
        ZipCode: data.Address.zipCode,
        CountryCode: 'USA',
      }

  trackApplicationSubmittedEvent({ userId: data.UserAccountID })

  return await api.post('/Utah/Application', {
    UserAccountID: data.UserAccountID,
    ModifyingUserAccountID: data.UserAccountID,
    MinorAccountID: data.MinorAccountID,
    ApplicationStatus: data.ApplicationStatus,
    InvestmentOption: data.InvestmentOption,
    My529AccountNumber: data.My529AccountNumber,
    IsMy529AccountForMySelf: data.IsMy529AccountForMySelf,
    IsMy529DelegatedToMySelf: data.IsMy529DelegatedToMySelf,
    IsAddressSameAsParent: data.IsAddressSameAsParent,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Utah Application',
    ...addressBody,
  })
}

export const getMy529Children = async () => {
  return await api.get('/Utah/Application/Minors')
}

export const getApplicationStatus = async () => {
  return await api.get('/Utah/Application/Status')
}

export const saveMy529SocialSecurity = async (ssn: string, userID: string) => {
  const getUserRes = await getUser(userID)
  if (getUserRes.status !== 200) throw new Error('Unable to get user.')

  return await api.post('/Utah/KnownPerson', {
    UserAccountID: getUserRes.data.userAccountID,
    KnownPersonStatus: 'UtahApplicationStarted',
    IdentifierType: 'SocialSecurityNumber',
    Identifier: ssn,
    DateOfBirth: getUserRes.data.dateOfBirth,
    FirstName: getUserRes.data.firstName,
    MiddleName: getUserRes.data.middleName,
    LastName: getUserRes.data.lastName,
    ModifyingUserAccountID: getUserRes.data.userAccountID,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Saving SSN',
  })
}
