import dayjs from 'dayjs'
import React from 'react'
import { FaDotCircle } from 'react-icons/fa'

type Proptypes = {
  partners?: string
  activationEventVenue?: string
  eventDate?: string
  description?: string
  activationMapLocation?: string
}

export const NextActivationEventHeadline = ({
  partners,
  activationEventVenue,
  eventDate,
  description,
}: Proptypes) => {
  return (
    <div className="px-[20px] -mt-[48px] md:-mt-[80px] text-white h-full pr-[8%] pt-[84px] flex flex-col justify-center">
      <h1 className="text-[32px] md:text-[40px] text-white font-semibold leading-[38.4px] md:leading-[48px] mb-[16px]">
        Brighten your child's future at our next college fund day
      </h1>
      <p className="text-[17px] font-normal leading-[32px] mb-[56px]">
        At this free event your child will learn how to build a prosperous
        future by accessing funds for college, trade school, homeownership, or
        starting a business.
      </p>

      <div>
        <h4 className="text-[18px] md:text-[22px] font-semibold leading-[28.8px] text-white">
          {activationEventVenue}
        </h4>
        <p className="text-[16px] font-normal leading-[32px]">
          {dayjs(eventDate).format('dddd, MMMM D YYYY, h:mm A')}
        </p>
      </div>
    </div>
  )
}

export const NextActivationEventLocation = ({
  partners,
  activationMapLocation,
  activationEventVenue,
  eventDate,
  description,
}: Proptypes) => {
  return (
    <div className="bg-white rounded-lg py-[36px] px-[24px] md:px-[40px] mb-[15px]">
      <h4 className="text-[24px] leading-[28.8px] font-semibold mb-[12px]">
        {activationEventVenue}
      </h4>
      <a
        href={activationMapLocation}
        target="_blank"
        rel="noopener noreferrer"
        className="font-thin leading-[24px] text-[15px] text-primaryDark hover:text-primaryLight"
      >
        Open location in maps
      </a>
    </div>
  )
}

export const ImportantTipsForEvent = () => {
  return (
    <ul>
      <li className="flex w-full space-x-2 justify-center">
        <div className="mt-2">
          <FaDotCircle size={12} />
        </div>
        <p>
          This event is meant for children. Parent attendance is welcome but
          optional.
        </p>
      </li>
      <li className="flex w-full space-x-2 justify-center">
        <div className="mt-2">
          <FaDotCircle size={12} />
        </div>
        <p>
          To get the most out of this event, create a profile on the Sootchy app
          after registering.
        </p>
      </li>
      <li className="flex w-full space-x-2 justify-center">
        <div className="mt-2">
          <FaDotCircle size={12} />
        </div>
        <p>
          To unlock opportunities after the event, we encourage you to set up a
          free Sootchy savings account.
        </p>
      </li>
      <li className="flex w-full space-x-2 justify-center">
        <div className="mt-2">
          <FaDotCircle size={12} />
        </div>
        <p>
          Our goal is to unlock exclusive funds and opportunities for your
          family
        </p>
      </li>
    </ul>
  )
}
