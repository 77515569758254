import React from 'react'

const WalletHomeLoader = () => {
  return (
    <div className="h-screen">
      {/* <LoadingSpinner size={15} color="#004F4E" /> */}
      <div className="w-full h-[360px] bg-slate-200 animate-pulse"></div>
      <div className="w-[90%] h-[140px] md:w-[480px] m-auto p-7  text-center rounded-lg -translate-y-16 bg-white">
        <div className="flex justify-between">
          <div className="w-[45%] h-[60px] py-4 bg-slate-200 rounded-lg"></div>
          <div className="w-[45%] h-[60px] py-4 bg-slate-200 rounded-lg"></div>
        </div>
      </div>
      <div className="w-[90%] md:w-[480px] m-auto">
        <div className="animate-pulse flex border-b-2 py-2 border-slate-200 border-dashed">
          <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
          <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
            <div className="h-2 bg-slate-200 rounded w-[90%] animate-pulse"></div>
            <div className="h-2 bg-slate-200 rounded w-[40%] animate-pulse"></div>
          </div>
        </div>
        <div className="flex border-b-2 py-2 border-slate-200 border-dashed animate-pulse">
          <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
          <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
            <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
            <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
          </div>
        </div>
        <div className="flex border-b-2 py-2 border-slate-200 border-dashed animate-pulse">
          <div className="w-[48px] h-[48px] rounded-full bg-slate-200"></div>
          <div className="basis-3/4  ml-[10px] flex flex-col justify-center space-y-4">
            <div className="h-2 bg-slate-200 rounded w-[90%]"></div>
            <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WalletHomeLoader
