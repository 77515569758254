import api from '../../api'
import { initialState, QuickstartState } from '../reducers/linkReducer'

export const GET_LINK_TOKEN = 'GET_LINK_TOKEN'
export const PLAID_INTEGRATION_DONE = 'PLAID_INTEGRATION_DONE'

export const PlaidLinkToken =
  (payload: QuickstartState) => async (dispatch: Function) => {
    dispatch({
      type: GET_LINK_TOKEN,
      payload,
    })
  }

export const PlaidIntegrationSuccess = () => async (dispatch: Function) => {
  dispatch({
    type: PLAID_INTEGRATION_DONE,
    payload: {},
  })
}
export interface BankAccount {
  Id: string
  Name: string
  Mask: string
  AccountType: string
  AccountSubType: string
}
export interface PlaidPublicMetadata {
  PublicToken: string
  PlaidAccountID: string
  BankName: string
  BankID: string
  Accounts: BankAccount[]
}
export const linkTokenAccess = () => {
  return async (dispatch: any) => {
    try {
      const response = await api.post('/Plaid/Token/Create')

      let payload: QuickstartState = initialState
      if (!response) {
        payload.linkToken = null
      } else {
        const data = response.data
        if (data) {
          if (data.error != null) {
            payload.linkToken = null
            payload.linkTokenError = data.error
          } else {
            payload.linkToken = data.link_token
            payload.linkSuccess = true
            localStorage.setItem('link_token', data.link_token)
          }
        }
      }
      dispatch(PlaidLinkToken(payload))
    } catch (err) {
      console.log(err)
    }
  }
}

export const publicTokenAccess = (data: PlaidPublicMetadata) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post('Plaid/Account/Link', data)
      if (response.status === 200) dispatch(PlaidIntegrationSuccess())
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }
}

export const getAllBanks = () => {
  //try {
  return api.get('Plaid/Account/Banks')
  //  if (response.status !== 200) return { success: false, response }

  //  return { success: true, response }
  //} catch (err: any) {
  //  return { success: false, err }
  //}
}

export const setDefaultBank = async ({
  silaUserAccountID,
  plaidBankAccountID,
  modifyingUserAccountID,
}: {
  silaUserAccountID: number
  plaidBankAccountID: number
  modifyingUserAccountID: number
}) => {
  try {
    const response = await api.put('/Plaid/Account/DefaultBank', {
      SilaUserAccountID: silaUserAccountID,
      PlaidBankAccountID: plaidBankAccountID,
      ModifyingUserAccountID: modifyingUserAccountID,
      SourceProcess: 'ManualTest',
      ChangeReason: 'Updating a bank account.',
    })

    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    return { success: false, err }
  }
}

export const removeBank = async ({
  silaUserAccountID,
  plaidBankAccountID,
  modifyingUserAccountID,
}: {
  silaUserAccountID: number
  plaidBankAccountID: number
  modifyingUserAccountID: number
}) => {
  try {
    const response = await api.delete('/Plaid/Account/Bank', {
      data: {
        SilaUserAccountID: silaUserAccountID,
        PlaidBankAccountID: plaidBankAccountID,
        ModifyingUserAccountID: modifyingUserAccountID,
        SourceProcess: 'ManualTest',
        ChangeReason: 'Removing a bank account.',
      },
    })

    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    return { success: false, err }
  }
}

export const getWallets = () => {
  //try {
  return api.get('/SilaWallet')
  //   if (response.status !== 200) return { success: false, response }

  //   return { success: true, response }
  // } catch (err: any) {
  //   return { success: false, err }
  // }
}

export const getWallet = async (walletName: string) => {
  try {
    const response = await api.get(`/SilaWallet/Wallet/${walletName}`)
    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    return { success: false, err }
  }
}

export const createWallet = ({
  walletName,
  walletColor,
}: {
  walletName: string
  walletColor: string
}) => {
  return api.post('/SilaWallet', {
    WalletName: walletName,
    WalletColor: walletColor,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Creating a wallet',
  })
  //   if (response.status !== 200) return { success: false, response }

  //   return { success: true, response }
  // } catch (err: any) {
  //   return { success: false, err }
  // }
}

export const updateWallet = async ({
  walletName,
  walletColor,
  silaWalletID,
  userAccountID,
  walletAddress,
}: {
  walletName: string
  walletColor: string
  silaWalletID: number | null
  userAccountID: string
  walletAddress: string
}) => {
  try {
    const response = await api.put('/SilaWallet', {
      SilaWalletID: silaWalletID,
      UserAccountID: parseInt(userAccountID),
      WalletAddress: walletAddress,
      WalletName: walletName,
      WalletColor: walletColor,
      SourceProcess: 'WebApplication',
      ChangeReason: 'Creating a wallet',
    })

    if (response.status !== 200) return { success: false, response }

    return { success: true, response }
  } catch (err: any) {
    return { success: false, err }
  }
}
