import React from 'react'
import { useSelector } from 'react-redux'
import { RootReduxStateType } from '../../../types'
import Alert from './index'
const AlertWrapper = () => {
  const alerts = useSelector((state: RootReduxStateType) => state.alerts)

  return (
    <div
      className="absolute inset-x-0 top-[20px] w-[90%] mx-auto"
      style={{ zIndex: 500 }}
    >
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          type={alert.type}
          title={alert.title}
          message={alert.message}
          id={alert.id}
        />
      ))}
    </div>
  )
}

export default AlertWrapper
