import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { RootReduxStateType } from '../../../types'

type PropsType = {
  showSignUp?: boolean
  showUser?: boolean
  showClose?: boolean
  showLogin?: boolean
  isFixable?: boolean
  wallet?: boolean
}

const UserMenu: React.FC<PropsType> = ({
  showSignUp,
  showUser,
  showClose,
  showLogin,
  isFixable,
  wallet,
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const menu = [
    {
      title: t('User.EditProfile'),
      url: '/profile',
    },
    {
      title: t('Base.GetHelp'),
      url: '/help',
    },
    {
      title: t('Base.LogOut'),
      url: '/logout',
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.button} onClick={() => setIsOpen(!isOpen)}>
        {wallet ? (
          <img
            className={styles.icon}
            src="/assets/images/user-circle_white.svg"
            alt="user circle"
          />
        ) : (
          <img
            src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/user-circle.svg"
            alt="close"
            className={styles.icon}
          />
        )}
      </div>
      <div
        className={`${styles.menu} ${
          isOpen
            ? 'pointer-events-auto opacity-100'
            : 'pointer-events-none opacity-0'
        }`}
      >
        <ul className={styles.ul}>
          {menu.map((item, i) => (
            <li key={i} className={styles.li}>
              <NavLink className={styles.link} to={item.url}>
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const styles = {
  wrapper: 'relative',
  button: '',
  icon: 'cursor-pointer block w-[28px] h-[28px]',
  menu: 'absolute right-0 w-[230px] shadow-[0px_2px_6px_rgba(0,0,0,0.17),_0px_6px_18px_rgba(0,0,0,0.1)] top-[62px] bg-white z-40 rounded-[10px] p-[4px] transition-[opacity] duration-300 ease-in-out',
  ul: 'm-0 p-0 list-none',
  li: 'block',
  link: 'block h-[40px] rounded-[10px] text-[14px] leading-[18px] font-bold px-[8px] py-[11px] text-primaryDark',
}

export default UserMenu
