import axios from 'axios'
import api, { setLoginRes } from '../../api'
import {
  DOCUMENT_UPLOAD_STEPPER_DECREMENT,
  DOCUMENT_UPLOAD_STEPPER_INCREMENT,
  DOCUMENT_UPLOAD_STEPPER_RETAKE,
  DOCUMENT_UPLOAD_WITH_CAMERA,
  ENABLE_MFA,
  STORE_UPLOAD_DATA,
  UPDATE_UPLOAD_STEP,
  UPDATE_USERACCOUNT_PROFILE,
  UPDATE_USERACCOUNT_PROFILE_PROGRESS,
} from './action.types'

// ....Two Factor Authentciation

export const changeMFAOption =
  (data: boolean) => async (dispatch: Function) => {
    dispatch({
      type: ENABLE_MFA,
      payload: data,
    })
  }

export const setupMultiFactorAuth = async () => {
  try {
    const res = await api.get('/UserAccount/MFA')

    if (res.status === 200) {
      //console.log('On 2fa get', res)
      return res.data
    }

    //return res
  } catch (error) {
    console.log('Failed to load MFA', error)
  }
}

export const saveMultiFactorAuth = (id: string, key: string) => {
  return api.post('/UserAccount/MFA', {
    UserAccountID: id,
    MultiFactorKey: key,
    sourceProcess: 'WebApplication',
    changeReason: 'Setup 2FA',
  })
}

export const getUserInformation = async (data?: any) => {
  console.log('User data')
}

export const validateMultiFactorAuth = async (code: string, navigate: any) => {
  try {
    //const user = await getUserInformation
    const res = await api.post('/UserAccount/MFA/validate', {
      code,
    })
    console.log('Runs in actions')
    if (res.status === 200) {
      setLoginRes(res.data)
      return { status: res.status, data: res.data }
    } else {
      //localStorage.clear();
      return { status: res.status, data: 'Failed to valdiate MFA' } // You should `return`  or `throw new Error()`, you cannot to `throw`
    }
  } catch (error) {
    console.log('Failed to valdiate MFA', error)
    return error
  }
}

export const updateProfileProgress = () => (dispatch: Function) => {
  dispatch({
    type: UPDATE_USERACCOUNT_PROFILE_PROGRESS,
  })
}

export const updateProfileStatus =
  (key: string, value: boolean) => (dispatch: Function) => {
    dispatch({
      type: UPDATE_USERACCOUNT_PROFILE,
      payload: { key, value },
    })
  }

export const updateUserAccount = async (
  id: number | string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  telephoneNumber: string | null,
  emailAddress: string,
  middleName?: string
) => {
  try {
    const res: any = await api.put('/UserAccount/Update/Profile', {
      userAccountID: id,
      firstName,
      lastName,
      middleName,
      dateOfBirth,
      telephoneNumber,
      emailAddress,
      modifyingUserAccountID: id,
      sourceProcess: 'WebApplication',
      changeReason: 'Update UserAccount',
    })

    //console.log('Res after Update', res)
    return res
  } catch (error) {
    console.log('Failed to Update account')
  }
}

export const updateSchoolDetails = async (
  id: number | string,
  schoolID: number | string,
  city: string,
  state: string,
  school: string,
  grade: string,
  isHomeschooled: boolean
) => {
  try {
    const res: any = await api.put('/UserAccount/schoolinfo', {
      userAccountID: id,
      userSchoolID: schoolID,
      city,
      state,
      school,
      grade,
      isHomeschooled,
      modifyingUserAccountID: id,
      sourceProcess: 'WebApplication',
      changeReason: 'Update School Details',
    })

    console.log('Res after Update', res)
    return res
  } catch (error) {
    console.log('Failed to Update Schools')
  }
}

export const incrementDocumentStepper = () => (dispatch: Function) => {
  dispatch({
    type: DOCUMENT_UPLOAD_STEPPER_INCREMENT,
  })
}

export const updateUploadStep = (step: number) => (dispatch: Function) => {
  dispatch({
    type: UPDATE_UPLOAD_STEP,
    payload: step,
  })
}

export const decrementDocumentStepper = () => (dispatch: Function) => {
  dispatch({
    type: DOCUMENT_UPLOAD_STEPPER_DECREMENT,
  })
}

export const resetDocumentStepper = () => (dispatch: Function) => {
  dispatch({
    type: DOCUMENT_UPLOAD_STEPPER_RETAKE,
  })
}

export const storeUploadData = (data: any) => (dispatch: Function) => {
  dispatch({
    type: STORE_UPLOAD_DATA,
    payload: data,
  })
}

export const getRequiredDocumentsFromSilla = async () => {
  try {
    const res: any = await api.get(
      '/SilaDocument/Required?includeUploaded=false'
    )

    //console.log('From Sila Document API', res)

    if (res.status === 200) {
      return res.data
    }

    //console.log('Response from Silla', res.data)
  } catch (error) {
    console.log('Failed to get required docs', error)
  }
}

export const handleUploadByCamera = (data: boolean) => (dispatch: Function) => {
  dispatch({
    type: DOCUMENT_UPLOAD_WITH_CAMERA,
    payload: data,
  })
}

export const sendEmailFromContactForm = async ({
  email,
  message,
  name,
  occupation,
  phoneNumber,
  state,
  subject,
  username,
}: {
  email: string
  message: string
  name: string
  occupation: string
  phoneNumber: string
  state: string
  subject: string
  username: string
}) => {
  return await axios.post(process.env.REACT_APP_CONTACT_FORM_EMAIL_API ?? '', {
    email,
    message,
    name,
    occupation,
    phoneNumber,
    state,
    subject,
    username,
  })
}
