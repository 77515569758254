import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
// @ts-ignore
import { AzureMP } from 'react-azure-mp'
import {
  increamentSavingsStepperStep,
  setupSavingsStepperUpdate,
} from '../../../../redux/actions/setupSavingsStepperActions'
import { getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { formatSSN, validdateSsn } from '../../../../utilities/generalUtilities'
import SafeAreaView from '../../../Common/SafeAreaView'
import SilaSetupBottom from '../SilaSetupBottom'

// This is seperated to fix azure mp re-rendering
const VideoComponent = React.memo(() => {
  return (
    <div className="w-full">
      <AzureMP
        skin="amp-flush"
        src={[
          {
            src: 'https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/d2283693-d020-46af-827d-0f56e6c83005/CraigFootage1.ism/manifest',
            type: 'video/mp4',
          },
        ]}
        options={{
          controls: true,
          autoplay: false,
          nativeControlsForTouch: true,
        }}
      />
    </div>
  )
})

const SetupSavingsStepperFour = () => {
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)

  const setupSavingsStepper = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper
  )
  const dispatch = useDispatch()
  const [loading, _] = useState<boolean>(false)
  const [isKYCFailed, setIsKYCFailed] = useState<boolean>(false)

  useEffect(() => {
    getUserInformation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUserInformation = async () => {
    const res: any = await getUser(user.id)

    res.data.knownPersonStatus === 'KycFailed' && setIsKYCFailed(true)
  }

  const handleNext = () => {
    if (isKYCFailed) {
      navigate('/savings-account-verification')
    } else {
      dispatch(increamentSavingsStepperStep())
    }
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value
    dispatch(setupSavingsStepperUpdate(key, formatSSN(value)))
  }

  return (
    <>
      <SafeAreaView>
        <VideoComponent />
        <div>
          <p className="leading-relaxed py-2 mt-4">
            US Federal regulations require us to obtain your Social Security
            Number to confirm your identity. This will not impact your credit.
          </p>

          <input
            type="text"
            placeholder="Social security number (SSN)"
            className="w-full outline-none bg-[#E3F2F2] px-4 py-4 rounded-lg mt-6"
            name="socialSecurity"
            value={setupSavingsStepper.socialSecurity ?? undefined}
            onChange={handleOnChange}
          />
        </div>
      </SafeAreaView>
      <SilaSetupBottom
        handleNext={handleNext}
        disabled={
          loading
            ? loading
            : !validdateSsn(setupSavingsStepper.socialSecurity ?? '')
        }
      />
    </>
  )
}

export default SetupSavingsStepperFour
