import React from 'react'
import { useSelector } from 'react-redux'
import { RootReduxStateType } from '../../../types'
// @ts-ignore
import Deposit from '../../../assets/Deposit.png'
// @ts-ignore
import Withdraw from '../../../assets/Withdraw.png'
// @ts-ignore
import Received from '../../../assets/Received.png'
// @ts-ignore
import Sent from '../../../assets/Sent.png'

export const TransactionMessages = (transaction: any, walletName: 'string') => {
  const user = useSelector((state: RootReduxStateType) => state.user)
  const { userName } = user
  const {
    transactionType,
    usdStatus,
    tokenStatus,
    sourceUserName,
    sourceWalletName,
    destinationUserName,
    destinationWalletName,
    silaAmount,
    silaTransactionType,
    silaOutcome,
  } = transaction

  const formattedAmount: any = parseFloat(silaAmount).toFixed(2)
  const amount = formattedAmount / 100

  const DetailedOperationMessages = () => {
    return (
      <>
        {transactionType === 'issue' &&
        usdStatus === 'success' &&
        tokenStatus === 'success'
          ? `You added $${amount}`
          : (transactionType === 'issue' && usdStatus !== 'success') ||
            tokenStatus !== 'success'
          ? `Processing deposit of $${amount}`
          : transactionType === 'transfer' &&
            tokenStatus === 'success' &&
            sourceUserName === userName &&
            destinationUserName === userName
          ? `You transferred $${amount} to ${destinationWalletName}`
          : transactionType === 'transfer' &&
            tokenStatus !== 'sucess' &&
            sourceUserName === userName &&
            destinationUserName === userName
          ? `Processing transfer of $${amount} to ${destinationWalletName} `
          : transactionType === 'transfer' &&
            tokenStatus === 'success' &&
            sourceUserName === userName &&
            destinationUserName !== userName
          ? `You sent $${amount} to ${destinationUserName}`
          : transactionType === 'transfer' &&
            tokenStatus !== 'success' &&
            sourceUserName === userName &&
            destinationUserName !== userName
          ? `Processing transfer of $${amount} to user ${destinationUserName}`
          : transactionType === 'transfer' &&
            tokenStatus === 'success' &&
            sourceUserName !== userName &&
            destinationUserName === userName
          ? `You received $${amount} from ${sourceUserName}`
          : transactionType === 'success' &&
            tokenStatus !== 'success' &&
            sourceUserName !== userName &&
            destinationUserName === userName
          ? `Processing transfer of $${amount} from user ${sourceUserName}`
          : transactionType === 'redeem' &&
            usdStatus === 'success' &&
            tokenStatus === 'success'
          ? `You withdrew $${amount}`
          : transactionType === 'redeem' &&
            usdStatus !== 'success' &&
            `Processing withdraw of $${amount}`}
      </>
    )
  }

  // const TransactionSummary = () => {
  //   return (
  //     <>
  //       {silaTransactionType === 'Issue' && silaOutcome === 'Success'
  //         ? `You added $${amount}`
  //         : silaTransactionType === 'Issue' && silaOutcome === 'Pending'
  //         ? `Processing deposit of $${amount}`
  //         : silaTransactionType === 'Issue' && silaOutcome === 'Failed'
  //         ? `Failed to deposit $${amount}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Success' &&
  //           sourceUserName === userName &&
  //           destinationUserName === userName
  //         ? `You sent $${amount} to ${destinationWalletName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Pending' &&
  //           sourceUserName === userName &&
  //           destinationUserName === userName
  //         ? `Processing transfer of $${amount} to ${destinationWalletName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Failed' &&
  //           sourceUserName === userName &&
  //           destinationUserName === userName
  //         ? `Failed to send $${amount} to ${destinationWalletName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Success' &&
  //           sourceUserName === userName &&
  //           destinationUserName !== userName
  //         ? `You sent $${amount} to ${destinationUserName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Pending' &&
  //           sourceUserName === userName &&
  //           destinationUserName !== userName
  //         ? `Processing transfer of $${amount} to ${destinationUserName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Failed' &&
  //           sourceUserName === userName &&
  //           destinationUserName !== userName
  //         ? `Failed to send $${amount} to ${destinationUserName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Success' &&
  //           sourceWalletName !== currentWalletName &&
  //           destinationWalletName === currentWalletName
  //         ? 'You have recived'
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Success' &&
  //           sourceUserName !== userName &&
  //           destinationUserName === userName
  //         ? `You received $${amount} from ${sourceUserName}`
  //         : silaTransactionType === 'Transfer' &&
  //           silaOutcome === 'Pending' &&
  //           sourceUserName !== userName &&
  //           destinationUserName === userName
  //         ? `Processing transfer of $${amount} from ${sourceUserName}`
  //         : silaTransactionType === 'Redeem' && silaOutcome === 'Success'
  //         ? `You withdrew $${amount}`
  //         : silaTransactionType === 'Redeem' && silaOutcome === 'Pending'
  //         ? `Processing withdraw of $${amount}`
  //         : silaTransactionType === 'Redeem' &&
  //           silaOutcome === 'Failed' &&
  //           `Failed to withdraw $${amount}`}
  //     </>
  //   )
  // }

  const TransactionSummary = () => {
    // if (silaTransactionType === 'Issue') {
    //   if (silaOutcome === 'Success') {
    //     return `You added $${amount}`
    //   } else if (silaOutcome === 'Pending') {
    //     return `Processing deposit of $${amount}`
    //   } else if (silaOutcome === 'Failed') {
    //     return `Failed to deposit $${amount}`
    //   }
    // }

    // if (silaTransactionType === 'Transfer') {
    //   if (silaOutcome === 'Success') {
    //     // Between Wallets
    //     if (sourceUserName === destinationUserName) {
    //       if (
    //         sourceWalletName === walletName &&
    //         destinationWalletName !== walletName
    //       ) {
    //         return `You sent $${amount} to ${destinationWalletName}`
    //       } else if (
    //         sourceWalletName !== walletName &&
    //         destinationWalletName === walletName
    //       ) {
    //         return `You received $${amount} from ${sourceWalletName}`
    //       }
    //     }

    //     //Between People
    //     if (sourceUserName !== destinationUserName) {
    //       if (sourceUserName === userName && destinationUserName !== userName) {
    //         return `You sent $${amount} to ${destinationUserName}`
    //       } else if (
    //         sourceUserName !== userName &&
    //         destinationUserName === userName
    //       ) {
    //         return `You received $${amount} from ${sourceUserName}`
    //       }
    //     }
    //   } else if (silaOutcome === 'Pending') {
    //     return `Processing transfer of $${amount}`
    //   } else if (silaOutcome === 'Failed') {
    //     return `Failed to transfer $${amount}`
    //   }
    // }

    // if (silaTransactionType === 'Redeem') {
    //   if (silaOutcome === 'Success') {
    //     return `You withrew $${amount}`
    //   } else if (silaOutcome === 'Pending') {
    //     return `Processing withdraw of $${amount}`
    //   } else if (silaOutcome === 'Failed') {
    //     return `Failed to withdraw $${amount}`
    //   }
    // }
    if (silaTransactionType === 'Issue') {
      if (silaOutcome === 'Success') {
        return `+$${amount}`
      } else if (silaOutcome === 'Pending') {
        return `Processing deposit of $${amount}`
      } else if (silaOutcome === 'Failed') {
        return `Failed to deposit $${amount}`
      }
    }

    if (silaTransactionType === 'Transfer') {
      if (silaOutcome === 'Success') {
        // Between Wallets
        if (sourceUserName === destinationUserName) {
          if (
            sourceWalletName === walletName &&
            destinationWalletName !== walletName
          ) {
            return `-$${amount}`
          } else if (
            sourceWalletName !== walletName &&
            destinationWalletName === walletName
          ) {
            return `+$${amount}`
          }
        }

        //Between People
        if (sourceUserName !== destinationUserName) {
          if (sourceUserName === userName && destinationUserName !== userName) {
            return `-$${amount}`
          } else if (
            sourceUserName !== userName &&
            destinationUserName === userName
          ) {
            return `+$${amount}`
          }
        }
      } else if (silaOutcome === 'Pending') {
        return `Processing transfer of $${amount}`
      } else if (silaOutcome === 'Failed') {
        return `Failed to transfer $${amount}`
      }
    }

    if (silaTransactionType === 'Redeem') {
      if (silaOutcome === 'Success') {
        return `-$${amount}`
      } else if (silaOutcome === 'Pending') {
        return `Processing withdraw of $${amount}`
      } else if (silaOutcome === 'Failed') {
        return `Failed to withdraw $${amount}`
      }
    }
  }

  // const TransactionIcons = () => {
  //   return (
  //     <img
  //       src={
  //         silaTransactionType === 'Issue' && silaOutcome === 'Success'
  //           ? Deposit
  //           : silaTransactionType === 'Issue' && silaOutcome === 'Pending'
  //           ? Deposit
  //           : silaTransactionType === 'Issue' && silaOutcome === 'Failed'
  //           ? Failed
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Success' &&
  //             sourceUserName === userName &&
  //             destinationUserName === userName
  //           ? Sent
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Pending' &&
  //             sourceUserName === userName &&
  //             destinationUserName === userName
  //           ? Sent
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Failed' &&
  //             sourceUserName === userName &&
  //             destinationUserName === userName
  //           ? Failed
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Success' &&
  //             sourceUserName === userName &&
  //             destinationUserName !== userName
  //           ? Sent
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Pending' &&
  //             sourceUserName === userName &&
  //             destinationUserName !== userName
  //           ? Sent
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Failed' &&
  //             sourceUserName === userName &&
  //             destinationUserName !== userName
  //           ? Failed
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Success' &&
  //             sourceUserName !== userName &&
  //             destinationUserName === userName
  //           ? Received
  //           : silaTransactionType === 'Transfer' &&
  //             silaOutcome === 'Pending' &&
  //             sourceUserName !== userName &&
  //             destinationUserName === userName
  //           ? Received
  //           : silaTransactionType === 'Redeem' && silaOutcome === 'Success'
  //           ? Withdraw
  //           : silaTransactionType === 'Redeem' && silaOutcome === 'Pending'
  //           ? Withdraw
  //           : silaTransactionType === 'Redeem' &&
  //             silaOutcome === 'Failed' &&
  //             Failed
  //       }
  //       alt="transactionIcon"
  //     />
  //   )
  // }

  const TransactionIcons = () => {
    if (silaTransactionType === 'Issue') {
      return <img src={Deposit} alt="Deposit" />
    } else if (silaTransactionType === 'Redeem') {
      return <img src={Withdraw} alt="Withdraw" />
    } else if (silaTransactionType === 'Transfer') {
      // Between Wallets
      if (sourceUserName === destinationUserName) {
        if (
          sourceWalletName === walletName &&
          destinationWalletName !== walletName
        ) {
          return <img src={Sent} alt="Sent" />
        } else if (
          sourceWalletName !== walletName &&
          destinationWalletName === walletName
        ) {
          return <img src={Received} alt="Receive" />
        }
      }

      //Between People
      if (sourceUserName !== destinationUserName) {
        if (sourceUserName === userName && destinationUserName !== userName) {
          return <img src={Sent} alt="Sent" />
        } else if (
          sourceUserName !== userName &&
          destinationUserName === userName
        ) {
          return <img src={Received} alt="Receive" />
        }
      }
    }
  }

  return {
    DetailedOperationMessages,
    TransactionIcons,
    TransactionSummary,
  }
}
