import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PinInput } from 'react-input-pin-code'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type PropsType = {
  onSuccess: (e?: any) => void
  openModal: boolean
  setOpenModal: (x: boolean) => void
}

const NewTwoFactorAuthentication: React.FC<PropsType> = ({
  onSuccess,
  openModal,
  setOpenModal,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [values, setValues] = useState(['', '', '', '', '', ''])
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  // const handleValidate = async (e: any) => {
  //   const code = values.join('')

  //   values.join('').length === 6 &&
  //     (await validateMultiFactorAuth(code, navigate)
  //       .then((res: any) => {
  //         if (res.status === 200) {
  //           const {
  //             UserName: userName,
  //             UserAccountID: id,
  //             is2FAEnabled: is2FA,
  //             DateOfBirth,
  //           } = getUserFromToken(res.data.accessToken)
  //           setLoading(false)

  //           dispatch({
  //             type: LOG_IN,
  //             payload: {
  //               id,
  //               userName,
  //               userAge: getUserAge(dayjs(DateOfBirth).format('YYYY-MM-DD')),
  //               emailAddress: '',
  //               token: res.data.accessToken,
  //               is2FAEnabled: is2FA,
  //             },
  //           })

  //           onSuccess()
  //           setOpenModal(false)
  //           setValues(['', '', '', '', '', ''])
  //         } else {
  //           setLoading(false)
  //           setErrorMessage('Your code is not valid, please try again')
  //         }
  //       })
  //       .catch((err) => console.log('Failed to validate')))
  // }
  // Commented out temporarily to disable 2FA
  // useEffect(() => {
  //   values.join('').length === 6 && handleValidate(values.join(''))
  // }, [values])

  return (
    <div className="bg-white absolute top-0 right-0 w-full h-full z-50 rounded-lg text-center">
      <p className="text-center text-primaryDark mt-10">
        Enter Two Factor Code to proceed
      </p>
      <p className="text-md mb-4 text-primaryDark">{t('2FA.ModalTitle')}</p>
      <p className="text-sm text-red-500 mb-2">{errorMessage}</p>

      <div className="flex items-center justify-center mt-10">
        <PinInput
          values={values}
          size="md"
          placeholder=""
          onChange={(value, index, values) => setValues(values)}
          autoComplete="off"
          autoFocus
          focusBorderColor="#0E6D6C"
          validBorderColor={errorMessage ? 'rgb(220,53,69)' : '#0E6D6C'}
          //onComplete={handleValidate}
          disabled={loading}
          inputClassName="text-primaryDark"
        />
      </div>
    </div>
  )
}

export default NewTwoFactorAuthentication
