import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAlertAction } from '../../../../redux/actions/alertActions'
import { increamentMy529Step } from '../../../../redux/actions/my529Actions'
import { RootReduxStateType } from '../../../../types'
import SafeAreaView from '../../../Common/SafeAreaView'
import My529SetupBottom from '../My529SetupBottom'
import My529ChildMain from './main'

const ChildrenStep = () => {
  const dispatch = useDispatch()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)
  const [loading, setLoading] = useState<boolean>(false)

  const handleNext = () => {
    setLoading(true)
    if (my529.invalidFields.length > 0) {
      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'SSN Required',
          message: `SSN is required to submit the applications, please add SSN for ${my529.invalidFields}`,
          autoClose: true,
          autoCloseDuration: 3000,
        })
      )
      setLoading(false)
    } else {
      dispatch(increamentMy529Step())
      setLoading(false)
    }
  }

  return (
    <>
      <SafeAreaView showIcons={false}>
        <p className="text-[18px] font-semibold">Your children</p>
        <p className="py-2">
          Please provide the details about your children so we can set up their
          wallets.
        </p>

        <My529ChildMain />
      </SafeAreaView>

      <My529SetupBottom
        disabled={
          loading
            ? true
            : my529.childrenInfo && my529.childrenInfo.length <= 0
            ? true
            : false
        }
        handleNext={handleNext}
      />
    </>
  )
}

export default ChildrenStep
