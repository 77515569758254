import { combineReducers } from 'redux'
import { persistReducer, WebStorage } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { activationReducer } from './activationReducer'
import { alertReducer } from './alertReducer'
import { alpacaStepperReducer } from './alpacaReducer'
import { coinsReducer } from './coinsReducer'
import { generalReducer } from './generalReducer'
import { linkReducer } from './linkReducer'
import { moneyAcademyReducer } from './moneyAcademyReducer'
import { my529Reducer } from './my529Reducer'
import { setupSavingsStepperReducer } from './setupSavingsStepperReducer'
import { transactionsReducer } from './transactionsReducer'
import { userReducer } from './userReducer'
import { userRegistrationStepper } from './userRegistrationStepperReducer'

type RootPersistConfigType = {
  key: string
  storage: WebStorage
  whitelist: string[]
}

const rootPersistConfig: RootPersistConfigType = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'userRegistrationStepper',
    'moneyacademy',
    'coins',
    'general',
    'setupSavingsStepper',
    'link',
    'alpaca',
    'activation',
    'my529',
  ],
}

const rootReducer = combineReducers({
  user: userReducer,
  userRegistrationStepper: userRegistrationStepper,
  alerts: alertReducer,
  moneyAcademy: moneyAcademyReducer,
  coins: coinsReducer,
  general: generalReducer,
  setupSavingsStepper: setupSavingsStepperReducer,
  link: linkReducer,
  transactions: transactionsReducer,
  alpaca: alpacaStepperReducer,
  activation: activationReducer,
  my529: my529Reducer,
})

export default persistReducer(rootPersistConfig, rootReducer)
