import api from '../../api'
import { RootReduxStateType } from '../../types'
import { getHashedPassword } from '../../utilities/generalUtilities'

export const modifyingExistingUserAccount = async (
  currentPassword: string,
  newPassword: string,
  rootUserState: RootReduxStateType['user']
) => {
  try {
    const hashedCurrentPassword = await getHashedPassword(currentPassword)
    const hashedPassword = await getHashedPassword(newPassword)

    const res = await api.post('/UserAccount/SetPassword', {
      userAccountID: rootUserState.id,
      currentPassword: hashedCurrentPassword,
      password: hashedPassword,
      modifyingUserAccountID: rootUserState.id,
      sourceProcess: 'WebApplication',
      changeReason: 'Update Password',
    })

    if (!res || res.status !== 200) return { success: false, err: res }

    return { success: true }
  } catch (err) {
    return {
      success: false,
      err: 'Unable to update Password',
    }
  }
}
