import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootReduxStateType } from '../../../../types'
import { UserAddress, UserAPIType } from '../../../../types/user'
import { getUser } from '../../../../redux/actions/userActions'
import {
  increamentSavingsStepperStep,
  setupSavingsStepperReset,
  setupSavingsStepperUpdate,
  setupSavingsStepperUpdateMultiple,
} from '../../../../redux/actions/setupSavingsStepperActions'
import { useNavigate } from 'react-router-dom'
import UserDetailsLoader from '../../SkeletonLoaders/UserDetailsLoader'
import dayjs from 'dayjs'

const LoadUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loadedUser, setLoadedUser] = useState<UserAPIType>()
  const [userAddress, setuserAddress] = useState<UserAddress>()

  const getUserInfo = async () => {
    const userResponse = await getUser(user.id)

    if (userResponse.status === 200) {
      setLoadedUser(userResponse.data)
    }
  }

  useEffect(() => {
    getUserInfo().then((address: any) => {
      //dispatch()
      if (address) {
        dispatch(
          setupSavingsStepperUpdateMultiple({
            address1: address?.streetAddress1,
            address2: address?.streetAddress2,
            state: address?.state,
            cityOrTown: address?.city,
            zipCode: address?.zipCode,
          })
        )
        setuserAddress({
          address1: address?.streetAddress1,
          address2: address?.streetAddress2,
          state: address?.state,
          city: address?.city,
          zip: address?.zipCode,
        })
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatPhoneNumber = (phoneNumberString: any) => {
    const newPhoneNumberString = phoneNumberString.split(' ')[1]
    let cleaned = ('' + newPhoneNumberString).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }
  const getValue = (
    value: string | null,
    returnType: 'function' | 'api' = 'function'
  ) => {
    if (returnType === 'function') {
      if (value === '' || value === null || value === undefined) return null
      return value
    }

    if (value === '' || value === null || value === undefined) return ''

    return value
  }
  const handleUpdateAccountInfo = async () => {
    const response = await getUser(user.id)

    console.log('Check User', response.data)
    dispatch(setupSavingsStepperReset())
    dispatch(setupSavingsStepperUpdate('updateAccountDetails', true))
    dispatch(increamentSavingsStepperStep())
    dispatch(increamentSavingsStepperStep())
    if (response.status === 200 && response) {
      dispatch(
        setupSavingsStepperUpdateMultiple({
          state: userAddress?.state,
          cityOrTown: userAddress?.city,
          zipCode: userAddress?.zip,
          address1: userAddress?.address1,
          address2: userAddress?.address2,
          firstName: getValue(response.data.firstName),
          lastName: getValue(response.data.lastName),
          dateOfBirth: getValue(response.data.dateOfBirth),
          phoneNumber: getValue(response.data.telephoneNumber),
          email: getValue(response.data.emailAddress),
        })
      )
    }
    //dispatch(increamentSavingsStepperStep())
    localStorage.setItem('isKycFailed', 'true')
    //navigate('/setup-savings')
    navigate('/savings-account-verification/user-details')
    //
  }

  return (
    <div className="relative h-[100%]">
      <p className="leading-relaxed mt-4">
        Some of the information you submitted to activate your account may be
        inaccurate. Please update your information so that it is accurate. For
        security purposes, there is a limit to how many times you can edit and
        resubmit this information. Please double check that everything you
        submit is accurate to the best of your knowledge.
      </p>

      {loadedUser ? (
        <div className="flex flex-col py-4">
          {loadedUser?.userName !== '' && (
            <div className="flex items-center py-1">
              <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
              <p className="ml-1">
                Your legal name is{' '}
                {loadedUser?.firstName + ' ' + loadedUser?.lastName}.
              </p>
            </div>
          )}

          {loadedUser?.dateOfBirth !== '' && (
            <div className="flex items-center py-1">
              <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
              <p className="ml-1">
                Your date of birth is{' '}
                {dayjs(loadedUser.dateOfBirth).format('MMMM D, YYYY')}
              </p>
            </div>
          )}

          {loadedUser?.telephoneNumber !== '' && (
            <div className="flex items-center py-1">
              <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
              <p className="ml-1">
                Your phone number is{' '}
                {formatPhoneNumber(loadedUser.telephoneNumber)}.
              </p>
            </div>
          )}

          {loadedUser?.emailAddress !== '' && (
            <div className="flex items-center py-1">
              <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
              <p className="ml-1">Your email is {loadedUser.emailAddress}.</p>
            </div>
          )}

          {userAddress && (
            <div className="flex items-center py-1">
              <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
              <p className="ml-1">
                Your Adddress is {userAddress?.address1} {userAddress?.address2}{' '}
                {userAddress?.city} {userAddress?.state} - {userAddress?.zip}.
              </p>
            </div>
          )}

          <div className="py-4">
            <p
              className="underline text-primaryDark cursor-pointer"
              onClick={handleUpdateAccountInfo}
            >
              I made a mistake
            </p>
          </div>
        </div>
      ) : (
        <>
          {/**<LoadingSpinner color="#004F4E" /> */}
          <UserDetailsLoader />
        </>
      )}
    </div>
  )
}

export default LoadUser
