import React, { useEffect, useState } from 'react'

import {
  AddActivationEventInfo,
  activationRegisterReset,
  getActivationEvents,
} from '../../redux/actions/activationActions'
import { LoadingSpinner } from '../Common/Loading'
import {
  Dave,
  Facebook,
  GraduationGirl,
  Instagram,
  PlayButton,
  TikTok,
  Twitter,
} from './ImageImports'
import ActivationModal from './Partials/ActivationModal'
import Brands from './Partials/Brands'
import { ActivationFeatures, SootchyFeatures } from './Partials/Features'
import ActivationFooter from './Partials/Footer'
import ImageSlider from './Partials/ImageSlider'
import ActivationNavbar from './Partials/Navbar'
import {
  ImportantTipsForEvent,
  NextActivationEventHeadline,
  NextActivationEventLocation,
} from './Partials/NextEvent'
import ActivationRegistrationForm from './Partials/RegistrationForm'
import ShareEvent from './Partials/Share'
import ActivationTestimonials from './Partials/Testimonials'
// import { getActivationUserInfo } from '../../redux/actions/userActions'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const ActivationsHome = () => {
  const dispatch = useDispatch()
  const { activationEvent } = useParams()
  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const [playSootchyVideo, setPlaySootchyVideo] = useState(false)
  const [playActivationVideo, setPlayActivationVideo] = useState(false)
  const [activationEventID, setActivationEventID] = useState<string>('')
  const [activationName, setActivationName] = useState<string>('')
  const [activationEventVenue, setActivationEventVenue] = useState<string>('')
  const [activationEventDate, setActivationEventDate] = useState<string>('')
  const [activationEventDescription, setActivationEventDescription] =
    useState<string>('')
  const [activationMapLocation, setActivationMapLocation] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleActivationEvents = () => {
    setLoading(true)

    if (activationEvent) {
      console.log('User Params', activationEvent)
      getActivationEvents(activationEvent)
        .then((res) => {
          setLoading(false)
          setActivationEventID(res.activationEventID)
          setActivationName(res.activationName)
          setActivationEventVenue(res.activationEventVenue)
          setActivationEventDescription(res.description)
          setActivationMapLocation(res.mapLocation)
          setActivationEventDate(res.activationEventDate)
          dispatch(AddActivationEventInfo(res))
          //console.log('Activation Events', res)
        })
        .catch((err) => console.log('Unable to fetch Activatiopn Events', err))
    } else {
      window.location.replace('https://www.sootchy.com/events')
    }
  }

  useEffect(() => {
    dispatch(activationRegisterReset())
    handleActivationEvents()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <LoadingSpinner color="#004F4E" size={8} />
        </div>
      ) : (
        <div className="relative h-[100vh]">
          {showShareModal && (
            <ActivationModal
              isShare
              onClose={() => setShowShareModal(!showShareModal)}
            >
              <ShareEvent />
            </ActivationModal>
          )}

          {/* Video Modals*/}
          {playActivationVideo && (
            <ActivationModal onClose={() => setPlayActivationVideo(false)}>
              <iframe
                width="640"
                height="360"
                src="https://www.youtube.com/embed/HVBGpUtqlpY?rel=0&autoplay=1"
                title="YouTube video player"
                //frameBorder="0"
                allow="autoplay"
                allowFullScreen
              ></iframe>
            </ActivationModal>
          )}

          {playSootchyVideo && (
            <ActivationModal onClose={() => setPlaySootchyVideo(false)}>
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/471488742?h=4cad17d4a1&autoplay=1"
                width="640"
                height="360"
                allow="autoplay"
                allowFullScreen
              ></iframe>
            </ActivationModal>
          )}

          {/* Landing Page */}
          <div className="relative h-[700px] w-full">
            <video
              className="absolute z-10 w-[100%] h-[100%] object-cover"
              autoPlay
              muted
              loop
              src="https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/28d4b70d-0388-4214-929e-cddbec95c842/April9th_1080p.mp4"
            />

            <div className="absolute z-20 bg-black/70 w-[100%] h-[100%]"></div>
            <div className="absolute w-full mx-a z-30">
              <ActivationNavbar activationEvent={activationName} />
              <div className="container mx-auto ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12">
                  <NextActivationEventHeadline
                    eventDate={activationEventDate}
                    activationEventVenue={activationEventVenue}
                  />

                  <ActivationRegistrationForm
                    key="landingpage_form"
                    eventID={activationEventID}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Brands */}
          <div className="px-[20px] mt-[480px] md:mt-0 py-[96px] container mx-auto">
            <p className="font-thin leading-[18px] text-[14px] mb-[24px]">
              Sootchy in the media
            </p>

            <Brands />

            <div className="grid grid-cols-1 md:grid-cols-4 gap-1 md:gap-4">
              <ActivationTestimonials />
            </div>
          </div>

          {/* What are Sootchy Activations */}
          <div className="bg-[#EFF9F9] py-[48px] px-[20px] md:py-[120px]">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                <div>
                  <h2 className="mb-[24px] font-semibold text-[27px] md:text-[32px] leading-[38.4px]">
                    What are Sootchy Activation Events?
                  </h2>
                  <p className="font-thin leading-[32px] text-[15px] md:text-[17px]">
                    Your child will have fun, learn healthy money habits, and
                    gain exclusive access to resources and opportunities. We
                    will teach your child the knowledge and habits they will
                    need to build wealth, and provide your family with a free
                    savings account you and your community can use to fund your
                    child's lifelong dreams.
                  </p>
                </div>

                <div
                  style={{ backgroundImage: `url(${Dave})` }}
                  className="relative rounded-[25px] h-[240px] md:h-[320px]"
                >
                  <div className="absolute  top-0 left-0 w-full h-full flex items-center justify-center">
                    <img
                      src={PlayButton}
                      alt="Play"
                      className="cursor-pointer"
                      onClick={() => setPlayActivationVideo(true)}
                    />
                  </div>
                </div>
              </div>

              <ActivationFeatures />
            </div>
          </div>

          {/* Slider */}
          <div className="h-[320px]">
            <div className="lg:min-lg:container mx-auto">
              <ImageSlider />
            </div>
          </div>

          {/* What is Sootchy */}
          <div className="py-[48px] px-[20px] md:py-[120px]">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-[48px] md:mb-[96px]">
                <div
                  style={{ backgroundImage: `url(${GraduationGirl})` }}
                  className="relative rounded-[25px] h-[240px] md:h-[320px]"
                >
                  <div className="absolute  top-0 left-0 w-full h-full flex items-center justify-center">
                    <img
                      onClick={() => setPlaySootchyVideo(true)}
                      src={PlayButton}
                      alt="Play"
                      className="cursor-pointer"
                    />
                  </div>
                </div>

                <div>
                  <h2 className="mb-[24px] font-semibold text-[32px] leading-[38.4px]">
                    What is Sootchy?
                  </h2>
                  <p className="font-thin leading-[32px] text-[17px]">
                    Sootchy is on a mission to end long-term poverty. We're
                    tackling this enormous task by connecting underserved and
                    underrepresented American families with the access and
                    opportunities they didn't even know existed. With the
                    Sootchy app and community, parents get access to the hidden
                    levers of wealth generation, and children learn the
                    financial skills to educate themselves debt free and lead
                    happy, successful lives.
                  </p>
                </div>
              </div>

              <SootchyFeatures />
            </div>
          </div>

          {/* Things to Know */}
          <div className="bg-[#EFF9F9] pt-[48px]  md:py-[120px]">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="px-[20px]">
                  <div className="bg-white rounded-lg py-[36px] px-[24px] md:px-[40px] mb-[15px]">
                    <h4 className="text-[24px] leading-[28.8px] font-semibold mb-[12px]">
                      Things to know
                    </h4>
                    <ImportantTipsForEvent />
                  </div>

                  <NextActivationEventLocation
                    activationEventVenue={activationEventVenue}
                    description={activationEventDescription}
                    eventDate={activationEventDate}
                    activationMapLocation={activationMapLocation}
                  />
                </div>

                <ActivationRegistrationForm
                  key="footer_form"
                  eventID={activationEventID}
                />
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="py-[48px] md:py-[120px] px-[20px]">
            <ActivationFooter />
          </div>

          <div className="fixed bottom-[0%] z-30">
            <div
              style={{ boxShadow: '-2px 6px 20px rgba(0, 0, 0, 0.75)' }}
              onClick={() => setShowShareModal(!showShareModal)}
              className="cursor-pointer ml-[20px] md:ml-[63%] z-40 w-[180px] pt-4 pb-2 px-4 rounded-t-[10px] shadow-2xl bg-white flex items-center space-x-4"
            >
              <p className="uppercase font-semibold text-[11px] leading-[13.2px] text-primaryDark">
                Share
              </p>
              <div className="flex items-center space-x-2">
                <img src={Twitter} alt="Twitter" className="h-[18px]" />
                <img src={TikTok} alt="Tiktok" className="h-[18px]" />
                <img src={Facebook} alt="Facebook" className="h-[18px]" />
                <img src={Instagram} alt="Instagram" className="h-[18px]" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ActivationsHome
