import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { alpacaStepperUpdateProgressStatus } from '../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../types'
import NewNavbar from '../Common/Partials/NewNavbar'
import AlpacaAssistenceModal from './AlpacaAssistenceModal'
import AlpacaGeneralModal from './AlpacaGeneralModal'
import AlpacaStepOne from './Step1'
import AlpacaStepTen from './Step10'
import AlpacaStepEleven from './Step11'
import AlpacaStepTwelve from './Step12'
import AlpacaStepThirteen from './Step13'
import AlpacaStepFourteen from './Step14'
import AlpacaStepFifteen from './Step15'
import AlpacaStepSixteen from './Step16'
import AlpacaStepTwo from './Step2'
import AlpacaStepThree from './Step3'
import AlpacaStepFour from './Step4'
import AlpacaStepFive from './Step5'
import AlpacaStepSix from './Step6'
import AlpacaStepSeven from './Step7'
import AlpacaStepEight from './Step8'
import AlpacaStepNine from './Step9'
// @ts-ignore
import CloseIcon from '../../assets/closeIcon.png'
// @ts-ignore
import { AzureMP } from 'react-azure-mp'

const MainAlpacaStepper: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const navigate = useNavigate()

  const progress = (alpaca.step / 17) * 100

  if (typeof window.Intercom !== 'undefined') {
    window.Intercom('update', {
      hide_default_launcher: true,
    })
  }

  const displayStepComponent = () => {
    switch (alpaca.step) {
      case 1:
        return <AlpacaStepOne />

      case 2:
        return <AlpacaStepTwo />

      case 3:
        return <AlpacaStepThree />

      case 4:
        return <AlpacaStepFour />

      case 5:
        return <AlpacaStepFive />

      case 6:
        return <AlpacaStepSix />

      case 7:
        return <AlpacaStepSeven />

      case 8:
        return <AlpacaStepEight />

      case 9:
        return <AlpacaStepNine />

      case 10:
        return <AlpacaStepTen />

      case 11:
        return <AlpacaStepEleven />

      case 12:
        return <AlpacaStepTwelve />

      case 13:
        return <AlpacaStepThirteen />

      case 14:
        return <AlpacaStepFourteen />

      case 15:
        return <AlpacaStepFifteen />

      case 16:
        return <AlpacaStepSixteen />

      default:
        return <AlpacaStepOne />
    }
  }

  const displayAlpacaProgressStatus = () => {
    switch (alpaca.progressStatus) {
      case 'LEAVE_SETUP':
        return (
          <>
            <AlpacaGeneralModal
              title="Leave Set-Up?"
              description="If you leave now, your progress will not be saved. If you have trouble using this, consider contacting our Customer Service."
              showCustomerCarelink
              primaryButtonText={'Continue filling'}
              secondaryButtonText={'Leave'}
              onClose={() => dispatch(alpacaStepperUpdateProgressStatus(null))}
              handlePrimaryOnClick={() =>
                dispatch(alpacaStepperUpdateProgressStatus(null))
              }
              handleSecondaryOnClick={() => {
                dispatch(alpacaStepperUpdateProgressStatus(null))
                navigate('/')
              }}
            />
          </>
        )

      case 'PAUSE_AND_CONTINUE':
        return (
          <>
            <AlpacaGeneralModal
              title="Pause & Continue Later?"
              description="Your progress will be saved and you will be able to continue from where you left off."
              primaryButtonText={'Pause'}
              secondaryButtonText={'Cancel'}
              secondaryButtonStyle={'secondary'}
              onClose={() => dispatch(alpacaStepperUpdateProgressStatus(null))}
              handlePrimaryOnClick={() =>
                dispatch(alpacaStepperUpdateProgressStatus(null))
              }
              handleSecondaryOnClick={() => {
                dispatch(alpacaStepperUpdateProgressStatus(null))
                navigate('/')
              }}
            />
          </>
        )

      default:
        return <></>
    }
  }

  const handleClose = () => {
    dispatch(alpacaStepperUpdateProgressStatus('PAUSE_AND_CONTINUE'))
  }

  return (
    <>
      <NewNavbar style="default" />
      <div className="h-full w-full sm:bg-[#E3F2F2] flex justify-center items-center sm:min-h-screen">
        {alpaca.assistenceNeeded && <AlpacaAssistenceModal />}

        {displayAlpacaProgressStatus()}

        <div
          className={`transition-all m-auto w-full bg-white ${
            alpaca.video ? 'sm:w-[900px]' : 'sm:w-[450px]'
          } sm:shadow-primaryLight sm:shadow-md sm:py-3 sm:px-5 sm:rounded-xl`}
        >
          <div className="shadow-md py-5 md:shadow-none">
            <div className="flex justify-between items-center pb-4 w-[90%] m-auto">
              <p className="text-[18px] font-semibold text-primaryDark">
                Set-up Savings
              </p>
              <img
                src={CloseIcon}
                alt="close icon"
                className="w-[13px] h-[13px] cursor-pointer"
                onClick={handleClose}
              />
            </div>

            <div className="relative w-[90%] m-auto">
              <div className="w-[100%] bg-[#E2FFFF] h-[19px] rounded-xl  cursor-pointer"></div>
              <div
                className={`absolute bg-[#7FE5E4] h-[19px] rounded-xl top-0 transition-all cursor-pointer shadow-lg`}
                style={{
                  width: `${progress}%`,
                }}
              ></div>
            </div>

            {alpaca.video && (
              <div className="w-[90%] m-auto mt-5 md:hidden">
                <AzureMP
                  skin="amp-flush"
                  src={[
                    {
                      src: `${alpaca.video}`,
                      type: 'application/vnd.ms-sstr+xml',
                    },
                  ]}
                  options={{ controls: true, autoplay: false }}
                />
              </div>
            )}
          </div>

          <div className="w-[90%] m-auto">
            <div>{displayStepComponent()}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainAlpacaStepper
