import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../api'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import DateInput from '../../Common/DateInput/DateInput'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepSix: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [_, setDateOfBirth] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (alpaca.user.dateOfBirth) setDisabled(false)
    else setDisabled(true)
  }, [alpaca.user.dateOfBirth])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNext = () => {
    // TODO: If email is changed send verification email
    dispatch(increamentAlpacaStepperStep())
  }

  const formatPhoneNumberToDisplay = (phone: string) => {
    const newNumber = phone.split(' ')
    let arr = ['1', newNumber[1]]
    setDateOfBirth(arr.join(''))
  }

  const getUserDetails = async () => {
    setLoading(true)
    setDisabled(true)
    const res = await api.get(`/UserAccount/UserAccountID/${user.id}`)

    if (res.data.telephoneNumber) {
      formatPhoneNumberToDisplay(res.data.telephoneNumber)
    } else {
      dispatch(alpacaStepperUpdateUser('dateOfBirth', res.data.telephoneNumber))
      setDateOfBirth(res.data.telephoneNumber)
    }

    dispatch(alpacaStepperUpdateUser('dateOfBirth', res.data.dateOfBirth))
    setDateOfBirth(res.data.dateOfBirth)

    setLoading(false)
    setDisabled(false)
  }

  useEffect(() => {
    getUserDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="font-semibold text-[17px] py-2">Birth Date</p>

        {loading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-4">
            <DateInput
              dateValue={alpaca.user.dateOfBirth ?? ''}
              onChange={(newValue) =>
                dispatch(alpacaStepperUpdateUser('dateOfBirth', newValue))
              }
            />
          </div>
        )}

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca disabled={disabled} handleNext={handleNext} />
    </div>
  )
}

export default AlpacaStepSix
