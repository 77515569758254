import React from 'react'

const TransactionModal = ({ children }: any) => {
  return (
    <div>
      <div className="block fixed bg-[#021212BF] w-[100%] h-[100vh] top-0 left-0 opacity-95 z-[100]"></div>
      <div>
        <div
          data-test="quiz-modal"
          className="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-[90%] md:w-[570px] lg:w-[570px] min-h-[200px] max-h-[90%]  opacity-100 px-5 pt-5 pb-1 rounded-xl flex z-[101]"
        >
          <div className="w-[100%] flex flex-col justify-center items-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionModal
