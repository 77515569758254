import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootReduxStateType } from '../../../types'
import { MAX_MY529_STEP } from '../../../types/my529'
import AccountBeneficiaryStep from './AccountBeneficiary'
import AddressStep from './Address'
import AgreementStep from './Agreement'
import ChildrenStep from './Children'
import InvestmentStep from './Investment'
import ReviewStep from './ReviewInfo'
import SocialSecurityStep from './SocialSecurity'
import WelcomeStep from './Welcome'
// @ts-ignore
import CloseIcon from '../../../assets/closeIcon.png'

const SetupMy529Account: React.FC = () => {
  const navigate = useNavigate()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)

  const progress = (my529.step / MAX_MY529_STEP) * 100

  const displayComponent = () => {
    switch (my529.step) {
      case 1:
        return <WelcomeStep />

      case 2:
        return <AgreementStep />

      case 3:
        return <ReviewStep />

      case 4:
        return <AddressStep />

      case 5:
        return <SocialSecurityStep />

      case 6:
        return <AccountBeneficiaryStep />

      case 7:
        return <ChildrenStep />

      // case 8:
      //   return <InitialInvestmentAmountStep />

      // case 9:
      //   return <BankStep />

      case 8:
        return <InvestmentStep />

      default:
        return <WelcomeStep />
    }
  }

  const handleClose = () => {
    return navigate('/')
  }

  return (
    <div>
      <>
        <div className="h-full w-full sm:bg-[#E3F2F2] flex justify-center items-center sm:min-h-screen md:py-5">
          <div
            className={`transition-all m-auto w-full bg-white sm:w-[450px] sm:shadow-primaryLight sm:shadow-md sm:py-3 sm:px-5 sm:rounded-xl`}
          >
            <div className="shadow-md py-5 md:shadow-none">
              <div className="flex justify-between items-center pb-4 w-[90%] m-auto">
                <p className="text-[18px] font-semibold text-primaryDark">
                  Set-up My529
                </p>
                <img
                  src={CloseIcon}
                  alt="close icon"
                  className="w-[13px] h-[13px] cursor-pointer"
                  onClick={handleClose}
                />
              </div>

              <div className="relative w-[90%] m-auto">
                <div className="w-[100%] bg-[#E2FFFF] h-[19px] rounded-xl  cursor-pointer"></div>
                <div
                  className={`absolute bg-[#7FE5E4] h-[19px] rounded-xl top-0 transition-all cursor-pointer shadow-lg`}
                  style={{
                    width: `${progress}%`,
                  }}
                ></div>
              </div>

              {/* {my529.video && my529.video.length > 0 && (
                <VideoComponent video={my529.video} />
              )} */}
            </div>

            <div className="w-[90%] m-auto">
              <div>{displayComponent()}</div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default SetupMy529Account
