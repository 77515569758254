import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getApplicationStatus,
  getMy529Children,
  setupMy529Update,
} from '../../redux/actions/my529Actions'
import { getUser } from '../../redux/actions/userActions'
import { RootReduxStateType } from '../../types'
import { UserAPIType } from '../../types/user'
import { LoadingSpinner } from '../Common/Loading'
import Navigation from '../Common/Navigation'
//@ts-ignore
import ArrowUpRight from '../../assets/arrow-up-right-from-square.png'
import CommonModal from '../Common/Modals/CommonModal'
import SafeAreaView from '../Common/SafeAreaView'
import My529SetupBottom from './SetupMy529Account/My529SetupBottom'
import My529AddChildModal from './SetupMy529Account/Children/My529AddChildModal'

const My529 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadedUser, setLoadedUser] = useState<UserAPIType | null>()
  const [isAnyPlanCreated, setIsAnyPlanCreated] = useState<boolean>(false)
  const [isAnyPlanCreatedByUtah, setIsAnyPlanCreatedByUtah] =
    useState<boolean>(false)
  const [plans, setPlans] = useState([])
  const [minorsPlans, setMinorsPlans] = useState<any>([])
  const [showAddChildModal, setShowAddChildModal] = useState<boolean>(false)
  const [showUtahModal, setShowUtahModal] = useState<boolean>(false)

  // const hexColors = [
  //   '#FF5733',
  //   '#C70039',
  //   '#900C3F',
  //   '#581845',
  //   '#DAF7A6',
  //   '#FFC300',
  //   '#FF5733',
  //   '#C70039',
  // ]

  const handleGetUser = () => {
    getUser(user.id)
      .then((res) => {
        if (res.status === 200) {
          setLoadedUser(res.data)
          handleGetApplicationStatus()
        }
      })
      .catch(() => {
        alert('Error retrieving user')
        setLoading(false)
      })
  }

  const handleGetApplicationStatus = async () => {
    getApplicationStatus()
      .then((res) => {
        if (res.data.length > 0) {
          setPlans(res.data)
          setIsAnyPlanCreated(true)

          const filteredArray = res.data.filter((item: any) => {
            if (item.applicationStatus === 'ApplicationPendingFromCustomer')
              setIsAnyPlanCreated(true)
            if (item.applicationStatus === 'My529AccountCreated')
              setIsAnyPlanCreatedByUtah(true)

            //return item.applicationStatus === 'ApplicationPendingFromCustomer'
            return item
          })

          filteredArray?.filter((item: any) => {
            if (
              item.minorAccountID !== null ||
              item.minorAccountID !== undefined
            ) {
              getChildrenInfo()
              return
            }
          })
        }
        setLoading(false)
      })
      .catch(() => {
        alert('Error Retrieving Application')
        setLoading(false)
      })
  }

  const getChildrenInfo = () => {
    getMy529Children()
      .then((res) => {
        setMinorsPlans(res.data)
        setLoading(false)
      })
      .catch(() => {
        console.log('Unable to retrive children info')
      })
  }

  const getApplicationStatusMessage = (status: string) => {
    if (
      status === '' ||
      status === null ||
      status === undefined ||
      status === 'Unknown' ||
      status === 'NotYetStarted'
    ) {
      return 'Application process not yet started'
    } else if (status === 'ApplicationPendingFromCustomer') {
      return 'Application Pending'
    } else if (status === 'ApplicationSubmittedFromCustomer') {
      return 'Application submitted to Sootchy'
    } else if (status === 'DocuSignSentToCustomer') {
      return 'DocuSign sent to customer email'
    } else if (status === 'DocuSignRecievedFromCustomer') {
      return 'Docusign signed and received by Sootchy'
    } else if (status === 'SentApplicationToUtah') {
      return 'Application sent to Utah'
    } else if (status === 'ApplicationInProcess') {
      return 'Application in process'
    } else if (status === 'ApplicationFreezed') {
      return 'Application frozen'
    } else if (status === 'My529AccountCreated') {
      return 'My529 Account created'
    } else if (status === 'ApplicationRejectedByUtah') {
      return 'Application rejected by Utah'
    } else if (status === 'ApplicationRejectSootchyTeam') {
      return 'Application rejected by Sootchy'
    } else {
      return 'Status unknown'
    }
  }

  const ManagingUtahPlans = () => {
    return (
      <div className="flex flex-col">
        <SafeAreaView>
          <div className="flex flex-col">
            <h1 className="font-semibold text-[18px] mb-4 text-primaryDark">
              How do I manage a My529 Plan?
            </h1>
            <p>
              Account owners (ages 18+) may access their Utah 529 Educational
              Savings Plan(s), may manage contributions, withdrawals, and invite
              contributions from friends and family by registering a My529
              account using the steps below.
            </p>

            <ul className="my-4">
              <li className="py-1">
                1. Head over to{' '}
                <span
                  className="text-primary underline cursor-pointer"
                  onClick={() =>
                    window.open('https://login.my529.org/register', '_blank')
                  }
                >
                  login.my529.org/register
                </span>
              </li>
              <li className="py-1">
                2. Select Registration Type{' '}
                <span className="font-bold">“I have accounts with my529”</span>{' '}
                to setup your online access
              </li>
              <li className="py-1">
                3. Enter the My529 Account Owner’s Social Security Number and
                Date of Birth
              </li>
              <li className="py-1">
                4. Create a Username and Password (once created, you will
                receive a verification email to proceed)
              </li>
              <li className="py-1">
                5. After confirming your email address, login at login.my529.org
                using the Username and Password created
              </li>
              <li className="py-1">
                6. Verify Your Identity by confirming the Account Owner's Date
                of Birth and answering a series of personal questions
              </li>
              <li className="py-1">
                7. Lastly, setup a Two-Factor Authentication Method (you may
                choose to accept authentication codes to be sent via SMS Text
                Message)
              </li>
            </ul>

            <p className="py-1">
              Please note that Sootchy does not manage 529 College Savings
              Plans, contributions and withdrawals
            </p>

            <p className="py-1 mb-16">
              If you have any questions, please{' '}
              <span
                className="text-primary underline cursor-pointer"
                onClick={() => navigate('/help/assitance')}
              >
                contact us
              </span>
            </p>
          </div>
        </SafeAreaView>
        <My529SetupBottom
          handleNext={() => {
            window.open('https://login.my529.org', '_blank')
            setShowUtahModal(false)
          }}
          handleCancel={() => setShowUtahModal(false)}
        />
      </div>
    )
  }

  const ManagePlans = () => (
    <>
      {showAddChildModal && (
        <>
          {/* <AddChildModal
            id={null}
            childInfo={[]}
            onClose={() => {
              setShowAddChildModal(false)
              getChildrenInfo()
            }}
          /> */}

          <My529AddChildModal
            onClose={() => {
              setShowAddChildModal(false)
              getChildrenInfo()
            }}
            id={null}
            childInfo={[]}
            isNotStepper
          />
        </>
      )}

      {showUtahModal && (
        <CommonModal onClose={() => setShowUtahModal(false)}>
          <ManagingUtahPlans />
        </CommonModal>
      )}

      <div className="w-[90%] m-auto mt-[50px] lg:w-[570px] lg:mt-[80px]">
        <div>
          <p className="font-semibold text-[30px] text-primaryDark">
            <span className="capitalize">{loadedUser?.firstName},</span>{' '}
            welcome!
          </p>

          {isAnyPlanCreatedByUtah && (
            <div className="py-[35px]">
              <div className="flex items-center space-x-2 ">
                <div
                  onClick={() => setShowUtahModal(true)}
                  className="cursor-pointer text-primary text-[14px] leading-[21px] underline py-1 inline-block"
                >
                  Manage your 529 plan(s)
                </div>
                <img
                  src={ArrowUpRight}
                  className="w-[12px] h-[12px] object-contain"
                  alt="redirection"
                />
              </div>
              <p className="text-primary text-[14px] mt-[12px]">
                This will take you to myplan529.org
              </p>
            </div>
          )}

          {plans?.length > 0 && (
            <div>
              <div className="border-b-2 border-dashed border-[#C4E8E8] my-10"></div>
              <div>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold text-[24px] text-primaryDark">
                      Your Application(s)
                    </p>
                    <p className="text-primary text-[14px] mt-[12px]">
                      Follow the status of your applications.
                    </p>
                  </div>

                  <button
                    className={`bg-primary text-white py-3 px-4`}
                    onClick={() => setShowAddChildModal(true)}
                  >
                    Add Child
                  </button>
                </div>

                <div className={`grid grid-cols-2 gap-5 mt-5`}>
                  {plans &&
                    plans.map(
                      (plan: any, index) =>
                        (plan.minorAccountID === null ||
                          plan.minorAccountID === undefined) && (
                          <div
                            key={index}
                            className="py-4 px-4 rounded-lg cursor-pointer"
                            style={{
                              //backgroundColor: `${getRandomColor()}`,
                              // backgroundColor: `#EAEAEA`,
                              // color: '#021212BF',
                              backgroundColor: `#7FE5C6`,
                              color: '#146D52',
                            }}
                            onClick={() => {
                              if (
                                plan.applicationStatus ===
                                'ApplicationPendingFromCustomer'
                              ) {
                                dispatch(setupMy529Update('step', 8))

                                navigate('/setup/my529')
                              }
                            }}
                          >
                            <p className="font-semibold text-[20px] capitalize">
                              {loadedUser?.firstName} {loadedUser?.lastName}
                            </p>
                            <p className="text-[14px] mt-4">Status</p>
                            <p className="  font-semibold text-[16px] mt-1">
                              {getApplicationStatusMessage(
                                plan.applicationStatus
                              )}
                            </p>
                          </div>
                        )
                    )}

                  {minorsPlans &&
                    minorsPlans.map((plan: any, index: any) => (
                      <div
                        key={index}
                        className="relative py-4 px-4 rounded-lg cursor-pointer"
                        style={{
                          //backgroundColor: `#7FE5C6`,
                          backgroundColor: `${
                            plan.applicationStatus === '' ||
                            plan.applicationStatus === null ||
                            plan.applicationStatus === undefined ||
                            plan.applicationStatus ===
                              'ApplicationPendingFromCustomer' ||
                            plan.applicationStatus === 'Unknown' ||
                            plan.applicationStatus === 'NotYetStarted'
                              ? '#EAEAEA'
                              : '#7FE5C6'
                          }`,
                          //color: '#146D52',
                          color: `${
                            plan.applicationStatus === '' ||
                            plan.applicationStatus === null ||
                            plan.applicationStatus === undefined ||
                            plan.applicationStatus ===
                              'ApplicationPendingFromCustomer' ||
                            plan.applicationStatus === 'Unknown' ||
                            plan.applicationStatus === 'NotYetStarted'
                              ? '#64748b'
                              : '#146D52'
                          }`,
                        }}
                        onClick={() => {
                          //console.log('Plan clicked on', plan)
                          if (
                            plan.applicationStatus ===
                            'ApplicationPendingFromCustomer'
                          ) {
                            dispatch(setupMy529Update('step', 3))
                            navigate('/setup/my529')
                          }

                          if (
                            plan.applicationStatus === '' ||
                            plan.applicationStatus === null ||
                            plan.applicationStatus === undefined ||
                            plan.applicationStatus === 'Unknown' ||
                            plan.applicationStatus === 'NotYetStarted'
                          ) {
                            dispatch(setupMy529Update('step', 1))
                            navigate('/setup/my529')
                          }
                        }}
                      >
                        <p className="font-semibold text-[20px] capitalize">
                          {plan.firstName} {plan.lastName}
                        </p>
                        <p className="text-[14px] mt-4">Status</p>
                        <p className="  font-semibold text-[16px] mt-1">
                          {getApplicationStatusMessage(plan.applicationStatus)}
                        </p>

                        {(plan.applicationStatus === '' ||
                          plan.applicationStatus === null ||
                          plan.applicationStatus === undefined ||
                          plan.applicationStatus === 'Unknown' ||
                          plan.applicationStatus === 'NotYetStarted') && (
                          <div className="absolute px-2 rounded-[20px] top-2 right-2 text-[11px] text-white bg-[#64748b]">
                            Start
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )

  const SetupMy529Plans = () => (
    <div className="mt-4 flex flex-col w-[90%] m-auto items-center lg:flex-row md:w-[95%] xl:w-[1200px] xl:mt-0">
      <div className="w-full">
        <div className="rounded-lg border-2 border-[#EFF9F9] p-1">
          <iframe
            title="IFrameMy529"
            className="w-full h-[220px] md:h-[360px]"
            src="https://player.vimeo.com/video/815421327?h=4fa278fc54&title=0&byline=0&portrait=0"
            width="100%"
            //height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className=" bg-[#EFF9F9] px-6 py-10 mt-5 rounded-lg lg:ml-5 w-full lg:w-[700px]">
        <p className="text-[1.875rem] text-[#004F4E] font-semibold">
          {loadedUser?.firstName
            ? `${loadedUser.firstName}, welcome!`
            : 'Welcome'}
        </p>

        <p className="text-[1.125rem] leading-9 mt-5">
          Let us help you save for your child’s future
        </p>

        <ul className="list-outside px-5 mt-3">
          <li className="font-thin list-disc leading-7">
            Open a savings account today
          </li>

          <li className="font-thin list-disc leading-7">
            Access events and courses crafted to help your family build savings
          </li>

          <li className="font-thin list-disc leading-7">
            Get help funding secondary, or post secondary education.
          </li>
        </ul>

        <button
          className="w-full flex justify-center m-auto mt-6 text-[1rem] font-normal bg-primary text-white py-5"
          onClick={() => navigate('/setup/my529')}
        >
          Get Started
        </button>
      </div>
    </div>
  )

  useEffect(() => {
    handleGetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Navigation>
      {loading ? (
        <div className="h-[100vh] flex justify-center items-center">
          <LoadingSpinner color="#004F4E" />
        </div>
      ) : isAnyPlanCreated ? (
        <ManagePlans />
      ) : (
        <SetupMy529Plans />
      )}
    </Navigation>
  )
}

export default My529
