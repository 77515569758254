import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { states } from '../../../constants/states'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateBeneficiary,
  alpacaStepperUpdateVideo,
  getCountriesList,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import {
  formatSSN,
  validateEmail,
  validdateSsn,
} from '../../../utilities/generalUtilities'
import SafeAreaView from '../../Common/SafeAreaView'
import CountrySelector from '../../Common/Partials/CountrySelector'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepFifteen: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isIntialStepCompleted, setIsIntialStepCompleted] =
    useState<boolean>(false)
  const [countryOptions, setCountryOptions] = useState<any>([])

  const handleNext = () => {
    if (!isIntialStepCompleted) {
      setIsIntialStepCompleted(true)
      return
    }

    // saveBeneficiaryDetailsAlpaca({
    //   ParentUserAccountID: parseInt(user.id),
    //   FirstName: alpaca.beneficiary.firstName ?? "",
    //   MiddleName: '',
    //   LastName: alpaca.beneficiary.lastName ?? "",
    //   EmailAddress: alpaca.beneficiary.email ?? "",
    //   DateOfBirth: alpaca.beneficiary.dateOfBirth,
    //   IdentifierType: '',
    //   Identifier: '',
    //   CountryOfCitizenship: '',
    //   CountryOfBirth: '',
    //   CountryOfTaxResidence: '',
    //   State: '',
    //   ChangeReason: ''
    // })
    // dispatch(increamentAlpacaStepperStep())
    alert('RUN API')
  }

  const handleCancel = () => {
    setIsIntialStepCompleted(false)
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    if (key === 'socialSecurity') {
      dispatch(alpacaStepperUpdateBeneficiary(key, formatSSN(value)))
      return
    }

    dispatch(alpacaStepperUpdateBeneficiary(key, value === '' ? null : value))
  }

  const findCountryLabel = (countryCode: string) => {
    if (countryOptions.length > 0) {
      setLoading(true)
      for (let item = 0; item < countryOptions.length; item++) {
        if (countryOptions[item].alpha3 === countryCode) {
          setLoading(false)
          return countryOptions[item].countryName
        }
      }
    }
  }

  const handleCitizenshipChange = (code: any) => {
    dispatch(alpacaStepperUpdateBeneficiary('citizenshipCountryCode', code))
    dispatch(
      alpacaStepperUpdateBeneficiary(
        'citizenshipCountry',
        findCountryLabel(code)
      )
    )
  }

  const handleTaxChange = (code: any) => {
    dispatch(alpacaStepperUpdateBeneficiary('taxResidenceCountryCode', code))
    dispatch(
      alpacaStepperUpdateBeneficiary(
        'taxResidenceCountry',
        findCountryLabel(code)
      )
    )
  }

  const displayComponent = () => {
    if (!isIntialStepCompleted)
      return (
        <div>
          <p className="text-[18px] font-semibold">Beneficiary</p>

          <p className="text-[16px] py-4 font-normal">
            Please fill in the legal name of the Beneficiary.
          </p>

          <div className="">
            {loading ? (
              <Skeleton height={90} />
            ) : (
              <div className={`form-group form-group--filled py-2`}>
                <input
                  onChange={handleOnChange}
                  name="firstName"
                  aria-label="firstName"
                  value={alpaca.beneficiary.firstName ?? ''}
                />
                <label>First name</label>
              </div>
            )}
          </div>

          <div className="">
            {loading ? (
              <Skeleton height={90} />
            ) : (
              <div className={`form-group form-group--filled py-2`}>
                <input
                  onChange={handleOnChange}
                  name="lastName"
                  aria-label="lastName"
                  value={alpaca.beneficiary.lastName ?? ''}
                />
                <label>Last name</label>
              </div>
            )}
          </div>

          <p className="text-[16px] py-4 font-normal">
            Beneficiary’s email address
          </p>

          <div className="">
            {loading ? (
              <Skeleton height={90} />
            ) : (
              <div className={`form-group form-group--filled py-2`}>
                <input
                  onChange={handleOnChange}
                  name="email"
                  aria-label="email"
                  value={alpaca.beneficiary.email ?? ''}
                />
                <label>Email</label>
              </div>
            )}
          </div>

          <p className="text-[16px] py-4 font-normal">Beneficiary’s SSN</p>

          <div className="">
            {loading ? (
              <Skeleton height={90} />
            ) : (
              <div className={`form-group form-group--filled py-2`}>
                <input
                  onChange={handleOnChange}
                  name="socialSecurity"
                  aria-label="socialSecurity"
                  value={alpaca.beneficiary.socialSecurity ?? ''}
                />
                <label>SSN</label>
              </div>
            )}
          </div>

          <p
            onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
            className="underline text-primary text-[14px] underline-offset-4 mt-4 py-3 cursor-pointer"
          >
            I need assistance
          </p>
        </div>
      )

    return (
      <div>
        <p className="text-[18px] font-semibold">Beneficiary (contd.)</p>

        <p className="text-[16px] py-4 font-normal">
          A little bit about the Beneficiary’s citizenship and residency.
        </p>

        {loading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-1">
            <CountrySelector
              title="Country of Citizenship"
              list={countryOptions}
              value={alpaca.beneficiary.citizenshipCountry ?? ''}
              disable={false}
              handleOnchange={handleCitizenshipChange}
            />
          </div>
        )}

        {loading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-1">
            <CountrySelector
              title="Country of Tax Residency"
              list={countryOptions}
              value={alpaca.beneficiary.taxResidenceCountryCode ?? ''}
              disable={false}
              handleOnchange={handleTaxChange}
            />
          </div>
        )}

        <div>
          <select
            name="state"
            aria-label="State"
            value={alpaca.beneficiary.state ?? undefined}
            onChange={handleOnChange}
            className="bg-[#E3F2F2] py-5 mt-2 w-full px-3 rounded-lg outline-none"
          >
            <option key={null} value={''} disabled selected>
              {'Select State'}
            </option>
            {states.map((state) => (
              <option key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!isIntialStepCompleted) {
      if (
        alpaca.beneficiary.firstName &&
        alpaca.beneficiary.lastName &&
        validateEmail(alpaca.beneficiary.email ?? '') &&
        validdateSsn(alpaca.beneficiary.socialSecurity ?? '')
      )
        setDisabled(false)
      else setDisabled(true)
    } else {
      if (
        (alpaca.beneficiary.citizenshipCountryCode,
        alpaca.beneficiary.taxResidenceCountryCode,
        alpaca.beneficiary.state)
      )
        setDisabled(false)
      else setDisabled(true)
    }
  }, [alpaca.beneficiary, isIntialStepCompleted])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    getCountriesList()
      .then((res) => {
        setCountryOptions(res)
        setLoading(false)
      })
      .catch(() => alert('Failed to load Countries List'))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>{displayComponent()}</SafeAreaView>
      <SetupBottomAlpaca
        handleCancel={isIntialStepCompleted ? handleCancel : undefined}
        disabled={disabled}
        handleNext={handleNext}
        loading={loading}
      />
    </div>
  )
}

export default AlpacaStepFifteen
