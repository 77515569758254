import { GET_LINK_TOKEN, PLAID_INTEGRATION_DONE } from '../actions/action.types'

export interface QuickstartState {
  linkSuccess: boolean
  isItemAccess: boolean
  linkToken: string | null
  accessToken: string | null
  itemId: string | null
  isError: boolean
  backend: boolean
  products: string[]
  linkTokenError: {
    error_message: string
    error_code: string
    error_type: string
  }
}

export const initialState: QuickstartState = {
  linkSuccess: false,
  isItemAccess: true,
  linkToken: '', // Don't set to null or error message will show up briefly when site loads
  accessToken: null,
  itemId: null,
  isError: false,
  backend: true,
  products: ['transactions'],
  linkTokenError: {
    error_type: '',
    error_code: '',
    error_message: '',
  },
}
export const linkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_LINK_TOKEN:
      return {
        ...state,
        ...action.payload,
      }
    case PLAID_INTEGRATION_DONE:
      return state
    default:
      return state
  }
}
