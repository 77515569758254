import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../../api'
import { updateUserAccount } from '../../../redux/actions/generalActions'
import { RootReduxStateType } from '../../../types'
import Toast from '../../Common/Toast'
import Navigation from '../../Common/Navigation'
import { LoadingSpinner } from '../../Common/Loading'
import TextField from '../../Common/TextField'
import ProfileManagementLoader from '../../Common/SkeletonLoaders/ProfileManagementLoader'

const PersonalDetails: React.FC = () => {
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState<string>('')
  const [telephoneNumber, setTelephoneNumber] = useState('')
  const [showToast, setShowToast] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(true)

  useEffect(() => {
    getUserDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUserDetails = async () => {
    setDataLoading(true)
    const res = await api.get(`/UserAccount/UserAccountID/${user.id}`)

    setFirstName(res.data.firstName)
    setLastName(res.data.lastName)
    setEmailAddress(res.data.emailAddress)

    if (res.data.middleName) setMiddleName(res.data.middleName)

    setDateOfBirth(res.data.dateOfBirth.substring(0, 10))
    setTelephoneNumber(res.data.telephoneNumber)
    setDataLoading(false)
  }

  const timeOutNotification = () => {
    const timer = setTimeout(() => {
      setShowToast(false)
    }, 3000)

    return () => clearTimeout(timer)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      setLoading(true)
      const res: any = await updateUserAccount(
        user.id,
        firstName,
        lastName,
        dayjs(new Date(dateOfBirth)).format('YYYY-MM-DD'),
        telephoneNumber,
        emailAddress,
        middleName
      )

      if (res.status === 200) {
        // await dispatch(updateProfileStatus('personalDetailsUpdated', true))
        // if (!progress.personalDetailsUpdated) dispatch(updateProfileProgress())
        setShowToast(true)
        timeOutNotification()
        // run getuser details after update is successful
      }
      setLoading(false)
    } catch (error) {
      console.log('Failed to Update Personal Details')
    }
  }

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4">
            <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
              <button
                type="button"
                className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
                onClick={() => navigate(-1)}
              >
                <img
                  src={`/assets/images/back.svg`}
                  alt="back"
                  className="block text-primaryDark"
                />
              </button>
              Personal Details
            </h1>
          </div>

          <div
            className={`lg:w-[37%] lg:mx-auto fixed inset-x-0 z-40 bottom-0  ${
              showToast ? 'translate-x-0' : '-translate-x-full'
            } ease-in-out duration-300 transition-transform`}
          >
            {showToast && (
              <div className="absolute inset-x-0 bottom-0 z-40 mx-auto">
                <Toast
                  styles={'plain'}
                  description="Awesome! Your personal details changes are saved."
                  className="my-5 mx-2"
                />
              </div>
            )}
          </div>

          {dataLoading ? (
            <ProfileManagementLoader />
          ) : (
            <div className="relative">
              <TextField
                type="text"
                label="First Name"
                name="firstName"
                classes={styles.input}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoComplete="off"
              />

              {middleName && (
                <TextField
                  type="text"
                  label="Middle Name"
                  name="middleName"
                  classes={styles.input}
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  autoComplete="off"
                />
              )}

              <TextField
                type="text"
                label="Last Name"
                name="lastName"
                classes={styles.input}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                autoComplete="off"
              />

              <div className="mt-4">
                <button className={styles.button} onClick={handleSubmit}>
                  {loading ? <LoadingSpinner /> : ' Save'}
                </button>
              </div>
            </div>
          )}
        </div>
      </Navigation>
    </>
  )
}

const styles = {
  form: 'w-8/12 flex flex-col justify-center py-10 p-12 mx-auto h-full tab:w-full',
  header:
    'font-semibold text-[18px] leading-[21.6px] text-primaryDark my-[41px]',
  input: 'text-input mb-[16px] focus:outline-0',
  button: 'btn btn--big btn--dark mb-[16px]',
  link: 'text-primary my-[16px] cursor-pointer font-semibold',
}

export default PersonalDetails
