// Use it for features which can stand alone

import { ReduxActionType } from '../../types'
import {
  DOCUMENT_UPLOAD_STEPPER_DECREMENT,
  DOCUMENT_UPLOAD_STEPPER_INCREMENT,
  DOCUMENT_UPLOAD_STEPPER_RETAKE,
  DOCUMENT_UPLOAD_WITH_CAMERA,
  ENABLE_MFA,
  STORE_UPLOAD_DATA,
  UPDATE_UPLOAD_STEP,
  UPDATE_USERACCOUNT_PROFILE,
  UPDATE_USERACCOUNT_PROFILE_PROGRESS,
} from '../actions/action.types'

type InitialStateType = {
  isTwoFactorAuthEnabled: boolean | null
  uploadStep: number
  uploadDocumentsByCamera: boolean
  updateProfile: {
    progress: number
    personalDetailsUpdated: boolean
    contactsUpdated: boolean
    passwordUpdated: boolean
    schoolUpdated: boolean
    twoFAUpdated: boolean
  }
  documentSelectedForUpload: {
    documentList: number
    instructions: string
    isFrontAndBackRequired: boolean
    silaDocumentIdentityType: string
    silaDocumentType: string
    silaDocumentTypeID: number
    silaDocumentTypeLabel: string
  }
}

const initialState: InitialStateType = {
  isTwoFactorAuthEnabled: null,
  uploadStep: 0,
  uploadDocumentsByCamera: false,
  updateProfile: {
    progress: 0,
    personalDetailsUpdated: false,
    contactsUpdated: false,
    passwordUpdated: false,
    schoolUpdated: false,
    twoFAUpdated: false,
  },
  documentSelectedForUpload: {
    documentList: 0,
    instructions: '',
    isFrontAndBackRequired: false,
    silaDocumentIdentityType: '',
    silaDocumentType: '',
    silaDocumentTypeID: 0,
    silaDocumentTypeLabel: '',
  },
}

export const generalReducer = (
  state = initialState,
  action: ReduxActionType<InitialStateType>
) => {
  switch (action.type) {
    case ENABLE_MFA: {
      return {
        ...state,
        isTwoFactorAuthEnabled: action.payload,
      }
    }

    case DOCUMENT_UPLOAD_WITH_CAMERA:
      return {
        ...state,
        uploadDocumentsByCamera: action.payload,
      }

    case UPDATE_UPLOAD_STEP:
      return {
        ...state,
        uploadStep: action.payload,
      }

    case DOCUMENT_UPLOAD_STEPPER_INCREMENT:
      if (state.uploadStep < 4) {
        return {
          ...state,
          uploadStep: state.uploadStep + 1,
        }
      }
      return state

    case DOCUMENT_UPLOAD_STEPPER_DECREMENT:
      if (state.uploadStep < 4 && state.uploadStep > 0) {
        return {
          ...state,
          uploadStep: state.uploadStep - 1,
        }
      }
      return state

    case DOCUMENT_UPLOAD_STEPPER_RETAKE:
      return {
        ...state,
        uploadStep: 1,
      }

    case UPDATE_USERACCOUNT_PROFILE_PROGRESS: {
      if (state.updateProfile.progress <= 100) {
        return {
          ...state,
          updateProfile: {
            ...state.updateProfile,
            progress: state.updateProfile.progress + 25,
          },
        }
      }
      return state
    }

    case UPDATE_USERACCOUNT_PROFILE: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        updateProfile: {
          ...state.updateProfile,
          [key]: value,
        },
      }
    }

    case STORE_UPLOAD_DATA: {
      return {
        ...state,
        documentSelectedForUpload: action.payload,
      }
    }

    default:
      return state
  }
}
