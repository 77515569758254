import { useLoadScript } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { states } from '../../../constants/states'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateEmployer,
  alpacaStepperUpdateEmployerAddress,
  alpacaStepperUpdateVideo,
  alpacaUserEmployerProfile,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import { validateZipCode } from '../../../utilities/generalUtilities'
import SafeAreaView from '../../Common/SafeAreaView'
import AlpacaEmployerAddressAutocomplete from '../AlpacaEmployerAddressAutoComplete'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepEleven: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const alpaca = useSelector(
    (state: RootReduxStateType) => state.alpaca.employment
  )
  const [manualAddress, setManualAddress] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '',
    libraries: ['places'],
  })

  // const [employerName, setEmployerName] = useState<string | null>('')
  // const [employerJobTitle, setEmployerJobTitle] = useState<string | null>('')
  // const [employerAddress1, setEmployerAddress1] = useState<string | null>('')
  // const [employerAddress2, setEmployerAddress2] = useState<string | null>('')
  // const [employerCityOrTown, setEmployerCityOrTown] = useState<string | null>('')
  // const [employerState, setEmployerState] = useState<string | null>('')
  // const [employerzipCode, setEemployerzipCode] = useState<string | null>('')
  const [employerAddress, setEmployerAddress] = useState({
    address1: '',
    address2: '',
    cityOrTown: '',
    zipCode: '',
    state: '',
  })

  const handleNext = async () => {
    if (
      manualAddress &&
      alpaca.employer.employerName &&
      alpaca.employer.employerJobTitle
    ) {
      setLoading(true)
      alpacaUserEmployerProfile({
        userAccountID: user.id,
        employerName: alpaca.employer.employerName,
        streetAddress1: alpaca.employer.employerAddress.address1,
        streetAddress2: alpaca.employer.employerAddress.address2
          ? alpaca.employer.employerAddress.address2
          : '',
        city: alpaca.employer.employerAddress.cityOrTown,
        state: alpaca.employer.employerAddress.state,
        zipCode: alpaca.employer.employerAddress.zipCode,
        countryCode: 'USA',
        occupation: alpaca.employer.employerJobTitle,
      })
        .then(() => {
          setLoading(false)
          dispatch(increamentAlpacaStepperStep())
        })
        .catch((err) => {
          setLoading(false)
          console.log('Failed to create employer profile', err)
        })
    }
  }

  const handleSearchAddress = () => {
    setManualAddress(!manualAddress)
  }

  const handleSearchAnotherAddress = () => {
    dispatch(alpacaStepperUpdateEmployerAddress('address1', null))
    dispatch(alpacaStepperUpdateEmployerAddress('cityOrTown', null))
    dispatch(alpacaStepperUpdateEmployerAddress('zipCode', null))
    dispatch(alpacaStepperUpdateEmployerAddress('state', null))
    handleSearchAddress()
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(alpacaStepperUpdateEmployer(key, value === '' ? null : value))
  }

  const handleAddressOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(
      alpacaStepperUpdateEmployerAddress(key, value === '' ? null : value)
    )
    key === 'address1' &&
      setEmployerAddress({ ...employerAddress, address1: value })
    key === 'address2' &&
      setEmployerAddress({ ...employerAddress, address2: value })
    key === 'cityOrTown' &&
      setEmployerAddress({ ...employerAddress, cityOrTown: value })
    key === 'zipCode' &&
      setEmployerAddress({ ...employerAddress, zipCode: value })
    key === 'state' && setEmployerAddress({ ...employerAddress, state: value })
  }

  useEffect(() => {
    console.log('My address is', employerAddress.address1)
  }, [employerAddress])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))
    //alpaca.status !== 'Employed' && handleNext() dispatch(increamentAlpacaStepperStep())
    alpaca.status !== 'Employed' && dispatch(increamentAlpacaStepperStep())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      alpaca.employer.employerAddress.address1 &&
      alpaca.employer.employerAddress.cityOrTown &&
      alpaca.employer.employerAddress.state &&
      alpaca.employer.employerAddress.zipCode &&
      validateZipCode(alpaca.employer.employerAddress.zipCode)
    ) {
      setManualAddress(true)
      setDisabled(false)
    } else setDisabled(true)
  }, [alpaca.employer])

  return (
    <div>
      <SafeAreaView>
        <p className="text-[18px] py-[16px] font-semibold">Your employer</p>

        <div className={`form-group form-group--filled`}>
          <input
            onChange={handleOnChange}
            name="employerName"
            aria-label="employerName"
            value={
              alpaca.employer.employerName
                ? alpaca.employer.employerName
                : undefined
            }
          />
          <label>Employer's name</label>
        </div>

        <div className={`form-group form-group--filled py-4`}>
          <input
            onChange={handleOnChange}
            name="employerJobTitle"
            aria-label="employerJobTitle"
            value={
              alpaca.employer.employerJobTitle
                ? alpaca.employer.employerJobTitle
                : undefined
            }
          />
          <label>Job Title</label>
        </div>

        <div id="employerAddress">
          <div className="flex justify-between items-center">
            <p className="font-normal text-[17px] py-2">Employer Address</p>
            {manualAddress && (
              <p
                onClick={handleSearchAnotherAddress}
                className="underline text-primary text-[14px] underline-offset-4"
              >
                Search for another
              </p>
            )}
          </div>

          <div className="py-4">
            {isLoaded && !manualAddress && (
              <AlpacaEmployerAddressAutocomplete
                handleSearchAddress={handleSearchAddress}
              />
            )}
            {manualAddress && (
              <div>
                <div className={`form-group form-group--filled py-4`}>
                  <input
                    onChange={handleAddressOnChange}
                    name="address1"
                    aria-label="address1"
                    value={
                      alpaca.employer.employerAddress.address1
                        ? alpaca.employer.employerAddress.address1
                        : undefined
                    }
                  />
                  <label>Address line 1</label>
                </div>

                <div className={`form-group form-group--filled py-2`}>
                  <input
                    onChange={handleAddressOnChange}
                    aria-label="address2"
                    name="address2"
                    value={
                      alpaca.employer.employerAddress.address2
                        ? alpaca.employer.employerAddress.address2
                        : undefined
                    }
                  />
                  <label>Address line 2</label>
                </div>

                <div className="flex">
                  <div className={`form-group form-group--filled py-2 px-1`}>
                    <input
                      onChange={handleAddressOnChange}
                      aria-label="cityOrTown"
                      name="cityOrTown"
                      value={
                        alpaca.employer.employerAddress.cityOrTown
                          ? alpaca.employer.employerAddress.cityOrTown
                          : undefined
                      }
                    />
                    <label>City or town</label>
                  </div>
                  <div className={`form-group form-group--filled py-2 px-1`}>
                    <input
                      onChange={handleAddressOnChange}
                      aria-label="zipCode"
                      name="zipCode"
                      value={
                        alpaca.employer.employerAddress.zipCode
                          ? alpaca.employer.employerAddress.zipCode
                          : undefined
                      }
                    />
                    <label>ZIP</label>
                  </div>
                </div>

                <div>
                  <select
                    name="state"
                    aria-label="State"
                    value={
                      alpaca.employer.employerAddress.state
                        ? alpaca.employer.employerAddress.state
                        : undefined
                    }
                    onChange={handleAddressOnChange}
                    className="bg-[#E3F2F2] py-5 mt-2 w-full px-3 rounded-lg outline-none"
                  >
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <p
                  onClick={() =>
                    dispatch(alpacaStepperUpdateAssistenceNeeded(true))
                  }
                  className="underline text-primary text-[14px] underline-offset-4 py-5 cursor-pointer"
                >
                  I need assistance
                </p>
              </div>
            )}
          </div>
        </div>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={disabled}
        loading={loading}
        handleNext={!manualAddress ? handleSearchAddress : handleNext}
      />
    </div>
  )
}

export default AlpacaStepEleven
