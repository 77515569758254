import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const middleware = [thunk]

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeWithDevTools

export const store = createStore(
  rootReducer,
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? composeEnhancers(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
)

export const persistor = persistStore(store)

const persistedStore = {
  store,
  persistor,
}

export default persistedStore
