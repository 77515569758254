import { Dispatch } from 'redux'
import api from '../../api'
import { formatPhoneNumberToSave } from '../../utilities/generalUtilities'
import { SetupSavingsAccountProcessType } from '../reducers/setupSavingsStepperReducer'
import {
  SETUP_SAVINGS_REGISTER_CHILD,
  SETUP_SAVINGS_REMOVE_CHILD,
  SETUP_SAVINGS_STEPPER_DECREAMENT,
  SETUP_SAVINGS_STEPPER_INCREAMENT,
  SETUP_SAVINGS_STEPPER_RESET,
  SETUP_SAVINGS_STEPPER_UPDATE,
  SETUP_SAVINGS_STEPPER_UPDATE_MULTIPLE,
  SETUP_SAVINGS_UPDATE_CHILD,
  SETUP_USER_DETAIL_FORM_STEP,
  UPDATE_KYC_STATUS,
} from './action.types'

export const userDetailStepperStep = () => async (dispatch: Function) => {
  dispatch({
    type: SETUP_USER_DETAIL_FORM_STEP,
  })
}

export const increamentSavingsStepperStep =
  () => async (dispatch: Function) => {
    dispatch({
      type: SETUP_SAVINGS_STEPPER_INCREAMENT,
    })
  }

export const decreamentSavingsStepperStep =
  () => async (dispatch: Function) => {
    dispatch({
      type: SETUP_SAVINGS_STEPPER_DECREAMENT,
    })
  }

export const setupSavingsStepperUpdate =
  (key: string, value: string | boolean) => async (dispatch: Function) => {
    dispatch({
      type: SETUP_SAVINGS_STEPPER_UPDATE,
      payload: { key, value },
    })
  }

export const setupSavingsUpdateKYCStatus =
  (data: string) => (dispatch: Function) => {
    dispatch({
      type: UPDATE_KYC_STATUS,
      payload: data,
    })
  }

export const setupSavingsStepperReset = () => async (dispatch: Function) => {
  dispatch({
    type: SETUP_SAVINGS_STEPPER_RESET,
  })
}

export const setupSavingsStepperUpdateMultiple =
  (fieldsObject: Partial<SetupSavingsAccountProcessType>) =>
  async (dispatch: Function) => {
    dispatch({
      type: SETUP_SAVINGS_STEPPER_UPDATE_MULTIPLE,
      payload: fieldsObject,
    })
  }

export const setupSavingsRegisterChild =
  (data: {
    firstName: string
    lastName: string
    dateOfBirth: string
    relationship: string
    phoneNumber: string
    email: string
    ssn: string
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SETUP_SAVINGS_REGISTER_CHILD,
      payload: data,
    })
  }

export const setupSavingsRemoveChild =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: SETUP_SAVINGS_REMOVE_CHILD,
      payload: id,
    })
  }

export const setupSavingsUpdateChild =
  (payload: {
    id: string
    data: {
      firstName: string
      lastName: string
      dateOfBirth: string
      relationship: string
      phoneNumber: string
      email: string
      ssn: string
    }
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SETUP_SAVINGS_UPDATE_CHILD,
      payload,
    })
  }

// ------------------ API ------------------
export const setupSavingsRegisterChildAPI = async (data: {
  userAccountID: number
  firstName: string
  lastName: string
  dateOfBirth: string
  phoneNumber: string
  email: string
  ssn: string
}) => {
  return api.post('/MinorAccount', {
    ParentUserAccountID: data.userAccountID,
    FirstName: data.firstName,
    MiddleName: '',
    LastName: data.lastName,
    EmailAddress: data.email === '' ? null : data.email,
    // TelephoneNumber:
    //   data.phoneNumber === '' || data.phoneNumber === '1'
    //     ? null
    //     : formatPhoneNumberToSave(data.phoneNumber),
    TelephoneNumber: formatPhoneNumberToSave(data.phoneNumber),
    DateOfBirth: data.dateOfBirth,
    IdentifierType: 'SocialSecurityNumber',
    Identifier: data.ssn,
    Relationship: 'Child',
    ChangeReason: 'Child registered via Client',
  })
}

export const setupSavingsGetChildrenAPI = async () => {
  return api.get('/MinorAccount')
}

const formatPhoneNumberToUpdate = (phone: string) => {
  if (phone === '' || phone === null) {
    return null
  } else if (phone.startsWith('+1 ')) {
    return phone
  } else if (phone.startsWith('1')) {
    return formatPhoneNumberToSave(phone)
  }
}

export const setupSavingsUpdateChildAPI = async (data: {
  minorAccountID: number
  userAccountID: number
  firstName: string
  lastName: string
  dateOfBirth: string
  phoneNumber: string
  email: string
  ssn: string
}) => {
  return api.put('/MinorAccount/Update', {
    MinorAccountID: data.minorAccountID,
    ParentUserAccountID: data.userAccountID,
    FirstName: data.firstName,
    MiddleName: '',
    LastName: data.lastName,
    EmailAddress: data.email,
    TelephoneNumber: formatPhoneNumberToUpdate(data.phoneNumber),
    DateOfBirth: data.dateOfBirth,
    IdentifierType: 'SocialSecurityNumber',
    Identifier: data.ssn,
    Relationship: 'Child',
    ChangeReason: 'Child registered via Client',
  })
}

export const getSilaUserAccountBalance = async () => {
  return api.get('/SilaUserAccount/Balance')
}

export const silaAgreementsAPI = async (
  userAccountID: string,
  signerIPAddress: string
) => {
  return api.post('/SilaUserAccount/Agreement', {
    userAccountID: userAccountID,
    CreatedUserAccountID: userAccountID,
    weblink: 'https://app.dev.sootchy.com/legal',
    signatureDate: new Date().toISOString(),
    signerIPAddress: signerIPAddress,
    SourceProcess: 'WebApplication',
  })
}

export const setupSavingsDeleteChildAPI = async (
  childID: string,
  parentUserAccountID: string
) => {
  return api.delete(`MinorAccount/${childID}/${parentUserAccountID}`)
}
