import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../../api'
import { setupSavingsUpdateKYCStatus } from '../../../redux/actions/setupSavingsStepperActions'
import { getSillaStatus } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
// @ts-ignore
import HamburgerMenu from './HamburgerMenu'
import HeaderCoins from './HeaderCoins'
import UserMenu from './UserMenu'

type LinkType = {
  name: string
  active: boolean
  path: string
}

const linksData: LinkType[] = [
  { name: 'Overview', active: true, path: '/overview' },
  { name: 'Wallet', active: false, path: '/wallet' },
  { name: 'Money Academy', active: false, path: '/money-academy' },
  { name: `My Coins`, active: false, path: '/my-coins' },
  { name: `Alpaca`, active: false, path: '/alpaca' },
  // { name: 'Family', active: false, path: '/family' },
]

type PropsType = {
  style?: 'default' | 'primary' | 'plain' | 'wallet'
  color?: string
}

const NewNavbar: React.FC<PropsType> = ({ style = 'default', color }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const kycStatus = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper.kycStatus
  )

  const [links, setLinks] = useState<LinkType[]>(linksData)
  const [coins, setCoins] = useState<number>(0)

  const [showNav, setShowNav] = useState<boolean>(false)
  const [_, setIsLogged] = useState<boolean>()

  const handleGetSilaStatus = async () => {
    try {
      const res = await getSillaStatus(user.id)

      if (res.status === 200) {
        if (res.data.silaAccountVerificationStatus !== undefined) {
          dispatch(
            setupSavingsUpdateKYCStatus(res.data.silaAccountVerificationStatus)
          )
        }
        //console.log('KYC STATUS AGAIN', res.data.silaAccountVerificationStatus)
      }
    } catch (err: any) {
      console.error('Unable to get sila status', err)
    }
  }

  const handleClick = () => {
    setShowNav(!showNav)
  }

  const updateRouteState = () => {
    const pathname = window.location.pathname

    const newArray = links.map((link: LinkType) => {
      if (link.path === pathname)
        return { name: link.name, active: true, path: link.path }
      else
        return {
          ...link,
          active: false,
          path: link.path,
        }
    })

    setLinks(newArray)
  }

  const getCoins = async () => {
    if (user.token) {
      setIsLogged(true)
      const response = await api.get(`UserAccount/UserAccountID/${user.id}`)
      if (response.status === 200) setCoins(+response.data.pointsEarned / 100)
    }
  }

  const getBackgroundColor = (color: any) => {
    return `bg-[${color}]`
  }

  useEffect(() => {
    if (user.id) {
      handleGetSilaStatus()
    }
    updateRouteState()
    getCoins()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  return (
    <div
      style={{ backgroundColor: `${style === 'wallet' && color}` }}
      className={`m-auto ${
        style === 'plain'
          ? ''
          : style === 'default'
          ? 'border-b-2 border-dashed border-[#C4E8E8]'
          : style === 'wallet'
          ? `${getBackgroundColor(color)}`
          : 'bg-primaryLight'
      }`}
    >
      <div className="flex justify-between items-center px-[20px] py-[18px]">
        <div className="flex justify-between items-center">
          <img
            src={
              style === 'wallet'
                ? '/assets/images/Logo_white.svg'
                : 'https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/logo.svg'
            }
            alt="logo"
            className="hidden  lg:block cursor-pointer"
            onClick={() => navigate('/')}
          />
          <button
            onClick={() => setShowNav(!showNav)}
            className="flex lg:hidden items-center space-x-[8px] border-0 t"
          >
            {style === 'wallet' ? (
              <img src="/assets/images/menubar_white.svg" alt="menu" />
            ) : (
              <img src="/assets/images/menu.svg" alt="menu" />
            )}
            <p
              className={`font-bold text-[11px] uppercase leading-[13.76%] ${
                style === 'wallet' ? 'text-white' : 'text-[#0E6D6C]'
              }`}
            >
              Menu
            </p>
          </button>

          <div
            className={`lg:hidden fixed inset-x-0 z-40 top-0 transform ${
              showNav ? 'translate-x-0' : '-translate-x-full'
            } ease-in-out duration-300`}
          >
            <HamburgerMenu show={showNav} handleClick={handleClick} />
          </div>

          {links.map((link, index: number) => (
            <div
              className="hidden px-7 lg:flex   cursor-pointer"
              key={index}
              onClick={() => navigate(link.path)}
            >
              <p
                //className={
                //link.active ? styles.activeStyle : styles.defaultStyle
                // }
                style={{
                  color: `${
                    style === 'wallet'
                      ? link.active
                        ? color
                        : '#FFFFFF'
                      : link.active
                      ? '#FFFFFF'
                      : '#004F4E'
                  }`,
                }}
                className={`${
                  style === 'wallet'
                    ? link.active
                      ? 'py-1 px-3 bg-white rounded-full cursor-pointer'
                      : 'cursor-pointer'
                    : link.active
                    ? styles.activeStyle
                    : styles.defaultStyle
                }`}
              >
                {link.name}
              </p>

              {link.name === 'Wallet' &&
                kycStatus !== null &&
                kycStatus !== 'Pending' &&
                kycStatus !== 'Passed' && (
                  <div className="ml-2 flex items-center">
                    <div className="h-3 w-3 animate-pulse rounded-full bg-red-600"></div>
                  </div>
                )}
            </div>
          ))}
        </div>

        {user.token ? (
          <div className="flex items-center">
            {style === 'wallet' ? (
              <div className="flex justify-center items-center space-x-2">
                <HeaderCoins wallet color={color} url="/" number={coins || 0} />
                <UserMenu wallet />
              </div>
            ) : (
              <div className="flex justify-center items-center space-x-2">
                <HeaderCoins url="/" number={coins || 0} />
                <UserMenu />
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            <button
              className={`mr-4 btn border-transparent`}
              onClick={(e) => navigate('/login')}
            >
              Login
            </button>
            <button className="btn" onClick={(e) => navigate('/signup')}>
              Sign up
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const styles = {
  activeStyle: 'bg-[#004F4E] py-1 px-3 text-white rounded-full cursor-pointer',
  defaultStyle: 'text-[#004F4E] cursor-pointer',
  activeMdStyle: '',
  defaultMdStyle:
    'leading-[22.31px] text-primaryDark font-semibold text-[18px]',
  wave: {
    wrapper: 'overflow-hidden line-height leading-[0px] w-[100%]',
    svg: 'relative block h-[30px] lg:h-[80px] w-[calc(100%_+_1.3px)]',
    path: 'fill-[#7FE5E4]',
  },
  start: {
    wrapper: 'bg-accent relative',
    wave: 'top-0 md:top-[-2px] bottom-auto scale-y-[-1]',
  },
}

export default NewNavbar
