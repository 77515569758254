import { track, getSessionId } from '@amplitude/analytics-browser'

export const trackLoginEvent = async ({ userId: id }: { userId: string }) => {
  const eventName = 'Login'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Login Button',
    buttonClickedAt: new Date().toISOString(),
    userId: id,
  }

  track(eventName, eventProperties)
}

export const trackIncorrectPasswordEvent = async ({
  username,
}: {
  username: string
}) => {
  const eventName = 'Incorrect password Login'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Login Button',
    buttonClickedAt: new Date().toISOString(),
    username,
  }

  track(eventName, eventProperties)
}

export const trackAddChildEvent = async ({
  userId: id,
  firstName,
  lastName,
}: {
  userId: string
  firstName: string
  lastName: string
}) => {
  const eventName = 'Add Child'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Add Child Button',
    buttonClickedAt: new Date().toISOString(),
    userId: id,
    firstName,
    lastName,
  }

  track(eventName, eventProperties)
}

export const trackLogoutEvent = async ({ userId: id }: { userId: string }) => {
  const eventName = 'Logout'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Logout Button',
    buttonClickedAt: new Date().toISOString(),
    userId: id,
  }

  track(eventName, eventProperties)
}

export const trackSignupEvent = async ({
  username,
  firstName,
  lastName,
}: {
  username: string
  firstName: string
  lastName: string
}) => {
  const eventName = 'Signup'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Signup Button',
    buttonClickedAt: new Date().toISOString(),
    username,
    firstName,
    lastName,
  }

  track(eventName, eventProperties)
}

export const getSession = async () => {
  return await getSessionId()
}

/*
Start Activation Register event -> 
Track Add Child Event with session ID -> 
Tract investment details with session ID -> 
Final Confimation Page (track sessions and create activation signup event) */

// Start Activation Register event
export const trackActivationRegisterEvent = async ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}) => {
  const eventName = 'Activation Register'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Activation Register Button',
    buttonClickedAt: new Date().toISOString(),
    firstName,
    lastName,
  }

  const sessionId = await getSession()
  track(eventName, eventProperties, { session_id: sessionId })
}

// Track Add Child Event
export const trackActivationAddChildEvent = async ({
  children,
}: {
  children: [] | any
}) => {
  const eventName = 'Activation Add Child'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Activation Register Button',
    buttonClickedAt: new Date().toISOString(),
    children,
  }

  const sessionId = await getSession()
  track(eventName, eventProperties, { session_id: sessionId })
}

// Track Set Goal
export const trackActivationSetGoalEvent = async ({
  reason,
  timeline,
}: {
  reason: string
  timeline: string
}) => {
  const eventName = 'Set Goal Activation'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Set Goal Activation Button',
    buttonClickedAt: new Date().toISOString(),
    reason,
    timeline,
  }

  const sessionId = await getSession()
  track(eventName, eventProperties, { session_id: sessionId })
}

// Tracking Signup from Activation Event (Final Confimation Page)
export const trackSignupActivationEvent = async ({
  username,
}: {
  username: string
  firstName: string
  lastName: string
}) => {
  const eventName = 'Signup Activation'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Signup Button',
    buttonClickedAt: new Date().toISOString(),
    username,
  }

  const sessionId = await getSession()
  track(eventName, eventProperties, { session_id: sessionId })
}

export const trackApplicationSubmittedEvent = async ({
  userId,
}: {
  userId: number
}) => {
  const eventName = 'Application Submitted'
  const eventProperties = {
    platform: 'Web',
    buttonName: 'Application Submit Button',
    buttonClickedAt: new Date().toISOString(),
    userId,
  }

  const sessionId = await getSession()
  track(eventName, eventProperties, { session_id: sessionId })
}
