import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
import { useDispatch, useSelector } from 'react-redux'
import { createAlertAction } from '../../../redux/actions/alertActions'
import {
  getWallet,
  getWallets,
  updateWallet,
} from '../../../redux/actions/linkActions'
import { RootReduxStateType } from '../../../types'
import { WalletType } from '../../../types/transactions'
// @ts-ignore
import { ChromePicker } from 'react-color'
import LayoutFullPage from '../../Common/Layouts/FullPage'
import FullPageTitle from '../../Common/Partials/FullPageTitle'
import ProfileManagementLoader from '../../Common/SkeletonLoaders/ProfileManagementLoader'

const WalletSettings = () => {
  const navigate = useNavigate()
  const params = useParams()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const dispatch = useDispatch()

  const [selectedWalletColor, setSelectedWalletColor] = useState<any>(null)
  const [walletData, setWalletData] = useState<{ name: string; color: string }>(
    {
      name: 'New Wallet',
      color: 'Red',
    }
  )
  const [walletName, setWalletName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [silaWalletID, setSilaWalletID] = useState<number | null>(null)
  const [walletAddress, setWalletAddress] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [wallets, setWallets] = useState<WalletType[] | null>(null)
  const [isWalletNameUnique, setIsWalletNameUnique] = useState<boolean>(false)
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)

  const handleGetWallets = async () => {
    try {
      const response = await getWallets()

      if (response.status === 200) {
        setWallets(response.data)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleGetWallet = async () => {
    setLoading(true)
    const response = await getWallet(params.walletName ?? '')

    if (response.success) {
      setWalletName(response.response?.data.walletName)
      setSelectedWalletColor(response.response?.data.walletColor)
      setWalletData({
        ...walletData,
        color: response.response?.data.walletColor,
      })
      setSilaWalletID(response.response?.data.silaWalletID)
      setWalletAddress(response.response?.data.walletAddress)
    }

    setLoading(false)
  }

  const handleUpdateWallet = async () => {
    setDisabled(true)
    if (params.walletName !== walletName) {
      const isWalletNameUnique = wallets?.filter(
        (wallet: WalletType) => wallet.walletName === walletName
      )[0]

      if (isWalletNameUnique) {
        setIsWalletNameUnique(true)
        return
      }
    }

    await updateWallet({
      walletName,
      walletColor: walletData.color,
      silaWalletID,
      walletAddress,
      userAccountID: user.id,
    })

    setDisabled(false)
    dispatch(
      createAlertAction({
        type: 'INFO',
        title: 'Successfully updated',
        message: 'Wallet Successfully updated',
        autoClose: true,
      })
    )

    navigate(`/wallet`)
  }

  useEffect(() => {
    if (params.walletName) {
      handleGetWallet()
    }
    handleGetWallets()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <LayoutFullPage>
          <FullPageTitle title="Wallet Settings" onBack={() => navigate(-1)} />
          <ProfileManagementLoader />
        </LayoutFullPage>
      ) : (
        <LayoutFullPage>
          <FullPageTitle title="Wallet Settings" onBack={() => navigate(-1)} />

          <div className="w-full">
            <div>
              <p className="text-[15px] mt-10">Wallet name</p>

              <input
                type="text"
                value={walletName && walletName}
                aria-label="Wallet Name"
                className="w-full mt-2 h-[50px] bg-[#E3F2F2] rounded-lg px-3 text-[15px]"
                onChange={(e: any) => {
                  setIsWalletNameUnique(false)
                  setDisabled(false)
                  setWalletName(e.target.value)
                }}
              />

              {isWalletNameUnique && (
                <p className="text-[13px] py-1 text-red-700">
                  Wallet name should be unique
                </p>
              )}
            </div>

            <div>
              <p className="text-[15px] mt-5">Wallet color</p>
              <div className="mt-2 flex">
                <div
                  style={{
                    backgroundColor: `${
                      selectedWalletColor && selectedWalletColor.hex
                    }`,
                  }}
                  className={`w-[40px] h-[40px] cursor-pointer border-none rounded-full ${
                    !selectedWalletColor?.hex &&
                    'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
                  }`}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                ></div>
              </div>
              {showColorPicker && (
                <div
                  onMouseLeave={() => setShowColorPicker(false)}
                  className="mb-[20px]"
                >
                  <ChromePicker
                    color={selectedWalletColor}
                    onChange={(updatedColor: any) => {
                      setSelectedWalletColor(updatedColor)
                      setWalletData({
                        ...walletData,
                        // @ts-ignore
                        color: selectedWalletColor.hex,
                      })
                    }}
                  />
                </div>
              )}
              <button
                className="w-full mt-10 py-4"
                onClick={handleUpdateWallet}
                disabled={disabled}
              >
                Update Wallet
              </button>
            </div>
          </div>
        </LayoutFullPage>
      )}
    </>
  )
}

export default WalletSettings
