import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAlertAction } from '../../../../redux/actions/alertActions'
import {
  getApplicationStatus,
  increamentMy529Step,
  setupMy529AddChildrenInfo,
  setupMy529Update,
} from '../../../../redux/actions/my529Actions'
import { RootReduxStateType } from '../../../../types'
import { LoadingSpinner } from '../../../Common/Loading'
import SafeAreaView from '../../../Common/SafeAreaView'
import My529SetupBottom from '../My529SetupBottom'

const AccountBeneficiaryStep = () => {
  const dispatch = useDispatch()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)
  const [isSelfPlansCreated, setIsSelfPlansCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const getPlansCreated = async () => {
    getApplicationStatus()
      .then((res) => {
        if (res.data.length > 0) {
          //console.log('Getting plans', res.data)

          res.data.filter((item: any) => {
            if (
              item.minorAccountID === null ||
              item.minorAccountID === undefined
            ) {
              setIsSelfPlansCreated(true)
              return
            }
          })
        }
        setLoading(false)
      })
      .catch(() => {
        alert('Error Retrieving Application')
        setLoading(false)
      })
  }

  const handleNext = async () => {
    setSubmitLoading(true)
    if (my529.accountBeneficiary === 'MY_SELF') {
      // dispatch(setupMy529Update('step', 8))
      // return

      if (isSelfPlansCreated) {
        dispatch(
          createAlertAction({
            type: 'DANGER',
            title: 'Error Occured',
            message: 'Application for this user already exists',
            autoClose: true,
            autoCloseDuration: 3000,
          })
        )
      } else {
        dispatch(setupMy529Update('step', 8))
        setSubmitLoading(false)
        return
      }
    } else if (my529.accountBeneficiary === 'MY_CHILDREN_NIECE') {
      dispatch(increamentMy529Step())
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    getPlansCreated()
    dispatch(setupMy529Update('video', null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (my529.accountBeneficiary === 'MY_SELF') {
      dispatch(setupMy529AddChildrenInfo([]))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [my529.accountBeneficiary])

  return (
    <div>
      <SafeAreaView>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingSpinner color="#004F4E" />
          </div>
        ) : (
          <>
            <p className="text-[18px] font-semibold">Account beneficiary</p>
            <p className="py-2">Who are you opening this account for?</p>

            <div className="mt-3">
              <div className="py-1">
                <input
                  type="radio"
                  id="children"
                  onClick={() =>
                    dispatch(
                      setupMy529Update(
                        'accountBeneficiary',
                        'MY_CHILDREN_NIECE'
                      )
                    )
                  }
                  checked={my529.accountBeneficiary === 'MY_CHILDREN_NIECE'}
                />
                <label htmlFor="children" className="px-2">
                  My children, nieces/nephews or grandchildren
                </label>
              </div>

              <div className="py-1">
                <input
                  type="radio"
                  id="myself"
                  onClick={() =>
                    dispatch(setupMy529Update('accountBeneficiary', 'MY_SELF'))
                  }
                  checked={my529.accountBeneficiary === 'MY_SELF'}
                />
                <label htmlFor="myself" className="px-2">
                  Myself
                </label>
              </div>
            </div>
          </>
        )}
      </SafeAreaView>

      <My529SetupBottom
        handleNext={handleNext}
        disabled={submitLoading ? true : !my529.accountBeneficiary}
      />
    </div>
  )
}

export default AccountBeneficiaryStep
