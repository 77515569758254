import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Add supported languages
const languages = ['en', 'es']

const JSON_PATH = (file: string, lang: string) => {
  try {
    return require(`./i18n/${lang}/${file}.json`)
  } catch {
    return null // Important - This is fallback - Don't delete
  }
}

const resources = {
  en: {
    translations: JSON_PATH(`translations`, 'en'),
  },
  es: {
    translations: JSON_PATH(`translations`, 'es'),
  },
}

i18n.use(initReactI18next).init(
  {
    lng: localStorage.getItem('lang') || 'en',
    resources,
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'], // 'override',
    defaultNS: 'translations',
    fallbackNS: ['translations'],
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: false,
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'hr'],
    },
    // @ts-ignore
    whitelist: languages,
  },
  (err, t) => {
    if (err) {
      return console.log('failed to init i18n', err, t)
    }
  }
)

export default i18n
