import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user'
import DocumentUpload from '../../../Common/DocumentUpload'
import NewFooter from '../../../Common/Partials/NewFooter'
// @ts-ignore
import SootchyGreenLogo from '../../../../assets/SootchyGreenLogo.png'
// @ts-ignore
import CloseIcon from '../../../../assets/closeIcon.png'

const VerifySavingsAccount = () => {
  const navigate = useNavigate()
  const [_, setShowModal] = useState(false)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [silaAccountIsNull, setSilaAccountIsNull] = useState(false)
  const [loadedUser, __] = useState<UserAPIType>()
  const [___, setShowToast] = useState<boolean>(false)
  const [selectedDoc, setSelectedDoc] = useState<any>()
  const [____, setFile] = useState('')

  const timeOutNotification = () => {
    const timer = setTimeout(() => {
      setShowToast(false)
    }, 3000)

    return () => clearTimeout(timer)
  }

  const handleGetUser = async () => {
    try {
      const response = await getUser(user.id)
      if (response.status === 200) {
        if (response.data) {
          //console.log('Sila', response.data.silaUserAccountID)
          if (response.data.silaUserAccountID === null)
            setSilaAccountIsNull(true)
        }
      }
    } catch (err: any) {
      return { success: false, err }
    }
  }

  // const handleDocumentAdd = (data: any) => {
  //   setSelectedDoc(data)
  //   //console.log('File Selected', data)
  // }

  // const handleFileChange = (e: any) => {
  //   setFile(e.target.files[0])
  //   handleFileUpload(e.target.files[0])
  // }

  // const handleFileUpload = async (data: any) => {
  //   try {
  //     let formData = new FormData()

  //     if (data) {
  //       if (loadedUser) {
  //         let silaUserID: any = loadedUser.silaUserAccountID

  //         formData.append('files', data)
  //         formData.append('SilaUserAccountID', silaUserID)
  //         formData.append('SilaDocumentType', selectedDoc.silaDocumentType)
  //         formData.append('CreatedUserAccountID', user.id)
  //         formData.append('SourceProcess', 'WebApplication')

  //         const response: any = await api.post('SilaDocument/Upload', formData)

  //         if (response.status === 200) {
  //           console.log('Response from Upload', response)
  //           setShowModal(false)
  //           setShowToast(true)
  //           timeOutNotification()
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log('Failed to upload file')
  //   }
  // }

  useEffect(() => {
    handleGetUser()
    silaAccountIsNull && navigate('/setup-savings')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [silaAccountIsNull])

  return (
    <>
      <div>
        <div className="bg-[#E3F2F2] hidden sm:block">
          <div className="flex justify-between items-center sm:w-[450px] m-auto py-10">
            <img
              src={SootchyGreenLogo}
              alt="Sootchy-logo"
              className="w-[200px]"
            />
            <p
              className="text-primaryDark underline cursor-pointer"
              onClick={() => navigate('/')}
            >
              Back to website
            </p>
          </div>
        </div>
        <div className="h-full w-full sm:bg-[#E3F2F2] flex justify-center items-center sm:min-h-screen">
          <div className="m-auto w-full bg-white sm:w-[450px] sm:shadow-primaryLight sm:shadow-md sm:py-3 sm:px-5 sm:rounded-xl">
            <div className="w-[90%] m-auto">
              <div className="flex justify-between items-center py-6 border-b-2 border-dashed border-[#C4E8E8]">
                <p className="text-[18px] font-semibold text-primaryDark">
                  Activate wallet
                </p>
                <img
                  src={CloseIcon}
                  alt="close icon"
                  className="w-[13px] h-[13px] cursor-pointer"
                  onClick={() => navigate('/')}
                />
              </div>
              {/**<BrowseFiles /> */}
              <DocumentUpload />
            </div>
          </div>
        </div>
        <div className="hidden sm:block">
          <NewFooter />
        </div>
      </div>
    </>
  )
}

export default VerifySavingsAccount
