import React from 'react'
import { TransactionMessages } from './TransactionMessages'
import dayjs from 'dayjs'

const Transaction: React.FC<{ transaction: any; currentWalletName?: any }> = ({
  transaction,
  currentWalletName,
}) => {
  return (
    <>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="flex justify-center items-center w-[24px] h-[24px] bg-[#3EB76E] rounded-full">
            {TransactionMessages(
              transaction,
              currentWalletName
            ).TransactionIcons()}
          </div>
          <div className="flex items-center">
            <h3 className="font-normal  text-primaryDark text-[1rem]] leading-[22px]">
              {TransactionMessages(
                transaction,
                currentWalletName
              ).TransactionSummary()}
            </h3>
          </div>
        </div>

        <div>
          <p className="capitalize leading-[21px] text-[14px] font-normal text-[#87AEAE] w-full">
            {dayjs(transaction.createDateUTC).format('MMMM D, h:mm A')}
          </p>
        </div>
      </div>
    </>
  )
}

export default Transaction
