import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../../api'
import { RootReduxStateType } from '../../../types'
import UserMenu from './UserMenu'

type LinkType = {
  name: string
  active: boolean
  path: string
}

type MenuProps = {
  show: boolean
  handleClick: React.MouseEventHandler<HTMLButtonElement>
}

const linksData: LinkType[] = [
  { name: 'Overview', active: true, path: '/overview' },
  { name: 'Wallet', active: false, path: '/wallet' },
  { name: 'Money Academy', active: false, path: '/money-academy' },
  { name: `Alpaca`, active: false, path: '/alpaca' },
  { name: `My Coins`, active: false, path: '/my-coins' },
]

const HamburgerMenu: React.FC<MenuProps> = ({ show, handleClick }) => {
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const kycStatus = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper.kycStatus
  )

  const [links, setLinks] = useState<LinkType[]>(linksData)
  const [coins, setCoins] = useState<number>(0)

  const updateRouteState = () => {
    const pathname = window.location.pathname

    const newArray = links.map((link: LinkType) => {
      if (link.path === pathname)
        return { name: link.name, active: true, path: link.path }
      else
        return {
          ...link,
          active: false,
          path: link.path,
        }
    })

    setLinks(newArray)
  }

  const getCoins = async () => {
    if (user.token) {
      const response = await api.get(`UserAccount/UserAccountID/${user.id}`)
      if (response.status === 200) setCoins(+response.data.pointsEarned / 100)
    }
  }

  useEffect(() => {
    updateRouteState()
    getCoins()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  return (
    <div className="min-h-screen bg-[#021212BF]">
      <div className="flex flex-col px-[20px] py-[15px] bg-[#7FE5E4] opacity-100">
        <div className="flex justify-between items-center">
          <button
            onClick={handleClick}
            className="flex lg:hidden items-center space-x-[8px] border-0 translate-x-0  ease-out duration-300"
          >
            <img src="/assets/images/close.svg" alt="close" />
          </button>

          <img
            src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/logo.svg"
            alt="logo"
            className="cursor-pointer h-[28px] "
            onClick={() => navigate('/')}
          />
          {user.token ? (
            <UserMenu />
          ) : (
            <button className="btn" onClick={(e) => navigate('/login')}>
              Login
            </button>
          )}
        </div>

        {links.map((link, index: number) => (
          <div
            className={`py-[21px] cursor-pointer ${
              index === links.length - 1
                ? ''
                : 'border-b-2 border-primaryDark border-dashed'
            }`}
            key={index}
            onClick={() => navigate(link.path)}
          >
            {link.active ? (
              <div className="flex items-center">
                <img
                  src="/assets/images/arrow-down.svg"
                  alt="arrow right"
                  className="transform -rotate-90 mr-[18px]"
                />
                <p
                  className={
                    link.active ? styles.activeMdStyle : styles.defaultMdStyle
                  }
                >
                  {link.name}
                </p>
              </div>
            ) : (
              <div
                className={`flex items-center h-[22px] ${
                  link.active ? styles.activeMdStyle : styles.defaultMdStyle
                }`}
              >
                <p>{link.name}</p>

                {link.name === 'Wallet' &&
                  kycStatus !== null &&
                  kycStatus !== 'Pending' &&
                  kycStatus !== 'Passed' && (
                    <div className="ml-2 flex items-center">
                      <div className="h-3 w-3 animate-pulse rounded-full bg-red-600"></div>
                    </div>
                  )}

                {link.name === 'My Coins' && (
                  <div className="ml-2 flex items-center space-x-2 h-full">
                    <p>{coins}</p>
                    <img
                      className="w-[16px] h-[12px]"
                      src="/assets/images/coinDark.svg"
                      alt="Coin"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-between w-full h-16 items-center text-[14px] text-primaryDark">
          <div className="flex">
            <p className="">© Sootchy, Inc.</p>
            <div className="ml-12">
              <a
                className="px-4 underline underline-offset-4 text-primaryDark"
                href="/"
              >
                Help
              </a>

              <a
                className="px-4 underline underline-offset-4 text-primaryDark"
                href="/legal"
              >
                Legal
              </a>
            </div>
          </div>
          <div>
            <p className="underline underline-offset-4 cursor-pointer">
              Español
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.wave.wrapper} ${styles.start.wave}`}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          className={styles.wave.svg}
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className={styles.wave.path}
          ></path>
        </svg>
      </div>
    </div>
  )
}

const styles = {
  activeStyle: 'bg-[#004F4E] py-1 px-3 text-white rounded-full cursor-pointer',
  defaultStyle: 'text-[#004F4E] cursor-pointer',
  activeMdStyle:
    'text-primaryDark leading-[22.31px] font-semibold text-[18px] cursor-pointer',
  defaultMdStyle:
    'leading-[22.31px] text-[#0E6D6C] p font-semibold text-[18px] cursor-pointer',
  wave: {
    wrapper: 'mt-[-2px] overflow-hidden line-height leading-[0px] w-[100%]',
    svg: 'relative block h-[30px] lg:h-[80px] w-[calc(100%_+_1.3px)]',
    path: 'fill-[#7FE5E4]',
  },
  start: {
    wrapper: 'bg-accent relative',
    wave: 'top-0 md:top-[-2px] bottom-auto scale-y-[-1]',
  },
}

export default HamburgerMenu
