import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PinInput } from 'react-input-pin-code'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from '../components/Common/Partials/Modal'
import LoginComponent from '../components/SootchyProfile/Login'
import { LOG_IN } from '../redux/actions/action.types'
import { validateMultiFactorAuth } from '../redux/actions/generalActions'
import { loginAction } from '../redux/actions/userActions'
import { getUserAge, getUserFromToken } from '../utilities/generalUtilities'
// @ts-ignore
import dayjs from 'dayjs'
import DefaultLayout from '../components/Common/Layouts/NewDefault'
import { resetUserRegistration } from '../redux/actions/userRegistrationStepperActions'
import { RootReduxStateType } from '../types'

type FormType = {
  userName: string
  password: string
}

const defaultFormState: FormType = {
  userName: '',
  password: '',
}

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [formState, setFormState] = useState<FormType>(defaultFormState)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [validateMFA, setValidateMFA] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [values, setValues] = useState(['', '', '', '', '', ''])
  const user = useSelector(
    (rootState: RootReduxStateType) => rootState.user.token
  )

  const handleLogin = async (e: any) => {
    e.preventDefault()

    setLoading(true)
    // @ts-ignore
    dispatch(loginAction(formState)).then((data) => {
      setLoading(false)

      // if (data.is2FAEnabled) {
      //   //alert('Show modal')
      //   setValidateMFA(true)
      //   setOpenModal(true)
      // } else {
      //   //window.location = '/'
      //   navigate('/')
      //   setLoading(false)
      // }
      navigate('/')
      setLoading(false)
    })
  }

  // const handleValidate = async (e: any) => {
  //   const code = values.join('')

  //   values.join('').length === 6 &&
  //     (await validateMultiFactorAuth(code, navigate)
  //       .then((res: any) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           const {
  //             UserName: userName,
  //             UserAccountID: id,
  //             is2FAEnabled: is2FA,
  //             DateOfBirth,
  //           } = getUserFromToken(res.data.accessToken)

  //           setLoading(false)
  //           dispatch({
  //             type: LOG_IN,
  //             payload: {
  //               id,
  //               userName,
  //               userAge: getUserAge(dayjs(DateOfBirth).format('YYYY-MM-DD')),
  //               emailAddress: '',
  //               token: res.data.accessToken,
  //               is2FAEnabled: is2FA,
  //             },
  //           })
  //           navigate('/')
  //         } else {
  //           setLoading(false)
  //           setErrorMessage('Your code is not valid, please try again')
  //         }
  //       })
  //       .catch((err) => console.log('Failed to validate')))

  //   //const response: any = await validateMultiFactorAuth(code, navigate)
  //   //console.log('Response from API', response)
  // }

  // Commented out temporarily to disable 2FA
  // useEffect(() => {
  //   values.join('').length === 6 && handleValidate(values.join(''))
  // }, [values])

  useEffect(() => {
    if (user) navigate('/')
    else dispatch(resetUserRegistration())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e: any) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleClear = () => {
    setErrorMessage('')
    setValues(['', '', '', '', '', ''])
  }

  const changePasswordIcon = () => {
    setShowPassword(!showPassword)
  }

  {
    /** 
  const handleSuccessGoogleLogin = async (res: any) => {
    console.log(res)
    const { Af, gw, gY } = res.getBasicProfile()
    await dispatch(
      socialLoginAction(
        {
          UserName: Af,
          Identifier: gY,
          IdToken: res.getAuthResponse().id_token,
          Email: gw,
        },
        'google'
      )
    )
  }
  const responseSuccessFacebook: any = async (res: any) => {
    console.log(res)
    window.fbAsyncInit(process.env.FACEBOOK_APP_ID)
    await window.fblogin(async (res: any) => {
      console.log(res)
      await dispatch(
        socialLoginAction(
          {
            UserName: res.response.name,
            Identifier: res.response.id, //res.authResponse.userID
            IdToken: res.res.authResponse.accessToken,
            Email: res.response.email,
          },
          'facebook'
        )
      )
    })
  }
  */
  }

  return (
    <DefaultLayout>
      {validateMFA && (
        <div className="absolute top-0 left-0">
          <Modal onLogin openModal={openModal} setOpenModal={setOpenModal}>
            <div className="w-[80%] flex flex-col items-center">
              <p className="text-lg mb-4">{t('2FA.ModalTitle')}</p>
              <p className="text-sm text-red-500 mb-2">{errorMessage}</p>

              <PinInput
                values={values}
                size="md"
                placeholder=""
                onChange={(value, index, values) => setValues(values)}
                autoComplete="off"
                autoFocus
                focusBorderColor="#0E6D6C"
                validBorderColor={errorMessage ? 'rgb(220,53,69)' : '#0E6D6C'}
                //onComplete={handleValidate}
                //onComplete={() => console.log('OnComplete is running')}
              />

              <div className="md:flex justify-between my-6 md:w-[80%] w-full">
                <button
                  className="btn btn--gray py-4 w-full cursor-pointer"
                  //onClick={handleValidate}
                  onClick={handleClear}
                >
                  {t('2FA.Clear')}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      <div>
        <p className={styles.title}>Log In</p>
        <LoginComponent
          formState={formState}
          handleChange={handleChange}
          handleLogin={handleLogin}
          showPassword={showPassword}
          changePasswordIcon={changePasswordIcon}
          navigate={navigate}
          loading={loading}
          translation={t}
        />

        <div className={styles.navigation.outerWrapper}>
          <div className={styles.navigation.innerWrapper}>
            <p>{t('User.DontHaveAccount')}</p>
            <button
              className={styles.button}
              onClick={(e) => navigate('/signup')}
            >
              {t('User.SignUp')}
            </button>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

const styles = {
  title: 'font-semibold text-[22px] text-primaryDark py-8',
  navigation: {
    outerWrapper:
      'w-full mt-[20px] border-t-2 border-primaryLight  border-dashed',
    innerWrapper: 'flex justify-between items-center mt-[20px]',
  },
  button:
    'cursor-pointer text-primaryDark font-semibold border-2 border-inputBackground p-[9px] rounded-[10px]',
}

export default Login
