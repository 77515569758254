import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { updateUserAccount } from '../../../redux/actions/generalActions'
import { getUser } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { UserAPIType } from '../../../types/user'
import { validatePhoneNumber } from '../../../utilities/generalUtilities'
import SafeAreaView from '../../Common/SafeAreaView'
import TelephoneNumber from '../../Common/TelephoneNumber'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepFour: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadedUser, setLoadedUser] = useState<UserAPIType | null>(null)

  useEffect(() => {
    if (alpaca.user.phoneNumber && validatePhoneNumber(alpaca.user.phoneNumber))
      setDisabled(false)
    else setDisabled(true)
  }, [alpaca.user.phoneNumber])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatPhoneNumberToSave = (phone: string) => {
    let arr = ['+1', phone.substring(1)]
    return arr.join(' ')
  }

  const handleNext = () => {
    setDisabled(true)
    if (!loadedUser) return

    if (alpaca.user.phoneNumber !== loadedUser?.telephoneNumber) {
      setDisabled(true)
      updateUserAccount(
        user.id,
        loadedUser?.firstName,
        loadedUser.lastName,
        loadedUser?.dateOfBirth,
        alpaca.user.phoneNumber ?? '',
        loadedUser?.emailAddress
      ).then((res) => {
        setDisabled(false)
        dispatch(increamentAlpacaStepperStep())
      })

      return
    }

    setDisabled(false)
    dispatch(increamentAlpacaStepperStep())
  }

  useEffect(() => {
    setLoading(true)
    getUser(user.id).then((res) => {
      setLoadedUser(res.data)
      dispatch(alpacaStepperUpdateUser('phoneNumber', res.data.telephoneNumber))
      setLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="font-semibold text-[17px]">Phone number</p>

        {loading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-6">
            <TelephoneNumber
              value={alpaca.user.phoneNumber ?? ''}
              name="phoneNumber"
              onChange={(phoneNumber) =>
                dispatch(
                  alpacaStepperUpdateUser(
                    'phoneNumber',
                    formatPhoneNumberToSave(phoneNumber)
                  )
                )
              }
            />
          </div>
        )}

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca disabled={disabled} handleNext={handleNext} />
    </div>
  )
}

export default AlpacaStepFour
