import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
import { setupMy529UpdateMultiple } from '../../../redux/actions/my529Actions'
import { LoadingSpinner } from '../../Common/Loading'
//@ts-ignore
import SearchIcon from '../../../assets/search.png'
//@ts-ignore
import GoogleSearchIcon from '../../../assets/powered-google.png'

const My529PlacesAutocomplete = ({
  handleSearchAddress,
}: {
  handleSearchAddress: () => void
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<string | null>(null)

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { componentRestrictions: { country: 'us' } },
  })

  const handleSelect = async (info: any) => {
    setLoading(info.place_id)

    const geoData = await getGeocode({ placeId: info.place_id })
    let postalCode: string | null = null

    geoData[0].address_components.map((geo) => {
      if (geo.types[0] === 'postal_code') {
        postalCode = geo.long_name
        return
      }
    })

    const formatedAddress = info.structured_formatting.secondary_text.split(',')
    dispatch(
      setupMy529UpdateMultiple({
        address1: info.structured_formatting.main_text,
        address2: null,
        cityOrTown: formatedAddress[0],
        zipCode: postalCode,
        state: formatedAddress[1].trim(),
      })
    )

    setValue(info.description, false)
    clearSuggestions()
    setLoading(null)
  }

  return (
    <div>
      <div className="relative bg-[#E3F2F2] outline-none w-full px-4 py-4 rounded-lg flex items-center">
        <img src={SearchIcon} alt="search-icon" />
        <input
          type="text"
          placeholder="Search for address"
          className="w-full outline-none bg-[#E3F2F2] ml-2"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
        />
      </div>
      {data.length > 0 && (
        <div
          style={{
            boxShadow:
              '0px 2px 6px rgba(0, 0, 0, 0.17), 0px 6px 18px rgba(0, 0, 0, 0.1)',
          }}
          className="py-1 px-4 absolute rounded-xl w-full bg-white"
        >
          {status !== 'ZERO_RESULTS' &&
            status === 'OK' &&
            data.map(
              (info, index) =>
                index < 3 && (
                  <div
                    key={info.place_id}
                    className="flex items-center justify-between"
                  >
                    <p
                      className="py-3 text-primaryDark text-[14px]"
                      onClick={() => handleSelect(info)}
                    >
                      {info.description}
                    </p>
                    {loading === info.place_id && (
                      <LoadingSpinner color="#004F4E" />
                    )}
                  </div>
                )
            )}
          <div className="flex items-center justify-between border-t-2 border-dashed border-[#C4E8E8]">
            <p
              className="py-5 text-primaryDark text-[14px]"
              onClick={handleSearchAddress}
            >
              Enter address manually
            </p>
            <img
              src={GoogleSearchIcon}
              alt="google-search-icon"
              className="w-[125px]"
            />
          </div>
        </div>
      )}
      {data.length === 0 && (
        <p
          className="py-5 text-primaryDark text-[14px]"
          onClick={handleSearchAddress}
        >
          Enter address manually
        </p>
      )}
    </div>
  )
}

export default My529PlacesAutocomplete
