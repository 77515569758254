import React from 'react'
import Navigation from '../Common/Navigation'

const CookiePolicy = () => {
  return (
    <Navigation>
      <div className="b-read legal w-richtext w-[85%] m-auto py-10 md:w-[800px]">
        <h1>Cookie Policy</h1>
        <h3>
          <strong>Cookie Policy of Sootchy.com</strong>
        </h3>
        <p>
          This document informs Users about the technologies that help
          Sootchy.com to achieve the purposes described below. Such technologies
          allow Sootchy, Inc. (the “Owner”) to access and store information (for
          example by using a Cookie) or use resources (for example by running a
          script) on a User’s device as they interact with Sootchy.com
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>Definitions</strong>‍
        </p>
        <ul>
          <li>
            <strong>Personal Data (or Data)</strong> <br />
            Any information that directly, indirectly, or in connection with
            other information — including a personal identification number —
            allows for the identification or identifiability of a natural
            person.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Usage Data</strong> <br />
            Information collected automatically through Sootchy.com (or
            third-party services employed in Sootchy.com), which can include:
            the IP addresses or domain names of the computers utilized by the
            Users who use Sootchy.com, the URI addresses (Uniform Resource
            Identifier), the time of the request, the method utilized to submit
            the request to the server, the size of the file received in
            response, the numerical code indicating the status of the server's
            answer (successful outcome, error, etc.), the country of origin, the
            features of the browser and the operating system utilized by the
            User, the various time details per visit (e.g., the time spent on
            each page within the Application) and the details about the path
            followed within the Application with special reference to the
            sequence of pages visited, and other parameters about the device
            operating system and/or the User's IT environment.
          </li>
        </ul>
        <ul>
          <li>
            <strong>User</strong> <br />
            The individual using Sootchy.com who, unless otherwise specified,
            coincides with the Data Subject.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Data Subject</strong> <br />
            The natural person to whom the Personal Data refers.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Data Processor (or Data Supervisor)</strong> <br />
            The natural or legal person, public authority, agency or other body
            which processes Personal Data on behalf of the Controller, as
            described in this privacy policy.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Data Controller (or Owner)</strong> <br />
            The natural or legal person, public authority, agency or other body
            which, alone or jointly with others, determines the purposes and
            means of the processing of Personal Data, including the security
            measures concerning the operation and use of Sootchy.com. The Data
            Controller, unless otherwise specified, is the Owner of Sootchy.com.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Sootchy.com (or this Application)</strong> <br />
            The means by which the Personal Data of the User is collected and
            processed.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Service</strong> <br />
            The service provided by Sootchy.com as described in the relative
            terms (if available) and on this site/application.
          </li>
        </ul>
        <ul>
          <li>
            <strong>European Union (or EU)</strong> <br />
            Unless otherwise specified, all references made within this document
            to the European Union include all current member states to the
            European Union and the European Economic Area.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Cookie</strong> <br />
            Cookies are Trackers consisting of small sets of data stored in the
            User's browser.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Tracker</strong> <br />
            Tracker indicates any technology - e.g Cookies, unique identifiers,
            web beacons, embedded scripts, e-tags and fingerprinting - that
            enables the tracking of Users, for example by accessing or storing
            information on the User’s device.
          </li>
        </ul>
        <p>
          <strong>
            Use of Cookies:
            <br />
          </strong>
          There are different cookies for different purposes. We mainly use
          cookies to improve your experience on our Website and for marketing
          purposes.&nbsp;
        </p>
        <p>
          Information collected by the cookies may include e.g. the following
          type of information (this depends on the cookie in question): device
          type, unique browser identifier, IP-address, unique cookie identifier,
          browser type, language, country, operating system, system settings,
          information about your interaction with our sites such as registering
          for services, indicated preferences and click behavior.
        </p>
        <p>
          <strong>
            Types of Cookies Used: <br />
          </strong>
          We do not control the functioning of cookies from third parties. The
          respective third parties are responsible for such cookies and for
          their own data processing.
        </p>
        <ol>
          <li>
            <strong>Essential Cookies</strong>
            <br />
            These cookies are essential in order to help you to move around on
            the website and use basic functions.&nbsp; These cookies are
            required to enable core site functionality.
          </li>
          <li>
            <strong>Performance Cookies</strong>
            <br />
            Performance cookies collect information how visitors use the website
            in anonymous and aggregated form.&nbsp; They are used to analyze and
            improve how the site works.
          </li>
          <li>
            <strong>Functionality Cookies</strong>
            <br />
            Functionality cookies allow the site to remember choices made by you
            (e.g. user name, language or the territorial region) and thus they
            provide more personal features.&nbsp; They can be used to provide
            services you have asked, e.g. watching a video.
          </li>
          <li>
            <strong>Marketing Cookies</strong>
            <br />
            These, typically third-party cookies, are used to deliver ads more
            relevant to you.&nbsp; They will also limit the number of times you
            see a particular ad as well as measure the effectiveness of the ad
            campaigns.&nbsp; They remember your device’s visit in the site and
            help to understand click behaviors and thus to improve our site and
            provide ads and offers of interest.&nbsp; Further, they enable you
            to like or recommend us on social media (such as Facebook, Twitter,
            Instagram, Pinterest) and help you to rate and review our services.
          </li>
        </ol>
        <p>
          For simplicity, all such technologies are defined as "Trackers" within
          this document – unless there is a reason to differentiate.
          <br />
          For example, while Cookies can be used on both web and mobile
          browsers, it would be inaccurate to talk about Cookies in the context
          of mobile apps as they are a browser-based Tracker. For this reason,
          within this document, the term Cookies is only used where it is
          specifically meant to indicate that particular type of Tracker.&nbsp;
        </p>
        <p>
          Some of the purposes for which Trackers are used may also require the
          User's consent. Whenever consent is given, it can be freely withdrawn
          at any time following the instructions provided in this document.
        </p>
        <p>
          Sootchy.com uses Trackers managed directly by the Owner (so-called
          “first-party” Trackers) and Trackers that enable services provided by
          a third-party (so-called “third-party” Trackers). Unless otherwise
          specified within this document, third-party providers may access the
          Trackers managed by them.
        </p>
        <p>
          The validity and expiration periods of Cookies and other similar
          Trackers may vary depending on the lifetime set by the Owner or the
          relevant provider. Some of them expire upon termination of the User’s
          browsing session. In addition to what’s specified in the descriptions
          within each of the categories below, Users may find more precise and
          updated information regarding lifetime specification as well as any
          other relevant information – such as the presence of other Trackers -
          in the linked privacy policies of the respective third-party providers
          or by contacting the Owner.
        </p>
        <p>
          Sootchy may use so called web beacons (or “pixel tags”) in connection
          with some websites. However, we do not use them to identify individual
          users personally.&nbsp; Web beacons are typically graphic images that
          are placed on a website, and they are used to count visitors to a
          website and/or to access certain cookies.&nbsp; This information is
          used to improve our services.&nbsp; Web beacons do not typically
          collect any other information than what your browser provides us with
          as a standard part of any internet communication.&nbsp; If you disable
          cookies, the web beacon will no longer be able to track your specific
          activity.&nbsp; The web beacon may, however, continue to collect
          information of visits from your IP-address, but such information will
          no longer be personally identifiable.
        </p>
        <p>
          You hereby acknowledge and agree to the storage of cookies, other
          local storage technologies, beacons and other information on your
          devices and to the access of such cookies, local storage technologies,
          beacons and information by us.
        </p>
        <p>
          <strong>
            How to Disable Cookies:
            <br />
          </strong>
          You can change your browser settings to delete or prevent certain
          cookies from being stored on your computer or mobile device. The
          “Help” section in your browser should provide information on how to
          manage the cookie settings in the browser.&nbsp; However, it should be
          noted that the blocking of cookies might affect the site
          functionality.
        </p>
        <p>
          If you want to accept only cookies by us but not the cookies from
          third parties, you can typically (depending on your browser) select
          from your browser settings "Block third-party cookies”.
        </p>
        <p>
          ‍
          <a href="https://www.aboutcookies.org/how-to-delete-cookies/">
            How to disable cookies in different browsers
            <br />
            <br />
          </a>
          If you wish to deny the site from placing cookies on your device (e.g.
          computer or mobile phone), you may block cookies in your browser
          settings:
        </p>
        <p>
          ‍
          <a href="https://support.microsoft.com/help/17442/windows-internet-explorer-delete-manage-cookies">
            Internet Explorer
            <br />
          </a>
          <a href="https://www.google.com/support/chrome/bin/answer.py?hl=en&amp;answer=95647">
            Google Chrome
            <br />
          </a>
          <a href="https://support.mozilla.com/en-US/kb/Cookies">
            Mozilla Firefox
            <br />
          </a>
          <a href="https://support.apple.com/kb/PH5042">
            Safari
            <br />
          </a>
          <a href="https://www.opera.com/help/tutorials/security/privacy/">
            Opera
            <br />
          </a>
          <a href="https://www.adobe.com/privacy/policies/flash-player.html">
            Adobe
          </a>
          (Flash cookies)
          <br />
          For other browsers, please check the support website for your browser.
        </p>
        <p>
          Instructions by Google
          <a href="https://support.google.com/ads/answer/2662922?hl=en">
            how to opt-out from targeted marketing
            <br />
            <br />
          </a>
          Instructions by Facebook for
          <a href="https://m.facebook.com/ads/settings">
            ads settings
            <br />
            <br />
          </a>
          Find out more about cookies, targeted marketing and how to opt-out:
        </p>
        <p>
          ‍
          <a href="https://www.allaboutcookies.org/">
            All About Cookies
            <br />
          </a>
          <a href="https://www.aboutads.info/choices">AdChoices</a> or
          <a href="https://www.youronlinechoices.eu/">
            Your Online Choices
            <br />
          </a>
          <a href="https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options">
            What Are My Options?
          </a>
        </p>
        <p>
          If you wish to disable Trackers and/or Cookies, or want to be notified
          before they are placed, you may do this in your browser
          settings.&nbsp; However, we may not be able to provide certain
          services, or you may not be able to view certain parts of our Website
          if you have disabled cookies.
        </p>
        <p>
          <strong>
            Activities strictly necessary for the operation of Sootchy.com and
            delivery of the Service
            <br />
          </strong>
          Sootchy.com uses so-called ’technical’ Cookies and other similar
          Trackers to carry out activities that are strictly necessary for the
          operation or delivery of the Service.
        </p>
        <p>
          <strong>Third-party Trackers</strong>
        </p>
        <ul>
          <li>
            <strong>
              SPAM protection <br />‍
            </strong>
            This type of service analyzes the traffic of Sootchy.com,
            potentially containing Users' Personal Data, with the purpose of
            filtering it from parts of traffic, messages and content that are
            recognized as SPAM.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Google reCAPTCHA (Google LLC)</strong> <br />
            Google reCAPTCHA is a SPAM protection service provided by Google
            LLC.
            <br />
            The use of reCAPTCHA is subject to the Google
            <a href="https://www.google.com/policies/privacy/">
              privacy policy
            </a>{' '}
            and
            <a href="https://www.google.com/intl/en/policies/terms/">
              terms of use
            </a>
            .<br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            .&nbsp;
            <br />
            <strong>Storage duration:&nbsp;</strong> <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            _GRECAPTCHA: duration of the session
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* rc::a: indefinite
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            rc::b: duration of the session
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            rc::c: duration of the session
          </li>
        </ul>
        <ul>
          <li>
            <strong>Infrastructure monitoring&nbsp;</strong> <br />
            This type of service allows Sootchy.com to monitor the use and
            behavior of its components so its performance, operation,
            maintenance and troubleshooting can be improved.
            <br />
            Which Personal Data are processed depends on the characteristics and
            mode of implementation of these services, whose function is to
            filter the activities of Sootchy.com.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Instabug (Instabug, Inc.)</strong> <br />
            Instabug is a monitoring service provided by Instabug, Inc. <br />
            Personal Data processed: address, app information, Cookies, country,
            date of birth, device information, device logs, email address, first
            name and geographic position.
            <br />
            Place of processing: United States –
            <a href="https://instabug.com/privacy">Privacy Policy</a>.&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>New Relic (New Relic)</strong> <br />
            New Relic is a monitoring service provided by New Relic Inc.
            <br />
            The way New Relic is integrated means that it filters all traffic of
            Sootchy.com, i.e., communication between the Application and the
            User's browser or device, while also allowing analytical data on
            Sootchy.com to be collected. <br />
            Personal Data processed: Cookies, Usage Data and various types of
            Data as specified in the privacy policy of the service.
            <strong>
              <br />
              Place of processing: United States –{' '}
            </strong>
            <a href="https://newrelic.com/termsandconditions/privacy">
              <strong>Privacy Policy</strong>
            </a>
            <strong>.</strong>
          </li>
        </ul>
        <p>
          <strong>
            Other activities involving the use of Trackers
            <br />
            Experience enhancement.{' '}
          </strong>
          Sootchy.com uses Trackers to provide a personalized user experience by
          improving the quality of preference management options, and by
          enabling interaction with external networks and platforms.
        </p>
        <ul>
          <li>
            <strong>Content commenting&nbsp;</strong> <br />
            Content commenting services allow Users to make and publish their
            comments on the contents of Sootchy.com.
            <br />
            Depending on the settings chosen by the Owner, Users may also leave
            anonymous comments. If there is an email address among the Personal
            Data provided by the User, it may be used to send notifications of
            comments on the same content. Users are responsible for the content
            of their own comments.
            <br />
            If a content commenting service provided by third parties is
            installed, it may still collect web traffic data for the pages where
            the comment service is installed, even when Users do not use the
            content commenting service.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Facebook Comments (Facebook, Inc.)</strong> <br />
            Facebook Comments is a content commenting service provided by
            Facebook, Inc. enabling the User to leave comments and share them on
            the Facebook platform. <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://www.facebook.com/privacy/explanation">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>Disqus (Disqus)</strong> <br />
            Disqus is a content commenting service provided by Big Heads Labs
            Inc. <br />
            Personal Data processed: Cookies, Usage Data and various types of
            Data as specified in the privacy policy of the service. <br />
            Place of processing: United States –
            <a href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy">
              Privacy Policy
            </a>
            – <a href="https://disqus.com/data-sharing-settings/">Opt out</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>Comment system managed directly (Sootchy.com)</strong>
            <br />
            Sootchy.com has its own internal content comment system.
            <br />
            Personal Data processed: Cookies, email address, first name, last
            name, phone number, Usage Data, username and website.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Displaying content from external platforms&nbsp;</strong>
            <br />
            This type of service allows you to view content hosted on external
            platforms directly from the pages of Sootchy.com and interact with
            them.
            <br />
            This type of service might still collect web traffic data for the
            pages where the service is installed, even when Users do not use it.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Google Maps widget (Google LLC)</strong> <br />
            Google Maps is a maps visualization service provided by Google LLC
            that allows Sootchy.com to incorporate content of this kind on its
            pages. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>Instagram widget (Instagram, Inc.)</strong> <br />
            Instagram is an image visualization service provided by Instagram,
            Inc. that allows Sootchy.com to incorporate content of this kind on
            its pages.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://help.instagram.com/519522125107875">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>OpenStreetMap widget (OpenStreetMap Foundation )</strong>
            <br />
            OpenStreetMap widget is a maps visualization service provided by
            OpenStreetMap Foundation that allows Sootchy.com to incorporate
            content of this kind on its pages. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United Kingdom –
            <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>YouTube video widget (Google LLC)</strong> <br />
            YouTube is a video content visualization service provided by Google
            LLC that allows Sootchy.com to incorporate content of this kind on
            its pages.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* PREF: 8 months
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            VISITOR_INFO1_LIVE: 8 months
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* YSC:
            duration of the session<strong>‍</strong>
          </li>
          <li>
            <strong>
              Interaction with external social networks and platforms&nbsp;
            </strong>
            <br />
            This type of service allows interaction with social networks or
            other external platforms directly from the pages of Sootchy.com.
            <br />
            The interaction and information obtained through Sootchy.com are
            always subject to the User’s privacy settings for each social
            network.
            <br />
            This type of service might still collect traffic data for the pages
            where the service is installed, even when Users do not use it.
            <br />
            It is recommended to log out from the respective services in order
            to make sure that the processed data on Sootchy.com isn’t being
            connected back to the User’s profile.
          </li>
        </ul>
        <ul>
          <li>
            <strong>AddThis (Oracle Corporation)</strong> <br />
            AddThis is a service provided by Oracle Corporation, which displays
            a widget that allows interaction with social networks and external
            platforms as well as sharing the contents of Sootchy.com.
            <br />
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.oracle.com/legal/privacy/addthis-privacy-policy.html">
              Privacy Policy
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* UID: 2
            years
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            __atuvc: 2 years
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            __atuvs: 30 minutes
          </li>
        </ul>
        <ul>
          <li>
            <strong>AddToAny (AddToAny LLC)</strong> <br />
            AddToAny is a service provided by AddToAny LLC, which displays a
            widget that allows interaction with social networks and external
            platforms as well as sharing the contents of Sootchy.com.
            <br />
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.addtoany.com/privacy">Privacy Policy</a>.&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Facebook Like button and social widgets (Facebook, Inc.)
            </strong>
            <br />
            The Facebook Like button and social widgets are services allowing
            interaction with the Facebook social network provided by Facebook,
            Inc.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.facebook.com/privacy/explanation">
              Privacy Policy
            </a>
            .&nbsp; <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* _fbp:
            3 months
          </li>
          <li>
            <strong>
              LinkedIn button and social widgets (LinkedIn Corporation)
            </strong>
            <br />
            The LinkedIn button and social widgets are services allowing
            interaction with the LinkedIn social network provided by LinkedIn
            Corporation. <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://www.linkedin.com/legal/privacy-policy">
              Privacy Policy
            </a>
            .&nbsp; <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            AnalyticsSyncHistory: 1 month <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* JSESSIONID:
            duration of the session
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            UserMatchHistory: 1 month
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            bcookie: 2 years
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            bscookie: 2 years
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* lang:
            duration of the session
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* lidc:
            1 day
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* lissc:
            1 year
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            lms_ads: 1 month
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;lms_analytics:
            1 month
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Pinterest “Pin it” button and social widgets (Pinterest)
            </strong>
            <br />
            The Pinterest “Pin it” button and social widgets are services
            allowing interaction with the Pinterest platform provided by
            Pinterest Inc. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://policy.pinterest.com/en/privacy-policy">
              Privacy Policy
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;_pin_unauth:
            1 year <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            _pinterest_ct_ua: duration of the session
          </li>
        </ul>
        <ul>
          <li>
            <strong>Reddit button and widgets (reddit inc.)</strong> <br />
            The reddit button and widgets are services allowing interaction with
            the reddit platform provided by reddit inc. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.reddit.com/help/privacypolicy">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>ShareThis (Sharethis Inc.)</strong> <br />
            ShareThis is a service provided by ShareThis Inc., which displays a
            widget that allows interaction with social networks and external
            platforms as well as sharing the contents of Sootchy.com.
            <br />
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.sharethis.com/privacy/">Privacy Policy</a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            __stid: 2 years
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              StumbleUpon button and social widgets (StumbleUpon, Inc.)
            </strong>
            <br />
            The StumbleUpon button and social widgets are services allowing
            interaction with the StumbleUpon social network provided by
            StumbleUpon, Inc. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.stumbleupon.com/privacy">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Tumblr sharing button and social widgets (Tumblr Inc.)
            </strong>
            <br />
            The Tumblr sharing button and social widgets are services allowing
            interaction with the Tumblr social network provided by Tumblr Inc.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.tumblr.com/privacy/en_eu">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Twitter Tweet button and social widgets (Twitter, Inc.)
            </strong>
            <br />
            The Twitter Tweet button and social widgets are services allowing
            interaction with the Twitter social network provided by Twitter,
            Inc.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://twitter.com/privacy">Privacy Policy</a>.&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*
            personalization_id: 2 years
          </li>
        </ul>
        <p>
          <strong>
            RSS feed management
            <br />
          </strong>
          This type of service allows Sootchy.com to manage RSS feeds and the
          distribution of their content. Depending on the characteristics of the
          service used, these services may be used to insert ads within the
          content and to gather statistics data from them.
        </p>
        <ul>
          <li>
            <strong>
              Feedburner (Google LLC)
              <br />
            </strong>
            Feedburner is a service to manage RSS feeds provided by Google LLC.
            It allows the collection of statistics on what content is consumed
            and to insert ads in it.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="http://www.google.com/intl/en/policies/privacy/">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Font Awesome (Fonticons, Inc. )<br />
            </strong>
            Font Awesome is a typeface visualization service provided by
            Fonticons, Inc. that allows Sootchy.com to incorporate content of
            this kind on its pages.
            <br />
            Personal Data processed: Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://fontawesome.com/privacy">Privacy Policy</a>.
          </li>
          <li>
            <strong>Google Fonts (Google LLC)</strong> <br />
            Google Fonts is a typeface visualization service provided by Google
            LLC that allows Sootchy.com to incorporate content of this kind on
            its pages.
            <br />
            Personal Data processed: Usage Data; various types of Data as
            specified in the privacy policy of the service.
            <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>Google Maps widget (Google LLC)</strong> &nbsp;
            <br />
            Google Maps is a maps visualization service provided by Google LLC
            that allows Sootchy.com to incorporate content of this kind on its
            pages.
            <br />
            Personal Data processed: Cookies; Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            .&nbsp;
          </li>
          <li>
            <strong>Instagram widget (Instagram, Inc.)</strong> <br />
            Instagram is an image visualization service provided by Instagram,
            Inc. that allows Sootchy.com to incorporate content of this kind on
            its pages.
            <br />
            Personal Data processed: Cookies; Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://help.instagram.com/519522125107875">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>OpenStreetMap widget (OpenStreetMap Foundation )</strong>
            <br />
            OpenStreetMap widget is a maps visualization service provided by
            OpenStreetMap Foundation that allows Sootchy.com to incorporate
            content of this kind on its pages.
            <br />
            Personal Data processed: Cookies; Usage Data.
            <br />
            Place of processing: United Kingdom –
            <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>YouTube video widget (Google LLC)</strong> <br />
            YouTube is a video content visualization service provided by Google
            LLC that allows Sootchy.com to incorporate content of this kind on
            its pages.
            <br />
            Personal Data processed: Cookies; Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <p>
          <strong>
            Heat mapping and session recording
            <br />
          </strong>
          Heat mapping services are used to display the areas of a page where
          Users most frequently move the mouse or click. This shows where the
          points of interest are. These services make it possible to monitor and
          analyze web traffic and keep track of User behavior.Some of these
          services may record sessions and make them available for later visual
          playback
        </p>
        <ul>
          <li>
            <strong>Hotjar Heat Maps &amp; Recordings (Hotjar Ltd.)</strong>
            <br />
            Hotjar is a session recording and heat mapping service provided by
            Hotjar Ltd.
            <br />
            Hotjar honors generic „Do Not Track” headers. This means the browser
            can tell its script not to collect any of the User's data. This is a
            setting that is available in all major browsers. Find Hotjar’s
            <a href="https://www.hotjar.com/legal/compliance/opt-out">
              opt-out information here
            </a>
            .<br />
            Personal Data processed: Cookies; Usage Data; various types of Data
            as specified in the privacy policy of the service.
            <br />
            Place of processing: Malta –
            <a href="https://www.hotjar.com/legal/policies/privacy">
              Privacy Policy
            </a>
            –
            <a href="https://www.hotjar.com/legal/compliance/opt-out">
              Opt Out
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration:&nbsp;</strong>
            <br />* _hjAbsoluteSessionInProgress: 30 minutes <br />*
            _hjCachedUserAttributes: duration of the session <br />*
            _hjClosedSurveyInvites: 1 year <br />* _hjDonePolls: 1 year <br />*
            _hjFirstSeen: duration of the session <br />*
            _hjIncludedInPageviewSample: 30 minutes
            <br />* _hjIncludedInSessionSample: 30 minutes
            <br />* _hjLocalStorageTest: duration of the session
            <br />* _hjLocalStorageTest: duration of the session
            <br />* _hjMinimizedPolls: 1 year
            <br />* _hjRecordingEnabled: duration of the session
            <br />* _hjRecordingLastActivity: duration of the session
            <br />* _hjSession*: 30 minutes
            <br />
            *_hjSessionRejected: duration of the session
            <br />* _hjSessionResumed: duration of the session
            <br />* _hjSessionTooLarge: duration of the session
            <br />* _hjSessionUser*: 1 year
            <br />* _hjShownFeedbackMessage: 1 year
            <br />* _hjTLDTest: duration of the session
            <br />* _hjUserAttributesHash: duration of the session
            <br />* _hjViewportId: duration of the session
            <br />* _hjid: 1 year
          </li>
        </ul>
        <ul>
          <li>
            <strong>Targeting &amp; Advertising</strong> <br />
            Sootchy.com uses Trackers to deliver personalized marketing content
            based on User behavior and to operate, serve and track ads.
          </li>
        </ul>
        <p>
          <strong>
            Interaction with external social networks and platforms <br />
          </strong>
          This type of service allows interaction with social networks or other
          external platforms directly from the pages of Sootchy.com.The
          interaction and information obtained through Sootchy.com are always
          subject to the User’s privacy settings for each social network.This
          type of service might still collect traffic data for the pages where
          the service is installed, even when Users do not use it.It is
          recommended to log out from the respective services in order to make
          sure that the processed data on Sootchy.com isn’t being connected back
          to the User’s profile
        </p>
        <ul>
          <li>
            <strong>AddThis (Oracle Corporation)</strong> <br />
            AddThis is a service provided by Oracle Corporation, which displays
            a widget that allows interaction with social networks and external
            platforms as well as sharing the contents of Sootchy.com.
            <br />
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed.
            <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://www.oracle.com/legal/privacy/addthis-privacy-policy.html">
              Privacy Policy
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />* UID: 2 years
            <br />* __atuvc: 2 years
            <br />* __atuvs: 30 minutes
          </li>
        </ul>
        <ul>
          <li>
            <strong>AddToAny (AddToAny LLC)</strong> <br />
            AddToAny is a service provided by AddToAny LLC, which displays a
            widget that allows interaction with social networks and external
            platforms as well as sharing the contents of Sootchy.com.
            <br />
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.addtoany.com/privacy">Privacy Policy</a>.&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Facebook Like button and social widgets (Facebook, Inc.)
            </strong>
            <br />
            The Facebook Like button and social widgets are services allowing
            interaction with the Facebook social network provided by Facebook,
            Inc.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.facebook.com/privacy/explanation">
              Privacy Policy
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />* _fbp: 3 months
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              LinkedIn button and social widgets (LinkedIn Corporation)
            </strong>
            <br />
            The LinkedIn button and social widgets are services allowing
            interaction with the LinkedIn social network provided by LinkedIn
            Corporation. <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://www.linkedin.com/legal/privacy-policy">
              Privacy Policy
            </a>
            .&nbsp; <br />
            <strong>Storage duration:&nbsp;</strong> <br />*
            AnalyticsSyncHistory: 1 month <br />* JSESSIONID: duration of the
            session
            <br />* UserMatchHistory: 1 month <br />* bcookie: 2 years
            <br />* bscookie: 2 years <br />* lang: duration of the session{' '}
            <br />* lidc: 1 day <br />* lissc: 1 year <br />* lms_ads: 1
            month&nbsp;
            <br />* lms_analytics: 1 month
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Pinterest “Pin it” button and social widgets (Pinterest)
            </strong>
            <br />
            The Pinterest “Pin it” button and social widgets are services
            allowing interaction with the Pinterest platform provided by
            Pinterest Inc.
            <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://policy.pinterest.com/en/privacy-policy">
              Privacy Policy
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp; <br />* _pin_unauth: 1 year
            <br />* _pinterest_ct_ua: duration of the session
          </li>
        </ul>
        <ul>
          <li>
            <strong>Reddit button and widgets (reddit inc.)</strong> <br />
            The reddit button and widgets are services allowing interaction with
            the reddit platform provided by reddit inc. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.reddit.com/help/privacypolicy">
              Privacy Policy
            </a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>ShareThis (Sharethis Inc.)</strong> <br />
            ShareThis is a service provided by ShareThis Inc., which displays a
            widget that allows interaction with social networks and external
            platforms as well as sharing the contents of Sootchy.com.
            <br />
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed.
            <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://www.sharethis.com/privacy/">Privacy Policy</a>
            .&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />* __stid: 2 years
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              StumbleUpon button and social widgets (StumbleUpon, Inc.)
            </strong>
            <br />
            The StumbleUpon button and social widgets are services allowing
            interaction with the StumbleUpon social network provided by
            StumbleUpon, Inc. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.stumbleupon.com/privacy">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Tumblr sharing button and social widgets (Tumblr Inc.)
            </strong>
            <br />
            The Tumblr sharing button and social widgets are services allowing
            interaction with the Tumblr social network provided by Tumblr Inc.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.tumblr.com/privacy/en_eu">Privacy Policy</a>
            .&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              Twitter Tweet button and social widgets (Twitter, Inc.)
            </strong>
            <br />
            The Twitter Tweet button and social widgets are services allowing
            interaction with the Twitter social network provided by Twitter,
            Inc.
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://twitter.com/privacy">Privacy Policy</a>.&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp; <br />* personalization_id:
            2 years
          </li>
        </ul>
        <p>
          <strong>
            Remarketing and behavioral targeting
            <br />
          </strong>
          This type of service allows Sootchy.com and its partners to inform,
          optimize and serve advertising based on past use of Sootchy.com by the
          User.
          <br />
          This activity is facilitated by tracking Usage Data and by using
          Trackers to collect information which is then transferred to the
          partners that manage the remarketing and behavioral targeting
          activity.
          <br />
          Some services offer a remarketing option based on email address lists.
          <br />
          In addition to any opt-out feature provided by any of the services
          below, Users may opt out by visiting the
          <a href="http://optout.networkadvertising.org/?c=1">
            Network Advertising Initiative opt-out page
          </a>
          .
        </p>
        <p>
          <strong>
            Users may also opt-out of certain advertising features through
            applicable device settings, such as the device advertising settings
            for mobile phones or ads settings in general
          </strong>
          .
        </p>
        <ul>
          <li>
            <strong>Facebook Custom Audience (Facebook, Inc.)</strong> <br />
            Facebook Custom Audience is a remarketing and behavioral targeting
            service provided by Facebook, Inc. that connects the activity of
            Sootchy.com with the Facebook advertising network. <br />
            Users can opt out of Facebook's use of cookies for ads
            personalization by visiting this
            <a href="https://www.aboutads.info/choices/">opt-out page</a>.<br />
            Personal Data processed: Cookies and email address. <br />
            Place of processing: United States –
            <a href="https://www.facebook.com/about/privacy/">
              Privacy Policy
            </a>{' '}
            –<a href="https://www.aboutads.info/choices/">Opt Out</a>.&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />* _fbp: 3 months
          </li>
        </ul>
        <ul>
          <li>
            <strong>Facebook Remarketing (Facebook, Inc.)</strong> <br />
            Facebook Remarketing is a remarketing and behavioral targeting
            service provided by Facebook, Inc. that connects the activity of
            Sootchy.com with the Facebook advertising network. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://www.facebook.com/about/privacy/">
              Privacy Policy
            </a>{' '}
            –<a href="https://www.aboutads.info/choices/">Opt Out</a>.&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />* _fbp: 3 months
          </li>
        </ul>
        <ul>
          <li>
            <strong>Google Ad Manager Audience Extension (Google LLC)</strong>
            <br />
            Google Ad Manager Audience Extension is a remarketing and behavioral
            targeting service provided by Google LLC that tracks the visitors of
            Sootchy.com and allows selected advertising partners to display
            targeted ads across the web to them. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a> –
            <a href="https://adssettings.google.com/authenticated#display_optout">
              Opt Out
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />* AID: 2 years
            <br />* ANID: 2 years
            <br />* Conversion: 3 months
            <br />* DSID: 14 days
            <br />* FCNEC: 1 year
            <br />* FLC: 10 seconds
            <br />* FPAU: 3 months
            <br />* FPGCLAW: 3 months
            <br />* FPGCLDC: 3 months
            <br />* FPGCLGB: 3 months
            <br />
            *&nbsp;IDE: 2 years
            <br />
            *&nbsp;NID: 6 months
            <br />
            *&nbsp;RUL: 1 year
            <br />* TAID: 14 days
            <br />* __gads: 2 years
            <br />* __gsas: 2 years
            <br />* _gac_: 3 months
            <br />* _gac_gb_: 3 months
            <br />* _gcl_au: 3 months
            <br />* _gcl_aw: 3 months
            <br />* _gcl_dc: 3 months
            <br />* _gcl_gb: 3 months <br />* _gcl_gf: 3 months <br />* _gcl_ha:
            3 months
            <br />* id: 2 years <br />* test_cookie: 15 minutes
          </li>
        </ul>
        <ul>
          <li>
            <strong>Google Ads Remarketing (Google LLC)</strong> <br />
            Google Ads Remarketing is a remarketing and behavioral targeting
            service provided by Google LLC that connects the activity of
            Sootchy.com with the Google Ads advertising network and the
            DoubleClick Cookie. <br />
            Users can opt out of Google's use of cookies for ads personalization
            by visiting Google's
            <a href="https://adssettings.google.com/authenticated">
              Ads Settings
            </a>
            .
            <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a> –
            <a href="https://adssettings.google.com/authenticated">Opt Out</a>
            .&nbsp;
            <br />
            <strong>Storage duration:&nbsp;</strong> <br />* AID: 2 years
            <br />* ANID: 2 years
            <br />* Conversion: 3 months
            <br />* DSID: 14 days
            <br />* FCNEC: 1 year
            <br />* FLC: 10 seconds
            <br />* FPAU: 3 months
            <br />* FPGCLAW: 3 months
            <br />* FPGCLDC: 3 months
            <br />* FPGCLGB: 3 months
            <br />* IDE: 2 years
            <br />* NID: 6 months
            <br />* RUL: 1 year
            <br />* TAID: 14 days
            <br />* __gads: 2 years
            <br />* __gsas: 2 years
            <br />* _gac_: 3 months
            <br />* _gac_gb_: 3 months
            <br />
            *_gcl_au: 3 months
            <br />* _gcl_aw: 3 months
            <br />* _gcl_dc: 3 months
            <br />* _gcl_gb: 3 months
            <br />* _gcl_gf: 3 months
            <br />* _gcl_ha: 3 months
            <br />* id: 2 years
            <br />* test_cookie: 15 minutes
          </li>
        </ul>
        <ul>
          <li>
            <strong>LinkedIn Website Retargeting (LinkedIn Corporation)</strong>
            <br />
            LinkedIn Website Retargeting is a remarketing and behavioral
            targeting service provided by LinkedIn Corporation that connects the
            activity of Sootchy.com with the LinkedIn advertising network.
            <br />
            Personal Data processed: Cookies and Usage Data.
            <br />
            Place of processing: United States –
            <a href="https://www.linkedin.com/legal/privacy-policy">
              Privacy Policy
            </a>
            –<a href="https://www.linkedin.com/legal/cookie_policy">Opt Out</a>
            .&nbsp;
            <br />
            <strong>Storage duration:</strong>&nbsp;
            <br />
            *&nbsp;AnalyticsSyncHistory: 1 month
            <br />* JSESSIONID: duration of the session
            <br />
            *&nbsp;UserMatchHistory: 1 month
            <br />* bcookie: 2 years
            <br />
            *&nbsp;bscookie: 2 years
            <br />* lang: duration of the session
            <br />* lidc: 1 day
            <br />* lissc: 1 year
            <br />* lms_ads: 1 month
            <br />* lms_analytics: 1 month
          </li>
        </ul>
        <ul>
          <li>
            <strong>Remarketing with Google Analytics (Google LLC)</strong>
            <br />
            Remarketing with Google Analytics is a remarketing and behavioral
            targeting service provided by Google LLC that connects the tracking
            activity performed by Google Analytics and its Cookies with the
            Google Ads advertising network and the Doubleclick Cookie. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://policies.google.com/privacy">Privacy Policy</a> –
            <a href="https://adssettings.google.com/authenticated">Opt Out</a>
            .&nbsp;
            <br />
            <strong>Storage duration</strong>:&nbsp;
            <br />* test_cookie: 15 minutes
            <br />* IDE: 2 years <br />* _gcl_*: 3 months
          </li>
        </ul>
        <ul>
          <li>
            <strong>Twitter Remarketing (Twitter, Inc.)</strong>&nbsp;
            <br />
            Twitter Remarketing is a remarketing and behavioral targeting
            service provided by Twitter, Inc. that connects the activity of
            Sootchy.com with the Twitter advertising network. <br />
            Personal Data processed: Cookies and Usage Data. <br />
            Place of processing: United States –
            <a href="https://twitter.com/en/privacy">Privacy Policy</a> –
            <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">
              Opt Out
            </a>
            .&nbsp;
            <br />
            <strong>Storage duration:&nbsp;</strong> <br />* personalization_id:
            2 years
          </li>
        </ul>
        <ul>
          <li>
            <strong>Twitter Tailored Audiences (Twitter, Inc.)</strong> <br />
            Twitter Tailored Audiences is a remarketing and behavioral targeting
            service provided by Twitter, Inc. that connects the activity of
            Sootchy.com with the Twitter advertising network. <br />
            Users can opt out from interest-based ads by going to:
            <a href="https://twitter.com/settings/personalization">
              Personalization and Data
            </a>
            . <br />
            Personal Data processed: Cookies and email address. <br />
            Place of processing: United States –
            <a href="https://twitter.com/en/privacy">Privacy Policy</a> –
            <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">
              Opt Out
            </a>
            .&nbsp; <br />
            <strong>Storage duration:&nbsp;</strong>
            <br />
            *&nbsp;personalization_id: 2 years
          </li>
        </ul>
        <p>
          <strong>
            How to manage preferences and provide or withdraw consent
          </strong>
          <br />
          There are various ways to manage Tracker related preferences and to
          provide and withdraw consent, where relevant:
        </p>
        <p>
          Users can manage preferences related to Trackers from directly within
          their own device settings, for example, by preventing the use or
          storage of Trackers.
        </p>
        <p>
          Additionally, whenever the use of Trackers is based on consent, Users
          can provide or withdraw such consent by setting their preferences
          within the cookie notice or by updating such preferences accordingly
          via the relevant consent-preferences widget, if available.
        </p>
        <p>
          It is also possible, via relevant browser or device features, to
          delete previously stored Trackers, including those used to remember
          the User’s initial consent.
        </p>
        <p>
          Other Trackers in the browser’s local memory may be cleared by
          deleting the browsing history.
        </p>
        <p>
          With regard to any third-party Trackers, Users can manage their
          preferences and withdraw their consent via the related opt-out link
          (where provided), by using the means indicated in the third party's
          privacy policy, or by contacting the third party
        </p>
        <p>
          <strong>
            Locating Tracker Settings
            <br />
          </strong>
          Users can, for example, find information about how to manage Cookies
          in the most commonly used browsers at the following addresses:
        </p>
        <ul>
          <li>
            <a href="https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/">
              Apple Safari
            </a>
          </li>
          <li>
            <a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">
              Microsoft Internet Explorer
            </a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-us/help/4027947">
              Microsoft Edge
            </a>
          </li>
          <li>
            <a href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing">
              Brave
            </a>
          </li>
          <li>
            <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
              Opera
            </a>
          </li>
        </ul>
        <p>
          Users may also manage certain categories of Trackers used on mobile
          apps by opting out through relevant device settings such as the device
          advertising settings for mobile devices, or tracking settings in
          general (Users may open the device settings and look for the relevant
          setting).
          <br />
        </p>
        <p>
          <strong>
            How to opt out of interest-based advertising
            <br />
          </strong>
          Notwithstanding the above, Users may follow the instructions provided
          by
          <a href="http://www.youronlinechoices.eu/">YourOnlineChoices</a> (EU),
          the
          <a href="https://thenai.org/about-online-advertising/">
            Network Advertising Initiative
          </a>
          (“NAI”) (US) and the
          <a href="https://www.aboutads.info/consumers/">
            Digital Advertising Alliance
          </a>
          (“DAA”) (US),
          <a href="https://youradchoices.ca/understanding-online-advertising/">
            DAAC
          </a>
          (Canada), <a href="http://www.ddai.info/optout">DDAI</a> (Japan) or
          other similar services. Such initiatives allow Users to select their
          tracking preferences for most of the advertising tools. The Owner thus
          recommends that Users make use of these resources in addition to the
          information provided in this document.&nbsp;
        </p>
        <p>
          The Digital Advertising Alliance offers an application called
          <a href="https://youradchoices.com/appchoices">AppChoices</a> that
          helps Users to control interest-based advertising on mobile
          apps.&nbsp;
        </p>
        <p>
          Even if you opt out, you still may receive advertising from us that is
          not customized for you or from companies that do not participate in
          the DAA or NAI.
        </p>
        <p>
          <strong>
            Consequences of denying consent
            <br />‍
          </strong>
          Users are free to decide whether or not to grant consent. However,
          please note that Trackers help Sootchy.com to provide a better
          experience and advanced functionalities to Users (in line with the
          purposes outlined in this document). Therefore, in the absence of the
          User's consent, the Owner may be unable to provide related features
        </p>
        <p>
          <strong>
            Owner and Data Controller
            <br />‍
          </strong>
          Sootchy, Inc.
          <strong>
            <br />
          </strong>
          1234 Wilshire Boulevard, Suite 104-A7
          <br />
          Los Angeles, CA 90017
        </p>
        <p>
          <strong>
            Owner contact email
            <br />‍
          </strong>
          help@sootchy.com
        </p>
        <p>
          Since the use of third-party Trackers through Sootchy.com cannot be
          fully controlled by the Owner, any specific references to third-party
          Trackers are to be considered indicative. In order to obtain complete
          information, Users are kindly requested to consult the privacy
          policies of the respective third-party services listed in this
          document.
        </p>
        <p>
          Given the objective complexity surrounding tracking technologies,
          Users are encouraged to contact the Owner should they wish to receive
          any further information on the use of such technologies by
          Sootchy.com.
        </p>
        <p>
          <strong>
            Legal information
            <br />‍
          </strong>
          This privacy statement has been prepared based on provisions of
          multiple legislations, including Art. 13/14 of Regulation (EU)
          2016/679 (General Data Protection Regulation).
        </p>
        <p>
          This privacy policy relates solely to Sootchy.com, if not stated
          otherwise within this document.
        </p>
        <p>Latest update: March 2023</p>
        <p>
          <a href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator">
            iubenda
          </a>
          hosts this content and only collects
          <a href="https://www.iubenda.com/privacy-policy/65675001">
            the Personal Data strictly necessary
          </a>
          for it to be provided.&nbsp;
        </p>
      </div>
    </Navigation>
  )
}

export default CookiePolicy
