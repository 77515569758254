import { ReduxActionType } from '../../types'
import { CoinsType } from '../../types/coins'
import { GET_COINS } from '../actions/action.types'

const initialState: CoinsType = {
  coins: 0,
}

export const coinsReducer = (
  state = initialState,
  action: ReduxActionType<CoinsType>
) => {
  switch (action.type) {
    case GET_COINS:
      return {
        ...state,
        coins: action.payload,
      }
    default:
      return state
  }
}
