// @ts-nocheck
import axios from 'axios'
import { store } from '../redux/store'
import { logoutUser } from '../utilities/generalUtilities'

export const getLinkTokenFromStore = () => {
  const rootState = store.getState()
  return rootState.link.linkToken
}
export interface Error {
  status: number
  message: string
}
const getRootState = () => {
  const rootState = store.getState()
  return {
    token: rootState.user.token ? rootState.user.token : null,
  }
}
store.subscribe(getRootState)

let authToken = getRootState().token

function getHeaders(): any {
  const headers = {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY,
    'Access-Control-Allow-Origin': '*',
  }

  if (authToken) {
    return {
      ...headers,
      Authorization: `Bearer ${getRootState().token}`,
    }
  }

  return { ...headers }
}

const getBaseURL = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PROD_URL

const api = axios.create({
  baseURL: getBaseURL(),
  headers: getHeaders(),
  withCredentials: false,
})

window.trying_refresh_token = false
const getLoginRes = () => window.localStorage.getItem('loginRes')

const setLoginRes = (data) => {
  return window.localStorage.setItem('loginRes', JSON.stringify(data))
}
const createLock = () => {
  let lockStatus = false

  const release = () => {
    lockStatus = false
  }

  const acuire = () => {
    if (lockStatus === true) return false
    lockStatus = true
    return true
  }

  return {
    lockStatus: lockStatus,
    acuire: acuire,
    release: release,
  }
}
const lock = createLock() // create a lock

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}
let numberOfUnauthenticatedRequest = 0
api.interceptors.response.use(
  (response) => {
    return response
  },
  async (err) => {
    let customError: Error = {
      status: err.response.status,
      statusText: err.response.statusText,
      message: err.response.data,
    }
    const originalRequest = err.config
    if (err.response) {
      // Access Token was expired

      let tokenExpiredHeader = err.response.headers['token-expired']
      if (err.response.status === 401 && tokenExpiredHeader) {
        numberOfUnauthenticatedRequest++
        try {
          if (numberOfUnauthenticatedRequest > 1) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject })
            })
              .then((token) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token
                return axios.request(originalRequest)
              })
              .catch((err) => {
                return Promise.reject(err)
              })
          }
          return new Promise(function (resolve, reject) {
            ;(() => {
              if (lock.acuire()) {
                api
                  .post(`/UserAccount/Refresh`, getLoginRes(), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then((res) => {
                    lock.release()
                    originalRequest.headers['Authorization'] =
                      'Bearer ' + res.data.accessToken
                    setLoginRes(res.data)
                    numberOfUnauthenticatedRequest = 0
                    processQueue(null, res.data.accessToken)
                    resolve(axios.request(originalRequest))
                  })
                  .catch((err) => {
                    logoutUser()
                  })
              }
            })()
          })
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }
          return Promise.reject(customError)
        }
      } else {
        return Promise.reject(customError)
      }
    } else {
      return Promise.reject(customError)
    }
  }
)
let failedQueue = []
api.interceptors.request.use(async (req) => {
  let tokenRes = JSON.parse(getLoginRes())
  if (tokenRes) req.headers.Authorization = `Bearer ${tokenRes.accessToken}`
  return req
})
export default api
export { getRootState, getLoginRes, setLoginRes }
