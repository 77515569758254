export const MIN_MY529_STEP: number = 1
//export const MAX_MY529_STEP: number = 10
export const MAX_MY529_STEP: number = 8

export type SetupMy529AccountProcessType = {
  step: number
  phoneNumber: string | null
  state: string | null
  cityOrTown: string | null
  zipCode: string | null
  address1: string | null
  address2: string | null
  firstName: string | null
  middleName: string | null
  lastName: string | null
  dateOfBirth: string | null
  email: string | null
  socialSecurity: string | null
  updateAccountDetails: boolean
  kycStatus: string | null
  //childrenInfo: SetupMy529AccountChildType[] | []
  childrenInfo: My529MinorAPIType[] | []
  video: null | string
  accountBeneficiary: 'MY_SELF' | 'MY_CHILDREN_NIECE' | null
  isMinorDelegated: boolean
  invalidFields: [] | string[]
  bankInformation: {
    bankAccountType: string | null
    firstName: string | null
    lastName: string | null
    accountNumber: string | null
    routingNumber: string | null
  }
}

export type SetupMy529AccountChildType = {
  id: string
  firstName: string
  lastName: string
  dateOfBirth: string
  relationship: string
  phoneNumber: string
  email: string
  ssn: string
  addressSameAsParent: boolean
}

type My529MinorApplicationStatusType =
  | ''
  | 'Unknown'
  | 'NotYetStarted'
  | 'ApplicationPendingFromCustomer'
  | 'ApplicationSubmittedFromCustomer'
  | 'DocuSignSentToCustomer'
  | 'DocuSignRecievedFromCustomer'
  | 'SentApplicationToUtah'
  | 'ApplicationInProcess'
  | 'ApplicationFreezed'
  | 'My529AccountCreated'
  | 'ApplicationRejectedByUtah'
  | 'ApplicationRejectSootchyTeam'

export type My529MinorAPIType = {
  applicationStatus: My529MinorApplicationStatusType
  city: null | string
  countryCode: null | string
  dateOfBirth: string
  emailAddress: string
  firstName: string
  identifier: string
  identifierType: null | string
  investmentOption: string
  isAddressSameAsParent: boolean
  isMy529DelegatedToMySelf: boolean
  isMy529AccountForMySelf: boolean
  lastName: string
  middleName: string
  minorAccountID: number
  parentUserAccountID: number
  relationship: null | string
  state: null | string
  streetAddress1: null | string
  streetAddress2: null | string
  telephoneNumber: '' | string
  userAccountID: 621 | string
  zipCode: null | string
}
