import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

type PropsType = {
  url: string
  number: number
  wallet?: boolean
  color?: string
}

const HeaderCoins: React.FC<PropsType> = ({ url, number, wallet, color }) => {
  const navigate = useNavigate()

  return (
    <NavLink
      style={{ color: `${wallet ? color : '#FFFFFF'}` }}
      to={'/my-coins'}
      className={`flex justify-center items-center rounded-[28px] font-extrabold space-x-1 text-[11px] px-2 w-[44px] h-[28px] ${
        wallet ? 'bg-white' : 'bg-[#EBB700] '
      }`}
    >
      <span className="flex items-center w-[16px] h-[16px]">{number}</span>

      {wallet ? (
        <div onClick={(e) => navigate(url)} className="cursor-pointer">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C3.5625 0 0 2.03125 0 4.5V8C0 10.2188 3.5625 12 8 12C12.4062 12 16 10.2188 16 8V4.5C16 2.03125 12.4062 0 8 0ZM2.75 9.375C1.9375 8.9375 1.5 8.4375 1.5 8V7.125C1.8125 7.375 2.375 7.71875 2.75 7.90625V9.375ZM5.75 10.3438C5.15625 10.25 4.28125 10.0312 3.75 9.8125V8.3125C4.28125 8.5 5.15625 8.75 5.75 8.84375V10.3438ZM9.25 10.4688C8.84375 10.5 8.40625 10.5312 8 10.5312C7.5625 10.5312 7.125 10.5 6.75 10.4688V8.96875C7.15625 9 7.5625 9 8 9C8.40625 9 8.8125 9 9.25 8.96875V10.4688ZM12.25 9.8125C11.6875 10.0312 10.8125 10.25 10.25 10.3438V8.84375C10.8125 8.75 11.6875 8.5 12.25 8.3125V9.8125ZM14.5 8C14.5 8.4375 14.0312 8.9375 13.25 9.375V7.90625C13.5938 7.71875 14.1562 7.375 14.5 7.125V8ZM8 7.5C4.28125 7.5 1.5 5.9375 1.5 4.5C1.5 3.09375 4.28125 1.5 8 1.5C11.7188 1.5 14.5 3.09375 14.5 4.5C14.5 5.9375 11.7188 7.5 8 7.5Z"
              fill={color}
            />
          </svg>
        </div>
      ) : (
        <img
          src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/coin.svg"
          alt="logo"
          onClick={(e) => navigate(url)}
          className={`w-[16px] h-[12px]`}
        />
      )}
    </NavLink>
  )
}

export default HeaderCoins
