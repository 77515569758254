import { ReduxActionType } from '../../types'
import {
  MAX_MY529_STEP,
  MIN_MY529_STEP,
  SetupMy529AccountProcessType,
} from '../../types/my529'
import {
  SETUP_MY529_ADD_BANK_INF0,
  SETUP_MY529_ADD_CHILDREN_INFO,
  SETUP_MY529_STEPPER_DECREAMENT,
  SETUP_MY529_STEPPER_INCREAMENT,
  SETUP_MY529_STEPPER_RESET,
  SETUP_MY529_STEPPER_UPDATE,
  SETUP_MY529_STEPPER_UPDATE_MULTIPLE,
} from '../actions/action.types'

const initialState: SetupMy529AccountProcessType = {
  step: MIN_MY529_STEP,
  phoneNumber: null,
  state: null,
  cityOrTown: null,
  zipCode: null,
  address1: null,
  address2: null,
  firstName: null,
  middleName: null,
  lastName: null,
  dateOfBirth: null,
  email: null,
  socialSecurity: null,
  updateAccountDetails: false,
  kycStatus: null,
  childrenInfo: [],
  video: null,
  accountBeneficiary: null,
  isMinorDelegated: false,
  invalidFields: [],
  bankInformation: {
    bankAccountType: null,
    firstName: null,
    lastName: null,
    accountNumber: null,
    routingNumber: null,
  },
}

export const my529Reducer = (
  state: SetupMy529AccountProcessType = initialState,
  action: ReduxActionType<SetupMy529AccountProcessType>
) => {
  switch (action.type) {
    case SETUP_MY529_STEPPER_INCREAMENT:
      return {
        ...state,
        step: state.step < MAX_MY529_STEP + 1 ? state.step + 1 : state.step,
      }

    case SETUP_MY529_STEPPER_DECREAMENT:
      return {
        ...state,
        step: state.step > MIN_MY529_STEP ? state.step - 1 : state.step,
      }

    case SETUP_MY529_STEPPER_UPDATE: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        [key]: value,
      }
    }

    case SETUP_MY529_STEPPER_UPDATE_MULTIPLE: {
      const multipleFields = action.payload

      return {
        ...state,
        ...multipleFields,
      }
    }

    case SETUP_MY529_ADD_CHILDREN_INFO: {
      return {
        ...state,
        childrenInfo: action.payload,
      }
    }

    case SETUP_MY529_ADD_BANK_INF0: {
      return {
        ...state,
        bankInformation: action.payload,
      }
    }

    case SETUP_MY529_STEPPER_RESET:
      return initialState

    default:
      return state
  }
}
