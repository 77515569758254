import React from 'react'
import { useSelector } from 'react-redux'
import { RootReduxStateType } from '../../../types'
import ActivationsChildStep from './ChildStep'
import ActivationConfirmationStep from './ConfirmationStep'
import ActivationGoalStep from './GoalStep'

const ActivationRegister: React.FC = () => {
  const activation = useSelector(
    (state: RootReduxStateType) => state.activation
  )

  const displayComponent = () => {
    switch (activation.step) {
      case 1:
        return <ActivationsChildStep />

      case 2:
        return <ActivationGoalStep />

      case 3:
        return <ActivationConfirmationStep />
    }
  }

  return <div>{displayComponent()}</div>
}

export default ActivationRegister
