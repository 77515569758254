import { ReduxActionType } from '../../types'
import { UserRegistrationStepperType } from '../../types/user'
import {
  CHANGE_USER_REGISTRATION_STEPPER_DATA,
  ENABLE_MFA,
  RESET_USER_REGISTRATION_STEPPER,
  RESET_USER_REGISTRATION_STEPPER_DATA,
  USER_REGISTRATION_STEPPER_DECREAMENT,
  USER_REGISTRATION_STEPPER_INCREAMENT,
  USER_REGISTRATION_STEPPER_RESET,
  USER_REGISTRATION_STEPPER_UNDERAGE,
} from '../actions/action.types'

const intialState: UserRegistrationStepperType = {
  data: {
    userName: '',
    password: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    phoneNumber: '',
    dateOfBirth: '',
    emailAddress: '',
    referredByReferralCode: '',
    contactByPhone: false,
    contactByEmail: false,
    recieveFutureUpdates: false,
    isActivationCodeValid: false,
  },
  step: 1,
  isTwoFactorAuthEnabled: null,
}

export const userRegistrationStepper = (
  state = intialState,
  action: ReduxActionType<UserRegistrationStepperType>
) => {
  switch (action.type) {
    case USER_REGISTRATION_STEPPER_INCREAMENT:
      return {
        ...state,
        step: state.step + 1,
      }

    case USER_REGISTRATION_STEPPER_DECREAMENT:
      if (state.step >= 2) {
        return {
          ...state,
          step: state.step - 1,
        }
      }
      return state

    case USER_REGISTRATION_STEPPER_RESET:
      return {
        ...state,
        step: 1,
      }

    case RESET_USER_REGISTRATION_STEPPER:
      return {
        ...state,
        data: {
          userName: '',
          password: '',
          firstName: '',
          lastName: '',
          streetAddress: '',
          phoneNumber: '',
          dateOfBirth: '',
          emailAddress: '',
          referredByReferralCode: '',
          contactByPhone: false,
          contactByEmail: false,
          recieveFutureUpdates: false,
          isActivationCodeValid: false,
        },
      }

    case USER_REGISTRATION_STEPPER_UNDERAGE:
      return {
        ...state,
        step: 5,
      }

    case CHANGE_USER_REGISTRATION_STEPPER_DATA: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        data: {
          ...state.data,
          [key]: value,
        },
      }
    }

    case ENABLE_MFA: {
      return {
        ...state,
        isTwoFactorAuthEnabled: action.payload,
      }
    }

    case RESET_USER_REGISTRATION_STEPPER_DATA:
      return {
        ...intialState,
        step: 4,
      }

    default:
      return state
  }
}
