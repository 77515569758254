import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createSillaUserAccount } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import SafeAreaView from '../../../Common/SafeAreaView'
import ChildMain from '../Child'
import SilaSetupBottom from '../SilaSetupBottom'

const SetupSavingsStepperFive = () => {
  const navigate = useNavigate()
  const setupSavingsStepper = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper
  )
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const handleCreateSavingsAccount = async () => {
    setLoading(true)
    createSillaUserAccount({
      userAccountID: parseInt(user.id),
      identifier: setupSavingsStepper.socialSecurity ?? '',
      dateOfBirth: setupSavingsStepper.dateOfBirth ?? '',
      firstName: setupSavingsStepper.firstName ?? '',
      middleName: setupSavingsStepper.middleName ?? '',
      lastName: setupSavingsStepper.lastName ?? '',
    })
      .then(() => {
        navigate('/wallet')
        setLoading(false)
      })
      .catch(() => {
        alert('Error creating savings account')
        setLoading(false)
      })
  }

  return (
    <div>
      <SafeAreaView>
        <p className="leading-relaxed pt-4 pb-2 font-semibold text-[20px]">
          Your children
        </p>

        <p className="leading-relaxed text-[16px]">
          Please provide the details about your children so we can set up their
          wallets.
        </p>

        <ChildMain disableForm={setDisabled} />
      </SafeAreaView>
      <SilaSetupBottom
        handleNext={handleCreateSavingsAccount}
        loading={loading}
        disabled={disabled}
      />
    </div>
  )
}

export default SetupSavingsStepperFive
