export const relationships = [
  { name: 'My child', value: 'Child' },
  { name: 'Child Of my coworker', value: 'ChildOfCoworker' },
  { name: 'My child’s classmate', value: 'ChildsClassmate' },
  { name: 'My grand child', value: 'Grandchild' },
  { name: 'My Niece Or Nephew', value: 'NieceOrNephew' },
  { name: 'Student', value: 'Student' },
  { name: 'Other Relative', value: 'OtherRelative' },
  { name: 'Other', value: 'Other' },
]
