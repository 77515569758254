import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  increamentMy529Step,
  saveMy529SocialSecurity,
  setupMy529Update,
} from '../../../../redux/actions/my529Actions'
import { RootReduxStateType } from '../../../../types'
import { formatSSN, validdateSsn } from '../../../../utilities/generalUtilities'
import SafeAreaView from '../../../Common/SafeAreaView'
import My529SetupBottom from '../My529SetupBottom'

const SocialSecurityStep = () => {
  const dispatch = useDispatch()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loading, setLoading] = useState<boolean>(false)

  const handleNext = () => {
    if (my529.socialSecurity) {
      setLoading(true)
      saveMy529SocialSecurity(my529.socialSecurity ?? '', user.id)
        .then(() => {
          dispatch(increamentMy529Step())
          setLoading(false)
        })
        .catch(() => {
          alert('Error when saving ssn')
          setLoading(false)
        })
    }
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(setupMy529Update(key, formatSSN(value)))
  }

  return (
    <div>
      <SafeAreaView>
        <div className="rounded-lg border-2 border-[#EFF9F9] p-1 mb-4">
          <iframe
            title="IFrame"
            className="w-full h-[220px]"
            src="https://player.vimeo.com/video/819299312?h=4fa278fc54&title=0&byline=0&portrait=0"
            width="100%"
            //height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        {/* <AzureMediaVideoPlayer video="https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/e9cad2e8-b34f-400b-866e-38b4301fdf02/ssn_video.ism/manifest" /> */}

        <p className="text-[18px] font-semibold">Social Security Number</p>

        <p className="py-2">
          US Federal regulations require us to obtain your Social Security
          Number. This will not impact your credit.
        </p>

        <input
          type="text"
          placeholder="Social security number (SSN)"
          className="w-full outline-none bg-[#E3F2F2] px-4 py-4 rounded-lg mt-6"
          name="socialSecurity"
          value={my529.socialSecurity ?? undefined}
          onChange={handleOnChange}
        />
      </SafeAreaView>

      <My529SetupBottom
        handleNext={handleNext}
        disabled={loading ? true : !validdateSsn(my529.socialSecurity ?? '')}
      />
    </div>
  )
}

export default SocialSecurityStep
