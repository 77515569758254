import React from 'react'

type PropsType = {
  title: string
  instructions: string
  buttonLabel: string
  handleAdd: () => void
}

const UploadCard: React.FC<PropsType> = ({
  title,
  instructions,
  buttonLabel,
  handleAdd,
}) => {
  return (
    <div className="bg-[#E3F2F2] rounded-[10px] p-[16px] flex flex-row">
      <div className="border-r-2 border-dashed border-[#C4E8E8] basis-4/5">
        <h3 className="font-semibold">{title}</h3>
        <p className="w-[90%]">{instructions}</p>
      </div>
      <div className="basis-1/5">
        <button
          className="flex flex-col items-center justify-around border-0 w-full h-full"
          onClick={handleAdd}
        >
          <img src="/assets/images/add_Icon.svg" alt="Add" />
          <p className="font-normal">{buttonLabel}</p>
        </button>
      </div>
    </div>
  )
}

export default UploadCard
