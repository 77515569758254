import React, { useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
// @ts-ignore
import SmallTopArrowIcon from '../../assets/small-top-arrow.png'
// @ts-ignore
import SmallDownArrowIcon from '../../assets/small-down-arrow.png'

type PropsType = {
  children: React.ReactNode
  showIcons?: boolean
}

const SafeAreaView: React.FC<PropsType> = ({ children, showIcons = true }) => {
  const { height, width } = useWindowDimensions()

  let scrollDivRef = useRef<any>(null)

  const [scrollHeight, setScrollHeight] = useState(0)
  const [scrollBottom, setScrollBottom] = useState(false)
  const [scrollTop, setScrollTop] = useState(true)

  const handleScroll = (event: any) => {
    if (height < parseInt((height * 0.75).toFixed())) {
      setScrollBottom(false)
      setScrollTop(false)
      return
    }

    if (event.target.scrollTop === 0) {
      setScrollBottom(false)
      setScrollTop(true)
      return
    }

    if (event.target.scrollTop > 0) {
      setScrollBottom(true)
      setScrollTop(false)
      return
    }
  }
  useEffect(() => {
    if (height > parseInt((height * 0.75).toFixed())) {
      setScrollBottom(false)
      setScrollTop(false)
    }
  }, [height])

  useEffect(() => {
    setScrollHeight(parseInt((height * 0.75).toFixed()))
  }, [height])

  return (
    <>
      <div className="relative">
        {scrollBottom && showIcons && (
          <div
            style={{
              left: '50%',
              top: '5%',
              transform: 'translate(-50%,-50%)',
              zIndex: 100,
            }}
            className="bg-white w-[30px] h-[30px] rounded-full shadow-xl shadow-slate-400 flex justify-center items-center absolute"
            onClick={() => (scrollDivRef.current.scrollTop = 0)}
          >
            <img src={SmallTopArrowIcon} alt="small-upper-arrow-icon" />
          </div>
        )}
        {scrollTop && showIcons && (
          <div
            style={{
              left: '50%',
              bottom: '5%',
              transform: 'translate(-50%,-50%)',
              zIndex: 100,
            }}
            className="bg-white w-[30px] h-[30px] rounded-full shadow-xl shadow-slate-400 flex justify-center items-center absolute"
            onClick={() => (scrollDivRef.current.scrollTop = height)}
          >
            <img src={SmallDownArrowIcon} alt="small-down-arrow-icon" />
          </div>
        )}
        <div
          style={{
            height: `${
              height > 680 ? (width < 640 ? scrollHeight : 650) : scrollHeight
            }px`,
          }}
          className={`overflow-y-auto py-4`}
          onScroll={handleScroll}
          ref={scrollDivRef}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default SafeAreaView
