import React from 'react'
// @ts-ignore
import Footer from '../Partials/Footer'
import NewNavbar from '../Partials/NewNavbar'

type PropsType = {
  children?: React.ReactNode
  isAbsolute?: boolean
  showFooter?: boolean
  footerWhite?: boolean
}

const LayoutDefault: React.FC<PropsType> = ({
  children,
  isAbsolute,
  showFooter = true,
  footerWhite,
}) => {
  return (
    <div className="mx-auto">
      <NewNavbar style="primary" />
      <div className="mx-auto">{children}</div>
      {/* mt-[72px] <NewNavbar style="primary" /> <Header showUser isFixable />*/}
      {showFooter && <Footer footerWhite={footerWhite} />}
    </div>
  )
}

export default LayoutDefault
