import React from 'react'

const PasswordStrengthChecker = ({ password }: { password: string }) => {
  const createPasswordLabel = () => {
    let score = 0
    let regexPattern = ['[A-Z]', '[a-z]', '[0-9]', '\\W']

    regexPattern.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {
        score += 1
      }
    })

    switch (score) {
      case 0:
        return {
          value: -1,
          status: '',
          progress: 0,
        }
      case 1:
        return {
          value: 1,
          status: 'Weak',
          progress: 20,
        }

      case 2:
        return {
          value: 2,
          status: 'Weak',
          progress: 45,
        }

      case 3:
        return {
          value: 3,
          status: 'Average',
          progress: 75,
        }

      case 4:
        return {
          value: 4,
          status: 'Strong',
          progress: 90,
        }

      default:
        return {
          value: -1,
          status: '',
          progress: 0,
        }
    }
  }

  return (
    <div>
      <div className=" w-full flex justify-between items-center">
        <p
          className={`text-[11px] uppercase font-semibold transition-all ease-out duration-1000 ${
            createPasswordLabel().value <= 2
              ? 'text-[#FF3D00]'
              : createPasswordLabel().value === 3
              ? 'text-[#EBB700]'
              : 'text-[#3EB76E]'
          }`}
        >
          Your Password is <span>{createPasswordLabel().status}</span>
        </p>

        <div
          className={`h-[12px] w-[100px] rounded-full ${
            createPasswordLabel().value <= 2
              ? 'bg-[#FFE5DD]'
              : createPasswordLabel().value === 3
              ? 'bg-[#FFEDAF]'
              : 'bg-[#90F2B8]'
          }`}
        >
          <div
            style={{ width: createPasswordLabel().progress }}
            className={`h-full transition-all ease-out duration-1000 rounded-full ${
              createPasswordLabel().value <= 2
                ? 'bg-[#FF3D00]'
                : createPasswordLabel().value === 3
                ? 'bg-[#EBB700]'
                : 'bg-[#3EB76E]'
            }`}
          ></div>
        </div>
      </div>
      <p className="mb-4 text-[13px]">
        {createPasswordLabel().status === 'Weak'
          ? 'Make it at least 8 characters long, include a number, an uppercase letter and a special character'
          : createPasswordLabel().status === 'Average'
          ? 'Make your password stronger by ensuring it is atleast 8 characters long, include a number, an uppercase letter and a special character'
          : ''}
      </p>
    </div>
  )
}

export default PasswordStrengthChecker
