import React from 'react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowLeft, ArrowRight } from '../ImageImports'

const ActivationTestimonials = () => {
  const testimonials = [
    {
      id: 1,
      title:
        '“This partnership with Sootchy enhances our ability to provide resources to help our students succeed.”',
      source: 'armoneyandpolitics.com',
    },
    {
      id: 2,
      title:
        '“By investing minimal amounts — just $4 a month or $50 a year, for instance — friends and family members can set their loved ones up forsuccess”',
      source: 'labusinessjournal.com',
    },
    {
      id: 3,
      title:
        '“My team and I used the power of friends and family, sprinkled in with a healthy dose of state-of-the-art artificial intelligence, to build something we believe will make the lives of parents and children easier.”',
      source: 'techcrunch.com',
    },
    {
      id: 4,
      title:
        '“Sootchy is a simple, user-friendly fintech app that allows families to save money for their children’s education”',
      source: 'apnews.com',
    },
  ]
  return (
    <>
      <Swiper
        navigation={false}
        modules={[Navigation]}
        className="md:hidden w-[100%] h-[100%]"
      >
        {testimonials.map((item, index) => (
          <SwiperSlide key={item.id}>
            <p className="font-thin leading-[18px] text-[14px] mb-[12px]">
              {item.title}
            </p>
            <p className="font-thin leading-[18px] text-[14px] mb-[12px]">
              {item.source}
            </p>

            <div className="flex justify-between items-center mt-[32px]">
              <img src={ArrowLeft} alt="Swipe Left" className="h-[8px]" />
              <p className="font-thin leading-[18px] text-[14px]">
                {index + 1}/{testimonials.length}
              </p>
              <img src={ArrowRight} alt="Swipe Right" className="h-[8px]" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {testimonials.map((item) => (
        <div className="hidden md:block" key={item.id}>
          <p className="font-thin leading-[18px] text-[14px] mb-[12px]">
            {item.title}
          </p>

          <p className="font-thin leading-[18px] text-[14px] mb-[12px]">
            {item.source}
          </p>
        </div>
      ))}
    </>
  )
}

export default ActivationTestimonials
