import React from 'react'
import { useNavigate } from 'react-router-dom'
import Navigation from '../../Common/Navigation'
import ChildMain from '../../Sila/SetupSavingsAccount/Child'

const ChildrenDetails: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4">
            <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
              <button
                type="button"
                className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
                onClick={() => navigate(-1)}
              >
                <img
                  src={`/assets/images/back.svg`}
                  alt="back"
                  className="block text-primaryDark"
                />
              </button>
              Your Children
            </h1>
          </div>
          <ChildMain />
        </div>
      </Navigation>
    </>
  )
}

export default ChildrenDetails
