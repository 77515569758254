import React, { useState, useRef, useEffect } from 'react'
import { Camera } from 'react-camera-pro'
import { HiArrowNarrowLeft, HiX } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../../../api'
import { getRequiredDocumentsFromSilla } from '../../../../redux/actions/generalActions'
import { getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user'
import LayoutFullPage from '../../Layouts/FullPage'
import { LoadingSpinner } from '../../Loading'
import { IoMdReverseCamera } from 'react-icons/io'

const TakeAPicture = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const document = useSelector((state: RootReduxStateType) => state.general)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [requiredSteps, setRequiredSteps] = useState(0)
  const [numberOfCameras, setNumberOfCameras] = useState(0)
  const camera = useRef<any>(null)
  const [image, setImage] = useState<any>(null)
  const [imagesTaken, setImagesTaken] = useState<any[]>([])
  const [isPhotoTaken, setIsPhotoTaken] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [loadedUser, setLoadedUser] = useState<UserAPIType>()

  const getUserInfo = async () => {
    const userResponse = await getUser(user.id)

    if (userResponse.status === 200 && userResponse) {
      setLoadedUser(userResponse.data)
      console.log('Loaded User', userResponse.data)
    }
  }

  useEffect(() => {
    if (requiredSteps !== 0) {
      // alert(`${numberOfCameras} cameras detected`)
      console.log(`${numberOfCameras} cameras detected`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredSteps])

  const displayRequiredDocs = async () => {
    setLoading(true)
    const response = await getRequiredDocumentsFromSilla()

    let result = response
      .reduce(function (res: any, currentValue: any) {
        if (res.indexOf(currentValue.documentList) === -1) {
          res.push(currentValue.documentList)
        }
        return res
      }, [])
      .map(function (documentList: any) {
        return {
          // documentList: documentList,
          obj: response
            .filter(function (_el: any) {
              return _el.documentList === documentList
            })
            .map(function (_el: any) {
              return _el
            }),
        }
      })
    console.log('required Steps', result.length)
    setRequiredSteps(result.length)
    setLoading(false)
  }

  const handleContinueButton = async () => {
    try {
      if (document.documentSelectedForUpload.isFrontAndBackRequired) {
        if (imagesTaken.length !== 2) {
          setIsPhotoTaken(false)
        } else {
          handleFileUpload()
        }
      } else {
        handleFileUpload()
      }
    } catch (error) {
      console.log('Failed to upload file')
    }
  }

  const handleFileUpload = async () => {
    try {
      let formData = new FormData()

      if (image) {
        if (loadedUser) {
          let silaUserID: any = loadedUser.silaUserAccountID

          if (document.documentSelectedForUpload.isFrontAndBackRequired) {
            imagesTaken.map((image) => formData.append('dataStream', image))
          } else formData.append('dataStream', image)

          formData.append('SilaUserAccountID', silaUserID)
          formData.append(
            'SilaDocumentType',
            document.documentSelectedForUpload.silaDocumentType
          )
          formData.append('CreatedUserAccountID', user.id)
          formData.append('SourceProcess', 'WebApplication')

          const response: any = await api.post(
            '/SilaDocument/Upload/Stream',
            formData
          )

          // console.log('Response from Upload', response)
          if (response.status === 200) {
            //console.log('Response from Upload', response)
            navigate('/savings-account-verification')
            // to skip documents which satisfy other conditions
            //implement redux
          }
        }
      }
    } catch (error) {
      console.log('Failed to upload file')
    }
  }

  useEffect(() => {
    getUserInfo()
    displayRequiredDocs()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredSteps])

  return (
    <div>
      {loading ? (
        <LayoutFullPage>
          <LoadingSpinner size={15} color="#004F4E" />
        </LayoutFullPage>
      ) : isPhotoTaken ? (
        <>
          <div className="bg-[#021212] w-full min-h-screen flex justify-center">
            <div className="bg-[#021212]  min-h-screen w-full sm:w-[376px] flex flex-col justify-between">
              <div className="text-[20px] opacity-75 h-[10%] text-white flex justify-between px-[10px] pt-[15px]">
                <HiArrowNarrowLeft
                  onClick={() => navigate('/savings-account-verification')}
                />
                <HiX onClick={() => navigate(-1)} />
              </div>
              <div className="text-center text-white">
                {document.documentSelectedForUpload.documentList === 51 ? (
                  <p className="py-2">Your face within the frame</p>
                ) : (
                  <p className="py-2">
                    {imagesTaken.length < 2 ? 'Front' : 'Back'} side of your{' '}
                    {document.documentSelectedForUpload.silaDocumentTypeLabel}{' '}
                    within the frame
                  </p>
                )}
                <div className="flex justify-center items-center">
                  {image && (
                    <img
                      src={image}
                      className="object-cover h-[300px] w-[400px]"
                      alt="proof of identification"
                    />
                  )}
                </div>

                {document.documentSelectedForUpload.documentList === 51 ? (
                  <p className="mx-[10px] py-2">
                    Is your face within the frame and your facial features
                    distinguishable?
                  </p>
                ) : (
                  <p className="mx-[10px] py-2">
                    Is the document within the frame and the text readable?{' '}
                  </p>
                )}
              </div>

              <div className="flex justify-between py-[20px] mx-[10px]">
                <button
                  className="text-primaryLight h-[48px] w-[45%] border-primaryLight"
                  onClick={() => setIsPhotoTaken(false)}
                >
                  No, retake
                </button>
                <button
                  className="bg-primaryLight h-[48px] w-[50%] border-0"
                  onClick={handleContinueButton}
                >
                  Yes, Continue
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-[#021212] flex justify-center items-center min-h-screen">
            <div className="absolute z-10 min-h-screen sm:min-h-[80%] w-full sm:max-w-[376px] md:max-w-[376px] lg:max-w-[376px]">
              <Camera
                ref={camera}
                numberOfCamerasCallback={setNumberOfCameras}
                errorMessages={{
                  noCameraAccessible:
                    'No camera device accessible. Please connect your camera or try a different browser.',
                  permissionDenied:
                    'Permission denied. Please refresh and give camera permission.',
                  switchCamera:
                    'It is not possible to switch camera to different one because there is only one video device accessible.',
                  canvas: 'Canvas is not supported.',
                }}
              />

              <div className=" fixed w-full sm:max-w-[376px] md:max-w-[376px] lg:max-w-[376px] h-[100vh] opacity-95 z-[100]">
                <div className="bg-[#021212] opacity-75 h-[10%] text-white flex justify-end px-[10px] pt-[15px]">
                  <HiX onClick={() => navigate(-1)} />
                </div>
                <div className="h-[25%] sm:h-[15%] md:h-[15%] bg-[#021212] opacity-75 text-center text-white px-[10px] flex flex-col justify-center items-center space-y-4">
                  <p className="font-bold">
                    {document.uploadStep} of {requiredSteps}
                  </p>

                  {document.documentSelectedForUpload.documentList === 51 ? (
                    <p className="py-2">
                      Take a selfie which includes you holding up your ID near
                      your face within the frame.
                    </p>
                  ) : (
                    <p className="py-2">
                      {imagesTaken.length < 1 ? 'Front' : 'Back'} side of your{' '}
                      {document.documentSelectedForUpload.silaDocumentTypeLabel}{' '}
                      within the frame
                    </p>
                  )}
                </div>
                <div className="h-[45%] sm:h-[40%] md:h-[40%]">
                  {document.documentSelectedForUpload.documentList === 51 ? (
                    <div className="h-full flex justify-center items-center">
                      <div className="w-[80%] h-[90%] rounded-[45%] border-4 border-white"></div>
                    </div>
                  ) : (
                    <div className="w-full h-[100%] border-x-8 border-y-[25px] border-[#021212] border-opacity-75">
                      <div className="w-full h-[100%] border-4"></div>
                    </div>
                  )}
                </div>
                <div className="relative h-[20%] sm:h-[18%] bg-[#021212] opacity-75 flex justify-center items-center space-x-12">
                  <div
                    className="flex justify-center items-center w-[80px] h-[80px] rounded-full bg-[#7FE5E480]"
                    onClick={() => {
                      if (camera.current) {
                        const photo = camera.current.takePhoto()
                        //console.log(photo)
                        setImage(photo)
                        setImagesTaken([...imagesTaken, photo])
                        setIsPhotoTaken(true)
                      }
                    }}
                  >
                    <div className="w-[60px] h-[60px] rounded-full bg-[#7FE5E4]"></div>
                  </div>

                  <div
                    className="absolute right-8"
                    hidden={numberOfCameras <= 1}
                    onClick={() => {
                      camera.current.switchCamera()
                    }}
                  >
                    <IoMdReverseCamera className="text-[#7FE5E4] text-[30px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TakeAPicture
