import { useLoadScript } from '@react-google-maps/api'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { states } from '../../../../constants/states'
import { updateUserAccount } from '../../../../redux/actions/generalActions'
import {
  decreamentMy529Step,
  increamentMy529Step,
  setupMy529Update,
  setupMy529UpdateMultiple,
} from '../../../../redux/actions/my529Actions'

import {
  createAddress,
  getUser,
  getUserAddressInfo,
} from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user.js'
import {
  validatePhoneNumber,
  validateZipCode,
} from '../../../../utilities/generalUtilities'
import { LoadingSpinner } from '../../../Common/Loading'
import SafeAreaView from '../../../Common/SafeAreaView'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import My529PlacesAutocomplete from '../My529PlacesAutocomplete'
import My529SetupBottom from '../My529SetupBottom'

const AddressStep = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '',
    libraries: ['places'],
  })
  const dispatch = useDispatch()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)
  const user = useSelector((state: RootReduxStateType) => state.user)

  const [manualAddress, setManualAddress] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loadedUser, setLoadedUser] = useState<UserAPIType | null>(null)
  const [infoNeeded, setInfoNeeded] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const formatPhoneNumberToSave = (phone: string) => {
    let arr = ['+1', phone.substring(1)]
    return arr.join(' ')
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(setupMy529Update(key, value))
  }

  const handleNext = async () => {
    if (!manualAddress) setManualAddress(true)
    else {
      setLoading(true)
      if (infoNeeded.includes('telephoneNumber') && loadedUser) {
        await updateUserAccount(
          user.id,
          loadedUser?.firstName,
          loadedUser.lastName,
          loadedUser?.dateOfBirth,
          formatPhoneNumberToSave(my529.phoneNumber ?? ''),
          loadedUser?.emailAddress
        )
      }
      await createAddress({
        userAccountID: parseInt(user.id),
        streetAddress1: my529.address1 ?? '',
        streetAddress2: my529.address2 ?? '',
        city: my529.cityOrTown ?? '',
        state: my529.state ?? '',
        zipCode: my529.zipCode ?? '',
      })

      dispatch(increamentMy529Step())
      setLoading(false)
    }
  }

  const handleSearchAddress = () => {
    if (infoNeeded.includes('telephoneNumber'))
      if (!my529.phoneNumber) {
        setManualAddress(false)
        return
      }

    setManualAddress(!manualAddress)
  }

  const handleDisabledState = () => {
    if (!my529.address1 || !my529.cityOrTown || !my529.state) {
      if (infoNeeded.includes('telephoneNumber'))
        if (!validatePhoneNumber(my529.phoneNumber ?? '')) setDisabled(true)
      setDisabled(true)
    } else {
      if (infoNeeded.includes('telephoneNumber'))
        if (!validatePhoneNumber(my529.phoneNumber ?? '')) {
          setDisabled(true)
          return
        }
      setDisabled(false)
    }
  }

  const getValue = (
    value: string | null,
    returnType: 'function' | 'api' = 'function'
  ) => {
    if (returnType === 'function') {
      if (value === '' || value === null || value === undefined) return null
      return value
    }

    if (value === '' || value === null || value === undefined) return ''

    return value
  }

  const loadUserData = async () => {
    const response = await getUser(user.id)

    if (response.status === 200) {
      dispatch(
        setupMy529UpdateMultiple({
          firstName: getValue(response.data.firstName),
          lastName: getValue(response.data.lastName),
          //dateOfBirth: getValue(response.data.dateOfBirth),

          dateOfBirth: getValue(
            //dateToISOLikeButLocal(new Date(response.data.dateOfBirth))
            dayjs(response.data.dateOfBirth).format('YYYY-MM-DD')
          ),
          phoneNumber: getValue(response.data.telephoneNumber),
          email: getValue(response.data.emailAddress),
        })
      )

      const userAccountAddress = response.data.userAccountAddress

      if (response.data.userAccountAddress) {
        dispatch(
          setupMy529UpdateMultiple({
            address1: userAccountAddress.streetAddress1,
            address2: userAccountAddress.streetAddress2,
            cityOrTown: userAccountAddress.city,
            zipCode: userAccountAddress.zipCode,
            state: userAccountAddress.state,
          })
        )
        setManualAddress(true)
      }
      setLoadedUser(response.data)
    }
  }

  const getUserInfo = async () => {
    if (loadedUser) {
      setInfoNeeded([])
      setDisabled(true)

      const addressResponse = await getUserAddressInfo(user.id)

      if (loadedUser.firstName === null || loadedUser.firstName === '') {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'firstName',
        ])
      }

      if (loadedUser.lastName === null || loadedUser.lastName === '') {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'lastName',
        ])
      }

      if (
        loadedUser.telephoneNumber === null ||
        loadedUser.telephoneNumber === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'telephoneNumber',
        ])
      }

      if (addressResponse.response?.data.length === 0) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'address',
        ])
      }
    }
  }

  const handleManualAddressCheck = () => {
    if (
      (my529.address1 === null
        ? true
        : my529.address1.length > 2
        ? true
        : false) &&
      (my529.address2 === null || my529.address2 === ''
        ? true
        : my529.address2.length > 2
        ? true
        : false) &&
      my529.cityOrTown &&
      my529.zipCode &&
      validateZipCode(my529.zipCode) &&
      my529.state
    )
      return false
    return true
  }

  useEffect(() => {
    if (!loadedUser) loadUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleDisabledState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [my529])

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedUser])

  useEffect(() => {
    if (my529.address1 && my529.cityOrTown && my529.zipCode && my529.state) {
      setManualAddress(true)
      setDisabled(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SafeAreaView>
        {manualAddress ? (
          <div>
            <p className="py-3">We also need your address.</p>
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="address1"
                aria-label="address1"
                value={my529.address1 ? my529.address1 : undefined}
              />
              <label>Address line 1</label>
            </div>

            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                aria-label="address2"
                name="address2"
                value={my529.address2 ? my529.address2 : undefined}
              />
              <label>Address line 2</label>
            </div>

            <div className="flex">
              <div className={`form-group form-group--filled py-2 px-1`}>
                <input
                  onChange={handleOnChange}
                  aria-label="cityOrTown"
                  name="cityOrTown"
                  value={my529.cityOrTown ? my529.cityOrTown : undefined}
                />
                <label>City or town</label>
              </div>
              <div className={`form-group form-group--filled py-2 px-1`}>
                <input
                  onChange={handleOnChange}
                  aria-label="zipCode"
                  name="zipCode"
                  value={my529.zipCode ? my529.zipCode : undefined}
                />
                <label>ZIP</label>
              </div>
            </div>

            <div>
              <select
                name="state"
                aria-label="State"
                value={my529.state ? my529.state : undefined}
                onChange={handleOnChange}
                className="bg-[#E3F2F2] py-5 mt-2 w-full px-3 rounded-lg outline-none"
              >
                {states.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <p
              className="py-5 text-primaryDark text-[14px]"
              onClick={() => {
                dispatch(
                  setupMy529UpdateMultiple({
                    address1: null,
                    address2: null,
                    cityOrTown: null,
                    zipCode: null,
                    state: null,
                  })
                )
                handleSearchAddress()
              }}
            >
              Search for another address
            </p>
          </div>
        ) : (
          <div>
            {infoNeeded.includes('telephoneNumber') && (
              <div>
                <p className="py-3">We also need your Phone Number.</p>
                <TelephoneNumber
                  onChange={(telephoneNumber) =>
                    dispatch(setupMy529Update('phoneNumber', telephoneNumber))
                  }
                  value={my529.phoneNumber ?? ''}
                />
              </div>
            )}
            <p className="py-3">We also need your address.</p>
            {isLoaded ? (
              <My529PlacesAutocomplete
                handleSearchAddress={handleSearchAddress}
              />
            ) : (
              <LoadingSpinner color="#004F4E" />
            )}
          </div>
        )}
      </SafeAreaView>

      <My529SetupBottom
        handleNext={handleNext}
        disabled={
          manualAddress
            ? loading
              ? true
              : handleManualAddressCheck()
            : disabled
        }
        handleCancel={() => {
          const state =
            localStorage.getItem('isKycFailed') === 'true' ? true : false
          dispatch(setupMy529Update('updateAccountDetails', state))
          dispatch(decreamentMy529Step())
          if (state) dispatch(increamentMy529Step())
        }}
      />
    </>
  )
}

export default AddressStep
