import * as amplitude from '@amplitude/analytics-browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import { persistor, store } from './redux/store'
import Router from './routes'
import './styles/index.css'

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY ?? ''

amplitude.init(API_KEY, undefined, {
  defaultTracking: {
    sessions: true,
    pageViews: true,
    formInteractions: true,
    fileDownloads: true,
  },
})

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading="loading..." persistor={persistor}>
      <Router />
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
)
