import { useLoadScript } from '@react-google-maps/api'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { states } from '../../../../constants/states'
import { updateUserAccount } from '../../../../redux/actions/generalActions'
import {
  decreamentSavingsStepperStep,
  increamentSavingsStepperStep,
  setupSavingsStepperReset,
  setupSavingsStepperUpdate,
  setupSavingsStepperUpdateMultiple,
} from '../../../../redux/actions/setupSavingsStepperActions'
import {
  createAddress,
  getUser,
  getUserAddressInfo,
  updateUser,
  verifyEmailAPI,
} from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user.js'
import {
  validatePhoneNumber,
  validateZipCode,
} from '../../../../utilities/generalUtilities'
import DateInput from '../../../Common/DateInput/DateInput'
import { LoadingSpinner } from '../../../Common/Loading'
import SafeAreaView from '../../../Common/SafeAreaView'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import Toast from '../../../Common/Toast'
import SilaPlacesAutocomplete from '../SilaPlacesAutocomplete'
import SilaSetupBottom from '../SilaSetupBottom'

type PropsType = {
  setShowModal: (x: boolean) => void
}

const SetupSavingsStepperThree: React.FC<PropsType> = ({ setShowModal }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '',
    libraries: ['places'],
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setupSavingsStepper = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper
  )
  const user = useSelector((state: RootReduxStateType) => state.user)

  const [manualAddress, setManualAddress] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [disabledReview, setDisabledReview] = useState<boolean>(true)
  const [loadedUser, setLoadedUser] = useState<UserAPIType | null>(null)
  const [infoNeeded, setInfoNeeded] = useState<string[]>([])

  const formatPhoneNumberToSave = (phone: string) => {
    let arr = ['+1', phone.substring(1)]
    return arr.join(' ')
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(setupSavingsStepperUpdate(key, value))
  }

  const handleNext = async () => {
    if (!manualAddress) setManualAddress(true)
    else {
      if (infoNeeded.includes('telephoneNumber') && loadedUser) {
        await updateUserAccount(
          user.id,
          loadedUser?.firstName,
          loadedUser.lastName,
          loadedUser?.dateOfBirth,
          formatPhoneNumberToSave(setupSavingsStepper.phoneNumber ?? ''),
          loadedUser?.emailAddress
        )
      }
      await createAddress({
        userAccountID: parseInt(user.id),
        streetAddress1: setupSavingsStepper.address1 ?? '',
        streetAddress2: setupSavingsStepper.address2 ?? '',
        city: setupSavingsStepper.cityOrTown ?? '',
        state: setupSavingsStepper.state ?? '',
        zipCode: setupSavingsStepper.zipCode ?? '',
      })

      dispatch(increamentSavingsStepperStep())
    }
  }

  const handleSearchAddress = () => {
    if (infoNeeded.includes('telephoneNumber'))
      if (!setupSavingsStepper.phoneNumber) {
        setManualAddress(false)
        return
      }

    setManualAddress(!manualAddress)
  }

  const handleDisabledState = () => {
    if (
      !setupSavingsStepper.address1 ||
      !setupSavingsStepper.cityOrTown ||
      !setupSavingsStepper.state
    ) {
      if (infoNeeded.includes('telephoneNumber'))
        if (!validatePhoneNumber(setupSavingsStepper.phoneNumber ?? ''))
          setDisabled(true)
      setDisabled(true)
    } else {
      if (infoNeeded.includes('telephoneNumber'))
        if (!validatePhoneNumber(setupSavingsStepper.phoneNumber ?? '')) {
          setDisabled(true)
          return
        }
      setDisabled(false)
    }
  }

  const getValue = (
    value: string | null,
    returnType: 'function' | 'api' = 'function'
  ) => {
    if (returnType === 'function') {
      if (value === '' || value === null || value === undefined) return null
      return value
    }

    if (value === '' || value === null || value === undefined) return ''

    return value
  }

  const loadUserData = async () => {
    const response = await getUser(user.id)

    if (response.status === 200) {
      dispatch(
        setupSavingsStepperUpdateMultiple({
          firstName: getValue(response.data.firstName),
          lastName: getValue(response.data.lastName),
          //dateOfBirth: getValue(response.data.dateOfBirth),

          dateOfBirth: getValue(
            //dateToISOLikeButLocal(new Date(response.data.dateOfBirth))
            dayjs(response.data.dateOfBirth).format('YYYY-MM-DD')
          ),
          phoneNumber: getValue(response.data.telephoneNumber),
          email: getValue(response.data.emailAddress),
        })
      )

      const userAccountAddress = response.data.userAccountAddress

      if (response.data.userAccountAddress) {
        dispatch(
          setupSavingsStepperUpdateMultiple({
            address1: userAccountAddress.streetAddress1,
            address2: userAccountAddress.streetAddress2,
            cityOrTown: userAccountAddress.city,
            zipCode: userAccountAddress.zipCode,
            state: userAccountAddress.state,
          })
        )
        setManualAddress(true)
      }
      setLoadedUser(response.data)
    }
  }

  const handleDisabledReviewState = () => {
    if (
      !setupSavingsStepper.firstName ||
      !setupSavingsStepper.lastName ||
      !setupSavingsStepper.dateOfBirth ||
      !setupSavingsStepper.email
    ) {
      setDisabledReview(true)
    } else {
      setDisabledReview(false)
    }
  }

  const handleOnClickReviewDetails = async () => {
    setDisabledReview(true)
    if (loadedUser) {
      const response = await updateUser({
        UserAccountID: parseInt(user.id),
        FirstName: getValue(setupSavingsStepper.firstName, 'api'),
        LastName: getValue(setupSavingsStepper.lastName, 'api'),
        DateOfBirth: setupSavingsStepper.dateOfBirth,
        TelephoneNumber: setupSavingsStepper.phoneNumber,
        EmailAddress: setupSavingsStepper.email,
        ModifyingUserAccountID: parseInt(user.id),
        SourceProcess: 'WebApplication',
        ChangeReason: 'Update User',
      })

      if (response.success) {
        if (setupSavingsStepper.email !== loadedUser.emailAddress) {
          const response = await verifyEmailAPI()

          if (response.success) setShowModal(true)
        }

        dispatch(setupSavingsStepperUpdate('updateAccountDetails', false))
        if (localStorage.getItem('isKycFailed')) {
          dispatch(decreamentSavingsStepperStep())
          dispatch(setupSavingsStepperUpdate('updateAccountDetails', false))
          setManualAddress(true)
          dispatch(increamentSavingsStepperStep())
        } else {
          dispatch(decreamentSavingsStepperStep())
        }
      }
      setDisabledReview(false)
    }
  }

  const getUserInfo = async () => {
    if (loadedUser) {
      setInfoNeeded([])
      setDisabled(true)

      const addressResponse = await getUserAddressInfo(user.id)

      if (loadedUser.firstName === null || loadedUser.firstName === '') {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'firstName',
        ])
      }

      if (loadedUser.lastName === null || loadedUser.lastName === '') {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'lastName',
        ])
      }

      if (
        loadedUser.telephoneNumber === null ||
        loadedUser.telephoneNumber === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'telephoneNumber',
        ])
      }

      if (addressResponse.response?.data.length === 0) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'address',
        ])
      }
    }
  }

  const handleManualAddressCheck = () => {
    if (
      (setupSavingsStepper.address1 === null
        ? true
        : setupSavingsStepper.address1.length > 2
        ? true
        : false) &&
      (setupSavingsStepper.address2 === null ||
      setupSavingsStepper.address2 === ''
        ? true
        : setupSavingsStepper.address2.length > 2
        ? true
        : false) &&
      setupSavingsStepper.cityOrTown &&
      setupSavingsStepper.zipCode &&
      validateZipCode(setupSavingsStepper.zipCode) &&
      setupSavingsStepper.state
    )
      return false
    return true
  }

  useEffect(() => {
    if (!loadedUser) loadUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleDisabledState()
    handleDisabledReviewState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupSavingsStepper])

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedUser])

  useEffect(() => {
    if (
      setupSavingsStepper.address1 &&
      setupSavingsStepper.cityOrTown &&
      setupSavingsStepper.zipCode &&
      setupSavingsStepper.state
    ) {
      setManualAddress(true)
      setDisabled(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return setupSavingsStepper.updateAccountDetails ? (
    <>
      <SafeAreaView>
        <p>
          Make sure the details below are accurate. We will use this information
          to verify your identity
        </p>
        <div className="py-4">
          <div className={`form-group form-group--filled py-2`}>
            <input
              onChange={handleOnChange}
              name="firstName"
              aria-label="firstName"
              value={
                setupSavingsStepper.firstName
                  ? setupSavingsStepper.firstName
                  : undefined
              }
            />
            <label>First name</label>
          </div>
          <div className={`form-group form-group--filled py-2`}>
            <input
              onChange={handleOnChange}
              name="lastName"
              aria-label="lastName"
              value={
                setupSavingsStepper.lastName
                  ? setupSavingsStepper.lastName
                  : undefined
              }
            />
            <label>Last name</label>
          </div>
          <div className="py-2">
            {setupSavingsStepper.dateOfBirth && (
              <DateInput
                dateValue={dayjs(setupSavingsStepper.dateOfBirth).format(
                  'YYYY-MM-DD'
                )}
                onChange={(date: any) =>
                  dispatch(
                    setupSavingsStepperUpdate(
                      'dateOfBirth',
                      dayjs(date).format('YYYY-MM-DD')
                    )
                  )
                }
              />
            )}
          </div>

          <TelephoneNumber
            onChange={(telephoneNumber) =>
              dispatch(
                setupSavingsStepperUpdate(
                  'phoneNumber',
                  formatPhoneNumberToSave(telephoneNumber)
                )
              )
            }
            value={setupSavingsStepper.phoneNumber ?? ''}
          />
          {localStorage.getItem('isKycFailed') !== 'true' ? (
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="email"
                aria-label="email"
                value={setupSavingsStepper.email ?? ''}
              />
              <label>Email</label>
            </div>
          ) : null}
          {localStorage.getItem('isKycFailed') !== 'true' ? (
            <Toast
              styles="primary"
              description="Note: upon changing the email, we’ll send you an email with a confirmation link to make sure the email provided is correct and belongs to you."
              className="my-5"
            />
          ) : null}
        </div>
      </SafeAreaView>
      <SilaSetupBottom
        disabled={disabledReview}
        handleNext={handleOnClickReviewDetails}
        handleCancel={() => {
          const state =
            localStorage.getItem('isKycFailed') === 'true' ? true : false
          if (state) {
            dispatch(setupSavingsStepperReset())
            localStorage.setItem('isKycFailed', 'false')
            navigate('/savings-account-verification')
          } else {
            dispatch(setupSavingsStepperUpdate('updateAccountDetails', false))
            dispatch(decreamentSavingsStepperStep())
          }
        }}
      />
    </>
  ) : (
    <>
      <SafeAreaView>
        {manualAddress ? (
          <div>
            <p className="py-3">We also need your address.</p>
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="address1"
                aria-label="address1"
                value={
                  setupSavingsStepper.address1
                    ? setupSavingsStepper.address1
                    : undefined
                }
              />
              <label>Address line 1</label>
            </div>

            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                aria-label="address2"
                name="address2"
                value={
                  setupSavingsStepper.address2
                    ? setupSavingsStepper.address2
                    : undefined
                }
              />
              <label>Address line 2</label>
            </div>

            <div className="flex">
              <div className={`form-group form-group--filled py-2 px-1`}>
                <input
                  onChange={handleOnChange}
                  aria-label="cityOrTown"
                  name="cityOrTown"
                  value={
                    setupSavingsStepper.cityOrTown
                      ? setupSavingsStepper.cityOrTown
                      : undefined
                  }
                />
                <label>City or town</label>
              </div>
              <div className={`form-group form-group--filled py-2 px-1`}>
                <input
                  onChange={handleOnChange}
                  aria-label="zipCode"
                  name="zipCode"
                  value={
                    setupSavingsStepper.zipCode
                      ? setupSavingsStepper.zipCode
                      : undefined
                  }
                />
                <label>ZIP</label>
              </div>
            </div>

            <div>
              <select
                name="state"
                aria-label="State"
                value={
                  setupSavingsStepper.state
                    ? setupSavingsStepper.state
                    : undefined
                }
                onChange={handleOnChange}
                className="bg-[#E3F2F2] py-5 mt-2 w-full px-3 rounded-lg outline-none"
              >
                {states.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <p
              className="py-5 text-primaryDark text-[14px]"
              onClick={() => {
                dispatch(
                  setupSavingsStepperUpdateMultiple({
                    address1: null,
                    address2: null,
                    cityOrTown: null,
                    zipCode: null,
                    state: null,
                  })
                )
                handleSearchAddress()
              }}
            >
              Search for another address
            </p>
          </div>
        ) : (
          <div>
            {infoNeeded.includes('telephoneNumber') && (
              <div>
                <p className="py-3">We also need your Phone Number.</p>
                <TelephoneNumber
                  onChange={(telephoneNumber) =>
                    dispatch(
                      setupSavingsStepperUpdate('phoneNumber', telephoneNumber)
                    )
                  }
                  value={setupSavingsStepper.phoneNumber ?? ''}
                />
              </div>
            )}
            <p className="py-3">We also need your address.</p>
            {isLoaded ? (
              <SilaPlacesAutocomplete
                handleSearchAddress={handleSearchAddress}
              />
            ) : (
              <LoadingSpinner color="#004F4E" />
            )}
          </div>
        )}
      </SafeAreaView>

      <SilaSetupBottom
        handleNext={handleNext}
        disabled={manualAddress ? handleManualAddressCheck() : disabled}
        handleCancel={() => {
          const state =
            localStorage.getItem('isKycFailed') === 'true' ? true : false
          dispatch(setupSavingsStepperUpdate('updateAccountDetails', state))
          dispatch(decreamentSavingsStepperStep())
          if (state) dispatch(increamentSavingsStepperStep())
        }}
      />
    </>
  )
}

export default SetupSavingsStepperThree
