import { v4 as uuidv4 } from 'uuid'
import { ReduxActionType } from '../../types'
import {
  SETUP_SAVINGS_REGISTER_CHILD,
  SETUP_SAVINGS_REMOVE_CHILD,
  SETUP_SAVINGS_STEPPER_DECREAMENT,
  SETUP_SAVINGS_STEPPER_INCREAMENT,
  SETUP_SAVINGS_STEPPER_RESET,
  SETUP_SAVINGS_STEPPER_UPDATE,
  SETUP_SAVINGS_STEPPER_UPDATE_MULTIPLE,
  SETUP_SAVINGS_UPDATE_CHILD,
  SETUP_USER_DETAIL_FORM_STEP,
  UPDATE_KYC_STATUS,
} from '../actions/action.types'

const MIN_STEP = 1
const MAX_STEP = 5

export type SetupSavingsAccountProcessType = {
  step: number
  phoneNumber: string | null
  state: string | null
  cityOrTown: string | null
  zipCode: string | null
  address1: string | null
  address2: string | null
  firstName: string | null
  middleName: string | null
  lastName: string | null
  dateOfBirth: string | null
  email: string | null
  socialSecurity: string | null
  updateAccountDetails: boolean
  kycStatus: string | null
  childrenInfo:
    | {
        id: string
        firstName: string
        lastName: string
        dateOfBirth: string
        relationship: string
        phoneNumber: string
        email: string
        ssn: string
      }[]
    | []
}

const initialState: SetupSavingsAccountProcessType = {
  step: MIN_STEP,
  phoneNumber: null,
  state: null,
  cityOrTown: null,
  zipCode: null,
  address1: null,
  address2: null,
  firstName: null,
  middleName: null,
  lastName: null,
  dateOfBirth: null,
  email: null,
  socialSecurity: null,
  updateAccountDetails: false,
  kycStatus: null,
  childrenInfo: [],
}

export const setupSavingsStepperReducer = (
  state: SetupSavingsAccountProcessType = initialState,
  action: ReduxActionType<SetupSavingsAccountProcessType>
) => {
  switch (action.type) {
    case SETUP_USER_DETAIL_FORM_STEP:
      return {
        ...state,
        step: 2,
      }
    case SETUP_SAVINGS_STEPPER_INCREAMENT:
      return {
        ...state,
        step: state.step < MAX_STEP + 1 ? state.step + 1 : state.step,
      }

    case SETUP_SAVINGS_STEPPER_DECREAMENT:
      return {
        ...state,
        step: state.step > 0 ? state.step - 1 : state.step,
      }

    case SETUP_SAVINGS_STEPPER_UPDATE: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        [key]: value,
      }
    }

    case SETUP_SAVINGS_STEPPER_UPDATE_MULTIPLE: {
      const multipleFields = action.payload

      return {
        ...state,
        ...multipleFields,
      }
    }

    case UPDATE_KYC_STATUS:
      return {
        ...state,
        kycStatus: action.payload,
      }

    case SETUP_SAVINGS_STEPPER_RESET:
      return initialState

    case SETUP_SAVINGS_REGISTER_CHILD:
      return {
        ...state,
        childrenInfo: [
          ...state.childrenInfo,
          {
            id: uuidv4(),
            ...action.payload,
          },
        ],
      }

    case SETUP_SAVINGS_UPDATE_CHILD: {
      const {
        payload: { id, data },
      }: any = action

      const updatedChildrenInfo = state.childrenInfo.map((info) => {
        if (info.id === id) {
          return { ...info, ...data }
        }

        return info
      })

      return { ...state, childrenInfo: updatedChildrenInfo }
    }

    case SETUP_SAVINGS_REMOVE_CHILD: {
      const { payload }: any = action
      const filteredInfo = state.childrenInfo.filter(
        (info) => info.id !== payload
      )

      return {
        ...state,
        childrenInfo: filteredInfo,
      }
    }

    default:
      return state
  }
}
