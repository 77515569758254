import React, { useState } from 'react'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import { FaTwitter } from 'react-icons/fa'
import { RiWhatsappFill } from 'react-icons/ri'
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

const ShareEvent = () => {
  const [copyLink, setCopyLink] = useState<string>('Copy')

  const shareInfo = {
    //url: `${window.location.origin}/signup?ReferralCode=${coins.referralCode}`,
    url: 'https://app.dev.sootchy.com/activation',
    title: 'Check this Event at Tuscon',
    hashtag: '#sootchyapp',
  }

  const handleCopy = () => {
    setCopyLink('Copied')
    // navigator.clipboard.writeText(
    //   `${window.location.origin}/signup?ReferralCode=${coins.referralCode}`
    // )
    navigator.clipboard.writeText(`app.dev.sootchy.com/activationa href`)
    setTimeout(() => {
      setCopyLink('Copy')
    }, 2000)
  }
  return (
    <div className="px-[32px] pb-[32px]">
      <h4 className="font-semibold text-[24px] leading-[29px] mb-[24px]">
        Share
      </h4>
      <div className="flex space-x-[18px] mb-[24px]">
        <div className={styles.inviteCopyModal.shareIcons}>
          <WhatsappShareButton
            url={shareInfo.url}
            title={shareInfo.title}
            separator=":: "
          >
            <RiWhatsappFill fontSize={20} className="text-primaryDark" />
          </WhatsappShareButton>
        </div>
        <div className={styles.inviteCopyModal.shareIcons}>
          <FacebookShareButton
            url={shareInfo.url}
            quote={shareInfo.title}
            hashtag={shareInfo.hashtag}
          >
            <BsFacebook fontSize={20} className="text-primaryDark" />
          </FacebookShareButton>
        </div>
        <div className={styles.inviteCopyModal.shareIcons}>
          <TwitterShareButton url={shareInfo.url} title={shareInfo.title}>
            <FaTwitter fontSize={20} className="text-primaryDark" />
          </TwitterShareButton>
        </div>
        <div className={styles.inviteCopyModal.shareIcons}>
          <InstapaperShareButton url={shareInfo.url} title={shareInfo.title}>
            <BsInstagram fontSize={20} className="text-primaryDark" />
          </InstapaperShareButton>
        </div>
      </div>

      <p className="mb-2 font-normal leading-[16px] uppercase text-[#8A8A8A] text-[14px]">
        Copy Link
      </p>

      <div className={styles.inviteCopyModal.border}>
        <div className="flex py-2">
          <input
            aria-label="Referral Code"
            className="bg-transparent appearance-none border-0rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-full-name"
            type="text"
            value={`app.dev.sootchy.com/tuscon`}
          />
          <button
            className="text-[14px] border-opacity-25"
            onClick={handleCopy}
          >
            {copyLink}
          </button>
        </div>
      </div>
    </div>
  )
}

const styles = {
  inviteCopyModal: {
    border:
      'grow px-[20px] rounded-[10px] items-center mb-[25px] bg-[#F2F6F6] ',
    textdiv: 'grow',
    text: 'grow h-12 bg-[#F2F6F6] outline-none mr-[20px] text-[#004F4E] font-bold pl-[5px] pr-[5px] w-[38vh]',
    btn: 'text-[14px] border-opacity-25',
    shareIcons: 'ml-1 flex items-center w-[20px] h-[20px]',
  },
}

export default ShareEvent
