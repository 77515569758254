import React from 'react'

const GetSootchyAssistance: React.FC = () => {
  const recipient = 'help@sootchy.com'
  const phoneNumber = '3236774482'

  const handleEmailButtonClick = () => {
    const mailtoLink = `mailto:${recipient}`
    window.location.href = mailtoLink
  }

  const handlePhoneButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`
  }

  const handleChatButtonClick = () => {
    window.Intercom('showNewMessage')
  }

  return (
    <>
      <div className={styles.item}>
        <div className="flex justify-between w-full">
          <div className="w-[80%]">
            <div className="w-[90%]">
              <p className="text-[1rem] leading-[24px] font-normal mb-1">
                Chat with us
              </p>
              <p className="text-[14px] text-[#727272]">
                The quickest way to contact us from Monday to Friday (7AM - 6PM)
              </p>
            </div>
          </div>
          <div className="w-[20%] flex items-center justify-end">
            <button onClick={handleChatButtonClick} className="btn btn--dark">
              Chat
            </button>
          </div>
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex justify-between w-full">
          <div className="w-[80%]">
            <div className="w-[90%]">
              <p className="text-[1rem] leading-[24px] font-normal mb-1">
                Email us (help@sootchy.com)
              </p>
              <p className="text-[14px] text-[#727272]">
                We usually reply in a few hours from Monday to Friday (7AM -
                6PM)
              </p>
            </div>
          </div>
          <div className="w-[20%] flex items-center justify-end">
            <button onClick={handleEmailButtonClick} className="btn btn--dark">
              Email
            </button>
          </div>
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex justify-between w-full">
          <div className="w-[80%]">
            <div className="w-[90%]">
              <p className="text-[1rem] leading-[24px] font-normal mb-1">
                Call us (323) 677-4482
              </p>
              <p className="text-[14px] text-[#727272]">
                We try to answer as quick as possible from Monday to Friday (7AM
                - 6PM)
              </p>
            </div>
          </div>
          <div className="w-[20%] flex items-center justify-end">
            <button onClick={handlePhoneButtonClick} className="btn btn--dark">
              Call
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const styles = {
  item: 'mb-[16px] last:mb-0  py-[16px] border-b-[2.5px] border-[#C4E8E8] border-dashed',
}

export default GetSootchyAssistance
