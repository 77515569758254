import React from 'react'
import {
  Bulb,
  Calendar,
  Cash,
  CreditCard,
  Dice,
  Graduate,
  Medal,
} from '../ImageImports'

export const SootchyFeatures = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className={styles.sootchyFeature.card}>
        <div>
          <h4 className={styles.sootchyFeature.title}>
            Unique saving platform
          </h4>
          <p className={styles.sootchyFeature.description}>
            Open a savings account in minutes and watch your money grow towards
            your savings goals.
          </p>
        </div>
        <div>
          <img src={Cash} alt="Cash" className="w-[130px] max-h-[86px]" />
        </div>
      </div>

      <div className={styles.sootchyFeature.card}>
        <div>
          <h4 className={styles.sootchyFeature.title}>Easy habit making</h4>
          <p className={styles.sootchyFeature.description}>
            Demystify the next steps you need to take in order to unlock a life
            of prosperity for your family.
          </p>
        </div>
        <div>
          <img
            src={Calendar}
            alt="Calendar"
            className="w-[97px] max-h-[86px]"
          />
        </div>
      </div>

      <div className={styles.sootchyFeature.card}>
        <div>
          <h4 className={styles.sootchyFeature.title}>
            New ways to save money
          </h4>
          <p className={styles.sootchyFeature.description}>
            Gain access to exclusive funds and opportunities that are already
            waiting for you to make the most of them.
          </p>
        </div>
        <div>
          <img src={Bulb} alt="Bulb" className="w-[130px] max-h-[86px]" />
        </div>
      </div>

      <div className={styles.sootchyFeature.card}>
        <div>
          <h4 className={styles.sootchyFeature.title}>Rewarding experience</h4>
          <p className={styles.sootchyFeature.description}>
            Tap into the power of community, locally and around the nation, in
            service of your child's future.
          </p>
        </div>
        <div>
          <img src={Medal} alt="Medal" className="w-[130px] max-h-[86px]" />
        </div>
      </div>
    </div>
  )
}

export const ActivationFeatures = () => {
  return (
    <div className="mt-[96px] grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="bg-white flex items-center rounded-lg p-[24px]">
        <img src={Dice} alt="SootchyGreenLogo" className="h-[38px]" />
        <div className="pl-4">
          <p className="font-thin leading-[32px] text-[17px]">
            Help your child learn smart money habits
          </p>
        </div>
      </div>

      <div className="bg-white flex items-center rounded-lg p-[24px]">
        <img src={CreditCard} alt="SootchyGreenLogo" className="h-[38px]" />
        <div className="pl-4">
          <p className="font-thin leading-[32px] text-[17px]">
            Open a free no-strings-attached savings account
          </p>
        </div>
      </div>

      <div className="bg-white flex items-center rounded-lg p-[24px]">
        <img src={Graduate} alt="SootchyGreenLogo" className="h-[38px]" />
        <div className="pl-4">
          <p className="font-thin leading-[32px] text-[17px]">
            Get access to funds for your child's future
          </p>
        </div>
      </div>
    </div>
  )
}

const styles = {
  sootchyFeature: {
    card: 'bg-[#EFF9F9] rounded-lg py-[32px] md:py-[38.5px] px-[32px] flex flex-col md:items-center md:flex-row space-y-12 md:space-y-0 md:space-x-12',
    title: 'text-[24px] leading-[28.8px] font-semibold',
    description: 'font-thin leading-[24px] text-[15px]',
  },
}
