import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../../api'
import Navigation from '../../Common/Navigation'
import { RootReduxStateType } from '../../../types'
import { logoutUser } from '../../../utilities/generalUtilities'

const Profile: React.FC = () => {
  // const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  // const { t } = useTranslation()

  // const getUserDetails = async () => {
  //   const res = await api.get(`/UserAccount/UserAccountID/${user.id}`)
  //   const { data } = res

  //   console.log('Data in personal maange', data)

  //   if (data.firstName.length >= 0 && data.lastName.length >= 0) {
  //     dispatch(updateProfileStatus('personalDetailsUpdated', true))
  //     dispatch(updateProfileStatus('passwordUpdated', true))

  //     if (!progress.personalDetailsUpdated) dispatch(updateProfileProgress())
  //     if (!progress.passwordUpdated) dispatch(updateProfileProgress())
  //   }

  //   if (data.emailAddress && data.telephoneNumber) {
  //     dispatch(updateProfileStatus('personalDetailsUpdated', true))
  //     if (!progress.contactsUpdated) dispatch(updateProfileProgress())
  //   }

  //   if (data.is2FAEnabled) {
  //     dispatch(updateProfileStatus('twoFAUpdated', true))
  //     if (!progress.twoFAUpdated) dispatch(updateProfileProgress())
  //   }
  // }

  const ProfileData = [
    {
      title: 'Personal details',
      path: '/profile/personal-details',
    },
    {
      title: 'Contact details',
      path: '/profile/contacts',
    },
    {
      title: 'Change password',
      path: '/profile/update-password',
    },
    // {
    //   title: 'Payment methods',
    //   path: '/settings/connected-banks/connected-banks',
    // },
    {
      title: 'Your children',
      path: '/profile/children-details',
    },
    {
      title: 'Frequently asked questions',
      path: '/help/faqs',
    },
    {
      title: 'Log-out',
      path: '/',
    },
  ]

  const getUserName = async () => {
    if (user.token) {
      setLoading(true)
      const response = await api.get(`UserAccount/UserAccountID/${user.id}`)
      if (response.status === 200) {
        setFirstName(response.data.firstName)
        setLastName(response.data.lastName)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserName()
    // getUserDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navigation>
        <div className="w-[90%] m-auto lg:w-[570px]">
          <div className="my-[32px]">
            <h3 className="font-semibold text-[18px] leading-[21.6px] mb-2 text-primaryDark">
              Profile & Settings
            </h3>
            {loading ? (
              <div className="w-[50%] rounded-md h-4 bg-slate-200 animate-pulse"></div>
            ) : (
              <p className="text-[14px] leading-[16.8px] capitalize">
                This is your profile, {`${firstName} ${lastName}`}.
              </p>
            )}
          </div>
          <div className="pb-[10px] ">
            {ProfileData.map((profile, index) => (
              <div
                key={index}
                className={`py-[20px] cursor-pointer border-b-[2.5px] border-[#C4E8E8] border-dashed`}
                //onClick={() => navigate(`${profile.path}`)}
                onClick={
                  profile.title === 'Log-out'
                    ? () => logoutUser(user.id)
                    : () => navigate(`${profile.path}`)
                }
              >
                <div className="flex justify-between ">
                  <h3
                    className={`font-normal text-[16px] leading-[19.2px] pb-[8px] ${
                      profile.title === 'Log-out'
                        ? 'text-red-500'
                        : ' text-primaryDark'
                    }`}
                  >
                    {profile.title}
                  </h3>

                  <img
                    onClick={() => navigate(`${profile.path}`)}
                    src="/assets/images/arrow-down.svg"
                    alt="arrow right"
                    className="transform -rotate-90 mr-[18px]"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="py-[20px]">
            <ul className="text-[14px] text-primaryDark underline">
              {/* <li className="pb-4">
                <Link to={'/'}>Sootchy en español</Link>
              </li> */}
              <li className="pb-4">
                <Link to={'/help/assitance'}>Assistance</Link>
              </li>
              <li className="pb-4">
                <Link to={'/legal'}>Legal</Link>
              </li>
            </ul>
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default Profile
