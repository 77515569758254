import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

type PropsType = {
  value: string
  onChange: (x: any) => void
  name?: 'telephone' | 'phoneNumber'
  disabled?: boolean
  placeholder?: string
  onMount?: (phoneNumber: string) => void
}

const TelephoneNumber = ({
  value,
  onChange,
  disabled,
  name = 'telephone',
  placeholder = 'Phone Number',
  onMount = () => null,
  ...props
}: PropsType) => {
  return (
    <PhoneInput
      onMount={onMount}
      autocompleteSearch={false}
      country={'us'}
      value={value}
      onChange={onChange}
      onlyCountries={['us']}
      disableDropdown
      prefix="+"
      placeholder={placeholder}
      countryCodeEditable={false}
      disabled={disabled ? true : false}
      inputProps={{
        name: name,
        required: true,
        autoFocus: false,
        autoComplete: 'new-password',
      }}
    />
  )
}

export default TelephoneNumber
