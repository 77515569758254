import React from 'react'
// @ts-ignore
import WhiteInfoIcon from '../../assets/white-info.png'
// @ts-ignore
import BlueInfoIcon from '../../assets/blue-info.png'
// @ts-ignore
import GreenCheckIcon from '../../assets/green-check.png'

type PropsType = {
  styles: 'primary' | 'secondary' | 'plain' | 'error'
  description: string
  className?: string
}

const Toast: React.FC<PropsType> = ({ styles, description, className }) => {
  const displayToast = () => {
    switch (styles) {
      case 'primary':
        return (
          <div
            className={`w-[95%] m-auto bg-[#00B2FF] flex p-4 rounded-2xl ${className}`}
          >
            <img
              src={WhiteInfoIcon}
              alt="info-icon"
              className="w-[22px] h-[22px]"
            />
            <p className="text-white px-3 text-[15px]">{description}</p>
          </div>
        )

      case 'secondary':
        return (
          <div
            className={`w-[95%] m-auto bg-white flex p-4 rounded-2xl ${className}`}
            style={{ boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)' }}
          >
            <img
              src={BlueInfoIcon}
              alt="info-icon"
              className="w-[22px] h-[22px]"
            />
            <p className="px-3 text-[15px] text-black">{description}</p>
          </div>
        )

      case 'plain':
        return (
          <div
            className={`w-[95%] m-auto bg-white flex p-4 rounded-2xl ${className}`}
            style={{ boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)' }}
          >
            <img
              src={GreenCheckIcon}
              alt="green-check-icon"
              className="w-[22px] h-[22px]"
            />
            <p className="px-3 text-[15px] text-black">{description}</p>
          </div>
        )

      case 'error':
        return (
          <div
            className={`w-[95%] m-auto bg-white flex p-4 rounded-2xl ${className}`}
            style={{ boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)' }}
          >
            <img
              src="/assets/images/error_icon.svg"
              alt="green-check-icon"
              className="w-[22px] h-[22px]"
            />
            <p className="px-3 text-[15px] text-black">{description}</p>
          </div>
        )

      default:
        return <></>
    }
  }

  return displayToast()
}

export default Toast
