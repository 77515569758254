import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  handleConfirmationDisplay,
  handleConfirmationOptions,
  handleTransactionPrompt,
  handleTransactionWidgetDisplay,
} from '../../../redux/actions/transactionsActions'

type Proptypes = {
  insufficientFunds: boolean
  noBankConnected: boolean
}

const TransactionNotAllowed = ({
  insufficientFunds,
  noBankConnected,
}: Proptypes) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className="w-full space-y-4">
      {insufficientFunds && (
        <>
          <h3 className="py-4 text-[24px] font-semibold text-textGreen">
            Insufficient Funds
          </h3>
          <p>Please add funds to continue with the transaction</p>
        </>
      )}

      {noBankConnected && (
        <>
          <h3 className="py-4 text-[24px] font-semibold text-textGreen">
            No banks Connected
          </h3>
          <p>Connect a bank to your account inorder to be able to transact.</p>
        </>
      )}
      <div className="flex justify-between py-4 border-t-2 border-dashed border-[#C4E8E8] self-center bg-white w-full sm:relative sm:w-full">
        <button
          className="w-[70%] mx-1 py-4  border-2 border-[#E3F2F2]"
          onClick={() => {
            dispatch(
              handleConfirmationOptions({
                handleChange: false,
                handleCancel: true,
                handleConfirm: false,
              })
            )
            dispatch(handleConfirmationDisplay(false))
            dispatch(handleTransactionPrompt(null))
            dispatch(
              handleTransactionWidgetDisplay({
                showTransactionModal: false,
                showTransactionSlideUp: false,
              })
            )
          }}
        >
          Cancel
        </button>

        {insufficientFunds && (
          <button
            className="w-[70%] mx-1 py-4  border-2 border-[#E3F2F2]"
            onClick={() => {
              dispatch(
                handleTransactionWidgetDisplay({
                  showTransactionModal: true,
                  showTransactionSlideUp: true,
                })
              )
              dispatch(handleConfirmationDisplay(false))
              dispatch(handleTransactionPrompt('ADD'))
            }}
          >
            Add Money
          </button>
        )}

        {noBankConnected && (
          <button
            className="w-[70%] mx-1 py-4  border-2 border-[#E3F2F2]"
            onClick={() => {
              dispatch(
                handleTransactionWidgetDisplay({
                  showTransactionModal: false,
                  showTransactionSlideUp: false,
                })
              )
              //dispatch(handleConfirmationDisplay(false))
              dispatch(handleTransactionPrompt('null'))
              navigate('/settings/connected-banks/connected-banks')
            }}
          >
            Connect Bank
          </button>
        )}
      </div>
    </div>
  )
}

export default TransactionNotAllowed
