import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  increamentMy529Step,
  setupMy529Update,
  setupMy529UpdateMultiple,
} from '../../../../redux/actions/my529Actions'
import {
  getUser,
  updateEmail,
  updateUser,
  verifyEmailAPI,
} from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user'
import { formatPhoneNumberToSave } from '../../../../utilities/generalUtilities'
import DateInput from '../../../Common/DateInput/DateInput'
import { LoadingSpinner } from '../../../Common/Loading'
import SafeAreaView from '../../../Common/SafeAreaView'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import Toast from '../../../Common/Toast'
import VerificationModal from '../../../Common/VerificationModal'
import My529SetupBottom from '../My529SetupBottom'

const ReviewStep = () => {
  const dispatch = useDispatch()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loading, setLoading] = useState<boolean>(true)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [loadedUser, setLoadedUser] = useState<UserAPIType | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [emailChangeModal, setEmailChangeModal] = useState<boolean>(false)
  const [showToast, setShowToast] = useState<boolean>(false)

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    key === 'email' && setShowToast(true)

    dispatch(setupMy529Update(key, value))
  }

  const loadUserData = async () => {
    getUser(user.id)
      .then((response) => {
        if (response.status === 200) {
          setLoadedUser(response.data)
          dispatch(
            setupMy529UpdateMultiple({
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              dateOfBirth: dayjs(response.data.dateOfBirth).format(
                'YYYY-MM-DD'
              ),
              phoneNumber: response.data.telephoneNumber,
              email: response.data.emailAddress,
            })
          )
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
        alert('Unable to retrive user data, from  - loadUserData')
      })
  }

  const handleNext = async () => {
    if (submitLoading) return
    if (loadedUser) {
      setSubmitLoading(true)
      updateUser({
        UserAccountID: parseInt(user.id),
        FirstName: my529.firstName,
        LastName: my529.lastName,
        DateOfBirth: my529.dateOfBirth,
        TelephoneNumber: my529.phoneNumber,
        EmailAddress: my529.email,
        ModifyingUserAccountID: parseInt(user.id),
        SourceProcess: 'WebApplication',
        ChangeReason: 'Update User',
      })
        .then((response) => {
          if (response.success) {
            if (my529.email !== loadedUser.emailAddress) verifyEmailAPI()

            getUser(user.id).then((response) => {
              if (!response.data.emailAddressIsVerified) {
                setShowModal(true)
                setSubmitLoading(false)
                return
              }

              dispatch(increamentMy529Step())
              setSubmitLoading(false)
            })
          }
        })
        .catch(() => {
          setSubmitLoading(false)
          alert('Unable to update user')
        })
    }
  }

  const handleUpdateEmail = async () => {
    setLoading(true)

    const response = await updateEmail(user.id, my529.email ?? '')
    if (response.success) {
      const response = await verifyEmailAPI()

      if (response.success) {
        setLoading(false)
        setEmailChangeModal(false)
        setShowModal(true)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    loadUserData()
    dispatch(setupMy529Update('video', null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {showModal && (
        <VerificationModal
          title="Check your email"
          description={`To start using this account, you first need to verify your email address ${
            my529.email ? my529.email : loadedUser?.emailAddress
          }`}
          showButton={true}
          buttonText="Gotcha!"
          handleOnClick={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
          emailVerification
          handleParentModel={() => setEmailChangeModal(true)}
        />
      )}

      {emailChangeModal && (
        <VerificationModal
          title="Change your email"
          showButton={true}
          showSecondaryButton={true}
          showSecondaryButtonText="cancel"
          showInput={true}
          showInputText={'Email'}
          showInputValue={my529.email ?? ''}
          onChangeInput={(e: any) =>
            dispatch(setupMy529Update('email', e.target.value))
          }
          buttonText="Change"
          handleOnClick={handleUpdateEmail}
          onClose={() => setEmailChangeModal(false)}
          loading={loading}
        />
      )}

      <SafeAreaView>
        <p>
          Make sure the details below are accurate. We will use this information
          to verify your identity
        </p>

        {loading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingSpinner color="#004F4E" />
          </div>
        ) : (
          <div className="py-4">
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="firstName"
                aria-label="firstName"
                value={my529.firstName ? my529.firstName : undefined}
              />
              <label>First name</label>
            </div>

            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="lastName"
                aria-label="lastName"
                value={my529.lastName ? my529.lastName : undefined}
              />
              <label>Last name</label>
            </div>

            <div className="py-2">
              <DateInput
                dateValue={dayjs(my529.dateOfBirth).format('YYYY-MM-DD')}
                onChange={(date: any) =>
                  dispatch(
                    setupMy529Update(
                      'dateOfBirth',
                      dayjs(date).format('YYYY-MM-DD')
                    )
                  )
                }
              />
            </div>

            <div className="py-2">
              <TelephoneNumber
                onChange={(telephoneNumber) =>
                  dispatch(
                    setupMy529Update(
                      'phoneNumber',
                      formatPhoneNumberToSave(telephoneNumber)
                    )
                  )
                }
                value={my529.phoneNumber ?? ''}
              />
            </div>

            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="email"
                aria-label="email"
                value={my529.email ?? ''}
              />
              <label>Email</label>
            </div>

            {showToast && (
              <div className=" transition-opacity duration-2s ease-in-out">
                <Toast
                  styles="primary"
                  description="Note: upon changing the email, we’ll send you an email with a confirmation link to make sure the email provided is correct and belongs to you."
                  className="my-5"
                />
              </div>
            )}
          </div>
        )}
      </SafeAreaView>

      <My529SetupBottom
        handleNext={handleNext}
        disabled={loadedUser ? false : true}
        loading={submitLoading}
      />
    </div>
  )
}

export default ReviewStep
