import { ReduxActionType } from '../../types'
import {
  CONNECT_BANK,
  REPEAT_TRANSACTION,
  RESET_CONFIRMATION_DIALOG,
  RESET_CONFIRMATION_DIALOG_DATA,
  SHOW_CONFIRMATION_DIALOG,
  SHOW_CONFIRMATION_DIALOG_DATA,
  SHOW_CONFIRMATION_DIALOG_OPTIONS,
  SHOW_SLIDE_UP,
  SHOW_TRANSACTION_MODAL,
  SHOW_TRANSACTION_SLIDEUP,
  TRANSACTIONS_WIDGET,
  UPDATE_TRANSACTION_PROMPT,
} from '../actions/action.types'

type InitialStateType = {
  transactionPrompt: string | null
  modals: {
    transactionWidgets: {
      showTransactionModal: boolean
      showTransactionSlideUp: boolean
    }
    showDialogModal: boolean // Yet to be implemented
    showConfirmationModal: boolean // Yet to be implemented
  }
  isBankConnect: boolean
  showDialog: boolean
  showSlideUp: boolean
  isRepeatTransaction: boolean
  confirmationDialogOptions: {
    handleChange: boolean
    handleCancel: boolean
    handleConfirm: boolean
  }
  confirmationDialogData: {
    transactionType: string
    sender: string
    recipient: string
    amount: number
  }

  // short term-fix
}

const initialState: InitialStateType = {
  transactionPrompt: null,
  modals: {
    transactionWidgets: {
      showTransactionModal: false,
      showTransactionSlideUp: false,
    },
    showDialogModal: false,
    showConfirmationModal: false,
  },
  isBankConnect: false,
  showDialog: false,
  showSlideUp: false,
  isRepeatTransaction: false,
  confirmationDialogOptions: {
    handleChange: false,
    handleCancel: false,
    handleConfirm: false,
  },
  confirmationDialogData: {
    transactionType: '',
    sender: '',
    recipient: '',
    amount: 0,
  },
}

export const transactionsReducer = (
  state = initialState,
  action: ReduxActionType<InitialStateType>
) => {
  switch (action.type) {
    case UPDATE_TRANSACTION_PROMPT:
      return {
        ...state,
        transactionPrompt: action.payload,
      }
    case SHOW_CONFIRMATION_DIALOG:
      return {
        ...state,
        //showDialog: action.payload,
        modals: {
          ...state.modals,
          showDialogModal: action.payload,
        },
      }

    case SHOW_SLIDE_UP:
      return {
        ...state,
        showSlideUp: action.payload,
      }

    case SHOW_CONFIRMATION_DIALOG_OPTIONS:
      return {
        ...state,
        confirmationDialogOptions: action.payload,
      }

    case SHOW_CONFIRMATION_DIALOG_DATA:
      return {
        ...state,
        confirmationDialogData: action.payload,
      }

    case CONNECT_BANK:
      return {
        ...state,
        isBankConnect: action.payload,
      }

    case SHOW_TRANSACTION_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          showTransactionModal: action.payload,
        },
      }

    case SHOW_TRANSACTION_SLIDEUP:
      return {
        ...state,
        modals: {
          ...state.modals,
          showTransactionSlideUp: action.payload,
        },
      }

    case REPEAT_TRANSACTION: {
      return {
        ...state,
        isRepeatTransaction: action.payload,
      }
    }

    case TRANSACTIONS_WIDGET: {
      return {
        ...state,
        modals: {
          ...state.modals,
          transactionWidgets: action.payload,
        },
      }
    }

    case RESET_CONFIRMATION_DIALOG:
      return {
        ...state,
        confirmationDialogOptions: state.confirmationDialogOptions,
      }

    case RESET_CONFIRMATION_DIALOG_DATA:
      return {
        ...state,
        confirmationDialogData: state.confirmationDialogData,
      }

    default:
      return state
  }
}
