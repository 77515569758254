import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import SlideUp from '../../../../Common/SlideUp'
import {
  BankAccount,
  linkTokenAccess,
  PlaidPublicMetadata,
  publicTokenAccess,
} from '../../../../../redux/actions/linkActions'
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'
import { useDispatch, useSelector } from 'react-redux'
import { getLinkTokenFromStore } from '../../../../../api'

type Proptypes = {
  isActive: boolean
  handleIsActiveChange: (e: any) => void
}

const ConnectingBank = ({ handleIsActiveChange }: Proptypes) => {
  const dispatch = useDispatch()
  const [showConnectBankModal, setShowConnectBankModal] = useState(true)
  const linkToken = useSelector((state) => getLinkTokenFromStore())

  const isMobile = useMediaQuery('(max-width: 767px)')

  // Connect to Plaid
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    // Make the connect bank component disappear before calling the API.
    // If API fails, show the state again.
    (publicToken, metadata: any) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      console.log(publicToken, metadata)

      const accounts: Array<BankAccount> = []
      metadata.accounts.forEach((account: any) => {
        let temp: BankAccount = {
          Id: account.id ? account.id : 'NoInfoProvided',
          Name: account.name ? account.name : 'NoInfoProvided',
          Mask: account.mask ? account.mask : 'NoInfoProvided',
          AccountType: account.type ? account.type : 'NoInfoProvided',
          AccountSubType: account.subtype ? account.subtype : 'NoInfoProvided',
        }
        accounts.push(temp)
      })
      const data: PlaidPublicMetadata = {
        PublicToken: publicToken,
        PlaidAccountID: metadata?.account_id,
        BankName: metadata?.institution?.name,
        BankID: metadata?.institution?.institution_id,
        Accounts: accounts,
      }

      console.log('Bank Connected succcessfully')
      //setIsBankConnected(true)
      dispatch(publicTokenAccess(data))
      //window.location.reload()
    },
    []
  )

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    onSuccess,
  }
  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    if (!open) {
      setShowConnectBankModal(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    dispatch(linkTokenAccess())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayComponent = () => {
    return (
      <div className="space-y-4 mb-4">
        <h3 className="py-4 text-[24px] font-semibold text-textGreen">
          Connect your bank
        </h3>
        <p>
          Connect your bank to begin adding funds. This should only take a few
          minutes.
        </p>
        <p>
          We will take you to a secure environment. Your data will always be
          safe with us.
        </p>

        <div className="flex justify-between py-4 border-t-2 border-dashed border-[#C4E8E8] self-center bg-white w-full sm:relative sm:w-full">
          <button
            className="w-[70%] mx-1 py-4  border-2 border-[#E3F2F2]"
            onClick={handleIsActiveChange}
          >
            Cancel
          </button>

          <button
            className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
            //onClick={() => dispatch(handleConnectBank(true))}
            onClick={() => {
              setShowConnectBankModal(false)
              open()
            }}
          >
            Connect Bank
          </button>
        </div>
      </div>
    )
  }

  if (showConnectBankModal) {
    return (
      <div className="fixed h-full w-screen z-50 top-0 left-0">
        <div className="absolute bg-black opacity-80 h-screen w-screen"></div>

        {isMobile ? (
          <div className="absolute w-full md:w-[570px] bottom-0 inset-x-0 mx-auto transform transition ease-in-out duration-300">
            <div className="bg-white shadow shadow-gray-400/50 rounded-t-[10px] p-[20px] z-40">
              <SlideUp>{displayComponent()}</SlideUp>
            </div>
          </div>
        ) : (
          <div
            className="absolute bg-white w-full md:w-[570px] ease-in-out duration-300 transition-transform rounded-xl py-6 px-6"
            style={{
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {displayComponent()}
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default ConnectingBank
