import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setupSavingsStepperUpdate } from '../../../../../../redux/actions/setupSavingsStepperActions'
import {
  getUser,
  updateEmail,
  verifyEmailAPI,
} from '../../../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../../../types'
import { UserAPIType } from '../../../../../../types/user'
import Toast from '../../../../../Common/Toast'
import VerificationModal from '../../../../../Common/VerificationModal'
import NewFooter from '../../../../../Common/Partials/NewFooter'
import SetupSavingsStepperOne from '../../../Step1'
import SetupSavingsStepperTwo from '../../../Step2'
import SetupSavingsStepperThree from '../../../Step3'
import SetupSavingsStepperFour from '../../../Step4'
import SetupSavingsStepperFive from '../../../Step5'
// @ts-ignore
import { AzureMP } from 'react-azure-mp'
// @ts-ignore
import SootchyGreenLogo from '../../../../../../assets/SootchyGreenLogo.png'
// @ts-ignore
import CloseIcon from '../../../../../../assets/closeIcon.png'

const UpdateSavingsAccount = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setupSavingsStepper = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper
  )
  const user = useSelector((state: RootReduxStateType) => state.user)

  const [showModal, setShowModal] = useState(false)
  const [emailChangeModal, setEmailChangeModal] = useState(false)
  const [emailChangeSuccess, setEmailChangeSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadedUser, setLoadedUser] = useState<UserAPIType>()

  const progress = (setupSavingsStepper.step / 6) * 100

  // Modified steps layout due to changes needed from Sila
  const displayStepComponent = () => {
    switch (setupSavingsStepper.step) {
      case 1:
        // Initial Setup screen
        return <SetupSavingsStepperOne setShowModal={setShowModal} />

      case 2:
        // Confirm information page
        return <SetupSavingsStepperTwo setShowModal={setShowModal} />

      case 3:
        // Request Phone number and Address page
        return <SetupSavingsStepperThree setShowModal={setShowModal} />

      case 4:
        // Social Security page
        return <SetupSavingsStepperFour />

      case 5:
        // Children info page
        return <SetupSavingsStepperFive />

      // NOTE: SetupSavingsStepperSix -> has been removed due to a recent design change

      default:
        return <SetupSavingsStepperOne setShowModal={setShowModal} />
    }
  }

  const handleClose = () => {
    navigate('/wallet')
  }

  const handleOnChange = (value: string) => {
    dispatch(setupSavingsStepperUpdate('email', value))
  }

  const handleUpdateEmail = async () => {
    setLoading(true)

    const response = await updateEmail(user.id, setupSavingsStepper.email ?? '')
    if (response.success) {
      const response = await verifyEmailAPI()

      if (response.success) {
        setLoading(false)
        setEmailChangeModal(false)
        setShowModal(true)
        setEmailChangeSuccess(true)

        setTimeout(() => {
          setEmailChangeSuccess(false)
        }, 5000)
      }
    }

    setLoading(false)
  }

  const getUserInfo = async () => {
    setLoading(true)
    const status: boolean = 'true' === localStorage.getItem('isKycFailed')
    const response = await getUser(user.id)
    if (response.status === 200) {
      if (response.data.silaUserAccountID !== null && !status) {
        navigate('/wallet')
      }
      setLoadedUser(response.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let alpaca = {
    video: null, //'https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/d2283693-d020-46af-827d-0f56e6c83005/CraigFootage1.ism/manifest',
  }

  return (
    <div>
      <div className="bg-[#E3F2F2] hidden sm:block">
        <div className="flex justify-between items-center sm:w-[450px] m-auto py-10">
          <img
            src={SootchyGreenLogo}
            alt="Sootchy-logo"
            className="w-[200px]"
          />
          <p
            className="text-primaryDark underline cursor-pointer"
            onClick={() => navigate('/')}
          >
            Back to website
          </p>
        </div>
      </div>
      <div className="h-full w-full sm:bg-[#E3F2F2] flex justify-center items-center sm:min-h-screen">
        <div className="m-auto w-full bg-white sm:w-[450px] sm:shadow-primaryLight sm:shadow-md sm:py-3 sm:px-5 sm:rounded-xl">
          {showModal && (
            <VerificationModal
              title="Check your email"
              description={`To start using this account, you first need to verify your email address ${
                setupSavingsStepper.email
                  ? setupSavingsStepper.email
                  : loadedUser?.emailAddress
              }`}
              showButton={true}
              buttonText="Gotcha!"
              handleOnClick={() => setShowModal(false)}
              onClose={() => setShowModal(false)}
              emailVerification
              handleParentModel={() => setEmailChangeModal(true)}
            />
          )}

          {emailChangeModal && (
            <VerificationModal
              title="Change your email"
              showButton={true}
              showSecondaryButton={true}
              showSecondaryButtonText="cancel"
              showInput={true}
              showInputText={'Email'}
              showInputValue={
                setupSavingsStepper.email
                  ? setupSavingsStepper.email
                  : loadedUser?.emailAddress
              }
              onChangeInput={(e: any) => handleOnChange(e.target.value)}
              buttonText="Change"
              handleOnClick={handleUpdateEmail}
              onClose={() => setEmailChangeModal(false)}
              loading={loading}
            />
          )}

          {emailChangeSuccess && (
            <div className="absolute z-50 w-full top-5 transition-all">
              <Toast styles="plain" description="Your email has been changed" />
            </div>
          )}

          <div className="shadow-md py-5 md:shadow-none">
            <div className="w-[90%] m-auto">
              <div className="flex justify-between items-center pb-4 w-full m-auto">
                <p className="text-[18px] font-semibold text-primaryDark">
                  Set-up Savings
                </p>
                <img
                  src={CloseIcon}
                  alt="close icon"
                  className="w-[13px] h-[13px] cursor-pointer"
                  onClick={handleClose}
                />
              </div>

              <div className="relative w-full m-auto">
                <div className="w-[100%] bg-[#E2FFFF] h-[19px] rounded-xl  cursor-pointer"></div>
                <div
                  className={`absolute bg-[#7FE5E4] h-[19px] rounded-xl top-0 transition-all cursor-pointer shadow-lg`}
                  style={{
                    width: `${progress}%`,
                  }}
                ></div>
              </div>

              {alpaca.video && (
                <div className="w-[90%] m-auto mt-5 md:hidden">
                  <AzureMP
                    skin="amp-flush"
                    src={[
                      {
                        src: `${alpaca.video}`,
                        type: 'application/vnd.ms-sstr+xml',
                      },
                    ]}
                    options={{ controls: true, autoplay: false }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-[90%] m-auto">{displayStepComponent()}</div>
        </div>
      </div>
      <div className="hidden sm:block">
        <NewFooter />
      </div>
    </div>
  )
}

export default UpdateSavingsAccount
