import React from 'react'
import {
  BGCA,
  Facebook,
  Instagram,
  SootchyGreenLogo,
  TikTok,
  TPG,
  Twitter,
} from '../ImageImports'

const ActivationFooter = () => {
  return (
    <div className="container mx-auto">
      <div className="block md:flex md:justify-between mb-[97px]">
        <div className="mb-[24px] w-[336px] md:w-[86%] md:mb-0">
          <h4 className="text-[24px] leading-[28.8px] font-semibold mb-[12px]">
            Questions?
          </h4>
          <p className="font-thin leading-[24px] text-[14px]">
            Contact us at help@sootchy.com or (323) 677-4482 <br /> We are
            available 9:00AM to 6:00PM (Monday to Friday)
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <img src={Twitter} alt="Twitter" className="h-[24px]" />
          <img src={TikTok} alt="Tiktok" className="h-[24px]" />
          <img src={Facebook} alt="Facebook" className="h-[24px]" />
          <img src={Instagram} alt="Instagram" className="h-[24px]" />
        </div>
      </div>

      <div>
        <div className="block md:flex md:justify-between mb-[18px]">
          <img
            src={SootchyGreenLogo}
            alt="SootchyGreenLogo"
            className="h-[36px]"
          />

          <div className="mt-[24px] md:mt-0 flex items-center space-x-4">
            <p className="font-thin leading-[15.6px] text-[14px]">Partners:</p>
            <img src={TPG} alt="TPG" className="h-[36px]" />
            <img
              src={BGCA}
              alt="Boys and Girls Clubs of America"
              className="h-[36px]"
            />
          </div>
        </div>
        <p className="font-thin leading-[15.6px] text-[14px]">
          © 2023 Sootchy, Inc. All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default ActivationFooter
