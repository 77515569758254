import { sha512 } from 'crypto-hash'
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import { trackLogoutEvent } from '../amplitude/events'

window.intercomSettings = {
  app_id: process.env.REACT_APP_INTERCOM_ID,
  alignment: 'right',
  horizontal_padding: 20,
  vertical_padding: 20,
}

// Declaring Window Object Globally with Intercom properties
declare global {
  interface Window {
    intercomSettings: any
    Intercom: any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    fbAsyncInit: any
    fblogin: any
  }
}

export const getUserFromToken: (token: string | any) => {
  UserName: string
  UserAccountID: string
  is2FAEnabled: string
  DateOfBirth: string
} = (token: string) => {
  try {
    const user = jwtDecode<any>(token)
    return user
  } catch (err) {
    return false
  }
}

export const getUserAge = (birthDate: string) => {
  const currentDate = dayjs()
  const ageInYears = currentDate.diff(birthDate, 'year')

  return ageInYears
}

export const logoutUser = (userId?: string) => {
  trackLogoutEvent({ userId: userId ?? '' })
  localStorage.clear()
  window.location.href = '/login'
  return false
}

export const getHashedPassword = async (plainPassword: string) => {
  const salt = process.env.REACT_APP_API_SALT
  const hash1 = await sha512(plainPassword + salt)
  const hash2 = await sha512(hash1 + salt)
  const hash3 = await sha512(hash2 + salt)
  return hash3
}

export const showIntercom = () => {
  if (typeof window !== 'undefined') {
    window.Intercom('show')
  }
}

export const formatTime: (duration: number) => string = (duration) => {
  let hours = ~~(duration / 3600)
  let minutes = ~~((duration % 3600) / 60)
  let seconds = ~~duration % 60

  let time = ''

  if (hours > 0) {
    time += '' + hours + ':' + (minutes < 10 ? '0' : '')
  }

  time += '' + minutes + ':' + (seconds < 10 ? '0' : '')
  time += '' + seconds
  return time
}

export const validdateSsn = (value: string) =>
  value &&
  /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(value)
    ? true
    : false

export const formatSSN = (value: string) => {
  const ssn = value.replace(/[^\d]/g, '')
  const ssnLength = ssn.length

  if (ssnLength < 4) return ssn

  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
  }

  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/\w+@\w+/)
}

export const validatePhoneNumber = (phoneNumber: string) => {
  return (
    phoneNumber.length > 10 &&
    String(phoneNumber).match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    )
  )
}

export const validateZipCode = (code: string) => {
  return /^\d{5}(-\d{4})?$/.test(code)
}

// export const formatPhoneNumberToSave = (phone: string) => {
//   let arr = ['+1', phone.substring(1)]
//   return arr.join(' ')
// }
export const formatPhoneNumberToSave = (phoneNumber: string) => {
  if (phoneNumber.startsWith('+1 ')) {
    return phoneNumber.substring(3)
  } else if (phoneNumber.startsWith('1')) {
    return `+1 ${phoneNumber.substring(1)}`
  } else {
    return phoneNumber
  }
}

export const calculateUserAge = (date: string) => {
  let birthDate = date
  let now = dayjs(new Date())

  const result = now.diff(birthDate, 'years')
  return result
}
