import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
import { AzureMP } from 'react-azure-mp'
import { LoadingSpinner } from '../../Common/Loading'
import { SlideType } from '../../Common/Partials/Slider'
import Quiz from '../quiz'
// @ts-ignore
import { getFinancialLiteracyPost } from '../../../redux/actions/financialLiteracyActions'
import Navigation from '../../Common/Navigation'

type ContentType = {
  Type: string
  TextAttribute: string
  Value: string
  ListType: string
  Values: [string]
}

const Post = () => {
  const navigate = useNavigate()
  const [content, setContent] = useState<[] | any>([])
  //const [metaData, setMetaData] = useState<null | any>(null)
  const [quizData, setQuizData] = useState(null)
  //const [authorData, setAuthorData] = useState<AuthorType | null>(null)
  const [contentID, setContentID] = useState<number>()
  //const [numberOfLessons, setNumberOfLessons] = useState<number>()
  const [nextContentInfo, setNextContentInfo] = useState<SlideType[] | null>(
    null
  )
  const [loading, setLoading] = useState(false)
  const params = useParams()

  // const shareInfo = {
  //   url: window.location.href,
  //   title: t('FinancialLiteracy.ShareTItle'),
  //   hashtag: '#sootchyapp',
  // }

  useEffect(() => {
    if (params.id) {
      getPost(params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentID, params.id])

  const getPost = async (contentID: string) => {
    setLoading(true)
    const res: any = await getFinancialLiteracyPost(contentID)
    console.log('Post Details', typeof res.contentMeta.contentID)
    setContent(res.content.Content)
    setContentID(res.contentMeta.contentID)
    //setMetaData(res.contentMeta)
    setQuizData(res.content.Quiz)
    //setAuthorData(res.authorData)
    setNextContentInfo(res.nextContentInfo)
    setLoading(false)
  }

  const displayOtherContent = () => {
    if (content) {
      const animatedVideoContent = content.find(
        (_content: ContentType) => _content.Type === 'vimeo'
      )

      if (animatedVideoContent) {
        // Display animated video if available
        return (
          <div className="flex justify-center mb-4">
            <iframe
              title="IFrame"
              className="w-full h-[300px]"
              src={`${animatedVideoContent.Value}?h=4fa278fc54&title=0&byline=0&portrait=0`}
              width="100%"
              //height="360"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>

            {/* <AzureMP
              skin="amp-flush"
              src={[{ src: animatedVideoContent.Value, type: 'video/mp4' }]}
              options={{ controls: true, autoplay: false }}
            /> */}
          </div>
        )
      } else {
        const videoContent = content.find(
          (_content: ContentType) => _content.Type === 'vimeo'
        )

        if (videoContent) {
          // Display regular video if animated video is not available
          return (
            <div className="flex justify-center mb-4">
              <iframe
                title="IFrame"
                className="w-full h-[300px]"
                src={`${animatedVideoContent.Value}?h=4fa278fc54&title=0&byline=0&portrait=0`}
                width="100%"
                //height="360"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* <AzureMP
                skin="amp-flush"
                src={[{ src: videoContent.Value, type: 'video/mp4' }]}
                options={{ controls: true, autoplay: false }}
              /> */}
            </div>
          )
        }
      }
    }

    return null // Return null if no video content is available
  }

  return (
    <>
      <Navigation>
        {loading ? (
          <PostSkeleton />
        ) : (
          <>
            <div className="max-w-[970px] m-auto py-4">
              <div className="flex justify-between">
                <button
                  type="button"
                  className={`btn flex items-center justify-center mr-[20px]  h-[36px] border-[#C4E8E8] border-2`}
                  //onClick={handleBackButton}
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={`/assets/images/back.svg`}
                    alt="back"
                    className="block text-primaryDark"
                  />
                </button>
                <h1 className="text-[1.125rem] text-primaryDark font-semibold flex items-center">
                  Lesson {contentID}
                </h1>
                <button
                  type="button"
                  className={`btn flex items-center justify-center mr-[20px]  h-[36px] border-[#C4E8E8] border-2`}
                  onClick={() => {
                    console.log('Content', nextContentInfo?.length)
                    navigate(
                      nextContentInfo?.length === 0
                        ? `/money-academy/post/1`
                        : `/money-academy/post/${
                            nextContentInfo ? nextContentInfo[0].contentID : 0
                          }`
                    )
                  }}
                >
                  {nextContentInfo?.length === 0 ? 'Start over' : 'Next'}
                </button>
              </div>
            </div>

            <div className="max-w-[970px] m-auto py-4">
              <div className="flex flex-col  m-auto lg:flex-row">
                <div className="w-full">{displayOtherContent()}</div>
                <div className="lg:mx-5 lg:w-[700px]">
                  {quizData && (
                    <Quiz
                      quizData={quizData}
                      contentID={contentID?.toString()}
                      finalContent={
                        nextContentInfo?.length === 0 ? true : false
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Navigation>
    </>
  )
}

export default Post

const PostSkeleton = () => {
  return (
    <div className="w-[90%] md:w-[570px] m-auto my-[2rem]">
      <div className="flex justify-center">
        <LoadingSpinner color="#004F4E" size={8} />
      </div>
    </div>
  )
}
