import React from 'react'
import Navigation from '../components/Common/Navigation'

const LegalPage: React.FC = () => {
  return (
    <Navigation>
      <div className="w-[90%] md:w-[570px] m-auto ">
        <div className="mt-20">
          <a
            href="https://www.sootchysecurities.com/legal-documents/sootchy-terms-of-use"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Click here to view Terms of Use
          </a>
          <br />
          <a
            href="https://www.sootchysecurities.com/legal-documents/sootchy-privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Click here to view Privacy Policy
          </a>
          <br />
          <a
            href="https://www.sootchysecurities.com/legal-documents/cookie-policy"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Click here to view Cookie Policy
          </a>
          <br />
          {/* <a
            href="https://www.sootchy.com/legal-documents/customer-account-agreement-disclosures-and-consent"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Disclosure and Consent
          </a>
          <br />
          <a
            href="https://www.sootchy.com/evolve-customer-account-agreement"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Evolve Customer Account Agreement
          </a>
          <br />
          <a
            href="https://www.silamoney.com/legal/evolve-electronic-communications-consent"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Evolve e-communications consent.
          </a>
          <br />

          <a
            href="https://www.sootchy.com/legal-documents/additional-required-disclosures"
            target="_blank"
            rel="noreferrer"
            className="text-primaryDark underline py-1 inline-block"
          >
            Additional Required Disclosure
          </a> */}
        </div>
      </div>
    </Navigation>
  )
}

export default LegalPage
