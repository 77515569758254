import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import Navigation from '../../../Common/Navigation'
import SendVerificationCode from './SendVerificationCode'
import { sendVerificationCode } from '../../../../redux/actions/resetPasswordActions'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [userName, setUserName] = useState('')

  const handleOnClick = async () => {
    await sendVerificationCode(userName)
    navigate('/verification-code-success')
  }

  const goLogin = () => {
    navigate('/login')
  }

  return (
    <Navigation>
      <SendVerificationCode
        userName={userName}
        setUserName={setUserName}
        handleOnClick={handleOnClick}
        goLogin={goLogin}
        translation={t}
      />
      <NavLink className={styles.small} to="/login">
        {t('User.RememberPassword')}
      </NavLink>
    </Navigation>
  )
}

const styles = {
  small:
    'text-primary my-[16px] text-sm underline cursor-pointer font-semibold block text-center  text-primary ',
}

export default ResetPassword
