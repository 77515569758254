import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleConfirmationDisplay,
  handleConfirmationOptions,
  handleTransactionPrompt,
  handleTransactionWidgetDisplay,
} from '../../redux/actions/transactionsActions'
import { RootReduxStateType } from '../../types'
import TransactionModal from './TransactionModal'

type Proptypes = {
  transactionType: string
  sender: string
  recipient: string
  amount: number
  isRepeatTransaction?: boolean
}

const Confirmation: React.FC<Proptypes> = ({
  sender,
  recipient,
  amount,
  isRepeatTransaction,
}) => {
  const dispatch = useDispatch()
  const transactions = useSelector(
    (state: RootReduxStateType) => state.transactions
  )

  const getConfirmationMessage = (transferType: string) => {
    switch (transferType) {
      case 'deposit':
        return (
          <div>
            Please confirm that you would like to deposit{' '}
            <span className="font-semibold">${amount}</span> from Bank Account{' '}
            <span className="font-semibold">{sender}</span> to Wallet{' '}
            <span className="font-semibold">{recipient}</span>
          </div>
        )

      case 'transfer':
        return (
          <div>
            Please confirm that you would like to transfer{' '}
            <span className="font-semibold">${amount}</span> from Wallet{' '}
            <span className="font-semibold">{sender}</span> to Wallet{' '}
            <span className="font-semibold">{recipient}</span>
          </div>
        )

      case 'transferTo':
        return (
          <div>
            Please confirm that you would like to transfer{' '}
            <span className="font-semibold">${amount}</span> from your Wallet{' '}
            <span className="font-semibold">{sender}</span> to User{' '}
            <span className="font-semibold">{recipient}</span>
          </div>
        )

      case 'redeem':
        return (
          <div>
            Please confirm that you would like to Withdraw{' '}
            <span className="font-semibold">${amount}</span> from Wallet{' '}
            <span className="font-semibold">{sender}</span> to Bank Account{' '}
            <span className="font-semibold">{recipient}</span>
          </div>
        )
      default:
        return (
          <div>
            Please confirm that you would like to transfer{' '}
            <span className="font-semibold">${amount}</span> from{' '}
            <span className="font-semibold">{sender}</span> to{' '}
            <span className="font-semibold">{recipient}</span>
          </div>
        )
    }
  }

  return (
    <TransactionModal>
      <div className="w-[100%] flex flex-col justify-center">
        <div className="flex flex-col py-4 space-y-4 text-center">
          <div>
            {transactions.confirmationDialogData.transactionType === 'deposit'
              ? getConfirmationMessage('deposit')
              : transactions.confirmationDialogData.transactionType ===
                'transfer'
              ? getConfirmationMessage('transfer')
              : transactions.confirmationDialogData.transactionType ===
                'transferTo'
              ? getConfirmationMessage('transferTo')
              : transactions.confirmationDialogData.transactionType === 'redeem'
              ? getConfirmationMessage('redeem')
              : getConfirmationMessage('default')}
          </div>
          <p>This is a one-time transaction</p>
        </div>

        <div className="flex justify-center space-x-2 py-4 border-t-2 border-dashed border-[#C4E8E8]">
          <button
            className="font-normal border-0"
            onClick={() => {
              dispatch(
                handleConfirmationOptions({
                  handleChange: false,
                  handleCancel: true,
                  handleConfirm: false,
                })
              )
              dispatch(handleConfirmationDisplay(false))
              dispatch(handleTransactionPrompt(null))
              dispatch(
                handleTransactionWidgetDisplay({
                  showTransactionModal: false,
                  showTransactionSlideUp: false,
                })
              )
            }}
          >
            Cancel
          </button>

          {!isRepeatTransaction && (
            <button
              className="font-normal border-0"
              onClick={() => {
                dispatch(
                  handleConfirmationOptions({
                    handleChange: true,
                    handleCancel: false,
                    handleConfirm: false,
                  })
                )
                dispatch(handleConfirmationDisplay(false))
              }}
            >
              Change
            </button>
          )}

          <button
            className="font-normal btn--dark"
            onClick={() => {
              dispatch(
                handleConfirmationOptions({
                  handleChange: false,
                  handleCancel: false,
                  handleConfirm: true,
                })
              )
              dispatch(handleConfirmationDisplay(false))
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </TransactionModal>
  )
}

export default Confirmation
