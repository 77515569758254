import { Skeleton } from '@mui/material'
import { useLoadScript } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { states } from '../../../constants/states'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import {
  createAddress,
  getUserAddressInfo,
} from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { UserAddressAPI } from '../../../types/user'
import { validateZipCode } from '../../../utilities/generalUtilities'
import SafeAreaView from '../../Common/SafeAreaView'
import AlpacaPlacesAutocomplete from '../AlpacaPlacesAutoComplete'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepFive: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [manualAddress, setManualAddress] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [currentAddressFromAPI, setCurrentAddressFromAPI] =
    useState<null | UserAddressAPI>(null)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '',
    libraries: ['places'],
  })

  useEffect(() => {
    if (
      alpaca.user.address1 &&
      alpaca.user.cityOrTown &&
      alpaca.user.state &&
      alpaca.user.zipCode &&
      validateZipCode(alpaca.user.zipCode)
    ) {
      setManualAddress(true)
      setDisabled(false)
    } else setDisabled(true)
  }, [alpaca.user])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNext = () => {
    if (currentAddressFromAPI) {
      if (
        currentAddressFromAPI.streetAddress1 !== alpaca.user.address1 ||
        currentAddressFromAPI.streetAddress2 !== alpaca.user.address2 ||
        currentAddressFromAPI.city !== alpaca.user.cityOrTown ||
        currentAddressFromAPI.zipCode !== alpaca.user.zipCode ||
        currentAddressFromAPI.state !== alpaca.user.state
      ) {
        // Run API call to update address
        setDisabled(true)

        createAddress({
          userAccountID: parseInt(user.id),
          streetAddress1: alpaca.user.address1 ?? '',
          streetAddress2: alpaca.user.address2 ?? '',
          city: alpaca.user.cityOrTown ?? '',
          state: alpaca.user.state ?? '',
          zipCode: alpaca.user.zipCode ?? '',
        }).then((res) => {
          setDisabled(false)
          dispatch(increamentAlpacaStepperStep())
        })

        return
      }
    } else {
      // Run API call to create an address
      setDisabled(true)

      createAddress({
        userAccountID: parseInt(user.id),
        streetAddress1: alpaca.user.address1 ?? '',
        streetAddress2: alpaca.user.address2 ?? '',
        city: alpaca.user.cityOrTown ?? '',
        state: alpaca.user.state ?? '',
        zipCode: alpaca.user.zipCode ?? '',
      }).then((res) => {
        setDisabled(false)
        dispatch(increamentAlpacaStepperStep())
      })

      return
    }

    // TODO: Run API if needed
    dispatch(increamentAlpacaStepperStep())
  }

  const handleSearchAddress = () => {
    setManualAddress(!manualAddress)
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(alpacaStepperUpdateUser(key, value === '' ? null : value))
  }

  const handleSearchAnotherAddress = () => {
    dispatch(alpacaStepperUpdateUser('address1', null))
    dispatch(alpacaStepperUpdateUser('address2', null))
    dispatch(alpacaStepperUpdateUser('cityOrTown', null))
    dispatch(alpacaStepperUpdateUser('zipCode', null))
    dispatch(alpacaStepperUpdateUser('state', null))
    handleSearchAddress()
  }

  useEffect(() => {
    setLoading(true)
    getUserAddressInfo(user.id).then((res) => {
      const data = res.response?.data
      const currentAddress = data[0]

      if (data.length >= 1 || currentAddress) {
        setCurrentAddressFromAPI(currentAddress)

        dispatch(
          alpacaStepperUpdateUser('address1', currentAddress.streetAddress1)
        )
        dispatch(
          alpacaStepperUpdateUser(
            'address2',
            currentAddress.streetAddress2 ?? null
          )
        )
        dispatch(alpacaStepperUpdateUser('cityOrTown', currentAddress.city))
        dispatch(alpacaStepperUpdateUser('zipCode', currentAddress.zipCode))
        dispatch(alpacaStepperUpdateUser('state', currentAddress.state))
        handleSearchAddress()
        setLoading(false)
      } else {
        setLoading(false)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <div className="flex justify-between items-center">
          <p className="font-semibold text-[17px] py-2">Address</p>
          {manualAddress && (
            <p
              onClick={handleSearchAnotherAddress}
              className="underline text-primary text-[14px] underline-offset-4"
            >
              Search for another
            </p>
          )}
        </div>

        <div className="py-2">
          {isLoaded &&
            !manualAddress &&
            (loading ? (
              <Skeleton height={90} />
            ) : (
              <AlpacaPlacesAutocomplete
                handleSearchAddress={handleSearchAddress}
              />
            ))}
          {manualAddress && (
            <div>
              <p>We also need your address.</p>
              <div className={`form-group form-group--filled py-4`}>
                <input
                  onChange={handleOnChange}
                  name="address1"
                  aria-label="address1"
                  value={
                    alpaca.user.address1 ? alpaca.user.address1 : undefined
                  }
                />
                <label>Address line 1</label>
              </div>

              <div className={`form-group form-group--filled py-2`}>
                <input
                  onChange={handleOnChange}
                  aria-label="address2"
                  name="address2"
                  value={
                    alpaca.user.address2 ? alpaca.user.address2 : undefined
                  }
                />
                <label>Address line 2</label>
              </div>

              <div className="flex">
                <div className={`form-group form-group--filled py-2 px-1`}>
                  <input
                    onChange={handleOnChange}
                    aria-label="cityOrTown"
                    name="cityOrTown"
                    value={
                      alpaca.user.cityOrTown
                        ? alpaca.user.cityOrTown
                        : undefined
                    }
                  />
                  <label>City or town</label>
                </div>
                <div className={`form-group form-group--filled py-2 px-1`}>
                  <input
                    onChange={handleOnChange}
                    aria-label="zipCode"
                    name="zipCode"
                    value={
                      alpaca.user.zipCode ? alpaca.user.zipCode : undefined
                    }
                  />
                  <label>ZIP</label>
                </div>
              </div>

              <div>
                <select
                  name="state"
                  aria-label="State"
                  value={alpaca.user.state ? alpaca.user.state : undefined}
                  onChange={handleOnChange}
                  className="bg-[#E3F2F2] py-5 mt-2 w-full px-3 rounded-lg outline-none"
                >
                  {states.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <p
                onClick={() =>
                  dispatch(alpacaStepperUpdateAssistenceNeeded(true))
                }
                className="underline text-primary text-[14px] underline-offset-4 py-5 cursor-pointer"
              >
                I need assistance
              </p>
            </div>
          )}
        </div>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={disabled || loading}
        handleNext={!manualAddress ? handleSearchAddress : handleNext}
      />
    </div>
  )
}

export default AlpacaStepFive
