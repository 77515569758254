import React from 'react'

type PropsType = {
  documentToUpload?: any
  onCancel: () => void
  onTakePicture: () => any
  handleFileChange: (e: any) => void
  enableMultipleUploads: boolean
}

const UploadModal: React.FC<PropsType> = ({
  onTakePicture,
  onCancel,
  handleFileChange,
  documentToUpload,
  enableMultipleUploads,
}) => {
  return (
    <>
      <div className="block fixed bg-[#021212BF] w-[100%] h-[100vh] top-0 left-0 opacity-95 z-[100]"></div>
      <>
        <div
          data-test="quiz-modal"
          className="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-[90%] md:w-[376px] lg:w-[480px] min-h-[200px] max-h-[90%]  opacity-100 rounded-[15px] flex flex-col z-[101]"
        >
          {/** Modal Title */}
          <div className="w-full bg-[#E3F2F2] p-5 rounded-t-[15px]">
            <p className="font-semibold text-[1rem] leading-[19.83px]">
              {documentToUpload.silaDocumentTypeLabel}
            </p>
            {documentToUpload.isFrontAndBackRequired ? (
              <p className="text-[1rem] leading-24px] ">
                We need a copy of both the front and back sides of your document
              </p>
            ) : (
              <p className="text-[1rem] leading-24px] ">
                We need a copy of the front side of your document only
              </p>
            )}
          </div>

          {/** Modal Content */}
          <div className="w-full h-full p-[16px]">
            <div className="flex flex-row border-b-2 border-dashed border-[#C4E8E8]">
              <div className="basis-1/2 min-h-[120px] flex flex-col items-center justify-center border-r-2 border-dashed border-[#C4E8E8]">
                <button className="font-normal border-0 flex flex-col items-center justify-center">
                  <img
                    src="/assets/images/camera.svg"
                    alt="camera"
                    className="pb-4"
                    //onClick={() => navigate('/document-upload')}
                    onClick={onTakePicture}
                  />
                  Take a Picture
                </button>
              </div>
              <div className="basis-1/2 min-h-[120px] flex flex-col items-center justify-center">
                <form>
                  <label
                    htmlFor="kycDocs"
                    className="cursor-pointer text-[14px] text-primary flex flex-col justify-center items-center"
                  >
                    <img
                      src="/assets/images/attach.svg"
                      alt="attach"
                      className="pb-4"
                    />
                    <input
                      type="file"
                      id="kycDocs"
                      className="hidden"
                      onChange={handleFileChange}
                      multiple={enableMultipleUploads}
                    />
                    Browse Files
                  </label>
                </form>
              </div>
            </div>

            <button
              className="font-normal mt-2 border-0 w-full"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    </>
  )
}

export default UploadModal
