import React from 'react'
import { useTranslation } from 'react-i18next'
import Navigation from '../components/Common/Navigation'

const Error404: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Navigation>
      <h1 className="mt-[76px] p-[20px] text-center">{t('Base.404')}</h1>
    </Navigation>
  )
}

export default Error404
