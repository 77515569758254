import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../redux/actions/userActions'

const LogOut = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutAction())
    localStorage.clear()
    window.location.href = '/'
    return

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
export default LogOut
