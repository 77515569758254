import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  increamentSavingsStepperStep,
  setupSavingsStepperUpdate,
} from '../../../../redux/actions/setupSavingsStepperActions'
import {
  getUser,
  getUserAddressInfo,
} from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { UserAPIType } from '../../../../types/user'
import SafeAreaView from '../../../Common/SafeAreaView'
import { LoadingSpinner } from '../../../Common/Loading'

import SilaSetupBottom from '../SilaSetupBottom'
type PropsType = {
  setShowModal: (x: boolean) => void
}

const SetupSavingsStepperTwo: React.FC<PropsType> = ({ setShowModal }) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const setupSavingsStepper = useSelector(
    (state: RootReduxStateType) => state.setupSavingsStepper
  )

  const [loadedUser, setLoadedUser] = useState<UserAPIType>()
  const [_, setInfoNeeded] = useState<string[]>([])

  const getUserInfo = async () => {
    setInfoNeeded([])

    const addressResponse = await getUserAddressInfo(user.id)
    const userResponse = await getUser(user.id)

    if (userResponse.status === 200) {
      setLoadedUser(userResponse.data)

      if (
        userResponse.data.firstName === null ||
        userResponse.data.firstName === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'firstName',
        ])
      }

      if (
        userResponse.data.lastName === null ||
        userResponse.data.lastName === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'lastName',
        ])
      }

      if (
        userResponse.data.telephoneNumber === null ||
        userResponse.data.telephoneNumber === ''
      ) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'telephoneNumber',
        ])
      }

      if (addressResponse.response?.data.length === 0) {
        setInfoNeeded((previousArray: string[]) => [
          ...previousArray,
          'address',
        ])
      }
    }
  }

  const handleNext = async () => {
    if (!loadedUser?.emailAddressIsVerified) {
      setShowModal(true)
      return
    }
    dispatch(increamentSavingsStepperStep())
  }

  const handleUpdateAccountInfo = () => {
    dispatch(setupSavingsStepperUpdate('updateAccountDetails', true))
    dispatch(increamentSavingsStepperStep())
  }

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatPhoneNumber = (phoneNumberString: any) => {
    const newPhoneNumberString = phoneNumberString.split(' ')[1]
    let cleaned = ('' + newPhoneNumberString).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  return (
    <>
      <SafeAreaView>
        <p className="leading-relaxed">
          To help the government fight terrorism funding and money laundering,
          all financial institutions are required by federal law to obtain,
          verify, and record information about you, including your name,
          address, and date of birth. We may also ask to see your driver's
          license or other identifying documents.
        </p>

        <p className="leading-relaxed mt-4">
          You must submit only genuine information, otherwise we won’t be able
          to set this up. <br />
        </p>

        <p className="leading-relaxed mt-4">
          Is this information that you provided correct?
        </p>

        {loadedUser ? (
          <div className="flex flex-col py-4">
            {loadedUser?.userName !== '' && (
              <div className="flex items-center py-1">
                <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
                <p className="ml-1">
                  Your legal name is{' '}
                  {loadedUser?.firstName + ' ' + loadedUser?.lastName}.
                </p>
              </div>
            )}

            {loadedUser?.dateOfBirth !== '' && (
              <div className="flex items-center py-1">
                <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
                <p className="ml-1">
                  Your date of birth is{' '}
                  {dayjs(loadedUser.dateOfBirth).format('MMMM D, YYYY')}
                  {/* {format(new Date(loadedUser.dateOfBirth), 'yyyy-MM-dd')} */}
                </p>
              </div>
            )}

            {loadedUser?.telephoneNumber !== '' && (
              <div className="flex items-center py-1">
                <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
                <p className="ml-1">
                  Your phone number is{' '}
                  {formatPhoneNumber(loadedUser.telephoneNumber)}.
                </p>
              </div>
            )}

            {loadedUser?.emailAddress !== '' && (
              <div className="flex items-center py-1">
                <div className="w-[11px] h-[11px] bg-[#3EB76E] rounded-full mx-1"></div>
                <p className="ml-1">
                  Your email is{' '}
                  {setupSavingsStepper.email
                    ? setupSavingsStepper.email
                    : loadedUser.emailAddress}
                  .
                </p>
              </div>
            )}

            <div className="py-4">
              <p
                className="underline text-primaryDark cursor-pointer"
                onClick={handleUpdateAccountInfo}
              >
                I made a mistake
              </p>
            </div>
          </div>
        ) : (
          <LoadingSpinner color="#004F4E" />
        )}
      </SafeAreaView>
      <SilaSetupBottom handleNext={handleNext} disabled={!loadedUser} />
    </>
  )
}

export default SetupSavingsStepperTwo
