import React, { useState } from 'react'

type SrcsetImageType = {
  small: string
  medium: string
  large: string
  alt: string
  className: string
}

const SrcsetImage: React.FC<SrcsetImageType> = ({
  small,
  medium,
  large,
  alt,
  className,
}) => {
  const [_, setCurrentImageUrl] = useState<string | null>(null)

  const onLoad = (e: any) => {
    setCurrentImageUrl(e.target.currentSrc)
  }

  return (
    <img
      src={small}
      srcSet={`${small} 300w, ${medium} 768w, ${large} 1280w`}
      onLoad={onLoad}
      alt={alt}
      className={className}
    />
  )
}

export default SrcsetImage
