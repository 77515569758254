import { ReduxActionType } from '../../types'
import { MoneyAcademyType } from '../../types/moneyacademy'
import { GET_SERIES, GET_SERIE_LESSONS } from '../actions/action.types'

const initialState: MoneyAcademyType = {
  series: [],
  lessons: [],
}

export const moneyAcademyReducer = (
  state = initialState,
  action: ReduxActionType<MoneyAcademyType[]>
) => {
  switch (action.type) {
    case GET_SERIES:
      return {
        ...state,
        series: [...action.payload],
      }

    case GET_SERIE_LESSONS:
      return {
        ...state,
        lessons: [...action.payload],
      }

    default:
      return state
  }
}
