import React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setNewPassword } from '../../../../redux/actions/resetPasswordActions'
import TextField from '../../../Common/TextField'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import PasswordStrengthChecker from '../../../Common/Partials/PasswordStrengthChecker'
import Navigation from '../../../Common/Navigation'
import { useNavigate } from 'react-router-dom'
import { RootReduxStateType } from '../../../../types'

const UpdatePassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [requiredLength] = useState(8)
  const [match, setMatch] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setValidLength(password.length >= requiredLength ? true : false)
    setUpperCase(password.toLowerCase() !== password)
    setLowerCase(password.toUpperCase() !== password)
    setHasNumber(/\d/.test(password))
    setSpecialChar(/[ `!@#$%^&*()_/+\-=\]{};':"\\|,.<>?~]/.test(password))
    setMatch(password === repeatPassword)
    setIsValid(
      validLength && hasNumber && upperCase && lowerCase && specialChar && match
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, repeatPassword, match])

  const handleOnClick = async () => {
    if (password !== repeatPassword) {
      alert(t('User.PasswordsNotMatch'))
    } else {
      const response = await setNewPassword(password, user)
      if (response.success) {
        localStorage.clear()
        window.location.href = '/login'
      }
    }
  }

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4">
            <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
              <button
                type="button"
                className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
                onClick={() => navigate(-1)}
              >
                <img
                  src={`/assets/images/back.svg`}
                  alt="back"
                  className="block text-primaryDark"
                />
              </button>
              {t('User.Password')}
            </h1>
          </div>

          <div>
            <TextField
              type="password"
              name="password"
              label={t('User.Password')}
              classes={styles.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {password.length !== 0 && (
            <PasswordStrengthChecker password={password} />
          )}

          <TextField
            type="password"
            name="repeatPassword"
            label="Repeat password"
            classes={styles.input}
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
          {!!!match && (
            <p className={styles.match}>{t('User.PasswordsNotMatch')}</p>
          )}
          <button
            className={styles.button}
            onClick={handleOnClick}
            disabled={!isValid}
          >
            {t('User.ResetPassword')}
          </button>
        </div>
      </Navigation>
    </>
  )
}

const styles = {
  input: 'text-input mb-[16px]',
  button: 'btn btn--big btn--dark mb-[16px]',
  match: 'text-warning text-sm mb-[16px]',
}

export default UpdatePassword
