import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changeMFAOption } from '../../../../redux/actions/generalActions'
import {
  signupAction,
  userNameCheck,
} from '../../../../redux/actions/userActions'
import { LoadingSpinner } from '../../../Common/Loading'
// @ts-ignore
import PasswordStrengthChecker from '../../../Common/Partials/PasswordStrengthChecker'
import TextField from '../../../Common/TextField'

type PropTypes = {
  styles: {
    button: string
    input: string
    backButton: string
    match: string
  }
  stepData: {
    referredByReferralCode: any
    userName: string
    password: string
    emailAddress: string
  }
  handleDataChange: (e: any) => any
  increamentStep: () => void
  decreamentStep: () => void
}

const StepThree = ({
  styles,
  decreamentStep,
  stepData,
  handleDataChange,
}: PropTypes) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)

    dispatch(signupAction())
  }

  const [validLength, setValidLength] = useState(false)
  const [requiredLength] = useState(8)
  const [isValid, setIsValid] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [agreePrivacy, setAgreePrivacy] = useState(false)
  const [mfa, _] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [userNameExist, setUserNameExist] = useState(false)

  useEffect(() => {
    setValidLength(stepData.password.length >= requiredLength ? true : false)

    setIsValid(
      validLength &&
        agreeTerms &&
        agreePrivacy &&
        isEmailValid &&
        stepData.userName.length > 0 &&
        !userNameExist
    )
    emailVerify(stepData.emailAddress)
    dispatch(changeMFAOption(mfa))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData, agreeTerms, agreePrivacy, isEmailValid, userNameExist])

  const emailVerify = (email: string) => {
    let regEmail = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )

    if (regEmail.test(email)) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }

  const changePasswordIcon = () => {
    setShowPassword(!showPassword)
  }

  const handleUserNameCheck = async () => {
    const res = await userNameCheck(stepData.userName)
    setUserNameExist(!res.success)
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex border-b-2 border-primaryLight border-dashed mb-[24px]">
        <p className="text-primary text-[11px] my-[12px] leading-[13.76px] font-semibold ">
          STEP 3 OUT OF 3
        </p>
        <div className="flex items-center space-x-2 ml-[16px]">
          <div className="bg-primaryLight border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
          <div className="bg-primaryLight border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
          <div className="bg-primaryLight border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
        </div>
      </div>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <p className="signup-description">
          Almost done! Enter your username, email and password next
        </p>

        <TextField
          type="text"
          label={`${t('Base.Username')} *`}
          name="userName"
          classes={styles.input}
          value={stepData.userName}
          onChange={handleDataChange}
          onBlur={handleUserNameCheck}
          autoComplete="off"
          data-test="signup-username-input"
        />
        {userNameExist && (
          <p className={styles.match}>{t('User.UsernameAlreadyExists')}</p>
        )}

        <TextField
          type="email"
          label={`${t('Base.Email')} *`}
          name="emailAddress"
          classes={styles.input}
          value={stepData.emailAddress}
          onChange={handleDataChange}
          data-test="signup-email-input"
          disabled={
            stepData.referredByReferralCode.length > 0 &&
            stepData.emailAddress.length > 0
          }
        />

        <TextField
          type={showPassword ? 'text' : 'password'}
          name="password"
          label={t('User.Password')}
          classes={styles.input}
          value={stepData.password}
          onChange={handleDataChange}
          passwordField
          showPasswordIconStatus={showPassword}
          changeIcon={changePasswordIcon}
          data-test="login-password-input"
          aria-label="login-password-input"
        />

        {stepData.password.length != 0 && (
          <PasswordStrengthChecker password={stepData.password} />
        )}

        <div className="checkbox mb-[16px] text-[15px] mt-10">
          <input
            type="checkbox"
            checked={agreePrivacy}
            onChange={() => {
              setAgreePrivacy(!agreePrivacy)
              setAgreeTerms(!agreeTerms)
            }}
            id="agreePrivacy"
            data-test="signup-terms-checkbox"
          />
          <label htmlFor="agreePrivacy">
            {t('User.IAgree')}
            <a
              href="https://www.sootchysecurities.com/legal-documents/sootchy-privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              {' Sootchy '}
              {t('User.PrivacyPolicy')}
            </a>
            {' & '} {t('User.IAgree')}
            <a
              href="https://www.sootchysecurities.com/legal-documents/sootchy-terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              {' Sootchy '}
              {t('User.TermsConditions')}
            </a>{' '}
          </label>
        </div>

        <div className="flex space-x-2 mt-[50px] pt-[24px] border-t-2 border-primaryLight border-dashed">
          <button onClick={decreamentStep} className={styles.backButton}>
            {t('Base.Back')}
          </button>
          <button
            onClick={handleSubmit}
            className={styles.button}
            disabled={loading ? true : !isValid}
            type="button"
          >
            {loading ? <LoadingSpinner /> : t('Base.Next')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default StepThree
