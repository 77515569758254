import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SIGNUP } from '../../../../redux/actions/action.types'
import { activationRegisterReset } from '../../../../redux/actions/activationActions'
import {
  newUserSignup,
  userNameCheck,
} from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import {
  calculateUserAge,
  getUserFromToken,
} from '../../../../utilities/generalUtilities'
import { LoadingSpinner } from '../../../Common/Loading'
import TextField from '../../../Common/TextField'
import Layout from '../Layout'
// @ts-ignore
import SuccesCheckIcon from '../../../../assets/SuccessCheck.png'
// @ts-ignore
import { setLoginRes } from '../../../../api'

// @ts-ignore
import BoysGirlClubLogo from '../../../../assets/BoysGirlsClubLogo.png'
import { trackSignupActivationEvent } from '../../../../amplitude/events'
import dayjs from 'dayjs'
import ActivationModal from '../../Partials/ActivationModal'

const ActivationConfirmationStep = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activation = useSelector(
    (state: RootReduxStateType) => state.activation
  )
  const [userName, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [userNameExist, setUserNameExist] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleSubmit = () => {
    setLoading(true)

    const userAge = calculateUserAge(activation.parentInfo.dateOfBirth)
    console.log('New User', userAge)

    if (userAge < 18) {
      setShowModal(true)
    } else {
      newUserSignup({
        username: userName,
        firstName: activation.parentInfo.firstName,
        lastName: activation.parentInfo.lastName,
        phoneNumber: activation.parentInfo.phoneNumber,
        password: password,
        dateOfBirth: activation.parentInfo.dateOfBirth,
        email: activation.parentInfo.email,
        activationUserProfileID: activation.activationUserProfileID ?? 0,
        invitationVerificationCode: null,
      })
        .then((res) => {
          setLoginRes(res.data)
          const { UserName, UserAccountID } = getUserFromToken(
            res.data.accessToken
          )
          const encodedPayload = res.data.accessToken
          dispatch({
            type: SIGNUP,
            payload: {
              id: UserAccountID,
              userName: UserName,
              emailAddress: res.data.emailAddress,
              token: encodedPayload,
            },
          })
          dispatch(activationRegisterReset())
          navigate('/')
          setLoading(false)
        })
        .catch((err) => {
          alert('Error when creating account')
          setLoading(false)
        })
    }
  }

  const handleUserNameCheck = () => {
    if (!userName) return

    userNameCheck(userName).then((res) => {
      if (!res.success) setUserNameExist(true)
    })
  }

  return (
    <>
      {showModal && (
        <div>
          <ActivationModal>
            <div className="my-4">
              <h3 className="fullpage-title">We need your mom or dad 👪</h3>

              <p className="signup-description text-center ">
                We know you're a smart kid, but because of your age we need a
                parent (or legal guardian) to sign you up for this event. Show
                this page to your parent and ask them to register you.
              </p>
              <button
                onClick={() => {
                  setShowModal(false)
                  setLoading(false)
                }}
                className="btn btn--big btn--gray"
              >
                My parents will help me 👍
              </button>
            </div>
          </ActivationModal>
        </div>
      )}

      <Layout>
        <div className="text-center">
          <img
            src={SuccesCheckIcon}
            alt="SuccesCheckIcon"
            className="h-[60px] m-auto"
          />
          <p className=" font-semibold text-[25px] mt-7">
            All set. Thanks, {activation.parentInfo.firstName}.
          </p>
        </div>
        <div className="w-full bg-[#EFF9F9] py-7 px-6 rounded-xl mt-10">
          <p className="text-[20px] font-semibold leading-9">
            How about one extra step towards financial freedom?
          </p>

          <p className="leading-8 py-3 font-thin">
            Set up a Sootchy profile to open a free savings account and access
            exclusive funds and opportunities.
          </p>

          <div className="grid grid-cols-1 gap-5 mt-3 sm:grid-cols-2">
            <div>
              <TextField
                type="text"
                name="userName"
                label={'Create a username'}
                classes={'text-input mb-[5px] w-full'}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value)
                  setUserNameExist(false)
                }}
                onBlur={handleUserNameCheck}
              />
              {userNameExist && (
                <p className="font-thin text-[14px] text-red-700">
                  Username already exists.
                </p>
              )}
            </div>
            <TextField
              type="password"
              name="userName"
              label={'Create a password'}
              classes={'text-input mb-[16px] w-full'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className="w-full mt-3 text-[16px] font-normal bg-primary text-white py-5"
            onClick={handleSubmit}
            disabled={
              loading ||
              userNameExist ||
              userName.length === 0 ||
              password.length === 0
            }
          >
            {loading ? <LoadingSpinner /> : 'Sign-up and explore Sootchy'}
          </button>
        </div>

        <div className="w-full bg-white shadow-card rounded-xl my-10 sm:h-[520px] sm:flex sm:justify-between">
          <div className="py-10 px-10">
            <p className="text-[20px] font-semibold leading-9">
              Let’s meet on{' '}
              <span className="text-primary">
                {dayjs(activation.activationInfo.activationEventDate).format(
                  'MMMM D YYYY, h:mm A'
                )}
              </span>
            </p>

            <p className="font-thin mt-2 leading-7">
              {activation.activationInfo.activationEventVenue}{' '}
              <span
                className="text-primary cursor-pointer"
                onClick={() =>
                  window.open(
                    `${activation.activationInfo.mapLocation}`,
                    '_blank'
                  )
                }
              >
                (open in Maps)
              </span>
            </p>

            <p className="text-[20px] font-semibold leading-9 mt-5">
              Things to know
            </p>

            <ul className="list-outside px-5 mt-3">
              <li className="font-thin list-disc leading-7">
                This event is meant for children. Parent attendance is welcome
                but optional.
              </li>

              <li className="font-thin list-disc leading-7">
                To get the most out of this event, create a Sootchy profile.
                Check your email after you RSVP!
              </li>

              <li className="font-thin list-disc leading-7">
                To unlock opportunities after the event, we encourage you to set
                up a free savings account on the Sootchy app.
              </li>

              <li className="font-thin list-disc leading-7">
                Our goal is to unlock exclusive funds and opportunities for your
                family
              </li>
            </ul>
          </div>

          <img
            src={
              'https://www.bgctucson.org/wp-content/uploads/2022/08/Frank-Edith-Morton-Clubhouse-1024x1024.jpg'
            }
            alt="SampleSchoolIcon"
            className="sm:hidden"
          />

          {/* Image */}
          <div
            style={{
              background: `url(https://www.bgctucson.org/wp-content/uploads/2022/08/Frank-Edith-Morton-Clubhouse-1024x1024.jpg)`,
              width: '80%',
              height: '100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            className=" rounded-r-xl"
          ></div>
        </div>

        <div className="w-full py-7 px-6 rounded-xl mt-10 bg-[#F0F4FF] flex items-center justify-between">
          <div>
            <p className="text-[20px] font-semibold leading-9">
              Join your Boys and Girls club!
            </p>

            <p className="leading-8 py-1 font-thin">
              Don’t miss other cool community events, join the club!
            </p>

            <button
              className="background-transparent border-[#0491D1] text-[#0491D1] font-thin text-[15px] px-5 py-4 mt-4 rounded-xl"
              onClick={() =>
                window.open('https://www.bgctucson.org/', '_blank')
              }
            >
              Join Boys and Girls Club
            </button>
          </div>

          <img src={BoysGirlClubLogo} alt="BoysGirlClubLogo" />
        </div>
      </Layout>
    </>
  )
}

export default ActivationConfirmationStep
