import React from 'react'
// @ts-ignore
import NewFooter from '../Partials/NewFooter'

type PropsType = {
  children?: React.ReactNode
}

const DefaultLayout: React.FC<PropsType> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.navbar}>
        <NewNavbar style="plain" />
      </div> */}
      <div className={styles.logo.wrapper}>
        <img
          src="https://stdevsootchyinc.blob.core.windows.net/core-website/assets/images/logo.svg"
          alt="logo"
          className={styles.logo.imgStyles}
        />
      </div>

      <div className={styles.content.outerWrapper}>
        <div className={styles.content.innerWrapper}>
          <div className="block">{children}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <NewFooter />
      </div>
    </div>
  )
}

const styles = {
  wrapper:
    'flex flex-col lg:bg-primaryLightBackground md:bg-primaryLightBackground min-h-screen w-full',
  navbar: 'flex-none lg:hidden md:hidden',
  logo: {
    wrapper: 'hidden lg:block md:block',
    imgStyles: 'w-40 mx-auto pt-[32px] pb-[68px]',
  },
  content: {
    outerWrapper: 'flex grow justify-center items-center',
    innerWrapper:
      'bg-white py-6 px-[30px] lg:px-10 lg:rounded-xl md:rounded-xl w-full md:w-[376px] lg:w-[376px] mx-auto lg:shadow-primaryLight md:shadow-primaryLight md:shadow-card lg:shadow-card',
  },
  footer: 'hidden lg:block md:block mt-[76px] sticky top-[100vh]',
}

export default DefaultLayout
