import React from 'react'
import { useEffect, useState } from 'react'
//import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Navigation from '../../../Common/Navigation'

const EmailVerificationSucess = () => {
  const navigate = useNavigate()
  //const { t } = useTranslation()
  const [count, setCount] = useState(15)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount)
    }, 1000)

    count === 0 && navigate('/')

    //console.log(count)

    return () => clearInterval(interval)
  }, [count, navigate])

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4 flex flex-col justify-center items-center">
            <img src="/assets/images/dolphin_logo.svg" alt="dolphin" />

            <h1 className="text-center text-[32px] lg:text-[36px] text-primaryDark leading-[32.37px] font-semibold py-[32px]">
              Welcome to Sootchy!
            </h1>
            <p className="text-primary pb-[32px]">
              Email verification was successful
            </p>
            <p className="text-primary pb-[32px]">
              Redirecting you in{' '}
              <span className="text-primary font-semibold">{count}</span>
            </p>
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default EmailVerificationSucess
