import React from 'react'
// @ts-ignore
import CloseIcon from '../../assets/closeIcon.png'

type PropsType = {
  title: string
  description: string
  showCustomerCarelink?: boolean
  primaryButtonText: string
  secondaryButtonText: string
  handlePrimaryOnClick: () => void
  handleSecondaryOnClick: () => void
  onClose: () => void
  secondaryButtonStyle?: 'primary' | 'secondary'
}

const AlpacaGeneralModal: React.FC<PropsType> = ({
  title,
  description,
  showCustomerCarelink = false,
  primaryButtonText,
  secondaryButtonText,
  handlePrimaryOnClick,
  handleSecondaryOnClick,
  onClose,
  secondaryButtonStyle = 'primary',
}) => {
  return (
    <div className="fixed h-full w-screen z-50 top-0 left-0">
      <div
        className="absolute bg-black opacity-80 h-screen w-screen"
        onClick={onClose}
      ></div>

      <div
        className="absolute bg-white w-[88%] rounded-xl p-4 sm:w-[400px]"
        style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
      >
        <div className="flex justify-between items-center border-[#C4E8E8] border-b-2 border-dashed pb-4">
          <p className="font-semibold text-primaryDark text-[18px]">{title}</p>

          <img
            src={CloseIcon}
            alt="close icon"
            className="w-[13px] h-[13px] cursor-pointer"
            onClick={onClose}
          />
        </div>

        <p className="py-5">{description}</p>

        {showCustomerCarelink && (
          <p className="underline text-primary text-[14px] underline-offset-4 cursor-pointer pb-5">
            Contact customer service
          </p>
        )}

        <div className="flex items-end justify-between">
          {secondaryButtonStyle === 'primary' ? (
            <button className="w-[40%]" onClick={handleSecondaryOnClick}>
              {secondaryButtonText}
            </button>
          ) : (
            <p
              className="underline text-primary text-[14px] underline-offset-4 cursor-pointer pb-5"
              onClick={handleSecondaryOnClick}
            >
              Start from Scratch
            </p>
          )}

          <button
            className={`border-none text-white bg-primary h-[50px] mx-2 ${
              secondaryButtonStyle === 'primary' ? 'w-[60%]' : ' w-[30%]'
            }`}
            onClick={handlePrimaryOnClick}
          >
            {primaryButtonText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AlpacaGeneralModal
