import React from 'react'
import { useTranslation } from 'react-i18next'
import FullPageTitle from '../../../Common/Partials/FullPageTitle'

type PropTypes = {
  resetStep: () => void
}

const StepFive = ({ resetStep }: PropTypes) => {
  const { t } = useTranslation()

  return (
    <div className="mt-[32px]">
      <FullPageTitle title="We need your mom or dad 👪" hideButton />
      <p className="signup-description text-center ">
        {t('User.UnderageDesc')}
      </p>
      <button onClick={resetStep} className="btn btn--big btn--gray">
        {t('User.UnderageButton')} 👍
      </button>
    </div>
  )
}

export default StepFive
