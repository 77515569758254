import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AlertWrapper from '../components/Common/Alert/AlertWrapper'
import Error404 from '../pages/Error404'
import BackgroundRoute from './backgroundRoute'
import PrivateRoute from './privateRoute'
import { routes } from './routes'

const Router = () => {
  return (
    <>
      <BackgroundRoute />
      <AlertWrapper />
      <BrowserRouter>
        <Routes>
          {/* General Routes */}
          {routes.map(
            (route) =>
              !route.protected && (
                <Route
                  path={route.path}
                  element={<route.element />}
                  key={route.path}
                />
              )
          )}
          {/* Privte Routes */}
          <Route element={<PrivateRoute />}>
            {routes.map(
              (route) =>
                route.protected && (
                  <Route
                    path={route.path}
                    element={<route.element />}
                    key={route.path}
                  />
                )
            )}
          </Route>
          {/* Catch routes */}
          <Route path="*" element={<Error404 />} key="Error404" />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Router
