import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../../api'
import { createAlertAction } from '../../../../redux/actions/alertActions'
import { getAllBanks, getWallets } from '../../../../redux/actions/linkActions'
import {
  getLatestTransactions,
  handleConfirmationDisplay,
  handleConnectBank,
  resetConfirmationDialogOptions,
} from '../../../../redux/actions/transactionsActions'
import { getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { BankType, WalletType } from '../../../../types/transactions'
import { UserAPIType } from '../../../../types/user'
import Confirmation from '../../../Common/Confirmation'
import Navigation from '../../../Common/Navigation'
import TransactionsLoader from '../../../Common/SkeletonLoaders/TransactionsLoader'
import WalletHomeLoader from '../../../Common/SkeletonLoaders/WalletHomeLoader'
import SlideUp from '../../../Common/SlideUp'
import Transaction from '../../Transactions/Transaction'
import AddingMoneyToWallet from '../Actions/AddingMoneyToWallet'
import ConnectingBank from '../Actions/ConnectBank'
import SendingMoneyToWallet from '../Actions/SendingMoneyToWallet'
import RequestMoneyWithdrawToBank from '../Actions/WithdrawingMoneyToBank'

const WalletDetails: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const user = useSelector((state: RootReduxStateType) => state.user)
  const transactions = useSelector(
    (state: RootReduxStateType) => state.transactions
  )

  const [walletName, setWalletName] = useState<any>('')
  const [showMore, setShowMore] = useState<boolean>(false)
  const [transactionsLoading, setTransactionsLoading] = useState<boolean>(false)
  const [walletTransactions, setWalletTransactions] = useState<any>([])
  const [walletBalance, setWalletBalance] = useState(0)
  const [walletColor, setWalletColor] = useState<string>('')
  const [silaWalletID, setSilaWalletID] = useState<string>('')
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [currentWallet, setCurrentWallet] = useState<any>()
  const [amountToAdd, setAmountToAdd] = useState<string>('')
  const [amountToSend, setAmountToSend] = useState<string>('')
  // const [amountToWithdraw, setAmountToWithdraw] = useState<string>('')
  // const [withdrawDescription, setWithdrawDescription] = useState<string>('')
  const [defaultBank, setDefaultBank] = useState<BankType>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loadedUser, setLoadedUser] = useState<UserAPIType>()
  const [_, setOpenTransactionModal] = useState<boolean>(false)
  const [banks, setBanks] = useState<BankType[]>()
  const [wallets, setWallets] = useState<WalletType[]>()
  const [__, setShowTransactionConfirmation] = useState<boolean>(false)
  const [___, setShowTransactionSlideUp] = useState<boolean>(false)

  const [showAddingMoneyComponent, setShowAddingMoneyComponent] =
    useState<boolean>(false)
  const [showConnectBankComponent, setShowConnectBankComponent] =
    useState<boolean>(false)
  const [showSendingMoneyComponent, setShowSendingMoneyComponent] =
    useState<boolean>(false)
  const [showWithdrawingMoneyComponent, setShowWithdrawingMoneyComponent] =
    useState<boolean>(false)

  useEffect(() => {
    handleGetUser()
    handleGetAllBanks()
    handleAllGetWallets()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOpenTransactionModal(
      transactions.modals.transactionWidgets.showTransactionModal
    )
    setShowTransactionSlideUp(
      transactions.modals.transactionWidgets.showTransactionSlideUp
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions.modals.transactionWidgets])

  useEffect(() => {
    dispatch(handleConnectBank(false))
    dispatch(resetConfirmationDialogOptions())
    dispatch(handleConfirmationDisplay(false))
    if (params) {
      setWalletName(params.id)
      getWalletDetails(params.id)
      console.log('Params', params)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    handleLatestTransactions(silaWalletID)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [silaWalletID])

  const handleGetUser = async () => {
    try {
      const response = await getUser(user.id)

      if (response.status === 200) setLoadedUser(response.data)
    } catch (err: any) {
      return { success: false, err }
    }
  }

  const handleAllGetWallets = async () => {
    const response = await getWallets()
    try {
      if (response.status === 200) {
        console.log('All Wallets', response.data)
        setWallets(response.data)
      }
    } catch (err) {
      return { success: false, err }
    }
  }

  const getWalletDetails = async (walletname: any) => {
    try {
      setPageLoading(true)
      const response: any = await api.get(`/SilaWallet/Wallet/${walletname}`)

      if (response.status === 200) {
        setWalletBalance(parseFloat(response.data.walletBalance) / 100)
        setSilaWalletID(response.data.silaWalletID)
        setWalletColor(response.data.walletColor)
        setCurrentWallet(response.data)

        setPageLoading(false)
      }
    } catch (error) {
      alert('Unable to fetch wallet details')
    }
  }

  const handleLatestTransactions = async (silawalletid: any) => {
    try {
      setTransactionsLoading(true)
      const response: any = await getLatestTransactions(silawalletid)
      setWalletTransactions(response)
      setTransactionsLoading(false)
      //console.log('Local transactions', response, walletTransactions)
    } catch (error) {
      console.log('Unable to fetch transactions')
    }
  }

  const handleTransactionOptions = (e: any) => {
    if (banks && banks.length === 0) {
      setShowConnectBankComponent(true)
    } else {
      if (e.target.name === 'add') {
        setShowAddingMoneyComponent(true)
      } else if (e.target.name === 'send') {
        setShowSendingMoneyComponent(true)
      } else if (e.target.name === 'withdraw') {
        setShowWithdrawingMoneyComponent(true)
      }
    }
  }

  const handleGetAllBanks = async () => {
    try {
      setPageLoading(true)
      const response = await getAllBanks()
      if (response.status === 200 && response.data) {
        setBanks(response.data)
        response.data.map((bank: any) => bank.isDefault && setDefaultBank(bank))
      }
      setPageLoading(false)
    } catch (err) {
      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Error Occured',
          message: 'Not able to fetch the Bank Details. Please try again',
          autoClose: true,
        })
      )
    }
  }

  // const handleAddMoney = () => {
  //   dispatch(handleConfirmationDisplay(true))
  //   dispatch(
  //     handleConfirmationDialogData({
  //       transactionType: 'deposit',
  //       sender: defaultBank?.accountName,
  //       recipient: currentWallet.walletName,
  //       amount: amountToAdd,
  //     })
  //   )
  // }

  const MainMoneyAction = ({ type }: { type: string }) => {
    return (
      <button
        className={`capitalize py-4 mb-4 ${
          type === 'withdraw'
            ? 'w-full  border-2 border-primaryDark text-primaryDark transition duration-200 ease-in-out'
            : 'w-[49%]  border-none bg-primaryDark text-white flex items-center justify-center '
        } `}
        onClick={handleTransactionOptions}
        name={type}
      >
        {(type === 'add' || type === 'send') && (
          <img
            className="mr-4"
            src={
              type === 'add'
                ? '/assets/images/add_icon_white.svg'
                : '/assets/images/transfer-icon-white.svg'
            }
            alt="add"
          />
        )}
        {type}
      </button>
    )
  }

  /** Main Function Return */

  useEffect(() => {
    console.log('Banks', banks, banks?.length)
  }, [banks])

  return (
    <>
      {/* <TwoFactorAuthentication
        onSuccess={handleAddMoney}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
      {pageLoading ? (
        <Navigation>
          <WalletHomeLoader />
        </Navigation>
      ) : (
        <Navigation style="wallet" color={walletColor}>
          <>
            <div style={{ backgroundColor: `${walletColor}` }}>
              <div className="w-[90%] md:w-[570px] m-auto">
                <div className="py-4">
                  <h1 className="fullpage-title text-[1.125rem] text-primary">
                    <button
                      type="button"
                      className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-primary border-2`}
                      onClick={() => navigate(-1)}
                    >
                      <img
                        src={`/assets/images/back.svg`}
                        alt="back"
                        className="block text-primary"
                      />
                    </button>
                    {walletName}
                  </h1>
                </div>

                <div className="pb-[2rem] border-b-[2.5px] border-primary border-dashed">
                  <p className="py-7 text-[2.25rem] font-semibold text-primary">
                    {walletName}
                  </p>
                  <div className="mt-4">
                    <p className="font-normal text-primary text-[1rem]">
                      Total
                    </p>
                    <p className="text-[2rem] leading-[1.79875rem] font-semibold mt-4 text-primary">
                      ${walletBalance}
                    </p>
                  </div>
                </div>

                <div className="pt-[2rem]">
                  <div className="flex justify-between">
                    <MainMoneyAction type="add" />
                    <MainMoneyAction type="send" />
                  </div>

                  <div className="py-4">
                    {showMore && <MainMoneyAction type="withdraw" />}

                    <button
                      onClick={() => setShowMore(!showMore)}
                      className="w-full flex justify-center items-center font-normal border-0 text-primaryDark"
                    >
                      {showMore ? 'Less options' : 'More options'}
                      <img
                        className="ml-4"
                        src={
                          showMore
                            ? '/assets/images/arrow-up.svg'
                            : '/assets/images/arrow-down.svg'
                        }
                        alt="arrow down"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-[1.5rem] w-[90%] md:w-[570px] m-auto">
              <p className="py-4 text-[24px] font-semibold text-textGreen">
                Latest Transactions
              </p>

              {transactionsLoading ? (
                <TransactionsLoader />
              ) : walletTransactions?.length > 0 ? (
                <div>
                  {walletTransactions
                    .slice(0, 4)
                    .map((transaction: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => {
                          console.log('Selected Transaction', transaction)
                          navigate(
                            `/transaction/${walletName}/${transaction.transactionID}`
                          )
                        }}
                        className={`flex py-[10px] cursor-pointer ${
                          index === 0
                            ? 'border-0'
                            : 'border-t-[2.5px] border-[#C4E8E8] border-dashed'
                        }`}
                      >
                        <Transaction
                          transaction={transaction}
                          currentWalletName={currentWallet.walletName}
                        />
                      </div>
                    ))}

                  <div className="mt-[2.25rem]">
                    <button
                      onClick={() => navigate(`/transactions/${walletName}`)}
                      //className="mb-[48px] border-2 border-[#C4E8E8]"
                      className="w-full md:w-[45%] py-4 border-[#C4E8E8] bg-white text-primaryDark flex items-center justify-center"
                    >
                      View all transactions
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full bg-[#E3F2F2] h-[75px] flex justify-center items-center mt-4 rounded-lg">
                  <div className="flex flex-col">
                    <p className="mt-2 text-[#0E6D6C]">
                      You don’t have any transactions yet.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/** Wallet Actions */}

            {transactions.modals.showDialogModal ? (
              <Confirmation
                transactionType={
                  transactions.confirmationDialogData.transactionType
                }
                amount={transactions.confirmationDialogData.amount}
                recipient={transactions.confirmationDialogData.recipient}
                sender={transactions.confirmationDialogData.sender}
              />
            ) : (
              <>
                {showConnectBankComponent && (
                  <ConnectingBank
                    isActive={showConnectBankComponent}
                    handleIsActiveChange={() =>
                      setShowConnectBankComponent(false)
                    }
                  />
                )}

                {showAddingMoneyComponent && (
                  <AddingMoneyToWallet
                    isActive={showAddingMoneyComponent}
                    handleIsActiveChange={() =>
                      setShowAddingMoneyComponent(false)
                    }
                    defaultBank={defaultBank}
                    setDefaultBank={setDefaultBank}
                    amountToAdd={amountToAdd}
                    setAmountToAdd={setAmountToAdd}
                    currentWallet={currentWallet}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    banks={banks}
                  />
                )}

                {showSendingMoneyComponent && (
                  <SendingMoneyToWallet
                    isActive={showSendingMoneyComponent}
                    handleIsActiveChange={() =>
                      setShowSendingMoneyComponent(false)
                    }
                    wallets={wallets}
                    currentWallet={currentWallet}
                    amountToSend={amountToSend}
                    setAmountToSend={setAmountToSend}
                  />
                )}

                {showWithdrawingMoneyComponent && (
                  // <WithdrawingMoneyToBank
                  //   //isActive={showWithdrawingMoneyComponent}
                  //   // handleIsActiveChange={() =>
                  //   //   setShowWithdrawingMoneyComponent(false)
                  //   // }
                  //   banks={banks}
                  //   defaultBank={defaultBank}
                  //   setDefaultBank={setDefaultBank}
                  //   //amountToWithdraw={amountToWithdraw}
                  //   //setAmountToWithdraw={setAmountToWithdraw}
                  //   currentWallet={currentWallet}
                  //   currentUser={loadedUser}
                  //   //withdrawDescription={withdrawDescription}
                  //   //setWithdrawDescription={setWithdrawDescription}
                  // />

                  <>
                    {/* <WithdrawMoneyComponent
                      currentUser={loadedUser}
                      currentWallet={currentWallet}
                      defaultBank={defaultBank}
                      setDefaultBank={setDefaultBank}
                      banks={banks}
                      showConfirmation={setShowTransactionConfirmation}
                    /> */}

                    <div className="fixed h-full w-screen z-50 top-0 left-0">
                      <div className="absolute bg-black opacity-80 h-screen w-screen"></div>

                      {isMobile ? (
                        <div className="absolute w-full md:w-[570px] bottom-0 inset-x-0 mx-auto transform transition ease-in-out duration-300">
                          <div className="bg-white shadow shadow-gray-400/50 rounded-t-[10px] p-[20px] z-40">
                            <SlideUp>
                              <RequestMoneyWithdrawToBank
                                currentUser={loadedUser}
                                currentWallet={currentWallet}
                                defaultBank={defaultBank}
                                setDefaultBank={setDefaultBank}
                                banks={banks}
                                showConfirmation={
                                  setShowTransactionConfirmation
                                }
                              />
                            </SlideUp>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="absolute bg-white w-full md:w-[570px] ease-in-out duration-300 transition-transform rounded-xl py-6 px-6"
                          style={{
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%,-50%)',
                          }}
                        >
                          <RequestMoneyWithdrawToBank
                            currentUser={loadedUser}
                            currentWallet={currentWallet}
                            defaultBank={defaultBank}
                            setDefaultBank={setDefaultBank}
                            banks={banks}
                            showConfirmation={setShowTransactionConfirmation}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </Navigation>
      )}
    </>
  )
}

export default WalletDetails
