import api from '../../api'
import { RootReduxStateType } from '../../types'
import { getHashedPassword } from '../../utilities/generalUtilities'
import { UPDATE_TOKEN } from './action.types'

export const sendVerificationCode = async (userName: string) => {
  try {
    await api.post('/UserAccount/ResetPassword', {
      userName,
      sourceProcess: 'WebApplication',
    })
    return { success: true }
  } catch (err) {
    return { success: false, err }
  }
}

export const checkVerificationCode =
  (code: string, navigate: Function) => async (dispatch: Function) => {
    try {
      const res = await api.post('/UserAccount/ResetPassword/Check', {
        passwordResetVerificationCode: code,
        sourceProcess: 'WebApplication',
      })
      if (res.status === 200) {
        const token = res.data.item1.accessToken

        localStorage.setItem('loginRes', JSON.stringify(res.data.item1))

        dispatch({
          type: UPDATE_TOKEN,
          payload: token,
        })

        navigate('/update-password')
      }
    } catch (err) {
      navigate('/reset-password-failed')
    }
  }

export const setNewPassword = async (
  password: string,
  user: RootReduxStateType['user']
) => {
  try {
    const hashedPassword = await getHashedPassword(password)
    const res = await api.put('/UserAccount/ResetPassword', {
      UserAccountID: parseInt(user.id),
      Password: hashedPassword,
      ModifyingUserAccountID: parseInt(user.id),
      SourceProcess: 'WebApplication',
      ChangeReason: 'Reset password',
    })

    if (!res || res.status !== 200) return { success: false, err: res }

    return { success: true, response: res }
  } catch (err) {
    return { success: false, err }
  }
}
