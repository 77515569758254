// --------- User Actions ---------
export const LOG_IN = 'LOGIN'
export const LOG_IN_WITH_GOOGLE = 'LOGIN'
export const SIGNUP = 'SIGNUP'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const RESET_USER = 'RESET_USER'
export const ENABLE_MFA = 'ENABLE_MFA'
export const UPDATE_USERACCOUNT_PROFILE = 'UPDATE_USERACCOUNT_PROFILE'
export const UPDATE_USERACCOUNT_PROFILE_PROGRESS =
  'UPDATE_USERACCOUNT_PROFILE_PROGRESS'
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'

// --------- User Registration Stepper Actions ---------
export const SETUP_USER_DETAIL_FORM_STEP = 'SETUP_USER_DETAIL_FORM_STEP'
export const USER_REGISTRATION_STEPPER_INCREAMENT =
  'USER_REGISTRATION_STEPPER_INCREAMENT'
export const USER_REGISTRATION_STEPPER_DECREAMENT =
  'USER_REGISTRATION_STEPPER_DECREAMENT'
export const USER_REGISTRATION_STEPPER_RESET = 'USER_REGISTRATION_STEPPER_RESET'
export const CHANGE_USER_REGISTRATION_STEPPER_DATA =
  'CHANGE_USER_REGISTRATION_STEPPER_DATA'
export const RESET_USER_REGISTRATION_STEPPER_DATA =
  'RESET_USER_REGISTRATION_STEPPER_DATA'
export const RESET_USER_REGISTRATION_STEPPER = 'RESET_USER_REGISTRATION_STEPPER'
export const USER_REGISTRATION_STEPPER_UNDERAGE =
  'USER_REGISTRATION_STEPPER_UNDERAGE'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT'
export const UPDATE_KYC_STATUS = 'UPDATE_KYC_STATUS'

// --------- Alert Actions ---------
export const CREATE_ALERT = 'CREATE_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

// ............... Money Academy Actions .............
export const GET_SERIES = 'GET_SERIES'
export const GET_SERIE_LESSONS = 'GET_SERIE_LESSONS'

// ..................Coins ........
export const GET_COINS = 'GET_COINS'

// ............... Setup Savings Account Stepper Actions .............
export const SETUP_SAVINGS_STEPPER_INCREAMENT =
  'SETUP_SAVINGS_STEPPER_INCREAMENT'

export const SETUP_SAVINGS_STEPPER_DECREAMENT =
  'SETUP_SAVINGS_STEPPER_DECREAMENT'

export const SETUP_SAVINGS_STEPPER_UPDATE = 'SETUP_SAVINGS_STEPPER_UPDATE'
export const SETUP_SAVINGS_STEPPER_RESET = 'SETUP_SAVINGS_STEPPER_RESET'
export const SETUP_SAVINGS_STEPPER_UPDATE_MULTIPLE =
  'SETUP_SAVINGS_STEPPER_UPDATE_MULTIPLE'
export const SETUP_SAVINGS_REGISTER_CHILD = 'SETUP_SAVINGS_REGISTER_CHILD'
export const SETUP_SAVINGS_UPDATE_CHILD = 'SETUP_SAVINGS_UPDATE_CHILD'
export const SETUP_SAVINGS_REMOVE_CHILD = 'SETUP_SAVINGS_REMOVE_CHILD'

// ............... Document Upload  Actions .............
export const DOCUMENT_UPLOAD_STEPPER_INCREMENT =
  'DOCUMENT_UPLOAD_STEPPER_INCREMENT'

export const DOCUMENT_UPLOAD_STEPPER_DECREMENT =
  'DOCUMENT_UPLOAD_STEPPER_DECREMENT'

export const DOCUMENT_UPLOAD_STEPPER_RETAKE = 'DOCUMENT_UPLOAD_STEPPER_RETAKE'
export const STORE_UPLOAD_DATA = 'STORE_UPLOAD_DATA'
export const UPDATE_UPLOAD_STEP = 'UPDATE_UPLOAD_STEP'
export const DOCUMENT_UPLOAD_WITH_CAMERA = 'DOCUMENT_UPLOAD_WITH_CAMERA'

// ............... Plaid Integrations Actions .............
export const GET_LINK_TOKEN = 'GET_LINK_TOKEN'
export const PLAID_INTEGRATION_DONE = 'PLAID_INTEGRATION_DONE'

// ............... TransActions .............
export const UPDATE_TRANSACTION_PROMPT = 'UPDATE_TRANSACTION_PROMPT'
export const GET_RECIPIENT_BANK_DETAILS = 'GET_RECIPIENT_BANK_DETAILS'
export const GET_RECIPIENT_WALLET_DETAILS = 'GET_RECIPIENT_WALLET_DETAILS'
export const SHOW_CONFIRMATION_DIALOG = 'SHOW_CONFIRMATION_DIALOG'
export const SHOW_CONFIRMATION_DIALOG_OPTIONS =
  'SHOW_CONFIRMATION_DIALOG_OPTIONS'
export const RESET_CONFIRMATION_DIALOG_OPTIONS =
  'RESET_CONFIRMATION_DIALOG_OPTIONS'
export const RESET_CONFIRMATION_DIALOG = 'RESET_CONFIRMATION_DIALOG'
export const SHOW_CONFIRMATION_DIALOG_DATA = 'SHOW_CONFIRMATION_DIALOG_DATA'
export const RESET_CONFIRMATION_DIALOG_DATA = 'RESET_CONFIRMATION_DIALOG_DATA'
export const SHOW_SLIDE_UP = 'SHOW_SLIDE_UP'
export const CONNECT_BANK = 'CONNECT_BANK'
export const REPEAT_TRANSACTION = 'REPEAT_TRANSACTION'

export const SHOW_TRANSACTION_MODAL = 'SHOW_TRANSACTION_MODAL'
export const SHOW_TRANSACTION_SLIDEUP = 'SHOW_TRANSACTION_SLIDEUP'
export const TRANSACTIONS_WIDGET = 'TRANSACTIONS_WIDGET'

// ............... Alpaca  .............
export const ALPACA_STEPPER_INCREAMENT_STEP = 'ALPACA_STEPPER_INCREAMENT_STEP'
export const ALPACA_STEPPER_DECREMENT_STEP = 'ALPACA_STEPPER_DECREMENT_STEP'
export const ALPACA_STEPPER_RESET_STEP = 'ALPACA_STEPPER_RESET_STEP'
export const ALPACA_STEPPER_UPDATE_STEP = 'ALPACA_STEPPER_UPDATE_STEP'
export const ALPACA_STEPPER_UPDATE_USER = 'ALPACA_STEPPER_UPDATE_USER'
export const ALPACA_STEPPER_UPDATE_TAX_AND_CITIZENSHIP =
  'ALPACA_STEPPER_UPDATE_TAX_AND_CITIZENSHIP'
export const ALPACA_STEPPER_UPDATE_VIDEO = 'ALPACA_STEPPER_UPDATE_VIDEO'
export const ALPACA_STEPPER_UPDATE_ASSISTENCE_NEEDED =
  'ALPACA_STEPPER_UPDATE_ASSISTENCE_NEEDED'
export const ALPACA_STEPPER_UPDATE_FUNDING = 'ALPACA_STEPPER_UPDATE_FUNDING'
export const ALPACA_STEPPER_UPDATE_EMPLOYMENT_STATUS =
  'ALPACA_STEPPER_UPDATE_EMPLOYMENT_STATUS'
export const ALPACA_STEPPER_UPDATE_EMPLOYER = 'ALPACA_STEPPER_UPDATE_EMPLOYER'
export const ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS =
  'ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS'
export const ALPACA_STEPPER_RESET = 'ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS'
export const ALPACA_STEPPER_AFFILIATIONS = 'ALPACA_STEPPER_AFFILIATIONS'
export const ALPACA_STEPPER_UPDATE_BENEFICIARY =
  'ALPACA_STEPPER_UPDATE_BENEFICIARY'
export const ALPACA_STEPPER_UPDATE_PROGRESS_STATUS =
  'ALPACA_STEPPER_UPDATE_PROGRESS_STATUS'

// ............... Activation  .............
export const ACTIVATION_REGISTER_INCREMENT_STEP =
  'ACTIVATION_REGISTER_INCREAMENT_STEP'
export const ACTIVATION_REGISTER_DECREMENT_STEP =
  'ACTIVATION_REGISTER_DECREMENT_STEP'
export const ACTIVATION_REGISTER_ADD_CHILD = 'ACTIVATION_REGISTER_ADD_CHILD'
export const ACTIVATION_REGISTER_REMOVE_CHILD =
  'ACTIVATION_REGISTER_REMOVE_CHILD'
export const ACTIVATION_REGISTER_UPDATE_CHILD =
  'ACTIVATION_REGISTER_UPDATE_CHILD'
export const ACTIVATION_REGISTER_UPDATE_GOAL = 'ACTIVATION_REGISTER_UPDATE_GOAL'
export const UPDATE_RSVP_STATUS = 'UPDATE_RSVP_STATUS'
export const ACTIVATION_ADD_PARENT_INFORMATION =
  'ACTIVATION_ADD_PARENT_INFORMATION'
export const UPDATE_ACTIVATION_USER_PROFILE = 'UPDATE_ACTIVATION_USER_PROFILE'
export const ACTIVATION_REGISTER_RESET = 'ACTIVATION_REGISTER_RESET'
export const ADD_ACTIVATION_EVENT_INFORMATION =
  'ADD_ACTIVATION_EVENT_INFORMATION'

// ............... Setup MY529 Account Stepper Actions .............
export const SETUP_MY529_STEPPER_INCREAMENT = 'SETUP_MY529_STEPPER_INCREAMENT'

export const SETUP_MY529_STEPPER_DECREAMENT = 'SETUP_MY529_STEPPER_DECREAMENT'

export const SETUP_MY529_STEPPER_UPDATE = 'SETUP_MY529_STEPPER_UPDATE'
export const SETUP_MY529_STEPPER_UPDATE_MULTIPLE =
  'SETUP_MY529_STEPPER_UPDATE_MULTIPLE'
export const SETUP_MY529_STEPPER_RESET = 'SETUP_MY529_STEPPER_RESET'
export const SETUP_MY529_ADD_CHILDREN_INFO = 'SETUP_MY529_ADD_CHILDREN_INFO'
export const SETUP_MY529_ADD_BANK_INF0 = 'SETUP_MY529_ADD_BANK_INFO'
export const SETUP_MY529_INITIAL_INVESTMENT_AMOUNT =
  'SETUP_MY529_INITIAL_INVESTMENT_AMOUNT'
