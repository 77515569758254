import React from 'react'

type PropsType = {
  title: string
  onBack?: () => void
  hideButton?: boolean
}

const FullPageTitle: React.FC<PropsType> = ({ title, onBack, hideButton }) => {
  return (
    <>
      <h1 className={styles.title}>
        {!hideButton && (
          <button
            type="button"
            className={`btn ${styles.btn}`}
            onClick={onBack}
          >
            <img
              src={`/assets/images/back.svg`}
              alt="back"
              className={styles.icon}
            />
          </button>
        )}
        {title}
      </h1>
    </>
  )
}

const styles = {
  title: 'fullpage-title',
  btn: 'flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[rgba(0,79,78,0.2)] border-2',
  icon: 'block',
}

export default FullPageTitle
