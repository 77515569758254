import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { modifyingExistingUserAccount } from '../../../redux/actions/editAccountActions'
import { RootReduxStateType } from '../../../types'
import Toast from '../../Common/Toast'
import Navigation from '../../Common/Navigation'
import TextField from '../../Common/TextField'
// @ts-ignore
import PasswordStrengthChecker from '../../Common/Partials/PasswordStrengthChecker'

const PasswordDetails: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector((state: RootReduxStateType) => state.user)

  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [_, setLoading] = useState(false)
  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)
  const [requiredLength] = useState(8)
  const [match, setMatch] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [showToast, setShowToast] = useState<boolean>(false)
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const [showNewPassword, setNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  useEffect(() => {
    timeOutNotification()
  }, [showToast, showErrorToast])

  useEffect(() => {
    setValidLength(password.length >= requiredLength ? true : false)
    setUpperCase(password.toLowerCase() !== password)
    setLowerCase(password.toUpperCase() !== password)
    setHasNumber(/\d/.test(password))
    setSpecialChar(/[ `!@#$%^&*()_/+\-=\]{};':"\\|,.<>?~]/.test(password))
    setMatch(password === repeatPassword)
    setIsAvailable(
      currentPassword.length > 0 && password.length > 0 ? true : false
    )
    setIsValid(
      isAvailable &&
        validLength &&
        hasNumber &&
        upperCase &&
        lowerCase &&
        specialChar &&
        match
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, repeatPassword, currentPassword, match, isAvailable])

  const timeOutNotification = () => {
    const timer = setTimeout(() => {
      setShowToast(false)
    }, 3000)

    return () => clearTimeout(timer)
  }

  useEffect(() => {
    console.log('Available', isAvailable)
    console.log('Valid', isValid)
  }, [isAvailable, isValid])

  const handleOnClick = async () => {
    try {
      if (currentPassword && password === repeatPassword) {
        setLoading(true)
        const res = await modifyingExistingUserAccount(
          currentPassword,
          password,
          user
        )

        if (res.success) {
          setError(false)
          setShowToast(true)
          setShowErrorToast(false)
          setLoading(false)
        } else {
          setError(true)
          setShowToast(true)
          setShowErrorToast(true)
          setLoading(false)
        }
      }
    } catch (error) {
      setShowErrorToast(true)
    }
  }

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4">
            <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
              <button
                type="button"
                className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
                onClick={() => navigate(-1)}
              >
                <img
                  src={`/assets/images/back.svg`}
                  alt="back"
                  className="block text-primaryDark"
                />
              </button>
              Change Password
            </h1>
          </div>

          <div
            className={`lg:w-[37%] lg:mx-auto fixed inset-x-0 z-40 top-0  ${
              showToast ? 'translate-x-0' : '-translate-x-full'
            } ease-in-out duration-300 transition-transform`}
          >
            {showToast && (
              <div className="absolute inset-x-0 top-0 z-40 lg:w-[37%] mx-auto">
                <Toast
                  styles={error ? 'error' : 'plain'}
                  description={
                    error
                      ? 'Error! Failed to update your password.'
                      : 'Awesome! Your password changes are saved.'
                  }
                  className="my-5 mx-2"
                />
              </div>
            )}
          </div>

          <TextField
            type={showOldPassword ? 'text' : 'password'}
            name="oldPassword"
            label="Old password"
            classes={styles.input}
            value={currentPassword}
            passwordField
            onChange={(e) => setCurrentPassword(e.target.value)}
            showPasswordIconStatus={showOldPassword}
            changeIcon={() => setShowOldPassword(!showOldPassword)}
          />

          <TextField
            type={showNewPassword ? 'text' : 'password'}
            name="password"
            label="New password"
            classes={styles.input}
            value={password}
            passwordField
            onChange={(e) => setPassword(e.target.value)}
            showPasswordIconStatus={showNewPassword}
            changeIcon={() => setNewPassword(!showNewPassword)}
          />

          {password.length !== 0 && (
            <PasswordStrengthChecker password={password} />
          )}

          <TextField
            type={showConfirmPassword ? 'text' : 'password'}
            name="repeatPassword"
            label="Confirm password"
            classes={styles.input}
            value={repeatPassword}
            passwordField
            onChange={(e) => setRepeatPassword(e.target.value)}
            showPasswordIconStatus={showConfirmPassword}
            changeIcon={() => setShowConfirmPassword(!showConfirmPassword)}
          />
          {!!!match && (
            <p className={styles.match}>{t('User.PasswordsNotMatch')}</p>
          )}
          <button
            className={styles.button}
            onClick={handleOnClick}
            disabled={!isValid}
          >
            Update Password
          </button>
        </div>
      </Navigation>
    </>
  )
}

const styles = {
  input: 'text-input mb-[16px]',
  button: 'btn btn--big btn--dark mb-[16px]',
  match: 'text-warning text-sm mb-[16px]',
}

export default PasswordDetails
