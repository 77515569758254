import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { increamentMy529Step } from '../../../../redux/actions/my529Actions'
import SafeAreaView from '../../../Common/SafeAreaView'
import My529SetupBottom from '../My529SetupBottom'

const WelcomeStep = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleNext = () => {
    dispatch(increamentMy529Step())
  }

  const handleCancel = () => {
    navigate('/my529')
  }

  return (
    <div>
      <SafeAreaView>
        <div className="rounded-lg border-2 border-[#EFF9F9] p-1 mb-4">
          <iframe
            title="IFrameWelcome"
            className="w-full h-[220px]"
            src="https://player.vimeo.com/video/819299312?h=4fa278fc54&title=0&byline=0&portrait=0"
            width="100%"
            //height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        {/* <AzureMediaVideoPlayer video="https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/e9cad2e8-b34f-400b-866e-38b4301fdf02/ssn_video.ism/manifest" /> */}
        <p className="text-[18px] font-semibold">Before we begin</p>

        <p className="mt-5">
          Congratulations for taking this step to set up a savings account with
          Sootchy! We created Sootchy savings accounts to help you fund a bright
          and prosperous future for your family. <br /> <br /> We'll be asking
          you some questions to collect information that helps us keep your
          account safe and secure, and also to comply with federal law.
          Completing this account setup process usually takes about 5 minutes.{' '}
          <br />
          <br />
          Your data is safe with us, and if at any point you need assistance,
          please call us on{' '}
          <span
            className="cursor-pointer text-primary"
            onClick={() => (window.location.href = `tel:${+13236774482}`)}
          >
            (323) 677-4482
          </span>
        </p>
      </SafeAreaView>

      <My529SetupBottom handleNext={handleNext} handleCancel={handleCancel} />
    </div>
  )
}

export default WelcomeStep
