import React from 'react'
import { useTranslation } from 'react-i18next'
import Navigation from '../../../Common/Navigation'

const SendVerificationCodeSuccess = () => {
  const { t } = useTranslation()
  return (
    <Navigation>
      <div className="w-[90%] md:w-[570px] m-auto">
        <h1 className="fullpage-title">{t('User.ResetPassword')}</h1>
        <p>{t('User.ResetPasswordSent')}</p>
      </div>
    </Navigation>
  )
}

export default SendVerificationCodeSuccess
