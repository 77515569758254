import React from 'react'
// @ts-ignore
import SootchyGreenLogo from '../../../assets/SootchyGreenLogo.png'
import { useSelector } from 'react-redux'
import { RootReduxStateType } from '../../../types'
import dayjs from 'dayjs'

type PropsType = {
  children: React.ReactNode
}

const Layout: React.FC<PropsType> = ({ children }) => {
  const activationInfo = useSelector(
    (state: RootReduxStateType) => state.activation.activationInfo
  )

  return (
    <div className="m-auto sm:w-[70%] mt-12 md:w-[800px] lg:w-[900px]">
      <div className="flex flex-col items-start px-5 sm:flex-row sm:items-center">
        <img
          src={SootchyGreenLogo}
          alt="SootchyGreenLogo"
          className="h-[40px]"
        />
        <div className="mt-4 sm:px-6">
          <p className="font-thin text-[16px] pb-1">
            {activationInfo.activationName}
          </p>

          <p className="text-[15px] font-thin text-[#969696]">
            {dayjs(activationInfo.activationEventDate).format(
              'MMMM D YYYY, h:mm A'
            )}{' '}
            at {activationInfo.activationEventVenue}
          </p>
        </div>
      </div>

      {/* Border line */}
      <div className="border-b-2 border-dashed border-[#C4E8E8] my-8"></div>
      {children}
      {/* Border line */}
      <div className="border-b-2 border-dashed border-[#C4E8E8] my-10"></div>

      {/* Footer */}
      <div className="text-center pb-10">
        <p className="text-[23px] font-semibold mb-4">Questions?</p>
        <p className="font-thin py-1">
          Contact us at <span className="text-primary">help@sootchy.com</span>{' '}
          or <span className="text-primary">(323) 677-4482</span>
        </p>
        <p className="font-thin py-1">
          We are available 9:00AM to 6:00PM (Monday to Friday)
        </p>
        <img
          src={SootchyGreenLogo}
          alt="SootchyGreenLogo"
          className="h-[35px] m-auto mt-12"
        />
        <p className="font-thin mt-4 text-[#969696]">
          © 2022 Sootchy, Inc. All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default Layout
