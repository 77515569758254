import React, { useEffect } from 'react'
import i18next from '../../../i18n'

const FooterLanguage: React.FC = () => {
  useEffect(() => {
    const localLang = localStorage.getItem('lang')

    localStorage.setItem('lang', localLang ? localLang : 'en')
  }, [])

  const handleLangChange = (key: string) => {
    i18next.changeLanguage(key)
    localStorage.setItem('lang', key)
  }

  return (
    <div className={styles.lang}>
      {[
        { title: 'English', key: 'en' },
        { title: 'Español ', key: 'es' },
      ].map((item, i) => (
        <button
          key={i}
          onClick={() => handleLangChange(item.key)}
          className={`${styles.langBtn} ${
            i18next.language === item.key ? 'font-bold' : 'font-normal'
          }`}
        >
          {item.title}
        </button>
      ))}
    </div>
  )
}

const styles = {
  lang: 'mb-[16px] lg:mx-auto lg:order-2',
  langBtn:
    "p-0 border-0 mr-[8px] pr-[8px] relative before:h-[17px] before:w-[2px] before:h-1px before:content-[''] before:block before:absolute before:top-[50%] before:right-0 before:bg-primaryDark last:before:content-none before:translate-y-[-50%]",
}

export default FooterLanguage
