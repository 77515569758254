import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import SlideUp from '../../../../Common/SlideUp'
import { WalletType, BankType } from '../../../../../types/transactions'
// @ts-ignore
import BankStarIcon from '../../../../../assets/BankStarIcon.png'
// @ts-ignore
import Success from '../../../../../assets/Success.png'
// @ts-ignore
import BoldBottomIcon from '../../../../../assets/BoldBottomIcon.png'
import { RootReduxStateType } from '../../../../../types'
import {
  handleAddMoneyFromBankToWallet,
  handleConfirmationDialogData,
  handleConfirmationDisplay,
  handleConfirmationOptions,
} from '../../../../../redux/actions/transactionsActions'
import { createAlertAction } from '../../../../../redux/actions/alertActions'
import { PreventNegativeValues } from '../../../../Common/preventNegativeValues'
import { PulseLoader } from 'react-spinners'

type Proptypes = {
  isActive: boolean
  handleIsActiveChange: (e: any) => void
  currentWallet: WalletType
  defaultBank: BankType | undefined
  setDefaultBank: (e: any) => void
  amountToAdd: string
  setAmountToAdd: (e: any) => void
  openModal: boolean
  setOpenModal: (e: any) => void
  banks: any
}

const AddingMoneyToWallet = ({
  isActive,
  handleIsActiveChange,
  currentWallet,
  defaultBank,
  setDefaultBank,
  amountToAdd,
  setAmountToAdd,
  banks,
}: Proptypes) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 767px)')
  const transactions = useSelector(
    (state: RootReduxStateType) => state.transactions
  )

  //const [banks, setBanks] = useState<BankType[]>()
  const [showMore, setShowMore] = useState<boolean>(false)
  const [transferLoading, setTransferLoading] = useState<boolean>(false)
  const [isSuccess, setIsSucess] = useState<boolean>(false)

  const handleAddMoney = () => {
    dispatch(handleConfirmationDisplay(true))
    dispatch(
      handleConfirmationDialogData({
        transactionType: 'deposit',
        sender: defaultBank?.accountName,
        recipient: currentWallet.walletName,
        amount: amountToAdd,
      })
    )
  }

  const handleAddTransaction = async () => {
    try {
      setTransferLoading(true)

      const res: any = await handleAddMoneyFromBankToWallet(
        defaultBank?.accountName,
        currentWallet.walletName,
        amountToAdd
      )

      if (res.status === 200) {
        setIsSucess(true)
      }
    } catch (error: any) {
      console.error(error)
      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Error Occured',
          message: error.message,
          autoClose: true,
          autoCloseDuration: 10000,
        })
      )
    } finally {
      setTransferLoading(false)
    }
  }

  const SuccessMessage = () => {
    return (
      <div>
        <div className="flex flex-col items-center justify-center py-2">
          <img src={Success} className="w-[40px]" alt="check" />
          <p className="py-4 text-[24px] font-semibold text-textGreen">
            ${amountToAdd} is on the way!
          </p>
          <p>It may take a few days for the money to reflect on the account.</p>
        </div>
        <div className="py-2  self-center bg-white w-full sm:relative sm:w-full">
          <button
            className="w-full mx-1 py-4  border-2 border-[#E3F2F2]"
            onClick={() => {
              setIsSucess(false)
              dispatch(
                handleConfirmationOptions({
                  handleChange: false,
                  handleCancel: false,
                  handleConfirm: false,
                })
              )

              setAmountToAdd('')
              handleIsActiveChange(undefined)
              window.location.reload()
            }}
          >
            Awesome
          </button>
        </div>
      </div>
    )
  }

  const AddMoneyForm = () => {
    return (
      <div>
        <div className="flex justify-between items-center mb-[27px]">
          <h3 className="py-4 text-[24px] font-semibold text-textGreen">
            Add Money
          </h3>
        </div>
        <div>
          <div className="flex space-x-4 items-center px-[16px] py-[8px] bg-[#E3F2F2] mb-[16px] rounded-[10px]">
            <p className="text-[#87AEAE] font-semibold leading-[120.93%] text-[24px]">
              $
            </p>
            <input
              type="number"
              min="0"
              onKeyPress={PreventNegativeValues}
              value={amountToAdd}
              onChange={(e) => setAmountToAdd(e.target.value)}
              name="Amount"
              aria-label="Amount"
              autoFocus
              className="w-full bg-[#E3F2F2] text-[36px] font-semibold focus: outline-0"
            />
          </div>

          <div className="mb-[34px] bg-[#E3F2F2]  rounded-[10px] min-h-[40px] flex flex-col items-center">
            <div className="px-[16px] py-[8px] flex justify-between items-center w-full">
              <div className="flex  justify-between items-center ">
                {defaultBank?.bankLogo ? (
                  <img
                    src={`data:image/png;base64,${defaultBank?.bankLogo}`}
                    alt="Bank Logo"
                    className="h-[32px]"
                  />
                ) : (
                  <p className="">{defaultBank?.bankName}</p>
                )}
                <p className="text-[14px] px-3">
                  {`XXX$${defaultBank?.maskedAccountNumber}`}
                </p>
              </div>

              <div className="flex items-center">
                {banks &&
                  banks.map(
                    (bank: BankType, index: number) =>
                      bank.isDefault &&
                      bank === defaultBank && (
                        <div className="px-1 ">
                          <img src={BankStarIcon} alt={'BankStarIcon'} />
                        </div>
                      )
                  )}

                <button
                  className="border-0 focus:outline-0"
                  onClick={() => setShowMore(!showMore)}
                >
                  <img
                    src={BoldBottomIcon}
                    alt="BoldBottomIcon"
                    className="w-[10px]"
                  />
                </button>
              </div>
            </div>

            <div
              className={` bg-white w-[99%] max-h-[240px] overflow-y-scroll  rounded-b-lg border-b-2 border-[#E3F2F2] px-[16px] mx-auto transform transition ease-in-out duration-1000 ${
                showMore ? 'block' : 'hidden'
              }`}
            >
              {banks &&
                banks.map((bank: BankType, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      setDefaultBank(bank)
                      setShowMore(false)
                    }}
                    className="py-4 flex justify-between items-center border-b-2 border-[#C4E8E8] border-dashed"
                  >
                    <div className="flex justify-between items-center">
                      {bank.bankLogo ? (
                        <img
                          src={`data:image/png;base64,${bank.bankLogo}`}
                          alt="Bank Logo"
                          className="h-[32px]"
                        />
                      ) : (
                        <p className="">{bank.bankName}</p>
                      )}

                      <p className="text-[14px] px-3">
                        {`XXX${bank.maskedAccountNumber}`}
                      </p>

                      {bank.isDefault && (
                        <div className="px-1">
                          <img src={BankStarIcon} alt={'BankStarIcon'} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="flex justify-between py-4 border-t-2 border-dashed border-[#E3F2F2] self-center bg-white w-full sm:relative sm:w-full">
          <button
            className="w-[70%] mx-1 py-4 border-2 border-[#E3F2F2]"
            onClick={() => {
              handleIsActiveChange(undefined)
            }}
          >
            Cancel
          </button>
          <button
            className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
            // onClick={
            //   user.is2FAEnabled ? () => setOpenModal(true) : handleAddMoney
            // }
            onClick={handleAddMoney}
            disabled={amountToAdd ? false : true}
          >
            {transferLoading ? (
              <PulseLoader size={8} color="#FFFFFF" />
            ) : (
              'Add Money'
            )}
          </button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    //transactions.confirmationDialogOptions.handleCancel && setAmountToAdd('')
    transactions.confirmationDialogOptions.handleConfirm &&
      handleAddTransaction()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions.confirmationDialogOptions])

  const displayComponent = () => {
    return isSuccess ? <SuccessMessage /> : <AddMoneyForm />
  }

  if (isActive) {
    return (
      <div className="fixed h-full w-screen z-50 top-0 left-0">
        <div className="absolute bg-black opacity-80 h-screen w-screen"></div>

        {isMobile ? (
          <div className="absolute w-full md:w-[570px] bottom-0 inset-x-0 mx-auto transform transition ease-in-out duration-300">
            <div className="bg-white shadow shadow-gray-400/50 rounded-t-[10px] p-[20px] z-40">
              <SlideUp>{displayComponent()}</SlideUp>
            </div>
          </div>
        ) : (
          <div
            className="absolute bg-white w-full md:w-[570px] ease-in-out duration-300 transition-transform rounded-xl py-6 px-6"
            style={{
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {displayComponent()}
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default AddingMoneyToWallet
