import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUserAffiliations,
  alpacaStepperUpdateVideo,
  alpacaUserAffiliation,
  decrementAlpacaStepperStep,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'
// @ts-ignore
import ContactSupport from '../../../assets/ContactSupport.png'
import TelephoneNumber from '../../Common/TelephoneNumber'

const AlpacaStepThirteen: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [showAssistanceNeeded, setShowAssistanceNeeded] =
    useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  //const [affiliations, setAffiliations] = useState(new Array())
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    alpaca.user.phoneNumber ?? null
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [isAffiliatedWithExchangeOrFinra, setIsAffiliatedWithExchangeOrFinra] =
    useState<boolean>(false)
  const [isControlPerson, setIsControlPerson] = useState<boolean>(false)
  const [isPoliticallyExposed, setIsPoliticallyExposed] =
    useState<boolean>(false)
  const [isImmediateFamilyExposed, setIsImmediateFamilyExposed] =
    useState<boolean>(false)
  // const [isAffiliatedPerson, setIsAffiliatedPerson] = useState<boolean>(false)
  const [isNoneSelected, setIsNoneSelected] = useState<boolean>(false)

  // const handleChange = (e: any) => {
  //   const { value, checked } = e.target

  //   if (checked) {
  //     setAffiliations([...affiliations, value])
  //     if (value === '5') {
  //       //setDisabled(true)
  //       affiliations.map((item: any) => {
  //         console.log('Disable', item)
  //         //setAffiliations(affiliations.filter((e) => e !== item))
  //       })
  //       //setAffiliations(affiliations.filter((e) => e !== value))
  //     }
  //   } else {
  //     setAffiliations(affiliations.filter((e) => e !== value))
  //   }
  // }

  const handleAPICall = async () => {
    const alpacaAffiliations = alpaca.affiliations

    if (
      isAffiliatedWithExchangeOrFinra ===
        alpacaAffiliations.isAffiliatedWithExchangeOrFinra ||
      isControlPerson === alpacaAffiliations.isControlPerson ||
      isPoliticallyExposed === alpacaAffiliations.isPoliticallyExposed ||
      isImmediateFamilyExposed === alpacaAffiliations.isImmediateFamilyExposed
    ) {
      dispatch(increamentAlpacaStepperStep())
    } else {
      setLoading(true)
      dispatch(
        alpacaStepperUpdateUserAffiliations('isControlPerson', isControlPerson)
      )
      dispatch(
        alpacaStepperUpdateUserAffiliations(
          'isPoliticallyExposed',
          isPoliticallyExposed
        )
      )
      dispatch(
        alpacaStepperUpdateUserAffiliations(
          'isImmediateFamilyExposed',
          isImmediateFamilyExposed
        )
      )
      dispatch(
        alpacaStepperUpdateUserAffiliations(
          'isAffiliatedWithExchangeOrFinra',
          isAffiliatedWithExchangeOrFinra
        )
      )
      alpacaUserAffiliation({
        userAccountID: user.id,
        isAffiliatedWithExchangeOrFinra: isAffiliatedWithExchangeOrFinra,
        isControlPerson: isControlPerson,
        isPoliticallyExposed: isPoliticallyExposed,
        isImmediateFamilyExposed: isImmediateFamilyExposed,
        //isAffiliatedPerson: isAffiliatedPerson,
      })
        .then(() => {
          setLoading(false)
          dispatch(increamentAlpacaStepperStep())
        })
        .catch((err) => {
          setLoading(false)
          console.log('Unable to create user affiliations', err)
        })
    }
  }

  const handleNext = () => {
    //dispatch(increamentAlpacaStepperStep())
    // if (
    //   isAffiliatedWithExchangeOrFinra ||
    //   isControlPerson ||
    //   isPoliticallyExposed ||
    //   isImmediateFamilyExposed ||
    //   isAffiliatedPerson
    // ) {
    //   if (isAffiliatedWithExchangeOrFinra || isControlPerson) {
    //     setShowAssistanceNeeded(true)
    //     if (showAssistanceNeeded) {
    //       handleAPICall()
    //     }
    //   } else {
    //     handleAPICall()
    //   }
    // }

    if (
      isAffiliatedWithExchangeOrFinra ||
      isControlPerson ||
      isPoliticallyExposed ||
      isImmediateFamilyExposed ||
      isNoneSelected
    ) {
      if (isAffiliatedWithExchangeOrFinra || isControlPerson) {
        setShowAssistanceNeeded(true)
        if (showAssistanceNeeded) {
          handleAPICall()
        }
      } else {
        handleAPICall()
      }
    }
  }

  const handleCancel = () => {
    if (showAssistanceNeeded) {
      setShowAssistanceNeeded(false)
    } else {
      dispatch(decrementAlpacaStepperStep())
    }
  }

  const getUserDetails = async () => {}

  useEffect(() => {
    getUserDetails()
    dispatch(alpacaStepperUpdateVideo(null))
  }, [])

  useEffect(() => {
    if (isNoneSelected) {
      setIsControlPerson(false)
      setIsAffiliatedWithExchangeOrFinra(false)
      setIsPoliticallyExposed(false)
      setIsImmediateFamilyExposed(false)
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [
    isAffiliatedWithExchangeOrFinra,
    isPoliticallyExposed,
    isImmediateFamilyExposed,
    isControlPerson,
    isNoneSelected,
  ])

  return (
    <div>
      <SafeAreaView>
        {showAssistanceNeeded ? (
          <div>
            <div className="pt-[80px] mb-[20px]">
              <img
                src={ContactSupport}
                alt="ContactSupport"
                className="w-[96px]"
              />
            </div>
            <p className="text-[24px] font-semibold py-3">
              We need to contact you
            </p>

            <p className="w-[90%] py-2">
              We can’t process ---PLACEHOLDER--- automatically, but we can do
              that with the help of our friendly reps.
            </p>

            <p className="w-[90%] py-1">
              Please check if your phone number is correct so our reps can call
              you.
            </p>

            <div className="py-6">
              <TelephoneNumber
                value={phoneNumber ?? ''}
                onChange={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
              />
            </div>
          </div>
        ) : (
          <>
            <p className="text-[18px] py-[16px] font-semibold">
              Your affiliations
            </p>

            <p className="text-[18px] py-[16px] font-normal">
              Do any of the following apply to you or a member of your immediate
              family?
            </p>

            <div>
              <div className="mb-[16px] flex items-center space-x-4 ">
                <input
                  className="accent-primary focus:accent-primary w-6 h-6"
                  id="1"
                  type="checkbox"
                  // value={isAffiliatedWithExchangeOrFinra ? true : false}
                  name="affiliations"
                  onChange={() =>
                    setIsAffiliatedWithExchangeOrFinra(
                      !isAffiliatedWithExchangeOrFinra
                    )
                  }
                  checked={isAffiliatedWithExchangeOrFinra}
                  disabled={disabled}
                />
                <label htmlFor="1" className="w-[90%]">
                  Affiliated or work with a US registered broker-dealer or
                  FINRA.
                </label>
              </div>

              <div className="mb-[16px] flex items-center space-x-4  ">
                <input
                  className="accent-primary focus:accent-primary w-6 h-6"
                  id="2"
                  type="checkbox"
                  //value={2}
                  name="affiliations"
                  onChange={() => setIsControlPerson(!isControlPerson)}
                  //checked={isControlPerson}
                  checked={isControlPerson}
                  disabled={disabled}
                />
                <label htmlFor="2" className="w-[90%]">
                  Senior executive at or a 10% or greater shareholder of a
                  publicly traded company.
                </label>
              </div>

              <div className="mb-[16px] flex items-center space-x-4  ">
                <input
                  className="accent-primary focus:accent-primary w-6 h-6"
                  id="3"
                  type="checkbox"
                  //value={3}
                  name="affiliations"
                  onChange={() =>
                    setIsPoliticallyExposed(!isPoliticallyExposed)
                  }
                  checked={isPoliticallyExposed}
                  disabled={disabled}
                />
                <label htmlFor="3" className="w-[90%]">
                  I am a senior political figure.
                </label>
              </div>

              <div className="mb-[16px] flex items-center space-x-4 ">
                <input
                  className="accent-primary focus:accent-primary w-6 h-6"
                  id="4"
                  type="checkbox"
                  //value={4}
                  name="affiliations"
                  onChange={() =>
                    setIsImmediateFamilyExposed(!isImmediateFamilyExposed)
                  }
                  checked={isImmediateFamilyExposed}
                  disabled={disabled}
                />
                <label htmlFor="4" className="w-[90%]">
                  I am a family member or relative of a senior political figure.
                </label>
              </div>

              <div className="mb-[16px] flex items-center space-x-4 ">
                <input
                  className="accent-primary focus:accent-primary w-6 h-6"
                  id="5"
                  type="checkbox"
                  //value={5}
                  name="affiliations"
                  checked={isNoneSelected}
                  onChange={() => setIsNoneSelected(!isNoneSelected)}
                  //disabled={disabled}
                />
                <label htmlFor="5" className="w-[90%]">
                  None of the above apply to me or my family.
                </label>
              </div>
            </div>

            <p
              onClick={() =>
                dispatch(alpacaStepperUpdateAssistenceNeeded(true))
              }
              className="underline text-primary text-[14px] underline-offset-4 mt-4 py-3 cursor-pointer"
            >
              I need assistance
            </p>
          </>
        )}
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={
          isAffiliatedWithExchangeOrFinra ||
          isControlPerson ||
          isPoliticallyExposed ||
          isImmediateFamilyExposed ||
          isNoneSelected
            ? false
            : true
        }
        loading={loading}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </div>
  )
}

export default AlpacaStepThirteen
