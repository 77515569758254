import React, { useEffect, useRef, useState } from 'react'
import { Camera } from 'react-camera-pro'
import { HiX } from 'react-icons/hi'
import { IoMdReverseCamera } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../../api'
import { handleUploadByCamera } from '../../../../redux/actions/generalActions'
import { RootReduxStateType } from '../../../../types'

type Proptypes = {
  loadedUser: any
  requiredSteps: number
  onCancel: () => void
}

const CameraUploadComponent = ({
  loadedUser,
  requiredSteps,
  onCancel,
}: Proptypes) => {
  const camera = useRef<any>(null)
  const dispatch = useDispatch()
  const document = useSelector((state: RootReduxStateType) => state.general)
  const [numberOfCameras, setNumberOfCameras] = useState(0)
  const [image, setImage] = useState<any>(null)
  const [imagesTaken, setImagesTaken] = useState<any[]>([])
  const [isPhotoTaken, setIsPhotoTaken] = useState(false)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  )

  const handleFileUpload = async () => {
    try {
      let formData = new FormData()

      if (image) {
        if (loadedUser) {
          let silaUserID: any = loadedUser.silaUserAccountID

          if (document.documentSelectedForUpload.isFrontAndBackRequired) {
            imagesTaken.map((image) => formData.append('dataStream', image))
          } else formData.append('dataStream', image)

          formData.append('SilaUserAccountID', silaUserID)
          formData.append(
            'SilaDocumentType',
            document.documentSelectedForUpload.silaDocumentType
          )
          formData.append('CreatedUserAccountID', loadedUser.userAccountID)
          formData.append('SourceProcess', 'WebApplication')

          const response: any = await api.post(
            '/SilaDocument/Upload/Stream',
            formData
          )

          console.log('Response from Upload', response)
          if (response.status === 200) {
            //console.log('Response from Upload', response)
            //navigate('/savings-account-verificationed')
            dispatch(handleUploadByCamera(true))
            window.location.reload()
            // to skip documents which satisfy other conditions
            //implement redux
          }
        }
      }
    } catch (error) {
      console.log('Failed to upload file')
    }
  }

  const handleContinueButton = async () => {
    try {
      if (document.documentSelectedForUpload.isFrontAndBackRequired) {
        if (imagesTaken.length !== 2) {
          setIsPhotoTaken(false)
        } else {
          handleFileUpload()
        }
      } else {
        handleFileUpload()
      }
    } catch (error) {
      console.log('Failed to upload file')
    }
  }

  const PictureReview = () => {
    return (
      <div className="bg-[#021212] border-none w-full min-h-[100%] flex justify-center">
        <div className="bg-[#021212] border-none min-h-[100%] w-full sm:w-[376px] flex flex-col justify-between">
          <div className="cursor-pointer bg-[#021212] opacity-75 h-[5%] sm:h-[10%] text-white flex justify-end px-[10px] pt-[15px]">
            <HiX onClick={onCancel} />
          </div>
          <div className="border-none text-center text-white">
            {document.documentSelectedForUpload.documentList === 51 ? (
              <p className="py-2">Your face within the frame</p>
            ) : (
              <p className="py-2">
                {imagesTaken.length < 2 ? 'Front' : 'Back'} side of your{' '}
                {document.documentSelectedForUpload.silaDocumentTypeLabel}{' '}
                within the frame
              </p>
            )}
            <div className="flex justify-center items-center">
              {image && (
                <img
                  src={image}
                  className="object-cover h-[300px] w-[400px]"
                  alt="proof of identification"
                />
              )}
            </div>

            {document.documentSelectedForUpload.documentList === 51 ? (
              <p className="mx-[10px] py-2">
                Is your face within the frame and your facial features
                distinguishable?
              </p>
            ) : (
              <p className="mx-[10px] py-2">
                Is the document within the frame and the text readable?{' '}
              </p>
            )}
          </div>

          <div className="flex justify-between py-[20px] mx-[10px]">
            <button
              className="text-primaryLight h-[48px] w-[45%] border-primaryLight"
              onClick={() => setIsPhotoTaken(false)}
            >
              No, retake
            </button>
            <button
              className="bg-primaryLight h-[48px] w-[50%] border-0"
              onClick={handleContinueButton}
            >
              Yes, Continue
            </button>
          </div>
        </div>
      </div>
    )
  }

  // useEffect(() => {
  //   ;(async () => {
  //     const devices = await navigator.mediaDevices.enumerateDevices()
  //     const videoDevices = devices.filter((i) => i.kind == 'videoinput')
  //     setDevices(videoDevices)
  //   })()
  // })

  const getVideoDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter((i) => i.kind === 'videoinput')
      setDevices(videoDevices)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getVideoDevices()
  }, [setDevices])

  return (
    <div className="block fixed bg-[#021212BF] w-[100%] h-[100vh] top-0 left-0 opacity-100 z-[100]">
      <div
        data-test="camera-modal"
        className="fixed bg-[#021212] opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-[100%] sm:min-h-[80%] w-[100%] sm:max-w-[376px] md:max-w-[376px] lg:max-w-[376px] rounded-xl flex z-[101]"
      >
        <div>
          {isPhotoTaken ? (
            <PictureReview />
          ) : (
            <>
              <Camera
                ref={camera}
                numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
                videoSourceDeviceId={activeDeviceId}
                errorMessages={{
                  noCameraAccessible:
                    'No camera device accessible. Please connect your camera or try a different browser.',
                  permissionDenied:
                    'Permission denied. Please refresh and give camera permission.',
                  switchCamera:
                    'It is not possible to switch camera to different one because there is only one video device accessible.',
                  canvas: 'Canvas is not supported.',
                }}
              />

              <div className="fixed w-full sm:max-w-[376px] md:max-w-[376px] lg:max-w-[376px] h-[100vh]">
                <div className="cursor-pointer bg-[#021212] opacity-75 h-[5%] sm:h-[10%] text-white flex justify-end px-[10px] pt-[15px]">
                  <HiX onClick={onCancel} />
                </div>
                <div className="bg-[#021212] h-[25%] sm:h-[15%] opacity-75 text-white px-[10px] flex flex-col justify-center items-center space-y-4">
                  <p className="font-bold">
                    {document.uploadStep} of {requiredSteps}
                  </p>

                  {document.documentSelectedForUpload.documentList === 51 ? (
                    <p className="py-2">
                      Take a selfie which includes you holding up your ID near
                      your face within the frame.
                    </p>
                  ) : (
                    <p className="py-2">
                      {imagesTaken.length < 1 ? 'Front' : 'Back'} side of your{' '}
                      {document.documentSelectedForUpload.silaDocumentTypeLabel}{' '}
                      within the frame
                    </p>
                  )}
                </div>

                <div className="h-[40%] sm:h-[30%]">
                  {document.documentSelectedForUpload.documentList === 51 ? (
                    <div className="h-full flex justify-center items-center">
                      <div className="w-[80%] h-[90%] rounded-[45%] border-2 border-white"></div>
                    </div>
                  ) : (
                    <div className="w-full h-[100%] border-x-8 border-y-[25px] border-[#021212] border-opacity-75">
                      <div className="w-full h-[100%] border-2"></div>
                    </div>
                  )}
                </div>

                <div className="relative bg-[#021212] py-4 opacity-75 flex justify-center items-center">
                  <select
                    className="bg-[#021212] opacity-75 text-white"
                    aria-label="Camera"
                    onChange={(event) => {
                      setActiveDeviceId(event.target.value)
                    }}
                  >
                    {devices.map((d) => (
                      <option key={d.deviceId} value={d.deviceId}>
                        {d.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="relative h-[30%] sm:h-[20%] bg-[#021212] opacity-75 flex justify-center items-center space-x-12">
                  <div
                    className="flex justify-center items-center w-[80px] h-[80px] rounded-full bg-[#7FE5E480]"
                    onClick={() => {
                      if (camera.current) {
                        const photo = camera.current.takePhoto()
                        //console.log(photo)
                        setImage(photo)
                        setImagesTaken([...imagesTaken, photo])
                        setIsPhotoTaken(true)
                      }
                    }}
                  >
                    <div className="w-[60px] h-[60px] rounded-full bg-[#7FE5E4]"></div>
                  </div>

                  <button
                    className="absolute left-2"
                    hidden={numberOfCameras <= 1}
                    onClick={() => {
                      camera.current.switchCamera()
                    }}
                  >
                    <IoMdReverseCamera className="text-[#7FE5E4] text-[30px]" />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CameraUploadComponent
