import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Common/Loading'
import TextField from '../../Common/TextField'

type PropsType = {
  formState: { userName: string; password: string }
  handleChange: (x: any) => void
  handleLogin: (x?: any) => void
  showPassword: boolean
  changePasswordIcon: () => void
  navigate: (x: string) => void
  loading: boolean
  //disabled: boolean
  translation: (x: string) => ''
}

const LoginComponent = ({
  formState,
  handleChange,
  handleLogin,
  showPassword,
  changePasswordIcon,
  navigate,
  loading,
  //disabled,
  translation: t,
}: PropsType) => {
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    setIsValid(
      formState.userName.length > 0 && formState.password.length > 0
        ? false
        : true
    )
  }, [formState])

  return (
    <form onSubmit={handleLogin}>
      <TextField
        type="text"
        name="userName"
        label={t('User.Username')}
        classes={styles.input}
        value={formState.userName}
        onChange={handleChange}
        data-test="login-username-input"
        aria-label="login-username-input"
        passwordField={undefined}
        showPasswordIconStatus={undefined}
        changeIcon={() => undefined}
      />
      <TextField
        type={showPassword ? 'text' : 'password'}
        name="password"
        label={t('User.Password')}
        classes={styles.input}
        value={formState.password}
        onChange={handleChange}
        passwordField
        showPasswordIconStatus={showPassword}
        changeIcon={changePasswordIcon}
        data-test="login-password-input"
        aria-label="login-password-input"
      />

      <p className={styles.link} onClick={(e) => navigate('/reset-password')}>
        {t('User.ForgotPassword')}
      </p>
      <button
        className={styles.button}
        type="submit"
        data-test="signup-button-input"
        data-testid="signup-button-input"
        disabled={isValid}
      >
        {loading ? <LoadingSpinner /> : t('User.LogIn')}
      </button>
    </form>
  )
}

const styles = {
  form: 'w-8/12 flex flex-col justify-center py-10 p-12 mx-auto h-full tab:w-full',
  header:
    'font-semibold text-[18px] leading-[21.6px] text-primaryDark my-[41px]',
  link: 'text-primary my-[30px] text-sm cursor-pointer font-semibold',
  input: 'text-input mb-[16px]',
  button: 'btn btn--big btn--dark mb-[16px]',
  linkButton:
    'text-primaryDark cursor-pointer font-semibold border-2 border-inputBackground p-[9px] rounded-[10px]',
}

export default LoginComponent
