import TextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import React from 'react'

type Proptypes = {
  dateValue: string
  onChange: (e: any) => void
  disabled?: boolean
  label?: string
  maxDateAvailable?: boolean
  isActivation?: boolean
  classes?: string
}

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E3F2F2',
        borderRadius: '10px',
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: '#E3F2F2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E3F2F2',
      },
    },
  },
})(TextField)

const DateInput = ({
  dateValue,
  onChange,
  label,
  isActivation,
  maxDateAvailable,
  disabled = false,
}: Proptypes) => {
  //console.log('Date Input', dateValue)
  //console.log('Day JS', dayjs(dateValue, 'YYYY-MM-DD').toDate())

  return (
    <div
      className={`pt-3 pb-1 rounded-[10px] ${
        disabled ? 'bg-[#e9e9e9]' : 'bg-[#E3F2F3]'
      }`}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          //label={label ? label : 'Date of Birth'}
          label={isActivation ? '' : label ? label : 'Date of Birth'}
          value={dateValue}
          onChange={onChange}
          maxDate={maxDateAvailable ? undefined : dayjs(new Date())}
          //inputFormat={'YYYY-MM-DD'}
          inputFormat={'MMMM D, YYYY'}
          openTo="year"
          views={['year', 'month', 'day']}
          className={`root w-full rounded-[10px] ${
            disabled ? 'bg-[#e9e9e9]' : 'bg-[#E3F2F3]'
          } text-[14px]`}
          PaperProps={{
            sx: {
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: '#004F4E',
                },
              },
            },
          }}
          renderInput={(params) => (
            <CustomTextField
              disabled={disabled}
              variant="outlined"
              size="small"
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: 'Select date',
                autoComplete: 'off',
              }}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  color: disabled ? '#ccc' : '#004F4E',
                },
              }}
              sx={{
                svg: {
                  color: '#004F4E',
                  fontSize: '18px',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default DateInput
