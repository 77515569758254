import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  decreamentMy529Step,
  setupMy529OtherInvestmentOptions,
  setupMy529Update,
  UtahApplication,
} from '../../../../redux/actions/my529Actions'
import SafeAreaView from '../../../Common/SafeAreaView'
import My529SetupBottom from '../My529SetupBottom'
// @ts-ignore
import InvestmentOptions from '../../../../assets/InvestmentOption.png'
// @ts-ignore
import Coin from '../../../../assets/coin.png'
// @ts-ignore
import Coins from '../../../../assets/coins.png'
// @ts-ignore
import BankIcon from '../../../../assets/bank.png'
// @ts-ignore
import ClockIcon from '../../../../assets/Clock.png'
import { RootReduxStateType } from '../../../../types'
import OptionsModal from '../../../Common/Modals/OptionsModal'
import { LoadingSpinner } from '../../../Common/Loading'
import InformationModal from '../../../Common/Modals/InformationModal'
import ListOptionsModal from '../../../Common/Modals/ListOptionsModal'
import { useNavigate } from 'react-router-dom'
import { createAlertAction } from '../../../../redux/actions/alertActions'

type InvestmentOptionPropTypes = {
  investmentOptionID: number
  investmentOption: string
  investmentOptionName: string
  investmentUpto: number
}

const InvestmentStep = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const my529 = useSelector((state: RootReduxStateType) => state.my529)
  const [_, setInvestmentOptions] = useState([])
  const [otherInvestmentOptions, setOtherInvestmentOptions] = useState([])
  const [targetEnrollmentOptions, setTargetEnrollmentOptions] = useState([])
  const [childIndex, setChildIndex] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState<
    InvestmentOptionPropTypes[]
  >([])
  const [showOptions, setShowOptions] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [apiLoading, setAPILoading] = useState<boolean>(true)
  const [optionOneModal, setOptionOneModal] = useState<boolean>(false)
  const [optionTwoModal, setOptionTwoModal] = useState<boolean>(false)
  const [finalConfirmationModal, setFinalConfirmationModal] =
    useState<boolean>(false)
  const [isPreviousClicked, setIsPreviousClicked] = useState<boolean>(false)
  const [beneficiaries, setBeneficiaries] = useState<any>(null)

  useEffect(() => {
    const fetchInvestmentOptions = async () => {
      setAPILoading(true)
      try {
        const response = await setupMy529OtherInvestmentOptions()

        if (response.status === 200) {
          const targetOptions = response.data.filter((option: any) =>
            option.investmentOption.includes('TargetEnrollment')
          )
          setTargetEnrollmentOptions(targetOptions)
          setInvestmentOptions(response.data)

          // const notTargetObjects = response.data.filter(
          //   (obj: any) =>
          //     !obj.investmentOption.includes('TargetEnrollment') &&
          //     !obj.investmentOption.includes('Unknown')
          // )

          const notTargetObjects = response.data.filter(
            (obj: any) => !obj.investmentOption.includes('Unknown')
          )

          setOtherInvestmentOptions(notTargetObjects)
          setAPILoading(false)
        }
      } catch (error) {
        console.error('Error fetching investment options:', error)
        setAPILoading(false)
      }
    }

    fetchInvestmentOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleInvestmentOptionRecommendation()
  }, [targetEnrollmentOptions, my529.childrenInfo, my529.accountBeneficiary])

  useEffect(() => {
    handleUserInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUserInformation = () => {
    // my529.accountBeneficiary && setAccountBeneficiary(my529.accountBeneficiary)

    if (
      my529.accountBeneficiary === 'MY_CHILDREN_NIECE' &&
      my529.childrenInfo.length !== 0
    ) {
      setBeneficiaries(my529.childrenInfo)
    }
  }

  const handleInvestmentOptionRecommendation = () => {
    if (my529.accountBeneficiary === 'MY_SELF') {
      if (targetEnrollmentOptions.length && my529.dateOfBirth) {
        const parentAge = calculateAge(my529.dateOfBirth)
        const recommendedOption = findTargetInvestmentOption(
          parentAge,
          targetEnrollmentOptions
        )
        //console.log('Selected Options for parent', recommendedOption)
        setSelectedOptions([recommendedOption])
        setLoading(false)
      }
    } else {
      if (targetEnrollmentOptions.length && my529.childrenInfo.length) {
        const recommendedOptions = my529.childrenInfo.map((child) => {
          const childAge = calculateAge(child.dateOfBirth)
          return findTargetInvestmentOption(childAge, targetEnrollmentOptions)
        })
        setSelectedOptions(recommendedOptions)
        setLoading(false)
      }
    }
  }

  const calculateAge = (dateOfBirth: string) => {
    const birthDate = new Date(dateOfBirth)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDifference = today.getMonth() - birthDate.getMonth()

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--
    }

    return age
  }

  const findTargetInvestmentOption = (age: any, options: any) => {
    const currentYear = new Date().getFullYear()
    const targetYear = age > 18 ? currentYear : 18 - age + currentYear

    if (options) {
      const closestInvestmentOption = options.reduce(
        (closestOption: any, currentOption: any) => {
          if (
            Math.abs(currentOption.investmentUpto - targetYear) <
            Math.abs(closestOption.investmentUpto - targetYear)
          ) {
            return currentOption
          } else {
            return closestOption
          }
        },
        options[0]
      )

      return closestInvestmentOption
    }
  }

  const handleOptionOne = () => {
    setShowOptions(false)
    setOptionOneModal(true)
  }

  const handleOptionTwo = () => {
    setShowOptions(false)
    setOptionTwoModal(true)
  }

  const handleOptionOneConfirm = () => {
    setOptionOneModal(false)
    // setSelectedOptions([
    //   {
    //     investmentOptionID: 20,
    //     investmentOption: 'FDIC_Insured',
    //     investmentOptionName: 'FDIC-Insured',
    //     investmentUpto: 0,
    //   },
    // ])
    replaceOption(childIndex, {
      investmentOptionID: 20,
      investmentOption: 'FDIC_Insured',
      investmentOptionName: 'FDIC-Insured',
      investmentUpto: 0,
    })
  }

  const replaceOption = (
    oldId: number,
    newOption: InvestmentOptionPropTypes
  ) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option, index) =>
        // option.investmentOptionID === oldId ? newOption : option
        index === oldId ? newOption : option
      )
      return updatedOptions
    })
  }

  const handleItemSelected = (selectedItem: any) => {
    setOptionTwoModal(false)
    replaceOption(childIndex, selectedItem)
  }

  const handleNext = () => {
    if (my529.accountBeneficiary === 'MY_SELF') {
      setAPILoading(true)
      //console.log('Testing handle next', selectedOptions[0])

      UtahApplication({
        UserAccountID: parseInt(user.id),
        MinorAccountID: null,
        ApplicationStatus: 'ApplicationSubmittedFromCustomer',
        InvestmentOption: selectedOptions[0]?.investmentOption,
        My529AccountNumber: '',
        IsMy529AccountForMySelf: true,
        IsMy529DelegatedToMySelf: false,
        IsAddressSameAsParent: true,
      })
        .then(() => {
          setShowOptions(false)
          setFinalConfirmationModal(true)
          setAPILoading(false)
        })
        .catch((err) => {
          setAPILoading(false)
          dispatch(
            createAlertAction({
              type: 'DANGER',
              title: 'Error Occured',
              message: 'Please fill in all the steps and try again',
              autoClose: true,
              autoCloseDuration: 3000,
            })
          )
        })
    } else if (my529.accountBeneficiary === 'MY_CHILDREN_NIECE') {
      if (isPreviousClicked) {
        setChildIndex(childIndex + 1)
        //setIsPreviousClicked(false)
      } else {
        if (childIndex < my529.childrenInfo.length) {
          setAPILoading(true)

          UtahApplication({
            UserAccountID: parseInt(user.id),
            MinorAccountID: beneficiaries[childIndex].minorAccountID,
            ApplicationStatus: 'ApplicationSubmittedFromCustomer',
            InvestmentOption: selectedOptions[childIndex]?.investmentOption,
            My529AccountNumber: '',
            IsMy529AccountForMySelf:
              beneficiaries[childIndex].isMy529AccountForMySelf,
            IsMy529DelegatedToMySelf:
              beneficiaries[childIndex].isMy529DelegatedToMySelf,
            //IsAddressSameAsParent: true,
            IsAddressSameAsParent:
              beneficiaries[childIndex].isAddressSameAsParent,
            Address: {
              address1: beneficiaries[childIndex].streetAddress1,
              address2: beneficiaries[childIndex].streetAddress2,
              cityOrTown: beneficiaries[childIndex].city,
              state: beneficiaries[childIndex].state,
              zipCode: beneficiaries[childIndex].zipCode,
            },
          })
            .then(() => {
              setShowOptions(false)
              setFinalConfirmationModal(true)
              setAPILoading(false)
            })
            .catch((err) => {
              setAPILoading(false)
              dispatch(
                createAlertAction({
                  type: 'DANGER',
                  title: 'Error Occured',
                  message: 'Unable to complete application',
                  autoClose: true,
                  autoCloseDuration: 3000,
                })
              )
            })
        }
      }
    }
  }

  const handleCancel = () => {
    if (my529.accountBeneficiary === 'MY_SELF') {
      dispatch(setupMy529Update('step', 6))
    } else {
      //dispatch(decreamentMy529Step())

      if (childIndex > 0) {
        setChildIndex(childIndex - 1)
        setIsPreviousClicked(true)
      } else {
        dispatch(decreamentMy529Step())
      }
    }
  }

  const handleDocuSignConfirm = () => {
    if (my529.accountBeneficiary === 'MY_SELF') {
      setFinalConfirmationModal(false)
      navigate('/my529')
    } else if (my529.accountBeneficiary === 'MY_CHILDREN_NIECE') {
      if (childIndex < my529.childrenInfo.length - 1) {
        setChildIndex(childIndex + 1)

        setFinalConfirmationModal(false)
      } else if (childIndex === my529.childrenInfo.length - 1) {
        navigate('/my529')
      }
    }
  }

  return (
    <>
      <SafeAreaView>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingSpinner color="#004F4E" />
          </div>
        ) : (
          <>
            {selectedOptions && (
              <>
                <h5 className="text-[18px] font-semibold leading-[21.6px]">
                  Recomended investment option for{' '}
                  {my529.accountBeneficiary === 'MY_SELF'
                    ? `${my529.firstName}`
                    : `${my529.childrenInfo[childIndex].firstName}`}
                </h5>

                <div className="my-[24px] bg-[#E3F2F2] rounded-[10px] h-[430px] flex items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={InvestmentOptions}
                      alt="Sootchy-logo"
                      className="w-[80px]"
                    />

                    <h6 className="pt-[17px] pb-[12px] text-[16px] text-primary leading-[24px] font-semibold">
                      {my529.accountBeneficiary === 'MY_SELF'
                        ? selectedOptions[0]?.investmentOptionName
                        : selectedOptions[childIndex]?.investmentOptionName}
                    </h6>

                    {my529.accountBeneficiary === 'MY_SELF'
                      ? selectedOptions[0]?.investmentOption.includes(
                          'TargetEnrollment'
                        ) && (
                          <p className="text-[14px]">
                            We chose this based on your age
                          </p>
                        )
                      : selectedOptions[childIndex]?.investmentOption.includes(
                          'TargetEnrollment'
                        ) && (
                          <p className="text-[14px]">
                            We chose this based on your age
                          </p>
                        )}
                    <button
                      onClick={() => setShowOptions(!showOptions)}
                      className="mt-[24px] text-[14px] font-normal leading-[21px] bg-[#C4E8E8] border-none py-[8px] px-[36px]"
                    >
                      Choose other option
                    </button>
                  </div>
                </div>

                <a
                  href="https://stprodsootchyinc.blob.core.windows.net/public/My529_Investment_options.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary text-[14px] leading-[21px] underline py-1 inline-block"
                >
                  Find more information about investment plans
                </a>
              </>
            )}
          </>
        )}

        {showOptions && (
          <OptionsModal
            title="Other options"
            description="Choose other investment options:"
            onOptionOne={handleOptionOne}
            onOptionTwo={handleOptionTwo}
            onCancel={() => setShowOptions(false)}
            optionOne="No risk/FDIC-Insured"
            optionOneIcon={Coin}
            optionTwo="Other invesment plans"
            optionTwoIcon={Coins}
          />
        )}

        {optionOneModal && (
          <InformationModal
            title="FDIC-Insured"
            description="FDIC-Insured accounts are safeguarded by the US government"
            icon={BankIcon}
            onSubmit={handleOptionOneConfirm}
            onCancel={() => setOptionOneModal(false)}
          />
        )}

        {optionTwoModal && (
          <ListOptionsModal
            title="Other investment plans"
            description="Find more information about investment plans"
            linkInDescription="https://stprodsootchyinc.blob.core.windows.net/public/My529_Investment_options.pdf"
            icon={BankIcon}
            list={otherInvestmentOptions!}
            onItemSelected={handleItemSelected}
            onCancel={() => setOptionTwoModal(false)}
          />
        )}

        {finalConfirmationModal && (
          <InformationModal
            title="Please check your email for DocuSign"
            icon={ClockIcon}
            isListRequired={true}
            nextSteps={[
              'Our automated system is preparing a document for you to sign',
              'Keep an eye out for an email from DocuSign to complete this setup process',
            ]}
            onSubmit={handleDocuSignConfirm}
            onCancel={() => setFinalConfirmationModal(false)}
          />
        )}
      </SafeAreaView>

      <My529SetupBottom
        loading={apiLoading ? true : false}
        handleNext={handleNext}
        handleCancel={handleCancel}
        handleNextBtn={
          my529.accountBeneficiary === 'MY_SELF'
            ? 'Confirm option'
            : beneficiaries &&
              (beneficiaries[childIndex].applicationStatus ===
                'ApplicationPendingFromCustomer' ||
                beneficiaries[childIndex].applicationStatus ===
                  'NotYetStarted' ||
                beneficiaries[childIndex].applicationStatus === '' ||
                beneficiaries[childIndex].applicationStatus === null ||
                beneficiaries[childIndex].applicationStatus === undefined)
            ? 'Confirm option'
            : 'Next'
        }
      />
    </>
  )
}

export default InvestmentStep
