import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../api'
import { createAlertAction } from '../../../redux/actions/alertActions'
import {
  alpacaStepperEmploymentStatus,
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateVideo,
  alpacaUserEmployment,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import DropdownSelector from '../../Common/Partials/DropdownSelector'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepTen: React.FC = () => {
  const dispatch = useDispatch()
  const [employmentStatus, setEmploymentStatus] = useState<string>('')
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [loading, setLoading] = useState<boolean>(false)
  const [apiLoading, setApiLoading] = useState<boolean>(false)
  const employmentState = useSelector(
    (state: RootReduxStateType) => state.alpaca.employment.status
  )

  const employmentOptions = ['Employed', 'Unemployed', 'Student', 'Retired']

  const getUserDetails = async () => {
    if (employmentState) {
      setEmploymentStatus(employmentState)
    } else {
      setApiLoading(true)

      api
        .get(`/Alpaca/User/Employment`)
        .then((response) => {
          if (response.status === 200) {
            setApiLoading(false)
            setEmploymentStatus(response.data.employmentStatus)
            dispatch(
              alpacaStepperEmploymentStatus(response.data.employmentStatus)
            )
          }
        })
        .catch((err) => {
          console.log('Unable to fetch data', err)
          setApiLoading(false)
        })
    }
  }

  const handleNext = () => {
    //dispatch(increamentAlpacaStepperStep())

    if (employmentStatus) {
      if (employmentStatus === employmentState) {
        dispatch(increamentAlpacaStepperStep())
      } else {
        setLoading(true)
        alpacaUserEmployment({
          userAccountID: user.id,
          employmentStatus: employmentStatus,
        })
          .then(() => {
            setLoading(false)
            dispatch(alpacaStepperEmploymentStatus(employmentStatus))
            dispatch(increamentAlpacaStepperStep())
          })
          .catch((err) => {
            setLoading(false)
            console.log('Unable to create employment status', err)
          })
      }
    } else {
      dispatch(
        createAlertAction({
          type: 'DANGER',
          title: 'Error Occured',
          message: 'Employment status is required to continue',
          autoClose: true,
          autoCloseDuration: 3000,
        })
      )
    }
  }

  const handleEmploymentStatusChange = (data: any) => {
    setEmploymentStatus(data)
  }

  useEffect(() => {
    getUserDetails()
    dispatch(alpacaStepperUpdateVideo(null))
    //if (employmentState) setEmploymentStatus(employmentState)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="text-[18px] py-[16px] font-semibold">Employment</p>

        <p>Are you employed? Please select which option best describes you.</p>

        {apiLoading ? (
          <Skeleton height={90} />
        ) : (
          <div className="py-2">
            <DropdownSelector
              title="Employment Status"
              list={employmentOptions}
              value={employmentStatus!}
              //handleOnchange={(item: any) => setEmploymentStatus(item)}
              handleOnchange={handleEmploymentStatusChange}
            />
          </div>
        )}

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca loading={loading} handleNext={handleNext} />
    </div>
  )
}

export default AlpacaStepTen
