import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  completeQuiz,
  getUserContentDetails,
} from '../../../redux/actions/financialLiteracyActions'
import { RootReduxStateType } from '../../../types'
import { LoadingSpinner } from '../../Common/Loading'
// @ts-ignore
import Modal from '../../Common/Partials/Modal'

type QuizType = {
  Question: string
  Answers: { Value: string; IsCorrect: boolean }[]
}

type PropsType = {
  quizData: QuizType[]
  contentID: string | undefined
  finalContent: boolean
}

const fallBackQuiz: QuizType[] = [
  {
    Question: 'What is a 529 plan?',
    Answers: [
      { Value: 'Nothing but fun', IsCorrect: false },
      { Value: 'An Anime show', IsCorrect: false },
      { Value: 'An investment plan', IsCorrect: true },
      { Value: 'A state bank', IsCorrect: false },
    ],
  },
  {
    Question: 'Which is the largest?',
    Answers: [
      { Value: '10', IsCorrect: true },
      { Value: '2', IsCorrect: false },
      { Value: '5', IsCorrect: false },
      { Value: '7', IsCorrect: false },
    ],
  },
  {
    Question: 'Which is the largest?',
    Answers: [
      { Value: '10', IsCorrect: true },
      { Value: '2', IsCorrect: false },
      { Value: '5', IsCorrect: false },
      { Value: '7', IsCorrect: false },
    ],
  },
]
const Quiz = ({ quizData, contentID, finalContent }: PropsType) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation()
  const [quiz] = useState<QuizType[]>(
    quizData.length === 0 || !quizData ? fallBackQuiz : quizData
  )
  const [currentQuiz, setCurrentQuiz] = useState<number>(0)
  const [currentQuizCompleted, setCurrentQuizCompleted] =
    useState<boolean>(false)
  const [isQuizCompleted, setIsQuizCompleted] = useState<boolean>(false)
  const [_, setUserAlreadyCompleted] = useState<boolean | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [incorrectQuizAnswer, setIncorrectQuizAnswer] = useState<any>([])
  const isUserLoggedIn = useSelector(
    (state: RootReduxStateType) => state.user.token
  )

  const handleOnAnswerClick = (isCorrect: boolean, index: any) => {
    if (isCorrect) {
      setCurrentQuizCompleted(true)
    } else {
      setIncorrectQuizAnswer([...incorrectQuizAnswer, index])
    }
  }

  const handleNextQuiz = async () => {
    if (currentQuiz + 1 < quiz.length) {
      setCurrentQuiz(currentQuiz + 1)
      setCurrentQuizCompleted(false)
    } else {
      setLoading(true)
      if (isUserLoggedIn) {
        const userContentDetailsRes: any = await getUserContentDetails(
          contentID ? contentID : ''
        )
        if (userContentDetailsRes.status === 200) {
          if (!userContentDetailsRes.data.userHasCompleted) {
            setLoading(false)
            setOpenModal(true)
            setUserAlreadyCompleted(false)
            setIsQuizCompleted(true)
            dispatch(completeQuiz(contentID))
          } else {
            setUserAlreadyCompleted(true)
            setOpenModal(true)
            setIsQuizCompleted(true)
          }
        }
      } else {
        setUserAlreadyCompleted(true)
        setOpenModal(true)
        setIsQuizCompleted(true)
      }

      setLoading(false)
    }
  }

  return (
    <div data-test="main-quiz">
      {isQuizCompleted && (
        <Modal
          onLogin={false}
          openModal={openModal}
          setOpenModal={setOpenModal}
        >
          <div className="text-center">
            {/* {!userAlreadyCompleted && (
              <img
                src="https://stdevsootchyinc.blob.core.windows.net/financial-literacy/assets/Plus-5.png"
                alt="coin_image"
                className="m-auto py-6 relative"
              />
            )} */}
            <p className="text-lg">
              {/* {userAlreadyCompleted
                ? `${t('MoneyAcademyPost.QuizCompleted')} 🚀`
                : t('MoneyAcademyPost.QuizStep')} */}
              {t('MoneyAcademyPost.QuizCompleted')} 🚀`
            </p>
            <button
              className="btn btn--dark mt-6 py-4 w-full border-[#C4E8E8] bg-white text-primaryDark"
              onClick={() =>
                navigate(
                  finalContent
                    ? `/money-academy`
                    : `/money-academy/post/${
                        parseInt(contentID ? contentID : '0') + 1
                      }`
                )
              }
            >
              {finalContent
                ? `${t('MoneyAcademyPost.Awesome')}!`
                : t('MoneyAcademyPost.AwesomeNextLesson')}
            </button>
          </div>
        </Modal>
      )}
      <div className="min-h-64 pt-[24px] pb-[24px] bg-[#EFF9F9] mx-auto mb-16 px-4 md:rounded-2xl relative">
        <p className="text-primaryDark text-xs font-semibold uppercase">
          TEST YOUR KNOWLEDGE
        </p>
        <h1
          className="mt-3 text-[1rem] text-[#021212] leading-[24px]"
          data-test="main-quiz-question"
        >
          {quiz[currentQuiz].Question}
        </h1>

        <div className="flex flex-col space-y-4 w-full mt-6">
          {quiz[currentQuiz].Answers.map((_quiz, index) => (
            <div
              data-test={`main-quiz-answer-${index}`}
              className={`p-3 bg-white rounded-xl text-sm text-textGreen font-normal pl-4 h-[48px] py-[10px] shadow-lg cursor-pointer flex justify-between items-center ${
                !incorrectQuizAnswer.includes(index)
                  ? currentQuizCompleted &&
                    !incorrectQuizAnswer.includes(index) &&
                    _quiz.IsCorrect
                    ? 'bg-green-100/50 border-2 text-[#3EB76E] border-[#3EB76E]'
                    : currentQuizCompleted && 'bg-orange-200'
                  : 'bg-orange-100/50 border-2 text-[#FF7A00] border-[#FF7A00]'
              }`}
              key={_quiz.Value}
              onClick={() => handleOnAnswerClick(_quiz.IsCorrect, index)}
            >
              <p>{_quiz.Value}</p>
              <div>
                {_quiz.IsCorrect && currentQuizCompleted ? (
                  <div className="bg-[#3EB76E] uppercase font-bold px-4 py-1 flex items-center text-white rounded-[20px] text-[11px]">
                    <p>{t('MoneyAcademyPost.Correct')}!</p>
                  </div>
                ) : incorrectQuizAnswer.includes(index) ||
                  currentQuizCompleted ? (
                  <p className="bg-orange-500 px-5 py-1 text-white rounded-[20px] text-xs">
                    {t('MoneyAcademyPost.Incorrect')}!
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))}
        </div>
        {currentQuizCompleted && (
          <div>
            <button
              className="w-full mt-6 py-4 border-[#C4E8E8] bg-white text-primaryDark"
              onClick={handleNextQuiz}
              disabled={loading}
            >
              {currentQuiz + 1 < quiz.length ? (
                t('MoneyAcademyPost.NextQuiz')
              ) : loading ? (
                <LoadingSpinner color="#0E6D6C" size={5} />
              ) : (
                t('MoneyAcademyPost.CompleteQuiz')
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Quiz
