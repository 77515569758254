import { ReduxActionType } from '../../types'
import { UserType } from '../../types/user'
import { getUserFromToken } from '../../utilities/generalUtilities'
import {
  GET_USER_ACCOUNT,
  LOG_IN,
  LOG_IN_WITH_GOOGLE,
  RESET_USER,
  SIGNUP,
  UPDATE_TOKEN,
  UPDATE_USER_EMAIL,
} from '../actions/action.types'

const initialState: UserType = {
  id: '',
  userName: '',
  emailAddress: '',
  userAge: null,
  emailAddressIsVerified: '',
  token: null,
  is2FAEnabled: null,
}

export const userReducer = (
  state = initialState,
  action: ReduxActionType<UserType>
) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        ...action.payload,
      }

    case LOG_IN_WITH_GOOGLE:
      return {
        ...state,
        ...action.payload,
      }

    case SIGNUP:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_TOKEN: {
      const token = action.payload
      const { UserName: userName, UserAccountID: id } = getUserFromToken(token)
      return {
        ...state,
        id,
        userName,
        token: token,
      }
    }

    case GET_USER_ACCOUNT:
      return {
        ...state,
        emailAddressIsVerified: action.payload,
      }

    case RESET_USER:
      return initialState

    case UPDATE_USER_EMAIL:
      return {
        ...state,
        emailAddress: action.payload,
      }

    default:
      return state
  }
}
