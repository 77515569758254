import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getNextInSeries,
  getUserDetails,
} from '../../redux/actions/financialLiteracyActions'
import { resetUserRegistrationStepData } from '../../redux/actions/userRegistrationStepperActions'
import { LoadingSpinner } from '../Common/Loading'
import Navigation from '../Common/Navigation'

type contentType = {
  contentUrl: string
  title: string
  isPublic: string
  userHasViewed: boolean
  isViewable: boolean
  contentID: number
  thumbnailUrl: string
}

const LessonsSkeleton = () => {
  return (
    <div className="animate-pulse my-[28px]">
      <div className="flex space-x-2 mb-4">
        <div className="h-[48px] w-[64px] rounded-md bg-slate-200"></div>
        <div className="w-full flex flex-col space-y-2">
          <div className="h-4 w-[40%] rounded-md bg-slate-200"></div>
          <div className="h-6 w-[80%] rounded-md bg-slate-200"></div>
        </div>
      </div>
      <div className="flex space-x-2 mb-4">
        <div className="h-[48px] w-[64px] rounded-md bg-slate-200"></div>
        <div className="w-full flex flex-col space-y-2">
          <div className="h-4 w-[40%] rounded-md bg-slate-200"></div>
          <div className="h-6 w-[80%] rounded-md bg-slate-200"></div>
        </div>
      </div>
      <div className="flex space-x-2 mb-4">
        <div className="h-[48px] w-[64px] rounded-md bg-slate-200"></div>
        <div className="w-full flex flex-col space-y-2">
          <div className="h-4 w-[40%] rounded-md bg-slate-200"></div>
          <div className="h-6 w-[80%] rounded-md bg-slate-200"></div>
        </div>
      </div>
    </div>
  )
}

const MoneyAcademy = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //const user = useSelector((state: RootReduxStateType) => state.user)
  const [content, setContent] = useState<contentType[]>()
  const [loading, setLoading] = useState(false)
  const [nextLesson, setNextLesson] = useState<number>()
  //const [nextSlide, setNextSlide] = useState<number>()

  useEffect(() => {
    getContent()
    dispatch(resetUserRegistrationStepData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContent = async () => {
    setLoading(true)
    const result = await getNextInSeries()

    if (result === undefined) {
      //setNextSlide(0)
      setNextLesson(1)
    } else {
      //setNextSlide(result - 1)
      setNextLesson(result)
    }

    const res = await getUserDetails()

    console.log('Content', res)

    setContent(
      res.map((lesson: any) => ({
        contentUrl: lesson.contentUrl,
        title: lesson.title,
        isPublic: lesson.isPublic,
        userHasViewed: lesson.userHasViewed,
        isViewable: lesson.isViewable,
        contentID: lesson.contentID,
        thumbnailUrl: lesson.thumbnailUrl,
      }))
    )

    setLoading(false)
  }

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto my-[2rem]">
          <img
            src="assets/images/Illustration.svg"
            alt="SavingsIcon"
            className="w-full m-auto object-cover"
          />

          <div className="block my-[2rem]">
            <h2 className="text-[2.25rem] mb-[0.75rem] text-primaryDark font-semibold leading-[43.2px]">
              Money Academy
            </h2>
            <p className="text-[1rem] leading-[24px] text-[#021212]">
              Learn how to save money and achieve your goals. This is a short
              video series of simple bite-sized lessons that will guide you
              through many different and very important topics.
            </p>
            <button
              className="btn btn--big bg-primary text-white btn--shadow my-[1.5rem] w-[80%] lg:w-[50%]"
              onClick={() => navigate(`/money-academy/post/${nextLesson}`)}
            >
              {nextLesson === undefined ? (
                <LoadingSpinner color="#FFFFFF" size={8} />
              ) : nextLesson === 1 ? (
                `Start with Lesson 1`
              ) : (
                `Continue with Lesson ${nextLesson}`
              )}
            </button>
          </div>

          <div>
            <h5 className="text-[1.125rem] text-primaryDark leadinf-[21.6px] font-semibold">
              Lesson breakdown
            </h5>

            {loading ? (
              <LessonsSkeleton />
            ) : (
              <>
                <div className="my-[28px]">
                  {content?.map((lesson: any) => (
                    <div
                      key={lesson.contentID}
                      className="cursor-pointer flex space-x-2 mb-2 py-2 border-b-2 border-dashed border-[#C4E8E8]"
                      onClick={() =>
                        navigate(`/money-academy/post/${lesson.contentID}`)
                      }
                    >
                      <div className="w-[68px] rounded-md">
                        <img
                          src={lesson.thumbnailUrl}
                          alt={lesson.title}
                          className="rounded-md object-cover"
                        />
                      </div>
                      <div className="w-full flex flex-col space-y-2">
                        <p className="w-full uppercase text-[11px] text-primaryDark leading-[12px] font-bold">
                          Lesson {lesson.contentID}
                        </p>
                        <p className="text-[1rem] leading-[24px] font-normal">
                          {lesson.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default MoneyAcademy
