import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  activationRegisterIncrementStep,
  activationRegisterUpdateGoal,
  activationSetupGoal,
} from '../../../../redux/actions/activationActions'
import { RootReduxStateType } from '../../../../types'
import { LoadingSpinner } from '../../../Common/Loading'
import TextField from '../../../Common/TextField'
import Layout from '../Layout'
import GoalTimeline from './GoalTimeline'
import { trackActivationSetGoalEvent } from '../../../../amplitude/events'

const eventInfoList = [
  { type: 'CollegeFund', description: 'Paying for college or university' },
  { type: 'TradeSchoolFund', description: 'Saving for trade school' },
  { type: 'HouseFund', description: 'Buying a house' },
  { type: 'BusinessFund', description: 'Starting a business' },
  { type: 'OtherFund', description: 'Something else' },
]

const ActivationGoalStep = () => {
  const dispatch = useDispatch()
  const activation = useSelector(
    (state: RootReduxStateType) => state.activation
  )
  const [loading, setLoading] = useState<boolean>(false)

  const handleNext = () => {
    // TODO: Add validations and API
    if (activation.activationUserProfileID) {
      setLoading(true)
      activationSetupGoal(
        activation.activationUserProfileID,
        activation.goalInfo.type,
        activation.goalInfo.description,
        activation.goalInfo.timeline
      )
        .then(() => {
          setLoading(false)
          // Amplitude - Set Goal Activation
          trackActivationSetGoalEvent({
            reason: activation.goalInfo.type,
            timeline: activation.goalInfo.timeline,
          })
          dispatch(activationRegisterIncrementStep())
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  const handleSkip = () => {
    dispatch(activationRegisterIncrementStep())
  }

  return (
    <Layout>
      <p className="text-[25px] font-semibold leading-9 px-5">
        We are glad to have you! Now let us know about the kids who will be
        coming to the event.
      </p>
      <p className="leading-8 py-5 font-thin px-5">
        Sharing the names and birth dates of any children you plan to have come
        to the event helps us plan a more fun and engaging experience for your
        family.
      </p>

      <div className="w-full bg-[#EFF9F9] py-7 px-6 rounded-xl mt-6">
        <p className="text-[20px] font-semibold leading-9">
          Which children will be attending this event?
        </p>

        {eventInfoList.map((info) => (
          <div
            className="mb-[16px] flex items-center space-x-4 mt-5 font-thin"
            key={info.type}
          >
            <input
              className="accent-primary focus:accent-primary"
              id={info.type}
              type="checkbox"
              value={info.type}
              name="agreements"
              onClick={() => {
                if (info.type === activation.goalInfo.type) {
                  dispatch(activationRegisterUpdateGoal('type', ''))
                  return
                }
                dispatch(activationRegisterUpdateGoal('type', info.type))
                dispatch(
                  activationRegisterUpdateGoal(
                    'description',
                    info.type === 'OtherFund' ? '' : info.description
                  )
                )
              }}
              checked={activation.goalInfo.type === info.type}
            />
            <label htmlFor={info.type}>{info.description}</label>
          </div>
        ))}

        {activation.goalInfo.type === 'OtherFund' && (
          <TextField
            type="text"
            name=""
            label={'Enter'}
            classes={'text-input mb-[16px]'}
            value={activation.goalInfo.description}
            onChange={(e) => {
              dispatch(
                activationRegisterUpdateGoal('description', e.target.value)
              )
            }}
          />
        )}
      </div>

      <div className="w-full bg-[#EFF9F9] py-7 px-6 rounded-xl mt-6">
        <p className="text-[20px] font-semibold leading-9">
          Set a timeline. When would you like to withdraw the funds for this
          goal?
        </p>

        <GoalTimeline />
      </div>

      <div className="w-[90%] m-auto sm:w-full">
        <button
          className="w-full mt-8 text-[16px] font-normal bg-primary text-white py-5"
          onClick={handleNext}
        >
          {loading ? <LoadingSpinner /> : 'Set goal'}
        </button>
      </div>

      <button
        className="w-full mt-4 text-[16px] font-normal bg-white text-primary border-none py-5"
        onClick={handleSkip}
      >
        Skip for now
      </button>
    </Layout>
  )
}

export default ActivationGoalStep
