import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserAccount, verifyEmail } from '../../../redux/actions/userActions'
import { LoadingSpinner } from '../../Common/Loading'
import TextField from '../../Common/TextField'
import { RootReduxStateType } from '../../../types'
import api from '../../../api'
import {
  updateUserAccount,
  validateMultiFactorAuth,
} from '../../../redux/actions/generalActions'
import Toast from '../../Common/Toast'
import TelephoneNumber from '../../Common/TelephoneNumber'
import Modal from '../../Common/Partials/Modal'
import { PinInput } from 'react-input-pin-code'
import { useTranslation } from 'react-i18next'
import {
  getUserAge,
  getUserFromToken,
} from '../../../utilities/generalUtilities'
import { LOG_IN } from '../../../redux/actions/action.types'
import dayjs from 'dayjs'
import Navigation from '../../Common/Navigation'

const ContactDetails: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [userName] = useState(user.userName)
  const [emailIsVerified, setEmailIsVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [counter, setCounter] = useState(30)
  const [disableResendBtn, setDisableResendBtn] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [telephoneNumber, setTelephoneNumber] = useState<any>('')
  const [showToast, setShowToast] = useState<boolean>(false)
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false)
  const [changedEmail, setEmailChanged] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [validateMFA, setValidateMFA] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [values, setValues] = useState(['', '', '', '', '', ''])

  useEffect(() => {
    timeOutNotification()
  }, [showToast, showErrorToast])

  useEffect(() => {
    if (disableResendBtn) {
      if (counter >= 0) {
        const interval = setInterval(() => {
          setCounter((currentCount) => --currentCount)
        }, 1000)

        counter === 0 && setDisableResendBtn(false)

        return () => clearInterval(interval)
      }
    } else {
      checkIsEmailVerified()
      getUserDetails()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, disableResendBtn, emailIsVerified])

  const getUserDetails = async () => {
    const res = await api.get(`/UserAccount/UserAccountID/${user.id}`)

    if (res.data.telephoneNumber) {
      formatPhoneNumberToDisplay(res.data.telephoneNumber)
    } else {
      setTelephoneNumber(res.data.telephoneNumber)
    }

    setFirstName(res.data.firstName)
    setLastName(res.data.lastName)
    setEmailAddress(res.data.emailAddress)
    setDateOfBirth(res.data.dateOfBirth)
    setEmailChanged(res.data.emailAddress)
  }

  const checkIsEmailVerified = async () => {
    const res = await getUserAccount(userName)
    setEmailIsVerified(res)
  }

  const timeOutNotification = () => {
    const timer = setTimeout(() => {
      setShowToast(false)
      setShowErrorToast(false)
    }, 3000)

    return () => clearTimeout(timer)
  }

  const handleVerfyEmail = async (e: any) => {
    e.preventDefault()
    dispatch(verifyEmail(user.id))
    setDisableResendBtn(true)
  }

  const formatPhoneNumberToDisplay = (phone: string) => {
    const newNumber = phone.split(' ')
    let arr = ['1', newNumber[1]]
    setTelephoneNumber(arr.join(''))
  }

  const formatPhoneNumberToSave = (phone: string) => {
    let arr = ['+1', phone.substring(1)]
    return arr.join(' ')
  }

  const handleSubmit = async () => {
    try {
      setButtonLoading(true)
      const phone = formatPhoneNumberToSave(telephoneNumber)

      if (phone) {
        const res: any = await updateUserAccount(
          user.id,
          firstName,
          lastName,
          dateOfBirth,
          phone,
          emailAddress
        )

        if (emailAddress !== changedEmail) {
          dispatch(verifyEmail(user.id))
          setEmailChanged(emailAddress)
        }

        if (res.status === 200) {
          await checkIsEmailVerified()
          setShowToast(true)
          setShowErrorToast(false)
          setButtonLoading(false)
        } else {
          setShowErrorToast(true)
          setButtonLoading(false)
        }
      }
    } catch (error) {
      setShowErrorToast(true)
      setButtonLoading(false)
      console.log('Failed to update contact details')
    }
  }

  // const handleValidate = async (e: any) => {
  //   const code = values.join('')

  //   values.join('').length === 6 &&
  //     (await validateMultiFactorAuth(code, navigate)
  //       .then((res: any) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           const {
  //             UserName: userName,
  //             UserAccountID: id,
  //             is2FAEnabled: is2FA,
  //             DateOfBirth,
  //           } = getUserFromToken(res.data.accessToken)

  //           setLoading(false)
  //           dispatch({
  //             type: LOG_IN,
  //             payload: {
  //               id,
  //               userName,
  //               userAge: getUserAge(dayjs(DateOfBirth).format('YYYY-MM-DD')),
  //               emailAddress: '',
  //               token: res.data.accessToken,
  //               is2FAEnabled: is2FA,
  //             },
  //           })
  //           handleSubmit()
  //           setOpenModal(false)
  //           return
  //         } else {
  //           setLoading(false)
  //           setErrorMessage('Your code is not valid, please try again')
  //         }
  //       })
  //       .catch((err) => console.log('Failed to validate')))
  // }
  const handleClear = () => {
    setErrorMessage('')
    setValues(['', '', '', '', '', ''])
  }

  // Commented out temporarily to disable 2FA
  // useEffect(() => {
  //   values.join('').length === 6 && handleValidate(values.join(''))
  // }, [values])

  return (
    <>
      <Navigation>
        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4">
            <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
              <button
                type="button"
                className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
                onClick={() => navigate(-1)}
              >
                <img
                  src={`/assets/images/back.svg`}
                  alt="back"
                  className="block text-primaryDark"
                />
              </button>
              Contact Details
            </h1>
          </div>

          {validateMFA && (
            <div className="absolute top-0 left-0">
              <Modal onLogin openModal={openModal} setOpenModal={setOpenModal}>
                <div className="w-[80%] flex flex-col items-center">
                  <p className="text-lg mb-4">{t('2FA.ModalTitle')}</p>
                  <p className="text-sm text-red-500 mb-2">{errorMessage}</p>

                  <PinInput
                    values={values}
                    size="md"
                    placeholder=""
                    onChange={(values: any) => setValues(values)}
                    autoComplete="off"
                    autoFocus
                    focusBorderColor="#0E6D6C"
                    validBorderColor={
                      errorMessage ? 'rgb(220,53,69)' : '#0E6D6C'
                    }
                  />

                  <div className="md:flex justify-between my-6 md:w-[80%] w-full">
                    <button
                      className="btn btn--gray py-4 w-full cursor-pointer"
                      onClick={handleClear}
                    >
                      {t('2FA.Clear')}
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          )}

          <div
            className={`lg:w-[37%] lg:mx-auto fixed inset-x-0 z-40 bottom-0  ${
              showToast ? 'translate-x-0' : '-translate-x-full'
            } ease-in-out duration-300 transition-transform`}
          >
            {showToast && (
              <div className="absolute inset-x-0 bottom-0 z-40 mx-auto">
                <Toast
                  styles="plain"
                  description="Awesome! Your contact details changes are saved."
                  className="my-5 mx-2"
                />
              </div>
            )}
          </div>

          <div
            className={`lg:w-[37%] lg:mx-auto fixed inset-x-0 z-40 bottom-0  ${
              showErrorToast ? 'translate-x-0' : '-translate-x-full'
            } ease-in-out duration-300 transition-transform`}
          >
            {showErrorToast && (
              <div className="absolute inset-x-0 bottom-0 z-40 lg:w-[37%] mx-auto">
                <Toast
                  styles="error"
                  description="Error! Failed to update your details."
                  className="my-5 mx-2"
                />
              </div>
            )}
          </div>

          {/**Beginning of form */}
          {loading ? (
            <div className="flex justify-center items-center">
              <LoadingSpinner color="#004F4E" />
            </div>
          ) : (
            <>
              {!loading && !emailIsVerified && (
                <div className="border-b-[2.5px] border-[#C4E8E8] border-dashed mb-[32px] py-[32px]">
                  <div className="text-[#004F4E] space-y-4">
                    <p>
                      You haven’t confirmed your email ({emailAddress}).
                      Completed confirmation will allow you to use more Sootchy
                      features.
                    </p>
                    <p>Please check your email.</p>
                  </div>
                  <button
                    disabled={disableResendBtn}
                    className="border-[#C4E8E8] mt-[24px]"
                    onClick={handleVerfyEmail}
                  >
                    Resend email {disableResendBtn && `in 00:${counter}`}
                  </button>
                </div>
              )}

              <TextField
                type="text"
                label="Email"
                name="email"
                classes={styles.input}
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value)
                }}
                autoComplete="off"
              />

              <div className="mb-[16px]">
                <TelephoneNumber
                  value={telephoneNumber}
                  onChange={(telephoneNumber) =>
                    setTelephoneNumber(telephoneNumber)
                  }
                />
              </div>

              {emailAddress && (
                <div className="bg-[#00B2FF] px-[18px] py-[14px] rounded-[10px] flex space-x-[10px]">
                  <div className="w-[60px] h-[24px] flex justify-center items-center">
                    <img src="/assets/images/info_icon.svg" alt="info" />
                  </div>

                  <p className="text-white">
                    Note: upon changing the email, we’ll send you an email with
                    a confirmation link to make sure the email provided is
                    correct and belongs to you.
                  </p>
                </div>
              )}

              <div className="mt-4">
                <button
                  className={styles.button}
                  onClick={
                    user.is2FAEnabled
                      ? () => {
                          setValidateMFA(true)
                          setOpenModal(true)
                        }
                      : handleSubmit
                  }
                  // disabled={
                  //   emailAddress.length > 0 && telephoneNumber.length === 11
                  //     ? false
                  //     : true
                  // }
                  disabled={emailAddress.length > 0 ? false : true}
                >
                  {buttonLoading ? <LoadingSpinner /> : ' Save'}
                </button>
              </div>
            </>
          )}
        </div>
      </Navigation>
    </>
  )
}

const styles = {
  form: 'w-8/12 flex flex-col justify-center py-10 p-12 mx-auto h-full tab:w-full',
  header:
    'font-semibold text-[18px] leading-[21.6px] text-primaryDark my-[41px]',
  input: 'text-input mb-[16px] ',
  button: 'btn btn--big btn--dark mb-[16px]',
  link: 'text-primary my-[16px] cursor-pointer font-semibold',
  phoneContainer: 'bg-red-500',
}

export default ContactDetails
