import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { createAlpacaKnownPerson } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { formatSSN, validdateSsn } from '../../../utilities/generalUtilities'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepSeven: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (alpaca.user.socialSecurity && validdateSsn(alpaca.user.socialSecurity))
      setDisabled(false)
    else setDisabled(true)
  }, [alpaca.user.socialSecurity])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(
      alpacaStepperUpdateUser(key, value === '' ? null : formatSSN(value))
    )
  }

  const handleNext = async () => {
    setLoading(true)

    createAlpacaKnownPerson(user.id, alpaca.user.socialSecurity ?? '')
      .then((res) => {
        dispatch(increamentAlpacaStepperStep())
        setLoading(false)
      })
      .catch((err) => {
        alert(err)
        setLoading(false)
      })
  }

  return (
    <div>
      <SafeAreaView>
        <p className="font-semibold text-[17px] py-2">Social Security Number</p>
        <p className="text-[15px]">
          US Federal regulations require us to obtain your Social Security
          Number to confirm your identity. This will not impact your credit.
        </p>

        <div className="py-4">
          <input
            type="text"
            placeholder="Social security number (SSN)"
            className="w-full outline-none bg-[#E3F2F2] px-4 py-4 rounded-lg mt-2"
            name="socialSecurity"
            value={alpaca.user.socialSecurity ?? undefined}
            onChange={handleOnChange}
          />
        </div>

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca
        disabled={disabled || loading}
        handleNext={handleNext}
      />
    </div>
  )
}

export default AlpacaStepSeven
