import React from 'react'
//import { useDispatch, useSelector } from 'react-redux'
//import { useNavigate } from 'react-router-dom'
//import { Country, State, City } from 'country-state-city'
//import { LoadingSpinner } from '../Common/Loading'
import LayoutFullPage from '../../Common/Layouts/FullPage'
//import FullPageTitle from '../Partials/FullPageTitle'
//import TextField from '../Partials/TextField'
//import { RootReduxStateType } from '../../types'
//import {
//updateProfileProgress,
//updateProfileStatus,
//updateSchoolDetails,
//} from '../../redux/actions/generalActions'

const OccupationDetails: React.FC = () => {
  {
    /**
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const progress = useSelector(
    (state: RootReduxStateType) => state.general.updateProfile
  )
  const [state, setState] = useState('')
  const [stateCode, setStateCode] = useState('')
  const [city, setCity] = useState('')
  //const [cities, setCities] = useState<any[]>([])
  const [school, setSchool] = useState('')
  const [grade, setGrade] = useState('')
  const [isHomeSchooled, setIsHomeSchooled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [occupation, setOccupation] = useState('student')
  const [showToast, setShowToast] = useState<boolean>(false)

  const timeOutNotification = () => {
    const timer = setTimeout(() => {
      setShowToast(false)
    }, 3000)

    return () => clearTimeout(timer)
  }

  const handleStateChange = (e: any) => {
    setStateCode(e.target.value)

    const res = State.getStateByCodeAndCountry(e.target.value, 'US')
    if (res) setState(res.name)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      setLoading(true)
      const res: any = await updateSchoolDetails(
        user.id,
        user.id,
        city,
        state,
        school,
        grade,
        isHomeSchooled
      )

      if (res.status === 200) {
        await dispatch(updateProfileStatus('schoolUpdated', true))
        if (!progress.schoolUpdated) dispatch(updateProfileProgress())
        setShowToast(true)
        timeOutNotification()
      }
      setLoading(false)
    } catch (error) {
      console.log('Failed to update schools in Profile')
    }
  } */
  }

  return (
    <LayoutFullPage>
      Commented out for now
      {/**
      <FullPageTitle title="Your Occupation" onBack={() => navigate(-1)} />
      <div> 
        <p className="mb-2">Which option best describes you?</p>

        <div className="flex items-center mb-2">
          <label htmlFor="employed">
            <input
              name="occupation"
              type="radio"
              checked={occupation === 'employed'}
              onChange={(e) => setOccupation(e.target.value)}
              value="employed"
              id="employed"
              data-test="occupation-radio"
              //className="text-primaryDark bg-white border-2 border-[#E3F2F2] focus:ring-0 ring-offset-0 focus:ring-2"
            />{' '}
            I am a working adult
          </label>
        </div>

        <div className="flex items-center mb-2">
          <label htmlFor="unemployed">
            <input
              name="occupation"
              type="radio"
              checked={occupation === 'unemployed'}
              onChange={(e) => setOccupation(e.target.value)}
              value="unemployed"
              id="unemployed"
              data-test="occupation-radio"
            />{' '}
            I am an unemployed adult
          </label>
        </div>

        <div className="flex items-center mb-2">
          <label htmlFor="student">
            <input
              name="occupation"
              type="radio"
              checked={occupation === 'student'}
              onChange={(e) => setOccupation(e.target.value)}
              value="student"
              id="student"
              data-test="occupation-radio"
            />{' '}
            I am a school student
          </label>
        </div>

        <div className="flex items-center mb-2">
          <label htmlFor="college">
            <input
              name="occupation"
              type="radio"
              checked={occupation === 'college/university'}
              onChange={(e) => setOccupation(e.target.value)}
              value="college/university"
              id="college"
              data-test="occupation-radio"
            />{' '}
            I am a college or university student
          </label>
        </div>
      </div>

      {occupation === 'student' && (
        <>
          <div>
            <p className="py-[16px]">Tell us a bit about your school</p>

            {!isHomeSchooled && (
              <>
                <div
                  className={`${styles.input} form-group ${
                    state.length > 0 && 'form-group--filled'
                  } `}
                >
                  <label htmlFor="state" className="flex">
                    State
                  </label>

                  <select
                    name="state"
                    id="state"
                    //onChange={(e) => setStateCode(e.target.value)}
                    onChange={handleStateChange}
                  >
                    <option value=""></option>
                    {State.getStatesOfCountry('US').map((st) => (
                      <option value={st.isoCode} key={st.name}>
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className={`${styles.input} form-group ${
                    state.length > 0 && 'form-group--filled'
                  } `}
                >
                  <label htmlFor="city" className="flex">
                    City or Town
                  </label>

                  <select
                    name="city"
                    id="city"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option value=""></option>
                    {City.getCitiesOfState('US', stateCode).map((st) => (
                      <option value={st.name} key={st.name}>
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className={`form-group ${
                    school.length > 0 && 'form-group--filled'
                  } py-2`}
                >
                  <input
                    onChange={(e) => setSchool(e.target.value)}
                    type="text"
                    id="school"
                    name="school"
                    disabled={state.length <= 0}
                    value={school}
                  />
                  <label htmlFor="school">School Name</label>
                </div>

                <div
                  className={`w-[40%] form-group ${
                    grade.length > 0 && 'form-group--filled'
                  } py-2`}
                >
                  <input
                    onChange={(e) => setGrade(e.target.value)}
                    type="text"
                    id="grade"
                    name="grade"
                    disabled={state.length <= 0}
                    value={grade}
                  />
                  <label htmlFor="grade">Grade</label>
                </div>
              </>
            )}
          </div>
          <div className="my-[32px] checkbox">
            <input
              type="checkbox"
              checked={isHomeSchooled}
              onChange={() => setIsHomeSchooled(!isHomeSchooled)}
              id="isHomeSchooled"
              data-test="isHomeSchooled-checkbox"
            />
            <label htmlFor="isHomeSchooled"> I am homeschooled</label>
          </div>
        </>
      )}
      <div className="mt-4">
        <button className={styles.button} onClick={handleSubmit}>
          {loading ? <LoadingSpinner /> : ' Save'}
        </button>
      </div> */}
    </LayoutFullPage>
  )
}

const styles = {
  form: 'w-8/12 flex flex-col justify-center py-10 p-12 mx-auto h-full tab:w-full',
  header:
    'font-semibold text-[18px] leading-[21.6px] text-primaryDark my-[41px]',
  input: 'text-input mb-[16px] rounded-[10px] bg-[#E3F2F2] ',
  select: 'mb-[16px]',
  button: 'btn btn--big btn--dark mb-[16px]',
  link: 'text-primary my-[16px] cursor-pointer font-semibold',
}

export default OccupationDetails
