import { v4 as uuidv4 } from 'uuid'
import { ReduxActionType } from '../../types'
import { ActivationRegisterStepperType } from '../../types/activation'
import {
  ACTIVATION_ADD_PARENT_INFORMATION,
  ACTIVATION_REGISTER_ADD_CHILD,
  ACTIVATION_REGISTER_DECREMENT_STEP,
  ACTIVATION_REGISTER_INCREMENT_STEP,
  ACTIVATION_REGISTER_REMOVE_CHILD,
  ACTIVATION_REGISTER_RESET,
  ACTIVATION_REGISTER_UPDATE_CHILD,
  ACTIVATION_REGISTER_UPDATE_GOAL,
  ADD_ACTIVATION_EVENT_INFORMATION,
  UPDATE_ACTIVATION_USER_PROFILE,
  UPDATE_RSVP_STATUS,
} from '../actions/action.types'

const MAX_STEP = 3
const MIN_STEP = 1
const DEFAULT_RELATIONSHIP = 'Child'

const initialState: ActivationRegisterStepperType = {
  activationUserProfileID: null,
  step: MIN_STEP,
  invitationVerificationCode: null,
  childrenInfo: [
    {
      id: uuidv4(),
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      relationship: DEFAULT_RELATIONSHIP,
      phoneNumber: '',
      email: '',
    },
  ],
  hasRSVPed: false,
  parentInfo: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
  },
  goalInfo: {
    type: '',
    description: '',
    timeline: '',
  },
  activationInfo: {
    activationEventID: null,
    activationEventVenue: null,
    activationName: null,
    activationEventDate: null,
    description: null,
    mapLocation: null,
  },
}

export const activationReducer = (
  state: ActivationRegisterStepperType = initialState,
  action: ReduxActionType<ActivationRegisterStepperType>
) => {
  switch (action.type) {
    case ACTIVATION_REGISTER_INCREMENT_STEP:
      return {
        ...state,
        step: state.step < MAX_STEP ? state.step + 1 : state.step,
      }

    case ACTIVATION_REGISTER_DECREMENT_STEP:
      return {
        ...state,
        step: state.step > MIN_STEP ? state.step - 1 : state.step,
      }

    case ADD_ACTIVATION_EVENT_INFORMATION:
      return {
        ...state,
        activationInfo: action.payload,
      }

    case ACTIVATION_REGISTER_ADD_CHILD:
      return {
        ...state,
        childrenInfo: [
          ...state.childrenInfo,
          {
            id: uuidv4(),
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            relationship: DEFAULT_RELATIONSHIP,
            phoneNumber: '',
            email: '',
          },
        ],
      }

    case ACTIVATION_REGISTER_REMOVE_CHILD: {
      const { payload }: any = action
      const filteredInfo = state.childrenInfo.filter(
        (info) => info.id !== payload
      )

      return {
        ...state,
        childrenInfo: filteredInfo,
      }
    }

    case ACTIVATION_REGISTER_UPDATE_CHILD: {
      const {
        payload: { id, key, value },
      }: any = action

      const updatedChildrenInfo = state.childrenInfo.map((info) => {
        if (info.id === id) {
          return { ...info, [key]: value }
        }

        return info
      })

      return { ...state, childrenInfo: updatedChildrenInfo }
    }
    case UPDATE_RSVP_STATUS:
      return {
        ...state,
        hasRSVPed: true,
      }

    case ACTIVATION_ADD_PARENT_INFORMATION:
      return {
        ...state,
        parentInfo: action.payload,
      }

    case ACTIVATION_REGISTER_UPDATE_GOAL: {
      const {
        payload: { key, value },
      }: any = action

      return {
        ...state,
        goalInfo: {
          ...state.goalInfo,
          [key]: value,
        },
      }
    }
    case UPDATE_ACTIVATION_USER_PROFILE:
      return {
        ...state,
        activationUserProfileID: action.payload,
      }

    case ACTIVATION_REGISTER_RESET:
      return {
        ...state,
        activationUserProfileID: null,
        step: MIN_STEP,
        hasRSVPed: false,

        childrenInfo: [
          {
            id: uuidv4(),
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            relationship: '',
            phoneNumber: '',
            email: '',
          },
        ],
        parentInfo: {
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          phoneNumber: '',
          email: '',
        },
        goalInfo: {
          type: '',
          description: '',
          timeline: '',
        },
      }

    default:
      return initialState
  }
}
