import React from 'react'
import Navigation from '../../components/Common/Navigation'

const PrivacyPolicy = () => {
  return (
    <>
      <Navigation>
        <div className="w-[85%] m-auto py-10 md:w-[800px]">
          <h1 className="standard-h1">Sootchy Privacy Policy</h1>
          <h3 className="standard-h3">
            PLEASE NOTE OUR PRIVACY POLICY RELATED TO CHILDREN UNDER THE AGE OF
            13 CAN BE FOUND IN SECTION 8 ON THIS DOCUMENT.
          </h3>
          <p className="standard-para">
            Sootchy Inc., Sootchy Securities, LLC and both entities’ officers,
            directors, employees, agents, members, managers, successors,
            assigns, predecessors, group companies, affiliates and subsidiaries
            from time-to-time (collectively referred to herein as “we”, “us”,
            “our”, or “Sootchy,”) are committed to protecting your privacy.
          </p>
          <p className="standard-para">
            This online Sootchy Privacy Policy (“Privacy Policy” or “Policy”)
            describes how we gather and use your personal information. This
            Sootchy Privacy Policy applies to our mobile application, web
            application (together, the “App”) and website as well the investment
            advisory services provided by Sootchy Securities (collectively, our
            “Service(s)”), and describes our practices relating to the
            collection, recording, use and disclosure of personal information
            collected from or about our current and past clients, website
            visitors, mobile application users and other individuals. By
            accessing or using our Service, you signify that you have read,
            understood and agree to our collection, storage, use and disclosure
            of your personal information as described in our general Privacy
            Policy and Terms of Use. In this Sootchy Privacy Policy, personal
            information means “personally identiﬁable information.” This Sootchy
            Privacy Policy will be reviewed periodically and will be updated to
            reﬂect any changes.
          </p>
          <p className="standard-para">
            The services of Sootchy are listed on www.sootchy.com. You
            acknowledge and agree that your personal data collected may be used
            in accordance with this Policy by and for one or more Sootchy
            companies, which will be regarded, individually or jointly, as data
            controllers in respect of such data.
          </p>
          <p className="standard-para">
            This Policy applies to personal data collected in connection with
            services offered by Sootchy or from other interactions with us where
            a link or other appropriate reference of incorporation to this
            Policy is made, for example, in connection with our website,
            applications and other types of services offered by Sootchy
            typically in electronic form, as well as other services, such as
            customer care, customer events, or promotions and campaigns.
          </p>
          <h4 className="standard-h4">
            <strong>1. Personal Information Collected</strong>
          </h4>
          <p className="standard-para">
            Sootchy uses personal information about you to service, maintain,
            and protect your account; process transactions in your account;
            respond to inquiries from you or your representative; develop,
            offer, and deliver products and services; or to fulﬁll legal and
            regulatory requirements. We collect and receive information about
            you from various sources including the following:
          </p>
          <p className="standard-para">
            <strong>
              <em>1.1 Information</em> <em>you</em> <em>provide.</em>{' '}
            </strong>
            Sootchy may collect public and non-public personal information about
            you from you or your representative.
          </p>
          <p className="standard-para">
            <strong>
              <em>
                1.2 Information collected through your use of the Sootchy
                Website.
              </em>
            </strong>
            When you download and use our Service, we automatically collect
            information on the type of device you use, operating system version,
            and the device identiﬁer. We collect information about how you use
            the Service, including information about your transactions and
            account activity with us and with any college savings programs and
            service providers who help us provide the Service.
          </p>
          <p className="standard-para">
            <strong>
              <em>1.3 Information from third parties.</em>{' '}
            </strong>
            We may obtain information about you from third parties including the
            custodian holding your college savings accounts, companies we work
            with to develop or enhance the Service, companies we use to verify
            your identity (including your wireless provider), our afﬁliates,
            public sources and others. This information could include, among
            other things, information about your account transactions and
            balance and personally identiﬁable information. We may also obtain
            publicly available demographic data, and any information needed to
            comply with regulatory requirements, ensure the accuracy of data,
            better understand your likely interests, and/or prevent fraud.
          </p>
          <p className="standard-para">
            <strong>
              <em>
                1.4 Information from third-party social networking or
                authentication services.
              </em>{' '}
            </strong>
            We may also receive personal information about you from
            third-parties when you log in through a third-party social
            networking or a single sign-on service. These services will
            authenticate your identity and provide you the option to share
            certain personal information with us, which could include your name,
            e-mail address, address book and contacts or other information. We
            use this information in the same way we use other personal
            information we collect from you as described in this Policy.
          </p>
          <p className="standard-para">
            <strong>
              <em>
                1.5 Information received via Cookies and other tracking
                technology.
              </em>{' '}
            </strong>
            We, and our third-party service providers, automatically collect
            certain types of usage information when you visit our websites, use
            our Service, read our emails or otherwise engage with us via
            “tracking technologies”.
          </p>
          <p className="standard-para">
            From these various tracking technologies, we may collect information
            about your device and its software, including but not limited to
            your IP address, browser type, Internet service provider, network
            domain, platform type, device type, operating system, date and time
            stamp. We may also collect information about the way you use our
            Service, how frequently you access the Service, access times,
            whether you open emails or click the links contained in emails, what
            content you view, whether you access the Service from multiple
            devices, and other actions you take on the Service. When you access
            our Service from a mobile device, we may collect unique
            identiﬁcation numbers associated with your device (including, for
            example, a UDID, Unique ID for Advertisers (“IDFA”), Google AdID, or
            Windows Advertising ID), mobile carrier, device type, model and
            manufacturer, mobile device operating system brand and model, phone
            number, and depending on your mobile device settings, your
            geographical location data including information regarding the
            location of your mobile device (subject to your prior consent when
            prompted). &nbsp;We may collect analytics data, or use third-party
            analytics tools such as Google Analytics, to help us measure trafﬁc
            and usage trends for the websites and to understand more about the
            demographics of our users.
          </p>
          <p className="standard-para">
            We do not collect personal information about your online activities
            over time and across different web sites or online services.
            Therefore, our websites do not respond to Do Not Track (“DNT”)
            signals. We do not knowingly authorize third parties to collect
            personal information about your online activities over time and
            across different web sites or online services.
          </p>
          <p className="standard-para">
            When you use our Services or otherwise interact with us over
            telecommunications networks, certain additional information, such as
            your mobile subscription number, may be transmitted to us by the
            telecommunications operator as a standard part of that
            communication. &nbsp;Please also see the “Cookie Policy” section
            below.
          </p>
          <p className="standard-para">
            Examples of Tracking Technologies include:
          </p>
          <ul>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Application,</em> <em>Widgets,</em> <em>and</em>
                  <em>Tools.</em>{' '}
                </strong>
                We may enable individuals to download an application, widget, or
                other tool that can be used on mobile or other computing
                devices. Some of these tools may store information on mobile or
                other devices. These tools can and may transmit personal
                information to Sootchy to enable you to access your account
                information and to enable us to track use of these tools. Some
                of these tools may enable users to email reports and other
                information from the tool. We may use personal or
                non-identiﬁable information transmitted to us to enhance these
                tools, to develop new tools, for quality improvement, and as
                otherwise described in this Privacy Policy or in other notices
                we provide.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Cookies.</em>{' '}
                </strong>
                Sootchy uses cookies, which enable our web servers to “identify”
                users, each time an individual initiates a session on our
                websites. A cookie is set in order to identify you and tailor
                our websites for your personal experience. Cookies do not store
                any of the personal information that you provided to us. You may
                delete cookie ﬁles from your own hard drive at any time.
                However, cookies may be necessary to provide access to much of
                the content and many of the features of our websites
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Pixel</em> <em>Tags.</em>{' '}
                </strong>
                Along with cookies, we may use “pixel tags” or “web beacons,”
                which are small graphic ﬁles that allow us to monitor the use of
                our websites. A pixel tag can collect information such as the IP
                address of the computer that downloaded the page on which the
                tag appears; the URL of the page on which the pixel tag appears;
                the time the page containing the pixel tag was viewed; the type
                of browser that fetched the pixel tag; and the identiﬁcation
                number of any cookie on the computer previously placed by that
                server.
              </p>
            </li>
          </ul>
          <p className="standard-para">
            <strong>
              <em>1.6 Data concerning minors.</em>{' '}
            </strong>
            We do not seek to collect any information from or engage in any
            transactions with persons under the legal age in their respective
            country. &nbsp;Our databases may nevertheless contain personal data
            of children due to the fact that it is not always possible to
            determine precisely the age of the user. If we learn that a minor
            has provided personal information through our services, we will use
            reasonable efforts to remove such information from our files. We
            reserve the right to block the service from any person who is or
            whom we reasonably suspect of being a minor. &nbsp;Sootchy’s policy
            is to request that minors do not engage in other legal acts on
            relating to our services without the consent of a parent or legal
            guardian, unless otherwise permitted by applicable law. If you are a
            minor but have your legal guardian’s consent, you must be able to
            prove that such consent exists upon request.
          </p>
          <h4 className="standard-h4">
            <strong>2. How We Use Information</strong>
          </h4>
          <p className="standard-para">
            Consistent with applicable law and the choices available to you, we
            use the information we collect for the purposes below. Please note
            that one or more purposes may apply simultaneously:
          </p>
          <ul className="no-padding-list">
            <li className="small-text">
              Operate, maintain and provide you the Service
            </li>
            <li className="small-text">
              Fulﬁll your requests or for the purposes explained when you
              provide information;
            </li>
            <li className="small-text">
              Communicate with you, via email, chat, text messages, push
              notiﬁcations and/or telephone calls;
            </li>
            <li className="small-text">
              Develop, analyze, and improve our websites and Service offerings;
            </li>
            <li className="small-text">
              Personalize your experience when you use our Service or receive
              advertisements, which might entail combining personal data
              collected in connection with your use of Sootchy with other
              personal data we may hold about &nbsp;you, except where such
              personal data is collected for a different purpose;
            </li>
            <li className="small-text">
              Deliver advertisements and other information about products,
              services and applications offered by Sootchy, our afﬁliates, and
              our participating companies;
            </li>
            <li className="small-text">
              To let us know which emails have been opened by recipients, to
              understand the effectiveness of our marketing and other
              communications and to make those communications more useful and
              interesting to you;
            </li>
            <li className="small-text">
              Remember your information so that you will not have to re-enter it
              during your next visit and identify you across different devices
              that you use;
            </li>
            <li className="small-text">
              To help maintain the safety, security and integrity of our
              websites, products and services, database and other technology
              assets and business; diagnose or ﬁx technology problems, and
              otherwise plan for and enhance our Service;
            </li>
            <li className="small-text">
              To fulﬁll legal and regulatory requirements;
            </li>
          </ul>
          <p className="standard-para">
            As otherwise may be necessary to perform or enforce any contracts
            between you and Sootchy.
          </p>
          <h4 className="standard-h4">
            <strong>3. Our Information Sharing Practices</strong>
          </h4>
          <p className="standard-para">
            Sootchy does not share personal information about our customers with
            unafﬁliated third parties for use in marketing their products and
            services. We may share personal information in the instances
            described below. For further information on your choices regarding
            your information, see the Your Choices section below.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>Investment and non-investment account providers. </em>
                </strong>
                We may share personal information about you with the investment
                and non-account providers you invest in through the Service.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Agents</em> <em>and</em> <em>service</em>
                  <em>providers.</em>{' '}
                </strong>
                We may share personal information about you with agents and
                service providers, as needed to perform functions on our behalf,
                such as to send email and postal mail, analyze data, provide
                marketing services, and service accounts.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Government</em> <em>agencies.</em>{' '}
                </strong>
                We may share personal information about you with Government
                agencies with other regulatory bodies and law enforcement
                ofﬁcials (for example, for tax purposes or for reporting
                suspicious transactions).
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>As</em> <em>permitted</em> <em>or</em> <em>required</em>
                  <em>by</em> <em>law.</em>{' '}
                </strong>
                We may share personal information about you as permitted or
                required by law (for example for fraud prevention or to respond
                to a subpoena).
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Third-parties</em> <em>as</em> <em>needed</em> <em>to</em>
                  <em>maintain</em> <em>and</em> <em>service</em> <em>your</em>
                  <em>Sootchy</em> <em>account.</em>{' '}
                </strong>
                We may disclose personal information about you to third parties
                as reasonably necessary to maintain and service your Sootchy
                account, including to facilitate transactions.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>With</em> <em>our</em> <em>afﬁliates.</em>{' '}
                </strong>
                We may provide personal information about you to our afﬁliates
                to the extent permitted by applicable law.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Protection</em> <em>of</em> <em>Sootchy</em> <em>and</em>
                  <em>others.</em>{' '}
                </strong>
                We may also disclose personal information as we believe
                appropriate to enforce or apply our rights under our agreements
                (such as, for example, our Terms of Use) with third parties and
                to protect the rights, property or safety of Sootchy and others,
                including exchanging information for fraud protection and credit
                risk reduction.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Use</em> <em>and</em> <em>sharing</em> <em>of</em>
                  <em>information</em> <em>relating</em> <em>to</em>{' '}
                  <em>social</em>
                  <em>networks.</em>{' '}
                </strong>
                If you access our applications or pages on social networking
                sites you may also be required to read and accept those sites’
                terms of service or privacy policies. In the future, those sites
                may use the information you provide them to serve you
                personalized advertising based on your background, location,
                demographics, likes, keywords, and any other information they
                receive from you, including the information you share with
                Sootchy on those sites. You acknowledge that you have agreed to
                and are bound by those sites’ privacy policies and terms of use
                with respect to those sites’ use of your information. Sootchy is
                not responsible for those sites’ use of your information,
                including the information you may submit to Sootchy using those
                social networking sites. If you access our applications or pages
                on social networking sites, you authorize Sootchy to collect,
                store, and use all information you submit to social networking
                sites applications or pages. You also authorize Sootchy to
                collect, store, and use all information that you agreed the
                social networking site could provide to Sootchy through those
                sites’ application programming interfaces. Sootchy will collect,
                store and use this information in accordance with this Privacy
                Policy. You agree to these conditions when you allow this
                sharing on social networking sites or start using our
                applications or pages on those sites. Please remember that when
                you interact with social networking applications or pages, you
                may have privacy controls at your disposal. When you publish
                content or information using a public setting on these social
                networking sites, you may be allowing everyone, including people
                off of those social networking sites, to access and use that
                information, and to associate it with you.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Date in aggregate.</em>{' '}
                </strong>
                Sootchy may share with any third parties information in an
                aggregated or otherwise anonymized form that does not personally
                identify individual clients or visitors for any reason Sootchy
                deems necessary or desirable.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Other disclosures</em>
                </strong>
                . We may disclose and otherwise process your personal data in
                accordance with applicable laws to defend Sootchy’s legitimate
                interests, for example, in civil or criminal legal proceedings.
                &nbsp;
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Mergers and acquisitions.</em>
                </strong>{' '}
                If we decide to sell, buy, merge, or otherwise reorganize our
                businesses in certain states or countries, this may involve us
                disclosing personal data to prospective or actual purchasers and
                their advisors, or receiving personal data from sellers and
                their advisors, for the purposes of such transactions. &nbsp;In
                addition, if any bankruptcy or reorganization proceeding is
                brought by or against us, such information may be considered an
                asset of ours and may be sold or transferred to third parties.
                &nbsp;Should such a sale or transfer occur, we will use
                reasonable efforts to try to require that the transferee use
                personal information in a manner that is consistent with this
                Privacy Policy.
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>4. Your Choices</strong>
          </h4>
          <p className="standard-para">
            <strong>
              <em>4.1 Accessing</em> <em>Your</em> <em>Data.</em>{' '}
            </strong>
            You may access, and update information stored in your account proﬁle
            by visiting the Sootchy website or mobile application. Please keep
            your contact, account, and preference information up-to-date. Doing
            so enables you to receive maximum possible beneﬁt from the Service.
          </p>
          <p className="standard-para">
            <strong>
              <em>4.2 Modifying or Deleting Your Data. </em>
            </strong>
            If you have any questions about modifying or deleting your
            information, you may contact us at help@sootchy.com. We may not be
            able to modify or delete your information in all circumstances. We
            will retain your information for as long as you are a Sootchy client
            and/or we reasonably deem it necessary to provide you services,
            comply with our legal or other obligations, resolve potential
            disputes, audit our records and/or enforce our rights and
            obligations under our agreements. If you no longer want us to use
            your information to provide you services, you may terminate your
            Sootchy Client Agreement and request that your information be
            deleted. We may not be able to delete your information in all
            instances, such as if we are required to retain certain data for
            record-keeping purposes or to comply with laws.
          </p>
          <p className="standard-para">
            <strong>
              <em>4.3 Unsubscribe. </em>
            </strong>
            If you do not wish to receive marketing messages from Sootchy, you
            may indicate your preferences by clicking the “Unsubscribe” link in
            the email. We make every effort to promptly process all unsubscribe
            requests. You may not opt out of service-related communications
            (e.g., account veriﬁcation, transactional communications,
            changes/updates to features of the Service or technical and security
            notices).
          </p>
          <h4 className="standard-h4">
            <strong>5. How We Store and Protect Your Information</strong>
          </h4>
          <p className="standard-para">
            We care about the security of your information and use commercially
            reasonable physical, administrative, and technological safeguards to
            preserve the integrity and security of all information collected
            through our Service. At Sootchy, we restrict access to personal
            information to those who require it to develop, support, offer and
            deliver products and services to you. If personally identiﬁable
            information is accessed through our Service, it is encrypted before
            it is transmitted over the internet. To protect your personally
            identiﬁable information, we use technical and physical safeguards
            and train our employees. Where appropriate, we may also take back-up
            copies and use other such means to prevent accidental damage or
            destruction of your personal data. Where the Sootchy website
            supports online transactions, we will use an industry standard
            security measure, such as the one available through “Secure Sockets
            Layer” (“SSL”), to protect the confidentiality and security of
            online transactions.However, no security system is impenetrable, and
            we cannot guarantee the security of our systems 100%. In the event
            that any information under our control is compromised as a result of
            a breach of security, we will take reasonable steps to investigate
            the situation and, where appropriate, notify those individuals whose
            information may have been compromised in accordance with any
            applicable laws and regulations.
          </p>
          <h4 className="standard-h4">
            <strong>6. California Privacy Rights</strong>
          </h4>
          <p className="standard-para">
            If you are a resident of the State of California, under the
            California Consumer Privacy Act (CCPA), you have the right to
            request information on how to exercise your disclosure choice
            options from companies conducting business in California.
            Specifically,
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>Exercising the Right to Know. </em>
                </strong>
                You may request, up to twice in a 12-month period, the following
                about the personal information collected on you in the past 12
                months: (a) the categories and specific pieces of personal
                information we have collected; (b) the categories of sources
                from which we collected the personal information; (c) the
                business or commercial purpose for which we collected it; (d)
                the categories of third parties with whom we shared the personal
                information; and (e) the categories of personal information
                about you that we disclosed for a business purpose.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Exercising the Right to Delete. </em>
                </strong>
                You may request that we delete the personal information we have
                collected from you, subject to certain limitations under
                applicable law.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Exercising the Right to Opt-Out from Sale. </em>
                </strong>
                You may request to opt out of any “sale” of your personal
                information that may take place.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>Non-Discrimination. </em>
                </strong>
                The CCPA provides that you may not be discriminated against for
                exercising these rights.
              </p>
            </li>
          </ul>
          <p className="standard-para">
            To submit a request to exercise any of the rights described above,
            you may contact Sootchy, Inc. either by email to
            <a href="mailto:help@sootchy.com">help@sootchy.com</a> or contact us
            via postal mail, proper postage prepaid, at:
          </p>
          <p className="standard-para">
            Sootchy, Inc.
            <br />
            Attn: Your California Privacy Rights
            <br />
            1234 Wilshire Boulevard, #104-A7
            <br />
            Los Angeles, CA 90017
          </p>
          <p className="standard-para">
            Please indicate your preference as to how you would like us to
            respond to your request (i.e., email or postal mail). All requests
            sent via postal mail must be labeled “Your California Privacy
            Rights” on the envelope or postcard and clearly stated on the actual
            request. For all requests, please include your name, street address
            (if you would like a response via postal mail), city, state, and zip
            code. We may need to verify your identity before responding to your
            request, such as verifying that the email address or contact
            information from which you send the request matches your email
            address or contact information that we have on file. Authentication
            based on a government-issued and valid identification document may
            be required. We will not accept requests via telephone or fax. We
            are not responsible for notices that are not labeled or sent
            properly, or do not have complete information.
          </p>
          <h4 className="standard-h4">
            <strong>7. Children’s Privacy</strong>
          </h4>
          <p className="standard-para">
            In accordance with Children’s Online Privacy Protection Act (COPPA)
            we maintain additional privacy controls for children under the age
            of 13. Before allowing registration on our mobile application and
            before we collect personal information we will request a user’s date
            of birth. Based on this date of birth, if the user is under 13 we
            will ask for the child’s parent or guardian’s email address and/or
            phone number for the limited purpose of obtaining parental/guardian
            consent. We will not use the parent/guardian email for any purpose
            other than obtain consent without express consent of the
            parent/guardian. If we do not receive parent/guardian consent in a
            reasonable period of time we will delete the email.
          </p>
          <p className="standard-para">
            The information we obtain from children under 13 will be limited to
            that which is deemed necessary. We will not share child information
            unless required by law, essential to protect Sootchy, necessary to
            enable our service providers to perform services, or to help protect
            a child’s safety.
          </p>
          <p className="standard-para">
            At any time a parent/guardian can request that we terminate child
            access and delete any child information. Please note, that doing so
            may prevent the child from using our Services. To request
            terminating child access please email us at{' '}
            <a href="mailto:help@sootchy.com">help@sootchy.com</a> along with
            the child’s name and your email address and telephone number. You
            may also request we terminate access and data by sending a written
            request to Sootchy at 1234 Wilshire Boulevard, Suite 104-A7, Los
            Angeles, CA 90017.
          </p>
          <h4 className="standard-h4">
            <strong>8. Links to Third Party Websites and Services</strong>
          </h4>
          <p className="standard-para">
            This Service may contain links to and from third party websites of
            college savings programs, service providers, advertisers, and social
            media companies. It is important to remember that if you link to
            another website from any of our Sootchy websites, that party's
            privacy policy and its terms and conditions of use apply to you. We
            encourage you to learn about each third party's privacy policy
            before giving your information to them.
          </p>
          <h4 className="standard-h4">
            <strong>9. Changes to this Sootchy Privacy Policy</strong>
          </h4>
          <p className="standard-para">
            We may update this Sootchy Privacy Policy to reﬂect changes to our
            practices from time-to-time. If we materially change this privacy
            policy or our information handling practices as described in this
            policy, we will notify you by email and/or through a notice on the
            applicable website prior to the change becoming effective. We
            encourage you to review this page periodically for the latest
            information on our privacy practices.
          </p>
          <h4 className="standard-h4">
            <strong>10. Contacting Sootchy about out Privacy Policy</strong>
          </h4>
          <p className="standard-para">
            Do not hesitate to contact Sootchy at
            <a href="mailto:help@sootchy.com">help@sootchy.com</a> with any
            questions or concerns you may have with our Privacy Policy. We want
            you to feel completely safe using our services, so we encourage
            ongoing communication with our clients
          </p>
          <h4 className="standard-h4">
            <strong>11. Effective Date</strong>
          </h4>
          <p className="standard-para">
            This online Sootchy Privacy Policy is effective as of March 6, 2023,
            and replaces any prior Sootchy Privacy Policy.
          </p>
          <h2 className="standard-h2">
            <strong>Sootchy Privacy Policy of Sootchy.com</strong>
          </h2>
          <p className="standard-para">
            Sootchy.com collects some Personal Data from its Users. This
            document can be printed for reference by using the print command in
            the settings of any browser.
          </p>
          <h2 className="standard-h2">
            <strong>Policy summary</strong>
          </h2>
          <h3 className="standard-h3">
            Personal Data collected for the following purposes and using the
            following services:
          </h3>
          <h4 className="standard-h4">
            <strong>Analytics</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Google Analytics, HubSpot Analytics and Wordpress Stats
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Backup saving and management</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Amazon Glacier
                    <br />
                  </em>
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Contacting the User</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Contact form
                    <br />
                  </em>
                </strong>
                Personal Data: email address; first name; last name; phone
                number
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Phone contact
                    <br />
                  </em>
                </strong>
                Personal Data: phone number
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Content commenting</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Facebook Comments
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Disqus
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data; various types of Data as
                specified in the privacy policy of the service
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Comment system managed directly
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; email address; first name; last name;
                phone number; Usage Data; username; website
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Displaying content from external platforms</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Font Awesome
                    <br />
                  </em>
                </strong>
                Personal Data: Usage Data
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Google Fonts
                    <br />
                  </em>
                </strong>
                Personal Data: Usage Data; various types of Data as specified in
                the privacy policy of the service
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Google Maps widget, Instagram widget, OpenStreetMap widget
                    and YouTube video widget
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Heat mapping and session recording</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Hotjar Heat Maps &amp; Recordings
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data; various types of Data as
                specified in the privacy policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Hosting and backend infrastructure</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Amazon Web Services (AWS) and mLab
                    <br />
                  </em>
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Infrastructure monitoring</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Crashlytics
                    <br />
                  </em>
                </strong>
                Personal Data: geographic position; unique device identifiers
                for advertising (Google Advertiser ID or IDFA, for example);
                various types of Data as specified in the privacy policy of the
                service
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Instabug
                    <br />
                  </em>
                </strong>
                Personal Data: address; app information; Cookies; country; date
                of birth; device information; device logs; email address; first
                name; geographic position
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    New Relic
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data; various types of Data as
                specified in the privacy policy of the service
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Uptime Robot
                    <br />
                  </em>
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>
              Interaction with external social networks and platforms
            </strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    AddThis, AddToAny, Facebook Like button and social widgets,
                    LinkedIn button and social widgets, Pinterest “Pin it”
                    button and social widgets, Reddit button and widgets,
                    ShareThis, StumbleUpon button and social widgets, Tumblr
                    sharing button and social widgets and Twitter Tweet button
                    and social widgets
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Interaction with live chat platforms</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Zendesk Chat
                    <br />
                  </em>
                </strong>
                Personal Data: address; country; Data communicated while using
                the service; email address; first name; last name; phone number
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Interaction with support and feedback platforms</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Zendesk Widget
                    <br />
                  </em>
                </strong>
                Personal Data: address; country; date of birth; email address;
                first name; last name; phone number
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Managing contacts and sending messages</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    HubSpot Email
                    <br />
                  </em>
                </strong>
                Personal Data: email address; Usage Data
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Sendgrid
                    <br />
                  </em>
                </strong>
                Personal Data: address; country; date of birth; email address;
                first name; last name; phone number
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Managing support and contact requests</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Zendesk
                    <br />
                  </em>
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Remarketing and behavioral targeting</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Facebook Custom Audience and Twitter Tailored Audiences
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; email address
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Facebook Remarketing, Google Ad Manager Audience Extension,
                    Google Ads Remarketing, LinkedIn Website Retargeting,
                    Remarketing with Google Analytics and Twitter Remarketing
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>RSS feed management</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Feedburner
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    FeedPress
                    <br />
                  </em>
                </strong>
                Personal Data: Usage Data
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>SPAM protection</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Google reCAPTCHA
                    <br />
                  </em>
                </strong>
                Personal Data: Cookies; Usage Data
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Akismet
                    <br />
                  </em>
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Tag Management</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Google Tag Manager
                    <br />
                  </em>
                </strong>
                Personal Data: Usage Data
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Traffic optimization and distribution</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>
                    Sucuri CloudProxy
                    <br />
                  </em>
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>User database management</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  HubSpot CRM
                  <br />‍
                </strong>
                Personal Data: email address; phone number; various types of
                Data as specified in the privacy policy of the service
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  HubSpot Lead Management
                  <br />
                </strong>
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>Contact information</strong>
          </h4>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  <em>Owner and Data Controller</em>
                  <br />
                </strong>
                Sootchy, Inc.
                <br />
                1234 Wilshire Boulevard, Suite 104-A7, Los Angeles, CA 90017
                <strong>
                  <br />
                  Owner contact email:{' '}
                </strong>
                help@sootchy.com
              </p>
            </li>
          </ul>
          <h2 className="standard-h2">
            <strong>Full Policy</strong>
          </h2>
          <h3 className="standard-h3">
            <strong>Owner and Data Controller</strong>
          </h3>
          <p className="standard-para">
            <strong>
              Sootchy, Inc.
              <br />‍
            </strong>
            1234 Wilshire Boulevard, Suite 104-A7, Los Angeles, CA 90017
            <br />‍<strong>Owner contact email:</strong>
            help@sootchy.com
          </p>
          <h3 className="standard-h3">
            <strong>Types of Data collected</strong>
          </h3>
          <p className="standard-para">
            Among the types of Personal Data that Sootchy.com collects, by
            itself or through third parties, there are: Cookies; Usage Data;
            first name; last name; phone number; email address; date of birth;
            address; country; Data communicated while using the service; unique
            device identifiers for advertising (Google Advertiser ID or IDFA,
            for example); geographic position; app information; device
            information; device logs; username; website
          </p>
          <p className="standard-para">
            Complete details on each type of Personal Data collected are
            provided in the dedicated sections of this privacy policy or by
            specific explanation texts displayed prior to the Data collection.
          </p>
          <p className="standard-para">
            Personal Data may be freely provided by the User, or, in case of
            Usage Data, collected automatically when using Sootchy.com.
          </p>
          <p className="standard-para">
            Unless specified otherwise, all Data requested by Sootchy.com is
            mandatory and failure to provide this Data may make it impossible
            for Sootchy.com to provide its Services. In cases where Sootchy.com
            specifically states that some Data is not mandatory, Users are free
            not to communicate this Data without consequences to the
            availability or the functioning of the Service.
          </p>
          <p className="standard-para">
            Users who are uncertain about which Personal Data is mandatory are
            welcome to contact the Owner.
          </p>
          <p className="standard-para">
            Any use of Cookies – or of other tracking tools – by Sootchy.com or
            by the owners of third-party services used by Sootchy.com serves the
            purpose of providing the Service required by the User, in addition
            to any other purposes described in the present document and in the
            Cookie Policy, if available.
          </p>
          <p className="standard-para">
            Users are responsible for any third-party Personal Data obtained,
            published or shared through Sootchy.com and confirm that they have
            the third party's consent to provide the Data to the Owner.
          </p>
          <h3 className="standard-h3">
            <strong>Mode and place of processing the Data</strong>
          </h3>
          <h4 className="standard-h4">
            <strong>Methods of processing</strong>
          </h4>
          <p className="standard-para">
            The Owner takes appropriate security measures to prevent
            unauthorized access, disclosure, modification, or unauthorized
            destruction of the Data. The Data processing is carried out using
            computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In
            addition to the Owner, in some cases, the Data may be accessible to
            certain types of persons in charge, involved with the operation of
            Sootchy.com (administration, sales, marketing, legal, system
            administration) or external parties (such as third-party technical
            service providers, mail carriers, hosting providers, IT companies,
            communications agencies) appointed, if necessary, as Data Processors
            by the Owner. The updated list of these parties may be requested
            from the Owner at any time.
          </p>
          <h4 className="standard-h4">
            <strong>Legal basis of processing</strong>
          </h4>
          <p className="standard-para">
            <strong>‍</strong>The Owner may process Personal Data relating to
            Users if one of the following applies:
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                Users have given their consent for one or more specific
                purposes. Note: Under some legislations the Owner may be allowed
                to process Personal Data until the User objects to such
                processing (“opt-out”), without having to rely on consent or any
                other of the following legal bases. This, however, does not
                apply, whenever the processing of Personal Data is subject to
                European data protection law;
              </p>
            </li>
            <li>
              <p className="standard-para">
                provision of Data is necessary for the performance of an
                agreement with the User and/or for any pre-contractual
                obligations thereof;
              </p>
            </li>
            <li>
              <p className="standard-para">
                processing is necessary for compliance with a legal obligation
                to which the Owner is subject;
              </p>
            </li>
            <li>
              <p className="standard-para">
                processing is related to a task that is carried out in the
                public interest or in the exercise of official authority vested
                in the Owner;
              </p>
            </li>
            <li>
              <p className="standard-para">
                processing is necessary for the purposes of the legitimate
                interests pursued by the Owner or by a third party.
              </p>
            </li>
          </ul>
          <p className="standard-para">
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
          <h4 className="standard-h4">
            <strong>Place</strong>
          </h4>
          <p className="standard-para">
            The Data is processed at the Owner's operating offices and in any
            other places where the parties involved in the processing are
            located.
          </p>
          <p className="standard-para">
            Depending on the User's location, data transfers may involve
            transferring the User's Data to a country other than their own. To
            find out more about the place of processing of such transferred
            Data, Users can check the section containing details about the
            processing of Personal Data.
          </p>
          <p className="standard-para">
            Users are also entitled to learn about the legal basis of Data
            transfers to a country outside the European Union or to any
            international organization governed by public international law or
            set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </p>
          <p className="standard-para">
            If any such transfer takes place, Users can find out more by
            checking the relevant sections of this document or inquire with the
            Owner using the information provided in the contact section.
          </p>
          <h4 className="standard-h4">
            <strong>Retention time</strong>
          </h4>
          <p className="standard-para">
            We take reasonable steps to keep the personal data we possess
            accurate and up-to-date and to delete out of date or otherwise
            incorrect or unnecessary personal data.
          </p>
          <p className="standard-para">
            Certain Sootchy Services may allow you to manage your profile and
            the information in such profile. We encourage you to access your
            personal data via your profile from time to time to ensure that it
            is correct and up-to-date. &nbsp;Please remember that for such
            electronic services where you may manage your profile, it is your
            responsibility to provided us with correct details as well as to
            update the personal data you have provided us with in case of any
            changes.
          </p>
          <p className="standard-para">
            Personal Data shall be processed and stored for as long as required
            by the purpose they have been collected for.
          </p>
          <p className="standard-para">Therefore:</p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                Personal Data collected for purposes related to the performance
                of a contract between the Owner and the User shall be retained
                until such contract has been fully performed.
              </p>
            </li>
            <li>
              <p className="standard-para">
                Personal Data collected for the purposes of the Owner’s
                legitimate interests shall be retained as long as needed to
                fulfill such purposes. Users may find specific information
                regarding the legitimate interests pursued by the Owner within
                the relevant sections of this document or by contacting the
                Owner.
              </p>
            </li>
          </ul>
          <p className="standard-para">
            The Owner may be allowed to retain Personal Data for a longer period
            whenever the User has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to do
            so for the performance of a legal obligation or upon order of an
            authority. Once the retention period expires, Personal Data shall be
            deleted. Therefore, the right of access, the right to erasure, the
            right to rectification and the right to data portability cannot be
            enforced
          </p>
          <h4 className="standard-h4">
            <strong>The purposes of processing</strong>
          </h4>
          <p className="standard-para">
            The Data concerning the User is collected to allow the Owner to
            provide its Service, comply with its legal obligations, respond to
            enforcement requests, protect its rights and interests (or those of
            its Users or third parties), detect any malicious or fraudulent
            activity, as well as the following: Analytics, Contacting the User,
            User database management, Managing contacts and sending messages,
            Traffic optimization and distribution, SPAM protection, Hosting and
            backend infrastructure, Interaction with external social networks
            and platforms, Tag Management, RSS feed management, Remarketing and
            behavioral targeting, Managing support and contact requests,
            Interaction with support and feedback platforms, Interaction with
            live chat platforms, Infrastructure monitoring, Heat mapping and
            session recording, Displaying content from external platforms,
            Content commenting and Backup saving and management.
          </p>
          <p className="standard-para">
            For specific information about the Personal Data used for each
            purpose, the User may refer to the section “Detailed information on
            the processing of Personal Data”.
          </p>
          <h4 className="standard-h4">
            <strong>
              Detailed information on the processing of Personal Data
            </strong>
          </h4>
          <h5 className="standard-h5">
            <strong>Analytics</strong>
          </h5>
          <p className="standard-para">
            The services contained in this section enable the Owner to monitor
            and analyze web traffic and can be used to keep track of User
            behavior
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Google Analytics (Google LLC)
                  <br />
                </strong>
                Google Analytics is a web analysis service provided by Google
                LLC (“Google”). Google utilizes the Data collected to track and
                examine the use of Sootchy.com, to prepare reports on its
                activities and share them with other Google services.
                <br />
                Google may use the Data collected to contextualize and
                personalize the ads of its own advertising network.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <strong>
                  <br />
                  Place of processing: United States –{' '}
                </strong>
                <a href="https://policies.google.com/privacy">
                  <strong>Privacy Policy</strong>
                </a>
                <strong> – </strong>
                <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                  <strong>Opt Out</strong>
                </a>
                <strong>.</strong>
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  HubSpot Analytics (HubSpot, Inc.)
                  <br />
                </strong>
                HubSpot Analytics is an analytics service provided by HubSpot,
                Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://legal.hubspot.com/privacy-policy">
                  Privacy Policy
                </a>{' '}
                –<a href="mailto:privacy@hubspot.com">Opt Out</a>
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Wordpress Stats (Automattic Inc.)
                  <br />
                </strong>
                Wordpress Stats is an analytics service provided by Automattic
                Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –{' '}
                <a href="https://automattic.com/privacy/">Privacy Policy</a>.
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Backup saving and management</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>This type of service allows the Owner to save and
            manage backups of Sootchy.com on external servers managed by the
            service provider itself. The backups may include the source code and
            content as well as the data that the User provides to Sootchy.com.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Amazon Glacier (Amazon)
                  <br />
                </strong>
                Amazon Glacier is a service to save and manage backups provided
                by Amazon Web Services Inc.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service.
                <br />
                Place of processing: United States<strong> – </strong>
                <a href="https://aws.amazon.com/privacy/">Privacy Policy</a>
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Contacting the User</strong>
          </h5>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Contact form (Sootchy.com)
                  <br />
                </strong>
                By filling in the contact form with their Data, the User
                authorizes Sootchy.com to use these details to reply to requests
                for information, quotes or any other kind of request as
                indicated by the form’s header.
                <br />
                Personal Data processed: email address; first name; last name;
                phone number.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Phone contact (Sootchy.com)
                  <br />
                </strong>
                Users that provided their phone number might be contacted for
                commercial or promotional purposes related to Sootchy.com, as
                well as for fulfilling support requests.
                <br />
                Personal Data processed: phone number.
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Content commenting</strong>
          </h5>
          <p className="standard-para">
            Content commenting services allow Users to make and publish their
            comments on the contents of Sootchy.com.Depending on the settings
            chosen by the Owner, Users may also leave anonymous comments. If
            there is an email address among the Personal Data provided by the
            User, it may be used to send notifications of comments on the same
            content. Users are responsible for the content of their own
            comments.If a content commenting service provided by third parties
            is installed, it may still collect web traffic data for the pages
            where the comment service is installed, even when Users do not use
            the content commenting service.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Facebook Comments (Facebook, Inc.)
                  <br />
                </strong>
                Facebook Comments is a content commenting service provided by
                Facebook, Inc. enabling the User to leave comments and share
                them on the Facebook platform.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States<strong> –</strong>
                <a href="https://www.facebook.com/privacy/explanation">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Disqus (Disqus)
                  <br />
                </strong>
                Disqus is a content commenting service provided by Big Heads
                Labs Inc.
                <br />
                Personal Data processed: Cookies; Usage Data; various types of
                Data as specified in the privacy policy of the service.
                <br />
                Place of processing: United States<strong>–</strong>
                <a href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy">
                  Privacy Policy
                </a>
                –{' '}
                <a href="https://disqus.com/data-sharing-settings/">Opt out</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Comment system managed directly (Sootchy.com)
                  <br />
                </strong>
                Sootchy.com has its own internal content comment system.
                <br />
                Personal Data processed: Cookies; email address; first name;
                last name; phone number; Usage Data; username; website.
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Displaying content from external platforms</strong>
          </h5>
          <p className="standard-para">
            This type of service allows you to view content hosted on external
            platforms directly from the pages of Sootchy.com and interact with
            them. This type of service might still collect web traffic data for
            the pages where the service is installed, even when Users do not use
            it.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Font Awesome (Fonticons, Inc. )<br />
                </strong>
                Font Awesome is a typeface visualization service provided by
                Fonticons, Inc. that allows Sootchy.com to incorporate content
                of this kind on its pages.
                <br />
                Personal Data processed: Usage Data.
                <br />
                Place of processing: United States<strong> </strong>–
                <a href="https://fontawesome.com/privacy">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Google Fonts (Google LLC)
                  <br />
                </strong>
                Google Fonts is a typeface visualization service provided by
                Google LLC that allows Sootchy.com to incorporate content of
                this kind on its pages.
                <br />
                Personal Data processed: Usage Data; various types of Data as
                specified in the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Google Maps widget (Google LLC) &nbsp;
                  <br />
                </strong>
                Google Maps is a maps visualization service provided by Google
                LLC that allows Sootchy.com to incorporate content of this kind
                on its pages.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Instagram widget (Instagram, Inc.)
                  <br />
                </strong>
                Instagram is an image visualization service provided by
                Instagram, Inc. that allows Sootchy.com to incorporate content
                of this kind on its pages.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://help.instagram.com/519522125107875">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  OpenStreetMap widget (OpenStreetMap Foundation )<br />
                </strong>
                OpenStreetMap widget is a maps visualization service provided by
                OpenStreetMap Foundation that allows Sootchy.com to incorporate
                content of this kind on its pages.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United Kingdom –<strong> </strong>
                <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  YouTube video widget (Google LLC)
                  <br />
                </strong>
                YouTube is a video content visualization service provided by
                Google LLC that allows Sootchy.com to incorporate content of
                this kind on its pages.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –<strong> </strong>
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Heat mapping and session recording</strong>
          </h5>
          <p className="standard-para">
            Heat mapping services are used to display the areas of a page where
            Users most frequently move the mouse or click. This shows where the
            points of interest are. These services make it possible to monitor
            and analyze web traffic and keep track of User behavior.Some of
            these services may record sessions and make them available for later
            visual playback
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Hotjar Heat Maps &amp; Recordings (Hotjar Ltd.)
                  <br />
                </strong>
                Hotjar is a session recording and heat mapping service provided
                by Hotjar Ltd.
                <br />
                Hotjar honors generic „Do Not Track” headers. This means the
                browser can tell its script not to collect any of the User's
                data. This is a setting that is available in all major browsers.
                Find Hotjar’s
                <a href="https://www.hotjar.com/legal/compliance/opt-out">
                  opt-out information here
                </a>
                .<br />
                Personal Data processed: Cookies; Usage Data; various types of
                Data as specified in the privacy policy of the service.
                <br />
                Place of processing: Malta –
                <a href="https://www.hotjar.com/legal/policies/privacy">
                  Privacy Policy
                </a>
                –
                <a href="https://www.hotjar.com/legal/compliance/opt-out">
                  Opt Out
                </a>
                .<strong> </strong>
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Hosting and backend infrastructure</strong>
          </h5>
          <p className="standard-para">
            This type of service has the purpose of hosting Data and files that
            enable Sootchy.com to run and be distributed as well as to provide a
            ready-made infrastructure to run specific features or parts of
            Sootchy.com.
          </p>
          <p className="standard-para">
            Some services among those listed below, if any, may work through
            geographically distributed servers, making it difficult to determine
            the actual location where the Personal Data are stored.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Amazon Web Services (AWS) (Amazon Web Services, Inc.)Amazon
                  Web Services{' '}
                </strong>
                (AWS) is a hosting and backend service provided by Amazon Web
                Services, Inc.Personal Data processed: various types of Data as
                specified in the privacy policy of the service.Place of
                processing: United States –
                <a href="https://aws.amazon.com/compliance/data-privacy-faq/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  mLab (ObjectLabs Corporation)
                  <br />
                </strong>
                mLab is a hosting and backend service provided by ObjectLabs
                Corporation.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://mlab.com/company/legal/privacy/">
                  Privacy Policy
                </a>
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Infrastructure monitoring</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Sootchy.com to monitor the use and
            behavior of its components so its performance, operation,
            maintenance and troubleshooting can be improved.Which Personal Data
            are processed depends on the characteristics and mode of
            implementation of these services, whose function is to filter the
            activities of Sootchy.com.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Crashlytics (Google LLC)
                  <br />
                </strong>
                Crashlytics is a monitoring service provided by Google LLC.
                <br />
                Personal Data processed: geographic position; unique device
                identifiers for advertising (Google Advertiser ID or IDFA, for
                example); various types of Data as specified in the privacy
                policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://www.google.com/policies/privacy/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Instabug (Instabug, Inc.)
                  <br />
                </strong>
                Instabug is a monitoring service provided by Instabug, Inc.
                <br />
                Personal Data processed: address; app information; Cookies;
                country; date of birth; device information; device logs; email
                address; first name; geographic position.
                <br />
                Place of processing: United States –
                <a href="https://instabug.com/privacy">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  New Relic (New Relic)
                  <br />
                </strong>
                New Relic is a monitoring service provided by New Relic Inc.
                <br />
                The way New Relic is integrated means that it filters all
                traffic of Sootchy.com, i.e., communication between the
                Application and the User's browser or device, while also
                allowing analytical data on Sootchy.com to be collected.
                <br />
                Personal Data processed: Cookies; Usage Data; various types of
                Data as specified in the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://newrelic.com/termsandconditions/privacy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Uptime Robot (Buzpark Bilisim Tarim Urunleri Sanayi Tic. Ltd.
                  Sti.)
                  <br />
                </strong>
                Uptime Robot is a monitoring service provided by Buzpark Bilisim
                Tarim Urunleri Sanayi Tic. Ltd. Sti.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service.
                <br />
                Place of processing: Turkey –
                <a href="https://uptimerobot.com/privacyPolicy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>
              Interaction with external social networks and platforms
            </strong>
          </h5>
          <p className="standard-para">
            This type of service allows interaction with social networks or
            other external platforms directly from the pages of Sootchy.com. The
            interaction and information obtained through Sootchy.com are always
            subject to the User’s privacy settings for each social network. This
            type of service might still collect traffic data for the pages where
            the service is installed, even when Users do not use it. It is
            recommended to log out from the respective services in order to make
            sure that the processed data on Sootchy.com isn’t being connected
            back to the User’s profile.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  AddThis (Oracle Corporation)
                  <br />
                </strong>
                AddThis is a service provided by Oracle Corporation, which
                displays a widget that allows interaction with social networks
                and external platforms as well as sharing the contents of
                Sootchy.com.
                <br />
                Depending on the configuration, this service can display widgets
                belonging to third parties such as the managers of social
                networks where interactions are shared. In this case, also the
                third parties that provide the widget will be informed of
                interactions and Usage Data on the pages where this service is
                installed.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.oracle.com/legal/privacy/addthis-privacy-policy.html">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  AddToAny (AddToAny LLC)
                  <br />
                </strong>
                AddToAny is a service provided by AddToAny LLC, which displays a
                widget that allows interaction with social networks and external
                platforms as well as sharing the contents of Sootchy.com.
                <br />
                Depending on the configuration, this service can display widgets
                belonging to third parties such as the managers of social
                networks where interactions are shared. In this case, also the
                third parties that provide the widget will be informed of
                interactions and Usage Data on the pages where this service is
                installed.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.addtoany.com/privacy">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Buffer button and social widgets (Buffer)
                  <br />
                </strong>
                The Buffer button and social widgets are services allowing
                interaction with the Buffer service provided by Buffer, Inc.
                <br />
                Personal Data processed: Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://buffer.com/privacy">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Facebook Like button and social widgets (Facebook, Inc.)
                  <br />
                </strong>
                The Facebook Like button and social widgets are services
                allowing interaction with the Facebook social network provided
                by Facebook, Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.facebook.com/privacy/explanation">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  LinkedIn button and social widgets (LinkedIn Corporation)
                  <br />
                </strong>
                The LinkedIn button and social widgets are services allowing
                interaction with the LinkedIn social network provided by
                LinkedIn Corporation.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.linkedin.com/legal/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Myspace button and social widgets (Myspace LLC)
                  <br />
                </strong>
                The Myspace button and social widgets are services allowing
                interaction with the Myspace social network provided by Myspace
                LLC.
                <br />
                Personal Data processed: Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://myspace.com/pages/privacy">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Pinterest “Pin it” button and social widgets (Pinterest)
                  <br />
                </strong>
                The Pinterest “Pin it” button and social widgets are services
                allowing interaction with the Pinterest platform provided by
                Pinterest Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policy.pinterest.com/en/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Reddit button and widgets (reddit inc.)
                  <br />
                </strong>
                The reddit button and widgets are services allowing interaction
                with the reddit platform provided by reddit inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.reddit.com/help/privacypolicy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  ShareThis (Sharethis Inc.)
                  <br />
                </strong>
                ShareThis is a service provided by ShareThis Inc., which
                displays a widget that allows interaction with social networks
                and external platforms as well as sharing the contents of
                Sootchy.com.
                <br />
                Depending on the configuration, this service can display widgets
                belonging to third parties such as the managers of social
                networks where interactions are shared. In this case, also the
                third parties that provide the widget will be informed of
                interactions and Usage Data on the pages where this service is
                installed.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.sharethis.com/privacy/">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  StumbleUpon button and social widgets (StumbleUpon, Inc.)
                  <br />
                </strong>
                The StumbleUpon button and social widgets are services allowing
                interaction with the StumbleUpon social network provided by
                StumbleUpon, Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.stumbleupon.com/privacy">Privacy Policy</a>
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Tumblr sharing button and social widgets (Tumblr Inc.)
                  <br />
                </strong>
                The Tumblr sharing button and social widgets are services
                allowing interaction with the Tumblr social network provided by
                Tumblr Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.tumblr.com/privacy/en_eu">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Twitter Tweet button and social widgets (Twitter, Inc.)
                  <br />
                </strong>
                The Twitter Tweet button and social widgets are services
                allowing interaction with the Twitter social network provided by
                Twitter, Inc.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –{' '}
                <a href="https://twitter.com/privacy">Privacy Policy</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  YouTube button and social widgets (Google LLC)
                  <br />
                </strong>
                The YouTube button and social widgets are services allowing
                interaction with the YouTube social network provided by Google
                LLC.
                <br />
                Personal Data processed: Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Interaction with live chat platforms</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Users to interact with third-party live
            chat platforms directly from the pages of Sootchy.com, for
            contacting and being contacted by Sootchy.com support service.If one
            of these services is installed, it may collect browsing and Usage
            Data in the pages where it is installed, even if the Users do not
            actively use the service. Moreover, live chat conversations may be
            logged.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Zendesk Chat (Zendesk, Inc.)
                  <br />
                </strong>
                Zendesk Chat is a service for interacting with the Zendesk live
                chat platform provided by Zendesk, Inc.
                <br />
                Personal Data processed: address; country; Data communicated
                while using the service; email address; first name; last name;
                phone number.
                <br />
                Place of processing: United States –
                <a href="https://www.zendesk.com/company/customers-partners/privacy-policy/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Interaction with support and feedback platforms</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Users to interact with third-party
            support and feedback platforms directly from the pages of
            Sootchy.com.If one of these services is installed, it may collect
            browsing and Usage Data in the pages where it is installed, even if
            the Users do not actively use the service.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Zendesk Widget (Zendesk, Inc.)
                  <br />
                </strong>
                The Zendesk Widget is a service for interacting with the Zendesk
                support and feedback platform provided by Zendesk Inc.
                <br />
                Personal Data processed: address; country; date of birth; email
                address; first name; last name; phone number.
                <br />
                Place of processing: United States –
                <a href="https://www.zendesk.com/company/customers-partners/privacy-policy/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Managing contacts and sending messages</strong>
          </h5>
          <p className="standard-para">
            This type of service makes it possible to manage a database of email
            contacts, phone contacts or any other contact information to
            communicate with the User.These services may also collect data
            concerning the date and time when the message was viewed by the
            User, as well as when the User interacted with it, such as by
            clicking on links included in the message.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  HubSpot Email (HubSpot, Inc.)
                  <br />
                </strong>
                HubSpot Email is an email address management and message sending
                service provided by HubSpot, Inc.
                <br />
                Personal Data processed: email address; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://legal.hubspot.com/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Sendgrid (Sendgrid)
                  <br />
                </strong>
                Sendgrid is an email address management and message sending
                service provided by Sendgrid Inc.
                <br />
                Personal Data processed: address; country; date of birth; email
                address; first name; last name; phone number.
                <br />
                Place of processing: United States –
                <a href="https://sendgrid.com/policies/privacy/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Managing support and contact requests</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Sootchy.com to manage support and
            contact requests received via email or by other means, such as the
            contact form.The Personal Data processed depend on the information
            provided by the User in the messages and the means used for
            communication (e.g. email address).
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Zendesk (Zendesk, Inc.)
                  <br />
                </strong>
                Zendesk is a support and contact request management service
                provided by Zendesk Inc.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://www.zendesk.com/company/customers-partners/privacy-policy/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Remarketing and behavioral targeting</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Sootchy.com and its partners to inform,
            optimize and serve advertising based on past use of Sootchy.com by
            the User. This activity is facilitated by tracking Usage Data and by
            using Trackers to collect information which is then transferred to
            the partners that manage the remarketing and behavioral targeting
            activity. Some services offer a remarketing option based on email
            address lists. In addition to any opt-out feature provided by any of
            the services below, Users may opt out by visiting the
            <a href="http://optout.networkadvertising.org/?c=1">
              Network Advertising Initiative opt-out page
            </a>
            .
          </p>
          <h5 className="standard-h5">
            <strong>
              Users may also opt-out of certain advertising features through
              applicable device settings, such as the device advertising
              settings for mobile phones or ads settings in general.
            </strong>
          </h5>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Facebook Custom Audience (Facebook, Inc.)
                  <br />
                </strong>
                Facebook Custom Audience is a remarketing and behavioral
                targeting service provided by Facebook, Inc. that connects the
                activity of Sootchy.com with the Facebook advertising
                network.Users can opt out of Facebook's use of cookies for ads
                personalization by visiting this
                <a href="https://www.aboutads.info/choices/">opt-out page</a>.
                Personal Data processed: Cookies; email address.Place of
                processing: United States –
                <a href="https://www.facebook.com/about/privacy/">
                  Privacy Policy
                </a>{' '}
                –<a href="https://www.aboutads.info/choices/">Opt Out</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Facebook Remarketing (Facebook, Inc.)
                  <br />
                </strong>
                Facebook Remarketing is a remarketing and behavioral targeting
                service provided by Facebook, Inc. that connects the activity of
                Sootchy.com with the Facebook advertising network.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.facebook.com/about/privacy/">
                  Privacy Policy
                </a>{' '}
                –<a href="https://www.aboutads.info/choices/">Opt Out</a>.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Google Ad Manager Audience Extension (Google LLC)
                  <br />
                </strong>
                Google Ad Manager Audience Extension is a remarketing and
                behavioral targeting service provided by Google LLC that tracks
                the visitors of Sootchy.com and allows selected advertising
                partners to display targeted ads across the web to them.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{' '}
                –
                <a href="https://adssettings.google.com/authenticated#display_optout">
                  Opt Out
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Google Ads Remarketing (Google LLC)
                  <br />
                </strong>
                Google Ads Remarketing is a remarketing and behavioral targeting
                service provided by Google LLC that connects the activity of
                Sootchy.com with the Google Ads advertising network and the
                DoubleClick Cookie.
                <br />
                Users can opt out of Google's use of cookies for ads
                personalization by visiting Google's
                <a href="https://adssettings.google.com/authenticated">
                  Ads Settings
                </a>
                .<br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{' '}
                –
                <a href="https://adssettings.google.com/authenticated">
                  Opt Out
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  LinkedIn Website Retargeting (LinkedIn Corporation)
                  <br />
                </strong>
                LinkedIn Website Retargeting is a remarketing and behavioral
                targeting service provided by LinkedIn Corporation that connects
                the activity of Sootchy.com with the LinkedIn advertising
                network.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://www.linkedin.com/legal/privacy-policy">
                  Privacy Policy
                </a>
                –{' '}
                <a href="https://www.linkedin.com/legal/cookie_policy">
                  Opt Out
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Remarketing with Google Analytics (Google LLC)
                  <br />
                </strong>
                Remarketing with Google Analytics is a remarketing and
                behavioral targeting service provided by Google LLC that
                connects the tracking activity performed by Google Analytics and
                its Cookies with the Google Ads advertising network and the
                Doubleclick Cookie.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –{' '}
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{' '}
                –
                <a href="https://adssettings.google.com/authenticated">
                  Opt Out
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Twitter Remarketing (Twitter, Inc.)
                  <br />
                </strong>
                Twitter Remarketing is a remarketing and behavioral targeting
                service provided by Twitter, Inc. that connects the activity of
                Sootchy.com with the Twitter advertising network.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://twitter.com/en/privacy">Privacy Policy</a> –
                <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">
                  Opt Out
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Twitter Tailored Audiences (Twitter, Inc.)
                  <br />
                </strong>
                Twitter Tailored Audiences is a remarketing and behavioral
                targeting service provided by Twitter, Inc. that connects the
                activity of Sootchy.com with the Twitter advertising network.
                <br />
                Users can opt out from interest-based ads by going to:
                <a href="https://twitter.com/settings/personalization">
                  Personalization and Data
                </a>
                .<br />
                Personal Data processed: Cookies; email address.
                <br />
                Place of processing: United States –
                <a href="https://twitter.com/en/privacy">Privacy Policy</a> –
                <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">
                  Opt Out
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>RSS feed management</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Sootchy.com to manage RSS feeds and the
            distribution of their content. Depending on the characteristics of
            the service used, these services may be used to insert ads within
            the content and to gather statistics data from them.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Feedburner (Google LLC)
                  <br />
                </strong>
                Feedburner is a service to manage RSS feeds provided by Google
                LLC. It allows the collection of statistics on what content is
                consumed and to insert ads in it.
                <br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="http://www.google.com/intl/en/policies/privacy/">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  FeedPress (Beta Et Compagnie Sarl)
                  <br />
                </strong>
                FeedPress is a service to manage RSS feeds provided by Beta Et
                Compagnie Sarl. It allows the collection of statistics on what
                content is consumed.
                <br />
                Personal Data processed: Usage Data.
                <br />
                Place of processing: France –
                <a href="https://feedpress.it/privacy">Privacy Policy</a>.
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>SPAM protection</strong>
          </h5>
          <p className="standard-para">
            This type of service analyzes the traffic of Sootchy.com,
            potentially containing Users' Personal Data, with the purpose of
            filtering it from parts of traffic, messages and content that are
            recognized as SPAM.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Google reCAPTCHA (Google LLC)
                  <br />
                </strong>
                Google reCAPTCHA is a SPAM protection service provided by Google
                LLC.
                <br />
                The use of reCAPTCHA is subject to the Google
                <a href="https://www.google.com/policies/privacy/">
                  privacy policy
                </a>
                and
                <a href="https://www.google.com/intl/en/policies/terms/">
                  terms of use
                </a>
                .<br />
                Personal Data processed: Cookies; Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Akismet (Automattic Inc.)
                  <br />
                </strong>
                Akismet is a SPAM protection service provided by Automattic Inc.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service. Place of processing: United
                States –
                <a href="https://automattic.com/privacy/">Privacy Policy</a>.
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Tag Management</strong>
          </h5>
          <p className="standard-para">
            This type of service helps the Owner to manage the tags or scripts
            needed on Sootchy.com in a centralized fashion.This results in the
            Users' Data flowing through these services, potentially resulting in
            the retention of this Data.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Google Tag Manager (Google LLC)
                  <br />
                </strong>
                Google Tag Manager is a tag management service provided by
                Google LLC.
                <br />
                Personal Data processed: Usage Data.
                <br />
                Place of processing: United States –
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>Traffic optimization and distribution</strong>
          </h5>
          <p className="standard-para">
            This type of service allows Sootchy.com to distribute their content
            using servers located across different countries and to optimize
            their performance.Which Personal Data are processed depends on the
            characteristics and the way these services are implemented. Their
            function is to filter communications between Sootchy.com and the
            User's browser. Considering the widespread distribution of this
            system, it is difficult to determine the locations to which the
            contents that may contain Personal Information of the User are
            transferred.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  Sucuri CloudProxy (Sucuri, Inc.)
                  <br />
                </strong>
                Sucuri CloudProxy is a traffic optimization and distribution
                service provided by Sucuri, Inc.
                <br />
                The way Sucuri CloudProxy is integrated means that it filters
                all the traffic through Sootchy.com, i.e., communication between
                Sootchy.com and the User's browser, while also allowing
                analytical data from Sootchy.com to be collected.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://sucuri.net/privacy">Privacy Policy</a>.
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>User database management</strong>
          </h5>
          <p className="standard-para">
            This type of service allows the Owner to build user profiles by
            starting from an email address, a personal name, or other
            information that the User provides to Sootchy.com, as well as to
            track User activities through analytics features. This Personal Data
            may also be matched with publicly available information about the
            User (such as social networks' profiles) and used to build private
            profiles that the Owner can display and use for improving
            Sootchy.com. Some of these services may also enable the sending of
            timed messages to the User, such as emails based on specific actions
            performed on Sootchy.com.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>
                  HubSpot CRM (HubSpot, Inc.)
                  <br />
                </strong>
                HubSpot CRM is a User database management service provided by
                HubSpot, Inc.
                <br />
                Personal Data processed: email address; phone number; various
                types of Data as specified in the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://legal.hubspot.com/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  HubSpot Lead Management (HubSpot, Inc.)
                  <br />
                </strong>
                HubSpot Lead Management is a User database management service
                provided by HubSpot, Inc.
                <br />
                Personal Data processed: various types of Data as specified in
                the privacy policy of the service.
                <br />
                Place of processing: United States –
                <a href="https://legal.hubspot.com/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>The Rights of Users</strong>
          </h5>
          <p className="standard-para">
            Users may exercise certain rights regarding their Data processed by
            the Owner. In particular, Users have the right to do the following:
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>Withdraw their consent at any time. </strong>Users have
                the right to withdraw consent where they have previously given
                their consent to the processing of their Personal Data.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>Object to processing of their Data. </strong>Users have
                the right to object to the processing of their Data if the
                processing is carried out on a legal basis other than consent.
                Further details are provided in the dedicated section below.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>Access their Data. </strong>Users have the right to
                learn if Data is being processed by the Owner, obtain disclosure
                regarding certain aspects of the processing and obtain a copy of
                the Data undergoing processing.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>Verify and seek rectification. </strong>Users have the
                right to verify the accuracy of their Data and ask for it to be
                updated or corrected.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>Restrict the processing of their Data. </strong>Users
                have the right, under certain circumstances, to restrict the
                processing of their Data. In this case, the Owner will not
                process their Data for any purpose other than storing it.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Have their Personal Data deleted or otherwise removed.{' '}
                </strong>
                Users have the right, under certain circumstances, to obtain the
                erasure of their Data from the Owner.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>
                  Receive their Data and have it transferred to another
                  controller.{' '}
                </strong>
                Users have the right to receive their Data in a structured,
                commonly used and machine readable format and, if technically
                feasible, to have it transmitted to another controller without
                any hindrance. This provision is applicable provided that the
                Data is processed by automated means and that the processing is
                based on the User's consent, on a contract which the User is
                part of or on pre-contractual obligations thereof.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>Lodge a complaint. </strong>Users have the right to
                bring a claim before their competent data protection authority,
                or contact us at
                <a href="mailto:help@sootchy.com">help@sootchy.com</a>.
              </p>
            </li>
          </ul>
          <h4 className="standard-h4">
            <strong>California Rights</strong>
          </h4>
          <h5 className="standard-h5">
            <strong>Right to know/access</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>California residents have the right, (upon a
            verifiable request) to request the following:
          </p>
          <ol className="nopadding-list__ordered">
            <li>
              <p className="paragraph-13">
                The categories and/or the specific pieces of personal
                information about you that we collect, use, disclose, and may
                sell.
              </p>
            </li>
            <li>
              <p className="paragraph-13">
                The categories of sources from which personal information is
                collected.
              </p>
            </li>
            <li>
              <p className="paragraph-13">
                The business or commercial purpose for collecting or selling
                personal information.
              </p>
            </li>
            <li>
              <p className="paragraph-13">
                The categories of third parties with whom we share personal
                information.
              </p>
            </li>
            <li>
              <p className="paragraph-13">
                The specific pieces of personal information we have collected
                about you.
              </p>
            </li>
          </ol>
          <p className="standard-para">
            You may request this information up to two (2) times each year, and
            we will respond within forty-five (45) days without charging you.
          </p>
          <p className="standard-para">
            Additionally, you may request and obtain from us one (1) time per
            year, free of charge, information about our disclosure of personal
            information to third parties or affiliated companies for their
            direct marketing purposes.
          </p>
          <p className="standard-para">
            Please note that Sootchy may need to identify you and to ask for
            additional information in order to fulfill your request. &nbsp;You
            can make your request through the online webform listed below. You
            may be required to provide your email address on file with us among
            other information. &nbsp;We will acknowledge your request within ten
            (10) days. &nbsp;Upon verification of your identity, we will fulfill
            your request. &nbsp;However, we will not provide any information
            that we reasonably believe may be subject to fraudulent action.
          </p>
          <h5 className="standard-h5">
            <strong>Right to request deletion of personal information</strong>
          </h5>
          <p className="standard-para">
            Subject to certain expectations, California residents have the right
            to request deletion of their personal information collected or
            maintained by Sootchy. Please note that Sootchy may need to identify
            you and to ask for additional information in order to be able to
            fulfill your request. You can make your request at the toll-free
            number or through the online webform listed below. In both
            instances, you may be required to provide your email address on file
            with us among other information. &nbsp;We will acknowledge your
            request within ten (10) days. &nbsp;Upon verification of your
            identity, we will fulfill your request. &nbsp;However, we will not
            provide any information that we reasonably believe may be subject to
            fraudulent action.
          </p>
          <p className="standard-para">
            To submit a deletion request please email
            <a href="mailto:help@sootchy.com">help@sootchy.com</a> or submit a
            request through our online form – Please select Type of Request
            “Personal Data Management” and Data Request Type “Right to Deletion”
            prior to filling following fields when making your request.
            &nbsp;Please also indicate in the “Comments” field “Right to
            Deletion”.
          </p>
          <p className="standard-para">
            Please note, to ensure that your personal information is permanently
            deleted, you should delete your cookies from your browser (refer to
            our link to Sootchy cookie policy) and decline giving your consent
            via our cookies preference center (link Sootchy website form).
          </p>
          <h5 className="standard-h5">
            <strong>California minors only</strong>
          </h5>
          <p className="standard-para">
            See DATA CONCERNING MINORS section above. &nbsp;A business shall not
            sell the personal information of consumers if the business has
            actual knowledge that the consumer is less than 16 years of age,
            unless the consumer, in the case of consumers at least 13 years of
            age and less than 16 years of age, or the consumer’s parent or
            guardian, in the case of consumers who are less than 13 years of
            age, has affirmatively authorized the sale of the consumer’s
            personal information. &nbsp;This is the right to opt-in. &nbsp;If
            you are between 13 and 16 years of age, you may opt-in simply by
            consenting to cookies via our cookies preference center (here). If
            you are under 13 years of age, we do not seek to engage with you in
            any transaction, tracking by cookies or otherwise.
          </p>
          <h5 className="standard-h5">
            <strong>Authorized agent</strong>
          </h5>
          <p className="standard-para">
            You have the right to designate an authorized agent to make any CCPA
            request on your behalf. Sootchy reserves the right to ask for proof
            that such agent is in fact authorized by you. Please direct your
            agent reach out by email at
            <a href="mailto:help@sootchy.com">help@sootchy.com</a> or (323)
            677-4482. &nbsp;Authorized agents must submit proof of the
            consumer’s signed authorization to make a request. We may require
            you to verify your identity directly with us prior to fulfilling
            your authorized agent’s request.
          </p>
          <h5 className="standard-h5">
            <strong>CCPA Disclosures</strong>
          </h5>
          <p className="standard-para">
            Pursuant to the CCPA, and in addition to the disclosures elsewhere
            in this Privacy Policy, this section describes the types of personal
            information that we collected, disclosed for business purposes, or
            sold to third parties in the last twelve (12) months.
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">
                <strong>Personal Information Collected:</strong> Biographical
                information / identifiers, characteristics of protected
                classNameifications under California or federal law (i.e.,
                gender, age), commercial information, payment card information,
                internet or other electronic network activity information,
                geolocation information, visual information, and inferences
                drawn from the above. For more information about the information
                that we collect, please see the above Privacy Policy terms.
                &nbsp;The sources of the information are the consumer, our
                business customers and partners, analytics providers,
                advertising partners, social networks, and service providers.
              </p>
            </li>
            <li>
              <p className="standard-para">
                <strong>Disclosure of Personal Information: </strong>In the last
                twelve (12) months, Sootchy has disclosed the following
                categories of personal information to its service providers,
                contractors, and vendors for business purposes: Biographical
                information / identifiers, characteristics of protected
                classNameifications under California or federal law (i.e.,
                gender, age), commercial information, financial and payment
                number, internet or other electronic network activity
                information, geolocation information, education information,
                visual information, and inferences drawn from the above.
                &nbsp;To learn more, please see the above Privacy Policy terms
                <strong>.</strong>
              </p>
            </li>
          </ul>
          <h5 className="standard-h5">
            <strong>
              Right to Non-Discrimination for the Exercise of Your California
              Rights
            </strong>
          </h5>
          <p className="standard-para">
            You have the right not be discriminated against because you
            exercised any of your rights under California law.
          </p>
          <h5 className="standard-h5">
            <strong>EEA and UK residents’ rights</strong>
          </h5>
          <p className="standard-para">
            If you live in the European Economic Area (“EEA”) or United Kingdom
            (“UK”) and wish to access, amend, delete, or transfer any personal
            data we hold about you, you may contact us at
            <a href="mailto:help@sootchy.com">help@sootchy.com</a>. In some
            cases, you may also update your registration information or
            preferences by accessing your account settings page on our websites.
          </p>
          <p className="standard-para">
            At any time, you may object to the processing of your personal data
            in our legitimate interests, except if otherwise permitted by
            applicable law. If you believe there has been a violation of your
            privacy rights, please contact us at{' '}
            <a href="mailto:help@sootchy.com">help@sootchy.com</a>. You also
            have a right to lodge a complaint with your local data protection
            authorities.
          </p>
          <p className="standard-para">
            You may decline to share certain personal data with us, in which
            case we may not be able to provide to you some of the features and
            functionality of the products and services.
          </p>
          <p className="standard-para">
            We will retain your personal data for as long as necessary to
            fulfill the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements. In
            some circumstances we may anonymize your personal data (so that it
            can no longer be associated with you) for analytics purposes, in
            which case we may use this information indefinitely without further
            notice to you.
          </p>
          <p className="standard-para">
            Please be advised that the following below terms shall apply to EEA
            and UK residents to the extent they vary from the terms listed
            above.
          </p>
          <p className="standard-para">
            EEA and UK MINORS only: See DATA CONCERNING MINORS above. A business
            shall not sell the personal information of consumers if the business
            has actual knowledge that the consumer is less than 16 years of age,
            unless the consumer’s parent or guardian has affirmatively
            authorized the sale of the consumer’s personal information. This is
            the right to opt-in. If you are under 13 years of age, we do not
            seek to engage with you in any transaction, tracking by cookies or
            otherwise.
          </p>
          <p className="standard-para">
            To the extent Sootchy is required to respond to questions about data
            it collects, stores and sells, Sootchy shall endeavor to respond
            within thirty (30) days of the request being made.
          </p>
          <p className="standard-para">
            To the extent Sootchy has any of your personal data, it shall
            promptly respond to requests regarding:
            <br />
          </p>
          <ul className="no-padding-list">
            <li>
              <p className="standard-para">Why Sootchy has the data; &nbsp;</p>
            </li>
            <li>
              <p className="standard-para">
                What categories of personal data Sootchy has;
              </p>
            </li>
            <li>
              <p className="standard-para">
                Who in Sootchy’s organization or third-parties accessed the
                personal data;
              </p>
            </li>
            <li>
              <p className="standard-para">
                How long Sootchy plans on keeping the personal data;
              </p>
            </li>
            <li>
              <p className="standard-para">
                Having data deleted or fixed as requested; and
              </p>
            </li>
            <li>
              <p className="standard-para">
                Source of where the personal data was obtained. &nbsp;
              </p>
            </li>
          </ul>
          <p className="standard-para">
            You have the right to lodge a complaint with the EU Commission if
            you are displeased with any of Sootchy’s responses. Sootchy shall
            endeavor to provide requested information electronically.
            <br />
          </p>
          <p className="standard-para">
            If you believe that any personal data that Sootchy has regarding you
            is inaccurate, please contact us at
            <a href="mailto:help@sootchy.com">help@sootchy.com </a>and inform us
            as to what you believe is inaccurate.
            <br />
          </p>
          <p className="standard-para">
            If you want to have any of your personal data that Sootchy has
            regarding you deleted, please contact us as at
            <a href="mailto:help@sootchy.com">help@sootchy.com </a>and Sootchy
            with endeavor to delete the information unless Sootchy is legally
            required to retain such information.
            <br />
          </p>
          <p className="standard-para">
            If you want to have any of your personal data that Sootchy has
            regarding you kept, but not used by Sootchy, please contact us at
            <a href="mailto:help@sootchy.com">help@sootchy.com </a>and Sootchy
            with endeavor to cease using such information.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Details about the right to object to processing</strong>
          </h5>
          <p className="standard-para">
            Where Personal Data is processed for a public interest, in the
            exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may
            object to such processing by providing a ground related to their
            particular situation to justify the objection.
            <br />
          </p>
          <p className="standard-para">
            Users must know that, however, should their Personal Data be
            processed for direct marketing purposes, they can object to that
            processing at any time without providing any justification. To
            learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this
            document.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>How to exercise these rights</strong>
          </h5>
          <p className="standard-para">
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. These
            requests can be exercised free of charge and will be addressed by
            the Owner as early as possible and always within one month.
            <br />
          </p>
          <h4 className="standard-h4">
            <strong>Cookie Policy</strong>
          </h4>
          <p className="standard-para">
            Sootchy.com uses Trackers. To learn more, the User may consult the
            <a href="https://www.iubenda.com/privacy-policy/21376363/cookie-policy">
              Cookie Policy
            </a>
            .<br />
          </p>
          <h4 className="standard-h4">
            <strong>
              Additional information about Data collection and processing
            </strong>
          </h4>
          <h5 className="standard-h5">
            <strong>Legal action</strong>
          </h5>
          <p className="standard-para">
            The User's Personal Data may be used for legal purposes by the Owner
            in Court or in the stages leading to possible legal action arising
            from improper use of Sootchy.com or the related Services. The User
            declares to be aware that the Owner may be required to reveal
            personal data upon request of public authorities.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Additional information about User's Personal Data</strong>
          </h5>
          <p className="standard-para">
            In addition to the information contained in this privacy policy,
            Sootchy.com may provide the User with additional and contextual
            information concerning particular Services or the collection and
            processing of Personal Data upon request.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>System logs and maintenance</strong>
          </h5>
          <p className="standard-para">
            For operation and maintenance purposes, Sootchy.com and any
            third-party services may collect files that record interaction with
            Sootchy.com (System logs) use other Personal Data (such as the IP
            Address) for this purpose.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Information not contained in this policy</strong>
          </h5>
          <p className="standard-para">
            More details concerning the collection or processing of Personal
            Data may be requested from the Owner at any time. Please see the
            contact information at the beginning of this document.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>How “Do Not Track” requests are handled</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>Sootchy.com does not support “Do Not Track”
            requests.To determine whether any of the third-party services it
            uses honor the “Do Not Track” requests, please read their privacy
            policies.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Changes to this Privacy Policy</strong>
          </h5>
          <p className="standard-para">
            The Owner reserves the right to make changes to this Privacy Policy
            at any time by notifying its Users on this page and possibly within
            Sootchy.com and/or, as far as technically and legally feasible,
            sending a notice to Users via any contact information available to
            the Owner. It is strongly recommended to check this page often,
            referring to the date of the last modification listed at the bottom.
            <br />
          </p>
          <p className="standard-para">
            Should the changes affect processing activities performed on the
            basis of the User’s consent, the Owner shall collect new consent
            from the User, where required.
            <br />
          </p>
          <h4 className="standard-h4">
            <strong>Definitions and legal references</strong>
          </h4>
          <h5 className="standard-h5">
            <strong>Personal Data (or Data)</strong>
          </h5>
          <p className="standard-para">
            Any information that directly, indirectly, or in connection with
            other information — including a personal identification number —
            allows for the identification or identifiability of a natural
            person.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Usage Data</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>Information collected automatically through
            Sootchy.com (or third-party services employed in Sootchy.com), which
            can include: the IP addresses or domain names of the computers
            utilized by the Users who use Sootchy.com, the URI addresses
            (Uniform Resource Identifier), the time of the request, the method
            utilized to submit the request to the server, the size of the file
            received in response, the numerical code indicating the status of
            the server's answer (successful outcome, error, etc.), the country
            of origin, the features of the browser and the operating system
            utilized by the User, the various time details per visit (e.g., the
            time spent on each page within the Application) and the details
            about the path followed within the Application with special
            reference to the sequence of pages visited, and other parameters
            about the device operating system and/or the User's IT environment.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>User</strong>
          </h5>
          <p className="standard-para">
            The individual using Sootchy.com who, unless otherwise specified,
            coincides with the Data Subject.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Data Subject</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>The natural person to whom the Personal Data
            refers.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Data Processor (or Data Supervisor)</strong>
          </h5>
          <p className="standard-para">
            The natural or legal person, public authority, agency or other body
            which processes Personal Data on behalf of the Controller, as
            described in this privacy policy.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Data Controller (or Owner)</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>The natural or legal person, public authority,
            agency or other body which, alone or jointly with others, determines
            the purposes and means of the processing of Personal Data, including
            the security measures concerning the operation and use of
            Sootchy.com. The Data Controller, unless otherwise specified, is the
            Owner of Sootchy.com.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Sootchy.com (or this Application)</strong>
          </h5>
          <p className="standard-para">
            The means by which the Personal Data of the User is collected and
            processed.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Service</strong>
          </h5>
          <p className="standard-para">
            The service provided by Sootchy.com as described in the relative
            terms (if available) and on this site/application.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>European Union (or EU)</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>Unless otherwise specified, all references made
            within this document to the European Union include all current
            member states to the European Union and the European Economic Area.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Cookie</strong>
          </h5>
          <p className="standard-para">
            Cookies are Trackers consisting of small sets of data stored in the
            User's browser.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Tracker</strong>
          </h5>
          <p className="standard-para">
            Tracker indicates any technology - e.g Cookies, unique identifiers,
            web beacons, embedded scripts, e-tags and fingerprinting - that
            enables the tracking of Users, for example by accessing or storing
            information on the User’s device.
            <br />
          </p>
          <h5 className="standard-h5">
            <strong>Legal information</strong>
          </h5>
          <p className="standard-para">
            <strong>‍</strong>This privacy statement has been prepared based on
            provisions of multiple legislations, including Art. 13/14 of
            Regulation (EU) 2016/679 (General Data Protection Regulation).
            <br />
          </p>
          <p className="standard-para">
            This privacy policy relates solely to Sootchy.com, if not stated
            otherwise within this document.
            <br />
          </p>
          <p className="standard-para">
            Latest update: March 6, 2023
            <br />
          </p>
          <p className="standard-para">
            Latest update: March 6, 2023
            <br />
          </p>
        </div>
      </Navigation>
    </>
  )
}

export default PrivacyPolicy
