import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// @ts-ignore
import SootchyLogo from '../../assets/SootchyGreenLogo.png'
// @ts-ignore
import UserProfileIcon from '../../assets/user-circle.png'
// @ts-ignore
import SavingsIcon from '../../assets/savings.png'
// @ts-ignore
import MoneyAcademyIcon from '../../assets/acadamy.png'

type PropsType = {
  children: React.ReactNode
  style?: string
  color?: string
}

if (typeof window.Intercom !== 'undefined') {
  window.Intercom('update', {
    hide_default_launcher: true,
  })
}

const Navigation: React.FC<PropsType> = ({ children, style, color }) => {
  const navigate = useNavigate()
  const [route, setRoute] = useState<string>('')

  // const getBackgroundColor = (color: any) => {
  //   switch (color) {
  //     case '#7FE5C6':
  //       return '#8BE7CB'

  //     case '#92FA89':
  //       return '#9CFA94'

  //     case '#D7E57F':
  //       return '#DBE78B'

  //     case '#E3DC87':
  //       return '#E5DF93'

  //     case '#EEB154':
  //       return '#EFB865'

  //     case '#F18357':
  //       return '#F28F67'

  //     case '#BFE0FF':
  //       return '#C5E3FF'

  //     case '#D5D1FF':
  //       return '#D9D5FF'

  //     case '#E1E8DE':
  //       return '#E4EAE1'

  //     case '#E1DEE8':
  //       return '#E4E1EA'

  //     case '#F5E6EC':
  //       return '#F6E8ED'

  //     default:
  //       return '#fffff'
  //   }
  // }

  useEffect(() => {
    setRoute(window.location.href.split('/')[3])
  }, [])

  return (
    <div className="w-full">
      <div
        style={{
          backgroundColor: `${style === 'wallet' && color}`,
        }}
      >
        <div className="xl:w-[1200px] lg:m-auto">
          <div className="flex justify-between px-5 py-5 items-center">
            <div>
              <div className="flex justify-between items-center">
                <img
                  src={SootchyLogo}
                  alt="SootchyLogo"
                  className="h-[32px] cursor-pointer mr-7"
                  onClick={() => navigate('/')}
                />
                {/* <div
                  onClick={() => navigate('/wallet')}
                  style={{
                    backgroundColor: `${
                      style === 'wallet' && color && getBackgroundColor(color)
                    }`,
                  }}
                  className={`w-[50%] py-4 cursor-pointer items-center px-4 hidden lg:flex `}
                >
                  <img
                    src={SavingsIcon}
                    alt="SavingsIcon"
                    className="h-[22px] m-auto"
                  />
                  <p className="text-primary text-center font-semibold text-[14px] tracking-widest mt-1 px-2">
                    SAVINGS
                  </p>
                </div> */}
                <div
                  onClick={() => navigate('/my529')}
                  className={`w-[50%] py-4 cursor-pointer items-center px-4 hidden lg:flex `}
                >
                  <img
                    src={SavingsIcon}
                    alt="SavingsIcon"
                    className="h-[22px] m-auto"
                  />
                  <p className="text-primary text-center font-semibold text-[14px] tracking-widest mt-1 px-2">
                    MY 529
                  </p>
                </div>
                <div
                  onClick={() => navigate('/money-academy')}
                  className={`w-[50%] py-4 cursor-pointer items-center px-4 hidden lg:flex ${
                    route === 'money-academy' ? 'bg-[#F3FCFC]' : ''
                  }`}
                >
                  <img
                    src={MoneyAcademyIcon}
                    alt="MoneyAcademyIcon"
                    className="h-[22px] m-auto"
                  />
                  <p
                    className="text-primary text-center font-semibold text-[14px]
            tracking-widest mt-1 px-2"
                  >
                    ACADEMY
                  </p>
                </div>
              </div>
            </div>
            <div
              className={
                window.location.pathname === '/profile'
                  ? 'cursor-pointer ring-4 rounded-full ring-[#C4E8E8]'
                  : ''
              }
            >
              <img
                src={UserProfileIcon}
                alt="UserProfileIcon"
                className="h-[25px] cursor-pointer m-1"
                onClick={() => navigate('/profile')}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Border line */}
      <div
        style={{ backgroundColor: `${style === 'wallet' && color}` }}
        className="w-full"
      >
        <div
          className={`lg:w-[1200px] lg:m-auto border-b-2 border-dashed  ${
            style === 'wallet' ? 'border-primary' : 'border-[#C4E8E8]'
          }`}
        ></div>
      </div>

      {/* Child Component*/}
      <div className="pb-[100px]">{children}</div>

      {/* Bottom Navigation on mobile */}
      <div className="bottom-0 left-0 w-full bg-white fixed lg:hidden">
        <div className="border-b-2 border-dashed border-[#C4E8E8]"></div>
        <div className="flex justify-between">
          {/* <div
            onClick={() => navigate('/wallet')}
            className={`w-[50%] ${
              route === '' || route === 'wallet' ? 'bg-[#F3FCFC]' : ''
            } py-4 cursor-pointer`}
          >
            <img
              src={SavingsIcon}
              alt="SavingsIcon"
              className="h-[1.375rem] m-auto"
            />
            <p className="text-primary text-center font-semibold text-[14px] tracking-widest mt-1">
              SAVINGS
            </p>
          </div> */}

          <div
            onClick={() => navigate('/my529')}
            className={`w-[50%] ${
              route === '' || route === '/my529' ? 'bg-[#F3FCFC]' : ''
            } py-4 cursor-pointer`}
          >
            <img
              src={SavingsIcon}
              alt="SavingsIcon"
              className="h-[1.375rem] m-auto"
            />
            <p className="text-primary text-center font-semibold text-[14px] tracking-widest mt-1">
              MY 529
            </p>
          </div>

          <div
            onClick={() => navigate('/money-academy')}
            className={`w-[50%] py-4 cursor-pointer ${
              route === 'money-academy' ? 'bg-[#F3FCFC]' : ''
            }`}
          >
            <img
              src={MoneyAcademyIcon}
              alt="MoneyAcademyIcon"
              className="h-[1.375rem] m-auto"
            />
            <p
              className="text-primary text-center font-semibold text-[14px]
            tracking-widest mt-1"
            >
              ACADEMY
            </p>
          </div>
        </div>
      </div>

      {/* Bottom Navigation on Desktop */}
      <div
        className="hidden fixed bottom-0 left-0 w-full bg-white lg:block"
        style={{
          left: '50%',
          transform: 'translate(-50%,1%)',
        }}
      >
        <div className="lg:w-[1200px] lg:m-auto">
          {/* Border line */}
          <div className="border-b-2 border-dashed border-[#C4E8E8]"></div>
          <div className="h-[75px] flex justify-between items-center">
            <div className="flex items-center">
              <img
                src={SootchyLogo}
                alt="SootchyLogo"
                className="h-[32px] cursor-pointer"
              />
              <p className="pl-3 text-[#676767]">
                © Sootchy, Inc. All Rights Reserved.
              </p>
            </div>

            <div className="flex">
              {/* <p className="px-2 text-primaryDark underline">Help</p>
              <p className="px-2 text-primaryDark underline">Legal</p>
              <p className="px-2 text-primaryDark underline">
                Sootchy en español
              </p> */}
              <Link
                to={'/help/assitance'}
                className="px-2 text-primaryDark underline"
              >
                Help
              </Link>
              <Link to={'/legal'} className="px-2 text-primaryDark underline">
                Legal
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation
