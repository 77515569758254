// @ts-nocheck
import { initIntercomWindow, loadIntercom } from 'next-intercom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

/*
  The main purpose of this component is to update the token 
  depending on the user's activity on thep page, which simply
  created a token every 30 seconds. You could use this component 
  to do background API calls and things that does not need a 
  component re-render
*/

const BackgroundRoute = () => {
  const user = useSelector((state) => state.user)

  /* Loads intercom depending on user is logged in or not 
    If user is logged in, most probably he/she will be able
    to see the past messages
  */

  useEffect(() => {
    loadIntercom({
      appId: process.env.REACT_APP_INTERCOM_ID,
      name: user.userName ? user.userName : 'Anonymous Sootchy Web',
      email: user.emailAddress ? user.emailAddress : undefined,
      ssr: false,
      initWindow: true,
      delay: 0,
    })

    initIntercomWindow({
      appId: process.env.REACT_APP_INTERCOM_ID,
      email: user.emailAddress ? user.emailAddress : undefined,
    })
  }, [user.emailAddress, user.userName])

  return <></>
}
export default BackgroundRoute
