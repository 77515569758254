import React from 'react'
import Navigation from '../Common/Navigation'

const TermsOfUse = () => {
  return (
    <Navigation>
      <div>
        <div className="b-read legal w-richtext w-[85%] m-auto py-10 md:w-[800px]">
          <h1>Sootchy Terms of Use</h1>
          <p>
            <strong>
              These Sootchy Terms of Use were last modiﬁed in March 2023.
              <br />
              Sootchy Terms of Use and your acceptance of them. Read before
              continuing.
            </strong>
            ‍
          </p>
          <p>
            Sootchy, Inc. and Sootchy Securities, LLC (collectively “Sootchy,”
            “we,” “us,” or “our”) may provide you with the information, content,
            tools, products, and services on its mobile application (“App”).
            Your use of the App is subject to our general Terms of Use
            ("Terms").
          </p>
          <p>
            These Terms are a binding agreement between you and Sootchy. By
            using or accessing the App, you accept and agree to be bound by
            these Terms. Your use of the App is governed by the version of the
            Terms in effect on the date the App is accessed by you. Sootchy may
            modify these Terms at any time and without prior notice. You should
            review the most current version of these Terms by visiting the App
            and clicking on the Terms of Use hyperlink. These Terms are in
            addition to any other agreements between you and Sootchy, including
            any client agreement and
            <br />
            any other agreements that govern your use of information, content,
            tools, products and services available on and through the App. If
            you do not agree to these terms and conditions, do not click to
            accept or otherwise register on the App.
          </p>
          <p>
            SOOTCHY MAKES EVERY REASONABLE EFFORT TO PROVIDE ACCURATE AND TIMELY
            INFORMATION ABOUT OUR COMPANY ON THE APP, HOWEVER, YOU SHOULD NOT
            ASSUME THAT THE INFORMATION PROVIDED IS ALWAYS CURRENT OR THAT THIS
            APP CONTAINS ALL THE RELEVANT INFORMATION AVAILABLE.
          </p>
          <h3>
            <strong>1. Use of App</strong>
            <br />
          </h3>
          <p>
            The App is intended only for your personal, non-commercial use,
            unless you and Sootchy have agreed otherwise in writing. You agree
            not to, or attempt to (or permit other third parties to): (i)
            reverse engineer, or attempt to reconstruct or discover any source
            code, underlying ideas, or programming of the App; (ii) disclose
            your user name and password to others; (ii) allow anyone other than
            You, directly or indirectly, to access the App with your username
            and password; or (iv) use the App in any manner that would interfere
            with any other party's use of the App.
            <br />
          </p>
          <h3>
            <strong>
              2. Limitations on investment guidance and professional advice
            </strong>
          </h3>
          <p>
            The App is not intended to provide legal, tax, investment or
            insurance advice. Nothing on the App should be construed as an offer
            to sell, a solicitation of an offer to buy, or a recommendation for
            any security by Sootchy or any third party. Certain investment
            planning tools available on the App may provide general investment
            education based on your input. You are solely responsible for
            determining whether any investment, investment strategy, security or
            related transaction is appropriate for you based on your personal
            investment objectives, ﬁnancial circumstances and risk tolerance.
            You should consult your legal or tax professional regarding your
            speciﬁc situation.
            <br />
          </p>
          <h3>
            <strong>
              3. Services provided by Sootchy&nbsp;Securities, LLC
            </strong>
          </h3>
          <p>
            Certain investment advisory services provided via the App are
            provided by Sootchy Securities, LLC (“Sootchy Securities”), which is
            a registered investment adviser. Nothing stated on the App shall be
            considered a solicitation to buy or an offer to sell a security, or
            any other product or service, to any person in any jurisdiction
            where such offer, solicitation, purchase or sale would be unlawful
            under the laws of such jurisdiction. Further, these investment
            advisory services are only provided upon receipt of Sootchy
            Securities disclosure documents and upon entering into a separate
            electronic agreement with Sootchy Securities (“Advisory Agreement”).
            Only those limited services provided by Sootchy Securities are
            governed by the Advisory Agreement. For the avoidance of doubt, the
            following items shall not be deemed investment advice performed by
            Sootchy Securities: ﬁnancial education, ﬁnancial literacy, blog
            posts, educational material, payment processing, and gifting
            transaction facilitation.
          </p>
          <h3>
            <strong>
              4. Consent to electronic delivery of information and electronic
              execution of agreements
            </strong>
            <br />
          </h3>
          <p>
            As a condition of the use of the App or any of the services provided
            by the App, you accept and agree to receipt of information solely
            via electronic means such as via email or the App. As such, you
            agree to notify Sootchy immediately if your electronic contact
            information has changed. Further, as a condition of the use of the
            App or the receipt of any services provided by the App, you accept
            and agree that digital signatures, electronic check boxes
            acknowledging acceptance, authorization, agreement, or, consent or
            any other electronic acknowledgment of intent to enter into an
            agreement shall be legally binding.
          </p>
          <h3>
            <strong>5. Private Information</strong>‍
          </h3>
          <p>
            You will be required to provide certain details about yourself for
            the purpose of establishing an account on the App or receiving
            services via the App. You agree that any information you provided is
            correct. Further you agree to update Sootchy upon any material
            changes to such information. For more information, please see
            Sootchy’s Privacy Policy.
          </p>
          <p>
            In addition to private information provided by you, we may obtain
            personal information about you via third party service providers and
            other sources in order. Please refer to our Privacy Policy for more
            information. IN ORDER TO ALLOW SOOTCHY TO OBTAIN YOUR PERSONAL
            INFORMATION, YOU AUTHORIZE YOUR WIRELESS CARRIER TO USE OR DISCLOSE
            INFORMATION ABOUT YOUR ACCOUNT AND YOUR WIRELESS DEVICE, IF
            AVAILABLE, TO SOOTCHY OR ITS SERVICE PROVIDER FOR THE DURATION OF
            YOUR BUSINESS RELATIONSHIP, SOLELY TO HELP THEM IDENTIFY YOU OR YOUR
            WIRELESS DEVICE AND TO PREVENT FRAUD. SEE OUR PRIVACY POLICY FOR HOW
            WE TREAT YOUR DATA.
            <br />
          </p>
          <h3>
            <strong>6. Trademarks and copyrights</strong>
          </h3>
          <p>
            Unless otherwise indicated, all materials on this App, including,
            but not limited to, text, graphics, logos, button icons, images,
            audio clips, digital downloads, and data compilations, are protected
            as the copyrights, trade dress, trademarks and/ or intellectual
            properties owned by Sootchy or its licensors and are protected by
            United States and international laws regarding copyrights,
            trademarks, trade secrets and other proprietary rights, except for
            any third-party trademarks and service marks, which are the property
            of their respective owners. The term “Sootchy” is a trademark and
            the Sootchy logos and all related product and service names, designs
            and slogans are Sootchy trademarks. You may not use such marks
            without Sootchy’s prior written permission.&nbsp; All other names,
            brands and marks are used for identification purposes only and may
            be trademarks or registered trademarks of their respective owners.
            Any rights not expressly granted herein are reserved.
          </p>
          <p>
            Accordingly, you may not copy, distribute, modify, post or frame-in
            the App, including any text, graphics, video, audio, software code,
            user interface design or logos. In order to avoid confusion you may
            not suggest that Sootchy endorses, sponsors or is afﬁliated with any
            non-Sootchy app or website, entity, service or product, and may not
            make use of any Sootchy trademarks, service marks, or copyrighted
            material.
            <br />
          </p>
          <h3>
            <strong>7. Third-party content and research</strong>
          </h3>
          <p>
            The App may include educational material, news, information,
            commentary, interactive tools, research reports, and data
            concerning, ﬁnancial literacy, college savings, and other subjects
            supplied by companies that are not afﬁliated with Sootchy or links
            to such information on other websites ("Third Party Content”). On
            the App, the source of all Third Party Content will be clearly and
            prominently identiﬁed. Third Party Content is protected by copyright
            pursuant to United States laws and international treaties and is
            owned or licensed by the Third Party Content provider(s). Sootchy
            has not been involved in the preparation, adoption or editing of
            Third Party Content and does not explicitly or implicitly endorse or
            approve such content. The Third Party Content providers do not
            implicitly or explicitly endorse or approve the Third Party Content,
            nor do they give investment advice, or advocate the purchase or sale
            of any security or investment.
          </p>
          <p>
            While Sootchy makes every attempt to provide accurate and timely
            information to serve the needs of users, neither Sootchy nor the
            Third Party Content providers guarantee its accuracy, timeliness,
            completeness or usefulness, and are not responsible or liable for
            any such content, including any advertising, products, or other
            materials on or available from third party sites linked to from this
            App. Third Party Content is provided or linked to for informational
            purposes only and Sootchy and the Third Party Content providers
            speciﬁcally disclaim any responsibility for Third Party Content. You
            will use Third Party Content only at your own risk, and you
            expressly relieve Sootchy form any and all liability arising from
            your use any Third Party Content. THE THIRD PARTY CONTENT IS
            PROVIDED ON AN "AS-IS" BASIS.&nbsp; SOOTCHY AND THE THIRD PARTY
            CONTENT PROVIDERS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT.
          </p>
          <p>
            If you choose to correspond, participate in a promotion or engage in
            transactions with any third party found on or through the App, you
            acknowledge and agree that Sootchy is not a party to, and will not
            be responsible for, your interaction with such third party,
            including its treatment of your information and the Terms of Use
            applicable to any merchant or third party are solely between you and
            such third party.&nbsp; You agree that Sootchy will have no
            responsibility or liability for any loss or damage of any kind that
            you may suffer as the result of any such interaction or the presence
            of such third parties on or through the App.&nbsp; By using this
            App, you expressly relieve Sootchy from any and all liability
            arising from any transactions with any third party found on or
            through this App.&nbsp;
          </p>
          <p>
            SOOTCHY AND THE THIRD PARTY CONTENT PROVIDERS AND THEIR PARENTS,
            SUBSIDIARIES, AFFILIATES, SERVICE PROVIDERS, LICENSORS, OFFICERS,
            DIRECTORS, SUCCESSORS, ASSIGNS, SHAREHOLDERS, PRINCIPALS, AGENTS, OR
            EMPLOYEES SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING TO THE
            USE OR THE INABILITY TO USE THE THIRD PARTY CONTENT, INCLUDING BUT
            NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, USE, DATA OR OTHER
            INTANGIBLE DAMAGES, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
            <br />
          </p>
          <h3>
            <strong>
              8. Notice specific to materials available on this App
            </strong>
          </h3>
          <p>
            Sootchy hereby grants you a non-exclusive, non-transferable, limited
            license to use materials (such as brochures and press releases) from
            this App provided that the use of the materials is for
            informational, non-commercial and personal use only and will not be
            copied or posted on any network computer or broadcast in any media.
            You agree not to distribute, publish, transmit, modify, display or
            create derivative works from or exploit the contents of this App in
            any way. Except as expressly authorized by Sootchy in writing, you
            may not reproduce, sell or exploit for any commercial purposes (a)
            any part of this App, (b) access to this App or (c) use of this App
            or of any services or materials available through this App. You
            agree to indemnify, defend and hold harmless Sootchy and all Sootchy
            companies, and their respective directors, officers, shareholders,
            agents, members, insureds, attorneys, successors, assigns and
            employees (collectively, the “Indemnified Parties”) for any and all
            authorized uses you may make of any material on this App. You
            acknowledge the unauthorized use of the contents could cause
            irreparable harm to Sootchy and that in the event of unauthorized
            use, Sootchy shall be entitled to an injunction in addition to any
            other remedies available at law or in equity.
          </p>
          <h3>
            <strong>9. Timeliness of content</strong>
          </h3>
          <p>
            All content on the App is presented only as of the date published or
            indicated, and may be superseded by subsequent market events or for
            other reasons. In addition, you are responsible for setting the
            cache settings on your browser to ensure you are receiving the most
            recent data.
          </p>
          <h3>
            <strong>10. Means of access</strong>
          </h3>
          <p>
            Certain parts of the App are protected by passwords or require a
            login. You may not obtain or attempt to obtain unauthorized access
            to such parts of the App, or to any other protected materials or
            information, through any means not intentionally made available by
            Sootchy for your speciﬁc use.
          </p>
          <h3>
            <strong>11. Password security and notiﬁcation</strong>
          </h3>
          <p>
            If you have a password for access to non-public areas of the App,
            you are solely responsible for maintaining the conﬁdentiality and
            use of the password and other security data, methods and devices.
            Further, you are responsible for all activities that occur in
            connection with your password including all instructions
            electronically transmitted or use of any data, information or
            services obtained using your password and other security data.
            Sootchy shall not be under any duty to inquire as to the authority
            or propriety of any instructions given to Sootchy by you or via your
            password and shall be entitled to act upon any such instructions and
            Sootchy will not be liable for any loss, cost, expense or other
            liability arising out of any such instructions. Accordingly, you
            should take steps to protect the conﬁdentiality of your password. As
            an authorized user you accept full responsibility for the monitoring
            of your account including frequently checking your account
            information, reviewing your transaction history online and promptly
            reviewing any correspondence, account statements and conﬁrmations
            received from Sootchy or any investment custodian. Notify Sootchy
            immediately if you become aware of any unauthorized activity,
            disclosure, loss, theft or unauthorized use of your password. You
            agree to cooperate with Sootchy in any investigation and agree to
            take corrective measures to protect your account from further
            fraudulent activity.
            <br />
          </p>
          <h3>
            <strong>12. Electronic Communications</strong>
          </h3>
          <p>
            When you make use of the App or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate with you
            by e-mail or by notifications through the App. You agree that all
            agreements, notices, disclosures and other communications that we
            provide to you electronically satisfy any legal requirement that
            such communities be in writing.
            <br />
          </p>
          <h3>
            <strong>13. Disclaimer of warranties</strong>
          </h3>
          <p>
            SOOTCHY DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES ABOUT THE
            APP, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. THE APP IS MADE AVAILABLE TO YOU "AS IS" AND "AS
            AVAILABLE" AND SOOTCHY DOES NOT WARRANT THAT ANY DEFECTS OR
            INACCURACIES WILL BE CORRECTED.
          </p>
          <p>
            SOOTCHY DOES NOT WARRANT THAT THE APP WILL MEET YOUR NEEDS, OR THAT
            THEY WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. SOOTCHY
            ALSO MAKES NO WARRANTY THAT THE RESULTS OBTAINED FROM THE USE OF THE
            APP WILL BE ACCURATE OR RELIABLE, OR THAT THE QUALITY OF ANY
            PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
            OBTAINED BY YOU THROUGH THE APP WILL MEET YOUR EXPECTATIONS.
            <br />
          </p>
          <h3>
            <strong>14. Legal equivalency</strong>
          </h3>
          <p>
            This electronic document and any other electronic documents,
            policies and guidelines incorporated herein will be: (i) deemed for
            all purposes to be a “writing” or “in writing,” and to comply with
            all statutory, contractual, and other legal requirements for a
            writing: (ii) legally enforceable as a signed writing as against the
            parties subject to the electronic documents; and (iii) deemed an
            “original” when printed from electronic records established and
            maintained in the ordinary course of business. Electronic documents
            introduced as evidence in any judicial, arbitration, mediation or
            administer of proceeding will, if established and maintained in the
            ordinary course of business, be admissible to the same extent as
            business records in written form that are similarly established and
            maintained.
            <br />
          </p>
          <h3>
            <strong>15. Limitation of liability</strong>
          </h3>
          <p>
            SOOTCHY WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
            CONSEQUENTIAL, INCIDENTAL, SPECIAL, DIRECT, PUNITIVE, EXEMPLARY OR
            INDIRECT DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS, TRADING
            LOSSES OR DAMAGES THAT RESULT FROM USE OR LOSS OF USE OF THE APP AND
            THIRD PARTY CONTENT, INCONVENIENCE OR DELAY). THIS IS TRUE EVEN IF
            THE DAMAGES ARE FORESEEABLE, SOOTCHY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES OR LOSSES, OR WHETHER OR NOT YOU HAVE
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            SOOTCHY IS NOT LIABLE, AND DENIES RESPONSIBILITY FOR, ANY DAMAGES,
            HARM, OR LOSSES TO YOU OR OTHERS ARISING FROM OR RELATING TO
            HACKING, TAMPERING, OR OTHER UNAUTHORIZED ACCESS OR USE OF ITS
            SERVICES, OR YOUR FAILURE TO USE OR IMPLEMENT ANTI-FRAUD MEASURES,
            SECURITY CONTROLS, OR ANY OTHER DATA SECURITY MEASURES.&nbsp;
            SOOTCHY FURTHER DENIES RESPONSIBILITY FOR ALL LIABILITY AND DAMAGES
            TO YOU OR OTHERS CAUSED BY (A) YOUR ACCESS OR USE OF SERVICES
            INCONSISTENT WITH THE TERMS OF USE; (B) ANY UNAUTHORIZED ACCESS OF
            SERVERS, INFRASTRUCTURE, OR DATA USED IN CONNECTION WITH ITS
            SERVICES; (C) INTERRUPTIONS TO OR CESSATION OF ITS SERVICES; (D) ANY
            BUGS, VIRUSES, OR OTHER HARMFUL CODE THAT MAY BE TRANSMITTED TO OR
            THROUGH ITS SERVICES; (E) ANY ERRORS, INACCURACIES, OMISSIONS, OR
            LOSSES IN OR TO ANY DATA PROVIDED TO SOOTCHY; (F) THIRD PARTY
            CONTENT PROVIDED BY YOU; OR (G) THE DEFAMATORY, OFFENSIVE, OR
            ILLEGAL CONDUCT OF OTHERS.
          </p>
          <p>
            SOOTCHY WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS
            RESULTING FROM A CAUSE OVER WHICH SUCH SOOTCHY DOES NOT HAVE DIRECT
            CONTROL. THIS INCLUDES FAILURE OF ELECTRONIC OR MECHANICAL EQUIPMENT
            OR COMMUNICATIONS LINES (INCLUDING TELEPHONE, CABLE AND INTERNET),
            UNAUTHORIZED ACCESS, VIRUSES, EPIDEMICS, PANDEMICS, THEFT, OPERATOR
            ERRORS, SEVERE OR EXTRAORDINARY WEATHER (INCLUDING FLOOD,
            EARTHQUAKE, OR OTHER ACT OF GOD), FIRE, WAR, INSURRECTION, TERRORIST
            ACT, RIOT, CIVIL PROTESTS OR UNREST, LABOR DISPUTE AND OTHER LABOR
            PROBLEMS, ACCIDENT, EMERGENCY OR ACTION OF GOVERNMENT.
          </p>
          <p>
            IF YOU LIVE IN A STATE THAT DOES NOT ALLOW THE LIMITATION OR
            EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES THIS
            LIMITATION MAY NOT APPLY TO YOU.
          </p>
          <p>
            You agree to limit any additional liability not disclaimed or denied
            by Sootchy to your direct and documented damages; and you further
            agree that under no circumstances will any such liability exceed in
            the aggregate the amount of the total amount paid by you to Sootchy
            during the three (3) month period immediately preceding the event
            that gave rise to your claim for damages.&nbsp; These limitations on
            Sootchy’s liability to you will apply regardless of the legal theory
            on which your claim is based, including contract, tort (including
            negligence), strict liability, or any other theory or basis.
          </p>
          <h3>
            <strong>16. Indemniﬁcation</strong>
            <br />
          </h3>
          <p>
            As a condition of your use of the App, you agree to indemnify and
            hold Sootchy and its Third Party Content providers and their
            respective parents, subsidiaries, affiliates, service providers,
            licensors, officers, directors, successors, assigns, shareholders,
            principals, agents and employees, harmless from and against any and
            all claims, losses, liability, costs and expenses (including but not
            limited to attorneys' fees) arising from your use of the App, or
            from your violation of these Terms. Third Party Content providers
            are expressly made third party beneﬁciaries of these Terms and as
            such may enforce the Terms for the sole purpose of protecting their
            interests.
          </p>
          <h3>
            <strong>17. Assignment</strong>
            <br />
          </h3>
          <p>
            You may not assign or transfer these Terms of Use, by operation of
            law or otherwise, without Sootchy’s prior written consent. Any
            attempt by you to assign or transfer these Terms without such
            consent will be null and of no effect. Sootchy may assign or
            transfer these Terms of Use in its sole discretion without
            restriction. Subject to the foregoing, these Terms of Use will bind
            and inure to the benefit of the parties, their successors and
            permitted assigns.
          </p>
          <h3>
            <strong>18. Changes to App</strong>
            <br />
          </h3>
          <p>
            Unless otherwise agreed, Sootchy may discontinue or modify the App
            at any time without prior notice to you, and you accept those
            modiﬁcations if you continue to use the App.
            <br />
          </p>
          <h3>
            <strong>19. Termination</strong>
          </h3>
          <p>
            These Terms shall remain in effect for as long as you use the App,
            or until terminated Sootchy. Sootchy may terminate your access to
            the App for any reason, without prior notice.
          </p>
          <p>
            Upon termination, you will no longer be able to access the App.
            However, if you have opened investment accounts via the App, these
            accounts will not necessarily be closed upon termination of your
            user account with Sootchy. Please refer to your investment Advisory
            Agreement for more details on closing any investment account.
          </p>
          <p>
            Termination of your user account on the App does not constitute an
            instruction to the Company to delete your data. In case you require
            that your data be deleted, you shall notify Sootchy via email. Note,
            to ensure compliance with certain state and federal rules and
            regulations, certain data related to investment advisory services
            provided by Sootchy will not be deleted.
          </p>
          <p>
            The provisions of paragraphs 2, 4, 6, 7, 8, 9, 10, 11, 12, 13, 15,
            16, 17, and 18 will survive any termination of your Sootchy user
            account
            <strong>‍</strong>.<strong>‍</strong>
          </p>
          <h3>
            <strong>20. Governing law</strong>
            <br />
          </h3>
          <p>
            Unless otherwise agreed, these Terms and their enforcement are
            governed by the laws of the State of Delaware, without regard to
            conﬂicts of law, and shall inure to the beneﬁt of Sootchy's
            successors and assigns, whether by merger, consolidation, or
            otherwise. ANY LEGAL ACTION OR PROCEEDING RELATING TO YOUR ACCESS
            TO, OR USE OF, THE APP OR THESE TERMS OF USE NOT SUBJECT TO THE
            ARBITRATION PROVISION SET FORTH HEREIN WILL BE INSTITUTED ONLY IN A
            STATE, OR FEDERAL COURT LOCATED IN LOS ANGELES COUNTY, CALIFORNIA,
            USA. YOU AND SOOTCHY AGREE TO SUBMIT TO THE JURISDICTION OF, AND
            AGREE THAT VENUE IS PROPER IN, THESE COURTS IN ANY SUCH LEGAL ACTION
            OR PROCEEDING.
            <br />
          </p>
          <h3>
            <strong>21. Binding arbitration</strong>
          </h3>
          <p>
            You and Sootchy agree that any controversy or claim arising out of
            or relating to the App, use of the App, and/or the Terms of Use
            shall be settled by binding arbitration in Los Angeles County,
            California, or at such other location as may be mutually agreed upon
            by both Sootchy and you, in accordance with the applicable
            procedural rules set forth in the then prevailing Comprehensive
            Arbitration Rules and Procedures of JAMS (“JAMS Rules and
            Procedures”), and judgment upon the award rendered by the
            arbitrator(s) may be entered in any court having jurisdiction
            thereof. The JAMS Rules and Procedures are available at
            www.jamsadr.com or by calling 800-352-5267.&nbsp; The arbitrator
            shall be selected pursuant to the JAMS Rules and Procedures.
            Alternatively, you may assert your claims in small claims court in
            accordance with the terms of this agreement if your claims qualify
            and so long as the matter remains in such court and advances only on
            an individual (non-class, non-representative) basis.&nbsp; The
            arbitrator shall apply Delaware law consistent with the Federal
            Arbitration Act and applicable statutes of limitations and shall
            honor claims of privilege recognized at law. If you initiate
            arbitration against Sootchy, you will not be responsible for
            professional fees for the arbitrator’s services or any other JAMS
            fees.&nbsp; If Sootchy initiates arbitration against you, Sootchy
            will pay for the arbitrator’s services and any other JAMS fees
            associated with the arbitration. If any part of this arbitration
            provision is deemed to be invalid, unenforceable, or illegal (other
            than that claims will not be arbitrated on a class or representative
            basis), or otherwise conflicts with the rules and procedures
            established by JAMS, then the balance of this arbitration provision
            shall remain in effect and shall be construed in accordance with its
            terms as if the invalid, unenforceable, illegal or conflicting
            provision were not contained herein.&nbsp; If, however, the portion
            that is deemed invalid, unenforceable, or illegal is that claims
            will not be arbitrated on a class or representative basis, then the
            entirety of this arbitration provision shall be null and void, and
            neither you nor Sootchy shall be entitled to arbitrate their
            dispute.&nbsp; You or Sootchy may seek injunctive relief in a Court
            of competent jurisdiction.&nbsp; THE ARBITRATION OF DISPUTES
            PURSUANT TO THIS PARAGRAPH SHALL BE IN YOUR INDIVIDUAL CAPACITY, AND
            NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE OR
            JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY
            SITUATED. BY AGREEING TO THE ARBITRATION OF DISPUTES AS SET FORTH
            HEREIN, YOU AGREE THAT YOU ARE WAIVING YOUR RIGHT TO A JURY TRIAL
            AND LIMITING YOUR RIGHT TO APPEAL AND YOU UNDERSTAND THAT YOU ARE
            WAIVING YOUR RIGHTS TO OTHER AVAILABLE RESOLUTION PROCESSES, SUCH AS
            A COURT ACTION.&nbsp; DO NOT USE THIS APP IF YOU DO NOT AGREE TO THE
            FOREGOING BINDING ARBITRATION PROVISIONS.&nbsp;&nbsp;
          </p>
          <p>
            ANY CAUSE OF ACTION YOU MAY HAVE WITH RESPECT TO THE SOOTCHY APP
            MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CLAIM OR CAUSE OF
            ACTION ARISES OR SUCH CLAIM OR CAUSE OF ACTION IS BARRED.
          </p>
          <h3>
            <strong>22. Entire agreement</strong>
          </h3>
          <p>
            These Sootchy Terms of Use, along with the Sootchy Privacy Policy,
            Cookie Policy, and Customer Relationship Summary, as they may be
            amended from time-to-time, completely and exclusively state the
            agreement between you and Sootchy with respect to the Sootchy App,
            and no other terms that may have been communicated to you orally or
            in any other manner shall have any force or effect. The Sootchy
            Terms of Use, Privacy Policy, Cookie Policy, and Customer
            Relationship Summary may be amended from time-to-time by Sootchy.
          </p>
          <h3>
            <strong>23. Severability</strong>
          </h3>
          <p>
            If a court of competent jurisdiction deems any provision of these
            Terms unenforceable, that provision will be enforced to the maximum
            extent permissible, and the remaining provisions of these Terns will
            remain in full force and effect.
          </p>
          <h3>
            <strong>24. Enforceability</strong>
          </h3>
          <p>
            Sootchy’s failure to insist upon or enforce strict performance of
            any provision of these Terms shall not constitute a waiver of the
            provision. Neither a course of dealing or conduct between you and
            Sootchy nor any trade practices shall be deemed to modify these
            Terms.
          </p>
          <h3>
            <strong>25. Contact</strong>
          </h3>
          <p>
            Please contact us at
            <a href="mailto:help@sootchy.com">help@sootchy.com</a> or (323)
            677-4482 if you become aware of any content that may infringe the
            copyright or other intellectual property rights of a third party or
            that you believe to be in violation of these Sootchy Terms of Use.
          </p>
        </div>
      </div>
    </Navigation>
  )
}

export default TermsOfUse
