import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../api'
import {
  getTransactionDetails,
  handleAddMoneyFromBankToWallet,
  handleConfirmationDialogData,
  handleConfirmationOptions,
  handleRepeatTransactionAction,
  handleWithdrawMoneyFromWalletToBank,
  resetConfirmationDialogOptions,
  sendMoneyFromWalletToPerson,
  sendMoneyFromWalletToWallet,
} from '../../../redux/actions/transactionsActions'
import { RootReduxStateType } from '../../../types'
import SingleTransactionLoader from '../../Common/SkeletonLoaders/SingleTransactionLoader'
import Confirmation from '../../Common/Confirmation'
import TransactionModal from '../../Common/TransactionModal'
import { PulseLoader } from 'react-spinners'
// @ts-ignore
import Success from '../../../assets/Success.png'
import Navigation from '../../Common/Navigation'

const TransactionDetails = () => {
  const { userName, id } = useSelector(
    (state: RootReduxStateType) => state.user
  )
  const transactions = useSelector(
    (state: RootReduxStateType) => state.transactions
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [transactionLoading, setTransactionLoading] = useState<boolean>(false)
  const [transactionSuccessful, setTransactionSuccessful] =
    useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [silaWalletID, setSilaWalletID] = useState<any>()
  const [transactionType, setTransactionType] = useState<string>('')
  const [sourceUserName, setSourceUserName] = useState<string>('')
  const [sourceWalletName, setSourceWalletName] = useState<string>('')
  const [destinationUserName, setDestinationUserName] = useState<string>('')
  const [destinationWalletName, setDestinationWalletName] = useState<string>('')
  const [bankAccountName, setBankAccountName] = useState<string>('')
  const [silaAmount, setSilaAmount] = useState<number>(0)
  const [dateCreated, setDateCreated] = useState<string>('')
  const [dateTimeCreated, setDateTimeCreated] = useState<string>('')
  const [walletBalance, setWalletBalance] = useState(0)
  const [status, setStatus] = useState<string>('')
  // const [usdStatus, setUsdStatus] = useState<string>('')
  // const [tokenStatus, setTokenStatus] = useState<string>('')

  const handleUserDetails = async () => {
    try {
      const response: any = await api.get(`/UserAccount/UserAccountID/${id}`)

      if (response.status === 200) {
        setFirstName(response.data.firstName)
        setLastName(response.data.lastName)
      }
    } catch (error) {
      console.log('Failed to retrieve user details')
    }
  }

  const handleGetTransactionDetails = async () => {
    setLoading(true)

    //let silawalletid: any = params.silawalletid
    let transactionid: any = params.transactionid

    if (transactionid) {
      const response: any = await getTransactionDetails(
        silaWalletID,
        transactionid
      )

      response.transactions.map((transaction: any) => {
        const formattedAmount: any = parseFloat(transaction.silaAmount).toFixed(
          2
        )
        const amount = formattedAmount / 100

        setSilaAmount(amount)
        setSourceUserName(transaction.sourceUserName)
        setDestinationUserName(transaction.destinationUserName)
        setDestinationWalletName(transaction.destinationWalletName)
        setDateCreated(format(parseISO(transaction.created), 'MMM do, yyyy'))
        setSourceWalletName(transaction.sourceWalletName)
        setDateTimeCreated(
          format(parseISO(transaction.created), 'MMM do, yyyy, HH:mm:ss')
        )
        setTransactionType(transaction.transactionType)
        // setUsdStatus(transaction.usdStatus)
        // setTokenStatus(transaction.tokenStatus)
        setBankAccountName(transaction.bankAccountName)
        setStatus(transaction.status)

        return
      })

      setLoading(false)
    }
  }

  const getWalletDetails = async (walletname: any) => {
    try {
      const response: any = await api.get(`/SilaWallet/Wallet/${walletname}`)

      if (response.status === 200) {
        setWalletBalance(parseFloat(response.data.walletBalance) / 100)
        setSilaWalletID(response.data.silaWalletID)

        //console.log('Wallet Balance', response.data)
      }
    } catch (error) {
      console.log('Unable to fetch wallet details')
    }
  }

  const getTransactionRecipient = (transactionType: string) => {
    switch (transactionType) {
      case 'redeem':
        return bankAccountName

      case 'issue':
        return sourceWalletName

      case 'transfer':
        return destinationUserName

      default:
        return 'N/A'
    }
  }

  const getTransactionSender = (transactionType: string) => {
    switch (transactionType) {
      case 'redeem':
        return sourceWalletName

      case 'issue':
        return bankAccountName

      case 'transfer':
        return sourceWalletName

      default:
        return 'N/A'
    }
  }

  const handleRepeatTransaction = () => {
    console.log('Repeat Transaction')
    dispatch(handleRepeatTransactionAction(true))
    // dispatch(handleConfirmationDisplay(true))
    dispatch(
      handleConfirmationDialogData({
        transactionType: transactionType,
        sender: getTransactionSender(transactionType),
        recipient: getTransactionRecipient(transactionType),
        amount: silaAmount,
      })
    )
  }

  const handleModalActions = () => {
    const cancelAction = transactions.confirmationDialogOptions.handleCancel
    const confirmAction = transactions.confirmationDialogOptions.handleConfirm

    if (cancelAction) {
      dispatch(handleRepeatTransactionAction(false))
    } else if (confirmAction) {
      // console.log('Confirmation Dialog Confirm ::', confirmAction)
      setTransactionLoading(true)

      if (transactionType === 'issue') {
        // CALL API FOR ADDING
        handleAddMoneyFromBankToWallet(
          bankAccountName,
          params.silawalletname,
          silaAmount.toString()
        )
          .then(() => {
            setTransactionSuccessful(true)
            console.log('Deposit successful')
          })
          .catch((err) => console.log('Unable to repeat transaction', err))
      } else if (transactionType === 'redeem') {
        // CALL API FOR WITHDRAW
        handleWithdrawMoneyFromWalletToBank(
          params.silawalletname,
          bankAccountName,
          silaAmount.toString()
        )
          .then(() => {
            setTransactionSuccessful(true)
            console.log('Withdraw successful')
          })
          .catch((err) => console.log('Unable to repeat transaction', err))
      } else if (transactionType === 'transfer') {
        if (sourceUserName === destinationUserName) {
          console.log('SEND TO WALLET')
          sendMoneyFromWalletToWallet(
            params.silawalletname,
            destinationWalletName,
            silaAmount.toString()
          )
            .then(() => {
              setTransactionSuccessful(true)
              console.log('Transfer successful')
            })
            .catch((err) => console.log('Unable to repeat transaction', err))
        } else if (sourceUserName !== destinationUserName) {
          console.log('SEND TO PEOPLE')
          sendMoneyFromWalletToPerson(
            params.silawalletname,
            destinationUserName,
            silaAmount.toString()
          )
            .then(() => {
              setTransactionSuccessful(true)
              console.log('Transfer successful')
            })
            .catch((err) => console.log('Unable to repeat transaction', err))
        }
      }
    }
  }

  const getStatusMessage = (transactionType: string, amount: number) => {
    switch (transactionType) {
      case 'issue':
        return `$${amount} has been added`
      case 'redeem':
        return `$${amount} has been withdrawn`
      case 'transfer':
        return `$${amount} has been transferred`
      default:
        return 'Transaction successful'
    }
  }

  const repeatButton = () => {
    return (
      <div className="w-[60%] mx-auto">
        <button
          onClick={handleRepeatTransaction}
          className="w-full py-4 mb-4 mt-[36px] border-2 border-[#C4E8E8]"
        >
          Repeat Transaction
        </button>
      </div>
    )
  }

  const getBackgroundColor = (transactionType: string) => {
    if (transactionType === 'issue') {
      return 'bg-[#90F2B8]'
    } else if (transactionType === 'redeem') {
      return 'bg-[#FFE5DD]'
    } else if (transactionType === 'transfer') {
      if (sourceUserName === destinationUserName) {
        // transfer between wallet
        if (params.silawalletname === sourceWalletName) {
          //you sent
          return 'bg-[#FFE5DD]'
        } else if (params.silawalletname === destinationWalletName) {
          // you recieved

          return 'bg-[#90F2B8]'
        }
      } else if (sourceUserName !== destinationUserName) {
        //transfer between user
        if (userName === sourceUserName) {
          // you sent
          return 'bg-[#FFE5DD]'
        } else if (userName === destinationUserName) {
          // you received
          return 'bg-[#90F2B8]'
        }
      }
    }
  }

  const getTextColor = (transactionType: string) => {
    if (transactionType === 'issue') {
      return 'text-[#3EB76E]'
    } else if (transactionType === 'redeem') {
      return 'text-[#8B210085]'
    } else if (transactionType === 'transfer') {
      if (sourceUserName === destinationUserName) {
        // transfer between wallet
        if (params.silawalletname === sourceWalletName) {
          //you sent
          return 'text-[#8B210085]'
        } else if (params.silawalletname === destinationWalletName) {
          // you recieved

          return 'text-[#3EB76E]'
        }
      } else if (sourceUserName !== destinationUserName) {
        //transfer between user
        if (userName === sourceUserName) {
          // you sent
          return 'text-[#8B210085]'
        } else if (userName === destinationUserName) {
          // you received
          return 'text-[#3EB76E]'
        }
      }
    }
  }

  const getTransactionNouns = (transactionType: string) => {
    if (transactionType === 'issue') {
      return 'From'
    } else if (transactionType === 'redeem') {
      return 'To'
    } else if (transactionType === 'transfer') {
      if (sourceUserName === destinationUserName) {
        // transfer between wallet
        if (params.silawalletname === sourceWalletName) {
          //you sent
          return 'To'
        } else if (params.silawalletname === destinationWalletName) {
          // you recieved
          return 'From'
        }
      } else if (sourceUserName !== destinationUserName) {
        //transfer between user
        if (userName === sourceUserName) {
          // you sent
          return 'To'
        } else if (userName === destinationUserName) {
          // you received
          return 'From'
        }
      }
    }
  }

  const getTransactionSenderOrRecipient = (transactionType: string) => {
    if (transactionType === 'issue') {
      return bankAccountName
    } else if (transactionType === 'redeem') {
      return bankAccountName
    } else if (transactionType === 'transfer') {
      if (sourceUserName === destinationUserName) {
        // transfer between wallet
        if (params.silawalletname === sourceWalletName) {
          //you sent
          return destinationWalletName
        } else if (params.silawalletname === destinationWalletName) {
          // you recieved
          return sourceWalletName
        }
      } else if (sourceUserName !== destinationUserName) {
        //transfer between user
        if (userName === sourceUserName) {
          // you sent
          return destinationUserName
        } else if (userName === destinationUserName) {
          // you received
          return sourceUserName
        }
      }
    }
  }

  const getTransactionHeadline = (transactionType: string) => {
    if (transactionType === 'issue') {
      if (status === 'success') {
        return 'Added'
      } else if (status === 'failed') {
        return 'Deposit Failed'
      } else if (status === 'pending') {
        return 'Processing Deposit'
      }
    } else if (transactionType === 'redeem') {
      if (status === 'success') {
        return 'Withdrew'
      } else if (status === 'failed') {
        return 'Withdraw Failed'
      } else if (status === 'pending') {
        return 'Processing withdraw'
      }
    } else if (transactionType === 'transfer') {
      if (sourceUserName === destinationUserName) {
        // transfer between wallet
        if (params.silawalletname === sourceWalletName) {
          //you sent
          //return destinationWalletName
          if (status === 'success') {
            return 'Sent'
          } else if (status === 'failed') {
            return 'Transfer'
          } else if (status === 'pending') {
            return 'Processing transfer'
          }
        } else if (params.silawalletname === destinationWalletName) {
          // you recieved
          //return sourceWalletName
          if (status === 'success') {
            return 'Received'
          } else if (status === 'failed') {
            return 'Transfer failed'
          } else if (status === 'pending') {
            return 'Processing transfer'
          }
        }
      } else if (sourceUserName !== destinationUserName) {
        //transfer between user
        if (userName === sourceUserName) {
          // you sent
          if (status === 'success') {
            return 'Sent'
          } else if (status === 'failed') {
            return 'Transfer'
          } else if (status === 'pending') {
            return 'Processing transfer'
          }
        } else if (userName === destinationUserName) {
          // you received
          if (status === 'success') {
            return 'Received'
          } else if (status === 'failed') {
            return 'Transfer failed'
          } else if (status === 'pending') {
            return 'Processing transfer'
          }
        }
      }
    }
  }

  const handleIsRepeatTransactionAllowed = (transactionType: string) => {
    if (transactionType === 'issue') {
      if (status === 'success') {
        return repeatButton()
      }
    } else if (transactionType === 'redeem') {
      if (status === 'success') {
        return repeatButton()
      }
    } else if (transactionType === 'transfer') {
      if (sourceUserName === destinationUserName) {
        // transfer between wallet
        if (params.silawalletname === sourceWalletName) {
          //you sent
          //return destinationWalletName
          if (status === 'success') {
            return repeatButton()
          }
        } else if (sourceUserName !== destinationUserName) {
          //transfer between user
          if (userName === sourceUserName) {
            // you sent
            if (status === 'success') {
              return repeatButton()
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    getWalletDetails(params.silawalletname)
    handleUserDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleGetTransactionDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [silaWalletID])

  useEffect(() => {
    if (transactions.isRepeatTransaction) {
      handleModalActions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions.confirmationDialogOptions])

  return loading ? (
    <SingleTransactionLoader />
  ) : (
    <>
      <Navigation>
        {transactions.isRepeatTransaction && (
          <Confirmation
            transactionType={
              transactions.confirmationDialogData.transactionType
            }
            amount={transactions.confirmationDialogData.amount}
            recipient={transactions.confirmationDialogData.recipient}
            sender={transactions.confirmationDialogData.sender}
            isRepeatTransaction
          />
        )}

        {transactions.isRepeatTransaction &&
          (transactionLoading ? (
            <TransactionModal>
              {transactionSuccessful ? (
                <div>
                  <div className="flex flex-col items-center justify-center py-2">
                    <img src={Success} className="w-[40px]" alt="check" />
                    <div className="py-4 text-[24px] font-semibold text-textGreen">
                      {getStatusMessage(transactionType, silaAmount)}
                    </div>
                    <p>
                      It may take a few days for the money to reflect on the
                      account.
                    </p>
                  </div>
                  <div className="py-2  self-center bg-white w-full sm:relative sm:w-full">
                    <button
                      className="w-full mx-1 py-4  border-2 border-[#E3F2F2]"
                      onClick={() => {
                        dispatch(
                          handleConfirmationOptions({
                            handleChange: false,
                            handleCancel: false,
                            handleConfirm: false,
                          })
                        )
                        dispatch(handleRepeatTransactionAction(false))
                      }}
                    >
                      Awesome
                    </button>
                  </div>
                </div>
              ) : (
                <PulseLoader size={8} color="#004F4E" />
              )}
            </TransactionModal>
          ) : (
            <Confirmation
              transactionType={
                transactions.confirmationDialogData.transactionType
              }
              amount={transactions.confirmationDialogData.amount}
              recipient={transactions.confirmationDialogData.recipient}
              sender={transactions.confirmationDialogData.sender}
              isRepeatTransaction
            />
          ))}

        <div className="w-[90%] md:w-[570px] m-auto">
          <div className="py-4">
            <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
              <button
                type="button"
                className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
                onClick={() => {
                  dispatch(resetConfirmationDialogOptions())
                  navigate(-1)
                }}
              >
                <img
                  src={`/assets/images/back.svg`}
                  alt="back"
                  className="block text-primaryDark"
                />
              </button>
              {dateCreated}
            </h1>
          </div>
          <div>
            <div>
              <div
                className={`flex flex-col py-[17px] items-center justify center rounded-[10px] ${getBackgroundColor(
                  transactionType
                )}`}
              >
                <h3
                  className={`uppercase text-[16px] font-bold ${getTextColor(
                    transactionType
                  )}`}
                >
                  {getTransactionHeadline(transactionType)}
                </h3>
                <h1
                  className={`text-[48px]  pt-[4px] font-bold leading-[89.93%] ${getTextColor(
                    transactionType
                  )} `}
                >
                  $ {silaAmount}
                </h1>
              </div>
            </div>

            <div className="mt-[32px]">
              {transactionType === 'issue' ||
                (transactionType === 'redeem' && (
                  <div className="text-[#021212] flex justify-between py-[19px] border-b-[2.5px] border-[#C4E8E8] border-dashed">
                    <p className="capitalize">
                      {transactionType === 'redeem'
                        ? 'Withdrawn by'
                        : 'Added by'}
                    </p>
                    <div className="flex items-center space-x-[8px]">
                      <img
                        className="w-[28px]"
                        src="/assets/images/user-circle.svg"
                        alt="user"
                      />
                      {sourceUserName === userName && (
                        <p>
                          {firstName} <span> </span> {lastName}
                          <span>(you)</span>
                        </p>
                      )}
                    </div>
                  </div>
                ))}

              <div className="text-[#021212] flex justify-between py-[19px] border-b-[2.5px] border-[#C4E8E8] border-dashed">
                <p className="capitalize">
                  {getTransactionNouns(transactionType)}
                </p>
                <div className="flex items-center space-x-[8px]">
                  <img
                    className="w-[28px]"
                    src="/assets/images/user-circle.svg"
                    alt="user"
                  />
                  <p>{getTransactionSenderOrRecipient(transactionType)}</p>
                </div>
              </div>

              <div className="text-[#021212] flex justify-between py-[19px] border-b-[2.5px] border-[#C4E8E8] border-dashed">
                <p>Date</p>
                <div className="flex items-center space-x-[8px]">
                  <p className="">{dateTimeCreated}</p>
                </div>
              </div>

              <div className="text-[#021212] flex justify-between py-[19px]">
                <p>Balance</p>
                <div className="flex items-center space-x-[8px]">
                  <p className="">$ {walletBalance}</p>
                </div>
              </div>
            </div>

            {handleIsRepeatTransactionAllowed(transactionType)}
          </div>
        </div>
      </Navigation>
    </>
  )
}

export default TransactionDetails
