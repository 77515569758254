import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../api'
import TransactionsLoader from '../../Common/SkeletonLoaders/TransactionsLoader'
import { getLatestTransactions } from '../../../redux/actions/transactionsActions'
import Navigation from '../../Common/Navigation'
import Transaction from './Transaction'

const AllTransactions = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [silaWalletID, setSilaWalletID] = useState<any>()
  const [walletTransactions, setWalletTransactions] = useState<any>()
  const [_, setPage] = useState<number>(1)

  const getWalletDetails = async (walletname: any) => {
    try {
      const response: any = await api.get(`/SilaWallet/Wallet/${walletname}`)

      if (response.status === 200) {
        setSilaWalletID(response.data.silaWalletID)
        // console.log('Get Wallet Transactions', response.data)
      }
    } catch (error) {
      console.log('Unable to fetch wallet details')
    }
  }

  // const getWalletTransactions = async (silaID: any) => {
  //   try {
  //     setLoading(true)
  //     const response: any = await api.get(
  //       `/SilaWallet/${silaID}/Transactions/${page}`
  //     )

  //     if (response.status === 200) {
  //       setWalletTransactions(response.data.transactions)
  //       //console.log('Get Wallet Transactions', response)
  //     }

  //     setLoading(false)
  //   } catch (error) {
  //     console.log('Unable to fetch transactions')
  //   }
  // }

  const handleLatestTransactions = async (silawalletid: any) => {
    try {
      setLoading(true)
      const response: any = await getLatestTransactions(silawalletid)
      setWalletTransactions(response)
      setLoading(false)
      //console.log('Local transactions', response, walletTransactions)
    } catch (error) {
      console.log('Unable to fetch transactions')
    }
  }

  // const handleShowOlder = () => {
  //   setPage((currentPage) => currentPage + 1)
  //   //getWalletTransactions(params.id)
  // }

  useEffect(() => {
    getWalletDetails(params.silawalletname)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //getWalletTransactions(silaWalletID)
    console.log('Loading', silaWalletID, params.silawalletname)
    handleLatestTransactions(silaWalletID)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [silaWalletID])

  return (
    <Navigation>
      <div className="w-[90%] md:w-[570px] m-auto">
        <div className="py-4">
          <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
            <button
              type="button"
              className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
              onClick={() => navigate(-1)}
            >
              <img
                src={`/assets/images/back.svg`}
                alt="back"
                className="block text-primaryDark"
              />
            </button>
            All Transactions
          </h1>
        </div>

        {loading ? (
          <TransactionsLoader />
        ) : (
          <>
            {walletTransactions && walletTransactions.length > 0 ? (
              <div>
                <div>
                  {walletTransactions.map((transaction: any, index: any) => (
                    <div
                      key={index}
                      className={`py-[10px] cursor-pointer ${
                        index === 0
                          ? 'border-0'
                          : 'border-t-[2.5px] border-[#C4E8E8] border-dashed'
                      }`}
                      onClick={() => {
                        console.log('On click', transaction)
                        navigate(
                          `/transaction/${params.silawalletname}/${transaction.transactionID}`
                        )
                      }}
                    >
                      <Transaction
                        transaction={transaction}
                        currentWalletName={params.silawalletname}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="w-full bg-[#E3F2F2] h-[75px] flex justify-center items-center mt-4 rounded-lg">
                <div className="flex flex-col">
                  <p className="mt-2 text-[#0E6D6C]">
                    You don’t have any transactions yet.
                  </p>
                </div>
              </div>
            )}

            {/* <button
                //onClick={handleShowOlder}
                className="w-full py-4 mb-4 mt-[36px] border-2 border-[#C4E8E8]"
              >
                Show Older
              </button> */}
          </>
        )}
      </div>
    </Navigation>
  )
}

export default AllTransactions
