import { Dispatch } from 'redux'
import api from '../../api'
import { ActivationRegisterChildrenInfoType } from '../../types/activation'
import { formatPhoneNumberToSave } from '../../utilities/generalUtilities'
import {
  ACTIVATION_ADD_PARENT_INFORMATION,
  ACTIVATION_REGISTER_ADD_CHILD,
  ACTIVATION_REGISTER_DECREMENT_STEP,
  ACTIVATION_REGISTER_INCREMENT_STEP,
  ACTIVATION_REGISTER_REMOVE_CHILD,
  ACTIVATION_REGISTER_RESET,
  ACTIVATION_REGISTER_UPDATE_CHILD,
  ACTIVATION_REGISTER_UPDATE_GOAL,
  ADD_ACTIVATION_EVENT_INFORMATION,
  UPDATE_ACTIVATION_USER_PROFILE,
  UPDATE_RSVP_STATUS,
} from './action.types'

// ------------ Redux Functions ------------

export const activationRegisterIncrementStep =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIVATION_REGISTER_INCREMENT_STEP,
    })
  }

export const activationRegisterDecrementStep =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIVATION_REGISTER_DECREMENT_STEP,
    })
  }

export const activationRegisterAddChild = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ACTIVATION_REGISTER_ADD_CHILD,
  })
}

export const activationRegisterRemoveChild =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIVATION_REGISTER_REMOVE_CHILD,
      payload: id,
    })
  }

export const activationRegisterUpdateChild =
  (payload: { id: string; key: string; value: string }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIVATION_REGISTER_UPDATE_CHILD,
      payload,
    })
  }

export const activationRSVPStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_RSVP_STATUS,
  })
}

export const activationRegisterParentInfo =
  (data: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIVATION_ADD_PARENT_INFORMATION,
      payload: data,
    })
  }

export const activationRegisterUpdateGoal =
  (key: string, value: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIVATION_REGISTER_UPDATE_GOAL,
      payload: { key, value },
    })
  }
export const updateActivationUserProfileID =
  (data: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ACTIVATION_USER_PROFILE,
      payload: data,
    })
  }

export const getActivationEvents = async (
  activationEvent: string | undefined
) => {
  //const response = await api.get('/Activation/Tucson/2023-05-12')
  const response = await api.get(`/Activation/${activationEvent}`)

  if (response.status === 200) {
    return response.data
  }
}

export const AddActivationEventInfo = (data: any) => (dispatch: Function) => {
  dispatch({
    type: ADD_ACTIVATION_EVENT_INFORMATION,
    payload: data,
  })
}

export const activationRegisterReset = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ACTIVATION_REGISTER_RESET,
  })
}

// ------------ API Functions ------------

export const activationUserRegistration = async (
  activationEventID: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  emailAddress: string,
  phoneNumber: string
) => {
  const response = await api.post('/Activation/UserProfile', {
    ActivationUserProfileID: null,
    ActivationEventID: activationEventID,
    FirstName: firstName,
    MiddleName: '',
    LastName: lastName,
    DateOfBirth: dateOfBirth,
    EmailAddress: emailAddress,
    PhoneNumber: phoneNumber,
    SourceProcess: 'WebApplication',
  })

  if (response.status === 200) {
    return response.data
  }
}

export const activationCreateMinorAPI = async (
  profileID: number,
  data: ActivationRegisterChildrenInfoType[]
) => {
  const newArray: Object[] = []

  data.map((info) => {
    newArray.push({
      ActivationUserProfileID: profileID,
      FirstName: info.firstName,
      MiddleName: '',
      LastName: info.lastName,
      DateOfBirth: info.dateOfBirth,
      Relationship: info.relationship,
      EmailAddress: info.email === '' ? null : info.email,
      PhoneNumber:
        info.phoneNumber === '' || info.phoneNumber === '1'
          ? null
          : formatPhoneNumberToSave(info.phoneNumber),
      SourceProcess: 'WebApplication',
    })
    return
  })

  return await api.post('/Activation/Minor', newArray)
}

export const activationCreateContactBy = async (
  profileID: number,
  contactPhone: boolean,
  contactEmail: boolean,
  notificationSubscribed: boolean
) => {
  return await api.put('/Activation/UserProfile/ContactBy', {
    ActivationUserProfileID: profileID,
    IsContactByPhonePreffered: contactPhone,
    IsContactByEmailPreffered: contactEmail,
    IsNotificationSubscribed: notificationSubscribed,
    SourceProcess: 'WebApplication',
  })
}

export const activationSetupGoal = async (
  profileID: number,
  goalType: string,
  goalDescription: string,
  timeline: string
) => {
  return await api.post('/Activation/Goal', {
    ActivationUserProfileID: profileID,
    ActivationGoalType: goalType,
    ActivationGoalDescription: goalDescription,
    Timeline: `${timeline}-01-01`,
    SourceProcess: 'WebApplication',
  })
}
