import api from '../../api'
import {
  AlpacaBeneficiary,
  AlpacaIncomeType,
  AlpacaStepperType,
} from '../../types/alpaca'
import {
  ALPACA_STEPPER_AFFILIATIONS,
  ALPACA_STEPPER_DECREMENT_STEP,
  ALPACA_STEPPER_INCREAMENT_STEP,
  ALPACA_STEPPER_RESET,
  ALPACA_STEPPER_RESET_STEP,
  ALPACA_STEPPER_UPDATE_ASSISTENCE_NEEDED,
  ALPACA_STEPPER_UPDATE_BENEFICIARY,
  ALPACA_STEPPER_UPDATE_EMPLOYER,
  ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS,
  ALPACA_STEPPER_UPDATE_EMPLOYMENT_STATUS,
  ALPACA_STEPPER_UPDATE_FUNDING,
  ALPACA_STEPPER_UPDATE_PROGRESS_STATUS,
  ALPACA_STEPPER_UPDATE_STEP,
  ALPACA_STEPPER_UPDATE_TAX_AND_CITIZENSHIP,
  ALPACA_STEPPER_UPDATE_USER,
  ALPACA_STEPPER_UPDATE_VIDEO,
} from './action.types'

export const increamentAlpacaStepperStep = () => async (dispatch: Function) => {
  dispatch({
    type: ALPACA_STEPPER_INCREAMENT_STEP,
  })
}

export const decrementAlpacaStepperStep = () => async (dispatch: Function) => {
  dispatch({
    type: ALPACA_STEPPER_DECREMENT_STEP,
  })
}

export const resetAlpacaStepperStep = () => async (dispatch: Function) => {
  dispatch({
    type: ALPACA_STEPPER_RESET_STEP,
  })
}

export const updateAlpacaStepperStep =
  (step: number) => async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_STEP,
      payload: step,
    })
  }

export const alpacaStepperUpdateUser =
  (key: string, value: string | boolean | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_USER,
      payload: { key, value },
    })
  }

export const alpacaStepperUpdateVideo =
  (payload: string | null) => async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_VIDEO,
      payload,
    })
  }

export const alpacaStepperUpdateAssistenceNeeded =
  (payload: boolean) => async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_ASSISTENCE_NEEDED,
      payload,
    })
  }

export const alpacaStepperUpdateTaxAndCitizenship =
  (key: string, value: string | boolean | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_TAX_AND_CITIZENSHIP,
      payload: { key, value },
    })
  }

export const alpacaStepperUpdateFunding =
  (key: string, value: string) => async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_FUNDING,
      payload: { key, value },
    })
  }

export const alpacaStepperEmploymentStatus =
  (payload: string | null) => async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_EMPLOYMENT_STATUS,
      payload,
    })
  }

export const alpacaStepperUpdateEmployer =
  (key: string, value: string | boolean | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_EMPLOYER,
      payload: { key, value },
    })
  }

export const alpacaStepperUpdateEmployerAddress =
  (key: string, value: string | boolean | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS,
      payload: { key, value },
    })
  }

export const resetAlpacaStepper = () => async (dispatch: Function) => {
  dispatch({
    type: ALPACA_STEPPER_RESET,
  })
}

export const alpacaStepperUpdateBeneficiary =
  (key: string, value: string) => async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_BENEFICIARY,
      payload: { key, value },
    })
  }

export const alpacaStepperUpdateProgressStatus =
  (payload: AlpacaStepperType['progressStatus']) =>
  async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_UPDATE_PROGRESS_STATUS,
      payload: payload,
    })
  }

export const alpacaCreateAccount = async (data: {
  userAccountID: string
  identifier: string
  dateOfBirth: string
  firstName: string
  middleName: string
  lastName: string
}) => {
  await api.post(`Alpaca/User/KnownPerson`, {
    UserAccountID: data.userAccountID,
    KnownPersonStatus: 'PendingKyc',
    IdentifierType: 'SocialSecurityNumber',
    Identifier: data.identifier,
    DateOfBirth: data.dateOfBirth,
    FirstName: data.firstName,
    MiddleName: data.middleName,
    LastName: data.lastName,
    SourceProcess: 'ManualTest',
    ChangeReason: 'Creating a known person entry.',
    ModifyingUserAccountID: data.userAccountID,
  })
}

export const alpacaUserAccountResidency = async (data: {
  userAccountID: string | null
  birthCountryCode: string | null
  citizenshipCountryCode: string | null
  taxResidenceCountryCode: string | null
  residencyStatus: any
}) => {
  await api.post('Alpaca/User/Residency', {
    UserAccountID: data.userAccountID,
    BirthCountryCode: data.birthCountryCode,
    CitizenshipCountryCode: data.citizenshipCountryCode,
    TaxResidenceCountryCode: data.taxResidenceCountryCode,
    ResidencyStatus: data.residencyStatus,
    ModifyingUserAccountID: data.userAccountID,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Creating user residence',
  })
}

export const alpacaUserAccountVisaStatus = async (data: {
  userAccountID: string | null
  visaType: string | null
  expirationDate: any
  expectedDepartureDate: any
}) => {
  await api.post('Alpaca/User/VisaStatus', {
    UserAccountID: data.userAccountID,
    VisaType: data.visaType,
    ExpirationDate: data.expirationDate,
    ExpectedDepartureDate: data.expectedDepartureDate,
    ModifyingUserAccountID: data.userAccountID,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Creating User Visa',
  })
}

export const alpacaUserEmployment = async (data: {
  userAccountID: string | null
  employmentStatus: string | null
}) => {
  await api.post('Alpaca/User/Employment', {
    UserAccountID: data.userAccountID,
    EmploymentStatus: data.employmentStatus,
    ModifyingUserAccountID: data.userAccountID,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Create User Employment Status',
  })
}

export const alpacaUserEmployerProfile = async (data: {
  userAccountID: string | null
  employerName: string | null
  streetAddress1: string | null
  streetAddress2: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  countryCode: string | null
  occupation: string | null
}) => {
  await api.post('Alpaca/User/Employer', {
    UserAccountID: data.userAccountID,
    EmployerName: data.employerName,
    StreetAddress1: data.streetAddress1,
    StreetAddress2: data.streetAddress2,
    City: data.city,
    State: data.state,
    ZipCode: data.zipCode,
    CountryCode: data.countryCode,
    Occupation: data.occupation,
    ModifyingUserAccountID: data.userAccountID,
    SourceProcess: 'WebApplication',
    ChangeReason: 'Create Employer Profile',
  })
}

export const alpacaUserAffiliation = async (data: {
  userAccountID: string | null
  isAffiliatedWithExchangeOrFinra: boolean
  isControlPerson: boolean
  isPoliticallyExposed: boolean
  isImmediateFamilyExposed: boolean
  //isAffiliatedPerson: boolean
}) => {
  await api.post('Alpaca/User/Affiliations', {
    UserAccountID: data.userAccountID,
    IsAffiliatedWithExchangeOrFinra: data.isAffiliatedWithExchangeOrFinra,
    IsControlPerson: data.isControlPerson,
    IsPoliticallyExposed: data.isPoliticallyExposed,
    IsImmediateFamilyExposed: data.isImmediateFamilyExposed,
    //IsAffiliatedPerson: data.isAffiliatedPerson,
    ModifyingUserAccountID: data.userAccountID,
    SourceProcess: 'WebApplication',
  })
}
export type agreementsDS = {
  userAccountID: string
  alpacaAgreementType: Array<string>
  signatureDate: string
  signerIPAddress: string
}

export const alpacaUserAgreements = async (data: agreementsDS) => {
  let apiData: any = []
  data.alpacaAgreementType.forEach((agreement) => {
    apiData.push({
      userAccountID: data.userAccountID,
      alpacaAgreementType: agreement,
      signatureDate: data.signatureDate,
      signerIPAddress: data.signerIPAddress,
      CreatedUserAccountID: data.userAccountID,
      SourceProcess: 'WebApplication',
    })
  })

  const response = await api.post('Alpaca/User/Agreement', apiData)
  if (response.status === 200) {
    return response.data
  }
}

export const getCountriesList = async () => {
  const response = await api.get('Reference/countriesList')

  if (response.status === 200) {
    return response.data
  }
}

export const alpacaStepperUpdateUserAffiliations =
  (key: string, value: string | boolean | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: ALPACA_STEPPER_AFFILIATIONS,
      payload: { key, value },
    })
  }

export const getIncomeAndLiquidAssetsValue = (data: AlpacaIncomeType) => {
  switch (data) {
    case '$0.00 - $19,999.99':
      return 0

    case '$20,000 - $49,999.99':
      return 1

    case '$50,000.00 - $99,999.99':
      return 2

    case '$100,000.00 - $499,999.99':
      return 3

    case '$500,000 - $999,999.99':
      return 4

    case '$1,000,000.00 - $9,999,999.99':
      return 5

    default:
      return 0
  }
}

export const getIncomeAndLiquidAssetsData: (
  data: number
) => AlpacaIncomeType = (data: number) => {
  switch (data) {
    case 0:
      return '$0.00 - $19,999.99'

    case 1:
      return '$20,000 - $49,999.99'

    case 2:
      return '$50,000.00 - $99,999.99'

    case 3:
      return '$100,000.00 - $499,999.99'

    case 4:
      return '$500,000 - $999,999.99'

    case 5:
      return '$1,000,000.00 - $9,999,999.99'

    default:
      return '$0.00 - $19,999.99'
  }
}

export const saveUserFinances = async (
  userID: number,
  incomePerYear: AlpacaIncomeType,
  liquidAssets: AlpacaIncomeType
) => {
  return await api.post('/Alpaca/User/Finances', {
    userAccountID: userID,
    annualHouseholdIncomeFinancialRangeID:
      getIncomeAndLiquidAssetsValue(incomePerYear),
    liquidAssetsFinancialRangeID: getIncomeAndLiquidAssetsValue(liquidAssets),
    accountFundingSource: 'EmploymentIncome',
    modifyingUserAccountID: userID,
    sourceProcess: 'ManualTest',
    changeReason: 'PostmanTests',
  })
}

export const getUserFinances = async () => {
  return await api.get('/Alpaca/User/Finances')
}

export const registerAlpaca = async () => {
  return await api.post('/Alpaca/Register')
}

export const saveBeneficiaryDetailsAlpaca = async (data: AlpacaBeneficiary) => {
  return await api.post('MinorAccount', data)
}
