import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMy529Children,
  setupMy529AddChildrenInfo,
  setupMy529Update,
} from '../../../../redux/actions/my529Actions'
import { getSillaStatus, getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { My529MinorAPIType } from '../../../../types/my529'
import { SilaStatusResp } from '../../../../types/sila'
import { calculateUserAge } from '../../../../utilities/generalUtilities'
import { LoadingSpinner } from '../../../Common/Loading'
import My529AddChildModal from './My529AddChildModal'
// @ts-ignore
import PlusIcon from '../../../../assets/plusnew.png'
// @ts-ignore
import PencilIcon from '../../../../assets/pencil.png'
// @ts-ignore
import CheckIcon from '../../../../assets/green-check.png'

type PropsType = {
  disableForm?: React.Dispatch<React.SetStateAction<boolean>>
}

const My529ChildMain: React.FC<PropsType> = ({ disableForm }) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [showAddChildModal, setShowAddChildModal] = useState(false)
  /* When user is editing the child info, 
  this ID will be sent to the modal */
  const [childID, setChildID] = useState<string | null>(null)
  const [childInfo, setChildInfo] = useState<My529MinorAPIType[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [verificationStatus, setVerificationStatus] = useState<
    'Passed' | 'Documents_Received' | 'Pending' | 'Server_Error' | null
  >(null)
  const [isSilaAccountCreated, setIsSilaAccountCreated] =
    useState<boolean>(false)

  const handleUpdateChild = (id: string) => {
    setChildID(id)
    setShowAddChildModal(true)
  }

  const checkChildrenInfo = () => {
    if (childInfo) {
      const invalidFields: any[] = []

      childInfo.map((child) => {
        if (
          !child.isMy529DelegatedToMySelf &&
          (child.identifier === 'XXX-XX-XXXX' ||
            child.identifier === null ||
            child.identifier === '')
        ) {
          invalidFields.push(child.firstName)
          return
        }
      })

      dispatch(setupMy529Update('invalidFields', invalidFields))
    }
  }

  const getChildrenInfo = () => {
    getMy529Children()
      .then((res) => {
        setChildInfo(res.data)
        setLoading(false)
        //dispatch(setupMy529AddChildrenInfo(res.data))

        const pendingApplications = res.data.filter(
          (item: any) =>
            item.applicationStatus === '' ||
            item.applicationStatus === null ||
            item.applicationStatus === undefined ||
            item.applicationStatus === 'ApplicationPendingFromCustomer' ||
            item.applicationStatus === 'Unknown' ||
            item.applicationStatus === 'NotYetStarted'
        )
        dispatch(setupMy529AddChildrenInfo(pendingApplications))
      })
      .catch(() => {
        alert('Unable to retrive children info')
      })
  }

  const handleDisableForm = () => {
    if (disableForm && childInfo) {
      disableForm(childInfo.length === 0 ?? false)
    }
  }

  const handleGetUser = () => {
    getUser(user.id)
      .then((res) => {
        if (res.status === 200) {
          if (!res.data.silaUserAccountID) setIsSilaAccountCreated(false)
          else setIsSilaAccountCreated(true)
          setLoading(false)
        }
      })
      .catch(() => {
        alert('Error retrieving user')
        setLoading(false)
      })
  }

  const handleGetSilaaStatus = async () => {
    try {
      const res = await getSillaStatus(user.id)
      const data: SilaStatusResp = res.data
      if (res.status === 200) {
        setVerificationStatus(data.silaAccountVerificationStatus ?? null)
      }
    } catch (err: any) {
      setVerificationStatus('Server_Error')
    }
  }

  const handleIsMinorDelegated = () => {
    if (childInfo) {
      if (childInfo.length > 0) {
        const filteredChildren = childInfo.filter(
          (info) => info.isMy529DelegatedToMySelf === true
        )[0]

        dispatch(
          setupMy529Update('isMinorDelegated', filteredChildren ? true : false)
        )
      }
    }
  }

  const isApplicationCompleted = (status: any) => {
    if (
      status === '' ||
      status === null ||
      status === undefined ||
      status === 'Unknown' ||
      status === 'NotYetStarted' ||
      status === 'ApplicationPendingFromCustomer'
    )
      return false

    return true
  }

  useEffect(() => {
    handleGetUser()
    getChildrenInfo()
    handleGetSilaaStatus()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleDisableForm()
    handleIsMinorDelegated()
    checkChildrenInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childInfo])

  return (
    <div className="w-full bg-[#E3F2F2] min-h-full rounded-lg my-7 p-4">
      {showAddChildModal && (
        <My529AddChildModal
          onClose={() => {
            setShowAddChildModal(false)
            getChildrenInfo()
          }}
          id={childID}
          childInfo={childInfo ?? []}
        />
      )}

      {loading && (
        <div className="flex items-center justify-center py-5">
          <LoadingSpinner color="#004F4E" />
        </div>
      )}

      {childInfo &&
        childInfo.map((child: any) => (
          <div
            key={child.minorAccountID}
            className="bg-white shadow-md p-3 rounded-lg mt-1 mb-4 flex justify-between"
          >
            <div>
              <div className="flex items-center">
                <p>{child.firstName}</p>

                {!isApplicationCompleted(child.applicationStatus) && (
                  <img
                    src={PencilIcon}
                    alt="PencilIcon"
                    className="px-3 h-[18px] cursor-pointer"
                    onClick={() => handleUpdateChild(child.minorAccountID)}
                  />
                )}
              </div>
              <p className="text-primary mt-1 text-[15px]">
                {child.dateOfBirth &&
                  calculateUserAge(child.dateOfBirth) + ', '}
                {child.emailAddress || child.emailAddress !== ''
                  ? ', ' + child.emailAddress + ', '
                  : ''}

                {!child.identifier
                  ? ''
                  : child.identifier === 'XXX-XX-XXXX'
                  ? ''
                  : child.isMy529DelegatedToMySelf
                  ? ''
                  : child.identifier}
              </p>
            </div>

            {isApplicationCompleted(child.applicationStatus) ? (
              <div>
                <img src={CheckIcon} alt="CheckIcon" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}

      {isSilaAccountCreated ? (
        verificationStatus === 'Passed' && (
          <div
            className="w-full border-none bg-[#C4E8E8] py-4 cursor-pointer flex items-center justify-center rounded-lg"
            onClick={() => {
              setChildID(null)
              setShowAddChildModal(true)
            }}
          >
            <img src={PlusIcon} alt="Plus" className="h-[20px]" />
            <p className="text-primaryDark px-2">Add child</p>
          </div>
        )
      ) : (
        <div
          className="w-full border-none bg-[#C4E8E8] py-4 cursor-pointer flex items-center justify-center rounded-lg"
          onClick={() => {
            setChildID(null)
            setShowAddChildModal(true)
          }}
        >
          <img src={PlusIcon} alt="Plus" className="h-[20px]" />
          <p className="text-primaryDark px-2">Add child</p>
        </div>
      )}
    </div>
  )
}

export default My529ChildMain
