import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootReduxStateType } from '../../../../types'
import DateInput from '../../../Common/DateInput/DateInput'

type PropTypes = {
  styles: {
    button: string
    input: string
    backButton: string
  }
  handleDataChange: (e: any) => any
  increamentStep: () => void
  underageStep: () => void
}

const StepOne = ({
  styles,
  handleDataChange,
  increamentStep,
  underageStep,
}: PropTypes) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userRegistration = useSelector(
    (state: RootReduxStateType) => state.userRegistrationStepper
  )
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null)
  const [formatedDateOfBirth, setFormatedDateOfBirth] = useState<string | null>(
    null
  )
  const user = useSelector(
    (rootState: RootReduxStateType) => rootState.user.token
  )
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setFormatedDateOfBirth(dayjs(dateOfBirth).format('YYYY-MM-DD'))

    calculateUserAge(dayjs(dateOfBirth).format('YYYY-MM-DD'))

    if (formatedDateOfBirth) {
      handleDataChange({
        target: {
          name: 'dateOfBirth',
          value: formatedDateOfBirth,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirth, formatedDateOfBirth])

  useEffect(() => {
    if (userRegistration.data.dateOfBirth) {
      setDateOfBirth(userRegistration.data.dateOfBirth)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculateUserAge = (date: string) => {
    let birthDate = dateOfBirth
    let now = dayjs(new Date())
    const result = now.diff(birthDate, 'years')

    return result
  }

  const handleOnClick = () => {
    setLoading(true)
    const result = isUnderAge()

    result ? underageStep() : increamentStep()
    setLoading(false)
  }

  const isUnderAge = () => {
    //let age = calculateFullAge(formatedDateOfBirth).years
    let age = calculateUserAge(dayjs(dateOfBirth).format('YYYY-MM-DD'))

    if (age < 13) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (user) navigate('/')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex border-b-2 border-primaryLight border-dashed mb-[24px]">
        <p className="text-primary text-[11px] my-[12px] leading-[13.76px] font-semibold ">
          STEP 1 OUT OF 3
        </p>
        <div className="flex items-center space-x-2 ml-[16px]">
          <div className="bg-primaryLight border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
          <div className="border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
          <div className="border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
        </div>
      </div>
      {/**       <FullPageTitle title={t('User.RegisterBirthdayTitle')} onBack={goHome} /> */}
      <p className="mb-[24px]">
        <p>To get started, what's your birthday? 🎂</p>
      </p>

      <DateInput
        dateValue={dateOfBirth!}
        onChange={(newValue) => setDateOfBirth(newValue)}
      />

      <div className="mt-[240px] pt-[24px] border-t-2 border-primaryLight border-dashed">
        <button
          className={styles.button}
          onClick={handleOnClick}
          disabled={
            loading ? true : !dateOfBirth || dateOfBirth === 'Invalid Date'
          }
        >
          {t('Base.Next')}
        </button>
      </div>
    </div>
  )
}

export default StepOne
