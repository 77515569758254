import React from 'react'

const ProfileManagementLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="mb-4">
        <div className="h-[50px] bg-slate-200 rounded-[10px] w-full"></div>
      </div>

      <div className="mb-4">
        <div className="h-[50px] bg-slate-200 rounded-[10px] w-full"></div>
      </div>

      <div className="mb-4">
        <div className="h-[50px] bg-slate-200 rounded-[10px] w-full"></div>
      </div>

      <div className="mb-4">
        <div className="h-[50px] bg-slate-200 rounded-[10px] w-full"></div>
      </div>
    </div>
  )
}

export default ProfileManagementLoader
