import React from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import Faq from '../../Common/Partials/Faq'

const SootchyFAQs: React.FC = () => {
  const { t } = useTranslation()

  const faq = [1, 2, 3, 4, 5].map((item: number, i: number) => ({
    title: t(`Home.Faq.${item}.Title`),
    content: t(`Home.Faq.${item}.Content`),
    open: false,
  }))

  return (
    <>
      <Faq items={faq} />
    </>
  )
}

export default SootchyFAQs
