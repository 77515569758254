import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @ts-ignore
// @ts-ignore
// @ts-ignore
import BoldBottomIcon from '../../../assets/BoldBottomIcon.png'
//@ts-ignore
import WalletPlusIcon from '../../../assets/WalletPlusIcon.png'
// @ts-ignore
import BankStarIcon from '../../../assets/BankStarIcon.png'
import BankModal from './BankModal'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { PinInput } from 'react-input-pin-code'
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'
import { useDispatch, useSelector } from 'react-redux'
import { getLinkTokenFromStore } from '../../../api'
import { LOG_IN } from '../../../redux/actions/action.types'
import { validateMultiFactorAuth } from '../../../redux/actions/generalActions'
import {
  BankAccount,
  getAllBanks,
  linkTokenAccess,
  PlaidPublicMetadata,
  publicTokenAccess,
  removeBank,
  setDefaultBank,
} from '../../../redux/actions/linkActions'
import { getUser } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { BankType } from '../../../types/transactions'
import { UserAPIType } from '../../../types/user'
import {
  getUserAge,
  getUserFromToken,
} from '../../../utilities/generalUtilities'
import Navigation from '../../Common/Navigation'
import Modal from '../../Common/Partials/Modal'

const ConnectedBanks = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [showOptionDropDown, setShowOptionDropDown] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [banks, setBanks] = useState<BankType[]>()
  const [loadedUser, setLoadedUser] = useState<UserAPIType>()
  const [openModal, setOpenModal] = useState(false)
  const [removeBankData, setRemoveBankData] = useState<{
    id: number
    name: string
  }>({ id: 0, name: '' })
  const [validateMFA, setValidateMFA] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [values, setValues] = useState(['', '', '', '', '', ''])

  const dispatch = useDispatch()
  const linkToken = useSelector((state) => getLinkTokenFromStore())

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata: any) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      console.log(publicToken, metadata)
      const accounts: Array<BankAccount> = []
      metadata.accounts.forEach((account: any) => {
        let temp: BankAccount = {
          Id: account.id ? account.id : 'NoInfoProvided',
          Name: account.name ? account.name : 'NoInfoProvided',
          Mask: account.mask ? account.mask : 'NoInfoProvided',
          AccountType: account.type ? account.type : 'NoInfoProvided',
          AccountSubType: account.subtype ? account.subtype : 'NoInfoProvided',
        }
        accounts.push(temp)
      })
      const data: PlaidPublicMetadata = {
        PublicToken: publicToken,
        PlaidAccountID: metadata?.account_id,
        BankName: metadata?.institution?.name,
        BankID: metadata?.institution?.institution_id,
        Accounts: accounts,
      }
      dispatch(publicTokenAccess(data))
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    onSuccess,
  }
  const { open } = usePlaidLink(config)

  const handleGetAllBanks = async () => {
    const response = await getAllBanks()

    if (response.status === 200) setBanks(response.data)
    console.log(response)
  }

  const handleGetUser = async () => {
    try {
      const response = await getUser(user.id)

      if (response.status === 200) setLoadedUser(response.data)
    } catch (err: any) {
      return { success: false, err }
    }
  }

  const handleMakeDefaultBanks = async (plaidBankAccountID: number) => {
    if (loadedUser) {
      setShowOptionDropDown('')

      await setDefaultBank({
        silaUserAccountID: loadedUser?.silaUserAccountID ?? 0,
        modifyingUserAccountID: loadedUser?.userAccountID ?? 0,
        plaidBankAccountID,
      })

      handleGetAllBanks()
    }
  }

  const handleShowRemoveBankModal = (plaidBankAccountID: number) => {
    setShowOptionDropDown('')

    setShowModal(true)
    setRemoveBankData({ id: plaidBankAccountID, name: 'something' })
  }

  const handleRemoveBank = async () => {
    if (loadedUser) {
      await removeBank({
        silaUserAccountID: loadedUser?.silaUserAccountID ?? 0,
        modifyingUserAccountID: loadedUser?.userAccountID ?? 0,
        plaidBankAccountID: removeBankData.id,
      })

      handleGetAllBanks()
      setShowModal(false)
    }
  }

  const handleConnectBankAccount = () => {
    if (user.is2FAEnabled) {
      setValidateMFA(true)
      setOpenModal(true)
    } else {
      open()
    }
  }

  // const handleValidate = async () => {
  //   const code = values.join('')

  //   values.join('').length === 6 &&
  //     (await validateMultiFactorAuth(code, navigate)
  //       .then((res: any) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           const {
  //             UserName: userName,
  //             UserAccountID: id,
  //             is2FAEnabled: is2FA,
  //             DateOfBirth,
  //           } = getUserFromToken(res.data.accessToken)
  //           setLoading(false)

  //           dispatch({
  //             type: LOG_IN,
  //             payload: {
  //               id,
  //               userName,
  //               userAge: getUserAge(dayjs(DateOfBirth).format('YYYY-MM-DD')),
  //               emailAddress: '',
  //               token: res.data.accessToken,
  //               is2FAEnabled: is2FA,
  //             },
  //           })

  //           setLoading(false)
  //           open()
  //           setOpenModal(false)
  //           setValues(['', '', '', '', '', ''])
  //         } else {
  //           setLoading(false)
  //           setErrorMessage('Your code is not valid, please try again')
  //         }
  //       })
  //       .catch((err) => console.log('Failed to validate')))
  // }

  const handleClear = () => {
    setErrorMessage('')
    setValues(['', '', '', '', '', ''])
  }

  useEffect(() => {
    dispatch(linkTokenAccess())
    handleGetUser()
    handleGetAllBanks()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Navigation>
      <div className="w-[90%] md:w-[570px] m-auto">
        <div className="py-4">
          <h1 className="fullpage-title text-[1.125rem] text-primaryDark">
            <button
              type="button"
              className={`btn flex items-center justify-center mr-[20px] absolute top-[50%] left-0 translate-y-[-50%] h-[36px] border-[#C4E8E8] border-2`}
              onClick={() => navigate(-1)}
            >
              <img
                src={`/assets/images/back.svg`}
                alt="back"
                className="block text-primaryDark"
              />
            </button>
            Connected Banks
          </h1>
        </div>

        {validateMFA && (
          <div className="absolute top-0 left-0">
            <Modal onLogin openModal={openModal} setOpenModal={setOpenModal}>
              <div className="w-[80%] flex flex-col items-center">
                <p className="text-lg mb-4">{t('2FA.ModalTitle')}</p>
                <p className="text-sm text-red-500 mb-2">{errorMessage}</p>

                <PinInput
                  values={values}
                  size="md"
                  placeholder=""
                  onChange={(value, index, values) => setValues(values)}
                  autoComplete="off"
                  autoFocus
                  focusBorderColor="#0E6D6C"
                  validBorderColor={errorMessage ? 'rgb(220,53,69)' : '#0E6D6C'}
                  //onComplete={handleValidate}
                />

                <div className="md:flex justify-between my-6 md:w-[80%] w-full">
                  <button
                    className="btn btn--gray py-4 w-full cursor-pointer"
                    //onClick={handleValidate}
                    onClick={handleClear}
                  >
                    {t('2FA.Clear')}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}

        {showModal && (
          <BankModal
            onClose={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            onSubmit={handleRemoveBank}
            loading={loading}
          />
        )}

        {banks &&
          banks.map((bank: BankType, index: number) => (
            <div
              key={index}
              className="flex justify-between items-center border-b-2 border-[#C4E8E8] border-dashed"
            >
              <div className="flex justify-between items-center py-5">
                {bank.bankLogo ? (
                  <img
                    src={`data:image/png;base64,${bank.bankLogo}`}
                    alt="Bank Logo"
                    className="h-[32px]"
                  />
                ) : (
                  <p className="">{bank.bankName}</p>
                )}

                <p className="text-[14px] px-3">
                  {`XXX${bank.maskedAccountNumber}`}
                </p>
                {bank.isDefault && (
                  <div className="px-1">
                    <img src={BankStarIcon} alt={'BankStarIcon'} />
                  </div>
                )}
              </div>
              <div>
                <div
                  className={`border-2 transition-all ${
                    showOptionDropDown === bank.accountName
                      ? 'border-[#004F4E]'
                      : 'border-[#C4E8E8]'
                  }  h-[20px] py-4 px-3 flex items-center justify-center rounded-lg  cursor-pointer `}
                  onClick={() => {
                    if (showOptionDropDown === bank.accountName) {
                      setShowOptionDropDown('')
                      return
                    }
                    setShowOptionDropDown(bank.accountName)
                  }}
                >
                  <p className="text-[12px] pr-2 relative">Options</p>
                  <img
                    src={BoldBottomIcon}
                    alt="BoldBottomIcon"
                    className="w-[10px]"
                  />
                </div>
                <div
                  className={`bg-white p-4 absolute rounded-lg shadow-lg w-[150px] z-50 ${
                    showOptionDropDown === bank.accountName ? 'block' : 'hidden'
                  }`}
                >
                  <p
                    className="text-[14px] py-1 text-primary cursor-pointer"
                    onClick={() =>
                      handleMakeDefaultBanks(bank.plaidBankAccountID)
                    }
                  >
                    Make default
                  </p>
                  <p
                    className="text-[14px] py-1 text-primary cursor-pointer"
                    onClick={() =>
                      handleShowRemoveBankModal(bank.plaidBankAccountID)
                    }
                  >
                    Disconnect
                  </p>
                </div>
              </div>
            </div>
          ))}

        <div className="w-full flex justify-center items-center rounded-lg mt-7 cursor-pointer">
          <div
            className="flex justify-center items-center"
            onClick={handleConnectBankAccount}
          >
            <img
              src={WalletPlusIcon}
              alt="WalletPlusIcon"
              className="w-[20px] m-auto"
            />
            <p className="text-[#0E6D6C] ml-3 text-[15px]">
              Connect another account
            </p>
          </div>
        </div>
      </div>
    </Navigation>
  )
}

export default ConnectedBanks
