import React from 'react'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepSixteen = () => {
  return (
    <div>
      <SafeAreaView>
        <div>Still on progress :)</div>
      </SafeAreaView>
      <SetupBottomAlpaca disabled={false} handleNext={() => null} />
    </div>
  )
}

export default AlpacaStepSixteen
