import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LayoutFullPage from '../components/Common/Layouts/FullPage'
import FullPageTitle from '../components/Common/Partials/FullPageTitle'
import { LoadingSpinner } from '../components/Common/Loading'
import {
  saveMultiFactorAuth,
  setupMultiFactorAuth,
} from '../redux/actions/generalActions'
import { RootReduxStateType } from '../types'
import { logoutUser } from '../utilities/generalUtilities'

const MultiFacorAuthentication = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [manualEntryKey, setManualEntryKey] = useState('')
  const [dataImage, setDataImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [settingUpMFA, setSettingUpMFA] = useState(false)
  const [copy, setCopy] = useState('Copy')

  const user = useSelector((state: RootReduxStateType) => state.user)

  useEffect(() => {
    generateQRCode()
  }, [])

  const generateQRCode = async () => {
    setLoading(true)
    console.log('Generating QR Code ')
    await setupMultiFactorAuth()
      .then((data) => {
        setManualEntryKey(data.manualEntryKey)
        setDataImage(data.qrCodeSetupImageUrl)
        setLoading(false)
        console.log('QR Code Generated')
      })
      .catch(() => console.log('Failed to Load Clientside MFA'))
  }

  const handleValidation = async () => {
    try {
      setSettingUpMFA(true)
      window.print()
      const res = await saveMultiFactorAuth(user.id, manualEntryKey)
      if (res.status >= 200 || res.status < 400) {
        setSettingUpMFA(false)
        navigate('/overview')
      }
    } catch (err: any) {
      logoutUser()
    }
  }

  function handleCopy() {
    setCopy('Copied')
    navigator.clipboard.writeText(`${manualEntryKey}`)
    setTimeout(() => {
      setCopy('Copy')
    }, 2000)
  }

  return (
    <LayoutFullPage>
      <FullPageTitle
        title="Two Factor Authentication"
        onBack={() => navigate(-1)}
      />

      {loading ? (
        <div className="flex justify-center w-full">
          <LoadingSpinner color="#0E6D6C" size={8} />
        </div>
      ) : (
        <div
          id="SetupMFA"
          className="flex flex-col justify-center items-center"
        >
          <p>
            To Setup two factor authentication you need to install google
            Authenticator app into your phone. To Install this app follow the
            instruction below. If you have the app already installed on your
            phone. Please ignore the installation process. Just open your app
            and scan the QR code
          </p>
          <p className="mt-[20px] mb-[20px]">
            <li>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                className="text-x text-[#835805] mb-[16px] font-bold"
                target="blank"
              >
                Install Google Authenticator into your Apple Devices
              </a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                className="text-x text-[#835805] mb-[16px] font-bold"
                target="blank"
              >
                Install Google Authenticator into your Andriod Devices
              </a>
            </li>
          </p>
          <div className="text-center mb-[10px]">
            <p>{t('2FA.Please')}</p>
          </div>
          <img
            className="w-[200px] h-[200px] my-[20px] object-contain"
            src={dataImage}
            alt="QR Code"
          />
          <div className="text-center mb-[10px]">
            <p className="text-xl text-[#835805] mb-[16px] font-bold">OR</p>
            <p className="">{t('2FA.Desc')}</p>
          </div>

          <div className={styles.inviteCopyModal.border}>
            <div className="flex py-2">
              <input
                aria-label="Dra"
                className="bg-transparent font-bold appearance-none border-0rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                type="text"
                disabled
                value={manualEntryKey}
              />
              <button
                className="text-[14px] border-opacity-25"
                onClick={handleCopy}
              >
                {copy}
              </button>
            </div>
          </div>

          <div className="text-center mb-[10px]">
            <p className=" text-[#835805] mb-[16px] font-bold">
              Please save the printed copy in a safe place. It is advisible to
              keep the physical copy of QR code so that It can used in future.
              Even if you lost your phone you can scan this code an login to
              sootchy account.
            </p>
          </div>

          <button
            className="btn btn--big btn--dark my-[16px]"
            onClick={handleValidation}
          >
            {settingUpMFA ? <LoadingSpinner /> : 'Set up 2FA'}
          </button>
        </div>
      )}
    </LayoutFullPage>
  )
}

const styles = {
  inviteCopyModal: {
    border: 'grow rounded-[10px] items-center mb-[25px] bg-[#F2F6F6] ',
    textdiv: 'flex py-2',
    text: 'grow h-12 bg-[#F2F6F6] outline-none mx-[20px] text-[#004F4E] font-bold pl-[5px] pr-[5px] w-[38vh]',
    btn: 'text-[14px] border-opacity-25',
    shareIcons: 'ml-1 flex items-center w-[20px] h-[20px]',
  },
}

export default MultiFacorAuthentication
