import { v4 as uuidv4 } from 'uuid'
import { CREATE_ALERT, REMOVE_ALERT } from './action.types'

export const createAlertAction =
  ({
    type = 'INFO',
    title = '',
    message = '',
    autoClose = false,
    autoCloseDuration = 5000,
  }) =>
  async (dispatch: Function) => {
    const id = uuidv4()

    dispatch({
      type: CREATE_ALERT,
      payload: {
        id,
        type,
        title,
        message,
      },
    })

    if (autoClose) {
      setTimeout(() => {
        dispatch(removeAlertAction(id))
      }, autoCloseDuration)
    }
  }

export const removeAlertAction = (id: string) => async (dispatch: Function) => {
  dispatch({
    type: REMOVE_ALERT,
    payload: {
      id,
    },
  })
}
