import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { relationships } from '../../../../constants/relationships'
import {
  activationCreateContactBy,
  activationCreateMinorAPI,
  activationRegisterAddChild,
  activationRegisterIncrementStep,
  activationRegisterRemoveChild,
  activationRegisterUpdateChild,
} from '../../../../redux/actions/activationActions'
import { RootReduxStateType } from '../../../../types'
import DateInput from '../../../Common/DateInput/DateInput'
import { LoadingSpinner } from '../../../Common/Loading'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import TextField from '../../../Common/TextField'
import Layout from '../Layout'
// @ts-ignore
import TrashIcon from '../../../../assets/trash.png'
// @ts-ignore
import WalletPlusIcon from '../../../../assets/WalletPlusIcon.png'
import dayjs from 'dayjs'
import { trackActivationAddChildEvent } from '../../../../amplitude/events'

const ActivationsChildStep: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activations = useSelector(
    (state: RootReduxStateType) => state.activation
  )
  const [selectState, setSelectState] = useState<{
    agreeToChildInfo: boolean
    contactByPhone: boolean
    contactByEmail: boolean
    recieveUpdates: boolean
  }>({
    agreeToChildInfo: false,
    contactByPhone: false,
    contactByEmail: false,
    recieveUpdates: false,
  })
  const [dateOfBirth] = useState<any>(null)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const handleNext = () => {
    if (activations.activationUserProfileID) {
      setLoading(true)
      activationCreateMinorAPI(
        activations.activationUserProfileID ?? 0,
        activations.childrenInfo
      )
        .then(() => {
          // Amplitude - Activation Add Child
          trackActivationAddChildEvent({
            children: activations.childrenInfo,
          })
          activationCreateContactBy(
            activations.activationUserProfileID ?? 0,
            selectState.contactByPhone,
            selectState.contactByEmail,
            selectState.recieveUpdates
          ).then(() => {
            setLoading(false)
            dispatch(activationRegisterIncrementStep())
            window.scrollTo({ top: 0, behavior: 'smooth' })
          })
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const handleAddChild = () => {
    dispatch(activationRegisterAddChild())
  }

  const handleRemoveChild = (id: string) => {
    dispatch(activationRegisterRemoveChild(id))
  }

  const handleOnChange = (e: any, id: string) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(activationRegisterUpdateChild({ id, key, value }))
  }

  useEffect(() => {
    activations.childrenInfo.map((info) => {
      if (
        info.firstName.length !== 0 &&
        info.lastName.length !== 0 &&
        info.dateOfBirth.length !== 0 &&
        info.relationship.length !== 0 &&
        selectState.agreeToChildInfo
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    })
  }, [selectState, activations.childrenInfo])

  useEffect(() => {
    if (!activations.activationUserProfileID) navigate('/activation')
  }, [])

  return (
    <Layout>
      <p className="text-[25px] font-semibold leading-9 px-5">
        Welcome, {activations.parentInfo.firstName}! Now tell us a little about
        the kids.
      </p>
      <p className="leading-8 py-5 font-thin px-5">
        Help us plan a great event. Let us know the names and birth dates of any
        children who will be attending.
      </p>

      <div className="w-full bg-[#EFF9F9] py-7 px-6 rounded-xl mt-6">
        <p className="text-[20px] font-semibold leading-9">
          Which children will be attending this event?
        </p>

        {activations.childrenInfo.map((info, index) => (
          <div
            className="w-full p-5 bg-white mt-6 shadow-sm rounded-xl"
            key={index}
          >
            <div className="flex justify-between items-center">
              <p className="text-[18px]">{index + 1} Child</p>
              {activations.childrenInfo.length > 1 && (
                <img
                  src={TrashIcon}
                  alt="TrashIcon"
                  className="h-[20px] cursor-pointer"
                  onClick={() => handleRemoveChild(info.id)}
                />
              )}
            </div>
            <div className="border-b-2 border-dashed border-[#C4E8E8] my-5"></div>

            <div className="grid grid-cols-2 gap-4 mt-3 md:grid-cols-4">
              <div>
                <p className="pb-3 font-thin text-[15px]">First Name</p>
                <TextField
                  type="text"
                  name="firstName"
                  label={'First Name'}
                  classes={'text-input mb-[16px]'}
                  value={activations.childrenInfo[index].firstName}
                  onChange={(e) => handleOnChange(e, info.id)}
                />
              </div>

              <div>
                <p className="pb-3 font-thin text-[15px]">Last Name</p>
                <TextField
                  type="text"
                  name="lastName"
                  label={'Last Name'}
                  classes={'text-input mb-[16px]'}
                  value={activations.childrenInfo[index].lastName}
                  onChange={(e) => handleOnChange(e, info.id)}
                />
              </div>

              <div>
                <p className="pb-3 font-thin text-[15px]">Date of Birth</p>

                <DateInput
                  label={dateOfBirth ? '' : 'Select'}
                  isActivation
                  dateValue={
                    activations.childrenInfo[index].dateOfBirth.length !== 0
                      ? activations.childrenInfo[index].dateOfBirth
                      : dateOfBirth
                  }
                  onChange={(newValue) => {
                    dispatch(
                      activationRegisterUpdateChild({
                        id: info.id,
                        key: 'dateOfBirth',
                        //value: newValue,
                        value: dayjs(newValue).format('YYYY-MM-DD'),
                      })
                    )
                  }}
                />
              </div>

              <div>
                <p className="pb-3 font-thin text-[15px]">Relationship</p>
                <div>
                  <select
                    name="relationship"
                    aria-label="State"
                    value={activations.childrenInfo[index].relationship}
                    onChange={(e) =>
                      dispatch(
                        activationRegisterUpdateChild({
                          id: info.id,
                          key: 'relationship',
                          value: e.target.value,
                        })
                      )
                    }
                    className="bg-[#E3F2F2] py-5 w-full px-3 rounded-lg outline-none"
                  >
                    {relationships.map((relationship) => (
                      <option
                        key={relationship.value}
                        value={relationship.value}
                      >
                        {relationship.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-1 sm:grid-cols-2">
              <div>
                <p className="pb-3 font-thin text-[15px]">
                  Phone number (optional)
                </p>
                <TelephoneNumber
                  value={activations.childrenInfo[index].phoneNumber}
                  onChange={(phoneNumber: string) =>
                    dispatch(
                      activationRegisterUpdateChild({
                        id: info.id,
                        key: 'phoneNumber',
                        value: phoneNumber,
                      })
                    )
                  }
                />
              </div>

              <div>
                <p className="pb-3 font-thin text-[15px]">Email (optional)</p>
                <TextField
                  type="text"
                  name="email"
                  label={'Email'}
                  classes={'text-input mb-[16px]'}
                  value={activations.childrenInfo[index].email}
                  onChange={(e) => handleOnChange(e, info.id)}
                />
              </div>
            </div>
          </div>
        ))}
        <div
          className="flex items-center justify-center shadow-sm mt-4 bg-white py-5 rounded-xl cursor-pointer"
          onClick={handleAddChild}
        >
          <img src={WalletPlusIcon} alt="WalletPlusIcon" className="w-[18px]" />
          <p className="text-[16px] font-normal ml-2 text-primary">Add child</p>
        </div>

        <div className="mb-[16px] flex items-center space-x-4 mt-5 font-thin">
          <input
            className="accent-primary focus:accent-primary"
            id="agreeToChildInfo"
            type="checkbox"
            value="agreeToChildInfo"
            name="agreements"
            checked={selectState.agreeToChildInfo}
            onChange={() =>
              setSelectState({
                ...selectState,
                agreeToChildInfo: !selectState.agreeToChildInfo,
              })
            }
          />
          <label htmlFor="agreeToChildInfo">
            I agree that the children’s information entered is correct
          </label>
        </div>
      </div>

      <div className="w-full bg-[#EFF9F9] py-7 px-14 rounded-xl mt-6">
        <p className="text-[20px] font-semibold leading-9">
          How do you want us to contact us in the future?
        </p>

        <div className="mb-[16px] flex items-center space-x-4 mt-5 font-thin">
          <input
            className="accent-primary focus:accent-primary"
            id="contactByPhone"
            type="checkbox"
            value="contactByPhone"
            name="agreements"
            checked={selectState.contactByPhone}
            onChange={() =>
              setSelectState({
                ...selectState,
                contactByPhone: !selectState.contactByPhone,
              })
            }
          />
          <label htmlFor="contactByPhone">
            By phone ({activations.parentInfo.phoneNumber})
          </label>
        </div>

        <div className="mb-[16px] flex items-center space-x-4 mt-3 font-thin">
          <input
            className="accent-primary focus:accent-primary"
            id="contactByEmail"
            type="checkbox"
            value="contactByEmail"
            name="agreements"
            checked={selectState.contactByEmail}
            onChange={() =>
              setSelectState({
                ...selectState,
                contactByEmail: !selectState.contactByEmail,
              })
            }
          />
          <label htmlFor="contactByEmail">
            By email ({activations.parentInfo.email})
          </label>
        </div>

        <div className="mb-[16px] flex items-center space-x-4 mt-3 font-thin">
          <input
            className="accent-primary focus:accent-primary"
            id="recieveUpdates"
            type="checkbox"
            value="recieveUpdates"
            name="agreements"
            checked={selectState.recieveUpdates}
            onChange={() =>
              setSelectState({
                ...selectState,
                recieveUpdates: !selectState.recieveUpdates,
              })
            }
          />
          <label htmlFor="recieveUpdates">
            I'd like to receive updates about Sootchy and future events
          </label>
        </div>
      </div>

      <div className="w-[90%] m-auto sm:w-full mt-5">
        <button
          className="w-full text-[16px] font-normal bg-primary text-white py-5"
          onClick={handleNext}
          disabled={disabled}
        >
          {loading ? <LoadingSpinner /> : 'Register'}
        </button>
      </div>
    </Layout>
  )
}

export default ActivationsChildStep
