import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateUser,
  alpacaStepperUpdateVideo,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { getUser, updateUserAccount } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepTwo: React.FC = () => {
  const dispatch = useDispatch()
  const alpaca = useSelector((state: RootReduxStateType) => state.alpaca)
  const user = useSelector((state: RootReduxStateType) => state.user)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (alpaca.user.firstName && alpaca.user.lastName) setDisabled(false)
    else setDisabled(true)
  }, [alpaca.user.firstName, alpaca.user.lastName])

  useEffect(() => {
    dispatch(alpacaStepperUpdateVideo(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    dispatch(alpacaStepperUpdateUser(key, value === '' ? null : value))
  }

  const handleNext = () => {
    setDisabled(true)
    if (alpaca.user.firstName && alpaca.user.lastName) {
      updateUserAccount(
        user.id,
        alpaca.user.firstName ?? '',
        alpaca.user.middleName ?? '',
        alpaca.user.lastName ?? ''
      ).then((res) => {
        dispatch(increamentAlpacaStepperStep())
        setDisabled(false)
      })
    }
  }

  useEffect(() => {
    setLoading(true)

    getUser(user.id).then((res) => {
      if (res && res.data) {
        dispatch(
          alpacaStepperUpdateUser(
            'firstName',
            res.data.firstName === '' ? null : res.data.firstName
          )
        )
        dispatch(
          alpacaStepperUpdateUser(
            'lastName',
            res.data.lastName === '' ? null : res.data.lastName
          )
        )
        dispatch(
          alpacaStepperUpdateUser(
            'middleName',
            res.data.middleName === '' ? null : res.data.middleName
          )
        )
        setLoading(false)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="font-semibold text-[17px] py-2">Legal Name</p>
        <p className="text-[15px]">
          Check if your name is correct, if not, change it. You must only use
          your legal name.
        </p>

        <div className="py-4">
          {loading ? (
            <Skeleton height={90} />
          ) : (
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="firstName"
                aria-label="firstName"
                value={alpaca.user.firstName ?? ''}
              />
              <label>First name</label>
            </div>
          )}

          {loading ? (
            <Skeleton height={90} />
          ) : (
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="middleName"
                aria-label="middleName"
                value={alpaca.user.middleName ?? ''}
              />
              <label>Middle Name</label>
            </div>
          )}

          {loading ? (
            <Skeleton height={90} />
          ) : (
            <div className={`form-group form-group--filled py-2`}>
              <input
                onChange={handleOnChange}
                name="lastName"
                aria-label="lastName"
                value={alpaca.user.lastName ?? ''}
              />
              <label>Last Name</label>
            </div>
          )}
        </div>

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>
      <SetupBottomAlpaca disabled={disabled} handleNext={handleNext} />
    </div>
  )
}

export default AlpacaStepTwo
