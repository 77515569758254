import React from 'react'
import { PuffLoader, PulseLoader } from 'react-spinners'

export const LoadingSpinner = ({ color = '#fff', size = 8 }) => {
  return <PulseLoader color={color} loading={true} size={size} margin={2} />
}

export const SliderLoadingSpinner = () => {
  // @ts-ignore
  return <PuffLoader color="#36D7B7" loading={true} size={60} margin={2} />
}
