import React from 'react'

const NewFooter: React.FC<{}> = () => {
  return (
    <div className="hidden lg:flex flex-col justify-between w-full h-[140px] bg-[#C4E8E8] items-center p-10 text-primaryDark lg:h-16 lg:flex-row">
      <div className="flex">
        <p className="">© Sootchy, Inc. All Rights Reserved.</p>
        <div className="ml-12">
          <a
            className="px-4 underline underline-offset-4 text-primaryDark"
            href="/help/assitance"
          >
            Help
          </a>
          <a
            className="px-4 underline underline-offset-4 text-primaryDark"
            href="/legal"
          >
            Legal
          </a>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default NewFooter
