import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decreamentMy529Step } from '../../../redux/actions/my529Actions'
import { RootReduxStateType } from '../../../types'
import { MAX_MY529_STEP, MIN_MY529_STEP } from '../../../types/my529'
import { LoadingSpinner } from '../../Common/Loading'
// @ts-ignore
import RightArrowIcon from '../../../assets/right-arrow.png'

type PropsType = {
  handleNext: () => void
  handleCancel?: () => void
  disabled?: boolean
  handleNextBtn?: string
  loading?: boolean
}

const My529SetupBottom: React.FC<PropsType> = ({
  handleNext,
  handleCancel,
  disabled = false,
  handleNextBtn,
  loading = false,
}) => {
  const dispatch = useDispatch()
  const my529 = useSelector((state: RootReduxStateType) => state.my529)

  const defaultHandleCancel = () => {
    dispatch(decreamentMy529Step())
  }

  return (
    <div className="fixed bottom-0 flex justify-between py-4 border-t-2 border-dashed border-[#C4E8E8] self-center bg-white w-[92%] sm:relative sm:w-full">
      <button
        className="w-[70%] mx-1 py-4"
        onClick={handleCancel ? handleCancel : defaultHandleCancel}
      >
        {my529.step === 1 ? 'cancel' : 'Previous'}
      </button>
      <button
        className="w-[100%] mx-1 py-4 border-none bg-primaryDark text-white flex items-center justify-center"
        onClick={handleNext}
        disabled={loading}
      >
        {loading ? (
          <LoadingSpinner color="#fff" />
        ) : handleNextBtn ? (
          handleNextBtn
        ) : my529.step === MAX_MY529_STEP ? (
          'Confirm option'
        ) : my529.step === MIN_MY529_STEP ? (
          'Continue'
        ) : (
          'Correct, Next'
        )}
        {my529.step !== MAX_MY529_STEP && (
          <img src={RightArrowIcon} alt="right-icon" className="ml-2" />
        )}
      </button>
    </div>
  )
}

export default My529SetupBottom
