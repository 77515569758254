import React from 'react'
import Navigation from '../../Common/Navigation'

const SingleTransactionLoader = () => {
  return (
    <Navigation>
      <div className="w-[90%] md:w-[570px] m-auto py-6">
        <div>
          <div
            className={`flex flex-col animate-pulse h-[100px] bg-slate-200  py-[17px] items-center justify center rounded-[10px]`}
          ></div>
        </div>

        <div className="mt-[32px]">
          <div className="flex flex-row py-[20px] border-b-[2.5px] border-slate-200 animate-pulse border-dashed">
            <div className="basis-1/2">
              <div className="h-2 bg-slate-200 rounded w-[60%]"></div>
            </div>
            <div className="basis-1/2 flex space-x-2 items-center">
              <div className="h-2 bg-slate-200 rounded w-full"></div>
            </div>
          </div>

          <div className="flex flex-row py-[20px] border-b-[2.5px] border-slate-200 animate-pulse border-dashed">
            <div className="basis-1/2">
              <div className="h-2 bg-slate-200 rounded w-[70%]"></div>
            </div>
            <div className="basis-1/2 flex space-x-2 items-center">
              <div className="h-2 bg-slate-200 rounded w-full"></div>
            </div>
          </div>

          <div className="flex flex-row py-[20px] border-b-[2.5px] border-slate-200 animate-pulse border-dashed">
            <div className="basis-1/2">
              <div className="h-2 bg-slate-200 rounded w-[40%]"></div>
            </div>
            <div className="basis-1/2 flex space-x-2 items-center">
              <div className="h-2 bg-slate-200 rounded w-full"></div>
            </div>
          </div>

          <div className="flex flex-row py-[20px] animate-pulse ">
            <div className="basis-1/2">
              <div className="h-2 bg-slate-200 rounded w-[70%]"></div>
            </div>
            <div className="basis-1/2 flex space-x-2 items-center">
              <div className="h-2 bg-slate-200 rounded w-full"></div>
            </div>
          </div>
        </div>

        <div className="w-[60%] mx-auto">
          <div className="w-full py-6 rounded-[10px] mb-4 mt-[36px] bg-slate-200 animate-pulse"></div>
        </div>
      </div>
    </Navigation>
  )
}

export default SingleTransactionLoader
