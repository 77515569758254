import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { changeUserRegistrationStepperData } from '../../../../redux/actions/userRegistrationStepperActions'
import {
  formatPhoneNumberToSave,
  validatePhoneNumber,
} from '../../../../utilities/generalUtilities'
import { LoadingSpinner } from '../../../Common/Loading'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import TextField from '../../../Common/TextField'

type PropTypes = {
  styles: {
    button: string
    input: string
    backButton: string
  }
  stepData: any
  handleDataChange: (e: any) => any
  increamentStep: () => void
  decreamentStep: () => void
}

const StepTwo = ({
  styles,
  increamentStep,
  decreamentStep,
  stepData,
  handleDataChange,
}: PropTypes) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (
      stepData.firstName.length !== 0 &&
      stepData.lastName.length !== 0 &&
      validatePhoneNumber(formatPhoneNumberToSave(stepData.phoneNumber))
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [stepData])

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex border-b-2 border-primaryLight border-dashed mb-[24px]">
        <p className="text-primary text-[11px] my-[12px] leading-[13.76px] font-semibold ">
          STEP 2 OUT OF 3
        </p>
        <div className="flex items-center space-x-2 ml-[16px]">
          <div className="bg-primaryLight border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
          <div className="bg-primaryLight border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
          <div className="border-2 rounded-full border-primaryLight w-[10px] h-[10px]"></div>
        </div>
      </div>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <p className="signup-description">
          Awesome! Now a little about yourself.
        </p>

        <TextField
          type="text"
          label={`${t('Base.FirstName')} *`}
          name="firstName"
          classes={styles.input}
          value={stepData.firstName}
          onChange={handleDataChange}
          autoComplete="off"
          data-test="signup-firstname-input"
        />

        <TextField
          type="text"
          label={`${t('Base.LastName')} *`}
          name="lastName"
          classes={styles.input}
          value={stepData.lastName}
          onChange={handleDataChange}
          autoComplete="off"
          data-test="signup-lastname-input"
        />

        <div>
          <TelephoneNumber
            value={stepData.phoneNumber ? stepData.phoneNumber : ''}
            onChange={(phoneNumber) =>
              handleDataChange({
                target: { name: 'phoneNumber', value: phoneNumber },
              })
            }
          />
        </div>

        <div className="w-full mt-6">
          <p className="font-semibold leading-9">
            How would you like us to contact you?
          </p>

          <div className="mb-[16px] flex items-center space-x-4 mt-5 font-thin text-[15px]">
            <input
              className="accent-primary focus:accent-primary"
              id="contactByPhone"
              type="checkbox"
              value="contactByPhone"
              name="agreements"
              checked={stepData.contactByPhone}
              onChange={() =>
                dispatch(
                  changeUserRegistrationStepperData(
                    'contactByPhone',
                    !stepData.contactByPhone
                  )
                )
              }
            />
            <label htmlFor="contactByPhone">By phone</label>
          </div>

          <div className="mb-[16px] flex items-center space-x-4 mt-3 font-thin text-[15px]">
            <input
              className="accent-primary focus:accent-primary"
              id="contactByEmail"
              type="checkbox"
              value="contactByEmail"
              name="agreements"
              checked={stepData.contactByEmail}
              onChange={() =>
                dispatch(
                  changeUserRegistrationStepperData(
                    'contactByEmail',
                    !stepData.contactByEmail
                  )
                )
              }
            />
            <label htmlFor="contactByEmail">By email</label>
          </div>

          <div className="mb-[16px] flex items-center space-x-4 mt-3 font-thin text-[15px]">
            <input
              className="accent-primary focus:accent-primary"
              id="recieveUpdates"
              type="checkbox"
              value="recieveUpdates"
              name="agreements"
              checked={stepData.recieveFutureUpdates}
              onChange={() =>
                dispatch(
                  changeUserRegistrationStepperData(
                    'recieveFutureUpdates',
                    !stepData.recieveFutureUpdates
                  )
                )
              }
            />
            <label htmlFor="recieveUpdates">
              I'd like to receive updates about Sootchy and future events
            </label>
          </div>
        </div>

        <div className="flex space-x-2 mt-[50px] pt-[24px] border-t-2 border-primaryLight border-dashed">
          <button onClick={decreamentStep} className={styles.backButton}>
            {t('Base.Back')}
          </button>
          <button
            onClick={() => increamentStep()}
            className={styles.button}
            disabled={!isValid}
            type="button"
          >
            {loading ? <LoadingSpinner /> : t('Base.Next')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default StepTwo
