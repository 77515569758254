import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  increamentMy529Step,
  setupMy529Update,
} from '../../../../redux/actions/my529Actions'
import SafeAreaView from '../../../Common/SafeAreaView'
import My529SetupBottom from '../My529SetupBottom'

const AgreementStep = () => {
  const dispatch = useDispatch()
  const [termsAndConditions, setTermsAndConditions] = useState({
    privacyPolicy: false,
    clientRelationShip: false,
  })

  const handleNext = () => {
    dispatch(increamentMy529Step())
  }

  useEffect(() => {
    dispatch(setupMy529Update('video', null))
  }, [])

  return (
    <div>
      <SafeAreaView>
        <p className="text-[18px] font-semibold">Terms and Conditions</p>

        <div className="checkbox mb-[16px] text-[15px] mt-5">
          <input
            type="checkbox"
            checked={termsAndConditions.privacyPolicy}
            onChange={() => {
              setTermsAndConditions({
                ...termsAndConditions,
                privacyPolicy: !termsAndConditions.privacyPolicy,
              })
            }}
            id="accountAgreementDisclosure"
            data-test="signup-terms-checkbox"
          />
          <label htmlFor="accountAgreementDisclosure">
            I agree to both the{' '}
            <a
              href="https://www.sootchysecurities.com/legal-documents/sootchy-privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and
            <a
              href="https://www.sootchysecurities.com/legal-documents/sootchy-terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{' '}
          </label>
        </div>

        <div className="checkbox mb-[16px] text-[15px] mt-5">
          <input
            type="checkbox"
            checked={termsAndConditions.clientRelationShip}
            onChange={() => {
              setTermsAndConditions({
                ...termsAndConditions,
                clientRelationShip: !termsAndConditions.clientRelationShip,
              })
            }}
            id="accountAgreementClient"
            data-test="signup-terms-checkbox"
          />
          <label htmlFor="accountAgreementClient">
            I acknowledge
            <a
              href="https://www.sootchysecurities.com/legal-documents/crs"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Client Relationship Summary
            </a>{' '}
            and
            <a
              href="https://adviserinfo.sec.gov/firm/summary/307621"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Form ADV Parts 2A and 2B,
            </a>{' '}
            and I agree to the
            <a
              href="https://www.sootchysecurities.com/legal-documents/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Cookie Policy
            </a>{' '}
          </label>
        </div>
      </SafeAreaView>

      <My529SetupBottom
        handleNext={handleNext}
        disabled={
          !termsAndConditions.privacyPolicy ||
          !termsAndConditions.clientRelationShip
        }
      />
    </div>
  )
}

export default AgreementStep
