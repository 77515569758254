import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { relationships } from '../../../../constants/relationships'
import { states } from '../../../../constants/states'
import { addChildMy529API } from '../../../../redux/actions/my529Actions'
import { getUser } from '../../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../../types'
import { My529MinorAPIType } from '../../../../types/my529'
import { UserAPIType } from '../../../../types/user'
import {
  formatSSN,
  validateEmail,
  validatePhoneNumber,
  validateZipCode,
  validdateSsn,
} from '../../../../utilities/generalUtilities'
import DateInput from '../../../Common/DateInput/DateInput'
import { LoadingSpinner } from '../../../Common/Loading'
import SafeAreaView from '../../../Common/SafeAreaView'
import TelephoneNumber from '../../../Common/TelephoneNumber'
import TextField from '../../../Common/TextField'
// @ts-ignore
import CloseIcon from '../../../../assets/closeIcon.png'

type PropsType = {
  onClose: () => void
  id: string | null
  isNotStepper?: boolean
  childInfo: My529MinorAPIType[]
}

type AddressType = {
  address1: null | string
  address2: null | string
  cityOrTown: null | string
  zipCode: null | string
  state: null | string
}

const defaultAddress: AddressType = {
  address1: null,
  address2: null,
  cityOrTown: null,
  zipCode: null,
  state: 'AL',
}

const My529AddChildModal: React.FC<PropsType> = ({
  onClose,
  id,
  childInfo,
  isNotStepper,
}) => {
  const user = useSelector((state: RootReduxStateType) => state.user)
  const my529 = useSelector((state: RootReduxStateType) => state.my529)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState<any>(null)
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [ssn, setSsn] = useState('')
  const [relationship, setRelationship] = useState<string>('Child')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [_, setIsSilaAccountCreated] = useState<boolean>(true)
  const [__, setLoadedUser] = useState<UserAPIType | null>(null)
  const [intialLoading, setInitialLoading] = useState<boolean>(true)
  const [isMinorDelegated, setIsMinorDelegated] = useState<boolean>(false)
  const [minorAccountID, setMinorAccountID] = useState<number | null>(null)
  const [isCurrentMinorDelegated, setIsCurrentMinorDelegated] =
    useState<boolean>(false)
  const [checkBoxState, setCheckBoxState] = useState({
    addressSameAsParent: false,
    minorDelegated: false,
  })
  const [address, setAddress] = useState<AddressType>(defaultAddress)

  const handleIsMinorDelegated = () => {
    if (childInfo.length > 0) {
      const filteredChildren = childInfo.filter(
        (info) => info.isMy529DelegatedToMySelf === true
      )[0]

      setIsMinorDelegated(filteredChildren ? true : false)
    }
  }

  const getIsCurrentMinorDelegated = (minorAccountID: number) => {
    if (childInfo.length > 0) {
      const filteredChildren = childInfo.filter(
        (info) =>
          info.isMy529DelegatedToMySelf === true &&
          info.minorAccountID === minorAccountID
      )[0]

      setIsCurrentMinorDelegated(filteredChildren ? true : false)
    }
  }

  const handleGetUser = async () => {
    const response = await getUser(user.id)

    if (response.status === 200) {
      setLoadedUser(response.data)
      setIsSilaAccountCreated(response.data.silaUserAccountID ? true : false)
      handleIsMinorDelegated()
      setInitialLoading(false)
    } else setInitialLoading(false)
  }

  const handleAddChild = () => {
    setLoading(true)

    addChildMy529API({
      UserAccountID: parseInt(user.id),
      MinorAccountID: null,
      FirstName: firstName,
      MiddleName: '',
      LastName: lastName,
      EmailAddress: email.length > 0 ? email : null,
      DateOfBirth: !dateOfBirth
        ? null
        : dayjs(dateOfBirth).format('YYYY-MM-DD'),
      Relationship: relationship,
      TelephoneNumber:
        phoneNumber.length > 0 && phoneNumber !== '+1' ? phoneNumber : null,
      Identifier: checkBoxState.minorDelegated ? null : ssn,
      IsMy529AccountForMySelf:
        my529.accountBeneficiary === 'MY_SELF' ? true : false,
      IsMy529DelegatedToMySelf: checkBoxState.minorDelegated,
      IsAddressSameAsParent: checkBoxState.addressSameAsParent,
      Address: checkBoxState.minorDelegated ? defaultAddress : address,
    })
      .then(() => {
        setLoading(false)
        onClose()
      })
      .catch(() => {
        setLoading(false)
        alert('Error adding child')
      })
  }

  const handleUpdateChild = () => {
    if (id && minorAccountID) {
      setLoading(true)
      // Add Child API is also used for updating minor account
      addChildMy529API({
        UserAccountID: parseInt(user.id),
        MinorAccountID: minorAccountID,
        FirstName: firstName,
        LastName: lastName,
        MiddleName: '',
        Relationship: relationship,
        EmailAddress: email.length > 0 ? email : null,
        DateOfBirth: !dateOfBirth
          ? null
          : dayjs(dateOfBirth).format('YYYY-MM-DD'),
        TelephoneNumber:
          phoneNumber.length > 0 && phoneNumber !== '+1' ? phoneNumber : null,
        Identifier: checkBoxState.minorDelegated || ssn[0] === 'X' ? null : ssn,
        IsMy529AccountForMySelf:
          my529.accountBeneficiary === 'MY_SELF' ? true : false,
        IsMy529DelegatedToMySelf: checkBoxState.minorDelegated,
        IsAddressSameAsParent: checkBoxState.addressSameAsParent,
        Address: checkBoxState.minorDelegated ? defaultAddress : address,
      })
        .then(() => {
          setLoading(false)
          onClose()
        })
        .catch(() => {
          setLoading(false)
          alert('Unable to update account')
        })
    }
  }

  const populateData = () => {
    const data = childInfo.find((child: any) => child.minorAccountID === id)

    if (data) {
      getIsCurrentMinorDelegated(data.minorAccountID)
      setFirstName(data.firstName ?? '')
      setLastName(data.lastName ?? '')
      setDateOfBirth(data.dateOfBirth)
      setEmail(data.emailAddress ?? '')
      setPhoneNumber(data.telephoneNumber ?? '')
      setSsn(
        data.identifier
          ? data.identifier === 'XXX-XX-XXXX'
            ? ''
            : data.identifier
          : ''
      )
      setMinorAccountID(data.minorAccountID)
      setCheckBoxState({
        minorDelegated: data.isMy529DelegatedToMySelf,
        addressSameAsParent: data.isAddressSameAsParent,
      })
      setRelationship(data.relationship ?? 'Child')
      if (!data.isAddressSameAsParent)
        setAddress({
          address1: data.streetAddress1,
          address2: data.streetAddress2,
          cityOrTown: data.city,
          zipCode: data.zipCode,
          state: data.state ?? 'AL',
        })

      if (
        !data.isMy529DelegatedToMySelf &&
        !data.streetAddress1 &&
        !data.streetAddress2 &&
        !data.city &&
        !data.zipCode &&
        !data.state
      )
        setCheckBoxState({
          ...checkBoxState,
          addressSameAsParent: true,
        })
    }
  }

  const handleOnChange = (e: any) => {
    const key = e.target.name
    const value = e.target.value

    setAddress({
      ...address,
      [key]: value === '' ? null : value,
    })
  }

  const handeValidations = () => {
    if (!checkBoxState.minorDelegated) {
      if (
        firstName.length === 0 ||
        lastName.length === 0 ||
        !dateOfBirth ||
        (email.length > 0 && !validateEmail(email)) ||
        (ssn[0] !== 'X' && !validdateSsn(ssn)) ||
        (phoneNumber.length > 0 &&
          phoneNumber !== '+1' &&
          !validatePhoneNumber(phoneNumber)) ||
        (!checkBoxState.addressSameAsParent
          ? (address.address1 === null || address.address1.length < 2
              ? true
              : false) ||
            !address.cityOrTown ||
            !validateZipCode(address.zipCode ?? '') ||
            !address.state
          : false)
      )
        setDisabled(true)
      else setDisabled(false)
    } else {
      if (
        firstName.length === 0 ||
        lastName.length === 0 ||
        relationship.length === 0 ||
        (email.length > 0 && !validateEmail(email)) ||
        (phoneNumber.length > 0 &&
          phoneNumber !== '+1' &&
          !validatePhoneNumber(phoneNumber))
      )
        setDisabled(true)
      else setDisabled(false)
    }
  }

  const handleMinorDelegated = async () => {
    setCheckBoxState({
      addressSameAsParent: true,
      minorDelegated: !checkBoxState.minorDelegated,
    })
  }

  useEffect(() => {
    handeValidations()
  }, [
    firstName,
    lastName,
    dateOfBirth,
    email,
    phoneNumber,
    ssn,
    address,
    checkBoxState,
    isCurrentMinorDelegated,
  ])

  useEffect(() => {
    handleGetUser()
    if (id) {
      populateData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (checkBoxState.minorDelegated) {
      setSsn('')
    }
  }, [checkBoxState.minorDelegated, isCurrentMinorDelegated])

  return (
    <>
      <div className="fixed h-full w-screen z-50 top-0 left-0">
        <div className="absolute bg-black opacity-80 h-screen w-screen"></div>

        <div
          className="absolute bg-white w-[88%] rounded-xl py-6 px-6 sm:w-[400px]"
          style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
        >
          {intialLoading ? (
            <div className="flex justify-center items-center">
              <LoadingSpinner color="#004F4E" />
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center border-[#C4E8E8] border-b-2 border-dashed pb-4">
                <p className="font-semibold text-primaryDark text-[18px]">
                  {id ? 'Update Child ' : 'Add Child '}{' '}
                  {!isNotStepper && '/ Beneficiary'}
                </p>

                <div className="flex items-center">
                  <img
                    src={CloseIcon}
                    alt="close icon"
                    className="w-[13px] h-[13px] cursor-pointer"
                    onClick={() => {
                      onClose()
                    }}
                  />
                </div>
              </div>

              <SafeAreaView>
                {!isNotStepper && (
                  <>
                    <p className="py-4">
                      If you don’t have these details at hand, you can
                      temporarily delegate yourself as the account owner and
                      beneficiary. You will be able to change it later.
                    </p>

                    <div className="checkbox text-[15px]">
                      <input
                        type="checkbox"
                        checked={checkBoxState.minorDelegated}
                        onChange={handleMinorDelegated}
                        id="minorDelegated"
                        data-test="signup-terms-checkbox"
                        disabled={
                          isCurrentMinorDelegated
                            ? false
                            : isMinorDelegated
                            ? true
                            : false
                        }
                        className="cursor-pointer"
                      />

                      <label
                        className="text-primaryDark"
                        htmlFor="minorDelegated"
                      >
                        Check this box to delegate yourself as beneficiary
                        temporarily{' '}
                        {isMinorDelegated &&
                          !isCurrentMinorDelegated &&
                          '(Please know that you can only delegate one beneficiary)'}
                      </label>
                    </div>
                  </>
                )}

                <div className="grid grid-cols-1 gap-4 py-4">
                  <TextField
                    type="text"
                    name="firstName"
                    label={'Child first name*'}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    passwordField={undefined}
                    showPasswordIconStatus={undefined}
                    changeIcon={() => undefined}
                  />

                  <TextField
                    type="text"
                    name="lastName"
                    label={'Child last name*'}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    passwordField={undefined}
                    showPasswordIconStatus={undefined}
                    changeIcon={() => undefined}
                  />

                  <div>
                    <div>
                      <select
                        name="relationship"
                        aria-label="State"
                        value={relationship}
                        onChange={(e) => setRelationship(e.target.value)}
                        className="bg-[#E3F2F2] py-5 w-full px-3 rounded-lg outline-none"
                      >
                        {relationships.map((relationship) => (
                          <option
                            key={relationship.value}
                            value={relationship.value}
                          >
                            {relationship.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <DateInput
                      label={
                        dateOfBirth
                          ? ''
                          : checkBoxState.minorDelegated
                          ? 'Date of birth'
                          : 'Date of birth*'
                      }
                      dateValue={dateOfBirth}
                      onChange={(newValue) => {
                        setDateOfBirth(newValue)
                      }}
                    />
                  </div>

                  <TextField
                    type="text"
                    name="email"
                    label={'Email (optional)'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    passwordField={undefined}
                    showPasswordIconStatus={undefined}
                    changeIcon={() => undefined}
                  />

                  <div>
                    <p className={`text-[15px] py-1 text-primaryDark`}>
                      Phone number (optional)
                    </p>
                    <TelephoneNumber
                      onMount={(phoneNumber: string) => {
                        setPhoneNumber(
                          phoneNumber === ''
                            ? ''
                            : phoneNumber.length === 1 && phoneNumber[0] === '1'
                            ? '+1'
                            : phoneNumber
                        )
                      }}
                      value={phoneNumber}
                      onChange={(phoneNumber: string) => {
                        setPhoneNumber(
                          phoneNumber === ''
                            ? ''
                            : phoneNumber.length === 1 && phoneNumber[0] === '1'
                            ? '+1'
                            : phoneNumber
                        )
                      }}
                    />
                  </div>

                  <TextField
                    type="text"
                    name="ssn"
                    label={'Social Security number'}
                    value={checkBoxState.minorDelegated ? '' : ssn}
                    onChange={(e) => setSsn(formatSSN(e.target.value))}
                    passwordField={undefined}
                    showPasswordIconStatus={undefined}
                    changeIcon={() => undefined}
                    disabled={checkBoxState.minorDelegated}
                  />
                </div>

                <div className="checkbox text-[15px] mb-4 mt-1">
                  <input
                    type="checkbox"
                    checked={checkBoxState.addressSameAsParent}
                    onChange={() => {
                      setCheckBoxState({
                        ...checkBoxState,
                        addressSameAsParent: !checkBoxState.addressSameAsParent,
                      })
                    }}
                    disabled={checkBoxState.minorDelegated}
                    id="addressSameAsParent"
                    data-test="signup-terms-checkbox"
                  />
                  <label htmlFor="addressSameAsParent">
                    Address same as account owner
                  </label>
                </div>

                {!checkBoxState.addressSameAsParent && (
                  <div>
                    <div className={`form-group form-group--filled py-2`}>
                      <input
                        onChange={handleOnChange}
                        name="address1"
                        aria-label="address1"
                        value={address.address1 ? address.address1 : undefined}
                      />
                      <label>Address line 1</label>
                    </div>

                    <div className={`form-group form-group--filled py-2`}>
                      <input
                        onChange={handleOnChange}
                        aria-label="address2"
                        name="address2"
                        value={address.address2 ? address.address2 : undefined}
                      />
                      <label>Address line 2</label>
                    </div>

                    <div className="flex">
                      <div
                        className={`form-group form-group--filled py-2 px-1`}
                      >
                        <input
                          onChange={handleOnChange}
                          aria-label="cityOrTown"
                          name="cityOrTown"
                          value={
                            address.cityOrTown ? address.cityOrTown : undefined
                          }
                        />
                        <label>City or town</label>
                      </div>
                      <div
                        className={`form-group form-group--filled py-2 px-1`}
                      >
                        <input
                          onChange={handleOnChange}
                          aria-label="zipCode"
                          name="zipCode"
                          value={address.zipCode ? address.zipCode : undefined}
                        />
                        <label>ZIP</label>
                      </div>
                    </div>

                    <div>
                      <select
                        name="state"
                        aria-label="State"
                        value={address.state ? address.state : undefined}
                        onChange={handleOnChange}
                        className="bg-[#E3F2F2] py-5 my-4  w-full px-3 rounded-lg outline-none"
                      >
                        {states.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                <button
                  className={`border-none text-white bg-primary h-[50px] flex items-center w-full justify-center`}
                  onClick={id ? handleUpdateChild : handleAddChild}
                  disabled={disabled}
                >
                  {loading ? (
                    <div className="flex justify-center items-center h-full">
                      <LoadingSpinner color="#fff" />
                    </div>
                  ) : id ? (
                    'Update'
                  ) : (
                    'Add'
                  )}
                </button>
              </SafeAreaView>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default My529AddChildModal
