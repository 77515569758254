import { ReduxActionType } from '../../types'
import { AlpacaStepperType } from '../../types/alpaca'
import {
  ALPACA_STEPPER_AFFILIATIONS,
  ALPACA_STEPPER_DECREMENT_STEP,
  ALPACA_STEPPER_INCREAMENT_STEP,
  ALPACA_STEPPER_RESET,
  ALPACA_STEPPER_UPDATE_ASSISTENCE_NEEDED,
  ALPACA_STEPPER_UPDATE_BENEFICIARY,
  ALPACA_STEPPER_UPDATE_EMPLOYER,
  ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS,
  ALPACA_STEPPER_UPDATE_EMPLOYMENT_STATUS,
  ALPACA_STEPPER_UPDATE_FUNDING,
  ALPACA_STEPPER_UPDATE_PROGRESS_STATUS,
  ALPACA_STEPPER_UPDATE_STEP,
  ALPACA_STEPPER_UPDATE_TAX_AND_CITIZENSHIP,
  ALPACA_STEPPER_UPDATE_USER,
  ALPACA_STEPPER_UPDATE_VIDEO,
} from '../actions/action.types'

// ** Make sure to change AlpacaStepperType for additional modifications **
const initialState: AlpacaStepperType = {
  step: 1,
  video: null,
  assistenceNeeded: false,
  progressStatus: null,
  user: {
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    address1: null,
    address2: null,
    cityOrTown: null,
    zipCode: null,
    state: null,
    dateOfBirth: null,
    socialSecurity: null,
  },
  taxAndIncome: {
    isTaxResidenceChosen: false,
    isCitizenshipChosen: false,
    taxPaymentStatus: null,
    residencyStatus: null,
    birthCountry: null,
    citizenshipCountry: null,
    taxResidenceCountry: null,
    birthCountryCode: null,
    citizenshipCountryCode: null,
    taxResidenceCountryCode: null,
    visaType: null,
    visaExpirationDate: null,
    dateOfDeparture: null,
    incomePerYear: null,
    liquidAssets: null,
  },
  beneficiary: {
    firstName: null,
    lastName: null,
    email: null,
    socialSecurity: null,
    citizenshipCountry: null,
    taxResidenceCountry: null,
    citizenshipCountryCode: null,
    taxResidenceCountryCode: null,
    state: null,
  },
  funding: {
    source: null,
  },
  employment: {
    status: null,
    employer: {
      employerName: null,
      employerJobTitle: null,
      employerAddress: {
        address1: null,
        address2: null,
        cityOrTown: null,
        zipCode: null,
        state: null,
      },
    },
  },
  affiliations: {
    isAffiliatedWithExchangeOrFinra: null,
    isControlPerson: null,
    isPoliticallyExposed: null,
    isImmediateFamilyExposed: null,
  },
}

export const alpacaStepperReducer = (
  state: AlpacaStepperType = initialState,
  action: ReduxActionType<AlpacaStepperType>
) => {
  switch (action.type) {
    case ALPACA_STEPPER_INCREAMENT_STEP:
      return {
        ...state,
        step: state.step < 17 ? state.step + 1 : state.step,
      }

    case ALPACA_STEPPER_DECREMENT_STEP:
      return {
        ...state,
        step: state.step > 1 ? state.step - 1 : state.step,
      }

    case ALPACA_STEPPER_UPDATE_STEP:
      return {
        ...state,
        step: action.payload,
      }

    case ALPACA_STEPPER_UPDATE_USER: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        user: {
          ...state.user,
          [key]: value,
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_TAX_AND_CITIZENSHIP: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        taxAndIncome: {
          ...state.taxAndIncome,
          [key]: value,
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_VIDEO:
      return {
        ...state,
        video: action.payload,
      }

    case ALPACA_STEPPER_UPDATE_ASSISTENCE_NEEDED:
      return {
        ...state,
        assistenceNeeded: action.payload,
      }

    case ALPACA_STEPPER_UPDATE_FUNDING: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        funding: {
          ...state.funding,
          [key]: value,
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_EMPLOYMENT_STATUS: {
      return {
        ...state,
        employment: {
          ...state.employment,
          status: action.payload,
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_EMPLOYER: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        employment: {
          ...state.employment,
          employer: {
            ...state.employment.employer,
            [key]: value,
          },
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_EMPLOYER_ADDRESS: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        employment: {
          ...state.employment,
          employer: {
            ...state.employment.employer,
            employerAddress: {
              ...state.employment.employer.employerAddress,
              [key]: value,
            },
          },
        },
      }
    }

    case ALPACA_STEPPER_AFFILIATIONS: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        affiliations: {
          ...state.affiliations,
          [key]: value,
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_BENEFICIARY: {
      const payload: any = action.payload
      const { key, value } = payload

      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          [key]: value,
        },
      }
    }

    case ALPACA_STEPPER_UPDATE_PROGRESS_STATUS:
      return {
        ...state,
        progressStatus: action.payload,
      }

    case ALPACA_STEPPER_RESET:
      return initialState

    default:
      return state
  }
}
