import React, { useEffect, useState } from 'react'
// @ts-ignore
import BoldBottomIcon from '../../../assets/BoldBottomIcon.png'

type Proptypes = {
  title: string
  list: any
  value: string
  handleOnchange: (data: any) => void
}

const DropdownSelector = ({
  title,
  list,
  value,
  handleOnchange,
}: Proptypes) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsListOpen(false)
    console.log('Value in dropdown is', value)
  }, [value])

  return (
    <div className="my-2">
      <div
        id="dd-header"
        className="py-1 px-3 rounded-lg w-full bg-[#E3F2F2] flex justify-between"
        onClick={() => setIsListOpen(!isListOpen)}
      >
        <div
          onClick={() => setIsListOpen(!isListOpen)}
          className="w-full flex justify-between font-normal"
        >
          <div className="flex items-center">
            <div>
              <p className="text-[11px] px-1 text-primary">{title}</p>
              <input
                className="w-full outline-none bg-[#E3F2F2] pl-1"
                type="text"
                value={value}
                //placeholder={title}
              />
            </div>
          </div>
          <button className="border-0 focus:outline-0 ">
            <img
              src={BoldBottomIcon}
              alt="BoldBottomIcon"
              className={`w-[10px] ${isListOpen && 'rotate-180'} `}
            />
          </button>
        </div>
      </div>

      {isListOpen && (
        <div
          role={list}
          className="z-40 absolute shadow max-h-[240px] w-[90%] overflow-y-scroll px-2 bg-slate-100"
        >
          {list.map((item: any, index: any) => (
            <div
              key={index}
              onClick={() => handleOnchange(item)}
              className="py-1 px-2 hover:bg-slate-200 rounded-lg cursor-pointer"
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DropdownSelector
