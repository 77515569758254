import React from 'react'
import Navigation from '../Common/Navigation'

const CustomerRelationshipSummary = () => {
  return (
    <Navigation>
      <div className="b-read legal w-richtext w-[85%] m-auto py-10 md:w-[800px]">
        <h1>Sootchy Securities LLC Customer Relationship Summary&nbsp;</h1>
        <p>
          <em>
            This important disclosure information about Sootchy Securities LLC
            (“Sootchy”) is provided to comply with the federal securities laws.
            It does not create or modify any agreement, relationship, or
            obligation between you and Sootchy Securities. Please consult our
            Client Agreement, Form ADV PART 2 Brochure E-Delivery Agreement,
            Terms of Use and Privacy Policy for the terms and conditions that
            govern your relationship with us. Effective as of March 30, 2021.
          </em>
          ‍
        </p>
        <p>
          Sootchy is a registered investment adviser (“RIA”) with the U.S.
          Securities and Exchange Commission and, as such, we provide advisory
          services rather than brokerage services. Investment advisory and
          brokerage services and fees differ, and it is important for you to
          understand these differences. Additionally, free and simple tools are
          available to research firms and financial professionals at
          <a href="http://Investor.gov/CRS" target="_blank" rel="noreferrer">
            Investor.gov/CRS
          </a>{' '}
          which also provides educational materials about broker-dealers,
          investment advisers, and investing. This document is a summary of the
          services and fees we offer to “retail” investors, which are natural
          persons who seek or receive services primarily for personal, family,
          or household purposes.
        </p>
        <h2>What investment services and advice can you provide me?</h2>
        <p>
          The Advisory Service is intended to be used by college savers who
          desire to save for qualified higher education expenses through a
          qualified tuition program (commonly referred to as a 529 Plan). The
          Advisory Service is delivered through the Sootchy application. Regular
          and continuous Internet access is required to enroll in the Advisory
          Service, to receive services and to access all documents. The minimum
          necessary to open an account with Sootchy (the “Account”) is the
          minimum necessary to open an account with the 529 Plan.
        </p>
        <p>
          The Advisory Service will give you the option to choose and investment
          option from the options available within the 529 Plan recommended by
          Sootchy (the “529 Plan”). If you choose not to elect an investment
          option, your assets will be placed in the moderate risk “Age Based” or
          “Target Date” fund available with the 529 Plan based on your
          beneficiary’s age and an assumed withdrawal start date at eighteen
          years of age (“Default Investment Option”). “Age Based” investments
          are diversified portfolios that adjust from mostly stocks to mostly
          bonds as your beneficiary nears their eighteenth birthday. The
          Advisory Service does not recommend any investment option and is not
          comprehensive investment advisory service or a complete financial
          plan. In making its recommendations, Sootchy does not take into
          account other aspects of your financial or tax situation. The 529 Plan
          is not affiliated with Sootchy and is not responsible for
          recommendations made by Sootchy or the operation of the Advisory
          Service. In the future, the Advisory Service may include other Section
          529 Plans that meet the Investment Adviser’s investment screening
          criteria.
        </p>
        <p>
          Sootchy will not monitor each client’s Account. Our investment
          advisory service is non-discretionary. &nbsp;Sootchy does not provide
          ongoing monitoring or advice regarding your investments. &nbsp;Some
          529 Plans may allow more customized investment options than the
          investment options listed on the Sootchy App. &nbsp;You can access
          these investment options by going to the 529 Plan administrator’s
          website.
        </p>
        <p>
          You have ultimate decision making to choose, our default investment
          option, or select your own option. &nbsp;If you invest in a different
          portfolio within the 529 Plan or another college savings program the
          performance of such Portfolio will differ from the performance of the
          portfolio Sootchy recommends. More information regarding our services,
          fees and other disclosures can be found in our Part 2A by clicking
          this link
          <a
            href="https://adviserinfo.sec.gov/firm/brochure/307621"
            target="_blank"
            rel="noreferrer"
          >
            https://adviserinfo.sec.gov/firm/brochure/307621
          </a>
          .
        </p>
        <h3>Conversation Starters*</h3>
        <ul>
          <li>
            “Given my financial situation, should I choose an investment
            advisory services? Why or why not?”&nbsp;
          </li>
          <li>“How will you choose investments to recommend to me?”&nbsp;</li>
          <li>
            “What is your relevant experience, including your licenses,
            education and other qualifications? What do these qualifications
            mean?”&nbsp;
          </li>
        </ul>
        <h2>What fees will I pay?</h2>
        <p>
          <strong>‍</strong>Sootchy does not receive a fee for the advisory
          services we provide. This does not mean that the service is free as
          there are fees and expenses charged by the 529 Plan you select.
          Additionally, an affiliate of Sootchy Securities, Sootchy, Inc.,
          charges a fee when individuals use the application’s gifting feature
          to send a gift to your account at Sootchy. People making gifts will
          pay fees and costs whether you make or lose money on your investments.
          Fees and costs will reduce any amount of money you make on your
          investments over time. Please make sure you understand what fees and
          costs you are paying. For additional information regarding fees, refer
          our Form ADV Part 2A by clicking this link
          <a
            href="https://adviserinfo.sec.gov/firm/brochure/307621"
            target="_blank"
            rel="noreferrer"
          >
            https://adviserinfo.sec.gov/firm/brochure/307621
          </a>
          . Please visit
          <a href="http://Sootchy.com" target="_blank" rel="noreferrer">
            www.sootchy.com
          </a>{' '}
          for more information on fees and expenses charged by the 529
          Plan.&nbsp;
        </p>
        <h3>Conversation Starters*&nbsp;</h3>
        <ul>
          <li>
            “Help me understand how these fees and costs might affect my
            investments. If I give you $10,000 to invest, how much will go to
            fees and costs, and how much will be invested for me?”&nbsp;
            <strong>‍</strong>
          </li>
        </ul>
        <h2>
          What are your legal obligations to me when acting as my investment
          adviser? How else does your firm make money and what conflicts of
          interest do you have?
        </h2>

        <p>
          <strong>‍</strong>
          <em>When we act as your investment adviser</em>, we have to act in
          your best interest and not put our interest ahead of yours. At the
          same time, the way we make money creates some conflicts with your
          interests. You should understand and ask us about these conflicts
          because they can affect the investment advice we provide you. Here are
          some examples to help you understand what this means.
        </p>
        <p>
          Sootchy makes money if you open a 529 plan through our application and
          you receive gift contributions from friends and family. &nbsp;While,
          in our judgment the 529 Plan is appropriate for college savers this
          creates an incentive for us to recommend you to invest in the 529 Plan
          as opposed to other qualified tuition programs and savings products
          that may be available to you.&nbsp;
        </p>
        <h3>Conversation Starters*&nbsp;</h3>
        <ul>
          <li>
            “How might your conflicts of interest affect me, and how will you
            address them?”
          </li>
        </ul>
        <h2>How do your financial professionals make money?</h2>
        <p>
          <strong>‍</strong>Our Company and the financial professionals
          servicing your account(s) are compensated through salary and either an
          annual bonus or variable compensation based on their performance – not
          based on the number of individuals that open accounts.&nbsp;
        </p>
        <h2>
          Do you or your financial professionals have legal or disciplinary
          history?
        </h2>
        <p>
          <strong>‍</strong>Yes, these events are disclosed in the specific
          individual’s Form U4. Their documents can be found by going to
          <a href="http://Investor.gov/CRS" target="_blank" rel="noreferrer">
            Investor.gov/CRS
          </a>
          .&nbsp;
        </p>
        <h3>Conversation Starters*</h3>
        <ul>
          <li>
            “As a financial professional, do you have any disciplinary history?
            For what type of conduct?”
          </li>
        </ul>
        <h2>Additional Information</h2>
        <p>
          <strong>‍</strong>For more information about our investment advisory
          services and to request a copy of the relationship summary contact us
          at
          <a href="mailto:help@sootchy.com">help@sootchy.com</a> or click the
          link provided
          <a
            href="https://adviserinfo.sec.gov/firm/brochure/307621"
            target="_blank"
            rel="noreferrer"
          >
            https://adviserinfo.sec.gov/firm/brochure/307621
          </a>
          .&nbsp;
        </p>
        <h3>Conversation Starters*</h3>
        <ul>
          <li>
            “Who is my primary contact person? Is he or she a representative of
            an investment adviser or a broker-dealer? Who can I talk to if I
            have concerns about how this person is treating me?”&nbsp;
          </li>
        </ul>
        <p>
          To review the answers to our conversation starters, please review our
          website at <a href="http://Sootchy.com">www.sootchy.com</a>.<br />
        </p>
      </div>
    </Navigation>
  )
}

export default CustomerRelationshipSummary
