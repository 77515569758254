import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { RootReduxStateType } from '../types'

const PrivateRoute = () => {
  const location = useLocation()
  const user = useSelector(
    (rootState: RootReduxStateType) => rootState.user.token
  )

  /*
  Currently we are checking of there is a token, if present
  then he will redirected, but behind every api call user's
  token will be validated, if expired new token will be generated,
  it not authorized, he will be logged out immediately. However this 
  might change in the future
  */

  const isAuthenticated = () => (user ? true : false)

  return isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default PrivateRoute
