import React, { useState } from 'react'
// @ts-ignore
import Close from '../../../assets/closeIcon.png'
// @ts-ignore
import CaretRight from '../../../assets/CaretRight.png'

type PropsType = {
  title: string
  description: string
  BtnText?: string
  linkInDescription?: string
  onSubmit?: () => void
  onCancel: () => void
  onItemSelected: (e: any) => void
  icon: string
  list: Array<any>
}

// This is also used in the KYC failure
const ListOptionsModal: React.FC<PropsType> = ({
  title,
  description,
  linkInDescription,
  icon,
  BtnText,
  onCancel,
  onSubmit,
  onItemSelected,
  list,
}) => {
  const [selctedValue, setSelectedValue] = useState<string>('')

  const handleItemClick = (item: string) => {
    setSelectedValue(item)
    onItemSelected(item)
    //onSubmit()
  }

  return (
    <>
      <div className="block fixed bg-[#021212BF] w-[100%] h-[100vh] top-0 left-0 opacity-95 z-[100]"></div>
      <>
        <div
          data-test="quiz-modal"
          className="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-[90%] md:w-[376px] lg:w-[480px] min-h-[200px] max-h-[90%]  opacity-100 rounded-[15px] flex flex-col z-[101]"
        >
          {/** Modal Title */}
          <div className="relative w-full bg-[#E3F2F2] p-5 rounded-t-[15px]">
            <p className="font-semibold text-[1rem] leading-[19.83px]">
              {title}
            </p>

            {linkInDescription ? (
              <a
                className="text-[14px] text-[#527473] underline leading-[24px]"
                href={`${linkInDescription}`}
                target="_blank"
                rel="noreferrer"
              >
                {description}
              </a>
            ) : (
              <p className="text-[14px] text-[#527473] underline leading-[24px]">
                {description}
              </p>
            )}

            <div className="absolute top-4 right-4 cursor-pointer">
              <img
                src={Close}
                alt="Close"
                className="pb-4"
                onClick={onCancel}
              />
            </div>
          </div>

          {/** Modal Content */}
          <div className="w-full max-h-[520px] overflow-y-scroll ">
            <div className="flex flex-col ">
              {list.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleItemClick(item)}
                  className="p-[16px] flex items-center justify-between cursor-pointer text-[14px] leading-[18px] font-normal py-[14px] border-b-2 border-dashed border-[#C4E8E8] hover:bg-[#E3F2F2]"
                >
                  {item.investmentOptionName}
                  <img src={CaretRight} alt="CaretRight" onClick={onCancel} />
                </div>
              ))}
            </div>

            <button
              className="font-normal mt-2 border-0 w-full"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    </>
  )
}

export default ListOptionsModal
