import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../api'
import { createAlertAction } from '../../../redux/actions/alertActions'
import {
  alpacaStepperUpdateAssistenceNeeded,
  alpacaStepperUpdateTaxAndCitizenship,
  alpacaUserAccountResidency,
  alpacaUserAccountVisaStatus,
  decrementAlpacaStepperStep,
  getCountriesList,
  increamentAlpacaStepperStep,
} from '../../../redux/actions/alpacaActions'
import { RootReduxStateType } from '../../../types'
import SafeAreaView from '../../Common/SafeAreaView'
import DateInput from '../../Common/DateInput/DateInput'
import CountrySelector from '../../Common/Partials/CountrySelector'
import DropdownSelector from '../../Common/Partials/DropdownSelector'
import TaxResidencePageLoader from '../../Common/SkeletonLoaders/TaxResidencePageLoader'
import SetupBottomAlpaca from '../SetupBottomAlpaca'

const AlpacaStepEight: React.FC = () => {
  const dispatch = useDispatch()

  const validVisaTypes = [
    'B1',
    'B2',
    'DACA',
    'E1',
    'E2',
    'E3',
    'F1',
    'G4',
    'H1B',
    'J1',
    'J1',
    'L1',
    'O1',
    'TN1',
    'OTHER',
  ]

  const user = useSelector((state: RootReduxStateType) => state.user)
  const alpacaTaxAndIncome = useSelector(
    (state: RootReduxStateType) => state.alpaca.taxAndIncome
  )
  const [isTaxResidenceChosen, setIsTaxResidenceChosen] =
    useState<boolean>(false)
  const [isCitizenshipChosen, setIsCitizenshipChosen] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [taxResidence, setTaxResidence] = useState<string | null>('')
  const [residenceStatus, setResidenceStatus] = useState<string | null>()
  const [countryOptions, setCountryOptions] = useState<any>([])
  const [taxResidenceCountry, setTaxResidenceCountry] = useState<any>('')
  const [taxResidenceCountryCode, setTaxResidenceCountryCode] = useState<
    string | null
  >('')

  const [birthCountry, setBirthCountry] = useState<any>('')
  const [birthCountryCode, setBirthCountryCode] = useState<string | null>('')
  const [citizenshipCountry, setCitizenshipCountry] = useState<any>('')
  const [citizenshipCountryCode, setCitizenshipCountryCode] = useState<
    string | null
  >('')

  const [isVisaRequired, setIsVisaRequired] = useState<boolean>(false)
  const [visaType, setVisaType] = useState<string | null>('')
  const [visaExpirationDate, setVisaExpirationDate] = useState<string | null>(
    null
  )
  const [dateOfDeparture, setDateOfDepature] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [apiLoading, setApiLoading] = useState<boolean>(false)
  //const [countriesLoading, setCountriesLoading] = useState<boolean>(true)

  const handleBirthCountryChange = (code: any, name: any) => {
    setBirthCountryCode(code)
    setBirthCountry(name)
  }

  const handleTaxResidenceCountryChange = (code: any, name: any) => {
    setTaxResidenceCountryCode(code)
    setTaxResidenceCountry(name)
  }

  const handleCitizenshipChange = (code: any, name: any) => {
    setCitizenshipCountryCode(code)
    setCitizenshipCountry(name)
  }

  const handleTaxResidenceChange = (e: any) => {
    setTaxResidence(e.target.value)
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship('taxPaymentStatus', e.target.value)
    )
  }

  const handleCitizenshipAndResidenceStatusChange = (e: any) => {
    setResidenceStatus(e.target.value)
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship('residencyStatus', e.target.value)
    )
  }

  const handleReduxDispatchActions = () => {
    // ONLY CALL WHEN MOVING TO NEXT STEP
    dispatch(alpacaStepperUpdateTaxAndCitizenship('isCitizenshipChosen', true))
    dispatch(alpacaStepperUpdateTaxAndCitizenship('isTaxResidenceChosen', true))
    dispatch(alpacaStepperUpdateTaxAndCitizenship('birthCountry', birthCountry))
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship('birthCountryCode', birthCountryCode)
    )
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship(
        'citizenshipCountry',
        citizenshipCountry
      )
    )
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship(
        'citizenshipCountryCode',
        citizenshipCountryCode
      )
    )
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship(
        'taxResidenceCountry',
        taxResidenceCountry
      )
    )
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship(
        'taxResidenceCountryCode',
        taxResidenceCountryCode
      )
    )

    dispatch(alpacaStepperUpdateTaxAndCitizenship('visaType', visaType))
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship(
        'visaExpirationDate',
        dayjs(visaExpirationDate).format('YYYY-MM-DD')
      )
    )
    dispatch(
      alpacaStepperUpdateTaxAndCitizenship(
        'dateOfDeparture',
        dayjs(dateOfDeparture).format('YYYY-MM-DD')
      )
    )
  }

  const handleCancel = () => {
    if (isTaxResidenceChosen && isCitizenshipChosen) {
      if (isVisaRequired) {
        setIsVisaRequired(false)
      } else {
        setIsCitizenshipChosen(false)
        setIsTaxResidenceChosen(false)
      }
    } else {
      dispatch(decrementAlpacaStepperStep())
    }
  }

  const handleNext = () => {
    if (isTaxResidenceChosen && isCitizenshipChosen) {
      if (taxResidence === '1') {
        // USER PAYS TAXES TO THE USA
        handleAPICall()
      } else if (taxResidence === '2') {
        // USER PAYS TAXES ELSEWHERE
        // WORK FLOW NOT YET AVAILABLE
        handleAPICall()
      }
    } else {
      setIsCitizenshipChosen(true)
      setIsTaxResidenceChosen(true)
    }
  }

  const handleAPICall = async () => {
    if (
      residenceStatus === 'Citizen' ||
      residenceStatus === 'PermanentResident'
    ) {
      if (
        birthCountryCode &&
        citizenshipCountryCode &&
        taxResidenceCountryCode
      ) {
        if (
          birthCountryCode === alpacaTaxAndIncome.birthCountryCode &&
          citizenshipCountryCode === alpacaTaxAndIncome.citizenshipCountryCode
        ) {
          dispatch(increamentAlpacaStepperStep())
        } else {
          handleReduxDispatchActions()
          setLoading(true)
          alpacaUserAccountResidency({
            userAccountID: user.id,
            birthCountryCode: birthCountryCode,
            citizenshipCountryCode: citizenshipCountryCode,
            taxResidenceCountryCode: taxResidenceCountryCode,
            residencyStatus: residenceStatus,
          })
            .then(() => {
              setLoading(false)
              dispatch(increamentAlpacaStepperStep())
            })
            .catch((error) => {
              setLoading(false)
              console.log('Error creating Known person.', error)
            })
        }
      } else {
        console.log(
          'Required Fields',
          birthCountryCode,
          citizenshipCountryCode,
          taxResidenceCountryCode
        )
      }
    } else if (residenceStatus === 'VisaHolder') {
      //console.log('I am a visa holder')
      if (isVisaRequired) {
        handleVisaRequired()
      } else {
        setIsVisaRequired(true)
      }
    }
  }

  const calculateDaysLeft = (date: string | null) => {
    const startDate = dayjs()
    const endDate = dayjs(date)
    const diff = endDate.diff(startDate, 'day', true)
    const days = Math.floor(diff)
    //console.log(`${days} days`)

    return days
  }

  const visaRequiredAPICall = async () => {
    if (
      alpacaTaxAndIncome.visaType === visaType ||
      alpacaTaxAndIncome.visaExpirationDate === visaExpirationDate ||
      alpacaTaxAndIncome.dateOfDeparture
    ) {
      dispatch(increamentAlpacaStepperStep())
    } else {
      alpacaUserAccountResidency({
        userAccountID: user.id,
        birthCountryCode: birthCountryCode,
        citizenshipCountryCode: citizenshipCountryCode,
        taxResidenceCountryCode: taxResidenceCountryCode,
        residencyStatus: residenceStatus,
      })
        .then(() => {
          alpacaUserAccountVisaStatus({
            userAccountID: user.id,
            visaType: visaType,
            expirationDate: dayjs(visaExpirationDate),
            expectedDepartureDate: dayjs(dateOfDeparture),
          })
            .then(() => {
              dispatch(increamentAlpacaStepperStep())
            })
            .catch((err) => console.log('Failed to Update Visa Status', err))
        })
        .catch((error) => {
          setLoading(false)
          console.log('Error creating Known person.', error)
        })
    }
  }

  const handleVisaRequired = async () => {
    if (isVisaRequired) {
      // (visaType === 'B2' || visaType === 'B1')
      if (visaType === 'B2' || visaType === 'B1') {
        // check for visadate and expiration date
        // visaExpirate  departure

        if (visaType && visaExpirationDate && dateOfDeparture) {
          if (
            calculateDaysLeft(dateOfDeparture) ===
              calculateDaysLeft(visaExpirationDate) ||
            calculateDaysLeft(dateOfDeparture) >
              calculateDaysLeft(visaExpirationDate)
          ) {
            dispatch(
              createAlertAction({
                type: 'DANGER',
                title: 'Error Occured',
                message:
                  'Date of Departure should not be equal or after your visa expiration date',
                autoClose: true,
                autoCloseDuration: 3000,
              })
            )
          } else {
            if (calculateDaysLeft(visaExpirationDate) > 90) {
              if (calculateDaysLeft(dateOfDeparture) > 90) {
                handleReduxDispatchActions()

                visaRequiredAPICall()
              } else {
                dispatch(
                  createAlertAction({
                    type: 'DANGER',
                    title: 'Error Occured',
                    message:
                      'Date of Departure must be more than 90 days from the date you try to apply for Alpaca',
                    autoClose: true,
                    autoCloseDuration: 3000,
                  })
                )
              }
            } else {
              dispatch(
                createAlertAction({
                  type: 'DANGER',
                  title: 'Error Occured',
                  message:
                    'Visa expiration date must be more than 90 days from the date you try to apply for Alpaca',
                  autoClose: true,
                  autoCloseDuration: 3000,
                })
              )
            }
          }
        }
      } else {
        //check for only visadate
        //console.log('Expiration Date', calculateDaysLeft(visaExpirationDate))
        if (visaType && visaExpirationDate) {
          if (calculateDaysLeft(visaExpirationDate) > 90) {
            handleReduxDispatchActions()

            visaRequiredAPICall()
          } else {
            dispatch(
              createAlertAction({
                type: 'DANGER',
                title: 'Error Occured',
                message:
                  'Visa expiration date must be more than 90 days from the date you try to apply for Alpaca',
                autoClose: true,
                autoCloseDuration: 3000,
              })
            )
          }
        }
      }
    }
  }

  const findCountryLabel = async (countryCode: string) => {
    if (countryOptions.length > 0) {
      setApiLoading(true)
      for (let item = 0; item < countryOptions.length; item++) {
        if (countryOptions[item].alpha3 === countryCode) {
          //console.log('Item from Func', countryOptions[item].countryName)
          //setApiLoading(false)
          return countryOptions[item].countryName
        }
      }
    } else {
      const res = await getCountriesList()

      for (let item = 0; item < res.length; item++) {
        if (res[item].alpha3 === countryCode) {
          //console.log('Item from Func', res[item].countryName)
          //setApiLoading(false)
          return res[item].countryName
        }
      }
    }
  }

  const getCountryOptions = async () => {
    getCountriesList()
      .then((response) => {
        //console.log('Options', response)
        setCountryOptions(response)
      })
      .catch((err) => console.log('Failed to load Countries List', err))
  }

  const getUserVisaDetails = async () => {
    if (alpacaTaxAndIncome.visaType !== null) {
      setVisaType(alpacaTaxAndIncome.visaType)
      setVisaExpirationDate(alpacaTaxAndIncome.visaExpirationDate)

      if (
        alpacaTaxAndIncome.visaType === 'B1' ||
        alpacaTaxAndIncome.visaType === 'B2'
      ) {
        setDateOfDepature(alpacaTaxAndIncome.dateOfDeparture)
      }
    } else {
      const res = await api.get('/Alpaca/User/VisaStatus')

      if (res.status === 200) {
        setVisaType(res.data.visaType)
        setVisaExpirationDate(
          dayjs(res.data.expirationDate).format('YYYY-MM-DD')
        )

        if (res.data.visaType === 'B1' || res.data.visaType === 'B2') {
          setDateOfDepature(
            dayjs(res.data.expectedDepartureDate).format('YYYY-MM-DD')
          )
        }
      }

      //console.log('Res', res)
    }
  }

  const getUserDetails = async () => {
    getCountryOptions()
    if (
      alpacaTaxAndIncome.residencyStatus &&
      alpacaTaxAndIncome.taxResidenceCountryCode
    ) {
      // console.log('There is Tax income', alpacaTaxAndIncome)

      setIsCitizenshipChosen(alpacaTaxAndIncome.isCitizenshipChosen)
      setIsTaxResidenceChosen(alpacaTaxAndIncome.isTaxResidenceChosen)
      setResidenceStatus(alpacaTaxAndIncome.residencyStatus)
      setTaxResidence(alpacaTaxAndIncome.taxPaymentStatus)
      setBirthCountry(alpacaTaxAndIncome.birthCountry)
      setBirthCountryCode(alpacaTaxAndIncome.birthCountryCode)

      // if (citizenshipCountry !== alpacaTaxAndIncome.citizenshipCountry) {
      setCitizenshipCountry(alpacaTaxAndIncome.citizenshipCountry)
      setCitizenshipCountryCode(alpacaTaxAndIncome.citizenshipCountryCode)
      setTaxResidenceCountry(alpacaTaxAndIncome.taxResidenceCountry)
      setTaxResidenceCountryCode(alpacaTaxAndIncome.taxResidenceCountryCode)

      // getCountryOptions()
    } else {
      setApiLoading(true)
      // getCountryOptions()

      api
        .get(`/Alpaca/User/Residency`)
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data.birthCountryCode) {
              setBirthCountryCode(res.data.birthCountryCode)
              setBirthCountry(await findCountryLabel(res.data.birthCountryCode))
              setTaxResidenceCountryCode(res.data.taxResidenceCountryCode)
              setTaxResidenceCountry(
                await findCountryLabel(res.data.taxResidenceCountryCode)
              )
              setCitizenshipCountryCode(res.data.citizenshipCountryCode)

              //console.log('Countyr list', res.data.citizenshipCountryCode)
              setCitizenshipCountry(
                await findCountryLabel(res.data.citizenshipCountryCode)
              )
              setResidenceStatus(res.data.residencyStatus)
            }

            if (res.data.taxResidenceCountryCode === 'USA') {
              setTaxResidence('1')
            } else {
              setTaxResidence('2')
            }

            setApiLoading(false)
          }
        })
        .catch(() => {
          console.log('Unable to run API')
          setApiLoading(false)
        })
    }
  }

  useEffect(() => {
    if (residenceStatus !== 'VisaHolder') {
      setIsVisaRequired(false)
      if (residenceStatus === 'Citizen') {
        setCitizenshipCountry('United States of America')
        setCitizenshipCountryCode('USA')
      } else {
        setCitizenshipCountry('')
        setCitizenshipCountryCode('')
      }
    }

    if (taxResidence === '1') {
      setTaxResidenceCountry('United States of America')
      setTaxResidenceCountryCode('USA')
    } else if (
      alpacaTaxAndIncome.taxResidenceCountry !== '' ||
      alpacaTaxAndIncome.taxResidenceCountry !== null
    ) {
      setTaxResidenceCountry(alpacaTaxAndIncome.taxResidenceCountry)
      setTaxResidenceCountryCode(alpacaTaxAndIncome.taxResidenceCountryCode)
    } else {
      setTaxResidenceCountry('')
      setTaxResidenceCountryCode('')
    }
  }, [taxResidence, residenceStatus])

  useEffect(() => {
    getUserDetails()
    getUserVisaDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Manage button disabled state
    if (isTaxResidenceChosen && isCitizenshipChosen) {
      if (isVisaRequired) {
        if (visaType && visaExpirationDate && birthCountry) {
          // Calculate VISA required
          setDisabled(false)
        } else {
          setDisabled(true)
        }
      } else {
        if (birthCountry && citizenshipCountry && taxResidenceCountry) {
          setDisabled(false)
        } else {
          setDisabled(true)
        }
      }
    } else {
      if (taxResidence && residenceStatus) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
  }, [
    taxResidence,
    residenceStatus,
    isTaxResidenceChosen,
    isCitizenshipChosen,
    birthCountry,
    citizenshipCountry,
    taxResidenceCountry,
    isVisaRequired,
    visaExpirationDate,
    visaType,
  ])

  return (
    <div>
      <SafeAreaView>
        {apiLoading ? (
          <>
            <TaxResidencePageLoader />
          </>
        ) : isTaxResidenceChosen && isCitizenshipChosen ? (
          <>
            <p className="text-[18px] py-[16px] font-semibold">
              Citizenship (contd.)
            </p>

            {isVisaRequired ? (
              <>
                <DropdownSelector
                  title="Visa Type"
                  list={validVisaTypes}
                  value={visaType!}
                  handleOnchange={(item: any) => setVisaType(item)}
                />

                <div className="py-4">
                  <DateInput
                    label="Visa Expiration Date"
                    maxDateAvailable
                    dateValue={visaExpirationDate ? visaExpirationDate : ''}
                    // dateValue={
                    //   visaExpirationDate ??
                    //   dayjs(new Date()).format('YYYY-MM-DD')
                    // }
                    onChange={(newValue) => setVisaExpirationDate(newValue)}
                  />
                </div>

                <CountrySelector
                  title="Country of Birth"
                  list={countryOptions}
                  value={birthCountry!}
                  handleOnchange={handleBirthCountryChange}
                />

                {(visaType === 'B2' || visaType === 'B1') && (
                  <>
                    <div className="py-4">
                      <DateInput
                        label="Date of Departure"
                        maxDateAvailable
                        dateValue={dateOfDeparture!}
                        onChange={(newValue) => setDateOfDepature(newValue)}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <CountrySelector
                  // Make the value change with redux inparent component
                  title="Country of Birth"
                  list={countryOptions}
                  value={birthCountry!}
                  handleOnchange={handleBirthCountryChange}
                  //handleValueChange={(newValue) => setBirthCountry(newValue)}
                />
                <CountrySelector
                  title="Country of Citizenship"
                  list={countryOptions}
                  value={citizenshipCountry!}
                  disable={residenceStatus === 'Citizen' ? true : false}
                  handleOnchange={handleCitizenshipChange}
                />
                {alpacaTaxAndIncome.taxPaymentStatus === '2' && (
                  <CountrySelector
                    title="Country of Tax Residency"
                    list={countryOptions}
                    value={taxResidenceCountry!}
                    handleOnchange={handleTaxResidenceCountryChange}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <p className="text-[18px] py-[16px] font-semibold">
              Tax residence & citizenship
            </p>

            <p className="leading-[24px] text-[16px] font-normal text-[#021212] mb-[16px]">
              Where do you pay your taxes?
            </p>

            <div className="leading-[24px] text-[16px] font-normal text-[#021212]">
              <div className="mb-[16px] flex space-x-2 ">
                <input
                  className="accent-primary focus:accent-primary"
                  aria-label="United States"
                  id="US"
                  type="radio"
                  value={1}
                  name="tax-residence"
                  onChange={handleTaxResidenceChange}
                  checked={taxResidence === '1'}
                />
                <label htmlFor="US">I pay my taxes in the United States</label>
              </div>

              <div className="flex space-x-2">
                <input
                  className="accent-primary focus:accent-primary"
                  aria-label="Elswehere"
                  id="Elsewhere"
                  type="radio"
                  value={2}
                  name="tax-residence"
                  onChange={handleTaxResidenceChange}
                  checked={taxResidence === '2'}
                />
                <label htmlFor="Elsewhere">I pay my taxes elsewhere</label>
              </div>
            </div>

            <p className="leading-[24px] text-[16px] font-normal text-[#021212] my-[24px]">
              Are you a citizen of the United States?
            </p>

            <div>
              <div className="mb-[16px] flex space-x-2 ">
                <input
                  className="accent-primary focus:accent-primary"
                  aria-label="Citizen"
                  id="citizen"
                  type="radio"
                  value="Citizen"
                  name="residencyStatus"
                  onChange={handleCitizenshipAndResidenceStatusChange}
                  checked={residenceStatus === 'Citizen'}
                  //checked={alpacaTaxAndIncome.residencyStatus === 'Citizen'}
                  //checked={residenceStatus === '1'}
                />
                <label htmlFor="citizen">
                  I am a citizen of the United States
                </label>
              </div>

              <div className="mb-[16px] flex space-x-2 ">
                <input
                  className="accent-primary focus:accent-primary"
                  aria-label="PermanentResident"
                  id="permanentResident"
                  type="radio"
                  value="PermanentResident"
                  name="residencyStatus"
                  onChange={handleCitizenshipAndResidenceStatusChange}
                  checked={residenceStatus === 'PermanentResident'}
                  // checked={
                  //   alpacaTaxAndIncome.residencyStatus === 'PermanentResident'
                  // }
                  //checked={residenceStatus === '2'}
                />
                <label htmlFor="permanentResident">
                  I am not a citizen of the United States but I have a Green
                  Card or a permanent resident status
                </label>
              </div>

              <div className="mb-[16px] flex space-x-2 ">
                <input
                  className="accent-primary focus:accent-primary"
                  aria-label="VisaHolder"
                  id="visaHolder "
                  type="radio"
                  value="VisaHolder"
                  name="residencyStatus"
                  onChange={handleCitizenshipAndResidenceStatusChange}
                  checked={residenceStatus === 'VisaHolder'}
                  //checked={alpacaTaxAndIncome.residencyStatus === 'VisaHolder'}
                />
                <label htmlFor="visaHolder">
                  I am not a citizen of the United States but I am a Visa holder
                </label>
              </div>
            </div>
          </>
        )}

        <p
          onClick={() => dispatch(alpacaStepperUpdateAssistenceNeeded(true))}
          className="underline text-primary text-[14px] underline-offset-4 py-3 cursor-pointer"
        >
          I need assistance
        </p>
      </SafeAreaView>

      <SetupBottomAlpaca
        disabled={disabled}
        handleCancel={handleCancel}
        handleNext={handleNext}
        loading={loading}
      />
      {/** <SetupBottomAlpaca
        handleNext={function (): void {
          throw new Error('Function not implemented.')
        }}
      /> */}
    </div>
  )
}

export default AlpacaStepEight
