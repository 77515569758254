import {
  CHANGE_USER_REGISTRATION_STEPPER_DATA,
  RESET_USER_REGISTRATION_STEPPER,
  RESET_USER_REGISTRATION_STEPPER_DATA,
  USER_REGISTRATION_STEPPER_DECREAMENT,
  USER_REGISTRATION_STEPPER_INCREAMENT,
  USER_REGISTRATION_STEPPER_RESET,
  USER_REGISTRATION_STEPPER_UNDERAGE,
} from './action.types'

export const increamentUserRegistrationStep =
  () => async (dispatch: Function) => {
    dispatch({
      type: USER_REGISTRATION_STEPPER_INCREAMENT,
    })
  }

export const decreamentUserRegistrationStep =
  () => async (dispatch: Function) => {
    dispatch({
      type: USER_REGISTRATION_STEPPER_DECREAMENT,
    })
  }

export const underageUserRegistrationStep =
  () => async (dispatch: Function) => {
    dispatch({
      type: USER_REGISTRATION_STEPPER_UNDERAGE,
    })
  }

export const resetUserRegistrationStep = () => async (dispatch: Function) => {
  dispatch({
    type: USER_REGISTRATION_STEPPER_RESET,
  })
}

export const resetUserRegistration = () => async (dispatch: Function) => {
  dispatch({
    type: RESET_USER_REGISTRATION_STEPPER,
  })
}
export const changeUserRegistrationStepperData =
  (key: string, value: string | boolean | null) =>
  async (dispatch: Function) => {
    dispatch({
      type: CHANGE_USER_REGISTRATION_STEPPER_DATA,
      payload: { key, value },
    })
  }

export const resetUserRegistrationStepData =
  () => async (dispatch: Function) => {
    dispatch({ type: RESET_USER_REGISTRATION_STEPPER_DATA })
  }
