import { ReduxActionType } from '../../types'
import { AlertType } from '../../types/alert'
import { CREATE_ALERT, REMOVE_ALERT } from '../actions/action.types'

const initialState: AlertType[] = []

export const alertReducer = (
  state = initialState,
  action: ReduxActionType<AlertType>
) => {
  switch (action.type) {
    case CREATE_ALERT: {
      const { id, type, message, title } = action.payload

      return [
        ...state,
        {
          id,
          type,
          message,
          title,
        },
      ]
    }
    case REMOVE_ALERT: {
      const filteredState = state.filter(
        (alert) => alert.id !== action.payload.id
      )

      return filteredState
    }
    default:
      return state
  }
}
