import React, { useState } from 'react'
import api from '../../api'
import useInterval from '../../hooks/useInterval'
import { formatTime } from '../../utilities/generalUtilities'
// @ts-ignore
import CloseIcon from '../../assets/closeIcon.png'

type PropsType = {
  title: string
  description?: string
  link?: string
  linkText?: string
  showButton: boolean
  showSecondaryButton?: boolean
  showSecondaryButtonText?: string
  showInput?: boolean
  showInputText?: string
  showInputValue?: string
  onChangeInput?: (e: any) => void
  buttonText?: string
  handleOnClick?: () => void
  onClose: () => void
  loading?: boolean
  emailVerification?: boolean
  handleParentModel?: () => void
}

const VerificationModal: React.FC<PropsType> = ({
  title,
  description,
  link,
  linkText,
  showButton = true,
  showSecondaryButton = false,
  showSecondaryButtonText,
  showInput = false,
  showInputText,
  showInputValue,
  onChangeInput,
  buttonText,
  handleOnClick,
  onClose,
  loading = false,
  emailVerification = false,
  handleParentModel,
}) => {
  const [emailVerificationSent, setEmailVerificationSent] =
    useState<boolean>(false)
  const [emailVerificationTime, setEmailVerificationTime] =
    useState<number>(120)

  const resendVerificationLink = async () => {
    if (!emailVerificationSent) {
      const response = await api.post(
        '/UserAccount/Verify/Email',
        'WebApplication'
      )

      if (response.status === 200) {
        setEmailVerificationSent(true)
      }
    }
  }

  useInterval(() => {
    if (emailVerificationSent) {
      if (emailVerificationTime !== 0)
        setEmailVerificationTime(emailVerificationTime - 1)
      else {
        setEmailVerificationSent(false)
        setEmailVerificationTime(120)
      }
    }
  }, 1000)

  return (
    <div className="fixed h-full w-screen z-50 top-0 left-0">
      <div
        className="absolute bg-black opacity-80 h-screen w-screen"
        onClick={onClose}
      ></div>
      <div
        className="absolute bg-white w-[88%] rounded-xl p-4 sm:w-[400px]"
        style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
      >
        <div className="flex justify-between items-center border-[#C4E8E8] border-b-2 border-dashed pb-4">
          <p className="font-semibold text-primaryDark">{title}</p>
          <img
            src={CloseIcon}
            alt="close icon"
            className="w-[13px] h-[13px]"
            onClick={onClose}
          />
        </div>
        {description && (
          <div className="py-5">
            <p>{description}</p>
            {emailVerification && (
              <p
                className="text-primaryDark cursor-pointer"
                onClick={handleParentModel}
              >
                (change)
              </p>
            )}
            <p></p>
          </div>
        )}

        {emailVerification && (
          <p
            className="text-primaryDark underline cursor-pointer"
            onClick={resendVerificationLink}
          >
            Send link again{' '}
            {emailVerificationSent && `in ${formatTime(emailVerificationTime)}`}
          </p>
        )}
        {showInput && (
          <div className={`form-group form-group--filled py-2`}>
            <input
              onChange={onChangeInput}
              value={showInputValue}
              disabled={loading}
              aria-label="showInputText"
            />
            <label>{showInputText}</label>
          </div>
        )}
        {link && (
          <a href={link} className="text-primaryDark underline">
            {linkText}
          </a>
        )}
        <div className="flex justify-center items-center">
          {showSecondaryButton && (
            <button
              className="w-[80%] mt-3 py-4 mr-2"
              onClick={onClose}
              disabled={loading}
            >
              {showSecondaryButtonText}
            </button>
          )}
          {showButton && (
            <button
              className={
                showSecondaryButton
                  ? 'w-[100%] mt-3 py-4 bg-primaryDark text-white'
                  : 'w-[100%] mt-3 py-4'
              }
              onClick={handleOnClick}
              disabled={loading}
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default VerificationModal
