import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getWallets } from '../../../redux/actions/linkActions'
import { getSilaUserAccountBalance } from '../../../redux/actions/setupSavingsStepperActions'
import { getSillaStatus, getUser } from '../../../redux/actions/userActions'
import { RootReduxStateType } from '../../../types'
import { SilaStatusResp } from '../../../types/sila'
import { WalletType } from '../../../types/transactions'
import { UserAPIType } from '../../../types/user'
import { LoadingSpinner } from '../../Common/Loading'
import Navigation from '../../Common/Navigation'
import AddChildModal from '../SetupSavingsAccount/Child/AddChildModal'
// @ts-ignore
import { AzureMP } from 'react-azure-mp'

const Wallets: React.FC<{}> = () => {
  const navigate = useNavigate()
  const user = useSelector((state: RootReduxStateType) => state.user)

  const [isSilaAccountCreated, setIsSilaAccountCreated] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadedUser, setLoadedUser] = useState<UserAPIType | null>()
  const [verificationStatus, setVerificationStatus] = useState<
    'Passed' | 'Documents_Received' | 'Pending' | 'Server_Error' | null
  >(null)
  const [showAddChildModal, setShowAddChildModal] = useState<boolean>(false)
  const [wallets, setWallets] = useState<WalletType[] | null>(null)
  const [balance, setBalance] = useState<number | null>(null)

  const handleGetUser = () => {
    getUser(user.id)
      .then((res) => {
        if (res.status === 200) {
          setLoadedUser(res.data)
          if (!res.data.silaUserAccountID) setIsSilaAccountCreated(false)
          else setIsSilaAccountCreated(true)
          setLoading(false)
        }
      })
      .catch(() => {
        alert('Error retrieving user')
        setLoading(false)
      })
  }

  const handleWalletOnClick = (id: string) => {
    navigate(`/wallet/${id}`)
  }

  const handleGetSilaaStatus = async () => {
    try {
      const res = await getSillaStatus(user.id)
      const data: SilaStatusResp = res.data
      if (res.status === 200) {
        setVerificationStatus(data.silaAccountVerificationStatus ?? null)
      }
    } catch (err: any) {
      setVerificationStatus('Server_Error')
    }
  }

  const handleGetWallets = () => {
    getWallets()
      .then((res) => {
        setWallets(res.data)
      })
      .catch(() => {
        alert('Error in getting wallets')
      })
  }

  useEffect(() => {
    !user.token && navigate('/login')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetSilaUserAccountBalance = () => {
    getSilaUserAccountBalance()
      .then((res) => {
        setBalance(res.data.totalBalance)
      })
      .catch(() => {
        alert('Error retrieving sila account balance')
      })
  }

  const displayBadge = () => {
    if (verificationStatus !== 'Passed') {
      return (
        <p className="mt-6 bg-[#E48310] inline-block uppercase font-semibold text-white py-1 px-3 rounded-full text-[13px] tracking-wide">
          {verificationStatus}
        </p>
      )
    }
  }

  const displayActionRequired = () => {
    if (
      verificationStatus !== 'Passed' &&
      verificationStatus !== 'Server_Error' &&
      verificationStatus !== 'Pending' &&
      verificationStatus !== 'Documents_Received'
    ) {
      return (
        <>
          <div className="py-6">
            <p className="text-primaryDark font-semibold text-[16px]">
              Your action is required
            </p>
            <p className="py-2 text-[15px]">
              We must ensure further that you are a human being. To do so, you
              will be asked to provide a little bit more details about yourself.
            </p>
            <button
              className="bg-primaryDark border-none text-white px-5 py-3 mt-2"
              onClick={() => navigate('/savings-account-verification')}
            >
              Complete Verification
            </button>
          </div>
          <div className="border-b-2 border-dashed border-[#C4E8E8] my-8"></div>
        </>
      )
    }
  }

  const displayWallets = () => {
    if (verificationStatus === 'Passed') {
      return (
        <>
          <p className=" lg:font-semibold lg:text-primaryDark lg:text-[20px] lg:py-5">
            Childrens' Wallets
          </p>

          <div className={`grid grid-cols-2 gap-5 mt-5`}>
            {wallets &&
              wallets.map((wallet, index) => (
                <div
                  key={index}
                  className="py-4 px-4 rounded-lg cursor-pointer"
                  style={{
                    backgroundColor: wallet.walletColor,
                    color: wallet.walletColor,
                  }}
                  onClick={() => handleWalletOnClick(wallet.walletName)}
                >
                  <p className="brightness-50 font-semibold text-[20px]">
                    {wallet.walletName}
                  </p>
                  <p className="brightness-50 text-[14px] mt-4">Total</p>
                  <p className="brightness-50 font-semibold text-[20px] mt-1">
                    ${wallet.currentBalance}
                  </p>
                </div>
              ))}
          </div>
        </>
      )
    }
  }

  const displayAccountDetails = () => {
    return (
      <div>
        <div className="flex justify-between items-center mt-5">
          <div>
            <p className="text-[16px]">Total</p>
            <p className="text-[30px] font-semibold text-primaryDark mt-3">
              ${balance}
            </p>
          </div>
          <button
            className={`bg-primary text-white py-3 px-4 mt-10`}
            onClick={() => setShowAddChildModal(true)}
            disabled={verificationStatus === 'Passed' ? false : true}
          >
            Add Child
          </button>
        </div>
        <div className="border-b-2 border-dashed border-[#C4E8E8] my-8"></div>

        {displayWallets()}
      </div>
    )
  }

  useEffect(() => {
    if (user.id || user.id !== '') {
      handleGetUser()
      handleGetSilaaStatus()
      handleGetWallets()
      handleGetSilaUserAccountBalance()
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SetupSilaaAccount = () => (
    <div className="mt-4 flex flex-col w-[90%] m-auto items-center lg:flex-row md:w-[95%] lg:w-[1200px] lg:mt-0">
      <div className="w-full">
        <AzureMP
          skin="amp-flush"
          src={[
            {
              src: 'https://strm-media-prodsootchy01-mediaprodsootchy01-usea.streaming.media.azure.net/e9cad2e8-b34f-400b-866e-38b4301fdf02/ssn_video.ism/manifest',
              type: 'video/mp4',
            },
          ]}
          options={{
            controls: true,
            autoplay: false,
            nativeControlsForTouch: true,
          }}
        />
      </div>

      <div className=" bg-[#EFF9F9] px-6 py-10 mt-5 rounded-lg lg:ml-5 w-full lg:w-[700px]">
        <p className="text-[1.875rem] text-[#004F4E] font-semibold">
          {loadedUser?.firstName
            ? `${loadedUser.firstName}, welcome!`
            : 'Welcome'}
        </p>

        <p className="text-[1.125rem] leading-9 mt-5">
          Set-up Sootchy savings to get the most out of Sootchy.
        </p>

        <ul className="list-outside px-5 mt-3">
          <li className="font-thin list-disc leading-7">
            Set-up Sootchy savings to get the most out of Sootchy.
          </li>

          <li className="font-thin list-disc leading-7">
            Get access to a new groundbreaking savings platform.
          </li>

          <li className="font-thin list-disc leading-7">
            Learn more about new ways of doubling up your savings.
          </li>

          <li className="font-thin list-disc leading-7">
            This is gibberish, please change the copy.
          </li>
        </ul>

        <button
          className="w-[80%] flex justify-center m-auto mt-6 text-[1rem] font-normal bg-primary text-white py-5"
          onClick={() => navigate('/setup-savings')}
        >
          Set-up Savings
        </button>
      </div>
    </div>
  )

  const ManageWallets = () => (
    <div className="w-[90%] m-auto mt-[50px] lg:w-[570px] lg:mt-[80px]">
      {showAddChildModal && (
        <AddChildModal
          onClose={() => {
            handleGetWallets()
            setShowAddChildModal(false)
          }}
          id={null}
          childInfo={undefined}
        />
      )}

      <div>
        <p className="font-semibold text-[30px] text-primaryDark">
          <span className="capitalize">{loadedUser?.firstName}'s</span> family
          savings
        </p>

        {displayBadge()}

        {displayActionRequired()}

        {displayAccountDetails()}
      </div>
    </div>
  )

  return (
    <Navigation>
      {loading ? (
        <div className="h-[100vh] flex justify-center items-center">
          <LoadingSpinner color="#004F4E" />
        </div>
      ) : isSilaAccountCreated ? (
        <ManageWallets />
      ) : (
        <SetupSilaaAccount />
      )}
    </Navigation>
  )
}

export default Wallets
